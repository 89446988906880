import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const valueSetTextViewChangesToJson = (fhirJson, valueSetState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.contained = valueSetState.contained || "DELETEME";
  fhirJson.extension = valueSetState.extension || "DELETEME";
  fhirJson.modifierExtension = valueSetState.modifierExtension || "DELETEME";
  fhirJson.url = valueSetState.url || "DELETEME";
  fhirJson.language = valueSetState.language || "DELETEME";
  fhirJson.identifier = valueSetState.identifier || "DELETEME";
  fhirJson.version = valueSetState.version || "DELETEME";
  fhirJson.versionAlgorithmString = valueSetState.versionAlgorithmString || "DELETEME";
  fhirJson.versionAlgorithmCoding = valueSetState.versionAlgorithmCoding || "DELETEME";
  fhirJson.name = valueSetState.name || "DELETEME";
  fhirJson.title = valueSetState.title || "DELETEME";
  fhirJson.status = valueSetState.status || "unknown";
  fhirJson.experimental = valueSetState.experimental ?? "DELETEME";
  fhirJson.date = valueSetState.date || "DELETEME";
  fhirJson.publisher = valueSetState.publisher || "DELETEME";
  fhirJson.contact = valueSetState.contact || "DELETEME";
  fhirJson.description = valueSetState.description || "DELETEME";
  fhirJson.useContext = valueSetState.useContext || "DELETEME";
  fhirJson.jurisdiction = valueSetState.jurisdiction || "DELETEME";
  fhirJson.immutable = valueSetState.immutable ?? "DELETEME";
  fhirJson.purpose = valueSetState.purpose || "DELETEME";
  fhirJson.copyright = valueSetState.copyright || "DELETEME";
  fhirJson.copyrightLabel = valueSetState.copyrightLabel || "DELETEME";
  fhirJson.approvalDate = valueSetState.approvalDate || "DELETEME";
  fhirJson.lastReviewDate = valueSetState.lastReviewDate || "DELETEME";
  fhirJson.effectivePeriod = valueSetState.effectivePeriod || "DELETEME";
  fhirJson.topic = valueSetState.topic || "DELETEME";
  fhirJson.author = valueSetState.author || "DELETEME";
  fhirJson.editor = valueSetState.editor || "DELETEME";
  fhirJson.reviewer = valueSetState.reviewer || "DELETEME"
  fhirJson.endorser = valueSetState.endorser || "DELETEME";
  fhirJson.relatedArtifact = valueSetState.relatedArtifact || "DELETEME";
  fhirJson.compose = valueSetState.compose || "DELETEME";
  fhirJson.expansion = valueSetState.expansion || "DELETEME";
  fhirJson.scope = valueSetState.scope || "DELETEME";

  deleteEmptyElementsInObjectRecursive(fhirJson);
  //let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
  //fhirJson = fhirJsonWithCiteAs;
  //return fhirJson;
  return generateCitation(fhirJson, fhirJson.id)[2];
}

const createNewClassificationJson = (valueSetState) => {
  let contentArray = valueSetState.newClassifications;
  if (!contentArray) {
      return "";
  }
  let classifiedResourceType = "ValueSet";
  let classifiedFoi = valueSetState.id;
  let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
  let classifedTitle = valueSetState.title || valueSetState.name || "[Untitled.]";
  let classificationJson = {
      "resourceType": "ArtifactAssessment",
      "artifactReference": {
          "reference": classifiedReference,
          "type": classifiedResourceType,
          "display": classifedTitle
      },
      "content": contentArray,
      "meta":{
          "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
      }
  };
  return classificationJson;
}

const builderUpdateValueSetJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let valueSetState = formInputsStateRef.current.valueSetState;
  let fhirJson = jsonErrorCheck2("ValueSet", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }
  if (fhirEntryState.activeIndex === 0) {
    fhirJson = valueSetTextViewChangesToJson(fhirJson, valueSetState);
    fhirJson = jsonErrorCheck2("ValueSet", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  let newClassificationJson = createNewClassificationJson(valueSetState);
  return [fhirJson, newClassificationJson];
}

export { builderUpdateValueSetJson };