import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { TextField, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const changeSubjectEntry = (setSubjectState, field, value, settingIndex) => {
    setSubjectState(prevState => {
        if (settingIndex !== undefined) {
            prevState[settingIndex] = { ...prevState[settingIndex], [field]: value };
            return [...prevState];
        } else {
            return { ...prevState, [field]: value }
        }
    });
}

const EvidenceMapSubjectEntry = ({ radioGroupName, subjectState, setSubjectState, settingIndex, noCoding, noReference, mustHaveCharacteristic }) => {
    return <><RadioGroup row aria-label={radioGroupName} name={radioGroupName}
        value={subjectState.radioSelection} onChange={(e) => {
            changeSubjectEntry(setSubjectState, "radioSelection", e.target.value, settingIndex);
        }}>
        <FormControlLabel
            value="description"
            control={<Radio color="primary" />}
            name="radio-button-control"
            color="default"
            inputprops={{ 'aria-label': 'description' }}
            labelPlacement="end"
            label={<h4 style={{ textAlign: 'left' }}>Description</h4>}
        />
        {!noCoding &&
            <FormControlLabel
                value="codeableConcept"
                control={<Radio color="primary" />}
                name="radio-button-control"
                color="default"
                inputprops={{ 'aria-label': 'codeableConcept' }}
                labelPlacement="end"
                label={<h4 style={{ textAlign: 'left' }}>Coding</h4>}
            />}
        {!noReference && <FormControlLabel
            value="reference"
            control={<Radio color="primary" />}
            name="radio-button-control"
            color="default"
            inputprops={{ 'aria-label': 'reference' }}
            labelPlacement="end"
            label={<h4 style={{ textAlign: 'left' }}>Reference</h4>}
        />}
        <br />
        {!mustHaveCharacteristic && <FormControlLabel
            value="NoCharacteristic"
            control={<Radio color="primary" />}
            name="radio-button-control"
            color="default"
            inputprops={{ 'aria-label': 'NoCharacteristic' }}
            labelPlacement="end"
            label={<h4 style={{ textAlign: 'left' }}>No characteristic</h4>}
        />}
    </RadioGroup>
        <div style={{ paddingLeft: "32px" }}>
            {subjectState.radioSelection === "description" &&
                <span><b>Description: </b>
                    <TextField style={{ width: "90%" }} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined'
                        value={subjectState.description}
                        onChange={(e) => {
                            changeSubjectEntry(setSubjectState, "description", e.target.value, settingIndex);
                        }}
                    />
                </span>
            }
            {subjectState.radioSelection === "codeableConcept" &&
                <span><b>Coding: </b>
                    <TextField style={{ width: "30%", marginRight: "8px" }} className="inputField" type='text' label={'System'} size="small" variant='outlined'
                        value={subjectState.codingSystem}
                        onChange={(e) => {
                            changeSubjectEntry(setSubjectState, "codingSystem", e.target.value, settingIndex);
                        }}
                    />
                    <TextField style={{ width: "30%", marginRight: "8px" }} className="inputField" type='text' label={'Code'} size="small" variant='outlined'
                        value={subjectState.codingCode}
                        onChange={(e) => {
                            changeSubjectEntry(setSubjectState, "codingCode", e.target.value, settingIndex);
                        }}
                    />
                    <TextField style={{ width: "30%" }} className="inputField" type='text' label={'Display'} size="small" variant='outlined'
                        value={subjectState.codingDisplay}
                        onChange={(e) => {
                            changeSubjectEntry(setSubjectState, "codingDisplay", e.target.value, settingIndex);
                        }}
                    /></span>
            }
            {subjectState.radioSelection === "reference" &&
                <span><b>Reference: </b>
                    <TextField style={{ width: "30%", marginRight: "8px" }} className="inputField" type='text' label={'Reference'} size="small" variant='outlined'
                        value={subjectState.referenceReference}
                        onChange={(e) => {
                            changeSubjectEntry(setSubjectState, "referenceReference", e.target.value, settingIndex);
                        }}
                    />
                    <TextField style={{ width: "30%", marginRight: "8px" }} className="inputField" type='text' label={'Identifier'} size="small" variant='outlined'
                        value={subjectState.referenceIdentifier}
                        onChange={(e) => {
                            changeSubjectEntry(setSubjectState, "referenceIdentifier", e.target.value, settingIndex);
                        }}
                    />
                    <TextField style={{ width: "30%" }} className="inputField" type='text' label={'Display'} size="small" variant='outlined'
                        value={subjectState.referenceDisplay}
                        onChange={(e) => {
                            changeSubjectEntry(setSubjectState, "referenceDisplay", e.target.value, settingIndex);
                        }}
                    /></span>
            }
            {subjectState.radioSelection === "NoCharacteristic" &&
                <span><i>No characteristic definition selected.</i></span>
            }
        </div>
    </>;
}

const CreateEvidenceReportSubjectForm = ({ addToast, projectid, projecttitle, setEvidenceReportSubjectModalState }) => {
    const globalContext = useContext(FevirContext);
    let blankSubject = {
        "radioSelection": "NoCharacteristic",
        "description": "",
        "codingSystem": "",
        "codingCode": "",
        "codingDisplay": "",
        "referenceReference": "",
        "referenceIdentifier": "",
        "referenceDisplay": "",
    };
    let startingStates = { "population": JSON.parse(JSON.stringify(blankSubject)), "intervention": JSON.parse(JSON.stringify(blankSubject)), "comparator": JSON.parse(JSON.stringify(blankSubject)), "outcome": JSON.parse(JSON.stringify(blankSubject)), "study-type": JSON.parse(JSON.stringify(blankSubject)), "citation": JSON.parse(JSON.stringify(blankSubject)) };

    const [evidenceReportSubjectState, setEvidenceReportSubjectState] = useState({ "title": projecttitle, "status": "active", "evidenceMapId": "", "project": projectid, "evidenceReportSubjectId": "" });

    const [subjectPopulationState, setSubjectPopulationState] = useState(startingStates["population"]);
    const [subjectInterventionState, setSubjectInterventionState] = useState(startingStates["intervention"]);
    const [subjectComparatorState, setSubjectComparatorState] = useState(startingStates["comparator"]);
    const [subjectOutcomeState, setSubjectOutcomeState] = useState(startingStates["outcome"]);
    const [subjectStudyDesignState, setSubjectStudyDesignState] = useState(startingStates["study-type"]);
    const [subjectCitationState, setSubjectCitationState] = useState(startingStates["citation"]);

    const submitEvidenceReportSubject = async () => {

        if (evidenceReportSubjectState.title.trim() === "") {
            alert("Title field is missing.");
            return;
        }

        const body = {
            'functionid': "submitevidencereportsubject",
            'evidenceReportSubjectFields': evidenceReportSubjectState,
            'subjectPopulation': subjectPopulationState,
            'subjectIntervention': subjectInterventionState,
            'subjectComparator': subjectComparatorState,
            'subjectOutcome': subjectOutcomeState,
            'subjectStudyDesign': subjectStudyDesignState,
            'subjectCitation': subjectCitationState,
            'idToken': ""
        };

        let response = await submitToFevirServer(globalContext, 30000, body, true, false);

        if (response) {
            if (response.success && response.evidencevariableid) {

                setEvidenceReportSubjectState(prevState => { return { ...prevState, evidenceReportSubjectId: response.evidencevariableid, title: response.evidencevariabletitle } });

                if (addToast) {
                    addToast("Evidence Report Subject created: " + response.evidencevariableid, { appearance: 'success' });
                }
                if (setEvidenceReportSubjectModalState) {
                    setEvidenceReportSubjectModalState(prevState => { return { ...prevState, modalOpen: false } });
                }
            } else if (response.errormessage) {
                alert(response.errormessage);
            } else {
                alert("Problem submitting EvidenceReportSubject.");
            }
        } else {
            alert("Problem (perhaps timeout) submitting EvidenceReportSubject.");
        }

    };

    useEffect(() => {
        if (globalContext.userState.loading === false) {
        }
    }, [globalContext.userState])

    return <div style={{ marginTop: "12px" }}>
        <h3 id="summary">Summary</h3>
        <div style={{ marginLeft: "24px" }}>
            <span><b>Select Project (by number): </b> <TextField style={{ width: "20%", minWidth: "100px" }} className="inputField" type='text' label={'Project'} size="small" variant='outlined'
                value={evidenceReportSubjectState.project}
                onChange={(e) => {
                    setEvidenceReportSubjectState(prevState => {
                        return { ...prevState, "project": e.target.value };
                    });
                }}
            /></span>
            <br />
            <span><b>Title: </b> <TextField style={{ width: "90%", minWidth: "500px" }} multiline className="inputField" type='text' label={'Title'} size="small" variant='outlined'
                value={evidenceReportSubjectState.title}
                onChange={(e) => {
                    setEvidenceReportSubjectState(prevState => {
                        return { ...prevState, "title": e.target.value };
                    });
                }}
            /></span>
            <br /><br />
            <span><b>Description: </b> <TextField style={{ width: "90%", minWidth: "500px" }} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined'
                value={evidenceReportSubjectState.description}
                onChange={(e) => {
                    setEvidenceReportSubjectState(prevState => {
                        return { ...prevState, "description": e.target.value };
                    });
                }}
            /></span>
            <br />
        </div>
        <br />
        <h3 id="subject">Subject</h3>
        <div>
            <div style={{ marginLeft: "24px" }}>
                <span><b>Population: </b> </span>
                <br />
                <EvidenceMapSubjectEntry radioGroupName={"subjectPopulation"} subjectState={subjectPopulationState} setSubjectState={setSubjectPopulationState} />
            </div>

            <div style={{ marginLeft: "24px" }}>
                <span><b>Intervention: </b> </span>
                <br />
                <EvidenceMapSubjectEntry radioGroupName={"subjectIntervention"} subjectState={subjectInterventionState} setSubjectState={setSubjectInterventionState} />
            </div>
            <div style={{ marginLeft: "24px" }}>
                <span><b>Comparator: </b> </span>
                <br />
                <div>
                </div>
                <EvidenceMapSubjectEntry radioGroupName={"subjectComparator"} subjectState={subjectComparatorState} setSubjectState={setSubjectComparatorState} />
            </div>
            <div style={{ marginLeft: "24px" }}>
                <span><b>Outcome: </b> </span>
                <br />
                <EvidenceMapSubjectEntry radioGroupName={"subjectOutcome"} subjectState={subjectOutcomeState} setSubjectState={setSubjectOutcomeState} />
            </div>
            <div style={{ marginLeft: "24px" }}>
                <span><b>Study Design: </b> </span>
                <br />
                <EvidenceMapSubjectEntry radioGroupName={"subjectStudyDesign"} subjectState={subjectStudyDesignState} setSubjectState={setSubjectStudyDesignState} noReference={true} />
            </div>
            <div style={{ marginLeft: "24px" }}>
                <span><b>Citation: </b> </span>
                <br />
                <EvidenceMapSubjectEntry radioGroupName={"subjectCitation"} subjectState={subjectCitationState} setSubjectState={setSubjectCitationState} noCoding={true} />
            </div>
        </div>
        <br />

        <Button className="formButton positive" style={{ minWidth: "400px" }} content="Submit Evidence Report Subject" onClick={submitEvidenceReportSubject} />
        <br /><br /><br />

    </div>
}

const CreateEvidenceReportSubjectModal = ({ addToast, evidenceReportSubjectModalState, setEvidenceReportSubjectModalState,
    projectid, projecttitle }) => {
    let modalContent = <div id="CreateEvidenceReportSubjecModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto" }}>
        <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative" content="✖" onClick={() => { setEvidenceReportSubjectModalState(prevState => { return { ...prevState, modalOpen: false }; }); }} />

        <h2 style={{ textAlign: "center" }}>Evidence Report Subject Builder</h2>
        <CreateEvidenceReportSubjectForm addToast={addToast} setEvidenceReportSubjectModalState={setEvidenceReportSubjectModalState}
            projectid={projectid} projecttitle={projecttitle} />
    </div>;
    return <Modal
        style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
        dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
        open={(evidenceReportSubjectModalState && evidenceReportSubjectModalState["modalOpen"])}
        centered={false}
        content={modalContent}
    />
}

export default CreateEvidenceReportSubjectModal;