import { AssociatedResourcesDisplay, getStringFromFHIR, DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import React, { useState, useEffect, useContext } from 'react';
import FevirContext from './FevirContext';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { EvidenceReportPackageSectionDisplay } from './EvidenceReportPackageViewingTool';
import { getJsonFromIdentifier } from './ResourceDictionaryFunctions';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';

const SectionDisplay = ({ sect, sectIndex, resourceDictionary }) => {
  if (!sect || !Object.keys(sect).length) {
    return <div key={sectIndex} >
      <br />
      <p><b>{'Section ' + (sectIndex + 1).toString()}:</b></p>
      <>No content in this section.</>
    </div>
  }
  let populationJson;
  let populationQuantity;
  let populationCombinationMethod;
  let populationDescription;
  let populationReference;
  let populationCharacteristic;
  if (sect.entry?.length > 0) {
    populationReference = sect.entry[0];
  }
  if (resourceDictionary && populationReference) {
    populationJson = getJsonFromIdentifier(resourceDictionary, populationReference, "Group");
    if (populationJson) {
      if (sect.text && sect.text.status !== "empty") {
        populationDescription = sect.text.div;
      } else {
        populationDescription = populationJson.description;
      }
      if (populationJson.quantity) {
        populationQuantity = populationJson.quantity;
      }
      if (populationJson.combinationMethod) {
        populationCombinationMethod = populationJson.combinationMethod;
        if (populationJson.combinationThreshold) {
          populationCombinationMethod += " " + populationJson.combinationThreshold.toString();
        }
      }
      if (populationJson.characteristic) {
        populationCharacteristic = true;
      }
    }
  }
  return <div key={sectIndex} >
    <br />
    <p><b>{sect.title ? sect.title : 'Section ' + (sectIndex + 1).toString()}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(sect.code && Object.keys(sect.code).length > 0) && <span><b>Code: </b><DisplayFromFHIR codeableConcept={sect.code} /><br /></span>}
      {(sect.focus && Object.keys(sect.focus).length > 0) && <span><b>Focus: </b><DisplayFromFHIR reference={sect.focus} /><br /></span>}
      {(Array.isArray(sect.author) && sect.author.length > 0) &&
        sect.author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(sect.text && Object.keys(sect.text).length > 0) && <div>
        {sect.text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={sect.text.div} />
            </div>
          </div>
        }
        <br />
      </div>}
      {(sect.orderedBy && Object.keys(sect.orderedBy).length > 0) && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={sect.orderedBy} /><br /></span>}
      {populationJson ? <div>
        {populationDescription && <div><br /><DisplayFromFHIR xhtml={populationDescription} /><br /><br /></div>}
        {populationQuantity && <div><br /><b>Quantity: </b>{populationQuantity}</div>}
        {populationCombinationMethod && <div><b>Combination Method: </b>{populationCombinationMethod}</div>}
        {populationCharacteristic &&
          <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={populationJson} />}
        {populationReference && <div><br /><b>Reference to {populationReference?.type && populationReference.type + " "}Resource: </b><DisplayFromFHIR reference={populationReference} /></div>}
      </div> :
        <>
          {(Array.isArray(sect.entry) && sect.entry.length > 0) &&
            sect.entry.map((entry, entryIndex) => {
              return <div key={entryIndex}><b>Entry {entryIndex + 1}:  </b><DisplayFromFHIR reference={entry} /></div>;
            })}
        </>}
      {(sect.emptyReason && Object.keys(sect.emptyReason).length > 0) && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={sect.emptyReason} /><br /></span>}
      {(Array.isArray(sect.section) && sect.section.length > 0) &&
        sect.section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}><SectionDisplay sect={subsection} sectIndex={subsectionIndex}
            resourceDictionary={resourceDictionary} /></span>;
        })}
    </div>
  </div>
}

const TopSectionDisplay = ({ topSection, resourceDictionary }) => {

  if (!topSection || !Object.keys(topSection).length) {
    return <>No content in this section.</>
  }
  let title;
  if (topSection.title) {
    title = topSection.title;
  }
  let code;
  if (topSection.code && Object.keys(topSection.code).length > 0) {
    code = topSection.code;
  }
  let author;
  if (Array.isArray(topSection.author) && topSection.author.length > 0) {
    author = topSection.author
  }
  let focus;
  if (topSection.focus && Object.keys(topSection.focus).length > 0) {
    focus = topSection.focus;
  }
  let text;
  if (topSection.text && Object.keys(topSection.text).length > 0) {
    text = topSection.text;
  }
  let orderedBy;
  if (topSection.orderedBy && Object.keys(topSection.orderedBy).length > 0) {
    orderedBy = topSection.orderedBy;
  }
  let entry;
  if (Array.isArray(topSection.entry) && topSection.entry.length > 0) {
    entry = topSection.entry
  }
  let emptyReason;
  if (topSection.emptyReason && Object.keys(topSection.emptyReason).length > 0) {
    emptyReason = topSection.emptyReason;
  }
  let section;
  if (Array.isArray(topSection.section) && topSection.section.length > 0) {
    section = topSection.section
  }
  let titleDisplay = title || getStringFromFHIR.CodeableConcept(code);

  return <div>
    <p><b>{titleDisplay}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {focus && <span><b>Focus: </b><DisplayFromFHIR reference={focus} /><br /></span>}
      {(Array.isArray(author) && author.length > 0) &&
        author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(text && Object.keys(text).length > 0) && <div>
        {text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={text.div} />
            </div>
          </div>}
        <br />
      </div>}
      {orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={orderedBy} /><br /></span>}
      {(Array.isArray(entry) && entry.length > 0) &&
        entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}:  </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={emptyReason} /><br /></span>}
      {(Array.isArray(section) && section.length > 0) &&
        section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}>
            <SectionDisplay sect={subsection} sectIndex={subsectionIndex}
              resourceDictionary={resourceDictionary} />
          </span>;
        })}
    </div>
  </div>
}

const RecommendationViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
        break;
      }
    }
  }
  let description;
  if (Array.isArray(fhirJson.extension)) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }

  let summarySection;
  let recommendationSection = {};
  let evidenceSection = {};
  let justificationSection = {};
  let considerationsSection = {};
  let introductionSection = {};
  let discussionSection = {};
  let methodsSection = {};
  let referencesSection = {};
  let competingInterestsSection = {};
  let acknowlegementsSection = {};
  let appendicesSection = {};
  let additionalSections = [];
  if (fhirJson?.section) {
    for (const section of fhirJson.section) {
      let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
      if (sectionCode === "recommendation-specification" || sectionCode === "Recommendation Specification") {
        recommendationSection = section;
      } else if (sectionCode === "evidence" || sectionCode === "Evidence") {
        evidenceSection = section;
      } else if (sectionCode === "justification" || sectionCode === "Justification" || sectionCode === "judgments" || sectionCode === "Judgments") {
        justificationSection = section;
      } else if (sectionCode === "considerations" || sectionCode === "Considerations") {
        considerationsSection = section;
      } else if (sectionCode === "introduction" || sectionCode === "Introduction") {
        introductionSection = section;
      } else if (sectionCode === "summary" || sectionCode === "Summary") {
        summarySection = section;
      } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
        discussionSection = section;
      } else if (sectionCode === "methods" || sectionCode === "Methods") {
        methodsSection = section;
      } else if (sectionCode === "references" || sectionCode === "References") {
        referencesSection = section;
      } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
        competingInterestsSection = section;
      } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
        acknowlegementsSection = section;
      } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
        appendicesSection = section;
      } else {
        additionalSections.push(section);
      }
    }
  }

  const globalContext = useContext(FevirContext);
  const [sourceJsonState, setSourceJsonState] = useState({});

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(fhirJson, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, []);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Recommendation Title: </b>
          {fhirJson.title ? fhirJson.title : fhirJson.name ? fhirJson.name : "No title provided."}
        </p>
        {description &&
          <div><b>Recommendation Description: </b><DisplayFromFHIR markdown={description} />
          </div>}
        {Array.isArray(fhirJson.subject) &&
          fhirJson.subject.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Subject: </b><DisplayFromFHIR reference={subject} /></div>;
          })}
        {Array.isArray(fhirJson.category) &&
          fhirJson.category.map((subject, subjectIndex) => {
            return <div key={subjectIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={subject} /></div>;
          })}
        {(fhirJson.text && fhirJson.text.status !== "empty" && fhirJson.text.div) && <div>
          <p>Narrative Summary of Recommendation Resource:</p>
          <DisplayFromFHIR xhtml={fhirJson.text.div} />
        </div>}
        {summarySection && <TopSectionDisplay topSection={summarySection} />}
      </div>
      <h3 id="introduction">Introduction</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={introductionSection} />
      </div>
      <br />
      <h3 id="recommendation">Recommendation</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={recommendationSection}
          resourceDictionary={sourceJsonState.resourceDictionary} />
      </div>
      <h3 id="justification">Justification</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={justificationSection} />
      </div>
      <h3 id="considerations">Considerations</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={considerationsSection} />
      </div>
      <h3 id="methods">Methods</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={methodsSection} />
      </div>
      <h3 id="evidence">Evidence</h3>
      <div style={{ marginLeft: "24px" }}>
        <TopSectionDisplay topSection={evidenceSection} />
      </div>
      <h3 id="discussion">Discussion</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={discussionSection} />
        {additionalSections?.length > 0 && additionalSections.map((section, sectionIndex) => {
          return <div key={sectionIndex} >
            <EvidenceReportPackageSectionDisplay topSection={section} />
          </div>
        })}
      </div>
      <h3 id="references">References</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={referencesSection} />
      </div>
      <h3 id="competing-interests">Competing Interests</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={competingInterestsSection} />
      </div>
      <h3 id="acknowledgements">Acknowledgements</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={acknowlegementsSection} />
      </div>
      <h3 id="appendices">Appendices</h3>
      <div style={{ marginLeft: "24px" }}>
        <EvidenceReportPackageSectionDisplay topSection={appendicesSection} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default RecommendationViewerTool;