import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import "firebase/compat/auth";
import { sendFli } from './ResourceFunctions';
import './App.css';
import FevirContext from './FevirContext';


const FliToFoiPage = ({ useTitle, changeFormState, urlFli }) => {

  useTitle("FEvIR FLI to FOI");

  const globalContext = useContext(FevirContext);
  const [fhirEntryState, setFhirEntryState] = useState({ "fli": urlFli });

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        //fhirEntry: newValue,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  useEffect(async () => {
    changeFormState("", "pageTitle");
    if (urlFli) {
      //if (!history.location.pathname.includes("/flitofoi/")) {
      //  alert("A resource by that FEvIR Linking Identifier does not exist, or you're not allowed to view it.\n\n"+urlFli);
      //}
      history.replace("/FLI");
    }
  }, []);

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>View a Resource by FEvIR Linking Identifier</h2>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <br /><br />
        <TextField style={{ width: "480px" }} multiline className="inputField" type='text' label={'FEvIR Linking Identifier'} size="small" variant='outlined' value={fhirEntryState.fli} onChange={(e) => { changeFhirEntryState(e.target.value, "fli"); }} />
        <br /><br />
        <Button className="formButton positive" content="View Resource" compact onClick={() => { sendFli(fhirEntryState.fli, globalContext, history, false); }} disabled={fhirEntryState.fli === ""} />
      </Segment>
    </div>
  );
};

export default FliToFoiPage;