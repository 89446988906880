import { DisplayFromFHIR, AssociatedResourcesDisplay, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const ResourceBottomDisplay = ({ howToCite, fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  return <div>
    <h3 id="how-to-cite">How to Cite</h3>
    <div style={{ marginLeft: "24px" }}>
      {(howToCite) ?
        <div>
          <p><b>Citation Summary:</b></p>
          <DisplayFromFHIR markdown={howToCite} />
        </div>
        :
        <p>Can be added upon editing.</p>
      }
    </div>
    <h3 id="metadata">Metadata</h3>
    <div style={{ marginLeft: "24px" }}>
      <MetadataPatternDisplay fhirJson={fhirJson} />
    </div>
    <h3 id="associated-resources">Associated Resources</h3>
    <div style={{ marginLeft: "24px" }}>
      <AssociatedResourcesDisplay fhirJson={fhirJson} />
    </div>
    <h3 id="classifiers">Classifiers</h3>
    {classificationsLoadedState ?
      <DisplayClassifiers classificationsArray={classificationsArrayState} />
      :
      <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
    }
    <h3 id="json-outline">JSON Outline</h3>
    <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
    <br /><br />

  </div>
}

export default ResourceBottomDisplay;