import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Button, Segment, Dimmer, Loader, Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import './App.css';
import CodeSystemTermProgressTable from './CodeSystemTermProgressTable';
import submitToFevirServer from './SubmitToFevirServer';
import FevirContext from './FevirContext';
import { SimpleResourceFieldViewer } from './ResourceFunctions';

const UserListTable = ({ userList, tableHeaderStyle, tableCellStyle }) => {
  return <Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell style={tableHeaderStyle}>User ID</Table.HeaderCell>
        <Table.HeaderCell style={tableHeaderStyle}>Username</Table.HeaderCell>
        <Table.HeaderCell style={tableHeaderStyle}>Date Joined</Table.HeaderCell>
        <Table.HeaderCell style={tableHeaderStyle}>Notification Email</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {userList?.map((user, userIndex) => {
        return <Table.Row key={userIndex}>
          <Table.Cell style={tableCellStyle}>{user.userid}</Table.Cell>
          <Table.Cell>{user.name}</Table.Cell>
          <Table.Cell>{user.datecreated}</Table.Cell>
          <Table.Cell>{user.notificationemail && <a href={"mailto:" + user.notificationemail} target="_blank" rel="noopener noreferrer">{user.notificationemail}</a>}</Table.Cell>
        </Table.Row>
      })}
    </Table.Body>
  </Table>;
}


const FeedbackTable = ({ feedbackFromServer, tableHeaderStyle, tableCellStyle }) => {
  return <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={tableHeaderStyle}>Title</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Date Created</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>User ID</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {feedbackFromServer.map((feedback, feedbackIndex) => {
          return <Table.Row key={feedbackIndex}>
            <Table.Cell style={tableCellStyle}><Link to={`/resources/ArtifactAssessment/${feedback.id}`}>{feedback.title}</Link></Table.Cell>
            <Table.Cell>{feedback.datecreated}</Table.Cell>
            <Table.Cell>{feedback.resourcecreatoruserid}</Table.Cell>
            <Table.Cell>{feedback.status}</Table.Cell>
          </Table.Row>
        })
        }
      </Table.Body>
    </Table>
  </>
}

const ErrorsTable = ({ errorsFromServer, tableHeaderStyle, tableCellStyle }) => {
  return <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={tableHeaderStyle}>Tool</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Grouping ID</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Type of Call</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Error first occurred</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Last occurrence</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Occurrences</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {errorsFromServer.map((error, errorIndex) => {
          return <Table.Row key={errorIndex}>
            <Table.Cell style={tableCellStyle}>{error.tool}</Table.Cell>
            <Table.Cell style={tableCellStyle}>{error.groupingidentifier}</Table.Cell>
            <Table.Cell>{(error.servercalltype === "get" || error.servercalltype === "api") && "API Call"}</Table.Cell>
            <Table.Cell>{error.datecreated}</Table.Cell>
            <Table.Cell>{error.lastoccurred}</Table.Cell>
            <Table.Cell>{error.occurrencecounter}</Table.Cell>
          </Table.Row>
        })
        }
      </Table.Body>
    </Table>
  </>
}

const AdminPage = ({ useTitle }) => {
  useTitle("FEvIR Admin");

  const [adminState, setAdminState] = useState({ "loading": true, "startedToLoad": true, showROBATusers: false, "sevcoTermProgress": undefined, "sevcoExpertWorkingGroupUsers": [], "enrollmentGroupUsersInfo": undefined, "errorsFromServer": [] });
  const [projectIdsState, setProjectIdsState] = useState("29571");
  const globalContext = useContext(FevirContext);

  const updateProjectsEvidenceResources = async () => {
    let projectIds = projectIdsState.replace(" ", "").split(",");
    const projectsEvidenceUpdateBody = {
      'functionid': 'projectevidencecalculateandupdate',
      'idToken': '',
      'projectfois': projectIds
    };
    let response = await submitToFevirServer(globalContext, 60000, projectsEvidenceUpdateBody, true, false);
    if (response?.success) {
      globalContext.openAlert({ "header": "Success", "content": "Done updating." });
    } else {
      globalContext.openAlert({ "header": "Error", "content": "Problem updating." });
    }
  }

  const getSpreadsheetReportCTGovMapping = async (titlesFind, startingFileName) => {
    const body = {
      'functionid': 'getspreadsheetreportctgovmapping',
      'titlesFind': titlesFind,
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success) {
      //globalContext.openAlert({ "header": "Success", "content": "Done updating" });
      //console.log(response.mappingresultscsv);
      let d = new Date();
      let todaysDate = d.toISOString().split('T')[0];
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.mappingresultscsv));
      element.setAttribute('download', startingFileName + " " + todaysDate + ".csv");

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      return response.mappingresultscsv;
    } else {
      globalContext.openAlert({ "header": "Error", "content": "Problem getting the data" });
    }
  }

  const getFeedback = async (setAdminState) => {//TODO does setAdminState need to be sent to this function or is it defined at a higher level?
    const body = {
      'functionid': 'getallfeedback',
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success && response.feedbackFromServer) {
      let feedbackFromServer = response.feedbackFromServer;
      feedbackFromServer.sort((a, b) => (a.lastmodified < b.lastmodified) ? 1 : -1);
      setAdminState(prevState => { return { ...prevState, feedbackFromServer: feedbackFromServer }; });
    }
  }

  const getErrors = async (setAdminState) => {
    const body = {
      'functionid': 'geterrors',
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success && response.errorsFromServer) {
      let errorsFromServer = response.errorsFromServer;
      errorsFromServer.sort((a, b) => (a.lastoccurred < b.lastoccurred) ? 1 : -1);
      setAdminState(prevState => { return { ...prevState, errorsFromServer: errorsFromServer }; });
    }
  }

  /*
  useEffect(async () => {
    if (adminState.showROBATusers && adminState.enrollmentGroupUsersInfo === undefined) {
      try {
        let enrollmentGroupJson;
        let enrollmentGroupTitle;
        let enrollmentGroupMembers;
        let enrollmentGroupUserIds;
        let enrollmentGroupUsersInfo = [];
        const enrollmentGroupBody = {
          'functionid': 'getfhirresource',
          'idToken': '',
          'resourceid': "30678"
        };
        let response;
        response = await submitToFevirServer(globalContext, 10000, enrollmentGroupBody, true, false);
        if (response.success && response.fhirjsonstring) {
          enrollmentGroupJson = JSON.parse(response.fhirjsonstring);
          if (enrollmentGroupJson.title) {
            enrollmentGroupTitle = enrollmentGroupJson.title;
          }
          if (enrollmentGroupJson.member) {
            enrollmentGroupMembers = [];
            enrollmentGroupUserIds = {};
            for (let memberIndex in enrollmentGroupJson.member) {
              let member = enrollmentGroupJson.member[memberIndex];
              if (member.entity?.reference) {
                let inactive = false;
                if (member.inactive) {
                  inactive = true;
                }
                let memberId = /[^/]*$/.exec(member.entity.reference)[0];
                enrollmentGroupMembers.push(memberId);
                const researchSubjectBody = {
                  'functionid': 'getfhirresource',
                  'idToken': '',
                  'resourceid': memberId.toString()
                };
                let researchSubjectResponse = await submitToFevirServer(globalContext, 10000, researchSubjectBody, true, false);
                if (researchSubjectResponse.success && researchSubjectResponse.fhirjsonstring) {
                  let researchSubjectJson = JSON.parse(researchSubjectResponse.fhirjsonstring);
                  if (researchSubjectJson.subject?.identifier?.system === "https://fevir.net" && researchSubjectJson.subject?.identifier?.value) {
                    let userInfo = {};
                    let userid = researchSubjectJson.subject.identifier.value;
                    let duplicate = false;
                    let displayname;
                    let notificationemail;
                    let additionalemail;
                    if (enrollmentGroupUserIds[userid]) {
                      duplicate = true;
                      enrollmentGroupUserIds[userid]["duplicate"] = true;
                      userInfo = enrollmentGroupUserIds[userid];
                    } else {
                      const userBody = {
                        'functionid': 'getuserinfo',
                        'idToken': '',
                        'userid': userid.toString()
                      };
                      let userResponse = await submitToFevirServer(globalContext, 10000, userBody, true, false);
                      if (userResponse?.success && userResponse?.userinfo) {
                        displayname = userResponse.userinfo.name;
                        notificationemail = userResponse.userinfo.notificationemail;
                        additionalemail = userResponse.userinfo.additionalemail;
                      }
                      userInfo = { researchSubjectFoi: memberId, userid: userid, displayname: displayname, notificationemail: notificationemail, additionalemail: additionalemail, inactive: inactive, duplicate: duplicate };
                      enrollmentGroupUserIds[userid] = userInfo;
                    }
                    enrollmentGroupUsersInfo.push(userInfo);
                  }
                }
              }
            }
          }
        }
        setAdminState(prevState => { return { ...prevState, enrollmentGroupUsersInfo: enrollmentGroupUsersInfo }; });
      } catch (e) { }

    }
  }, [adminState]);
  */

  useEffect(async () => {
    if (globalContext.userState.name && globalContext.userState.loading === false && adminState.startedToLoad) {

      setAdminState(prevState => { return { ...prevState, startedToLoad: false }; });

      let sevcoExpertWorkingGroupUsers;
      let sevcoTermProgress;
      let gradeExpertWorkingGroupUsers;
      let gradeTermProgress;
      await getFeedback(setAdminState);
      await getErrors(setAdminState);
      let allLoaded = true;
      try {
        const usersBody = {
          'functionid': 'getusersinvotingpermissiongroup',
          'idToken': '',
          'aboutformstateid': "27270"
        };
        let response;
        response = await submitToFevirServer(globalContext, 10000, usersBody, true, false);
        if (response.success) {
          sevcoExpertWorkingGroupUsers = response.votinggroupusers;
        } else {
          allLoaded = false;
        }
      } catch (e) { allLoaded = false; }
      try {
        const usersBody = {
          'functionid': 'gettermcountincodesystem',
          'idToken': '',
          'resourceid': "27270"
        };
        let response;
        response = await submitToFevirServer(globalContext, 10000, usersBody, true, false);
        if (response?.success) {
          sevcoTermProgress = response.termcountinfo;
        } else {
          //allLoaded = false;
        }
      } catch (e) { allLoaded = false; }

      try {
        const usersBody = {
          'functionid': 'getusersinvotingpermissiongroup',
          'idToken': '',
          'aboutformstateid': "27833"
        };
        let response;
        response = await submitToFevirServer(globalContext, 10000, usersBody, true, false);
        if (response.success) {
          gradeExpertWorkingGroupUsers = response.votinggroupusers;
        } else {
          allLoaded = false;
        }
      } catch (e) { allLoaded = false; }
      try {
        const usersBody = {
          'functionid': 'gettermcountincodesystem',
          'idToken': '',
          'resourceid': "27833"
        };
        let response;
        response = await submitToFevirServer(globalContext, 10000, usersBody, true, false);
        if (response.success) {
          gradeTermProgress = response.termcountinfo;
        } else {
          allLoaded = false;
        }
      } catch (e) { allLoaded = false; }

      if (allLoaded) {
        setAdminState(prevState => {
          return {
            ...prevState,
            sevcoExpertWorkingGroupUsers: sevcoExpertWorkingGroupUsers.reverse(),
            sevcoTermProgress: sevcoTermProgress,
            gradeExpertWorkingGroupUsers: gradeExpertWorkingGroupUsers.reverse(),
            gradeTermProgress: gradeTermProgress,
            loading: false,
            startedToLoad: false
          };
        });
      } else {
        setAdminState(prevState => { return { ...prevState, startedToLoad: true }; });
      }
    }
  }, [globalContext.userState]);

  const history = useHistory();
  const [foiForIndexState, setFoiForIndexState] = useState({ "foi": "", "fevirIndexEntry": "" });

  let errorsTableHeaderStyle = { backgroundColor: "#FABEBE" };
  let errorsTableCellStyle = { backgroundColor: "#FAEAEA" };
  let feedbackTableHeaderStyle = { backgroundColor: "#C0FAC0" };
  let feedbackTableCellStyle = { backgroundColor: "#F2FDF2" };
  let sevcoTableHeaderStyle = { backgroundColor: "#E2DDEF" };
  let sevcoTableCellStyle = { backgroundColor: "#F6F4FA" };
  let gradeTableHeaderStyle = { backgroundColor: "#EFE2DD" };
  let gradeTableCellStyle = { backgroundColor: "#FAF6F4" };

  let researchStudyHeaderStyle = { backgroundColor: "#D2DDD2" };
  let researchStudyCellStyle = { backgroundColor: "#E6F4E6" };

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        {(!globalContext.userState.loading && globalContext.userState.admin) ?
          <div>
            {adminState.loading &&
              <Dimmer className={"loadingDimmer"} active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            }
            <span style={{ width: "100%", textAlign: "center" }}><h3>Admin Dashboard</h3></span>
            <Button style={{ position: "absolute", right: "3px", top: "3px" }} className="formButton negative" content="✖" 
            onClick={() => { history.push("/"); }} />
            {/*<Button style={{ width: "360px", float: "left" }} className="formButton" content="Recreate Index Database"
              onClick={() => {
                submitToFevirServer(globalContext, 120000, { functionid: 'recreateindexentriestable' }, true, false);
                globalContext.openAlert({ "header": "", "content": "The process to recreate the index database is underway. It may take up to 10 minutes or more." });
              }} />
            <br /><br />*/}
            <Button style={{ width: "360px", float: "left" }} className="formButton" content="Recreate Resource List Index"
              onClick={async () => {
                let response = await submitToFevirServer(globalContext, 120000, { functionid: 'recreateresourcelistindex' }, true, false);
                if (response) {
                  globalContext.openAlert({ "header": "", "content": JSON.stringify(response) });
                } else {
                  globalContext.openAlert({ "header": "", "content": "The process to recreate the resource list index database is underway. It may take up to 10 minutes or more." });
                }
              }} />
            <br /><br />
            <Button style={{ width: "360px", float: "left" }} className="formButton" content="Refresh Search Index"
              onClick={async () => {
                let response = await submitToFevirServer(globalContext, 60000, { functionid: 'refreshsearchindex' }, true, false);
                globalContext.openAlert({ "header": "", "content": JSON.stringify(response) });
              }} />
            <br /><br />
            <TextField style={{ width: "480px" }} className="inputField" size="small" variant='outlined'
              placeholder='Enter FOI for index entry' type='number'
              value={foiForIndexState.foi} onKeyUp={async (event) => {
                if (event.key === 'Enter' && foiForIndexState) {
                  let body = { "functionid": "getfevirindexentry", "foi": foiForIndexState.foi };
                  let response = await submitToFevirServer(globalContext, 10000, body, true, false);
                  if (response?.success && response.fevirIndexEntry) {
                    setFoiForIndexState(prevState => { return { ...prevState, "fevirIndexEntry": response.fevirIndexEntry } })
                  }
                }
              }}
              onChange={(e) => { setFoiForIndexState({ "foi": e.target.value, "fevirIndexEntry": "" }); }} />
            {foiForIndexState.fevirIndexEntry &&
              <SimpleResourceFieldViewer resource={foiForIndexState.fevirIndexEntry} parentElement={""} />
            }
            <br /><br />
            {adminState.errorsFromServer && <><h4 style={{ paddingTop: "0px", marginTop: "0px" }}>Errors</h4><ErrorsTable errorsFromServer={adminState.errorsFromServer} tableHeaderStyle={errorsTableHeaderStyle} tableCellStyle={errorsTableCellStyle} /></>}
            <br />
            {adminState.feedbackFromServer && <><h4 style={{ paddingTop: "0px", marginTop: "0px" }}>Feedback</h4><FeedbackTable feedbackFromServer={adminState.feedbackFromServer} tableHeaderStyle={feedbackTableHeaderStyle} tableCellStyle={feedbackTableCellStyle} /></>}
            <br />
            {adminState.sevcoTermProgress && <><h4 style={{ paddingTop: "0px", marginTop: "0px" }}>SEVCO terms progress</h4><CodeSystemTermProgressTable termProgress={adminState.sevcoTermProgress} tableHeaderStyle={sevcoTableHeaderStyle} tableCellStyle={sevcoTableCellStyle} totalText={"SEVCO (total)"} /></>}
            <h4 style={{ paddingTop: "0px" }}>SEVCO Expert Working Group userlist</h4>
            <UserListTable userList={adminState.sevcoExpertWorkingGroupUsers} tableHeaderStyle={sevcoTableHeaderStyle} tableCellStyle={sevcoTableCellStyle} />
            <br />
            {adminState.gradeTermProgress && <><h4 style={{ paddingTop: "18px" }}>GRADE terms progress</h4><CodeSystemTermProgressTable termProgress={adminState.gradeTermProgress} tableHeaderStyle={gradeTableHeaderStyle} tableCellStyle={gradeTableCellStyle} totalText={"GRADE (total)"} /></>}
            <h4 style={{ paddingTop: "18px" }}>GRADE Expert Working Group userlist</h4>
            <UserListTable userList={adminState.gradeExpertWorkingGroupUsers} tableHeaderStyle={gradeTableHeaderStyle} tableCellStyle={gradeTableCellStyle} />
            <br />
            <br />
            <br />
            <h4 style={{ paddingTop: "18px" }}>RoBATUR Pilot Study Participant Enrollment Group</h4>
            {adminState.showROBATusers === false ?
              <Button style={{ color: "#FFFFFF", width: "360px", float: "left" }} className="formButton" content="Show RoBATUR Enrolled Users" positive
                onClick={() => {
                  setAdminState(prevState => { return { ...prevState, showROBATusers: true }; });
                }}
              />
              :
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={researchStudyHeaderStyle}>RS #</Table.HeaderCell>
                    <Table.HeaderCell style={researchStudyHeaderStyle}>User ID</Table.HeaderCell>
                    <Table.HeaderCell style={researchStudyHeaderStyle}>Name</Table.HeaderCell>
                    <Table.HeaderCell style={researchStudyHeaderStyle}>Email</Table.HeaderCell>
                    <Table.HeaderCell style={researchStudyHeaderStyle}>Inactive</Table.HeaderCell>
                    <Table.HeaderCell style={researchStudyHeaderStyle}>Duplicate</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {adminState.enrollmentGroupUsersInfo?.map((user, userIndex) => {
                    let fontColor = "#000000";
                    let cellStyle = researchStudyCellStyle;
                    if (user.inactive) {
                      cellStyle = { backgroundColor: "#FFF0F0", color: "#FF0000" };
                    } else {
                      cellStyle["backgroundColor"] = "#FFFFFF";
                    }

                    return <Table.Row key={userIndex} style={{ color: fontColor }}>
                      <Table.Cell style={cellStyle}>{user.researchSubjectFoi}</Table.Cell>
                      <Table.Cell style={cellStyle}>{user.userid}</Table.Cell>
                      <Table.Cell style={cellStyle}>{user.displayname}</Table.Cell>
                      <Table.Cell style={cellStyle}>{user.notificationemail && <a href={"mailto:" + user.notificationemail} target="_blank" rel="noopener noreferrer">{user.notificationemail}</a>}</Table.Cell>
                      <Table.Cell style={cellStyle}>{user.inactive.toString()}</Table.Cell>
                      <Table.Cell style={cellStyle}>{user.duplicate ? <b>TRUE</b> : <>false</>}</Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
              </Table>
            }
          </div>
          :
          <div></div>
        }
        <br />
        <br />
        <br />
        <br />
        <TextField className="inputField" type='text' label={'Project Ids'} size="small" variant='outlined' value={projectIdsState} onChange={(e) => { setProjectIdsState(e.target.value); }} />
        &nbsp;&nbsp;&nbsp;
        <Button style={{ color: "#000000" }} className="formButton builderButton" content="Update Projects' Evidence Resources"
          onClick={() => { updateProjectsEvidenceResources(projectIdsState); }}
        />
        <br />
        <br />
        <br />
        <Button style={{ color: "#000000" }} className="formButton builderButton" content="Get Spreadsheet Report of CT.gov Mapping"
          onClick={() => { getSpreadsheetReportCTGovMapping(["ClinicalTrials.gov Study.ProtocolSection", "ClinicalTrials.gov Study.ResultsSection"], "CTgov Mapping Report"); }}
        />
        &nbsp;&nbsp;&nbsp;
        <Button style={{ color: "#000000" }} className="formButton builderButton" content="Get Spreadsheet Report of Beta.CT.gov Mapping"
          onClick={() => { getSpreadsheetReportCTGovMapping(["Beta.ClinicalTrials.gov"], "Beta.CTgov Mapping Report"); }}
        />
        {/*<a href={"data:text/csv;charset=utf-8,'"+getSpreadsheetReportCTGovMapping()+"'"} download="filename.csv">download</a>*/}
        <br />
        <br />
      </Segment>
    </div>
  );
};

export default AdminPage;