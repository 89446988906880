import React, { useState, useEffect, memo } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import submitToFevirServer from './SubmitToFevirServer';

const getSearchResultsForReferenceEntry = async (searchQueryString, selectedResourceType, allowedResourceTypes, globalContext) => {
  const body = {
    'functionid': "getsearchresults",
    'idToken': "",
    'contextInfo': { "referenceEntry": true },
    'selectedResourceType': selectedResourceType,
    'allowedResourceTypes': allowedResourceTypes,
    'searchQueryString': searchQueryString
  };
  let response = await submitToFevirServer(globalContext, 5000, body, true, false);
  let searchResults;
  if (response?.success) {
    searchResults = response.searchResults;
  }
  return searchResults;
}

const SearchResourcesToAddToProjectModal = memo(({ globalContext, setSelectResourceFromSearchState }) => {

  let startingModalState = {
    "modalOpen": true, "searchQueryString": "", "searchResults": "", "selectedResource": ""
  }

  const [modalState, setModalState] = useState(startingModalState);

  const deliverSearchResults = async () => {
    if (modalState.modalOpen && modalState.searchQueryString && modalState.searchQueryCompleted &&
      !modalState.searchResultsDelivered) {
      let searchResults = await getSearchResultsForReferenceEntry(modalState.searchQueryString, null, null, globalContext);
      setModalState(prevState => { return { ...prevState, "searchResults": searchResults, "searchResultsDelivered": true } })
    }
  };

  useEffect((() => {
    deliverSearchResults();
  }), [modalState])

  const selectTheResource = (targetfoi) => {
    setSelectResourceFromSearchState(prevState => { return { ...prevState, "selectedResourceReference": targetfoi }; });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setModalState(prevState => { return { ...prevState, modalOpen: false }; });
        setSelectResourceFromSearchState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Enter search string</h3>
    <TextField style={{ width: "100%", marginTop: "16px" }}
      className="inputField" type='text' label={"Enter search string"} size="small" variant='outlined'
      value={modalState.searchQueryString || ""} onKeyUp={(event) => {
        if (event.key === 'Enter') {
          setModalState(prevState => { return { ...prevState, "searchQueryCompleted": true, "searchResultsDelivered": false } });
        }
      }}
      onChange={(e) => { setModalState(prevState => { return { ...prevState, "searchQueryString": e.target.value } }) }} />
    {modalState.searchResults && <>
      <h3>Select a resource</h3>
      {modalState.searchResults.length > 0
        ?
        <>
          {modalState.searchResults.map((result, resultIndex) => {
            return <p key={resultIndex}>
              <span onClick={() => { selectTheResource(result.targetfoi); }} >{result.display}</span>
              &nbsp;&nbsp;
              <a href={result.onClick} >View</a>
            </p>
          })}
        </>
        :
        <>No results found.</>}
    </>
    }
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={modalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
})


export { SearchResourcesToAddToProjectModal };