import React from 'react';
import { Segment } from 'semantic-ui-react';
import CreateNewRiskOfBiasAssessmentForm from './CreateNewRiskOfBiasAssessmentForm';

const CreateNewRiskOfBiasAssessmentPage = ( { useTitle, fhirJson, changeFormState, ratedResourceType, ratedResourceId, associatedProjectFoi, getResource } ) => {
  
  useTitle("FEvIR Risk of Bias Assessment Tool");

  return (
    <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <CreateNewRiskOfBiasAssessmentForm editExistingAssessment={false} fhirJson={fhirJson} changeFormState={changeFormState} 
      ratedResourceType={ratedResourceType} ratedResourceId={ratedResourceId} associatedProjectFoi={associatedProjectFoi} 
      getResource={getResource} />
    </Segment>
    </div>
  );
};

  export default CreateNewRiskOfBiasAssessmentPage;