import React, { useState, useEffect } from 'react';
import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector } from './DataEntryFormFunctions';

const libraryDotTypeValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/library-type", code: "logic-library", display: "Logic Library" },
  { system: "http://terminology.hl7.org/CodeSystem/library-type", code: "model-definition", display: "Model Definition" },
  { system: "http://terminology.hl7.org/CodeSystem/library-type", code: "asset-collection", display: "Asset Collection" },
  { system: "http://terminology.hl7.org/CodeSystem/library-type", code: "module-definition", display: "Module Definition" }
];
const activityDefinitionDotSubjectReferenceResourceTypes = ['Group'];
const activityDefinitionDotSubjectCodeableConceptValueSet = [
  { system: "http://hl7.org/fhir/fhir-types", code: "CareTeam", display: "CareTeam" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Device", display: "Device" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Group", display: "Group" },
  { system: "http://hl7.org/fhir/fhir-types", code: "HealthcareService", display: "HealthcareService" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Location", display: "Location" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Organization", display: "Organization" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Patient", display: "Patient" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Practitioner", display: "Practitioner" },
  { system: "http://hl7.org/fhir/fhir-types", code: "PractitionerRole", display: "PractitionerRole" },
  { system: "http://hl7.org/fhir/fhir-types", code: "RelatedPerson", display: "RelatedPerson" }
];
const activityDefinitionDotSubjectAllowedDatatypes = ['CodeableConcept', 'Reference'];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.subtitle && typeof resource.type === "string") {
      innerDiv += "<p><b>Subtitle: </b>" + resource.subtitle + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.type) {
      innerDiv += "<p><b>Type: </b>" + getStringFromFHIR.CodeableConcept(resource.type) + "</p><br/>";
    }
    if (resource.subjectCodeableConcept) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.CodeableConcept(resource.subjectCodeableConcept) + "</p><br/>";
    }
    if (resource.subjectReference) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.Reference(resource.subjectReference) + "</p><br/>";
    }
    if (resource.relatedArtifact?.length > 0) {
      innerDiv += resource.relatedArtifact.map((item) => {
        let type = item.type || "";
        let display = item.display || "";
        let citation = item.citation || "";
        let resource = item.resource || "";
        let resourceReference = getStringFromFHIR.Reference(item.resourceReference) || "";
        return "<p><b>Item (" + type + "): </b>" + display + " " + citation + " " + resource + " " + resourceReference + "</p><br/>"
      }).join("");
    }
    if (resource.content?.length > 0) {
      innerDiv += resource.content.map((item) => {
        let title = item.title || "";
        let url = item.url || "";
        return "<p><b>Item: </b>" + title + " " + url + "</p><br/>"
      }).join("");
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const LibraryBuilder = ({ resourceState, setResourceState }) => {

  let startingSubjectDatatype = 'none';
  if (resourceState.subjectCodeableConcept) { startingSubjectDatatype = 'CodeableConcept'; }
  if (resourceState.subjectReference) { startingSubjectDatatype = 'Reference'; }

  const [subjectDatatypeState, setSubjectDatatypeState] = useState(startingSubjectDatatype);

  useEffect((() => {
    if (subjectDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null } })
    }
    if (subjectDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'subjectCodeableConcept': null } })
    }
  }), [subjectDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='subtitle' fieldLabel='Subtitle'
          startingValue={resourceState.subtitle} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
      </div>
      <h3 id="subject">Subject</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startCollapsed
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
          valueSet={libraryDotTypeValueSet} startCollapsed
          startingValue={resourceState.type} setResourceState={setResourceState} />
        <p><b>Subject:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='subject[x]' allowedDatatypes={activityDefinitionDotSubjectAllowedDatatypes}
            datatypeState={subjectDatatypeState} setDatatypeState={setSubjectDatatypeState} />
          {(subjectDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='subjectCodeableConcept'
              fieldLabel='Subject (as CodeableConcept)' 
              startingValue={resourceState.subjectCodeableConcept || null}
              startCollapsed
              valueSet={activityDefinitionDotSubjectCodeableConceptValueSet} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='subjectReference' fieldLabel='Subject (as Reference)'
              referencedResourceTypes={activityDefinitionDotSubjectReferenceResourceTypes}
              startCollapsed
              startingValue={resourceState.subjectReference || null} setResourceState={setResourceState} />}
        </div>
      </div>
      <h3 id="content">Content</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='RelatedArtifact' elementName='relatedArtifact'
          allowedTypeValues="FHIR" startCollapsed
          fieldLabel='Item (as Related Artifact)' startingValue={resourceState.relatedArtifact} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Attachment' elementName='content' startCollapsed
          fieldLabel='Item (as Attachment)' startingValue={resourceState.content} setResourceState={setResourceState} />
      </div>
      <h3 id="parameters">Parameters</h3>
      <div style={{ marginLeft: "24px" }}>
        <p>Data entry interface not created for parameter or dataRequirement elements.</p>
        {Array.isArray(resourceState.parameter) &&
          resourceState.parameter.map((parameter, parameterIndex) => {
            return <div key={parameterIndex}><b>Parameter: </b><DisplayFromFHIR parameterDefinition={parameter} /></div>
          })}
        {Array.isArray(resourceState.dataRequirement) &&
          resourceState.dataRequirement.map((dataRequirement, dataRequirementIndex) => {
            return <div key={dataRequirementIndex}><b>Data Requirement: </b><DisplayFromFHIR dataRequirement={dataRequirement} /></div>
          })}
      </div>
    </div>
  </div>
}

export default LibraryBuilder;
