import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';
import './App.css';

const NetEffectCalculatorPage = ({ useTitle }) => {
  useTitle("FEvIR Net Effect Calculator");

  const history = useHistory();

  useEffect(() => {
    history.replace(`/effect-calculator`);
  }, [])

  //let headerStyle = {backgroundColor: "#D1D7EF"};
  //let cellStyle = {backgroundColor: "#ECF4FC"};
  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <p>Placeholder</p>
      <div>
        React Input and Output here
      </div>
     </Segment>
  </div>

};

export default NetEffectCalculatorPage;