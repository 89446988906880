import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import React from 'react';
import { AssociatedResourcesDisplay, getStringFromFHIR } from './ResourceFunctions';

const SectionDisplay = ({ sect, sectIndex }) => {
  if (!sect || !Object.keys(sect).length) {
    return <div key={sectIndex} >
      <br />
      <p><b>{'Section ' + (sectIndex + 1).toString()}:</b></p>
      <>No content in this section.</>
    </div>
  }
  return <div key={sectIndex} >
    <br />
    <p><b>{sect.title ? sect.title : 'Section ' + (sectIndex + 1).toString()}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {(sect.code && Object.keys(sect.code).length > 0) && <span><b>Code: </b><DisplayFromFHIR codeableConcept={sect.code} /><br /></span>}
      {(sect.focus && Object.keys(sect.focus).length > 0) && <span><b>Focus: </b><DisplayFromFHIR reference={sect.focus} /><br /></span>}
      {(Array.isArray(sect.author) && sect.author.length > 0) &&
        sect.author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(sect.text && Object.keys(sect.text).length > 0) && <div>
        {sect.text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={sect.text.div} />
            </div>
          </div>
        }
        <br />
      </div>}
      {(sect.orderedBy && Object.keys(sect.orderedBy).length > 0) && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={sect.orderedBy} /><br /></span>}
      {(Array.isArray(sect.entry) && sect.entry.length > 0) &&
        sect.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {(sect.emptyReason && Object.keys(sect.emptyReason).length > 0) && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={sect.emptyReason} /><br /></span>}
      {(Array.isArray(sect.section) && sect.section.length > 0) &&
        sect.section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}><SectionDisplay sect={subsection} sectIndex={subsectionIndex} /></span>;
        })}
    </div>
  </div>
}

const EvidenceReportPackageSectionDisplay = ({ topSection }) => {

  if (!topSection || !Object.keys(topSection).length) {
    return <>No content in this section.</>
  }

  let title;
  if (topSection.title) {
    title = topSection.title;
  }
  let code;
  if (topSection.code && Object.keys(topSection.code).length > 0) {
    code = topSection.code;
  }
  let author;
  if (Array.isArray(topSection.author) && topSection.author.length > 0) {
    author = topSection.author
  }
  let focus;
  if (topSection.focus && Object.keys(topSection.focus).length > 0) {
    focus = topSection.focus;
  }
  let text;
  if (topSection.text && Object.keys(topSection.text).length > 0) {
    text = topSection.text;
  }
  let orderedBy;
  if (topSection.orderedBy && Object.keys(topSection.orderedBy).length > 0) {
    orderedBy = topSection.orderedBy;
  }
  let entry;
  if (Array.isArray(topSection.entry) && topSection.entry.length > 0) {
    entry = topSection.entry
  }
  let emptyReason;
  if (topSection.emptyReason && Object.keys(topSection.emptyReason).length > 0) {
    emptyReason = topSection.emptyReason;
  }
  let section;
  if (Array.isArray(topSection.section) && topSection.section.length > 0) {
    section = topSection.section
  }
  let titleDisplay = title || getStringFromFHIR.CodeableConcept(code);

  return <div>
    <p><b>{titleDisplay}:</b></p>
    <div style={{ marginLeft: "24px" }}>
      {focus && <span><b>Focus: </b><DisplayFromFHIR reference={focus} /><br /></span>}
      {(Array.isArray(author) && author.length > 0) &&
        author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {(text && Object.keys(text).length > 0) && <div>
        {text.status === "empty" ?
          <></>
          :
          <div>
            <b>Narrative Summary: </b>
            <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
              <DisplayFromFHIR xhtml={text.div} />
            </div>
          </div>}
        <br />
      </div>}
      {orderedBy && <span><b>Ordered by: </b><DisplayFromFHIR codeableConcept={orderedBy} /><br /></span>}
      {(Array.isArray(entry) && entry.length > 0) &&
        entry.map((entry, entryIndex) => {
          return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
        })}
      {emptyReason && <span><b>Empty Reason: </b><DisplayFromFHIR codeableConcept={emptyReason} /><br /></span>}
      {(Array.isArray(section) && section.length > 0) &&
        section.map((subsection, subsectionIndex) => {
          return <span key={subsectionIndex}>
            <SectionDisplay sect={subsection} sectIndex={subsectionIndex} />
          </span>;
        })}
    </div>
  </div>
}

const EvidenceReportPackageSectionView = ({ fhirJson, reportType, additionalSections }) => {
  let description;
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === 'http://hl7.org/fhir/StructureDefinition/artifact-description') {
        description = extension.valueMarkdown;
      }
    }
  }

  let introductionSection = {};
  let discussionSection = {};
  let methodsSection = {};
  let referencesSection = {};
  let competingInterestsSection = {};
  let acknowlegementsSection = {};
  let appendicesSection = {};
  if (Array.isArray(fhirJson?.section)) {
    for (const section of fhirJson.section) {
      let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
      if (sectionCode === "introduction" || sectionCode === "Introduction") {
        introductionSection = section;
      } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
        discussionSection = section;
      } else if (sectionCode === "methods" || sectionCode === "Methods") {
        methodsSection = section;
      } else if (sectionCode === "references" || sectionCode === "References") {
        referencesSection = section;
      } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
        competingInterestsSection = section;
      } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
        acknowlegementsSection = section;
      } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
        appendicesSection = section;
      }
    }
  }

  return <div>
    <h3 id="introduction">Introduction</h3>
    <div style={{ marginLeft: "24px" }}>
      <p><b>{reportType} Title: </b>
        {fhirJson.title ? fhirJson.title : fhirJson.name ? fhirJson.name : "No title provided."}
      </p>
      {description &&
        <div><b>{reportType} Description: </b><DisplayFromFHIR markdown={description} />
        </div>}
      {Array.isArray(fhirJson.subject) &&
        fhirJson.subject.map((subject, subjectIndex) => {
          return <div key={subjectIndex}><b>Subject: </b><DisplayFromFHIR reference={subject} /></div>;
        })}
      {Array.isArray(fhirJson.category) &&
        fhirJson.category.map((subject, subjectIndex) => {
          return <div key={subjectIndex}><b>Category: </b><DisplayFromFHIR codeableConcept={subject} /></div>;
        })}
      <EvidenceReportPackageSectionDisplay topSection={introductionSection} />
    </div>
    <br />
    <h3 id="discussion">Discussion</h3>
    <div style={{ marginLeft: "24px" }}>
      <EvidenceReportPackageSectionDisplay topSection={discussionSection} />
      {additionalSections?.length > 0 && additionalSections.map((section, sectionIndex) => {
        return <div key={sectionIndex} >
          <EvidenceReportPackageSectionDisplay topSection={section} />
        </div>
      })}
    </div>
    <br />
    <h3 id="methods">Methods</h3>
    <div style={{ marginLeft: "24px" }}>
      <EvidenceReportPackageSectionDisplay topSection={methodsSection} />
    </div>
    <br />
    <h3 id="references">References</h3>
    <div style={{ marginLeft: "24px" }}>
      <EvidenceReportPackageSectionDisplay topSection={referencesSection} />
    </div>
    <br />
    <h3 id="competing-interests">Competing Interests</h3>
    <div style={{ marginLeft: "24px" }}>
      <EvidenceReportPackageSectionDisplay topSection={competingInterestsSection} />
    </div>
    <br />
    <h3 id="acknowledgements">Acknowledgements</h3>
    <div style={{ marginLeft: "24px" }}>
      <EvidenceReportPackageSectionDisplay topSection={acknowlegementsSection} />
    </div>
    <br />
    <h3 id="appendices">Appendices</h3>
    <div style={{ marginLeft: "24px" }}>
      <EvidenceReportPackageSectionDisplay topSection={appendicesSection} />
    </div>
    <br />
  </div>
};

const EvidenceReportPackageViewerTool = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  let howToCite;
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
        break;
      }
    }
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <EvidenceReportPackageSectionView fhirJson={fhirJson} reportType="Report" />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="associated-resources">Associated Resources</h3>
      <div style={{ marginLeft: "24px" }}>
        <AssociatedResourcesDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export { EvidenceReportPackageSectionDisplay, EvidenceReportPackageViewerTool };