import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';
import './App.css';
import FevirContext from './FevirContext';

const TestPage = ({ useTitle }) => {
  useTitle("FEvIR Test Page");
  const globalContext = useContext(FevirContext);
  let userState = globalContext.userState;
  const [testPageState, setTestPageState] = useState({});

  const history = useHistory();

  useEffect(async () => {
  }, [userState]);

  let headerStyle = {backgroundColor: "#D1D7EF"};
  let cellStyle = {backgroundColor: "#ECF4FC"};
  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <h2>This page is for testing FEvIR functionality.</h2>
     </Segment>
  </div>

};

export default TestPage;