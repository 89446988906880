import React from 'react';
import { Button } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import './App.css';
import { changeSimpleStateWithCheck } from './ResourceFunctions';

const EditUserProfileTextFields = ( { firebaseConfig, userState, setUserState, userProfileState, setUserProfileState } ) => {
  
  const editAffiliationToState = (index, newText) => {
    setUserState(prevState  => {
      let prevAffiliations = [...prevState["affiliations"]];
      prevAffiliations[index] = newText;
      return {
        ...prevState,
        affiliations: prevAffiliations,
      };
    });
  };

  const deleteAffiliationFromState = (index) => {
    setUserState(prevState  => {
      let prevAffiliations = [...prevState["affiliations"]];
      prevAffiliations.splice(index, 1);
      return {
        ...prevState,
        affiliations: prevAffiliations,
      };
    });
  };
  
  const addAffiliationToState = () => {
    setUserState(prevState  => {
      let prevAffiliations = [...prevState["affiliations"]];
      prevAffiliations.push("");
      return {
        ...prevState,
        affiliations: prevAffiliations,
      };
    });
  };

  const affiliations = userState.affiliations;
  const affiliationInputs = affiliations.map((affiliation, index) => {
    return <span key={index}>
        <TextField style={{width: "400px"}} key={index} className="inputField" type='text' label={'Affiliation ' + (index+1)} size="small" variant='outlined' value={affiliation} onChange={(e) => { editAffiliationToState(index, e.target.value) } } autoFocus={!userState.startLoad} />
        &nbsp;&nbsp;&nbsp;<Button className="formButton negative" content="✖" compact onClick={() => { deleteAffiliationFromState(index) } } />
        <br /><br />
    </span>
  });

  return (
    <>
      <h3>Edit User Profile {userState.name && <>({userState.name})</>}</h3>
      <span style={{marginLeft: "20px"}}>
        <br />
        <TextField style={{width: "400px"}} className="inputField" type='text' label='Display Name' size="small" variant='outlined' value={userState.name || ''} onChange={(e) => { changeSimpleStateWithCheck(userState, setUserState, e.target.value, "name"); } } />
        <br /><br />
        <TextField style={{width: "460px"}} className="inputField" type='text' label='Email for Notifications' size="small" variant='outlined' value={userState.notificationemail || ''} onChange={(e) => { changeSimpleStateWithCheck(userState, setUserState, e.target.value, "notificationemail"); } } />
        <br /><br />
        <TextField style={{width: "460px"}} className="inputField" type='text' label='Backup Email' size="small" variant='outlined' value={userState.additionalemail || ''} onChange={(e) => { changeSimpleStateWithCheck(userState, setUserState, e.target.value, "additionalemail"); } } />
        <br /><br />
        <TextField style={{width: "460px"}} className="inputField" type='text' label='Personal Tagline' size="small" variant='outlined' value={userState.shortbio || ''} onChange={(e) => { changeSimpleStateWithCheck(userState, setUserState, e.target.value, "shortbio"); } } />
        <br /><br />
        {affiliationInputs}
        <span style={{height: "0px"}}> </span>
        <span style={{paddingLeft: "320px"}}>
          <Button className="formButton" style={{color: "#000000"}} content="+ Add Another Affiliation" compact onClick={() => { changeSimpleStateWithCheck(userState, setUserState, false, "startLoad"); addAffiliationToState(setUserState);}} />
        </span>
      </span>
    </>
  );
};

  export default EditUserProfileTextFields;