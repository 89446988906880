import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const CommentsDisplay = ({ commentArray }) => {
  if (Array.isArray(commentArray) && commentArray.length > 0) {
    return <div>{commentArray.map((content, contentIndex) => {
      return <div key={contentIndex}>
        <p><b>Comment {contentIndex + 1}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          {content.path && <><p><b>Specific to: </b>{content.path.toString()}</p></>}
          {content.summary && <DisplayFromFHIR markdown={content.summary} />}
          {content.freeToShare === true && <p>Free to share.</p>}
          {content.freeToShare === false && <p>NOT free to share.</p>}
        </div>
      </div>
    })}</div>
  } else {
    return <></>
  }
};

const CommentDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {
  let howToCite;
  if (fhirJson.citeAsMarkdown) {
    howToCite = fhirJson.citeAsMarkdown;
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="comment-on">Comment On</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.artifactReference && <DisplayFromFHIR reference={fhirJson.artifactReference} />}
        {fhirJson.artifactCanonical && <DisplayFromFHIR uri={fhirJson.artifactCanonical} />}
        {fhirJson.artifactUri && <DisplayFromFHIR uri={fhirJson.artifactUri} />}
      </div>
      <h3 id="comments">Comments</h3>
      <CommentsDisplay commentArray={fhirJson.content} />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default CommentDisplay;