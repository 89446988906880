import { jsonErrorCheck2 } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import { deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';

const libraryTextViewChangesToJson = (fhirJson, libraryState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = libraryState.meta;
    fhirJson.language = libraryState.language;
    fhirJson.text = libraryState.text;
    fhirJson.contained = libraryState.contained || "DELETEME";
    fhirJson.extension = libraryState.extension || "DELETEME";
    fhirJson.modifierExtension = libraryState.modifierExtension || "DELETEME";
    fhirJson.url = libraryState.url || "DELETEME";
    fhirJson.identifier = libraryState.identifier || "DELETEME";
    fhirJson.version = libraryState.version || "DELETEME";
    fhirJson.versionAlgorithmString = libraryState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = libraryState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = libraryState.name || "DELETEME";
    fhirJson.title = libraryState.title || "DELETEME";
    fhirJson.subtitle = libraryState.subtitle || "DELETEME";
    fhirJson.status = libraryState.status || "unknown";
    fhirJson.experimental = libraryState.experimental ?? "DELETEME";
    fhirJson.type = libraryState.type || "DELETEME";
    fhirJson.subjectCodeableConcept = libraryState.subjectCodeableConcept || "DELETEME";
    fhirJson.subjectReference = libraryState.subjectReference || "DELETEME";
    fhirJson.date = libraryState.date || "DELETEME";
    fhirJson.publisher = libraryState.publisher || "DELETEME";
    fhirJson.contact = libraryState.contact || "DELETEME";
    fhirJson.description = libraryState.description || "DELETEME";
    fhirJson.useContext = libraryState.useContext || "DELETEME";
    fhirJson.jurisdiction = libraryState.jurisdiction || "DELETEME";
    fhirJson.purpose = libraryState.purpose || "DELETEME";
    fhirJson.usage = libraryState.usage || "DELETEME";
    fhirJson.copyright = libraryState.copyright || "DELETEME";
    fhirJson.copyrightLabel = libraryState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = libraryState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = libraryState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = libraryState.effectivePeriod || "DELETEME";
    fhirJson.topic = libraryState.topic || "DELETEME";
    fhirJson.author = libraryState.author || "DELETEME";
    fhirJson.editor = libraryState.editor || "DELETEME";
    fhirJson.reviewer = libraryState.reviewer || "DELETEME"
    fhirJson.endorser = libraryState.endorser || "DELETEME";
    fhirJson.relatedArtifact = libraryState.relatedArtifact || "DELETEME";
    fhirJson.parameter = libraryState.parameter || "DELETEME";
    fhirJson.dataRequirement = libraryState.dataRequirement || "DELETEME";
    fhirJson.content = libraryState.content || "DELETEME";
    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (libraryState) => {
    let contentArray = libraryState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "Library";
    let classifiedFoi = libraryState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = libraryState.title || libraryState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateLibraryJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let libraryState = formInputsStateRef.current.libraryState;

    let fhirJson = jsonErrorCheck2("Library", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = libraryTextViewChangesToJson(fhirJson, libraryState);
        fhirJson = jsonErrorCheck2("Library", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(libraryState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateLibraryJson };