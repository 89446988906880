import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';


const recommendationJustificationTextViewChangesToJson = (fhirJson, recommendationJustificationState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.resourceType = "ArtifactAssessment";
  fhirJson.meta = recommendationJustificationState.meta;
  fhirJson.language = recommendationJustificationState.language;
  fhirJson.text = recommendationJustificationState.text;
  fhirJson.contained = recommendationJustificationState.contained || "DELETEME";
  fhirJson.extension = recommendationJustificationState.extension || "DELETEME";
  fhirJson.modifierExtension = recommendationJustificationState.modifierExtension || "DELETEME";
  fhirJson.language = recommendationJustificationState.language || "DELETEME";
  fhirJson.identifier = recommendationJustificationState.identifier || "DELETEME";
  fhirJson.title = recommendationJustificationState.title || "DELETEME";
  fhirJson.date = recommendationJustificationState.date || "DELETEME";
  fhirJson.copyright = recommendationJustificationState.copyright || "DELETEME";
  fhirJson.approvalDate = recommendationJustificationState.approvalDate || "DELETEME";
  fhirJson.lastReviewDate = recommendationJustificationState.lastReviewDate || "DELETEME";
  fhirJson.artifactReference = recommendationJustificationState.artifactReference || "DELETEME";
  fhirJson.artifactCanonical = recommendationJustificationState.artifactCanonical || "DELETEME";
  fhirJson.artifactUri = recommendationJustificationState.artifactUri || "DELETEME";
  fhirJson.content = recommendationJustificationState.content || "DELETEME"

  deleteEmptyElementsInObjectRecursive(fhirJson);
  let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
  fhirJson = fhirJsonWithCiteAs;
  return fhirJson;
}

const createNewClassificationJson = (recommendationJustificationState) => {
  let contentArray = recommendationJustificationState.newClassifications;
  if (!contentArray) {
    return "";
  }
  let classifiedResourceType = "ArtifactAssessment";
  let classifiedFoi = recommendationJustificationState.id;
  let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
  let classifedTitle = recommendationJustificationState.title || "[Untitled.]";
  let classificationJson = {
    "resourceType": "ArtifactAssessment",
    "artifactReference": {
      "reference": classifiedReference,
      "type": classifiedResourceType,
      "display": classifedTitle
    },
    "content": contentArray,
    "meta": {
      "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
    }
  };
  return classificationJson;
}

const builderUpdateRecommendationJustificationJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let recommendationJustificationState = formInputsStateRef.current.recommendationJustificationState;

  let fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }


  if (fhirEntryState.activeIndex == 0) {
    fhirJson = recommendationJustificationTextViewChangesToJson(fhirJson, recommendationJustificationState);
    fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  let newClassificationJson = createNewClassificationJson(recommendationJustificationState);

  return [fhirJson, newClassificationJson];
}

export { builderUpdateRecommendationJustificationJson }