import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import axios from 'axios';
import server_config from './ServerConfiguration';
const node_server_address = server_config["node_server_address"];
const go_server_address = server_config["go_server_address"];
//const ports = server_config["ports"];
//const server_address = server_config["address"];

const submitContentToServer = async (timeout, body, idToken, useGoServer) => {
    let response = {'success': false};
    if (idToken) {
        body['idToken'] = idToken;
    }
    response = await axios({
        method: 'POST',
        //url: server_address + ':' + ports["master_server"] + '/',
        url: useGoServer ? go_server_address : node_server_address,
        timeout: timeout,
        data: body,
    }).then(async (resp) => {
        return resp.data;
    }).catch(async (error) => {
        console.log(error);
        console.log(body);
    });
    return response;
}

const submitToFevirServer = async (globalContext, timeout, body, runOnFail, useGoServer) => {
    let response = {'success': false};
    let success = false;
    let noId = true;
    let idToken = "";
    let storedIdToken = "";
    let refreshToken = "";
    let userState = globalContext.userState;
    let setUserState = globalContext.setUserState;
    let firebaseConfig = globalContext.firebaseConfig;
    let continuedSessionLogin = globalContext.continuedSessionLogin;
    if (userState?.id) {
        noId = false;
        if (userState.idToken) {
            idToken = userState.idToken;
            //storedIdToken = idToken;
        } else {
            try {
                let user = firebase.auth().currentUser;
                await user.getIdToken(true).then( async (retrievedIdToken) => { storedIdToken = retrievedIdToken });
                refreshToken = user.refreshToken;
            } catch (e) { }
        }

        let idTokenToSend = idToken || storedIdToken || refreshToken;
        if (idToken !== "" || storedIdToken !== "" || refreshToken !== "") {
            const verifyBody = {
                'functionid': 'verifyuserid',
                'idToken': idTokenToSend,
            };
            await axios({
                method: 'POST',
                //url: server_address + ':' + ports["master_server"] + '/',
                //url: node_server_address,
                url: go_server_address,
                timeout: 60000,
                data: verifyBody
            }).then(async (resp) => {
                if (resp.data) {
                    success = resp.data.success;
                    //let newIdToken = resp.data.idtoken;
                    let newIdToken = idTokenToSend;
                    if (success) {
                        if (newIdToken && idToken !== newIdToken) {
                            idToken = newIdToken;
                        }
                    } else if (refreshToken) {
                        let refreshBody = {
                            'grant_type': 'refresh_token',
                            'refresh_token': refreshToken
                          };
                          try {
                            await axios({
                              method: 'POST',
                              url: 'https://securetoken.googleapis.com/v1/token?key=' + firebaseConfig['apiKey'],
                              timeout: 6000,
                              data: refreshBody,
                            }).then(async (refreshResp) => {
                                success = true;
                                newIdToken = refreshResp.data.access_token;
                             })
                          } catch (e) { }
                    }
                }
            })
            //.catch(async (error) => { });
        }
        if (success === false) {
            let continuedResponse = await continuedSessionLogin(globalContext, refreshToken, undefined);
            if (continuedResponse) {
                idToken = continuedResponse;
                success = true;
            }
        }
    }
    if (idToken && userState.idToken !== idToken) {
        setUserState(prevState  => {
            return {
            ...prevState,
            idToken: idToken,
            };
        });
    }
    if (success === false && noId === false && runOnFail === false) {
        alert("Could not verify user ID. Please manually backup any changes you made, like copying the JSON, and then refresh the page. Please send an email to support@computablepublishing.com telling us of this issue. Thank you.")
    } else {
        response = await submitContentToServer(timeout, body, idToken, useGoServer);
    }
    return response;
}

export default submitToFevirServer;