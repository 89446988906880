import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';

import scrollTo from './ScrollTo';
import { navigateToAnotherTerm } from './CodeSystemFunctions';

const bulletPoints = ['◉', '⦾', '■', '○', '♦', '◘', '•'];

let separatorCharacter = ": ";

const NavigationCodeSystemTermSegment = ({ resourceId, fhirEntryState, setFhirEntryState, formInputsStateRef }) => {

    useEffect(async () => {
        if (fhirEntryState.initialScrolled !== true && fhirEntryState.editMode !== true) {
            let scrollToId = "termdetail-navigation-code-" + fhirEntryState.conceptCode;
            if (fhirEntryState.conceptPathIndexes?.length === 1 && parseInt(fhirEntryState.conceptPathIndexes[0]) === 0) {
                scrollToId = ""; //So it doesn't scroll for the top-most element
            }
            scrollTo("navigationTermDetailSegment", scrollToId, true);
            setFhirEntryState(prevState => { return { ...prevState, initialScrolled: true } })
        }
    }, [fhirEntryState]);


    const history = useHistory();

    let json;
    if (fhirEntryState.fhirEntryString) {
        json = JSON.parse(fhirEntryState.fhirEntryString);
    }
    const generatePathsLookup = (parentJson, parentPathString, dictionary) => {
        if (parentJson) {
            let separator = "";
            if (parentPathString) {
                separator = separatorCharacter;
            }

            for (let conceptIndex in parentJson.concept) {
                let concept = parentJson.concept[conceptIndex];
                if (concept?.display) {
                    let pathString = parentPathString + separator + concept.display;
                    if (concept.concept) {
                        dictionary[pathString] = { expanded: true };
                        generatePathsLookup(concept, pathString, dictionary);
                    }
                }
            }
        }
        return dictionary
    }

    const [navigationCollapsedState, setNavigationCollapsedState] = useState(generatePathsLookup(json, "", {}));
    const [collapseAllState, setCollapseAllState] = useState(false);

    const NavigationConcepts = ({ parentJson, level, parentPathString, parentPath, parentPathIndexes, setFhirEntryState, formInputsStateRef }) => {
        let hasApprovalProperty = fhirEntryState.hasApprovalProperty;
        let style = { paddingLeft: (level * 10).toString() + "px" };
        let separator = "";
        if (parentPathString) {
            separator = separatorCharacter;
        }
        return <>
        {parentJson?.concept?.map((concept, conceptIndex) => {
            let display = concept.display;
            if (display) {
                let pathString = parentPathString + separator + display;
                let path = [...parentPath, display];
                let pathIndexes = [...parentPathIndexes, conceptIndex];

                let showAsDraft = false;
                if (hasApprovalProperty) {
                    showAsDraft = true;
                    for (let propertyIndex in concept.property) {
                        let property = concept.property[propertyIndex];
                        if (property?.valueString && property.code === "approval") {
                            showAsDraft = false;
                            break;
                        }
                    }
                }

                let linkStyle = {};
                let linkOnClick;
                if (concept && fhirEntryState.conceptCode === concept.code) { // || (fhirEntryState.termChanged && fhirEntryState.conceptName === concept.display)
                    linkStyle = { cursor: "default", color: "#000000", fontWeight: "bold" };
                } else {
                    linkOnClick = () => {
                        navigateToAnotherTerm("Term", history, fhirEntryState, setFhirEntryState, 'CodeSystem', resourceId, json, concept, display, path, pathIndexes, pathString);
                    }
                }
                return <span key={conceptIndex} style={style} 
                id={concept && "termdetail-navigation-code-" + (concept.code || '')}>
                    {bulletPoints[level % bulletPoints.length]}
                    &nbsp;<a style={linkStyle} onClick={linkOnClick}>{display}</a>
                    {showAsDraft && <span style={{ fontSize: "11px" }}> (draft)</span>}
                    {(navigationCollapsedState[pathString] && navigationCollapsedState[pathString]["expanded"] !== undefined) &&
                        <span className={"unselectable"} style={{ cursor: "pointer" }} onClick={() => { setNavigationCollapsedState(prevState => { let prevObj = prevState[pathString]; prevObj["expanded"] = !prevObj["expanded"]; return { ...prevState, [pathString]: prevObj }; }) }}>&nbsp;{navigationCollapsedState[pathString]["expanded"] ? <>▼</> : <>►</>}</span>
                    }
                    <br />
                    {(concept && navigationCollapsedState[pathString] && (navigationCollapsedState[pathString]["expanded"] === undefined || navigationCollapsedState[pathString]["expanded"] === true)) &&
                        <NavigationConcepts parentJson={concept} level={level + 1} parentPathString={pathString} setFhirEntryState={setFhirEntryState} parentPath={path} parentPathIndexes={pathIndexes} formInputsStateRef={formInputsStateRef} />
                    }
                </span>
            } else {
                return <span key={conceptIndex}></span>
            }
        })}
        </>
    }

    const CollapseAllButton = ({}) => {
        return <>{collapseAllState === false && <span
            className={"unselectable"}
            style={{ cursor: "pointer" }}
            onClick={() => {
                setNavigationCollapsedState(prevState => {
                    for (let key in prevState) {
                        prevState[key]["expanded"] = false;
                    }
                    return prevState;
                });
                setCollapseAllState(true);
            }}
            >
            ➖
        </span>}</>
    }

    return <Segment id={`navigationTermDetailSegment`} className={`containerSegment maxNavigationCodeSystemSegment`} style={{ marginTop: "8px", padding: "2px 4px 6px 4px" }} raised>
        <h2 className={"unselectable"} style={{ fontSize: "22px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>Term Navigation</h2>
        <div className={`navigationCodeSystemTermDetailSection`} style={{ paddingTop: "4px" }}>
            <span>
                {fhirEntryState.conceptPath?.length > 0 ?
                    <span onClick={() => {
                        /*
                        setFhirEntryState(prevState  => {
                            return { ...prevState, conceptName: "", conceptCode: "", conceptDefinition: undefined, conceptPath: undefined, conceptPathIndexes: undefined, conceptPathString: undefined, conceptSelected: undefined, newChildConcepts: [], conceptCommentsExpanded: false, comments: [], votes: [], myvotes: [] } }
                        );
                        */
                        navigateToAnotherTerm("Term", history, fhirEntryState, setFhirEntryState, 'CodeSystem', resourceId, json, {}, "", [], [], []);
                    }}>
                        <a><CollapseAllButton /> TOP</a>
                    </span>
                    :
                    <b><CollapseAllButton /> TOP</b>
                }
                <br />
            </span>
            {json && <NavigationConcepts parentJson={json} level={0} parentPathString={""} parentPath={[]} parentPathIndexes={[]} setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} />}
        </div>
    </Segment>
}
export default NavigationCodeSystemTermSegment;