import { jsonErrorCheck2 } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import { deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';

const researchStudyTextViewChangesToJson = (fhirJson, researchStudyState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = researchStudyState.meta;
    fhirJson.language = researchStudyState.language;
    fhirJson.text = researchStudyState.text;
    fhirJson.contained = researchStudyState.contained || "DELETEME";
    fhirJson.extension = researchStudyState.extension || "DELETEME";
    fhirJson.modifierExtension = researchStudyState.modifierExtension || "DELETEME";
    fhirJson.url = researchStudyState.url || "DELETEME";
    fhirJson.identifier = researchStudyState.identifier || "DELETEME";
    fhirJson.version = researchStudyState.version || "DELETEME";
    fhirJson.name = researchStudyState.name || "DELETEME";
    fhirJson.title = researchStudyState.title || "DELETEME";
    fhirJson.label = researchStudyState.label || "DELETEME";
    fhirJson.protocol = researchStudyState.protocol || "DELETEME";
    fhirJson.partOf = researchStudyState.partOf || "DELETEME";
    fhirJson.relatedArtifact = researchStudyState.relatedArtifact || "DELETEME";
    fhirJson.date = researchStudyState.date || "DELETEME";
    fhirJson.status = researchStudyState.status || "unknown";
    fhirJson.primaryPurposeType = researchStudyState.primaryPurposeType || "DELETEME";
    fhirJson.phase = researchStudyState.phase || "DELETEME";
    fhirJson.studyDesign = researchStudyState.studyDesign || "DELETEME";
    fhirJson.focus = researchStudyState.focus || "DELETEME";
    fhirJson.condition = researchStudyState.condition || "DELETEME";
    fhirJson.keyword = researchStudyState.keyword || "DELETEME";
    fhirJson.region = researchStudyState.region || "DELETEME";
    fhirJson.descriptionSummary = researchStudyState.descriptionSummary || "DELETEME";
    fhirJson.description = researchStudyState.description || "DELETEME";
    fhirJson.period = researchStudyState.period || "DELETEME";
    fhirJson.site = researchStudyState.site || "DELETEME";
    fhirJson.note = researchStudyState.note || "DELETEME";
    fhirJson.classifier = researchStudyState.classifier || "DELETEME";
    fhirJson.associatedParty = researchStudyState.associatedParty || "DELETEME";
    fhirJson.progressStatus = researchStudyState.progressStatus || "DELETEME";
    fhirJson.whyStopped = researchStudyState.whyStopped || "DELETEME";
    fhirJson.recruitment = researchStudyState.recruitment || "DELETEME";
    fhirJson.comparisonGroup = researchStudyState.comparisonGroup || "DELETEME";
    fhirJson.objective = researchStudyState.objective || "DELETEME"
    fhirJson.outcomeMeasure = researchStudyState.outcomeMeasure || "DELETEME";
    fhirJson.result = researchStudyState.result || "DELETEME";
    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (researchStudyState) => {
    let contentArray = researchStudyState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "ResearchStudy";
    let classifiedFoi = researchStudyState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = researchStudyState.title || researchStudyState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateResearchStudyJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let researchStudyState = formInputsStateRef.current.researchStudyState;

    let fhirJson = jsonErrorCheck2("ResearchStudy", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = researchStudyTextViewChangesToJson(fhirJson, researchStudyState);
        fhirJson = jsonErrorCheck2("ResearchStudy", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(researchStudyState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateResearchStudyJson };