import submitToFevirServer from './SubmitToFevirServer';
import { getFoiFromReference, getHowToCite, getResourceDictionary } from './ResourceDictionaryFunctions';
import { getTargetResourceRatingDictionary } from './SummaryOfFindingsFunctions';
import { emptyReasonNotStarted, sectionCodeRelativeImportance } from './CodeSystemLookup';

const getRecommendationJsonArray = async (globalContext, recommendationFoiArray) => {
    const body = {
        'functionid': 'getmultiplefhirresources',
        'resourceids': recommendationFoiArray,
        'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 20000, body, true, false);
    if (response?.success && response.resourceentries) {
        let recommendationJsonArray = response.resourceentries.map(entry => {
            return JSON.parse(entry.toolstatestring);
        });
        return recommendationJsonArray;
    } else {
        return null;
    }
}

const getRecommendationDisplayValues = (recommendationJsonArray) => {
    let recommendationDisplayValuesDictionary = {};
    for (const resource of recommendationJsonArray) {
        let id = resource.id;
        let title = resource.title || resource.name || "Recommendation with FOI " + resource.id;
        let statement;
        let ratings;
        let population;
        let action;
        if (Array.isArray(resource.section) && Array.isArray(resource.section[0]?.section) && resource.section[0].section.length > 0) {
            let recSections = resource.section[0].section;
            for (const recSection of recSections) {
                let sectionCode = recSection.code?.text || recSection.code?.coding?.[0]?.code;
                if (sectionCode === "overallSummary" || sectionCode === "recommendation-statement") {
                    statement = recSection.text?.div;
                    if (statement === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" ||
                        statement === "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>") {
                        statement = "";
                    }
                } else if (sectionCode === "ratings" || sectionCode === "strengthOfRecommendationRating" || sectionCode === "strength-of-recommendation") {
                    ratings = recSection.text?.div;
                    if (ratings === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" ||
                        ratings === "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>") {
                        ratings = "";
                    }
                } else if (sectionCode === "population") {
                    population = recSection.text?.div;
                    if (population === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" ||
                        population === "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>") {
                        population = "";
                    }
                } else if (sectionCode === "action") {
                    action = recSection.text?.div;
                    if (action === "<div xmlns=\"http://www.w3.org/1999/xhtml\">[No data.]</div>" ||
                        action === "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>[No data.]</p></div>") {
                        action = "";
                    }
                }
            }
        }

        let resourceReference = {
            "reference": "Composition/" + resource.id,
            "type": "Composition",
            "display": title
        };
        recommendationDisplayValuesDictionary[id] = {
            id: id,
            title: title,
            statement: statement,
            ratings: ratings,
            population: population,
            action: action,
            resourceReference: resourceReference
        }
    }
    return recommendationDisplayValuesDictionary;
}

const loadSourceJsonFunction = async (resourceState, globalContext, setSourceJsonState,
    interventionDescription = null, comparatorDescription = null) => {
    if (resourceState) {
        let fhirJson;
        if (resourceState.resourceJson) {
            fhirJson = resourceState.resourceJson;
        } else if (resourceState.resourceType) {
            fhirJson = resourceState;
        }
        fhirJson.section = resourceState.section;
        fhirJson.relatesTo = resourceState.relatesTo;
        let howToCite = getHowToCite(fhirJson);
        let resourceDictionary = await getResourceDictionary(fhirJson, globalContext);

        let totalGroup = null;
        let subpopulations = [];
        let interventionsubgroups = [];
        let comparatorsubgroups = [];
        let interventionGroup = null;
        let comparatorGroup = null;
        let researchStudy = null;
        let recommendationDisplayValuesDictionary;
        if (resourceState.section?.length) {
            for (const section of resourceState.section) {
                if (section?.code) {
                    if (section.code.text === "Population" || section.code.coding?.[0]?.code === "population") {
                        totalGroup = section.entry?.[0] || null;
                        if (section.section?.[0]?.code?.text === "Population Subgroups" ||
                            section.section?.[0]?.code?.coding?.[0]?.code === "subgroup") {
                            subpopulations = section.section[0].entry || null;
                        }
                    } else if (section.code.text === "Intervention" || section.code.coding?.[0]?.code === "intervention") {
                        for (const subsection of section.section) {
                            if (subsection.code.text === "Intervention Description" || subsection.code.coding?.[0]?.code === "intervention-description") {
                                interventionDescription = subsection.entry?.[0] || null;
                            } else if (subsection.code.text === "Intervention Group" || subsection.code.coding?.[0]?.code === "intervention-group") {
                                interventionGroup = subsection.entry?.[0] || null;
                            } else if (subsection.code?.text === "Intervention Groups for Population Subgroups" ||
                                subsection.code?.coding?.[0]?.code === "subgroup") {
                                interventionsubgroups = subsection.entry || null;
                            }
                        }
                    } else if (section.code.text === "Comparator" || section.code.coding?.[0]?.code === "comparator") {
                        for (const subsection of section.section) {
                            if (subsection.code.text === "Comparator Description" || subsection.code.coding?.[0]?.code === "comparator-description") {
                                comparatorDescription = subsection.entry?.[0] || null;
                            } else if (subsection.code.text === "Comparator Group" || subsection.code.coding?.[0]?.code === "comparator-group") {
                                comparatorGroup = subsection.entry?.[0] || null;
                            } else if (subsection.code?.text === "Comparator Groups for Population Subgroups" ||
                                subsection.code?.coding?.[0]?.code === "subgroup") {
                                comparatorsubgroups = subsection.entry || null;
                            }
                        }
                    } else if (section.code.text === "Research Study" || section.code.coding?.[0]?.code === "research-study") {
                        researchStudy = section.entry?.[0] || null;
                    } else if (section.code.text === "Recommendations" || section.code.coding?.[0]?.code === "recommendations") {
                        if (section.entry?.length > 0) {
                            let recommendationFoiArray = section.entry
                                .filter(item => item.reference?.split('/').length > 1)
                                .map(item => { return item.reference.split('/')[1] });
                            if (recommendationFoiArray?.length > 0) {
                                let recommendationJsonArray = await getRecommendationJsonArray(globalContext, recommendationFoiArray);
                                if (recommendationJsonArray?.length > 0) {
                                    recommendationDisplayValuesDictionary = getRecommendationDisplayValues(recommendationJsonArray);
                                }
                            }
                        }
                    }
                }
            }
        }

        let totalGroupFoi;
        let totalGroupJson;
        let interventionGroupFoi;
        let interventionGroupJson;
        let comparatorGroupFoi;
        let comparatorGroupJson;
        let groupAssignmentFoi;
        let groupAssignmentJson;
        let groupAssignment;
        let comparatorCategory;

        if (resourceState.relatesTo && Array.isArray(resourceState.relatesTo) && resourceState.relatesTo.length > 0) {
            for (let relatedArtifactEntry of resourceState.relatesTo) {
                if (relatedArtifactEntry.type === "depends-on") {
                    if (relatedArtifactEntry.label === "Intervention Group") {
                        interventionGroupFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
                    } else if (relatedArtifactEntry.label === "Comparator Group") {
                        comparatorGroupFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
                    } else if (relatedArtifactEntry.label === "Total Group") {
                        totalGroupFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
                    }
                } else if (relatedArtifactEntry.type === "composed-of") {
                    groupAssignmentFoi = getFoiFromReference(relatedArtifactEntry.resourceReference, "", resourceDictionary);
                }
            }
        }
        if (resourceDictionary) {
            if (totalGroupFoi) {
                totalGroupJson = resourceDictionary[totalGroupFoi];
                totalGroup = {
                    "reference": "Group/" + totalGroupFoi,
                    "type": "Group",
                    "display": totalGroupJson.title || totalGroupJson.name || "[Untitled Group.]"
                };
            }
            if (interventionGroupFoi) {
                interventionGroupJson = resourceDictionary[interventionGroupFoi];
                interventionGroup = {
                    "reference": "Group/" + interventionGroupFoi,
                    "type": "Group",
                    "display": interventionGroupJson?.title || interventionGroupJson?.name || "[Untitled Group.]"
                };
            }
            if (comparatorGroupFoi) {
                comparatorGroupJson = resourceDictionary[comparatorGroupFoi];
                comparatorGroup = {
                    "reference": "Group/" + comparatorGroupFoi,
                    "type": "Group",
                    "display": comparatorGroupJson?.title || comparatorGroupJson?.name || "[Untitled Group.]"
                };
            }
            if (groupAssignmentFoi) {
                groupAssignmentJson = resourceDictionary[groupAssignmentFoi];
                groupAssignment = {
                    "reference": "EvidenceVariable/" + groupAssignmentFoi,
                    "type": "EvidenceVariable",
                    "display": groupAssignmentJson?.title
                };
                comparatorCategory = groupAssignmentJson?.title.split(" vs. ")[1] || null;
            }
        }

        let groupReferences = {
            totalGroup: totalGroup,
            subpopulations: subpopulations,
            interventionsubgroups: interventionsubgroups,
            comparatorsubgroups: comparatorsubgroups,
            interventionGroup: interventionGroup,
            interventionDescription: interventionDescription,
            comparatorGroup: comparatorGroup,
            comparatorDescription: comparatorDescription,
            researchStudy: researchStudy,
            groupAssignment: groupAssignment,
            comparatorCategory: comparatorCategory
        };
        setSourceJsonState({
            "howToCite": howToCite,
            "resourceDictionary": resourceDictionary,
            "loaded": true,
            "loaded2": true,
            "groupReferences": groupReferences,
            "recommendationDisplayValuesDictionary": recommendationDisplayValuesDictionary
        });
    }
}


const identifyTargetResources = (summarySection = [], fliListToProcess) => {
    let targetResources = summarySection.map(section => {
        let targetfoi = section.focus?.reference?.replace("EvidenceVariable/", "");
        let targetfli;
        if (!targetfoi && section.focus?.identifier?.system === "https://fevir.net/FLI" && section.focus.identifier.value) {
            targetfli = section.focus.identifier.value;
        }
        let targettitle = section.focus?.display;
        if (!targetfoi && targetfli) {
            fliListToProcess.push(targetfli);
        }
        return {
            id: targetfoi,
            title: targettitle,
            fli: targetfli
        };
    });
    return [targetResources, fliListToProcess]
}

const identifyBaselineRiskResources = (summarySection = []) => {
    let baselineRiskResources = summarySection.map(section => {
        let entry;
        if (Array.isArray(section.section)) {
            for (let subsection of section.section) {
                if (subsection.code?.coding?.length > 0 &&
                    (subsection.code.coding[0].code === "evidence-with-comparator-alone" || subsection.code.coding[0].code === "result-with-comparator-alone" || subsection.code.coding[0].code === "Control-group-alone-Evidence")) {
                    if (subsection.entry?.length) {
                        let sourcefoi = subsection.entry[0].reference?.replace("Evidence/", "") || "missing";
                        let sourcetitle = subsection.entry[0].display || "";
                        if (!isNaN(sourcefoi)) {
                            entry = {
                                id: parseInt(sourcefoi),
                                title: sourcetitle,
                                titletrimmed: sourcetitle.replace("ComparatorOnlyEvidence: ", "").replace("StudyOutcomeEvidence: ", "")
                            };
                        } else if (subsection.entry[0].identifier?.system === "https://fevir.net/FLI" && subsection.entry[0].identifier.value) {
                            let sourcefli = subsection.entry[0].identifier.value;
                            entry = {
                                id: NaN,
                                title: sourcetitle,
                                titletrimmed: sourcetitle.replace("ComparatorOnlyEvidence: ", "").replace("StudyOutcomeEvidence: ", ""),
                                fli: sourcefli
                            };
                        }
                    }
                }
            }
        }
        return entry;
    });
    return baselineRiskResources;
}

const loadSourceJsonWithRatingDictionaryFunction = async (resourceState, globalContext,
    setSourceJsonState, setResourceState, customizedState) => {
    if (resourceState) {
        let fhirJson;
        if (resourceState.resourceJson) {
            fhirJson = resourceState.resourceJson;
        } else if (resourceState.resourceType) {
            fhirJson = resourceState;
        }
        fhirJson.section = resourceState.section;
        fhirJson.relatesTo = resourceState.relatesTo;
        let howToCite = getHowToCite(fhirJson);
        let resourceDictionary = await getResourceDictionary(fhirJson, globalContext);
        let ratingDictionary;
        let [targetResources, fliListToProcess] = identifyTargetResources(fhirJson.section[1].section, []);
        let baselineRiskResources = identifyBaselineRiskResources(fhirJson.section[1].section);
        if (fliListToProcess.length) {
            const body = {
                'functionid': 'getfoilistfromflilist',
                'fliList': fliListToProcess,
                'idToken': ""
            };
            let response = await submitToFevirServer(globalContext, 100000, body, true, false);
            if (response?.success && response.foilist?.length && response.flitofoi) {
                for (let resource of targetResources) {
                    if (!resource?.id && resource?.fli && response.flitofoi[resource.fli]) {
                        resource.id = response.flitofoi[resource.fli];
                    }
                }
            }
        }
        if (customizedState?.customized && targetResources && fhirJson.section[1].section?.length) {
            let response = await getTargetResourceRatingDictionary(targetResources, globalContext);
            if (response?.success && response.targetResourceRatingDictionary) {
                ratingDictionary = response.targetResourceRatingDictionary;
            }
        }
        if (ratingDictionary && setResourceState) {
            setResourceState(prevState => {
                let newSection = JSON.parse(JSON.stringify(prevState.section));
                let findingsSection = newSection[1].section;
                let loadedSection = findingsSection.map(section => {
                    if (section.focus?.reference) {
                        let targetfoi = section.focus.reference.replace("EvidenceVariable/", "");
                        if (!targetfoi) {
                            let targetfli;
                            if (section.focus?.identifier?.system === "https://fevir.net/FLI" && section.focus.identifier.value) {
                                targetfli = section.focus.identifier.value;
                            }
                            for (const foiKey in ratingDictionary) {
                                if (ratingDictionary[foiKey].fli == targetfli) {
                                    targetfoi = foiKey;
                                }
                            }
                        }
                        if (ratingDictionary[targetfoi]) {
                            let entry = ratingDictionary[targetfoi];
                            if (entry.exists === false) {
                                section.section[8] = {
                                    "title": "Relative Importance",
                                    "code": sectionCodeRelativeImportance,
                                    "emptyReason": emptyReasonNotStarted
                                }
                            }
                            if (entry.exists === true) {
                                section.section[8] = {
                                    "title": "Relative Importance",
                                    "code": sectionCodeRelativeImportance,
                                    "text": {
                                        "status": "generated",
                                        "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + entry.desirabilityDisplay + " " + entry.quantityValue.toString() + "%</p></div>"
                                    },
                                    "entry": [
                                        {
                                            "reference": "ArtifactAssessment/" + targetfoi, //TODO - check if targetfoi should be a different FOI here
                                            "type": "ArtifactAssessment",
                                            "display": entry.targettitle //TODO - check what title shows up here
                                        }
                                    ]
                                }
                            }
                        }
                    }
                    return section;
                });
                newSection[1].section = loadedSection;
                return {
                    ...prevState,
                    "section": newSection,
                    "targetResources": targetResources,
                    "baselineRiskResources": baselineRiskResources,
                    "ratingDictionary": ratingDictionary
                };
            });
        }

        setSourceJsonState({
            "howToCite": howToCite,
            "resourceDictionary": resourceDictionary,
            "loaded": true,
            "loaded2": true
        });
    }
}

export { loadSourceJsonFunction, loadSourceJsonWithRatingDictionaryFunction, identifyBaselineRiskResources };