import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const classificationTextViewChangesToJson = (fhirJson, classificationState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.resourceType = "ArtifactAssessment";
    fhirJson.meta = classificationState.meta;
    fhirJson.language = classificationState.language;
    fhirJson.text = classificationState.text;
    fhirJson.contained = classificationState.contained || "DELETEME";
    fhirJson.extension = classificationState.extension || "DELETEME";
    fhirJson.modifierExtension = classificationState.modifierExtension || "DELETEME";
    fhirJson.language = classificationState.language || "DELETEME";
    fhirJson.identifier = classificationState.identifier || "DELETEME";
    fhirJson.title = classificationState.title || "DELETEME";
    fhirJson.date = classificationState.date || "DELETEME";
    fhirJson.copyright = classificationState.copyright || "DELETEME";
    fhirJson.approvalDate = classificationState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = classificationState.lastReviewDate || "DELETEME";
    fhirJson.artifactReference = classificationState.artifactReference || "DELETEME";
    fhirJson.artifactCanonical = classificationState.artifactCanonical || "DELETEME";
    fhirJson.artifactUri = classificationState.artifactUri || "DELETEME";
    fhirJson.content = classificationState.content || "DELETEME";
    fhirJson.workflowStatus = classificationState.workflowStatus || "DELETEME";
    fhirJson.disposition = classificationState.disposition || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (classificationState) => {
    let contentArray = classificationState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "ArtifactAssessment";
    let classifiedFoi = classificationState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = classificationState.title || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateClassificationJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let classificationState = formInputsStateRef.current.classificationState;
    let fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    if (fhirEntryState.activeIndex === 0) {
        fhirJson = classificationTextViewChangesToJson(fhirJson, classificationState);
        fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(classificationState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateClassificationJson };