import React, { useState, useEffect, useImperativeHandle, useContext } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import { DisplayFromFHIR, getValueSetCodesAsOptions } from './ResourceFunctions';
import submitToFevirServer from './SubmitToFevirServer';
import FevirContext from './FevirContext';

let separatorCharacter = ": ";

const ChildConceptsTable = ({ fhirEntryState }) => {
  if (fhirEntryState.fhirEntryString) {
    //let header = "Child concepts:";
    let header;
    if (fhirEntryState.conceptName) {
      header = "Types of " + fhirEntryState.conceptName + ":"
    }
    let addButtonText = "+ Add Child Concept";
    let concept = fhirEntryState.conceptSelected;
    let conceptPath = fhirEntryState.conceptPath;
    let json;
    if (fhirEntryState.fhirEntryString) {
      json = JSON.parse(fhirEntryState.fhirEntryString);
    }

    if (conceptPath === undefined || conceptPath.length === 0) {
      concept = json;
      //header = "Top-level concepts:"
      //addButtonText = "+ Add Top-Level Concept";
    }

    let ratingComponents = [];
    for (let componentIndex in concept.component) {
      if (concept.component[componentIndex].type) { //TODO -- changedd from if informationType === "rating"
        ratingComponents.push(concept.component[componentIndex]);
      }
    }

    let subRatingFound = false;
    let childrenTable = <>
      {concept?.component?.length > 0 &&
        <>
          {header && <h3>{header}</h3>}
          {(concept?.component?.length > 0 || fhirEntryState.newChildConcepts.length > 0) &&
            <div>
              <Table>
                <Table.Header><Table.Row>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Display</Table.HeaderCell>
                  {/*<Table.HeaderCell>Definition</Table.HeaderCell>*/}
                </Table.Row></Table.Header>
                <Table.Body>
                  {ratingComponents.map((subConcept, subConceptIndex) => {
                    subRatingFound = true;
                    let subConceptPath = [];
                    let subConceptPathString = fhirEntryState.conceptPathString + ": " + subConcept.display;
                    if (conceptPath) {
                      subConceptPath = [...conceptPath, subConcept.display];
                    } else {
                      subConceptPath = [subConcept.display];
                    }
                    let subConceptPathIndexes = [];
                    if (fhirEntryState.conceptPathIndexes) {
                      subConceptPathIndexes = [...fhirEntryState.conceptPathIndexes, subConceptIndex];
                    } else {
                      subConceptPathIndexes = [subConceptIndex];
                    }
                    return <Table.Row key={subConceptIndex}>
                      <Table.Cell>{subConcept.code}</Table.Cell>
                      <Table.Cell><a onClick={() => {
                        //navigateToAnotherRobatTerm("Term", history, fhirEntryState, setFhirEntryState, 'ArtifactAssessment', resourceId, json, subConcept, subConcept.display, subConceptPath, subConceptPathIndexes, subConceptPathString, globalContext, changeFormState, formInputsStateRef, changeFhirEntryState, addToast);
                        alert("Sub-rating table navigation not yet supported.");
                      }}>{subConcept.display}</a></Table.Cell>
                      {/*<Table.Cell>{subConcept.definition}</Table.Cell>*/}
                    </Table.Row>

                  })}
                </Table.Body>
              </Table>
            </div>
          }
          {/*fhirEntryState.editMode && <Button className="formButton" style={{color: "#000000"}} content={addButtonText} onClick={() => { setFhirEntryState(prevState  => { let newNewChildConcepts = prevState.newChildConcepts; newNewChildConcepts.push({"code": "", "display": "", "definition": ""}); return { ...prevState, "newChildConcepts": newNewChildConcepts }; }); } } />*/}
        </>
      }
    </>
    if (subRatingFound) {
      return childrenTable;
    } else {
      return <></>
    }
  } else {
    return <></>;
  }
}


const RobatTermDisplay = ({ changeFormState, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState,
  history }) => {

  const [factorPresenceOptions, setFactorPresenceOptions] = useState([]);
  const [factorPresenceLookup, setFactorPresenceLookup] = useState({});
  const [potentialInfluenceOptions, setPotentialInfluenceOptions] = useState([]);
  const [potentialInfluenceLookup, setPotentialInfluenceLookup] = useState({});
  const [biasDirectionOptions, setBiasDirectionOptions] = useState([]);
  const [biasDirectionLookup, setBiasDirectionLookup] = useState({});
  const [biasRiskOptions, setBiasRiskOptions] = useState([]);
  const [biasRiskLookup, setBiasRiskLookup] = useState({});

  const globalContext = useContext(FevirContext);
  const [factorPresenceSelectedState, setFactorPresenceSelectedState] = useState("");
  const [potentialInfluenceSelectedState, setPotentialInfluenceSelectedState] = useState("");
  const [biasDirectionSelectedState, setBiasDirectionSelectedState] = useState("");
  const [biasRiskSelectedState, setBiasRiskSelectedState] = useState("");
  const [explainRationaleState, setExplainRationaleState] = useState("");
  const [notesState, setNotesState] = useState([]);

  const [termInformationState, setTermInformationState] = useState({ conceptCode: "", preferredTerm: "", definition: "", alternativeTerms: [], commentsForApplication: [], commentForApplication: "" });


  useEffect(async () => {
    if (factorPresenceOptions.length === 0) {
      let factorPresenceOptionsResponse = await getValueSetCodesAsOptions('29654', globalContext);
      let [factorPresenceOptionsTemp, factorPresenceOptionsLookupTemp] = factorPresenceOptionsResponse;
      setFactorPresenceOptions(factorPresenceOptionsTemp);
      setFactorPresenceLookup(factorPresenceOptionsLookupTemp);

      let potentialInfluenceResponse = await getValueSetCodesAsOptions('29656', globalContext);
      let [potentialInfluenceOptionsTemp, potentialInfluenceOptionsLookupTemp] = potentialInfluenceResponse;
      setPotentialInfluenceOptions(potentialInfluenceOptionsTemp);
      setPotentialInfluenceLookup(potentialInfluenceOptionsLookupTemp);

      let biasDirectionResponse = await getValueSetCodesAsOptions('29655', globalContext);
      let [biasDirectionOptionsTemp, biasDirectionOptionsLookupTemp] = biasDirectionResponse;
      setBiasDirectionOptions(biasDirectionOptionsTemp);
      setBiasDirectionLookup(biasDirectionOptionsLookupTemp);

      let biasRiskResponse = await getValueSetCodesAsOptions('29653', globalContext);
      let [biasRiskOptionsTemp, biasRiskOptionsLookupTemp] = biasRiskResponse;
      setBiasRiskOptions(biasRiskOptionsTemp);
      setBiasRiskLookup(biasRiskOptionsLookupTemp);
    }
  }, [factorPresenceOptions])

  useImperativeHandle(formInputsStateRef, () => ({
    factorPresenceSelectedState: factorPresenceSelectedState,
    potentialInfluenceSelectedState: potentialInfluenceSelectedState,
    biasDirectionSelectedState: biasDirectionSelectedState,
    biasRiskSelectedState: biasRiskSelectedState,
    explainRationaleState: explainRationaleState,
    notesState: notesState,
    factorPresenceLookup: factorPresenceLookup,
    potentialInfluenceLookup: potentialInfluenceLookup,
    biasDirectionLookup: biasDirectionLookup,
    biasRiskLookup: biasRiskLookup
  }), [factorPresenceSelectedState, potentialInfluenceSelectedState, biasDirectionSelectedState, biasRiskSelectedState, explainRationaleState, notesState, factorPresenceLookup, potentialInfluenceLookup, biasDirectionLookup, biasRiskLookup]);

  let json;
  if (fhirEntryState.fhirEntryString) {
    json = JSON.parse(fhirEntryState.fhirEntryString)
  }

  let concept = fhirEntryState.conceptSelected;
  let conceptPath = fhirEntryState.conceptPath;
  let conceptPathIndexes = fhirEntryState.conceptPathIndexes;
  let conceptPathString = fhirEntryState.conceptPathString;
  let conceptPathLength = 0;
  if (conceptPath) {
    conceptPathLength = conceptPath.length;
  }

  if (concept) {
    /*
    let editors = [];
    let comments = [];
    let ewgComments = [];
    let ewgAgreement = [];
    let ewgDisagreement = [];
    //editors.push(fhirEntryState["conceptProperties"]["editors"]);
    //comments.push(fhirEntryState["conceptProperties"]["comment"]);
    //ewgComments.push(fhirEntryState["conceptProperties"]["expert-comments"]);
    //ewgAgreement.push(fhirEntryState["conceptProperties"]["approval"]);
    //ewgDisagreement.push(fhirEntryState["conceptProperties"]["negative-vote"]);
    for (let propertyIndex in concept.property) {
      let property = concept.property[propertyIndex];
      let valueString = property.valueString;
      if (valueString) {
        if (property.code === "editors") {
          //editors.push(valueString);
        } else if (property.code === "comment") {
          comments.push(valueString);
        } else if (property.code === "expert-comments") {
          ewgComments.push(valueString);
        } else if (property.code === "approval") {
          ewgAgreement.push(valueString);
        } else if (property.code === "negative-vote") {
          ewgDisagreement.push(valueString);
        }
      }
    }
    */
  }


  //setFhirEntryState(prevState  => { return { ...prevState, conceptProperties: { ["editors"]: editors[0] || ""} }; })

  let separator = "";
  if (conceptPathString) {
    separator = separatorCharacter;
  }

  let alternativeTermsOptions = [];
  for (let alternativeTermsIndex in fhirEntryState.alternativeTerms) {
    let alternativeTerm = fhirEntryState.alternativeTerms[alternativeTermsIndex];
    alternativeTermsOptions.push({ ket: alternativeTerm, text: alternativeTerm, value: alternativeTerm });
  }

  const ComponentTree = ({ json }) => {
    return <>
      <>
        <span style={{ paddingLeft: "8px" }}>{conceptPath?.map((parentConceptDisplay, parentConceptIndex) => {
          //let parentConceptPath = conceptPath.slice(0, parentConceptIndex+1);
          let parentConceptPathIndexes = conceptPathIndexes.slice(0, parentConceptIndex + 1);
          let parentConcept = json;
          let concepts;
          if (parentConcept) {
            if (parentConcept.content) {
              concepts = parentConcept.content;
            } else if (parentConcept.component) {
              concepts = parentConcept.component;
            } else if (parentConcept.concept) {
              concepts = parentConcept.concept;
            }
            for (let parentIndexIndex in parentConceptPathIndexes) {
              let parentIndex = parentConceptPathIndexes[parentIndexIndex];
              parentConcept = concepts[parentIndex];
            }

            //let parentConceptPathString = conceptPath.join(separator);
            return <span key={parentConceptIndex} style={{ paddingLeft: parentConceptIndex * 24 + "px" }}>
              <span>
                {parentConceptIndex + 1 === conceptPath.length ?
                  <b>{parentConceptDisplay}</b>
                  :
                  <a onClick={() => {
                    alert("This navigation is not yet supported.");
                    /*
                    //history.push(`/resources/ArtifactAssessment/${resourceId}#${parentConcept.code},${parentConceptPathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
                    history.push(`/resources/ArtifactAssessment/${resourceId}#${parentConcept.code}`);
                    let propertyProperties = getPropertyCodes(json);
                    let propertyCodes = propertyProperties["propertyCodes"];
                    let propertyDescriptions = propertyProperties["propertyDescriptions"];
                    if (fhirEntryState.termChanged !== true) {
                      let scrollToId = "termdetail-navigation-code-"+parentConcept.code;
                      if (parentConceptPathIndexes.length === 1 && parseInt(parentConceptPathIndexes[0]) == 0) {
                          scrollToId = "";
                      }
                      scrollTo("navigationTermDetailSegment", scrollToId, true);
                    }
                    let conceptProperties = findConceptProperties(parentConcept, propertyCodes);
                    let conceptOpenForVoting = false;
                    let conceptPropertyComment = "";
                    if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
                      conceptOpenForVoting = true;
                    }
                    if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
                      conceptPropertyComment = conceptProperties["comment"];
                    }*/

                    /*
                    for (let classifierIndex in parentConcept.classifier) {
                      let classifier = parentConcept.classifier[classifierIndex];
                      if (classifier.coding?.length > 0 && classifier.coding[0].code) {
                        console.log(classifier.coding[0].code);
                        //
                      }
                    }
                    */
                    /*
                    setFhirEntryState(prevState  => { return {
                      ...prevState,
                      conceptName: parentConceptDisplay,
                      conceptPath: parentConceptPath,
                      conceptPathIndexes: parentConceptPathIndexes,
                      conceptPathString: parentConceptPathString,
                      conceptSelected: parentConcept,
                      conceptDefinition: parentConcept.definition,
                      conceptCode: parentConcept.code,
                      alternativeTerms: getAlternativeTerms(parentConcept),
                      propertyCodes: propertyCodes,
                      propertyDescriptions: propertyDescriptions,
                      conceptProperties: conceptProperties,
                      conceptOpenForVoting: conceptOpenForVoting,
                      conceptPropertyComment: conceptPropertyComment,
                      newChildConcepts: [],
                      conceptCommentsExpanded: false,
                      comments: [],
                      votes: [],
                      myvotes: []
                    }; })
                    */
                  }} >
                    {parentConceptDisplay}</a>
                }
              </span>
              <br />
            </span>
          } else {
            return <span key={parentConceptIndex} style={{ paddingLeft: parentConceptIndex * 24 + "px" }}></span>
          }
        })
        }</span>
      </>
      {concept.component?.length > 0 &&
        <div>{concept.component.map((subConcept, subConceptIndex) => {
          /*
      let subConceptDisplay = subConcept.display;
      let subConceptPath = [];
      if (conceptPath) {
        subConceptPath = [...conceptPath, subConceptDisplay];
      }
      let subConceptPathIndexes = [];
      if (conceptPathIndexes) {
        subConceptPathIndexes = [...conceptPathIndexes, subConceptIndex];
      }
      let subConceptPathString = conceptPathString+separator+subConceptDisplay;
      */
          return <span key={subConceptIndex} style={{ paddingLeft: conceptPathLength * 24 + "px" }}>
            <a onClick={() => {
              //history.push(`/resources/ArtifactAssessment/${resourceId}#${subConcept.code},${subConceptPathIndexes.toString()}`);    //INCLUDES PATH SO IT CAN HANDLE DUPLICATE CODES, COMMENTED OUT FOR NOW
              alert("This navigation is not yet supported.");
              /*
              history.push(`/resources/ArtifactAssessment/${resourceId}#${subConcept.code}`);
              let propertyProperties = getPropertyCodes(json);
              let propertyCodes = propertyProperties["propertyCodes"];
              let propertyDescriptions = propertyProperties["propertyDescriptions"];

              if (fhirEntryState.termChanged !== true) {
                  let scrollToId = "termdetail-navigation-code-"+subConcept.code;
                  if (subConceptPathIndexes.length === 1 && parseInt(subConceptPathIndexes[0]) == 0) {
                      scrollToId = "";
                  }
                  scrollTo("navigationTermDetailSegment", scrollToId, true);
              }

              let conceptProperties = findConceptProperties(subConcept, propertyCodes);
              let conceptOpenForVoting = false;
              let conceptPropertyComment = "";
              if (conceptProperties["open-for-voting"] && conceptProperties["open-for-voting"] !== "") {
                conceptOpenForVoting = true;
              }
              if (conceptProperties["comment"] && conceptProperties["comment"] !== "") {
                conceptPropertyComment = conceptProperties["comment"];
              }
              setFhirEntryState(prevState  => { return {
                ...prevState,
                conceptName: subConceptDisplay,
                conceptPath: subConceptPath,
                conceptPathIndexes: subConceptPathIndexes,
                conceptPathString: subConceptPathString,
                conceptSelected: subConcept,
                conceptDefinition: subConcept.definition,
                conceptCode: subConcept.code,
                alternativeTerms: getAlternativeTerms(subConcept),
                propertyCodes: propertyCodes,
                propertyDescriptions: propertyDescriptions,
                conceptProperties: conceptProperties,
                conceptOpenForVoting: conceptOpenForVoting,
                conceptPropertyComment: conceptPropertyComment,
                newChildConcepts: [],
                conceptCommentsExpanded: false,
                comments: [],
                votes: [],
                myvotes: []
              }; })*/
            }} >
              {subConcept.display}
            </a>
            <br />
          </span>
        })
        }
        </div>
      }
    </>
  }

  const loadTermInformation = async () => {
    if (fhirEntryState.conceptCode && fhirEntryState.conceptSystem && termInformationState.conceptCode !== fhirEntryState.conceptCode) {
      if (fhirEntryState.conceptSystem.substring(0, 39) === 'https://fevir.net/resources/CodeSystem/') {
        let codeSystemFevirId = fhirEntryState.conceptSystem.substring(39);

        const body = {
          'functionid': "gettermjsonfromcodesystem",
          'idToken': "",
          'resourceid': codeSystemFevirId,
          'termcode': fhirEntryState.conceptCode,
          'recursive': false
        };

        let response = await submitToFevirServer(globalContext, 10000, body, true, false);

        if (response?.success && response.termJson) {
          let alternativeTerms = [];
          for (let designationIndex in response.termJson.designation) {
            let designation = response.termJson.designation[designationIndex];
            if (designation.value && designation.use?.display === "Alternative term") {
              alternativeTerms.push(designation.value);
            }
          }
          let commentForApplication = "";
          for (let propertyIndex in response.termJson.property) {
            let property = response.termJson.property[propertyIndex];
            if (property.code === "comment" && property.valueString) {
              //commentsForApplication.push(property.valueString);
              commentForApplication = property.valueString;
              break;
            }
          }
          setTermInformationState({ conceptCode: fhirEntryState.conceptCode, preferredTerm: response.termJson.display, definition: response.termJson.definition, alternativeTerms: alternativeTerms, commentForApplication: commentForApplication });
        }
      }
    }
  }

  useEffect(async () => {
    if (fhirEntryState.submittingToServer !== true && fhirEntryState.loading !== true) {
      if (fhirEntryState.notes && JSON.stringify(notesState) !== JSON.stringify(fhirEntryState.notes)) {
        setNotesState(fhirEntryState.notes);
      }
      if (fhirEntryState.conceptSummary !== undefined && explainRationaleState !== fhirEntryState.conceptSummary) {
        setExplainRationaleState(fhirEntryState.conceptSummary);
      }
      let classifierCodes = fhirEntryState.classifierCodes;
      if (classifierCodes === undefined && concept) {
        let tempClassifierCodes = [];
        for (let classifierIndex in concept.classifier) {
          let classifier = concept.classifier[classifierIndex];
          for (let classifierCodingIndex in classifier.coding) {
            let classifierCoding = classifier.coding[classifierCodingIndex];
            if (classifierCoding.code) {
              tempClassifierCodes.push(classifierCoding.code);
            }
          }
        }
        if (tempClassifierCodes.length > 0) {
          classifierCodes = tempClassifierCodes;
        }
      }

      if (classifierCodes) {
        let factorPresence = "";
        let potentialInfluence = "";
        let biasDirection = "";
        let biasRisk = "";
        for (let classifierIndex in classifierCodes) {
          let classifierCode = classifierCodes[classifierIndex];
          if (["SEVCO:00194", "SEVCO:00195", "SEVCO:00196", "SEVCO:00197", "SEVCO:00199", "SEVCO:00198"].includes(classifierCode)) {
            factorPresence = classifierCode;
          } else if (["SEVCO:00207", "SEVCO:00208", "SEVCO:00209", "SEVCO:00210"].includes(classifierCode)) {
            potentialInfluence = classifierCode;
          } else if (["SEVCO:00201", "SEVCO:00202", "SEVCO:00203", "SEVCO:00204", "SEVCO:00205"].includes(classifierCode)) {
            biasDirection = classifierCode;
          } else if (["SEVCO:00186", "SEVCO:00187", "SEVCO:00188", "SEVCO:00189", "SEVCO:00190", "SEVCO:00191", "SEVCO:00192"].includes(classifierCode)) {
            biasRisk = classifierCode;
          }
        }
        setFactorPresenceSelectedState(factorPresence);
        setPotentialInfluenceSelectedState(potentialInfluence);
        setBiasDirectionSelectedState(biasDirection);
        setBiasRiskSelectedState(biasRisk);
      } else {
        //console.log(concept);
      }
      loadTermInformation();
    }
  }, [fhirEntryState]);

  useEffect(async () => {
    changeFormState("Computable Publishing®: Risk of Bias Assessment Tool", "pageTitle");
  }, []);

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          {fhirEntryState.editMode ?
            <>
              {fhirEntryState.conceptDelete ?
                <><h1 style={{ color: "#FF0000" }}>Concept "{fhirEntryState.conceptName}" deletion pending, please click "Update" to complete deletion.</h1></>
                :
                <>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ float: "left", minWidth: "480px", marginRight: "18px" }}>
                      <div style={{ border: "3px solid #DAA520", padding: "6px", backgroundColor: "#FCFAEF" }}>
                        <span style={{ color: "#997418" }}><b>Instructions:</b> </span>
                        <ol style={{ margin: "4px" }}>
                          <li>Click to report your ratings. (You don't have to rate all four)</li>
                          <li>You can write text to <b>Explain your rationale</b>.</li>
                          <li>You can use <b>Add Note</b> if desired.</li>
                          <li>To rate another "<b>Type of Bias</b>" pick one from the left panel.
                            <ul>
                              <li>Use the hierarchy or your browser's find function (CTRL+F) to select a term.</li>
                              <li>To report your overall rating, select "<b>Bias</b>" at the top of the left panel.</li>
                            </ul>
                          </li>
                          <li>Click <b>Update</b> (the green button at the bottom left), using Draft when incomplete and Publish when complete.</li>
                        </ol>
                      </div>
                      <br />
                      <h2 style={{ margin: "0px" }}>{fhirEntryState.conceptName}</h2>
                      <br />
                      {/*
                <TextField style={{width: "100%"}} multiline className="inputField" type='text' label={'Code'} size="small" variant='outlined' value={fhirEntryState.conceptCode || ""} onChange={(e) => { setFhirEntryState(prevState  => { let newConceptCode = e.target.value; return { ...prevState, "conceptCode": newConceptCode, "termChanged": true } }) } } />
                <br/><br/>
                <TextField style={{width: "100%"}} multiline className="inputField" type='text' label={'Preferred term'} size="small" variant='outlined' value={fhirEntryState.conceptName || ""} onChange={(e) => { setFhirEntryState(prevState  => { let newConceptName = e.target.value; return { ...prevState, "conceptName": newConceptName, "termChanged": true } }) } } />
                <br/><br/>
                <TextField style={{width: "100%"}} multiline className="inputField" type='text' label={'Definition'} size="small" variant='outlined' value={fhirEntryState.conceptDefinition || ""} onChange={(e) => { setFhirEntryState(prevState  => { let newConceptDefinition = e.target.value; return { ...prevState, "conceptDefinition": newConceptDefinition, "termChanged": true } }) } } />
                <br/><br/>
                */}
                      {fhirEntryState.conceptCode !== "SEVCO:00001" &&
                        <>
                          <b>Rate factor presence: </b><Button.Group size='small'>
                            <Button className={"SEVCO:00194" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00194" === factorPresenceSelectedState} style={{ border: "2px solid #000000" }} onClick={() => { if (factorPresenceSelectedState === "SEVCO:00194") { setFactorPresenceSelectedState(""); } else { setFactorPresenceSelectedState("SEVCO:00194"); } }}>Present</Button>
                            <Button className={"SEVCO:00195" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00195" === factorPresenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (factorPresenceSelectedState === "SEVCO:00195") { setFactorPresenceSelectedState(""); } else { setFactorPresenceSelectedState("SEVCO:00195"); } }}>Likely present</Button>
                            <Button className={"SEVCO:00196" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00196" === factorPresenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (factorPresenceSelectedState === "SEVCO:00196") { setFactorPresenceSelectedState(""); } else { setFactorPresenceSelectedState("SEVCO:00196"); } }}>Likely absent</Button>
                            <Button className={"SEVCO:00197" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00197" === factorPresenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (factorPresenceSelectedState === "SEVCO:00197") { setFactorPresenceSelectedState(""); } else { setFactorPresenceSelectedState("SEVCO:00197"); } }}>Absent</Button>
                            <Button className={"SEVCO:00199" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00199" === factorPresenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (factorPresenceSelectedState === "SEVCO:00199") { setFactorPresenceSelectedState(""); } else { setFactorPresenceSelectedState("SEVCO:00199"); } }}>Unclear</Button>
                            <Button className={"SEVCO:00198" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00198" === factorPresenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (factorPresenceSelectedState === "SEVCO:00198") { setFactorPresenceSelectedState(""); } else { setFactorPresenceSelectedState("SEVCO:00198"); } }}>No information</Button>
                            {/*<Button className={"SEVCO:00194" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00194" === factorPresenceSelectedState} style={{border: "2px solid #000000"}} onClick={() => { setFactorPresenceSelectedState("SEVCO:00194"); }}>Present</Button>
                    <Button className={"SEVCO:00195" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00195" === factorPresenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setFactorPresenceSelectedState("SEVCO:00195"); }}>Likely present</Button>
                    <Button className={"SEVCO:00196" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00196" === factorPresenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setFactorPresenceSelectedState("SEVCO:00196"); }}>Likely absent</Button>
                    <Button className={"SEVCO:00197" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00197" === factorPresenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setFactorPresenceSelectedState("SEVCO:00197"); }}>Absent</Button>
                    <Button className={"SEVCO:00199" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00199" === factorPresenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setFactorPresenceSelectedState("SEVCO:00199"); }}>Unclear</Button>
                    <Button className={"SEVCO:00198" === factorPresenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00198" === factorPresenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setFactorPresenceSelectedState("SEVCO:00198"); }}>No information</Button>*/}
                          </Button.Group><br /><br />

                          <b>Does factor have potential to impact results?: </b><Button.Group size='small'>
                            <Button className={"SEVCO:00207" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00207" === potentialInfluenceSelectedState} style={{ border: "2px solid #000000" }} onClick={() => { if (potentialInfluenceSelectedState === "SEVCO:00207") { setPotentialInfluenceSelectedState(""); } else { setPotentialInfluenceSelectedState("SEVCO:00207"); } }}>Yes</Button>
                            <Button className={"SEVCO:00208" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00208" === potentialInfluenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (potentialInfluenceSelectedState === "SEVCO:00208") { setPotentialInfluenceSelectedState(""); } else { setPotentialInfluenceSelectedState("SEVCO:00208"); } }}>Likely yes</Button>
                            <Button className={"SEVCO:00209" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00209" === potentialInfluenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (potentialInfluenceSelectedState === "SEVCO:00209") { setPotentialInfluenceSelectedState(""); } else { setPotentialInfluenceSelectedState("SEVCO:00209"); } }}>Likely no</Button>
                            <Button className={"SEVCO:00210" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00210" === potentialInfluenceSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (potentialInfluenceSelectedState === "SEVCO:00210") { setPotentialInfluenceSelectedState(""); } else { setPotentialInfluenceSelectedState("SEVCO:00210"); } }}>No</Button>
                            {/*<Button className={"SEVCO:00207" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00207" === potentialInfluenceSelectedState} style={{border: "2px solid #000000"}} onClick={() => { setPotentialInfluenceSelectedState("SEVCO:00207"); }}>Yes</Button>
                    <Button className={"SEVCO:00208" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00208" === potentialInfluenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setPotentialInfluenceSelectedState("SEVCO:00208"); }}>Likely yes</Button>
                    <Button className={"SEVCO:00209" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00209" === potentialInfluenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setPotentialInfluenceSelectedState("SEVCO:00209"); }}>Likely no</Button>
                  <Button className={"SEVCO:00210" === potentialInfluenceSelectedState ? "" : "whiteButton"} primary={"SEVCO:00210" === potentialInfluenceSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setPotentialInfluenceSelectedState("SEVCO:00210"); }}>No</Button>*/}
                          </Button.Group><br /><br />
                        </>}

                      <b>Rate direction of bias: </b><Button.Group size='small'>
                        <Button className={"SEVCO:00201" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00201" === biasDirectionSelectedState} style={{ border: "2px solid #000000" }} onClick={() => { if (biasDirectionSelectedState === "SEVCO:00201") { setBiasDirectionSelectedState(""); } else { setBiasDirectionSelectedState("SEVCO:00201"); } }}>Favor experimental</Button>
                        <Button className={"SEVCO:00202" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00202" === biasDirectionSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasDirectionSelectedState === "SEVCO:00202") { setBiasDirectionSelectedState(""); } else { setBiasDirectionSelectedState("SEVCO:00202"); } }}>Favor comparator</Button>
                        <Button className={"SEVCO:00203" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00203" === biasDirectionSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasDirectionSelectedState === "SEVCO:00203") { setBiasDirectionSelectedState(""); } else { setBiasDirectionSelectedState("SEVCO:00203"); } }}>Towards null</Button>
                        <Button className={"SEVCO:00204" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00204" === biasDirectionSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasDirectionSelectedState === "SEVCO:00204") { setBiasDirectionSelectedState(""); } else { setBiasDirectionSelectedState("SEVCO:00204"); } }}>Away from null</Button>
                        <Button className={"SEVCO:00205" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00205" === biasDirectionSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasDirectionSelectedState === "SEVCO:00205") { setBiasDirectionSelectedState(""); } else { setBiasDirectionSelectedState("SEVCO:00205"); } }}>Unpredictable</Button>
                        {/*<Button className={"SEVCO:00201" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00201" === biasDirectionSelectedState} style={{border: "2px solid #000000"}} onClick={() => { setBiasDirectionSelectedState("SEVCO:00201"); }}>Favor experimental</Button>
                  <Button className={"SEVCO:00202" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00202" === biasDirectionSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setBiasDirectionSelectedState("SEVCO:00202"); }}>Favor comparator</Button>
                  <Button className={"SEVCO:00203" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00203" === biasDirectionSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setBiasDirectionSelectedState("SEVCO:00203"); }}>Towards null</Button>
                  <Button className={"SEVCO:00204" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00204" === biasDirectionSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setBiasDirectionSelectedState("SEVCO:00204"); }}>Away from null</Button>
                  <Button className={"SEVCO:00205" === biasDirectionSelectedState ? "" : "whiteButton"} primary={"SEVCO:00205" === biasDirectionSelectedState} style={{border: "2px solid #000000", borderLeft: "0px"}} onClick={() => { setBiasDirectionSelectedState("SEVCO:00205"); }}>Unpredictable</Button>*/}
                      </Button.Group><br /><br />

                      <b>Rate risk of bias: </b><Button.Group size='small'>
                        <Button className={"SEVCO:00186" === biasRiskSelectedState ? "" : "whiteButton"} primary={"SEVCO:00186" === biasRiskSelectedState} style={{ border: "2px solid #000000" }} onClick={() => { if (biasRiskSelectedState === "SEVCO:00186") { setBiasRiskSelectedState(""); } else { setBiasRiskSelectedState("SEVCO:00186"); } }}>Low</Button>
                        <Button className={"SEVCO:00187" === biasRiskSelectedState ? "" : "whiteButton"} primary={"SEVCO:00187" === biasRiskSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasRiskSelectedState === "SEVCO:00187") { setBiasRiskSelectedState(""); } else { setBiasRiskSelectedState("SEVCO:00187"); } }}>Moderate</Button>
                        <Button className={"SEVCO:00188" === biasRiskSelectedState ? "" : "whiteButton"} primary={"SEVCO:00188" === biasRiskSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasRiskSelectedState === "SEVCO:00188") { setBiasRiskSelectedState(""); } else { setBiasRiskSelectedState("SEVCO:00188"); } }}>High</Button>
                        <Button className={"SEVCO:00190" === biasRiskSelectedState ? "" : "whiteButton"} primary={"SEVCO:00190" === biasRiskSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasRiskSelectedState === "SEVCO:00190") { setBiasRiskSelectedState(""); } else { setBiasRiskSelectedState("SEVCO:00190"); } }}>Critical</Button>
                        <Button className={"SEVCO:00192" === biasRiskSelectedState ? "" : "whiteButton"} primary={"SEVCO:00192" === biasRiskSelectedState} style={{ border: "2px solid #000000", borderLeft: "0px" }} onClick={() => { if (biasRiskSelectedState === "SEVCO:00192") { setBiasRiskSelectedState(""); } else { setBiasRiskSelectedState("SEVCO:00192"); } }}>Undetermined</Button>
                      </Button.Group><br /><br />

                      <div style={{ padding: "6px" }}></div>
                      <table className="noStyleTable"><tbody><tr>
                        <td style={{ verticalAlign: "top", paddingTop: "10px" }}><b>Explain your rationale: </b></td>
                        <td><TextField style={{ width: "600px" }} multiline className="inputField" type='text' label={'Summarize the rationale for your rating'} size="small" variant='outlined' value={explainRationaleState} onChange={(e) => { setExplainRationaleState(e.target.value); }} /></td>
                      </tr></tbody></table>
                      <br />

                      <table className="noStyleTable"><tbody>
                        {notesState.map((note, noteIndex) => {
                          return <tr key={noteIndex}>
                            <td>
                              <b>Note{notesState.length > 1 && <> {noteIndex + 1}</>}: </b>
                            </td>
                            <td>
                              <TextField style={{ width: "560px" }} multiline className="inputField" type='text' label='' size="small" variant='outlined' value={note} onChange={(e) => { setNotesState(prevState => { prevState[noteIndex] = e.target.value; return [...prevState]; }) }} />
                            </td>
                          </tr>
                        })}
                      </tbody></table>
                      <><Button className="formButton" style={{ color: "#000000", marginTop: "4px", padding: "10px" }} content={notesState.length == 0 ? "+ Add Note" : "+ Add Another Note"} onClick={() => { setNotesState(prevState => { prevState.push(""); return [...prevState]; }) }} /> (Add any number of footnotes or explanatory notes)</>
                      {/*<Button.Group size='small'>
                  {factorPresenceOptions.map((option, optionIndex) => {
                    let className = "whiteButton";
                    let primary = false;
                    let buttonStyle = {border: "2px solid #000000"};
                    if (optionIndex !== 0) {
                      buttonStyle.borderLeft = "0px";
                    }
                    if (option.value === factorPresenceSelectedState) {
                      className = "";
                      //buttonStyle.backgroundColor = "#4169E1";
                      primary = true;
                    }
                    return <Button className={className} style={buttonStyle} primary={primary} onClick={() => { console.log(option.value); setFactorPresenceSelectedState(option.value); }}>{option.text}</Button>
                  })}
                </Button.Group><br/><br/>*/}
                    </div>
                    <div style={{ float: "right" }}>
                      <ComponentTree json={json} />
                    </div>
                  </div>
                  <br />
                  <br />
                  {termInformationState.conceptCode === fhirEntryState.conceptCode &&
                    <div style={{ border: "2px solid #555555", backgroundColor: "#FDFDFD", padding: "6px" }}>
                      {fhirEntryState.conceptName && <><b>Preferred term:</b> {termInformationState.preferredTerm || fhirEntryState.conceptName}<br /><br /></>}
                      {termInformationState.alternativeTerms?.length > 0 && <>
                        <b>Alternative term{termInformationState.alternativeTerms.length > 1 && <>s</>}: </b>
                        <ul style={{ margin: "0px" }}>{termInformationState.alternativeTerms.map((alternativeTerm, alternativeTermIndex) => { return <li key={alternativeTermIndex}>{alternativeTerm}</li> })}</ul>
                        <br />
                      </>
                      }
                      {fhirEntryState.conceptCode && <><b>Code:</b> {fhirEntryState.conceptCode}<br /><br /></>}
                      {(termInformationState.definition || fhirEntryState.conceptDefinition) && <><b>Definition:</b> {termInformationState.definition || fhirEntryState.conceptDefinition}<br /><br /></>}
                      {termInformationState.commentForApplication && <div><b>Comment for application: </b><DisplayFromFHIR markdown={termInformationState.commentForApplication} /><br /><br /></div>}
                      {/*termInformationState.commentsForApplication?.map((comment, commentIndex) => {
                  return <div key={commentIndex}><b>Comment for application: </b> {comment}<br/></div>
                })*/}
                    </div>
                  }
                  {/*<ChildConceptsTable fhirEntryState={fhirEntryState} />*/}
                  <br /><br />
                  {/*<div style={{width: "100%"}}><Button style={{color: "#000000", float: "right"}} className="formButton" content="Delete Concept" onClick={() => {
                if (window.confirm("Are you sure you want to permanately remove the term \"" + concept.display + "\" and all related concepts including child concepts (if any)?")) {
                  setFhirEntryState(prevState  => {  return { ...prevState, "conceptDelete": true, termChanged: true }; });
                }
              } } /></div>
            <br/><br/>*/}
                </>
              }
            </>
            :
            <>
              <h2 style={{ margin: "0px" }}>{fhirEntryState.conceptName} {(fhirEntryState["conceptProperties"]["approval"] === "" || fhirEntryState["conceptProperties"]["approval"] === undefined) && <span style={{ fontWeight: "normal", fontSize: "18px" }}> (draft)</span>}</h2>
              <br />
              <span style={{ whiteSpace: "pre-wrap" }}>
                {fhirEntryState.conceptCode && <><b>Code:</b> {fhirEntryState.conceptCode}<br /><br /></>}
                {fhirEntryState.conceptName && <><b>Preferred term:</b> {fhirEntryState.conceptName}<br /><br /></>}
                {fhirEntryState.conceptPropertyComment && <><b>Comment for application: </b> <DisplayFromFHIR markdown={fhirEntryState.conceptPropertyComment} /><br /><br /></>}
                <ComponentTree json={json} />
                <br />
                {//Object.keys(fhirEntryState["conceptProperties"]).map((propertyKey, propertyIndex) => {
                  fhirEntryState.propertyCodes.map((propertyKey, propertyIndex) => {
                    let property = fhirEntryState["conceptProperties"][propertyKey];
                    let propertyBlock = <span key={propertyIndex}>{property && <><b>{fhirEntryState.propertyDescriptions[propertyKey]}:</b> {property}<br /><br /></>}</span>;
                    if (propertyKey === "comment") {
                      propertyBlock = <span key={propertyIndex}></span>
                    }
                    return propertyBlock;
                  })}
                <br />
              </span>
              {/*<ChildConceptsTable fhirEntryState={fhirEntryState} />*/}
              <br />
              <span><b>JSON</b> <span className={"unselectable"} style={{ cursor: "pointer" }} onClick={() => { setFhirEntryState(prevState => { return { ...prevState, conceptJsonExpanded: !prevState.conceptJsonExpanded }; }) }}>{fhirEntryState.conceptJsonExpanded ? <>▼</> : <>►</>}</span></span>
              <br />
              {fhirEntryState.conceptJsonExpanded && <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>{concept && JSON.stringify(concept, null, 2)}</div>}
            </>
          }
        </>
        :
        <>
          <div style={{ border: "3px solid #DAA520", padding: "6px", backgroundColor: "#FCFAEF" }}>
            <span>Instructions: </span>
            <ol style={{ margin: "4px" }}>
              <li>Select a "<b>Type of Bias</b>" on the left panel to rate.
                <ul>
                  <li>Use the hierarchy or your browser's find function (CTRL+F) to select a term.</li>
                  <li>To report your overall rating, select "<b>Bias</b>" at the top of the left panel.</li>
                </ul>
              </li>
              <li>Click <b>Update</b> (the green button at the bottom left), using Draft when incomplete and Publish when complete.</li>
            </ol>
          </div>
          <br />
          <br />
          {/*<ChildConceptsTable fhirEntryState={fhirEntryState} />*/}
        </>
      }

    </div>
  </div>
}

export default RobatTermDisplay;