import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { FormControlLabel, Checkbox } from '@mui/material';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import './App.css';
import { cpLogin, continuedSessionLogin } from "./UserAccountFunctions";
import FevirContext from './FevirContext';

const TermsOfAgreementPage = ({ useTitle }) => {

  useTitle("FEvIR Terms of Agreement");
  const globalContext = useContext(FevirContext);
  const [agreementState, setAgreementState] = useState({ termsofuseacceptCheckbox: false, datapersonalagreementacceptCheckbox: false });

  const editAgreementState = (newValue, field) => {
    setAgreementState(prevState => {
      return {
        ...prevState,
        //agreement: newValue,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const FirstSignIn = async () => {
    let idToken;
    if (globalContext.userState?.idToken) {
      idToken = globalContext.userState.idToken;
    }
    if (!idToken) {
      try {
        idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
      } catch { }
    }
    if (!idToken) {
      idToken = await continuedSessionLogin(globalContext, undefined, undefined);
    }
    if (idToken) {
      await cpLogin(idToken, globalContext, (agreementState.termsofuseacceptCheckbox && agreementState.datapersonalagreementacceptCheckbox));
    }

  }

  useEffect(() => {
    console.log(globalContext.userState?.waitingForVerificationEmail);
    if (!globalContext.userState.displaythetermsofagreement) {
      history.push("/editprofile");
    }
  }, [globalContext.userState]);


  return (
    <div>
      <br />
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        {firebase.auth().currentUser?.emailVerified === false || globalContext.userState?.waitingForVerificationEmail ? 
          <h2>Please verify your email. Check your spam folder. (If you already did verify please refresh the page.)</h2>
        :
        <div>
          <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Terms of Agreement</h2>
          <Button style={{ position: "absolute", right: "3px", top: "3px" }} className="formButton negative" content="✖" onClick={() => { history.push("/"); }} />
          <br /><br />
          <FormControlLabel
            control={<Checkbox style={{ pointerEvents: "auto" }} name="termsofuseaccept" inputProps={{ 'aria-label': 'Agree to the Terms of Use' }} color="primary" checked={agreementState.termsofuseacceptCheckbox}
              onChange={(e) => { editAgreementState(e.target.checked, "termsofuseacceptCheckbox"); }} />}
            label={<span>I agree to the <a href="/termsofuse" target="_blank" rel="noopener noreferrer" style={{ pointerEvents: "auto" }}>Acceptable Use Policy</a></span>}
          />
          <br />
          <FormControlLabel
            style={{ pointerEvents: "none" }}
            control={<Checkbox style={{ pointerEvents: "auto" }} name="datapersonalagreementaccept" inputProps={{ 'aria-label': 'Agree to the Personal Data Agreement Terms' }} color="primary" checked={agreementState.datapersonalagreementacceptCheckbox}
              onChange={(e) => { editAgreementState(e.target.checked, "datapersonalagreementacceptCheckbox"); }} />}
            label={<span>I agree to the <a href="/personaldataagreement" target="_blank" rel="noopener noreferrer" style={{ pointerEvents: "auto" }}>Privacy Policy Agreement</a></span>}
          />
          <br /><br />
          <Button className="formButton positive" content="Agree to the Terms" compact onClick={FirstSignIn} disabled={!(agreementState.termsofuseacceptCheckbox && agreementState.datapersonalagreementacceptCheckbox)} />  
        </div>
        }
      </Segment>
      <br /><br /><br /><br />
    </div>
  );
};

export default TermsOfAgreementPage;