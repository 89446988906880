import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, Dropdown, Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import GenericToggleButtons from './GenericToggleButtons';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const CreateNewCitationPage = ({ useTitle }) => {

  useTitle("FEvIR Citation Builder");

  const globalContext = useContext(FevirContext);

  const [fhirEntryState, setFhirEntryState] = useState({ "title": "", "description": "", "citationResourceContributors": [{ "name": globalContext.userState.name, "role": "Creator" }], "status": "active" });

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState => {
      return {
        ...prevState,
        //fhirEntry: newValue,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const submitResource = async () => {

    if (fhirEntryState.title.trim() === "") {
      alert("title field is missing");
      return;
    }

    let fhirEntry = {
      "resourceType": "Citation",
      "title": fhirEntryState.title,
      "description": fhirEntryState.description,
      "useContext": [{
        "code": {
          "system": "http://hl7.org/fhir/citation-classification-type",
          "code": "fevir-platform-use",
          "display": "FEvIR Platform Use"
        },
        "valueCodeableConcept":
        {
          "coding": [
            {
              "system": "http://hl7.org/fhir/citation-artifact-classifier",
              "code": "project-specific",
              "display": "Project Specific"
            }
          ]
        }
      }],
      "citedArtifact": {}
    };

    let contributorRolesOrganized = { "author": [], "editor": [], "reviewer": [], "endorser": [] }
    for (let x in fhirEntryState.citationResourceContributors) {
      let role = fhirEntryState.citationResourceContributors[x].role;
      if (role === "Creator") {
        contributorRolesOrganized.author.push(fhirEntryState.citationResourceContributors[x]);
      } else if (role === "Editor") {
        contributorRolesOrganized.editor.push(fhirEntryState.citationResourceContributors[x]);
      } else if (role === "Reviewer") {
        contributorRolesOrganized.reviewer.push(fhirEntryState.citationResourceContributors[x]);
      } else if (role === "Endorser") {
        contributorRolesOrganized.endorser.push(fhirEntryState.citationResourceContributors[x]);
      }
    }
    for (let key in contributorRolesOrganized) {
      for (let x in contributorRolesOrganized[key]) {
        if (fhirEntry[key] === undefined) {
          fhirEntry[key] = [];
        }
        if (x >= fhirEntry[key].length) {
          fhirEntry[key].push({});
        }
        if (fhirEntry[key][x].name !== contributorRolesOrganized[key][x].name) {
          fhirEntry[key][x] = {};
        }
        fhirEntry[key][x].name = contributorRolesOrganized[key][x].name;
      }
    }

    let fhirEntryString = JSON.stringify(fhirEntry, null, 2);
    const body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': fhirEntryString,
      'title': fhirEntryState.title,
      'status': fhirEntryState.status,
    };

    let response = await submitToFevirServer(globalContext, 5000, body, true, false);

    if (response.success) {
      history.push(`/resources/${response.resourcetype}/${response.formstateid}`);
    }

  };

  useEffect(() => {
    changeFhirEntryState([{ "name": globalContext.userState.name, "role": "Creator" }], "citationResourceContributors");
  }, [globalContext.userState]);

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>FEvIR<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: Citation Builder</h2>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <br /><br />
        {globalContext.userState.firebaseuid ?
          <>
            <TextField style={{ width: "760px" }} multiline className="inputField" type='text' label={'Citation Title'} size="small" variant='outlined' value={fhirEntryState.title} onChange={(e) => { changeFhirEntryState(e.target.value, "title"); }} />
            <br /><br />
            <TextField style={{ width: "760px" }} multiline className="inputField" type='text' label={'Citation Description'} size="small" variant='outlined' value={fhirEntryState.description} onChange={(e) => { changeFhirEntryState(e.target.value, "description"); }} />
            <br /><br />
            <span>
              <b style={{ fontSize: "1.1em" }}>Contributors: </b>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Contributor Name</Table.HeaderCell>
                    <Table.HeaderCell>Contributor Role</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {fhirEntryState.citationResourceContributors.map((contributorEntry, index) => {
                    return <Table.Row key={index}>
                      <Table.Cell><TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={"Name"} size="small" variant='outlined' value={contributorEntry.name || ''} onChange={(e) => { setFhirEntryState(prevState => { let newArray = prevState.citationResourceContributors; newArray[index].name = e.target.value; return { ...prevState, citationResourceContributors: newArray }; }); }} /></Table.Cell>
                      <Table.Cell>
                        <Dropdown
                          name="citationResourceContributorRole"
                          placeholder="Role"
                          closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                          style={{ width: "100px" }}
                          options={[{ key: "Creator", text: "Creator", value: "Creator" }, { key: "Editor", text: "Editor", value: "Editor" }, { key: "Reviewer", text: "Reviewer", value: "Reviewer" }, { key: "Endorser", text: "Endorser", value: "Endorser" }]}
                          value={contributorEntry.role || ''}
                          onChange={(e, data) => {
                            setFhirEntryState(prevState => { let newArray = prevState.citationResourceContributors; newArray[index].role = data.value; return { ...prevState, citationResourceContributors: newArray }; });
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>;
                  })
                  }
                </Table.Body>
              </Table>

              <Button className="formButton" style={{ color: "#000000" }} content="+ Add Contributor" onClick={() => { setFhirEntryState(prevState => { let prevField = prevState["citationResourceContributors"]; prevField.push({ "role": "", "name": "" }); return { ...prevState, "citationResourceContributors": prevField }; }); }} />
            </span>
            <br /><br />
            {/* Move this into a module because it's used for both JsonEntryPage and ResourceDisplayPage */}
            
            <GenericToggleButtons thin={true} boxes={true} togglable={false}
              values={["active", "draft"]} labels={["Publish (anyone can view)", "Draft (only editors can view)"]} fieldLabel={"status"} elementName={"status"}
              startingValue={fhirEntryState.status} setter={setFhirEntryState}
            />
            <br />
            <br />
            <Button className="formButton positive" content="Submit" compact onClick={submitResource} />
          </>
          :
          <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
        }
      </Segment>
    </div>
  );
};

export default CreateNewCitationPage;