import React, { useState, useEffect, memo } from 'react';
import { Button, Table, Modal } from 'semantic-ui-react';
import { DataEntry } from './DataEntryFormFunctions';
import { autoEditEmptyReason, DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { getFoiFromReference } from './ResourceDictionaryFunctions';
import { getStringFromFHIR, DisplayFromFHIR, clearDivWrapper } from './ResourceFunctions';
import {
  emptyReasonNotStarted, emptyReasonValueSet, sectionCodeSummaryOfFindingsForSingleOutcome,
  sectionCodePopulation, sectionCodeInterventionGroup,
  sectionCodeComparatorGroup, sectionCodeGroupAssignment,
  sectionCodeResults, emptyTextNoData,
  compositionTypeCodeOutcomeMeasureSynthesisReport,
  sectionCodeResultWithComparatorAlone,
  sectionCodeResultWithInterventionAlone,
  sectionCodeResultWithInterventionVsComparator
} from './CodeSystemLookup';
import { generateEvidenceSummary, generateEvidenceVariableSummary, generateGroupSummary, generateVariablesSummary } from './GenerateNarrativeSummaryFunctions';
import submitToFevirServer from './SubmitToFevirServer';
import { handleAdaptChange } from './AdaptItemDataEntryFunctions';
import { NarrativeEntry } from './NarrativeEntry';
import { generateSectionTextForEffectEstimate, generateSectionTextForPercentage } from './SummaryOfFindingsAuthoringTool';
import { SingleEvidenceEntry } from './CompositionEvidenceTableAuthor';

const handleChange = (name, value, setResourceState) => {
  setResourceState(prevState => { return { ...prevState, [name]: value } });
}

const EditTableCellModal = memo(({ setOpenEditModalState, setResourceState, generateTextDivFunction, entryHeader,
  endSectionState, entryStartingResourceType, resourceDictionary, globalContext, sectionCode, setProfile,
  statisticType, statisticUnit, knownDataCount, entryAddElementValues, setSourceJsonState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState, resourceState }) => {

  const submitChange = () => {
    setOpenEditModalState(prevState => { return { ...prevState, openModal: false }; });
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setOpenEditModalState(prevState => { return { ...prevState, openModal: false }; });
      }} />
    <h3>Edit Table Cell Content</h3>
    <br />
    <NarrativeEntry elementName='text' fieldLabel='Section Summary'
      editTextStatus={false} editTextDiv={true}
      generateSummaryFunction={generateTextDivFunction}
      entryFoi={getFoiFromReference(endSectionState.entry, entryStartingResourceType, resourceDictionary)}
      resourceDictionary={resourceDictionary} globalContext={globalContext}
      startingValue={endSectionState.text} setResourceState={setResourceState}
      path={sectionCode}
      adaptationReportState={adaptationReportState}
      setAdaptationReportState={setAdaptationReportState}
      setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    <br /><br />
    {entryHeader ?
      <h3>{entryHeader}</h3>
      :
      <h3>Change Resource References for the Section</h3>
    }
    <br />
    <div style={{ marginLeft: "24px" }}>
      {entryStartingResourceType === "Evidence" ?
        <SingleEvidenceEntry startingValue={endSectionState.entry}
          statisticType={statisticType} statisticUnit={statisticUnit}
          knownDataCount={knownDataCount}
          addElementValues={entryAddElementValues}
          resourceDictionary={resourceDictionary} globalContext={globalContext}
          setResourceState={setResourceState} resourceState={resourceState}
          setSourceJsonState={setSourceJsonState}
          path={sectionCode + '.entry'}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        :
        <DataEntry asArray={true} datatype='Reference'
          elementName='entry' fieldLabel={entryHeader}
          startingValue={endSectionState.entry}
          setProfile={setProfile} startingResourceType={entryStartingResourceType}
          referencedResourceTypes={[entryStartingResourceType]}
          enableCreation={true}
          setResourceState={setResourceState}
          path={sectionCode + '.entry'}
          adaptationReportState={adaptationReportState}
          setAdaptationReportState={setAdaptationReportState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      }
    </div>
    {((!endSectionState.section || endSectionState.section.length === 0) &&
      (!endSectionState.entry || endSectionState.entry.length === 0) &&
      (!endSectionState.text || endSectionState.text.status === "empty")) && <>
        <h3>Explain why this Table Cell is empty</h3>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={endSectionState.emptyReason} valueSet={emptyReasonValueSet}
            startCollapsed={true}
            setResourceState={setResourceState}
            path={sectionCode + '.emptyReason'}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </div>
      </>}
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Changes" positive
      onClick={submitChange}
    />
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={true}
      centered={false}
      content={modalContent}
    />
  )
});

const InnerSectionDataEntry = memo(({ startingValue, elementName, setResourceState,
  entryHeader, entrySetProfile, variableDefinition, resourceDictionary, setSourceJsonState,
  resourceType, resourceState, suggestedEvidenceTitle,
  globalContext, statisticType, statisticUnit, knownDataCount,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState,
  parentSectionCode, sectionArrayIndex, generateTextDivFunction }) => {
  let sectionCode;
  if (adaptationReportState?.pathList && startingValue?.code) {
    if (sectionArrayIndex || sectionArrayIndex === 0) {
      if (startingValue.code.text) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section[" + sectionArrayIndex + "]:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section[" + sectionArrayIndex + "]:" + JSON.stringify(startingValue.code);
      }
    } else {
      if (startingValue.code.text) {
        sectionCode = "section:" + startingValue.code.text;
      } else if (startingValue.code.coding?.[0]) {
        sectionCode = "section:" + startingValue.code.coding[0].system + "#" + startingValue.code.coding[0].code;
      } else {
        sectionCode = "section:" + JSON.stringify(startingValue.code);
      }
    }
    if (parentSectionCode) {
      sectionCode = parentSectionCode.split(":")[0] + "." + sectionCode;
    }
  }

  let compositionId = resourceState.id;
  let compositionTitle = resourceState.title || resourceState.name;

  let entryAddElementValues;
  if (resourceType === "Evidence") {
    entryAddElementValues = {
      title: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.title || suggestedEvidenceTitle,
      description: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.description || "",
      variableDefinition: variableDefinition || "",
      statistic: resourceDictionary?.[startingValue?.entry?.[0]?.reference?.split("/")[1]]?.statistic || ""
    };
  }

  if (entryAddElementValues) {
    if (!entryAddElementValues.relatedArtifact && !entryAddElementValues.relatesTo) {
      entryAddElementValues.relatedArtifact = [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ];
    }
  } else {
    entryAddElementValues = {
      relatedArtifact: [
        {
          "type": "part-of",
          "resourceReference": {
            "reference": "Composition/" + compositionId,
            "type": "Composition",
            "display": compositionTitle
          }
        }
      ]
    };
  }

  let timestamp = new Date();
  let startingSection = {
    title: "", code: { "text": "section created " + timestamp },
    author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [endSectionState, setEndSectionState] = useState(JSON.parse(JSON.stringify(startingSection || {})));
  const [openEditModalState, setOpenEditModalState] = useState({ "openModal": false });

  useEffect((() => {
    if (Object.keys(endSectionState).length > 0) {
      let newSection = {};
      if (endSectionState.extension) { newSection.extension = endSectionState.extension; }
      if (endSectionState.title) { newSection.title = endSectionState.title; }
      if (endSectionState.code) { newSection.code = endSectionState.code; }
      if (Array.isArray(endSectionState.author) && endSectionState.author.length > 0) {
        newSection.author = endSectionState.author;
      }
      if (endSectionState.focus) { newSection.focus = endSectionState.focus; }
      if (endSectionState.text) { newSection.text = endSectionState.text; }
      if (endSectionState.orderedBy) { newSection.orderedBy = endSectionState.orderedBy; }
      if (Array.isArray(endSectionState.entry) && endSectionState.entry.length > 0) {
        newSection.entry = endSectionState.entry;
      }
      if (endSectionState.emptyReason) { newSection.emptyReason = endSectionState.emptyReason; }
      if (Array.isArray(endSectionState.section) && endSectionState.section.length > 0) {
        newSection.section = endSectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0) {
        newSection = null;
      }
      handleChange(elementName, newSection, setResourceState);
    }
  }), [endSectionState]);

  if (!openEditModalState.openModal) {
    return <div>
      {startingValue && <div style={{ marginLeft: "24px" }}>
        {startingSection.text?.div &&
          <div><b>Summary: </b><DisplayFromFHIR xhtml={startingSection.text.div} /></div>}
        {startingSection.entry?.length > 0 && <>
          {startingSection.entry.length > 1 ? <><br />Contains {startingSection.entry.length} entries</>
            :
            <><br /><DisplayFromFHIR reference={startingSection.entry[0]} /></>}
        </>}
        <br />
      </div>}
      &nbsp;&nbsp;
      <span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => {
          setOpenEditModalState({ openModal: true });
        }}>
        {startingValue ? <>✎ Edit</> : <>➕ Add</>}
      </span>
    </div>
  } else {
    return <EditTableCellModal endSectionState={endSectionState}
      entryStartingResourceType={resourceType} resourceDictionary={resourceDictionary}
      setOpenEditModalState={setOpenEditModalState} globalContext={globalContext}
      generateTextDivFunction={generateTextDivFunction} sectionCode={parentSectionCode}
      setResourceState={setEndSectionState} setProfile={entrySetProfile}
      statisticType={statisticType} statisticUnit={statisticUnit}
      knownDataCount={knownDataCount} entryHeader={entryHeader}
      addElementValues={entryAddElementValues}
      setSourceJsonState={setSourceJsonState} resourceState={resourceState}
      adaptationReportState={adaptationReportState}
      setAdaptationReportState={setAdaptationReportState}
      setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
  }
});

const InnerSectionArrayDataEntry = memo(({ startingValue, setResourceState,
  setSourceJsonState, resourceDictionary, resourceState, globalContext,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState,
  parentSectionCode }) => {

  let populationSection = null;
  let interventionGroupSection = null;
  let comparatorGroupSection = null;
  let groupAssignmentSection = null;
  let comparatorResultSection = null;
  let interventionResultSection = null;
  let effectEstimateSection = null;
  let startingSectionStateValues = {};
  let defaultStatisticType = "";
  let defaultStatisticUnits = "";
  let comparatorGroupReference;
  let interventionGroupReference;
  let populationReference;
  let groupAssignmentReference;
  if (startingValue) {
    for (let itemIndex in startingValue.section) {
      let subsection = startingValue.section[itemIndex];
      let subsectionCode = subsection?.code?.coding?.[0]?.code;
      if (subsectionCode === "result-with-comparator-alone") {
        comparatorResultSection = subsection;
        startingSectionStateValues["comparatorResultSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "result-with-intervention-alone") {
        interventionResultSection = subsection;
        startingSectionStateValues["interventionResultSection"] = subsection;
        if (!defaultStatisticType && resourceDictionary && subsection.entry?.[0]?.reference &&
          subsection.entry[0].reference.split("/")[1] &&
          resourceDictionary[subsection.entry[0].reference.split("/")[1]]) {
          let evidenceJson = resourceDictionary[subsection.entry[0].reference.split("/")[1]];
          if (evidenceJson.statistic?.[0]?.statisticType) {
            defaultStatisticType = evidenceJson.statistic[0].statisticType;
            if (!defaultStatisticUnits && evidenceJson.statistic?.[0]?.quantity?.unit) {
              defaultStatisticUnits = evidenceJson.statistic[0].quantity.unit;
            }
          }
        }
      } else if (subsectionCode === "population") {
        populationSection = subsection;
        startingSectionStateValues["populationSection"] = subsection;
        populationReference = subsection.entry?.[0];
      } else if (subsectionCode === "intervention-group") {
        interventionGroupSection = subsection;
        startingSectionStateValues["interventionGroupSection"] = subsection;
        interventionGroupReference = subsection.entry?.[0];
      } else if (subsectionCode === "comparator-group") {
        comparatorGroupSection = subsection;
        startingSectionStateValues["comparatorGroupSection"] = subsection;
        comparatorGroupReference = subsection.entry?.[0];
      } else if (subsectionCode === "GroupAssignment") {
        groupAssignmentSection = subsection;
        startingSectionStateValues["groupAssignmentSection"] = subsection;
        groupAssignmentReference = subsection.entry?.[0];
      } else if (subsectionCode === "result-with-intervention-vs-comparator") {
        effectEstimateSection = subsection;
        startingSectionStateValues["effectEstimateSection"] = subsection;
      }
    }
  }
  let comparatorVariableDefinition = [
    {
      "variableRole": "population",
      "observed": comparatorGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparatorEvidenceTitle = startingValue.focus.display + " with comparator";
  let interventionVariableDefinition = [
    {
      "variableRole": "population",
      "observed": interventionGroupReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedInterventionEvidenceTitle = startingValue.focus.display + " with intervention";
  let comparativeVariableDefinition = [
    {
      "variableRole": "population",
      "observed": populationReference
    },
    {
      "variableRole": "exposure",
      "comparatorCategory": comparatorGroupSection?.title || comparatorGroupReference?.display || "",
      "observed": groupAssignmentReference
    },
    {
      "variableRole": "outcome",
      "observed": startingValue.focus
    }
  ];
  let suggestedComparativeEvidenceTitle = startingValue.focus.display + " comparing intervention vs. comparator";

  const [titleState, setTitleState] = useState({ title: startingValue.title || "" });
  const [focusState, setFocusState] = useState({ focus: startingValue.focus || null })
  const [sectionState, setSectionState] = useState(JSON.parse(JSON.stringify(startingSectionStateValues || {})));
  const [statisticDefaultsState, setStatisticDefaultsState] = useState({
    "statisticType": "", "units": ""
  });

  useEffect(() => {
    let newTitle = titleState.title || "";
    setResourceState(prevState => { return { ...prevState, "title": newTitle } });
  }, [titleState]);

  useEffect(() => {
    let newFocus = focusState.focus || "";
    setResourceState(prevState => { return { ...prevState, "focus": newFocus } });
  }, [focusState]);

  useEffect(() => {
    let newSection = [];
    for (const key in sectionState) {
      newSection.push(sectionState[key]);
    }
    if (newSection && Object.keys(newSection).length) {
      setResourceState(prevState => { return { ...prevState, "section": newSection } });
    }
  }, [sectionState]);

  return <>
    <Table.Cell style={{ verticalAlign: "top" }}>
      <DataEntry datatype="string" elementName="title" fieldLabel="Outcome Name"
        startingValue={titleState.title || focusState.focus?.display}
        setResourceState={setTitleState}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} />
      <DataEntry datatype="Reference" startCollapsed
        startingValue={focusState.focus || null}
        elementName={"focus"} setResourceState={setFocusState}
        fieldLabel="Outcome Resource" startingResourceType={"EvidenceVariable"}
        referencedResourceTypes={["EvidenceVariable"]}
        generateTextDivFunction={generateEvidenceVariableSummary}
        setProfile="VariableDefinition"
        resourceDictionary={resourceDictionary}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} />
      <p><span className={"unselectable"} style={{ cursor: "pointer" }}
        onClick={() => {
          setStatisticDefaultsState({
            "statisticType": defaultStatisticType || "",
            "units": defaultStatisticUnits || ""
          })
        }}>
        {(defaultStatisticType && !statisticDefaultsState.statisticType) &&
          <>➕ Add Default Statistic Type {getStringFromFHIR.CodeableConcept(defaultStatisticType)} and Units {defaultStatisticUnits || "none"}</>}
      </span></p>
    </Table.Cell>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {populationSection && <InnerSectionDataEntry startingValue={sectionState.populationSection}
        elementName={"populationSection"} setResourceState={setSectionState}
        entryHeader="population" resourceType={"Group"}
        generateTextDivFunction={generateGroupSummary}
        resourceDictionary={resourceDictionary}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={0} />}
    </Table.Cell>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {interventionGroupSection && <InnerSectionDataEntry startingValue={sectionState.interventionGroupSection}
        elementName={"interventionGroupSection"} setResourceState={setSectionState}
        entryHeader="intervention group" resourceType={"Group"}
        generateTextDivFunction={generateGroupSummary}
        resourceDictionary={resourceDictionary}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={1} />}
    </Table.Cell>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {comparatorGroupSection && <InnerSectionDataEntry startingValue={sectionState.comparatorGroupSection}
        elementName={"comparatorGroupSection"} setResourceState={setSectionState}
        entryHeader="comparator group" resourceType={"Group"}
        generateTextDivFunction={generateGroupSummary}
        resourceDictionary={resourceDictionary}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={2} />}
    </Table.Cell>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {groupAssignmentSection && <InnerSectionDataEntry startingValue={sectionState.groupAssignmentSection}
        elementName={"groupAssignmentSection"} setResourceState={setSectionState}
        entryHeader="group assignment" resourceType={"EvidenceVariable"}
        generateTextDivFunction={generateEvidenceVariableSummary}
        entrySetProfile="GroupAssignment"
        resourceDictionary={resourceDictionary}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={3} />}
    </Table.Cell>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {comparatorResultSection && <InnerSectionDataEntry startingValue={sectionState.comparatorResultSection}
        elementName={"comparatorResultSection"} setResourceState={setSectionState}
        entryHeader="result with comparator alone" entrySetProfile={"NoncomparativeEvidence"}
        resourceType="Evidence"
        generateTextDivFunction={generateEvidenceSummary}
        variableDefinition={comparatorVariableDefinition}
        suggestedEvidenceTitle={suggestedComparatorEvidenceTitle} resourceDictionary={resourceDictionary}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={4} />}
    </Table.Cell>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {interventionResultSection && <InnerSectionDataEntry startingValue={sectionState.interventionResultSection}
        elementName={"interventionResultSection"} setResourceState={setSectionState}
        entryHeader="result with intervention alone" entrySetProfile={"NoncomparativeEvidence"}
        variableDefinition={interventionVariableDefinition}
        suggestedEvidenceTitle={suggestedInterventionEvidenceTitle} resourceDictionary={resourceDictionary}
        resourceType="Evidence"
        generateTextDivFunction={generateEvidenceSummary}
        statisticType={statisticDefaultsState.statisticType}
        statisticUnit={statisticDefaultsState.units}
        resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={5} />}
    </Table.Cell>
    <Table.Cell style={{ verticalAlign: "top" }}>
      {effectEstimateSection && <InnerSectionDataEntry startingValue={sectionState.effectEstimateSection}
        elementName={"effectEstimateSection"} setResourceState={setSectionState}
        entryHeader="effect estimate (intevention vs. comparator)" entrySetProfile={"ComparativeEvidence"}
        variableDefinition={comparativeVariableDefinition}
        generateTextDivFunction={generateEvidenceSummary}
        suggestedEvidenceTitle={suggestedComparativeEvidenceTitle} resourceDictionary={resourceDictionary}
        resourceType="Evidence" resourceState={resourceState}
        globalContext={globalContext} setSourceJsonState={setSourceJsonState}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState}
        parentSectionCode={parentSectionCode} sectionArrayIndex={6} />}
    </Table.Cell>
  </>
});

const TableRowEdit = memo(({ startingValue, setResourceState, sectionArrayIndex,
  setSourceJsonState, resourceDictionary, globalContext,
  resourceState, adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let startingSection = {
    title: "", code: sectionCodeSummaryOfFindingsForSingleOutcome,
    author: [], focus: "", text: "", orderedBy: "", entry: [], emptyReason: "", section: []
  }
  if (startingValue) {
    if (startingValue.extension) { startingSection.extension = startingValue.extension; }
    if (startingValue.title) { startingSection.title = startingValue.title; }
    if (startingValue.code) { startingSection.code = startingValue.code; }
    if (startingValue.author) { startingSection.author = startingValue.author; }
    if (startingValue.focus) { startingSection.focus = startingValue.focus; }
    if (startingValue.text) { startingSection.text = startingValue.text; }
    if (startingValue.orderedBy) { startingSection.orderedBy = startingValue.orderedBy; }
    if (startingValue.entry) { startingSection.entry = startingValue.entry; }
    if (startingValue.emptyReason) { startingSection.emptyReason = startingValue.emptyReason; }
    if (startingValue.section) { startingSection.section = startingValue.section; }
  }

  const [tableRowSectionState, setTableRowSectionState] = useState(JSON.parse(JSON.stringify(startingSection || {})));

  useEffect((() => {
    if (Object.keys(tableRowSectionState).length > 0) {
      let newSection = {};
      if (tableRowSectionState.extension) { newSection.extension = tableRowSectionState.extension; }
      if (tableRowSectionState.title) { newSection.title = tableRowSectionState.title; }
      if (tableRowSectionState.code) { newSection.code = tableRowSectionState.code; }
      if (Array.isArray(tableRowSectionState.author) && tableRowSectionState.author.length > 0) {
        newSection.author = tableRowSectionState.author;
      }
      if (tableRowSectionState.focus) { newSection.focus = tableRowSectionState.focus; }
      if (tableRowSectionState.text) { newSection.text = tableRowSectionState.text; }
      if (tableRowSectionState.orderedBy) { newSection.orderedBy = tableRowSectionState.orderedBy; }
      if (Array.isArray(tableRowSectionState.entry) && tableRowSectionState.entry.length > 0) {
        newSection.entry = tableRowSectionState.entry;
      }
      if (tableRowSectionState.emptyReason) { newSection.emptyReason = tableRowSectionState.emptyReason; }
      if (Array.isArray(tableRowSectionState.section) && tableRowSectionState.section.length > 0) {
        newSection.section = tableRowSectionState.section;
      }
      newSection = autoEditEmptyReason(newSection);
      if (Object.keys(newSection).length === 0 || (Object.keys(newSection).length === 1 && newSection.code)) {
        newSection = null;
      }
      newSection = JSON.parse(JSON.stringify(newSection));
      if (sectionArrayIndex || sectionArrayIndex === 0 || sectionArrayIndex === "0") {
        setResourceState(prevState => {
          return { ...prevState, [sectionArrayIndex]: newSection };
        });
      }
    }
  }), [tableRowSectionState]);

  return <InnerSectionArrayDataEntry startingValue={startingSection}
    setResourceState={setTableRowSectionState}
    resourceDictionary={resourceDictionary} resourceState={resourceState}
    globalContext={globalContext} setSourceJsonState={setSourceJsonState}
    adaptationReportState={adaptationReportState}
    setAdaptationReportState={setAdaptationReportState}
    setChangeAvailableToSaveState={setChangeAvailableToSaveState}
    parentSectionCode={"section[0].section[" + sectionArrayIndex + "]:"} />
});

const CompositionTableCellArrayEntry = ({ elementName, fieldLabel, startingValue, setResourceState,
  columnHeaders, dataEntryStyle, addTableRowModalState }) => {
  let startingArrayAsObject = {};
  if (startingValue) {
    for (let itemIndex in startingValue) {
      startingArrayAsObject[itemIndex] = startingValue[itemIndex];
      if (itemIndex === '0') {
        if (addTableRowModalState.newRowTitle ||
          !(typeof addTableRowModalState.newRowFocus === "object" && Object.keys(addTableRowModalState.newRowFocus).length === 1 && addTableRowModalState.newRowFocus["display"] === "[No data]")) {
          if (addTableRowModalState.newRowTitle) {
            startingArrayAsObject['0'].text = {
              "status": "generated",
              "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + addTableRowModalState.newRowTitle + "</p></div>"
            };
          }

          if (!(typeof addTableRowModalState.newRowFocus === "object" && Object.keys(addTableRowModalState.newRowFocus).length === 1 && addTableRowModalState.newRowFocus["display"] === "[No data]")) {
            startingArrayAsObject['0'].entry = [addTableRowModalState.newRowFocus];
          }
          delete startingArrayAsObject['0'].emptyReason;
        }
      }
    }
  } else {
    startingArrayAsObject['0'] = null;
  }

  const [arrayState, setArrayState] = useState(JSON.parse(JSON.stringify(startingArrayAsObject || {})));

  useEffect((() => {
    if (Object.keys(arrayState).length) {
      let newArray = [];
      for (let key of Object.keys(arrayState)) {
        if (arrayState[key] !== null && !(typeof arrayState[key] === "object" && Object.keys(arrayState[key]).length === 0) &&
          !(Array.isArray(arrayState[key]) && arrayState[key].length === 0) && arrayState[key] !== "" &&
          arrayState[key] !== undefined && arrayState[key] !== "DELETEME") {
          newArray.push(arrayState[key]);
        }
      }
      setResourceState(prevState => { return { ...prevState, [elementName]: newArray } });
    }
  }), [arrayState]);

  return <div>
    {fieldLabel && <p><b>{fieldLabel}: </b></p>}
    <div style={{ marginLeft: "24px" }}>
      {Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
        let title = keyValuePair[1].title;
        let columnHeader = columnHeaders[title];
        return <div key={keyValuePairIndex}>
          {keyValuePair[0] === "0" ? <></> :
            <DataEntry datatype="CompositionTableCell" elementName={keyValuePair[0]}
              fieldLabel={columnHeader} dataEntryStyle={dataEntryStyle} addTableRowModalState={addTableRowModalState}
              startingValue={keyValuePair[1]} setResourceState={setArrayState} />
          }
        </div>
      })}
    </div>
  </div>
};

const generateTableCellContent = async (sourceReferencesState, newRowSectionsState, setNewRowSectionsState, globalContext) => {

  let newRowSections = JSON.parse(JSON.stringify(newRowSectionsState.newRowSections));
  if (Array.isArray(newRowSections) && newRowSections.length > 0) {
    let outcomeDefinitionJson; //TODO -- this is not in the inner section anymore
    let populationJson;
    let interventionGroupJson;
    let comparatorGroupJson;
    let groupAssignmentJson;
    let comparatorOnlyEvidenceJson;
    let interventionOnlyEvidenceJson;
    let comparativeEvidenceJson;
    if (sourceReferencesState.outcomeDefinition) {
      let outcomeDefinitionFoi = sourceReferencesState.outcomeDefinition.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': outcomeDefinitionFoi,
        'resourcetype': "EvidenceVariable",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        outcomeDefinitionJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.population) {
      let populationFoi = sourceReferencesState.population.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': populationFoi,
        'resourcetype': "Group",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        populationJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.interventionGroup) {
      let interventionGroupFoi = sourceReferencesState.interventionGroup.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': interventionGroupFoi,
        'resourcetype': "Group",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        interventionGroupJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.comparatorGroup) {
      let comparatorGroupFoi = sourceReferencesState.comparatorGroup.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': comparatorGroupFoi,
        'resourcetype': "Group",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        comparatorGroupJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.groupAssignment) {
      let groupAssignmentFoi = sourceReferencesState.groupAssignment.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': groupAssignmentFoi,
        'resourcetype': "EvidenceVariable",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        groupAssignmentJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.comparatorOnlyEvidence) {
      let comparatorOnlyEvidenceFoi = sourceReferencesState.comparatorOnlyEvidence.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': comparatorOnlyEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        comparatorOnlyEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.interventionOnlyEvidence) {
      let interventionOnlyEvidenceFoi = sourceReferencesState.interventionOnlyEvidence.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': interventionOnlyEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        interventionOnlyEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    if (sourceReferencesState.comparativeEvidence) {
      let comparativeEvidenceFoi = sourceReferencesState.comparativeEvidence.reference.split('/')[1];
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': comparativeEvidenceFoi,
        'resourcetype': "Evidence",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        comparativeEvidenceJson = JSON.parse(response.fhirjsonstring);
      }
    }
    for (let section of newRowSections) {
      if (section.code?.coding && section.code.coding[0].code) {
        switch (section.code.coding[0].code) {
          case "outcome-measure":
            if (outcomeDefinitionJson) {
              section.entry = [sourceReferencesState.outcomeDefinition];
              let outcomeName = outcomeDefinitionJson.title || outcomeDefinitionJson.name || '[No title found.]';
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + outcomeName.replace('OutcomeDefinition: ', '') + "</p></div>"
              };
            }
            break;
          case "population":
            if (populationJson) {
              section.entry = [sourceReferencesState.population];
              let populationText = populationJson.title || populationJson.name || '[No title found.]';
              if (populationJson.quantity) {
                populationText += " (" + populationJson.quantity.toString() + " participants)";
              }
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + populationText + "</p></div>"
              };
            }
            break;
          case "intervention-group":
            if (interventionGroupJson) {
              section.entry = [sourceReferencesState.interventionGroup];
              let populationText = interventionGroupJson.title || interventionGroupJson.name || '[No title found.]';
              if (interventionGroupJson.quantity) {
                populationText += " (" + interventionGroupJson.quantity.toString() + " participants)";
              }
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + populationText + "</p></div>"
              };
            }
            break;
          case "comparator-group":
            if (comparatorGroupJson) {
              section.entry = [sourceReferencesState.comparatorGroup];
              let populationText = comparatorGroupJson.title || comparatorGroupJson.name || '[No title found.]';
              if (comparatorGroupJson.quantity) {
                populationText += " (" + comparatorGroupJson.quantity.toString() + " participants)";
              }
              section.text = {
                "status": "generated",
                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + populationText + "</p></div>"
              };
            }
            break;
          case "result-with-comparator-alone": //TODO - change to more inclusive model
            if (comparatorOnlyEvidenceJson) {
              section.entry = [sourceReferencesState.comparatorOnlyEvidence];
              generateSectionTextForPercentage(section, comparatorOnlyEvidenceJson.statistic);
            }
            break;
          case "result-with-intervention-alone":
            if (interventionOnlyEvidenceJson) {
              section.entry = [sourceReferencesState.interventionOnlyEvidence];
              generateSectionTextForPercentage(section, interventionOnlyEvidenceJson.statistic);
            }
            break;
          case "result-with-intervention-vs-comparator":
            generateSectionTextForEffectEstimate(section, comparativeEvidenceJson, sourceReferencesState);
            break;
          case "GroupAssignment":
            break;
          default:
            console.log("UNRECOGNIZED section code: " + section.code.coding[0].code);
        }
      } else if (section.code?.text) {
        console.log("UNRECOGNIZED section code text: " + section.code.text);
      }
    }
    setNewRowSectionsState({ newRowSections: newRowSections, tableRowContentGenerated: true });
  } else {
    console.log(newRowSections);
  }
};

const AddTableRowModal = ({ startingValue, setResourceState, globalContext, customized, setOpenAddTableRowModalState }) => {
  let failOut = false;

  let columnTitles = [];
  let columnHeaders = {};
  let columnCodes = [];
  if (Array.isArray(startingValue) &&
    startingValue[0]?.code?.coding?.[0]?.code.toLowerCase() === "results" &&
    Array.isArray(startingValue[0].section) && startingValue[0].section.length) {
    columnTitles = ["Population", "Intervention", "Comparator", "Group Assignment",
      "Result with Comparator", "Result with Intervention", "Effect Estimate"
    ];
    columnCodes = [sectionCodePopulation, sectionCodeInterventionGroup,
      sectionCodeComparatorGroup, sectionCodeGroupAssignment, sectionCodeResultWithComparatorAlone,
      sectionCodeResultWithInterventionAlone, sectionCodeResultWithInterventionVsComparator
    ];
  } else {
    alert("The Composition does not have an initial section coded for results with iterable sections.");
    failOut = true;
  }

  let tableRowSectionCode = sectionCodeSummaryOfFindingsForSingleOutcome;

  let startingNewRowSections = [];
  for (let i in columnTitles) {
    let tableCellSection = {
      "title": columnTitles[i],
      "code": columnCodes[i],
      "text": emptyTextNoData,
      "entry": [],
      "emptyReason": emptyReasonNotStarted
    }
    startingNewRowSections.push(tableCellSection);
  }

  const [addTableRowModalState, setAddTableRowModalState] = useState({
    "newRowTitle": "",
    "newRowFocus": "",
    "newRowSections": JSON.parse(JSON.stringify(startingNewRowSections))
  });
  const [newRowSectionsState, setNewRowSectionsState] = useState({ "newRowSections": JSON.parse(JSON.stringify(startingNewRowSections)) });
  const [sourceReferencesState, setSourceReferencesState] = useState({
    outcomeDefinition: "", interventionOnlyEvidence: "", comparatorOnlyEvidence: "",
    comparativeEvidence: "", population: "", interventionGroup: "", comparatorGroup: "",
    groupAssignment: ""
  });

  const submitNewRowChange = () => {
    if (addTableRowModalState.newRowTitle) {
      setResourceState(prevState => {
        let newRow = {
          "title": addTableRowModalState.newRowTitle,
          "code": tableRowSectionCode,
          "focus": addTableRowModalState.newRowFocus || { "display": "[No data]" },
          "section": newRowSectionsState.newRowSections
        }
        let newSection = JSON.parse(JSON.stringify(startingValue));
        newSection[0].section.push(newRow);
        return { ...prevState, "section": newSection, "sectionChanged": true };
      });
      setOpenAddTableRowModalState(prevState => { return { ...prevState, openModal: false }; });
    }
  }

  useEffect(() => {
    if (newRowSectionsState.tableRowContentGenerated) {
      submitNewRowChange();
    }
  }, [newRowSectionsState]);


  useEffect((() => {
    if (addTableRowModalState.newRowTitle ||
      !(typeof addTableRowModalState.newRowFocus === "object" &&
        Object.keys(addTableRowModalState.newRowFocus).length === 1 &&
        addTableRowModalState.newRowFocus["display"] === "[No data]")) {
      let updatedNewRowSections = JSON.parse(JSON.stringify(newRowSectionsState.newRowSections));
      if (addTableRowModalState.newRowTitle) {
        updatedNewRowSections[0].text = {
          "status": "generated",
          "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\"><p>" + addTableRowModalState.newRowTitle + "</p></div>"
        };
      }
      if (!(typeof addTableRowModalState.newRowFocus === "object" &&
        Object.keys(addTableRowModalState.newRowFocus).length === 1 &&
        addTableRowModalState.newRowFocus["display"] === "[No data]")) {
        updatedNewRowSections[0].entry = [addTableRowModalState.newRowFocus];
      }
      setNewRowSectionsState({ "newRowSections": updatedNewRowSections });
    }
  }), [addTableRowModalState]);

  useEffect(() => {
    if (sourceReferencesState.outcomeDefinition) {
      let newRowFocus = JSON.parse(JSON.stringify(sourceReferencesState.outcomeDefinition))
      setAddTableRowModalState(prevState => { return { ...prevState, newRowFocus: newRowFocus } });
    }
  }, [sourceReferencesState]);

  if (failOut) {
    return <></>
  }

  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }}
      className="formButton negative"
      content="✖"
      onClick={() => {
        setOpenAddTableRowModalState(prevState => { return { ...prevState, openModal: false }; });
      }} />
    <h3>Name the Outcome (how it will show in the table row header)</h3>
    <br />
    <DataEntry datatype='string' elementName='newRowTitle' fieldLabel='Title' required
      startingValue={addTableRowModalState.newRowTitle} setResourceState={setAddTableRowModalState} />
    <br />
    <h3>Set or create the structured Outcome Definition</h3>
    <br />
    <DataEntry datatype='Reference' elementName='outcomeDefinition' enableCreation={true}
      fieldLabel='OutcomeDefinition (Resource Reference)' referencedResourceTypes={['EvidenceVariable']}
      startingResourceType="EvidenceVariable" setProfile="VariableDefinition"
      startingValue={sourceReferencesState.outcomeDefinition} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Population (Total Group)</h3>
    <br />
    <DataEntry datatype='Reference' elementName='population' enableCreation={true}
      fieldLabel='Population (Resource Reference)' referencedResourceTypes={['Group']}
      startingResourceType="Group"
      startingValue={sourceReferencesState.population} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Intervention Group</h3>
    <br />
    <DataEntry datatype='Reference' elementName='interventionGroup' enableCreation={true}
      fieldLabel='Intervention Group (Resource Reference)' referencedResourceTypes={['Group']}
      startingResourceType="Group"
      startingValue={sourceReferencesState.interventionGroup} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Comparator Group</h3>
    <br />
    <DataEntry datatype='Reference' elementName='comparatorGroup' enableCreation={true}
      fieldLabel='Comparator Group (Resource Reference)' referencedResourceTypes={['Group']}
      startingResourceType="Group"
      startingValue={sourceReferencesState.comparatorGroup} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Group Assignment</h3>
    <br />
    <DataEntry datatype='Reference' elementName='groupAssignment' enableCreation={true}
      fieldLabel='Group Assignment (Resource Reference)' referencedResourceTypes={['EvidenceVariable']}
      startingResourceType="EvidenceVariable" setProfile="GroupAssignment"
      startingValue={sourceReferencesState.groupAssignment} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Comparator Only Evidence</h3>
    <br />
    <DataEntry datatype='Reference' elementName='comparatorOnlyEvidence' enableCreation={true}
      fieldLabel='ComparatorOnlyEvidence (Resource Reference)' referencedResourceTypes={['Evidence']}
      startingResourceType="Evidence" setProfile="ComparatorOnlyEvidence"
      addElementValues={{
        variableDefinition: [
          {
            "description": sourceReferencesState.comparatorGroup?.display || "comparator only group",
            "variableRole": "population",
            "observed": sourceReferencesState.comparatorGroup
          },
          {
            "description": sourceReferencesState.outcomeDefinition?.display,
            "variableRole": "outcome",
            "observed": sourceReferencesState.outcomeDefinition
          }
        ]
      }}
      startingValue={sourceReferencesState.comparatorOnlyEvidence} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Intervention Only Evidence</h3>
    <br />
    <DataEntry datatype='Reference' elementName='interventionOnlyEvidence' enableCreation={true}
      fieldLabel='InterventionOnlyEvidence (Resource Reference)' referencedResourceTypes={['Evidence']}
      startingResourceType="Evidence" setProfile="InterventionOnlyEvidence"
      addElementValues={{
        variableDefinition: [
          {
            "description": sourceReferencesState.interventionGroup?.display || "intervention only group",
            "variableRole": "population",
            "observed": sourceReferencesState.interventionGroup
          },
          {
            "description": sourceReferencesState.outcomeDefinition?.display,
            "variableRole": "outcome",
            "observed": sourceReferencesState.outcomeDefinition
          }
        ]
      }}
      startingValue={sourceReferencesState.interventionOnlyEvidence} setResourceState={setSourceReferencesState} />
    <br />
    <h3>Set or create the structured Comparative Evidence</h3>
    <br />
    <DataEntry datatype='Reference' elementName='comparativeEvidence' enableCreation={true}
      fieldLabel='ComparativeEvidence (Resource Reference)' referencedResourceTypes={['Evidence']}
      startingResourceType="Evidence" setProfile="ComparativeEvidence"
      addElementValues={{
        variableDefinition: [
          {
            "description": sourceReferencesState.population?.display || "total group",
            "variableRole": "population",
            "observed": sourceReferencesState.population
          },
          {
            "description": sourceReferencesState.groupAssignment?.display || "intervention group vs. comparator group",
            "variableRole": "exposure",
            "comparatorCategory": sourceReferencesState.comparatorGroup?.display || "comparator only group",
            "observed": sourceReferencesState.groupAssignment
          },
          {
            "description": sourceReferencesState.outcomeDefinition?.display,
            "variableRole": "outcome",
            "observed": sourceReferencesState.outcomeDefinition
          }
        ]
      }}
      startingValue={sourceReferencesState.comparativeEvidence} setResourceState={setSourceReferencesState} />
    <br />
    <br />
    {sourceReferencesState.comparativeEvidence?.reference &&
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
        content="Generate Table Cell Content" positive
        onClick={async () => {
          await generateTableCellContent(sourceReferencesState, newRowSectionsState, setNewRowSectionsState, globalContext);
        }}
      />}
    <br />
    <br />
    <h3>Add Table Cell Content</h3>
    <br />
    <CompositionTableCellArrayEntry elementName='newRowSections' startingValue={newRowSectionsState.newRowSections}
      setResourceState={setNewRowSectionsState} columnHeaders={columnHeaders}
      dataEntryStyle="SummaryOfFindings" addTableRowModalState={addTableRowModalState} />
    <br /><br /><span>
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
        content="Submit Changes" positive
        onClick={submitNewRowChange}
        disabled={!addTableRowModalState.newRowTitle}
      />
      &nbsp;&nbsp;
      {!addTableRowModalState.newRowTitle && <>Enter a title at the top to submit changes.</>}
    </span>
    <br />
  </div>;

  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={true}
      centered={false}
      content={modalContent}
    />
  )
};

const notStartedSection = [
  {
    "code": sectionCodePopulation,
    "emptyReason": emptyReasonNotStarted
  },
  {
    "code": sectionCodeInterventionGroup,
    "emptyReason": emptyReasonNotStarted
  },
  {
    "code": sectionCodeComparatorGroup,
    "emptyReason": emptyReasonNotStarted
  },
  {
    "code": sectionCodeGroupAssignment,
    "emptyReason": emptyReasonNotStarted
  },
  {
    "code": sectionCodeResultWithInterventionAlone,
    "emptyReason": emptyReasonNotStarted
  },
  {
    "code": sectionCodeResultWithComparatorAlone,
    "emptyReason": emptyReasonNotStarted
  },
  {
    "code": sectionCodeResultWithInterventionVsComparator,
    "emptyReason": emptyReasonNotStarted
  }
];

const createStartingArrayAsObject = (section) => {
  let usableSection;
  if (section?.[0]?.code?.coding?.[0]?.code === "results" && section[0].section?.length) {
    usableSection = section[0].section;
  } else {
    usableSection = [
      {
        "title": "[Not started]",
        "code": sectionCodeSummaryOfFindingsForSingleOutcome,
        "text": emptyTextNoData,
        "section": notStartedSection
      }
    ];
  }

  let startingArrayAsObject = {};
  if (usableSection) {
    for (let itemIndex in usableSection) {
      startingArrayAsObject[itemIndex] = usableSection[itemIndex];
    }
  }
  return startingArrayAsObject;
}

const EvidenceTableEdit = ({ resourceState, setResourceState, setSourceJsonState,
  resourceDictionary, globalContext, adaptationReportState, setAdaptationReportState,
  setChangeAvailableToSaveState }) => {

  const [arrayState, setArrayState] = useState(null);
  const [startingArrayState, setStartingArrayState] = useState(null);
  const [openAddTableRowModalState, setOpenAddTableRowModalState] = useState({ "openModal": false });

  useEffect(() => {
    let resourceStateTemp = JSON.parse(JSON.stringify(resourceState));
    let startingArrayAsObject = createStartingArrayAsObject(resourceStateTemp.section);
    setArrayState(startingArrayAsObject);
    setStartingArrayState(startingArrayAsObject);
  }, []);

  useEffect((() => {
    if (arrayState && Object.keys(arrayState).length > 0 && startingArrayState &&
      JSON.stringify(arrayState) !== JSON.stringify(startingArrayState)) {

      let newArrayStateDictionary = {};
      for (const index in arrayState) {
        newArrayStateDictionary[index] = JSON.parse(JSON.stringify(arrayState[index]));
      }

      if (Object.keys(newArrayStateDictionary).length > 0) {
        let newArray = [];
        for (let key of Object.keys(newArrayStateDictionary)) {
          if (typeof newArrayStateDictionary[key] === "object" && Object.keys(newArrayStateDictionary[key]).length > 0) {
            newArray.push(newArrayStateDictionary[key]);
          }
        }
        if (newArray.length > 0) {
          setResourceState(prevState => {
            let newSection = prevState.section;
            newSection[0].section = JSON.parse(JSON.stringify(newArray));
            return { ...prevState, "section": newSection }
          });
        }
      }
    }
  }), [arrayState]);

  return <>{arrayState && <>
    {openAddTableRowModalState.openModal &&
      <AddTableRowModal startingValue={resourceState.section} globalContext={globalContext}
        setOpenAddTableRowModalState={setOpenAddTableRowModalState}
        setResourceState={setResourceState} />}
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Outcome</Table.HeaderCell>
          <Table.HeaderCell>Population</Table.HeaderCell>
          <Table.HeaderCell>Intervention</Table.HeaderCell>
          <Table.HeaderCell>Comparator</Table.HeaderCell>
          <Table.HeaderCell>Group Assignment</Table.HeaderCell>
          <Table.HeaderCell>Result with Comparator</Table.HeaderCell>
          <Table.HeaderCell>Result with Intervention</Table.HeaderCell>
          <Table.HeaderCell>Effect Estimate</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(arrayState && Object.keys(arrayState).length > 0) &&
          Object.entries(arrayState).map((keyValuePair, keyValuePairIndex) => {
            return <Table.Row key={keyValuePairIndex}>
              <TableRowEdit startingValue={keyValuePair[1]}
                setResourceState={setArrayState} sectionArrayIndex={keyValuePair[0]}
                resourceDictionary={resourceDictionary}
                resourceState={resourceState}
                globalContext={globalContext} setSourceJsonState={setSourceJsonState}
                adaptationReportState={adaptationReportState}
                setAdaptationReportState={setAdaptationReportState}
                setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            </Table.Row>
          })}
      </Table.Body>
    </Table>
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="+ Add Table Row" positive
      onClick={() => {
        setOpenAddTableRowModalState({ openModal: true });
      }}
    />
    <br /><br />
  </>}
  </>
};

const reorderResultsSections = (resultsSection, setSectionsReorderedState) => {
  let originalResultsSectionString = JSON.stringify(resultsSection);
  let reorderedResults = resultsSection.map(result => {
    if (!result.code) {
      result.code = sectionCodeSummaryOfFindingsForSingleOutcome;
    }
    let emptyPopulation = {
      "code": sectionCodePopulation,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyInterventionGroup = {
      "code": sectionCodeInterventionGroup,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyComparatorGroup = {
      "code": sectionCodeComparatorGroup,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyGroupAssignment = {
      "code": sectionCodeGroupAssignment,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyInterventionEvidence = {
      "code": sectionCodeResultWithInterventionAlone,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyComparatorEvidence = {
      "code": sectionCodeResultWithComparatorAlone,
      "emptyReason": emptyReasonNotStarted
    };
    let emptyEffectEstimate = {
      "code": sectionCodeResultWithInterventionVsComparator,
      "emptyReason": emptyReasonNotStarted
    };
    let resultDictionary = {
      population: emptyPopulation,
      interventionGroup: emptyInterventionGroup,
      comparatorGroup: emptyComparatorGroup,
      groupAssignment: emptyGroupAssignment,
      intervention: emptyInterventionEvidence,
      comparator: emptyComparatorEvidence,
      effect: emptyEffectEstimate,
      additionalSections: []
    };
    for (const section of result.section) {
      let sectionCode = section?.code?.coding?.[0]?.code || section?.code?.text;
      if (sectionCode === "population" || sectionCode === "Population") {
        resultDictionary.population = section;
      } else if (sectionCode === "intervention-group") {
        resultDictionary.interventionGroup = section;
      } else if (sectionCode === "comparator-group") {
        resultDictionary.comparatorGroup = section;
      } else if (sectionCode === "GroupAssignment") {
        resultDictionary.groupAssignment = section;
      } else if (sectionCode === "result-with-intervention-alone" || sectionCode === "evidence-with-intervention-alone" || sectionCode === "Evidence with intervention alone") {
        resultDictionary.intervention = section;
      } else if (sectionCode === "result-with-comparator-alone" || sectionCode === "evidence-with-comparator-alone" || sectionCode === "Evidence with comparator alone") {
        resultDictionary.comparator = section;
      } else if (sectionCode === "result-with-intervention-vs-comparator" || sectionCode === "evidence-with-intervention-vs-comparator" || sectionCode === "Evidence with intervention vs. comparator") {
        resultDictionary.effect = section;
      } else if (section) {
        resultDictionary.additionalSections.push(section);
      }
    }
    let reorderedResultArray = [
      resultDictionary.population,
      resultDictionary.interventionGroup,
      resultDictionary.comparatorGroup,
      resultDictionary.groupAssignment,
      resultDictionary.intervention,
      resultDictionary.comparator,
      resultDictionary.effect
    ].concat(resultDictionary.additionalSections);
    result.section = reorderedResultArray;
    return result;
  });
  if (originalResultsSectionString !== JSON.stringify(reorderedResults)) {
    setSectionsReorderedState(true);
  }
  return reorderedResults;
}

const reorderSections = (sectionArray, setSectionsReorderedState) => {
  let emptyResults = {
    "title": "Results",
    "code": sectionCodeResults,
    "section": [
      {
        "title": "[Not started]",
        "code": sectionCodeSummaryOfFindingsForSingleOutcome,
        "text": emptyTextNoData,
        "section": notStartedSection
      }
    ]
  };
  let sectionDictionary = {
    results: emptyResults,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.coding?.[0]?.code || section?.code?.text;
    if (sectionCode === "results" || sectionCode === "Results") {
      sectionDictionary.results = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  if (Array.isArray(sectionDictionary.results.section) && sectionDictionary.results.section.length) {
    sectionDictionary.results.section = reorderResultsSections(sectionDictionary.results.section, setSectionsReorderedState);
  }
  let reorderedSectionArray = [
    sectionDictionary.results
  ].concat(sectionDictionary.additionalSections);
  if (sectionArray[0].title !== "Results") {
    setSectionsReorderedState(true);
  }
  return reorderedSectionArray;
};

const OutcomeMeasureSynthesisReportAuthor = ({ resourceState, setResourceState, globalContext,
  sourceJsonState, setSourceJsonState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  const [sectionsReorderedState, setSectionsReorderedState] = useState(false);

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        let newSection = reorderSections(JSON.parse(JSON.stringify(prevState.section)), setSectionsReorderedState);
        return {
          ...prevState,
          type: prevState.type || compositionTypeCodeOutcomeMeasureSynthesisReport,
          section: newSection
        };
      });
    }
  }, []);

  useEffect(() => {
    if (sectionsReorderedState) {
      if (adaptationReportState?.adaptOn) {
        alert("Sections have been reordered. Before proceeding with Adapting this Resource, it is suggested to edit the Resource or Clone the Resource, then edit it to reorder sections, then proceed with Adaptation.");
      } else {
        alert("Sections have been reordered. Clicking Update is suggested before further editing.");
      }
    }
  }, [sectionsReorderedState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  return <>{(sourceJsonState.loaded && resourceState.section?.length > 0) ?
    <div style={{ marginTop: "12px" }}>
      {adaptationReportState?.adaptOn ?
        <>
          <h3 id="results">Results</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceTableEdit resourceState={resourceState}
              resourceDictionary={sourceJsonState.resourceDictionary}
              setSourceJsonState={setSourceJsonState}
              setResourceState={setResourceState} globalContext={globalContext}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
        </>
        :
        <div>
          <h3 id="results">Results</h3>
          <div style={{ marginLeft: "24px" }}>
            <EvidenceTableEdit resourceState={resourceState}
              resourceDictionary={sourceJsonState.resourceDictionary}
              setSourceJsonState={setSourceJsonState}
              setResourceState={setResourceState} globalContext={globalContext} />
          </div>
        </div>
      }
    </div>
    :
    <div>
      <p>Loading ...</p>
    </div>}</>
};

export default OutcomeMeasureSynthesisReportAuthor;