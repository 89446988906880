import React from 'react';

const JsonDifferenceWalkthrough = ({ jsonDifferences, previousValue, parentElementName, parentChangeValue }) => {

    if (jsonDifferences && typeof jsonDifferences === "object" && jsonDifferences.CHANGETYPE === undefined && jsonDifferences.VALUE === undefined && jsonDifferences.PREVIOUSVALUE === undefined && Object.keys(jsonDifferences).length && (typeof jsonDifferences[Object.keys(jsonDifferences)[0]] !== "object" || (jsonDifferences[Object.keys(jsonDifferences)[0]] && jsonDifferences[Object.keys(jsonDifferences)[0]].CHANGETYPE === undefined))) {
        let className = "addedJsonDiffValue";
        let previousValueClassName = "deletedJsonDiffValue";
        if (parentChangeValue === "reordered" || parentChangeValue ===  "reorderedadded" || parentChangeValue ===  "reorderedandpreviousvaluemoved") {
            className = "reorderedJsonDiffValueString";
        } else if (parentChangeValue === "nochange") {
            className = "nochangeJsonDiffValueString";
        }
        if (parentChangeValue === "previousvaluemoved") {
            className = "changedJsonDiffValueString";
            previousValueClassName = "previousvaluemovedJsonDiffValue";
        } else if (parentChangeValue === "reorderedandpreviousvaluemoved" || parentChangeValue === "deletedpreviousvaluemoved") {
            previousValueClassName = "previousvaluemovedJsonDiffValue";
        }
        return <>
            <span className={className}>
                {JSON.stringify(jsonDifferences, null, 2)}
            </span>
            {previousValue === undefined ? <></> : <><br /><span className={previousValueClassName}>{JSON.stringify(previousValue, null, 2)}</span></>}
        </>
    } else if (jsonDifferences && typeof jsonDifferences === "object" && !Array.isArray(jsonDifferences) && Object.keys(jsonDifferences).length) {
        let jsonDifferencesKeys = Object.keys(jsonDifferences);
        return jsonDifferencesKeys.map((elementName, index) => {
            let element = jsonDifferences[elementName];
            let changeType = element?.CHANGETYPE;
            if (element && (typeof element.VALUE === "object" || typeof element.PREVIOUSVALUE === "object")) {
                let hasCurrentValue = (element && element.VALUE !== undefined && element.VALUE !== null && typeof element.VALUE === "object" && (Array.isArray(element.VALUE) || (!Array.isArray(element.VALUE) && Object.keys(element.VALUE).length)));
                const ElementLabel = ({}) => { return <span>{(!isNaN(elementName) && parentElementName) ? <b>{parentElementName + " " + parseInt(elementName)}: </b> : <b>{elementName}: </b>}</span>};
                let labelClassName = "nochangeJsonDiffLabel";
                let valueClassName = "nochangeJsonDiffValue";
                if (element.CHANGETYPE !== "nochange") {
                    if (element.CHANGETYPE === "deletedpreviousvaluemoved") {
                        labelClassName = "deletedpreviousvaluemovedJsonDiffLabel";
                        valueClassName = "deletedpreviousvaluemovedJsonDiffValueElement";
                    } else {
                        labelClassName = "deletedJsonDiffLabel";
                        valueClassName = "deletedJsonDiffValue";
                    }
                    //deletedpreviousvaluemoved
                }
                let divClassName = changeType + "JsonDiffValue";
                if (hasCurrentValue) {
                    divClassName = changeType + "JsonDiff";
                }
                if (element.CHANGETYPE === "deletedpreviousvaluemoved") {
                    divClassName = "deletedpreviousvaluemovedJsonDiffValueDiv";
                }
                return <div key={index} className={divClassName}>
                    {hasCurrentValue ?
                        <>
                            <ElementLabel />
                            <div style={{ marginLeft: "24px" }}>
                                <span className={changeType + "JsonDiffValue"}>
                                    <JsonDifferenceWalkthrough jsonDifferences={element.VALUE} previousValue={element.PREVIOUSVALUE} parentElementName={elementName} parentChangeValue={changeType} />
                                </span>

                            </div>
                        </>
                        :
                        <span>{(element && element.PREVIOUSVALUE !== undefined) && <>
                            <span className={labelClassName}><ElementLabel />
                        </span>
                        <span className={valueClassName}>
                            {typeof element.PREVIOUSVALUE === "object" ? JSON.stringify(element.PREVIOUSVALUE, null, 2) : element.PREVIOUSVALUE}
                        </span></>}</span>
                    }
                </div>
            } else {
                return <div key={index} className={changeType + "JsonDiff"}><b>{elementName}: </b><span className={changeType + "JsonDiffValue"}>{element.VALUE ?? element.PREVIOUSVALUE} {(element.PREVIOUSVALUE && JSON.stringify(element.VALUE) !== JSON.stringify(element.PREVIOUSVALUE)) && <span className={changeType + "JsonDiffDeleted"}>{element.PREVIOUSVALUE}</span>}</span></div>
            }
        })
    } else {
        //console.log("CRASHING NOW");
        //let x = jsonDifferences.dsa.dsadsa.das.das.dsa.dsa.das.CRASH();
        //console.log(jsonDifferences);
        return <div className={"JsonDiff"}>
            <span className="nochangeJsonDiffValue">{JSON.stringify(previousValue, null, 2)}</span>
        </div>
    }
    return <></>
}

const JsonVersionComparison = ({ jsonDifferences }) => {
    //SERVERCALL
    //const differences = 
    /* return <div className="jsonVersionComparison"><JsonDifferenceWalkthrough jsonDifferences={differenceExample} /></div>*/
    // return <div className="jsonVersionComparison"><JsonDifferenceWalkthrough jsonDifferences={jsonDifferences} /></div>
    if (jsonDifferences && typeof jsonDifferences === "object" && Object.keys(jsonDifferences).length) {
        return <div className="jsonVersionComparison"><JsonDifferenceWalkthrough jsonDifferences={jsonDifferences} /></div>
    } else {
        return <div>There seems to be no differences. (Or this is an error.)</div>
    }
}

export default JsonVersionComparison;