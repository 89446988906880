import React, { useState, useEffect } from 'react';
import { DataEntry } from './DataEntryFormFunctions';

const ValueSetBuilder = ({ resourceState, setResourceState }) => {

  const [scopeState, setScopeState] = useState({ "inclusionCriteria": resourceState.scope?.inclusionCriteria || "", "exclusionCriteria": resourceState.scope?.exclusionCriteria || "" })

  useEffect(() => {
    if (Object.keys(scopeState).length > 0) {
      let newScope = {};
      if (scopeState.extension) { newScope.extension = scopeState.extension; }
      if (scopeState.inclusionCriteria) { newScope.inclusionCriteria = scopeState.inclusionCriteria; }
      if (scopeState.exclusionCriteria) { newScope.exclusionCriteria = scopeState.exclusionCriteria; }
      if (Object.keys(newScope).length === 0) {
        newScope = null;
      }
      setResourceState(prevState => { return { ...prevState, "scope": newScope } });
    }
  }, [scopeState]);

  return <div style={{ marginTop: "12px" }}>
      <h3 id="description">ValueSet Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='version' fieldLabel='Version'
          startingValue={resourceState.version} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='inclusionCriteria' fieldLabel='Inclusion Criteria'
          startingValue={scopeState.inclusionCriteria} setResourceState={setScopeState} />
        <DataEntry datatype='string' elementName='exclusionCriteria' fieldLabel='Exclusion Criteria'
          startingValue={scopeState.exclusionCriteria} setResourceState={setScopeState} />
        <DataEntry datatype='markdown' elementName='copyright' fieldLabel='Copyright'
          startingValue={resourceState.copyright} setResourceState={setResourceState} />
      </div>
      <h3 id="content">Content</h3>
      <div style={{ marginLeft: "24px" }}></div>
      <DataEntry datatype='ValueSetCompose' elementName='compose' fieldLabel='Compose (content logical definition)' 
        startingValue={resourceState.compose} setResourceState={setResourceState} />
    </div>
}

export default ValueSetBuilder;
