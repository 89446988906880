import { emptyTextNoData } from "./CodeSystemLookup";
import submitToFevirServer from "./SubmitToFevirServer";

const createAdaptedComposition = (compositionState, adaptationReportState, globalContext) => {
    let returningdata = { "success": false };
    try {
        let currentdate = new Date().toISOString();
        const adaptationDictionary = adaptationReportState.adaptationDictionary;
        let newCompositionJson = JSON.parse(JSON.stringify(compositionState));
        newCompositionJson.resourceType = "Composition";
        delete newCompositionJson.resourceJson;
        delete newCompositionJson.newClassifications;
        delete newCompositionJson.attester;
        let adaptationReportJson = {
            "resourceType": "ArtifactAssessment",
            "meta": {
                "profile": [
                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation"
                ],
                "versionId": "1",
                "lastUpdated": currentdate
            },
            "extension": [
                {
                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
                    "valueCode": "active"
                },
                {
                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
                    "valueContactDetail": {
                        "name": globalContext.userState.name
                    }
                }
            ],
            "title": globalContext.userState.name + "'s Adaptation Report of " + newCompositionJson.title,
            "artifactReference": {
                "reference": "Composition/" + newCompositionJson.id,
                "type": "Composition",
                "display": newCompositionJson.title || newCompositionJson.name
            },
            "content": []
        }
        newCompositionJson.meta.versionId = "1";
        newCompositionJson.meta.lastUpdated = "";
        newCompositionJson.url = "";
        newCompositionJson.date = currentdate;
        if (newCompositionJson.version) {
            newCompositionJson.version += "-adapted";
        }
        newCompositionJson.author = [{ "display": globalContext.userState.name }];

        newCompositionJson.custodian = {
            "reference": "Organization/118079",
            "type": "Organization",
            "display": "Computable Publishing LLC"
        };
        if (newCompositionJson.relatesTo) {
            newCompositionJson.relatesTo = newCompositionJson.relatesTo.filter((relatedItem) => (relatedItem.type !== "cite-as" && relatedItem.type !== "derived-from"));
        } else {
            newCompositionJson.relatesTo = [];
        }
        newCompositionJson.relatesTo.push({
            "type": "derived-from",
            "classifier": [{ "text": "Original Recommendation" }],
            "resourceReference": {
                "reference": "Composition/" + newCompositionJson.id,
                "type": "Composition",
                "display": newCompositionJson.title || newCompositionJson.name
            }
        });
        if (newCompositionJson.name) {
            newCompositionJson.name += "-adapted";
        }
        if (newCompositionJson.title) {
            newCompositionJson.title += "-adapted";
        }
        newCompositionJson.id = "";
        for (let sectionIndex in newCompositionJson.section) {
            let section = newCompositionJson.section[sectionIndex];
            if (section?.code) {
                let sectionCode;
                if (section.code.text) {
                    sectionCode = "section[" + sectionIndex + "]:" + section.code.text;
                } else if (section.code.coding?.[0]) {
                    sectionCode = "section[" + sectionIndex + "]:" + section.code.coding[0].system + "#" + section.code.coding[0].code;
                } else {
                    sectionCode = "section[" + sectionIndex + "]:" + JSON.stringify(section.code);
                }
                let sectionTitleMapCode = sectionCode + ".title";
                let sectionAuthorMapCode = sectionCode + ".author";
                let sectionFocusMapCode = sectionCode + ".focus";
                let sectionOrderedByMapCode = sectionCode + ".orderedBy";
                let sectionEntryMapCode = sectionCode + ".entry";
                let sectionEmptyReasonMapCode = sectionCode + ".emptyReason";
                if (adaptationDictionary[sectionCode]) {
                    if (adaptationDictionary[sectionCode].itemChanged) {
                        if (adaptationDictionary[sectionCode].revisedValue) {
                            if (typeof adaptationDictionary[sectionCode].revisedValue === "string") {
                                section.text = {
                                    "status": "additional",
                                    "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[sectionCode].revisedValue + "</div>"
                                }
                            } else if (typeof adaptationDictionary[sectionCode].revisedValue === "object") {
                                section.text = adaptationDictionary[sectionCode].revisedValue;
                            }
                        } else {
                            section.text = JSON.parse(JSON.stringify(emptyTextNoData));
                        }
                        let adaptationContent = {
                            "type": { "text": "adapted element" },
                            "classifier": [
                                {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112109",
                                            "code": "string-datatype",
                                            "display": "string Datatype"
                                        }
                                    ]
                                }
                            ],
                            "path": [sectionCode],
                            "component": [
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionCode].initialValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "OriginalResourceContent",
                                                "display": "Original Resource Content"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "summary": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[sectionCode].revisedValue + "</div>",
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "RevisedResourceContent",
                                                "display": "Revised Resource Content"
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        if (typeof adaptationDictionary[sectionCode].revisedValue === "object") {
                            adaptationContent.classifier = [{ "text": "Narrative datatype object" }];
                            adaptationContent.component[1].summary = JSON.stringify(adaptationDictionary[sectionCode].revisedValue);
                        }
                        if (adaptationDictionary[sectionCode].rationale) {
                            adaptationContent.component.push({
                                "summary": adaptationDictionary[sectionCode].rationale,
                                "type": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                            "code": "RationaleForChange",
                                            "display": "Rationale for Change to Resource Content"
                                        }
                                    ]
                                }
                            });
                        }
                        adaptationReportJson.content.push(adaptationContent);
                    }
                }
                if (adaptationDictionary[sectionTitleMapCode]) {
                    if (adaptationDictionary[sectionTitleMapCode].itemChanged) {
                        if (adaptationDictionary[sectionTitleMapCode].revisedValue) {
                            section.title = adaptationDictionary[sectionTitleMapCode].revisedValue;
                        } else {
                            section.title = "";
                        }
                        let adaptationContent = {
                            "type": { "text": "adapted element" },
                            "classifier": [
                                {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112109",
                                            "code": "string-datatype",
                                            "display": "string Datatype"
                                        }
                                    ]
                                }
                            ],
                            "path": [sectionCode + ".title"],
                            "component": [
                                {
                                    "summary": adaptationDictionary[sectionTitleMapCode].initialValue,
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "OriginalResourceContent",
                                                "display": "Original Resource Content"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "summary": adaptationDictionary[sectionTitleMapCode].revisedValue,
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "RevisedResourceContent",
                                                "display": "Revised Resource Content"
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        if (adaptationDictionary[sectionTitleMapCode].rationale) {
                            adaptationContent.component.push({
                                "summary": adaptationDictionary[sectionTitleMapCode].rationale,
                                "type": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                            "code": "RationaleForChange",
                                            "display": "Rationale for Change to Resource Content"
                                        }
                                    ]
                                }
                            });
                        }
                        adaptationReportJson.content.push(adaptationContent);
                    }
                }
                if (adaptationDictionary[sectionAuthorMapCode]) {
                    if (adaptationDictionary[sectionAuthorMapCode].itemChanged) {
                        if (adaptationDictionary[sectionAuthorMapCode].revisedValue) {
                            section.author = adaptationDictionary[sectionAuthorMapCode].revisedValue;
                        } else {
                            section.author = [];
                        }
                        let adaptationContent = {
                            "type": { "text": "adapted element" },
                            "classifier": [
                                {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112109",
                                            "code": "reference-array",
                                            "display": "Reference Array"
                                        }
                                    ]
                                }
                            ],
                            "path": [sectionCode + ".author"],
                            "component": [
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionAuthorMapCode].initialValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "OriginalResourceContent",
                                                "display": "Original Resource Content"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionAuthorMapCode].revisedValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "RevisedResourceContent",
                                                "display": "Revised Resource Content"
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        if (adaptationDictionary[sectionAuthorMapCode].rationale) {
                            adaptationContent.component.push({
                                "summary": adaptationDictionary[sectionAuthorMapCode].rationale,
                                "type": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                            "code": "RationaleForChange",
                                            "display": "Rationale for Change to Resource Content"
                                        }
                                    ]
                                }
                            });
                        }
                        adaptationReportJson.content.push(adaptationContent);
                    }
                }
                if (adaptationDictionary[sectionFocusMapCode]) {
                    if (adaptationDictionary[sectionFocusMapCode].itemChanged) {
                        if (adaptationDictionary[sectionFocusMapCode].revisedValue) {
                            section.focus = adaptationDictionary[sectionFocusMapCode].revisedValue;
                        } else {
                            section.focus = "";
                        }
                        let adaptationContent = {
                            "type": { "text": "adapted element" },
                            "classifier": [
                                {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112109",
                                            "code": "manageAsReference",
                                            "display": "Manage as Reference"
                                        }
                                    ]
                                }
                            ],
                            "path": [sectionCode + ".focus"],
                            "component": [
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionFocusMapCode].initialValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "OriginalResourceContent",
                                                "display": "Original Resource Content"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionFocusMapCode].revisedValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "RevisedResourceContent",
                                                "display": "Revised Resource Content"
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        if (adaptationDictionary[sectionFocusMapCode].rationale) {
                            adaptationContent.component.push({
                                "summary": adaptationDictionary[sectionFocusMapCode].rationale,
                                "type": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                            "code": "RationaleForChange",
                                            "display": "Rationale for Change to Resource Content"
                                        }
                                    ]
                                }
                            });
                        }
                        adaptationReportJson.content.push(adaptationContent);
                    }
                }
                if (adaptationDictionary[sectionOrderedByMapCode]) {
                    if (adaptationDictionary[sectionOrderedByMapCode].itemChanged) {
                        if (adaptationDictionary[sectionOrderedByMapCode].revisedValue) {
                            section.orderedBy = adaptationDictionary[sectionOrderedByMapCode].revisedValue;
                        } else {
                            section.orderedBy = "";
                        }
                        let adaptationContent = {
                            "type": { "text": "adapted element" },
                            "classifier": [
                                {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112109",
                                            "code": "single-codeableconcept-object",
                                            "display": "Single CodeableConcept Object"
                                        }
                                    ]
                                }
                            ],
                            "path": [sectionCode + ".orderedBy"],
                            "component": [
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionOrderedByMapCode].initialValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "OriginalResourceContent",
                                                "display": "Original Resource Content"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionOrderedByMapCode].revisedValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "RevisedResourceContent",
                                                "display": "Revised Resource Content"
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        if (adaptationDictionary[sectionOrderedByMapCode].rationale) {
                            adaptationContent.component.push({
                                "summary": adaptationDictionary[sectionOrderedByMapCode].rationale,
                                "type": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                            "code": "RationaleForChange",
                                            "display": "Rationale for Change to Resource Content"
                                        }
                                    ]
                                }
                            });
                        }
                        adaptationReportJson.content.push(adaptationContent);
                    }
                }
                if (adaptationDictionary[sectionEntryMapCode]) {
                    if (adaptationDictionary[sectionEntryMapCode].itemChanged) {
                        if (adaptationDictionary[sectionEntryMapCode].revisedValue) {
                            section.entry = adaptationDictionary[sectionEntryMapCode].revisedValue;
                        } else {
                            section.entry = [];
                        }
                        let adaptationContent = {
                            "type": { "text": "adapted element" },
                            "classifier": [
                                {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112109",
                                            "code": "reference-array",
                                            "display": "Reference Array"
                                        }
                                    ]
                                }
                            ],
                            "path": [sectionCode + ".entry"],
                            "component": [
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionEntryMapCode].initialValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "OriginalResourceContent",
                                                "display": "Original Resource Content"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionEntryMapCode].revisedValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "RevisedResourceContent",
                                                "display": "Revised Resource Content"
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        if (adaptationDictionary[sectionEntryMapCode].rationale) {
                            adaptationContent.component.push({
                                "summary": adaptationDictionary[sectionEntryMapCode].rationale,
                                "type": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                            "code": "RationaleForChange",
                                            "display": "Rationale for Change to Resource Content"
                                        }
                                    ]
                                }
                            });
                        }
                        adaptationReportJson.content.push(adaptationContent);
                    }
                }
                if (adaptationDictionary[sectionEmptyReasonMapCode]) {
                    if (adaptationDictionary[sectionEmptyReasonMapCode].itemChanged) {
                        if (adaptationDictionary[sectionEmptyReasonMapCode].revisedValue) {
                            section.emptyReason = adaptationDictionary[sectionEmptyReasonMapCode].revisedValue;
                        } else {
                            section.emptyReason = "";
                        }
                        let adaptationContent = {
                            "type": { "text": "adapted element" },
                            "classifier": [
                                {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112109",
                                            "code": "single-codeableconcept-object",
                                            "display": "Single CodeableConcept Object"
                                        }
                                    ]
                                }
                            ],
                            "path": [sectionCode + ".emptyReason"],
                            "component": [
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionEmptyReasonMapCode].initialValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "OriginalResourceContent",
                                                "display": "Original Resource Content"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "summary": JSON.stringify(adaptationDictionary[sectionEmptyReasonMapCode].revisedValue),
                                    "type": {
                                        "coding": [
                                            {
                                                "system": "https://fevir.net/resources/CodeSystem/112108",
                                                "code": "RevisedResourceContent",
                                                "display": "Revised Resource Content"
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        if (adaptationDictionary[sectionEmptyReasonMapCode].rationale) {
                            adaptationContent.component.push({
                                "summary": adaptationDictionary[sectionEmptyReasonMapCode].rationale,
                                "type": {
                                    "coding": [
                                        {
                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                            "code": "RationaleForChange",
                                            "display": "Rationale for Change to Resource Content"
                                        }
                                    ]
                                }
                            });
                        }
                        adaptationReportJson.content.push(adaptationContent);
                    }
                }
                if (Array.isArray(section.section)) {
                    for (let subsectionIndex in section.section) {
                        let subsection = section.section[subsectionIndex];
                        if (subsection.code) {
                            let subsectionCode;
                            if (subsection.code.text) {
                                subsectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "]:" + subsection.code.text;
                            } else if (subsection.code.coding?.[0]) {
                                subsectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "]:" + subsection.code.coding[0].system + "#" + subsection.code.coding[0].code;
                            } else {
                                subsectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "]:" + JSON.stringify(subsection.code);
                            }
                            let subsectionTitleMapCode = subsectionCode + ".title";
                            let subsectionAuthorMapCode = subsectionCode + ".author";
                            let subsectionFocusMapCode = subsectionCode + ".focus";
                            let subsectionOrderedByMapCode = subsectionCode + ".orderedBy";
                            let subsectionEntryMapCode = subsectionCode + ".entry";
                            let subsectionEmptyReasonMapCode = subsectionCode + ".emptyReason";
                            if (adaptationDictionary[subsectionCode]) {
                                if (adaptationDictionary[subsectionCode].itemChanged) {
                                    if (adaptationDictionary[subsectionCode].revisedValue) {
                                        if (typeof adaptationDictionary[subsectionCode].revisedValue === "string") {
                                            subsection.text = {
                                                "status": "additional",
                                                "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[subsectionCode].revisedValue + "</div>"
                                            }
                                        } else if (typeof adaptationDictionary[subsectionCode].revisedValue === "object") {
                                            subsection.text = adaptationDictionary[subsectionCode].revisedValue;
                                        }
                                    } else {
                                        subsection.text = JSON.parse(JSON.stringify(emptyTextNoData));
                                    }
                                    let adaptationContent = {
                                        "type": { "text": "adapted element" },
                                        "classifier": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112109",
                                                        "code": "string-datatype",
                                                        "display": "string Datatype"
                                                    }
                                                ]
                                            }
                                        ],
                                        "path": [subsectionCode],
                                        "component": [
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionCode].initialValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "OriginalResourceContent",
                                                            "display": "Original Resource Content"
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "summary": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[subsectionCode].revisedValue + "</div>",
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "RevisedResourceContent",
                                                            "display": "Revised Resource Content"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    };
                                    if (typeof adaptationDictionary[subsectionCode].revisedValue === "object") {
                                        adaptationContent.classifier = [{ "text": "Narrative datatype object" }];
                                        adaptationContent.component[1].summary = JSON.stringify(adaptationDictionary[subsectionCode].revisedValue);
                                    }
                                    if (adaptationDictionary[subsectionCode].rationale) {
                                        adaptationContent.component.push({
                                            "summary": adaptationDictionary[subsectionCode].rationale,
                                            "type": {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                        "code": "RationaleForChange",
                                                        "display": "Rationale for Change to Resource Content"
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                    adaptationReportJson.content.push(adaptationContent);
                                }
                            }
                            if (adaptationDictionary[subsectionTitleMapCode]) {
                                if (adaptationDictionary[subsectionTitleMapCode].itemChanged) {
                                    if (adaptationDictionary[subsectionTitleMapCode].revisedValue) {
                                        subsection.title = adaptationDictionary[subsectionTitleMapCode].revisedValue;
                                    } else {
                                        subsection.title = "";
                                    }
                                    let adaptationContent = {
                                        "type": { "text": "adapted element" },
                                        "classifier": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112109",
                                                        "code": "string-datatype",
                                                        "display": "string Datatype"
                                                    }
                                                ]
                                            }
                                        ],
                                        "path": [subsectionCode + ".title"],
                                        "component": [
                                            {
                                                "summary": adaptationDictionary[subsectionTitleMapCode].initialValue,
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "OriginalResourceContent",
                                                            "display": "Original Resource Content"
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "summary": adaptationDictionary[subsectionTitleMapCode].revisedValue,
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "RevisedResourceContent",
                                                            "display": "Revised Resource Content"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    };
                                    if (adaptationDictionary[subsectionTitleMapCode].rationale) {
                                        adaptationContent.component.push({
                                            "summary": adaptationDictionary[subsectionTitleMapCode].rationale,
                                            "type": {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                        "code": "RationaleForChange",
                                                        "display": "Rationale for Change to Resource Content"
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                    adaptationReportJson.content.push(adaptationContent);
                                }
                            }
                            if (adaptationDictionary[subsectionAuthorMapCode]) {
                                if (adaptationDictionary[subsectionAuthorMapCode].itemChanged) {
                                    if (adaptationDictionary[subsectionAuthorMapCode].revisedValue) {
                                        subsection.author = adaptationDictionary[subsectionAuthorMapCode].revisedValue;
                                    } else {
                                        subsection.author = [];
                                    }
                                    let adaptationContent = {
                                        "type": { "text": "adapted element" },
                                        "classifier": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112109",
                                                        "code": "reference-array",
                                                        "display": "Reference Array"
                                                    }
                                                ]
                                            }
                                        ],
                                        "path": [subsectionCode + ".author"],
                                        "component": [
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionAuthorMapCode].initialValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "OriginalResourceContent",
                                                            "display": "Original Resource Content"
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionAuthorMapCode].revisedValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "RevisedResourceContent",
                                                            "display": "Revised Resource Content"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    };
                                    if (adaptationDictionary[subsectionAuthorMapCode].rationale) {
                                        adaptationContent.component.push({
                                            "summary": adaptationDictionary[subsectionAuthorMapCode].rationale,
                                            "type": {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                        "code": "RationaleForChange",
                                                        "display": "Rationale for Change to Resource Content"
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                    adaptationReportJson.content.push(adaptationContent);
                                }
                            }
                            if (adaptationDictionary[subsectionFocusMapCode]) {
                                if (adaptationDictionary[subsectionFocusMapCode].itemChanged) {
                                    if (adaptationDictionary[subsectionFocusMapCode].revisedValue) {
                                        subsection.focus = adaptationDictionary[subsectionFocusMapCode].revisedValue;
                                    } else {
                                        subsection.focus = "";
                                    }
                                    let adaptationContent = {
                                        "type": { "text": "adapted element" },
                                        "classifier": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112109",
                                                        "code": "manageAsReference",
                                                        "display": "Manage as Reference"
                                                    }
                                                ]
                                            }
                                        ],
                                        "path": [subsectionCode + ".focus"],
                                        "component": [
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionFocusMapCode].initialValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "OriginalResourceContent",
                                                            "display": "Original Resource Content"
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionFocusMapCode].revisedValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "RevisedResourceContent",
                                                            "display": "Revised Resource Content"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    };
                                    if (adaptationDictionary[subsectionFocusMapCode].rationale) {
                                        adaptationContent.component.push({
                                            "summary": adaptationDictionary[subsectionFocusMapCode].rationale,
                                            "type": {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                        "code": "RationaleForChange",
                                                        "display": "Rationale for Change to Resource Content"
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                    adaptationReportJson.content.push(adaptationContent);
                                }
                            }
                            if (adaptationDictionary[subsectionOrderedByMapCode]) {
                                if (adaptationDictionary[subsectionOrderedByMapCode].itemChanged) {
                                    if (adaptationDictionary[subsectionOrderedByMapCode].revisedValue) {
                                        subsection.orderedBy = adaptationDictionary[subsectionOrderedByMapCode].revisedValue;
                                    } else {
                                        subsection.orderedBy = "";
                                    }
                                    let adaptationContent = {
                                        "type": { "text": "adapted element" },
                                        "classifier": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112109",
                                                        "code": "single-codeableconcept-object",
                                                        "display": "Single CodeableConcept Object"
                                                    }
                                                ]
                                            }
                                        ],
                                        "path": [subsectionCode + ".orderedBy"],
                                        "component": [
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionOrderedByMapCode].initialValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "OriginalResourceContent",
                                                            "display": "Original Resource Content"
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionOrderedByMapCode].revisedValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "RevisedResourceContent",
                                                            "display": "Revised Resource Content"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    };
                                    if (adaptationDictionary[subsectionOrderedByMapCode].rationale) {
                                        adaptationContent.component.push({
                                            "summary": adaptationDictionary[subsectionOrderedByMapCode].rationale,
                                            "type": {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                        "code": "RationaleForChange",
                                                        "display": "Rationale for Change to Resource Content"
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                    adaptationReportJson.content.push(adaptationContent);
                                }
                            }
                            if (adaptationDictionary[subsectionEntryMapCode]) {
                                if (adaptationDictionary[subsectionEntryMapCode].itemChanged) {
                                    if (adaptationDictionary[subsectionEntryMapCode].revisedValue) {
                                        subsection.entry = adaptationDictionary[subsectionEntryMapCode].revisedValue;
                                    } else {
                                        subsection.entry = [];
                                    }
                                    let adaptationContent = {
                                        "type": { "text": "adapted element" },
                                        "classifier": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112109",
                                                        "code": "reference-array",
                                                        "display": "Reference Array"
                                                    }
                                                ]
                                            }
                                        ],
                                        "path": [subsectionCode + ".entry"],
                                        "component": [
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionEntryMapCode].initialValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "OriginalResourceContent",
                                                            "display": "Original Resource Content"
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionEntryMapCode].revisedValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "RevisedResourceContent",
                                                            "display": "Revised Resource Content"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    };
                                    if (adaptationDictionary[subsectionEntryMapCode].rationale) {
                                        adaptationContent.component.push({
                                            "summary": adaptationDictionary[subsectionEntryMapCode].rationale,
                                            "type": {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                        "code": "RationaleForChange",
                                                        "display": "Rationale for Change to Resource Content"
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                    adaptationReportJson.content.push(adaptationContent);
                                }
                            }
                            if (adaptationDictionary[subsectionEmptyReasonMapCode]) {
                                if (adaptationDictionary[subsectionEmptyReasonMapCode].itemChanged) {
                                    if (adaptationDictionary[subsectionEmptyReasonMapCode].revisedValue) {
                                        subsection.emptyReason = adaptationDictionary[subsectionEmptyReasonMapCode].revisedValue;
                                    } else {
                                        subsection.emptyReason = "";
                                    }
                                    let adaptationContent = {
                                        "type": { "text": "adapted element" },
                                        "classifier": [
                                            {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112109",
                                                        "code": "single-codeableconcept-object",
                                                        "display": "Single CodeableConcept Object"
                                                    }
                                                ]
                                            }
                                        ],
                                        "path": [subsectionCode + ".emptyReason"],
                                        "component": [
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionEmptyReasonMapCode].initialValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "OriginalResourceContent",
                                                            "display": "Original Resource Content"
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                "summary": JSON.stringify(adaptationDictionary[subsectionEmptyReasonMapCode].revisedValue),
                                                "type": {
                                                    "coding": [
                                                        {
                                                            "system": "https://fevir.net/resources/CodeSystem/112108",
                                                            "code": "RevisedResourceContent",
                                                            "display": "Revised Resource Content"
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    };
                                    if (adaptationDictionary[subsectionEmptyReasonMapCode].rationale) {
                                        adaptationContent.component.push({
                                            "summary": adaptationDictionary[subsectionEmptyReasonMapCode].rationale,
                                            "type": {
                                                "coding": [
                                                    {
                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                        "code": "RationaleForChange",
                                                        "display": "Rationale for Change to Resource Content"
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                    adaptationReportJson.content.push(adaptationContent);
                                }
                            }
                            if (Array.isArray(subsection.section)) {
                                for (let subsubsectionIndex in subsection.section) {
                                    let subsubsection = subsection.section[subsubsectionIndex];
                                    if (subsubsection.code) {
                                        let subsubsectionCode;
                                        if (subsubsection.code.text) {
                                            subsubsectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "].section[" + subsubsectionIndex + "]:" + subsubsection.code.text;
                                        } else if (subsection.code.coding?.[0]) {
                                            subsubsectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "].section[" + subsubsectionIndex + "]:" + subsubsection.code.coding[0].system + "#" + subsubsection.code.coding[0].code;
                                        } else {
                                            subsubsectionCode = "section[" + sectionIndex + "].section[" + subsectionIndex + "].section[" + subsubsectionIndex + "]:" + JSON.stringify(subsubsection.code);
                                        }
                                        let subsubsectionTitleMapCode = subsubsectionCode + ".title";
                                        let subsubsectionAuthorMapCode = subsubsectionCode + ".author";
                                        let subsubsectionFocusMapCode = subsubsectionCode + ".focus";
                                        let subsubsectionOrderedByMapCode = subsubsectionCode + ".orderedBy";
                                        let subsubsectionEntryMapCode = subsubsectionCode + ".entry";
                                        let subsubsectionEmptyReasonMapCode = subsubsectionCode + ".emptyReason";
                                        if (adaptationDictionary[subsubsectionCode]) {
                                            if (adaptationDictionary[subsubsectionCode].itemChanged) {
                                                if (adaptationDictionary[subsubsectionCode].revisedValue) {
                                                    if (typeof adaptationDictionary[subsubsectionCode].revisedValue === "string") {
                                                        subsubsection.text = {
                                                            "status": "additional",
                                                            "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[subsubsectionCode].revisedValue + "</div>"
                                                        }
                                                    } else if (typeof adaptationDictionary[subsubsectionCode].revisedValue === "object") {
                                                        subsubsection.text = adaptationDictionary[subsubsectionCode].revisedValue;
                                                    }
                                                } else {
                                                    subsubsection.text = JSON.parse(JSON.stringify(emptyTextNoData));
                                                }
                                                let adaptationContent = {
                                                    "type": { "text": "adapted element" },
                                                    "classifier": [
                                                        {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112109",
                                                                    "code": "string-datatype",
                                                                    "display": "string Datatype"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "path": [subsubsectionCode],
                                                    "component": [
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionCode].initialValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "OriginalResourceContent",
                                                                        "display": "Original Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            "summary": "<div xmlns=\"http://www.w3.org/1999/xhtml\">" + adaptationDictionary[subsubsectionCode].revisedValue + "</div>",
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "RevisedResourceContent",
                                                                        "display": "Revised Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                };
                                                if (typeof adaptationDictionary[subsubsectionCode].revisedValue === "object") {
                                                    adaptationContent.classifier = [{ "text": "Narrative datatype object" }];
                                                    adaptationContent.component[1].summary = JSON.stringify(adaptationDictionary[subsubsectionCode].revisedValue);
                                                }
                                                if (adaptationDictionary[subsubsectionCode].rationale) {
                                                    adaptationContent.component.push({
                                                        "summary": adaptationDictionary[subsubsectionCode].rationale,
                                                        "type": {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                    "code": "RationaleForChange",
                                                                    "display": "Rationale for Change to Resource Content"
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                                adaptationReportJson.content.push(adaptationContent);
                                            }
                                        }
                                        if (adaptationDictionary[subsubsectionTitleMapCode]) {
                                            if (adaptationDictionary[subsubsectionTitleMapCode].itemChanged) {
                                                if (adaptationDictionary[subsubsectionTitleMapCode].revisedValue) {
                                                    subsubsection.title = adaptationDictionary[subsubsectionTitleMapCode].revisedValue;
                                                } else {
                                                    subsubsection.title = "";
                                                }
                                                let adaptationContent = {
                                                    "type": { "text": "adapted element" },
                                                    "classifier": [
                                                        {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112109",
                                                                    "code": "string-datatype",
                                                                    "display": "string Datatype"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "path": [subsubsectionCode + ".title"],
                                                    "component": [
                                                        {
                                                            "summary": adaptationDictionary[subsubsectionTitleMapCode].initialValue,
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "OriginalResourceContent",
                                                                        "display": "Original Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            "summary": adaptationDictionary[subsubsectionTitleMapCode].revisedValue,
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "RevisedResourceContent",
                                                                        "display": "Revised Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                };
                                                if (adaptationDictionary[subsubsectionTitleMapCode].rationale) {
                                                    adaptationContent.component.push({
                                                        "summary": adaptationDictionary[subsubsectionTitleMapCode].rationale,
                                                        "type": {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                    "code": "RationaleForChange",
                                                                    "display": "Rationale for Change to Resource Content"
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                                adaptationReportJson.content.push(adaptationContent);
                                            }
                                        }
                                        if (adaptationDictionary[subsubsectionAuthorMapCode]) {
                                            if (adaptationDictionary[subsubsectionAuthorMapCode].itemChanged) {
                                                if (adaptationDictionary[subsubsectionAuthorMapCode].revisedValue) {
                                                    subsubsection.author = adaptationDictionary[subsubsectionAuthorMapCode].revisedValue;
                                                } else {
                                                    subsubsection.author = [];
                                                }
                                                let adaptationContent = {
                                                    "type": { "text": "adapted element" },
                                                    "classifier": [
                                                        {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112109",
                                                                    "code": "reference-array",
                                                                    "display": "Reference Array"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "path": [subsubsectionCode + ".author"],
                                                    "component": [
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionAuthorMapCode].initialValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "OriginalResourceContent",
                                                                        "display": "Original Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionAuthorMapCode].revisedValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "RevisedResourceContent",
                                                                        "display": "Revised Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                };
                                                if (adaptationDictionary[subsubsectionAuthorMapCode].rationale) {
                                                    adaptationContent.component.push({
                                                        "summary": adaptationDictionary[subsubsectionAuthorMapCode].rationale,
                                                        "type": {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                    "code": "RationaleForChange",
                                                                    "display": "Rationale for Change to Resource Content"
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                                adaptationReportJson.content.push(adaptationContent);
                                            }
                                        }
                                        if (adaptationDictionary[subsubsectionFocusMapCode]) {
                                            if (adaptationDictionary[subsubsectionFocusMapCode].itemChanged) {
                                                if (adaptationDictionary[subsubsectionFocusMapCode].revisedValue) {
                                                    subsubsection.focus = adaptationDictionary[subsubsectionFocusMapCode].revisedValue;
                                                } else {
                                                    subsubsection.focus = "";
                                                }
                                                let adaptationContent = {
                                                    "type": { "text": "adapted element" },
                                                    "classifier": [
                                                        {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112109",
                                                                    "code": "manageAsReference",
                                                                    "display": "Manage as Reference"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "path": [subsubsectionCode + ".focus"],
                                                    "component": [
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionFocusMapCode].initialValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "OriginalResourceContent",
                                                                        "display": "Original Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionFocusMapCode].revisedValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "RevisedResourceContent",
                                                                        "display": "Revised Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                };
                                                if (adaptationDictionary[subsubsectionFocusMapCode].rationale) {
                                                    adaptationContent.component.push({
                                                        "summary": adaptationDictionary[subsubsectionFocusMapCode].rationale,
                                                        "type": {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                    "code": "RationaleForChange",
                                                                    "display": "Rationale for Change to Resource Content"
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                                adaptationReportJson.content.push(adaptationContent);
                                            }
                                        }
                                        if (adaptationDictionary[subsubsectionOrderedByMapCode]) {
                                            if (adaptationDictionary[subsubsectionOrderedByMapCode].itemChanged) {
                                                if (adaptationDictionary[subsubsectionOrderedByMapCode].revisedValue) {
                                                    subsubsection.orderedBy = adaptationDictionary[subsubsectionOrderedByMapCode].revisedValue;
                                                } else {
                                                    subsubsection.orderedBy = "";
                                                }
                                                let adaptationContent = {
                                                    "type": { "text": "adapted element" },
                                                    "classifier": [
                                                        {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112109",
                                                                    "code": "single-codeableconcept-object",
                                                                    "display": "Single CodeableConcept Object"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "path": [subsubsectionCode + ".orderedBy"],
                                                    "component": [
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionOrderedByMapCode].initialValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "OriginalResourceContent",
                                                                        "display": "Original Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionOrderedByMapCode].revisedValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "RevisedResourceContent",
                                                                        "display": "Revised Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                };
                                                if (adaptationDictionary[subsubsectionOrderedByMapCode].rationale) {
                                                    adaptationContent.component.push({
                                                        "summary": adaptationDictionary[subsubsectionOrderedByMapCode].rationale,
                                                        "type": {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                    "code": "RationaleForChange",
                                                                    "display": "Rationale for Change to Resource Content"
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                                adaptationReportJson.content.push(adaptationContent);
                                            }
                                        }
                                        if (adaptationDictionary[subsubsectionEntryMapCode]) {
                                            if (adaptationDictionary[subsubsectionEntryMapCode].itemChanged) {
                                                if (adaptationDictionary[subsubsectionEntryMapCode].revisedValue) {
                                                    subsubsection.entry = adaptationDictionary[subsubsectionEntryMapCode].revisedValue;
                                                } else {
                                                    subsubsection.entry = [];
                                                }
                                                let adaptationContent = {
                                                    "type": { "text": "adapted element" },
                                                    "classifier": [
                                                        {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112109",
                                                                    "code": "reference-array",
                                                                    "display": "Reference Array"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "path": [subsubsectionCode + ".entry"],
                                                    "component": [
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionEntryMapCode].initialValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "OriginalResourceContent",
                                                                        "display": "Original Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionEntryMapCode].revisedValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "RevisedResourceContent",
                                                                        "display": "Revised Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                };
                                                if (adaptationDictionary[subsubsectionEntryMapCode].rationale) {
                                                    adaptationContent.component.push({
                                                        "summary": adaptationDictionary[subsubsectionEntryMapCode].rationale,
                                                        "type": {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                    "code": "RationaleForChange",
                                                                    "display": "Rationale for Change to Resource Content"
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                                adaptationReportJson.content.push(adaptationContent);
                                            }
                                        }
                                        if (adaptationDictionary[subsubsectionEmptyReasonMapCode]) {
                                            if (adaptationDictionary[subsubsectionEmptyReasonMapCode].itemChanged) {
                                                if (adaptationDictionary[subsubsectionEmptyReasonMapCode].revisedValue) {
                                                    subsubsection.emptyReason = adaptationDictionary[subsubsectionEmptyReasonMapCode].revisedValue;
                                                } else {
                                                    subsubsection.emptyReason = "";
                                                }
                                                let adaptationContent = {
                                                    "type": { "text": "adapted element" },
                                                    "classifier": [
                                                        {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112109",
                                                                    "code": "single-codeableconcept-object",
                                                                    "display": "Single CodeableConcept Object"
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    "path": [subsubsectionCode + ".emptyReason"],
                                                    "component": [
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionEmptyReasonMapCode].initialValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "OriginalResourceContent",
                                                                        "display": "Original Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            "summary": JSON.stringify(adaptationDictionary[subsubsectionEmptyReasonMapCode].revisedValue),
                                                            "type": {
                                                                "coding": [
                                                                    {
                                                                        "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                        "code": "RevisedResourceContent",
                                                                        "display": "Revised Resource Content"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                };
                                                if (adaptationDictionary[subsubsectionEmptyReasonMapCode].rationale) {
                                                    adaptationContent.component.push({
                                                        "summary": adaptationDictionary[subsubsectionEmptyReasonMapCode].rationale,
                                                        "type": {
                                                            "coding": [
                                                                {
                                                                    "system": "https://fevir.net/resources/CodeSystem/112108",
                                                                    "code": "RationaleForChange",
                                                                    "display": "Rationale for Change to Resource Content"
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }
                                                adaptationReportJson.content.push(adaptationContent);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        returningdata = { "success": true, "newCompositionJson": newCompositionJson, "adaptationReportJson": adaptationReportJson };
    } catch (error) {
        alert("Failure creating new Composition. See console for error message and notify support@computablepublishing.com");
        console.log(error);
    }
    return returningdata;
}

const submitResource = async (globalContext, fhirJson) => {

    let fhirEntryString = JSON.stringify(fhirJson, null, 2);

    const body = {
        'functionid': "submitfhirresource",
        'idToken': "",
        'fhirEntry': fhirEntryString,
        'title': fhirJson.title,
        'status': "active",
    };

    let response = await submitToFevirServer(globalContext, 5000, body, true, false);

    if (response?.success) {
        return response.formstateid;
    }

};

const submitNewAdaptedResource = async (fhirEntryState, globalContext, history) => {
    let resourceJson = JSON.parse(fhirEntryState.fhirEntryString);
    if (resourceJson.resourceType === "Composition") {
        let response = createAdaptedComposition(resourceJson, fhirEntryState, globalContext);
        if (response?.success) {
            let newCompositionJson = response.newCompositionJson;
            const adaptationReportJson = response.adaptationReportJson;
            let adaptationReportFOI = await submitResource(globalContext, adaptationReportJson);
            newCompositionJson.relatesTo.push({
                "type": "derived-from",
                "classifier": [{ "text": "Adaptation Report" }],
                "resourceReference": {
                    "reference": "ArtifactAssessment/" + adaptationReportFOI,
                    "type": "ArtifactAssessment",
                    "display": adaptationReportJson.title
                }
            });
            let newCompositionFOI = await submitResource(globalContext, newCompositionJson);
            if (newCompositionFOI) {
                let newUrl = "/resources/Composition/" + newCompositionFOI;
                history.push('/');
                history.push(newUrl);
            }
        }
    }
}

export { submitNewAdaptedResource }