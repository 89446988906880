import { Switch } from '@mui/material';
import { styled } from '@mui/styles';
import "firebase/compat/auth";
import './App.css';

const YesNoSwitch = styled(Switch)(({ value }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30"><text x="10%" y="66%" font-size="14" font-weight="bold" font-family="sans-serif" fill="white">Yes</text></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#94C09E',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: (value && (value === true || value.toLowerCase() === "yes" || value.toLowerCase() === "true")) ? '#178230' : '#C52424',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="30" width="30"><text x="18%" y="66%" font-size="14" font-weight="bold" font-family="sans-serif" fill="white">No</text></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#C0949E',
    borderRadius: 10,
  },
}));

export default YesNoSwitch;