import React, { useEffect, useState } from 'react';
import { DisplayFromFHIR } from './ResourceFunctions';
import {
  EditIntroduction, EditDiscussion, EditMethods, EditReferences, EditCompetingInterests,
  EditAcknowledgements, EditAppendices
} from './EvidenceReportPackageAuthoringTool';
import { loadSourceJsonFunction } from './loadSourceJsonFunction';
import { EditTheSectionButton } from './NavigationCompositionSectionSegment';
import { DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import { emptyReasonNotStarted, emptyReasonValueSet, emptyReasonCodeableConceptSet, sectionCodeSummary, sectionCodeIntroduction, sectionCodeDiscussion, sectionCodeMethods, sectionCodeReferences, sectionCodeCompetingInterests, sectionCodeAcknowledgements, sectionCodeAppendices, sectionCodeRecommendations, compositionTypeCodeGuideline } from './CodeSystemLookup';

const RecommendationsDisplay = ({ recommendationDisplayValuesDictionary }) => {

  let recommendationKeys = [];
  if (recommendationDisplayValuesDictionary && typeof recommendationDisplayValuesDictionary === 'object' &&
    Object.keys(recommendationDisplayValuesDictionary).length > 0) {
    recommendationKeys = Object.keys(recommendationDisplayValuesDictionary);
  }
  let numberOfRecommendations = recommendationKeys.length || 0;

  if (numberOfRecommendations === 0) {
    return <div>
      <p>There are no recommendations yet. Create a Recommendation above to add one, or <a
        href='/createrecommendation' target='_blank'
        rel='noopener noreferrer'>Create Recommendation</a> then Add Content Entry above to associate it with this guideline.</p>
    </div>
  }

  return <div>
    <div style={{ marginLeft: "24px" }}>
      {numberOfRecommendations > 0 && recommendationKeys.map((key, keyIndex) => {
        const rec = recommendationDisplayValuesDictionary[key];
        return <div key={keyIndex} >
          <p><b>Recommendation {keyIndex + 1} of {numberOfRecommendations}</b></p>
          <div style={{ marginLeft: "24px" }}>
            {rec.title && <p><b>Title: </b>{rec.title}</p>}
            {rec.statement && <span><b>Recommendation Statement: </b><DisplayFromFHIR xhtml={rec.statement} /><br /></span>}
            {rec.ratings && <span><b>Strength/Level of Recommendation: </b><DisplayFromFHIR xhtml={rec.ratings} /><br /></span>}
            {rec.population && <span><b>Population: </b><DisplayFromFHIR xhtml={rec.population} /><br /></span>}
            {rec.action && <span><b>Action: </b><DisplayFromFHIR xhtml={rec.action} /><br /></span>}
            {rec.resourceReference && <span><b>Recommendation Resource: </b>
              <DisplayFromFHIR reference={rec.resourceReference} /></span>}
          </div>
        </div>
      })}
    </div>
  </div>
}

const reorderSections = (sectionArray, setSectionsReorderedState) => {
  let emptySummary = {
    "title": "Summary Section",
    "code": sectionCodeSummary,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyIntroduction = {
    "title": "Introduction Section",
    "code": sectionCodeIntroduction,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyDiscussion = {
    "title": "Discussion Section",
    "code": sectionCodeDiscussion,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyMethods = {
    "title": "Methods Section",
    "code": sectionCodeMethods,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyReferences = {
    "title": "References Section",
    "code": sectionCodeReferences,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyCompetingInterests = {
    "title": "Competing Interests Section",
    "code": sectionCodeCompetingInterests,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAcknowledgements = {
    "title": "Acknowledgements Section",
    "code": sectionCodeAcknowledgements,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyAppendices = {
    "title": "Appendices Section",
    "code": sectionCodeAppendices,
    "emptyReason": emptyReasonNotStarted
  };
  let emptyRecommendations = {
    "title": "Recommendations Section",
    "code": sectionCodeRecommendations,
    "emptyReason": emptyReasonNotStarted
  };
  let sectionDictionary = {
    summary: emptySummary,
    introduction: emptyIntroduction,
    discussion: emptyDiscussion,
    methods: emptyMethods,
    references: emptyReferences,
    competingInterests: emptyCompetingInterests,
    acknowledgements: emptyAcknowledgements,
    appendices: emptyAppendices,
    recommendations: emptyRecommendations,
    additionalSections: []
  };
  for (const section of sectionArray) {
    let sectionCode = section?.code?.text || section?.code?.coding?.[0]?.code;
    if (sectionCode === "introduction" || sectionCode === "Introduction") {
      sectionDictionary.introduction = section;
    } else if (sectionCode === "discussion" || sectionCode === "Discussion" || sectionCode === "text" || sectionCode === "Text") {
      sectionDictionary.discussion = section;
    } else if (sectionCode === "methods" || sectionCode === "Methods") {
      sectionDictionary.methods = section;
    } else if (sectionCode === "summary" || sectionCode === "Summary") {
      sectionDictionary.summary = section;
    } else if (sectionCode === "references" || sectionCode === "References") {
      sectionDictionary.references = section;
    } else if (sectionCode === "competing-interests" || sectionCode === "Competing Interests") {
      sectionDictionary.competingInterests = section;
    } else if (sectionCode === "acknowledgements" || sectionCode === "Acknowledgements") {
      sectionDictionary.acknowledgements = section;
    } else if (sectionCode === "appendices" || sectionCode === "Appendices") {
      sectionDictionary.appendices = section;
    } else if (sectionCode.includes("recommendations") || sectionCode === "Recommendations") {
      sectionDictionary.recommendations = section;
    } else if (section) {
      sectionDictionary.additionalSections.push(section);
    }
  }
  let reorderedSectionArray = [
    sectionDictionary.summary,
    sectionDictionary.introduction,
    sectionDictionary.methods,
    sectionDictionary.recommendations,
    sectionDictionary.discussion,
    sectionDictionary.references,
    sectionDictionary.competingInterests,
    sectionDictionary.acknowledgements,
    sectionDictionary.appendices
  ].concat(sectionDictionary.additionalSections);
  if (sectionArray.length !== reorderedSectionArray.length) {
    setSectionsReorderedState(true);
  }
  return reorderedSectionArray;
};

const GuidelineAuthor = ({ resourceState, setResourceState, globalContext, sourceJsonState,
  setSourceJsonState, previousVersionLoaded, history, setFhirEntryState,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {

  let defaultType = compositionTypeCodeGuideline;

  const [sectionsReorderedState, setSectionsReorderedState] = useState(false);

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        let newSection = reorderSections(JSON.parse(JSON.stringify(prevState.section)), setSectionsReorderedState);
        return {
          ...prevState,
          type: prevState.type || defaultType,
          section: newSection
        };
      });
    }
  }, []);

  useEffect(() => {
    if (sectionsReorderedState) {
      if (adaptationReportState?.adaptOn) {
        alert("Sections have been reordered. Before proceeding with Adapting this Resource, it is suggested to edit the Resource or Clone the Resource, then edit it to reorder sections, then proceed with Adaptation.");
      } else {
        alert("Sections have been reordered. Clicking Update is suggested before further editing.");
      }
    }
  }, [sectionsReorderedState]);

  const loadSourceJson = () => {
    if (!sourceJsonState.loaded) {
      loadSourceJsonFunction(resourceState, globalContext, setSourceJsonState);
    }
  }

  useEffect(() => {
    loadSourceJson();
  }, [sourceJsonState]);

  const EditSectionViaSectionDetail = ({ sectionNumber, sectionTitle }) => {
    return <div style={{ marginLeft: "24px" }}>
      <EditTheSectionButton theThis="the" sectionName={sectionTitle}
        sectionSelected={resourceState.section[sectionNumber]} sectionPathIndexes={[sectionNumber]}
        resourceStateId={resourceState.id} history={history}
        setFhirEntryState={setFhirEntryState} />
      <p><b>Section Title: </b>{resourceState.section[sectionNumber].title}</p>
      <p>Narrative Status: {resourceState.section[sectionNumber].text?.status || "empty"}</p>
      <DisplayFromFHIR xhtml={resourceState.section[sectionNumber].text?.div || null} />
      <br />
      {(Array.isArray(resourceState.section[sectionNumber].author) && resourceState.section[sectionNumber].author.length > 0) &&
        resourceState.section[sectionNumber].author.map((author, authorIndex) => {
          return <div key={authorIndex}><b>Author: </b><DisplayFromFHIR reference={author} /></div>;
        })}
      {resourceState.section[sectionNumber].orderedBy && <span>
        <b>Ordered by: </b>
        <DisplayFromFHIR codeableConcept={resourceState.section[sectionNumber].orderedBy} /><br />
      </span>}
      {((Array.isArray(resourceState.section[sectionNumber].entry) && resourceState.section[sectionNumber].entry.length > 0) ||
        (Array.isArray(resourceState.section[sectionNumber].section) && resourceState.section[sectionNumber].section.length > 0)) &&
        <div>
          <p><b>Section Content</b></p>
          <div style={{ marginLeft: "24px" }}>
            {(Array.isArray(resourceState.section[sectionNumber].entry) && resourceState.section[sectionNumber].entry.length > 0) &&
              resourceState.section[sectionNumber].entry.map((entry, entryIndex) => {
                return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
              })}
            {(Array.isArray(resourceState.section[sectionNumber].section) && resourceState.section[sectionNumber].section.length > 0) &&
              resourceState.section[sectionNumber].section.map((section, sectionIndex) => {
                return <div key={sectionIndex}>
                  <p><b>Section {sectionIndex + 1}: </b>{section.title}</p>
                  <p>Narrative Status: {section.text?.status || "empty"}</p>
                  <DisplayFromFHIR xhtml={section.text?.div || null} />
                  {(Array.isArray(section.entry) && section.entry.length > 0) &&
                    section.entry.map((entry, entryIndex) => {
                      return <div key={entryIndex}><b>Entry {entryIndex + 1}: </b><DisplayFromFHIR reference={entry} /></div>;
                    })}
                  <br />
                  {(Array.isArray(section.section) && section.section.length > 0) && <div>
                    <p>Contained sections:</p>
                    <div style={{ marginLeft: "24px" }}>
                      {section.section.map((sect, sectIndex) => {
                        return <div key={sectIndex}>
                          <p>
                            <b>Section {sectIndex + 1}: </b>
                            {sect.title || sect.code?.text || sect.code?.coding?.[0]?.display || "Untitled section"}
                          </p>
                        </div>;
                      })}
                    </div>
                    <br />
                  </div>
                  }
                  <EditTheSectionButton theThis="this"
                    sectionName={section.title || sectionTitle + " section " + (sectionIndex + 1)}
                    sectionSelected={resourceState.section[sectionNumber].section[sectionIndex]}
                    sectionPathIndexes={[sectionNumber, sectionIndex]}
                    resourceStateId={resourceState.id} history={history}
                    setFhirEntryState={setFhirEntryState} />
                  <p>-----------------</p>
                </div>;
              })}
          </div>
        </div>
      }
      {resourceState.section[sectionNumber].emptyReason && <span>
        <b>Empty Reason: </b>
        <DisplayFromFHIR codeableConcept={resourceState.section[sectionNumber].emptyReason} /><br />
      </span>}
      <EditTheSectionButton theThis="this" sectionName={sectionTitle}
        sectionSelected={resourceState.section[sectionNumber]} sectionPathIndexes={[sectionNumber]}
        resourceStateId={resourceState.id} history={history}
        setFhirEntryState={setFhirEntryState} />
    </div>
  }

  return <>{(sourceJsonState.loaded && resourceState.section?.length > 8) ?
    <div style={{ marginTop: "12px" }}>
      {adaptationReportState?.adaptOn ?
        <>
          <h3 id="summary">Summary</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={0}
              fieldLabel="Summary" sectionCode="section[0]:https://fevir.net/resources/CodeSystem/179423#summary"
              startingValue={resourceState.section[0] || null}
              fixedTitle={"Summary Section"} fixedCode={sectionCodeSummary} startCollapsed editTextDiv={true} textDeletable={true}
              emptyTextDivValue="[No data.]"
              editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
              noEntry={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonStartCollapsed={true}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              editSection={true}
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          </div>
          <EditIntroduction sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditMethods sectionIndex={2} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <h3 id="recommendations">Recommendations</h3>
          <div style={{ marginLeft: "24px" }}>
            <DocumentSectionEntry sectionArrayIndex={3}
              startingValue={resourceState.section[3] || null}
              fieldLabel="Recommendations"
              sectionCode={"section[3]:https://fevir.net/resources/CodeSystem/179423#recommendations"}
              fixedTitle={"Recommendations Section"} fixedCode={sectionCodeRecommendations} editOrderedBy={true} editOrderedByStartCollapsed={true}
              startCollapsed editTextDiv={true} editAuthor={true}
              noEntry={false} entryDeletable={true} entryInstanceDeletable={true}
              entryEnableCreation={true} entryStartCollapsed={true}
              entryReferencedResourceTypes={["Composition"]}
              entryStartingResourceType="Composition"
              entrySetProfile="Recommendation"
              editEmptyReason={true}
              emptyReasonValueSet={emptyReasonValueSet}
              emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
              emptyReasonStartCollapsed={true}
              editSection={true}
              editableSectionCodes={true} addSectionAllowed={true}
              setResourceState={setResourceState}
              compositionId={resourceState.id} compositionTitle={resourceState.title}
              adaptationReportState={adaptationReportState}
              setAdaptationReportState={setAdaptationReportState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState}
              globalContext={globalContext} resourceState={resourceState} setSourceJsonState={setSourceJsonState} />
          </div>
          <EditDiscussion sectionIndex={4} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditReferences sectionIndex={5} previousVersionLoaded={previousVersionLoaded}
            resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditCompetingInterests sectionIndex={6} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditAcknowledgements sectionIndex={7} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          <EditAppendices sectionIndex={8} resourceState={resourceState} setResourceState={setResourceState}
            adaptationReportState={adaptationReportState}
            setAdaptationReportState={setAdaptationReportState}
            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
        </>
        :
        <>
          <h3 id="summary">Summary</h3>
          <EditSectionViaSectionDetail sectionNumber={0} sectionTitle={"Summary"} />
          <>
            <h3 id="introduction">Introduction</h3>
            <EditSectionViaSectionDetail sectionNumber={1} sectionTitle={"Introduction"} />
          </>
          <>
            <h3 id="methods">Methods</h3>
            <EditSectionViaSectionDetail sectionNumber={2} sectionTitle={"Methods"} />
          </>
          <h3 id="recommendations">Recommendations</h3>
          <EditSectionViaSectionDetail sectionNumber={3} sectionTitle={"Recommendations"} />
          <h3>To edit the Recommendations, click through to edit directly.</h3>
          <RecommendationsDisplay recommendationDisplayValuesDictionary={sourceJsonState.recommendationDisplayValuesDictionary} />
          <>
            <h3 id="discussion">Discussion</h3>
            <EditSectionViaSectionDetail sectionNumber={4} sectionTitle={"Discussion"} />
          </>
          <>
            <h3 id="references">References</h3>
            <EditSectionViaSectionDetail sectionNumber={5} sectionTitle={"References"} />
          </>
          <>
            <h3 id="competing-interests">Competing Interests</h3>
            <EditSectionViaSectionDetail sectionNumber={6} sectionTitle={"Competing Interests"} />
          </>
          <>
            <h3 id="acknowledgements">Acknowledgements</h3>
            <EditSectionViaSectionDetail sectionNumber={7} sectionTitle={"Acknowledgements"} />
          </>
          <>
            <h3 id="appendices">Appendices</h3>
            <EditSectionViaSectionDetail sectionNumber={8} sectionTitle={"Appendices"} />
          </>
        </>
      }
    </div>
    :
    <div><p>Loading ...</p></div>}</>
};

const changeSectionDetailEditSettingsForGuideline = (sectionDetailEditSettings, section) => {

  if (section.code?.coding?.[0]?.code === "summary" || section.code?.text === "Summary") {
    sectionDetailEditSettings.fixedTitle = "Summary";
    sectionDetailEditSettings.editCode = false;
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.noEntry = true;
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
  }
  if (section.code?.coding?.[0]?.code === "recommendations" || section.code?.text?.includes("recommendations")) {
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.entryHeader = "Add Composition Resource (Recommendation Profile)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Composition"];
    sectionDetailEditSettings.entryStartingResourceType = "Composition";
    sectionDetailEditSettings.entrySetProfile = "Recommendation";
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
    sectionDetailEditSettings.createRecommendation = true;
    sectionDetailEditSettings.editCode = true;
    if (section.code?.coding?.[0]?.code === "recommendations") {
      sectionDetailEditSettings.editCode = false;
    }
  }

  return sectionDetailEditSettings;
}

export { GuidelineAuthor, changeSectionDetailEditSettingsForGuideline };