import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import "firebase/compat/auth";
import axios from 'axios';
import server_config from './ServerConfiguration';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
const go_server_address = server_config["go_server_address"];

const CTgovConvertPage = ( { useTitle,  urlNCTid } ) => {

  useTitle("FEvIR ClinicalTrials.gov-to-FEvIR Converter");
  const globalContext = useContext(FevirContext);

  const [CTgovEntryState, setCTgovEntryState] = useState({"nctid": urlNCTid, "loadingSubmission": false});

  const changeCTgovEntryState = (newValue, field) => {
    setCTgovEntryState(prevState  => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const submitNCTId = async (nctid, redirect) => {
    changeCTgovEntryState(true, "loadingSubmission");
    if (nctid === undefined) {
      nctid = CTgovEntryState.nctid;
    }
    if (redirect === undefined) {
      redirect = true;
    }

    if (nctid) {
      changeCTgovEntryState(true, "loadingSubmission");
    } else {
      globalContext.openAlert({header: "Please enter a NCT ID.", content: ""});
    }

    const body = {
      'functionid': "submitnctid",      
      'tool': "ctgovtofhirconverter",
      'idToken': "",
      'nctid': nctid || "",
      'addtodatabase': true,
      'status':  "active",
    };

    let response = await submitToFevirServer(globalContext, 120000, body, true, false);
    
    if (response?.success) {
      if (redirect) {
        if (response.jobAlreadyRunning && response.message) {
          globalContext.openAlert({"header": "Job Currently In Process", "content": response.message });
        } else if (response.numberOfResourcesCreated) {
          if (response.largeCollection) {
            const messageContent = "The ClinicalTrials.gov record was converted to " + response.numberOfResourcesCreated + " FHIR Resources. Click out to view the ResearchStudy Resource. Because the number of Resources created is so large, it may take a few minutes before the referenced Resources are loaded to the FEvIR Platform.";
            globalContext.openAlert({"header": "Processing", "content": messageContent });
          } else {
            const messageContent = "The ClinicalTrials.gov record was converted to " + response.numberOfResourcesCreated + " FHIR Resources. Click out to view the ResearchStudy Resource.";
            globalContext.openAlert({"header": "Complete", "content": messageContent });
          }
        } else if (response.message) {
          globalContext.openAlert({"header": "", "content": response.message });
        }
        if (response.researchstudyfoi) {
          history.push(`/resources/ResearchStudy/${response.researchstudyfoi}`);
        }
      }
    } else if (response?.errormessage) {
      changeCTgovEntryState(false, "loadingSubmission");
      globalContext.openAlert({"header": "Error", "content": response.errormessage });
    } else {
      changeCTgovEntryState(false, "loadingSubmission");
      const messageContent = "We were unable to convert this citation. Perhaps this NCT ID: " + nctid + " doesn't exist or there are issues in its structure.";
      globalContext.openAlert({"header": "Error", "content": messageContent });
    }

  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !globalContext.alertModalState.open) {
      submitNCTId();
    }
  }

  const sleep = (ms) => {
    return new Promise(r => setTimeout(r, ms));
  }

  const updateAllCTgovConvertedResources = async () => {
      if (!globalContext.userState.loading && globalContext.userState.name === "Khalid Shahin") {
        let idToken = globalContext.userState.idToken;
  
        const body = {
          'functionid': 'getfhirresourceslist',
          'loadmyresources': false,
          'idToken': idToken
        };
  
        axios({
          method: 'POST',
          //url: server_address + ':' + ports["master_server"] + '/',
          url: go_server_address,
          timeout: 5000,
          data: body
        }).then(async (resp) => {
          if (resp?.data?.success) {
            setTimeout(() => { }, 600);
            for (let index in resp.data.resourceslist) {
              let resource = resp.data.resourceslist[index];
              let nctid = resource.nctid;
              if (nctid){          
                await submitNCTId(nctid, false);
                await sleep(16000);
              }
            }
            globalContext.openAlert({"header": "All done!", "content": "" });
          }
        });
      }
  }

  useEffect( async () => {
    if (globalContext.userState.loading === false) {
      if (urlNCTid) {
        submitNCTId();
        history.replace("/ctgovconvert");
      }    
    }
  }, [globalContext.userState]);

  return (
    <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <h2 style={{textAlign: "center", color: "#AC3B61"}}>Computable Publishing<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: ClinicalTrials.gov-to-FEvIR Converter</h2>
      {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
      <br /><br />
      {/*I made it so you don't need to be logged in.*/}
      {true || globalContext.userState.firebaseuid ?
        <>
          <TextField style={{width: "180px"}} className="inputField" type='text' size="small" variant='outlined' placeholder='NCT ID' value={CTgovEntryState.nctid} onKeyUp={handleKeyUp} onChange={(e) => { changeCTgovEntryState(e.target.value, "nctid"); } } />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button className="formButton positive" content="Submit" compact onClick={() => submitNCTId()} disabled={CTgovEntryState.loadingSubmission} />
          {globalContext.userState.name === "Khalid Shahin" && <><Button className="formButton positive" content="UPDATE ALL NCTId Resources" compact onClick={updateAllCTgovConvertedResources} /></>}
          <br/>
             <br/>
          <img className={CTgovEntryState.loadingSubmission ? 'visible' : 'invisible'} style={{height: "22px"}} src="/spinner.gif" alt="Loading" />
          <div style={{"textAlign": "left", "width": "100%"}}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h3>{CTgovEntryState.loadingSubmission ? <>Your requested ClinicalTrials.gov study record is being converted to</> : <><br/>Enter an NCT Identifier to automatically create</>} HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Resources.</h3><span>HL7<sup>&#174;</sup> and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
          </div>
        </>
          :
            <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
      </Segment>
    </div>
  );
};

  export default CTgovConvertPage;