import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import axios from 'axios';
import server_config from './ServerConfiguration';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const go_server_address = server_config["go_server_address"];

const MedlineConvertPage = ({ useTitle }) => {

  useTitle("FEvIR MEDLINE-to-FEvIR Converter");

  const [medlineEntryState, setMedlineEntryState] = useState({
    "pmid": "", "submitButtonText": "Submit", "projectfoi": "", "status": "active", "loadingSubmission": false
  });
  const globalContext = useContext(FevirContext);

  const changeMedlineEntryState = (newValue, field) => {
    setMedlineEntryState(prevState => {
      return {
        ...prevState,
        //medlineEntry: newValue,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  /*
  const getPmidListFromSearchQuery = async () => {

    if (medlineEntryState.medlinequery) {
      changeMedlineEntryState(true, "loadingSubmission");
      const body = {
        'functionid': 'getpmidsfrommedlinesearchquery',
        'medlinequery': medlineEntryState.medlinequery,
        'idToken': ''
      };

      let response = await submitToFevirServer(globalContext, 15000, body, true, false);
      if (response?.success) {
        //Loads PMIDS into text box (the state)
      }
      changeMedlineEntryState(false, "loadingSubmission");
    } else {
      globalContext.openAlert({ "header": "", "content": "Please enter a query to use this function of auto-filling PMIDS." });
    }

  }
  */

  const submitPMID = async (pmid, projectfoi, multiple, redirect) => {
    pmid = pmid ?? medlineEntryState.pmid;
    projectfoi = projectfoi ?? medlineEntryState.projectfoi;
    multiple = multiple ?? false;
    redirect = redirect ?? true;

    pmid = pmid.replaceAll(" ", "");
    if (pmid.match(/[^$\d]/)) {
      globalContext.openAlert({ "header": "Error", "content": "The PMID cannot contain any non-numerical characters: " + pmid });
    } else {
      pmid = parseInt(pmid).toString();

      const body = {
        'functionid': "submitpmid",
        'tool': "medlinetofhirconverter",
        'idToken': "",
        'pmid': pmid,
        'multiple': multiple,
        'projectfoi': projectfoi,
        'status': medlineEntryState.status
      };
      let response = await submitToFevirServer(globalContext, 120000, body, true, false);

      if (response?.success) {
        if (redirect) {
          history.push(`/resources/Citation/${response.formstateid}`);
        } else {
          return response;
        }
      } else if (response?.errormessage) {
        globalContext.openAlert({ "header": "Error", "content": response.errormessage });
        response.projecterror = true;
        return response;
      } else {
        globalContext.openAlert({ "header": "Error", "content": "We were unable to convert this citation: " + pmid + ". Perhaps this PMID entry doesn't have Medline XML. Maybe it's a Book entry, and we're only converting Journal Articles at this time." });
      }
    }
  };

  const submitPMIDList = async () => {
    let pmidlistsplit = medlineEntryState.pmid.replace(" ", "").split(',');
    let successfulFois = [];
    let failedPmids = [];
    let projectfoi = medlineEntryState.projectfoi;

    let makeNewProject = false;
    let title = "MEDLINE Citations added by " + globalContext.userState.name;
    let fhirJson = { "resourceType": "Project", "title": title };
    if (!projectfoi) {
      makeNewProject = true;
      const body = {
        'functionid': 'submitfhirresource',
        'idToken': '',
        'fhirEntry': JSON.stringify(fhirJson, null, 2),
        'title': title,
        'status': "active",
      };

      let response = await submitToFevirServer(globalContext, 9000, body, true, false);
      if (response?.success) {
        projectfoi = response.formstateid;
      } else {
        globalContext.openAlert({ "header": "Error", "content": "Problem creating Project." });
        return null;
      }
    }

    for (const pmid of pmidlistsplit) {
      if (pmid) {
        let response = await submitPMID(pmid, projectfoi, true, false);
        if (response?.success) {
          successfulFois.push(response.formstateid);
          await sleep(400);
        } else {
          failedPmids.push(pmid);
          if (response?.projecterror) {
            console.log("Please enter a correct Project FOI, or clear it and we'll make one for you.")
            break;
          }
        }
      }
    }
    if (makeNewProject) {

      if (successfulFois.length) {
        fhirJson.description = "You successfully added " + successfulFois.length + " Citations.";
      } else {
        fhirJson.description = "No Citations were successfully added.";
      }

      if (failedPmids.length) {
        fhirJson.description += "Suggested PMIDs that did not result in Citations include: " + failedPmids.join(", ");
      }

      let body = {
        'functionid': 'updatefhirresource',
        'idToken': '',
        'fhirEntry': JSON.stringify(fhirJson, null, 2),
        'resourcetype': "Project",
        'resourceid': projectfoi,
        'title': title,
        'status': "active",
        'bypasswarnings': true
      };
      let response = await submitToFevirServer(globalContext, 9000, body, false, false);
    }
    changeMedlineEntryState(false, "loadingSubmission");
    history.push(`/resources/Project/${projectfoi}`);
  }


  const submitMedlineQuery = async () => {
    if (medlineEntryState.pmid) {
      changeMedlineEntryState(true, "loadingSubmission");

      const body = {
        'functionid': 'importmedlinesearchquery',
        'medlinequery': medlineEntryState.pmid,
        'projectfoi': medlineEntryState.projectfoi,
        'idToken': ''
      };

      let response = await submitToFevirServer(globalContext, 120000, body, true, false);
      changeMedlineEntryState(false, "loadingSubmission");
      if (response?.success) {
        if (response.noResults) {
          globalContext.openAlert({ "header": "", "content": "No results found for this query." });
        } else {
          history.push(`/resources/Project/${response.projectfoi}`);
        }
      } else {
        globalContext.openAlert({ "header": "Error", "content": "Something went wrong with the search query." });
      }

    } else {
      globalContext.openAlert({ "header": "", "content": "Please enter a PMID Query." });
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !globalContext.alertModalState.open) {
      submitPMIDEntry();
    }
  }

  const submitPMIDEntry = async () => {
    if (medlineEntryState.pmid) {
      changeMedlineEntryState(true, "loadingSubmission");
      if (medlineEntryState.pmid.match(/[^$\d\s,]/)) {
        await submitMedlineQuery();
      } else if (medlineEntryState.pmid.includes(",")) {
        await submitPMIDList();
      } else {
        await submitPMID();
      }
      changeMedlineEntryState(false, "loadingSubmission");
    } else {
      globalContext.openAlert({ "header": "", "content": "Please enter a PMID, a comma-separated list of PMIDs, or a search query." });
    }
  }

  /*
  const handleKeyDownForList = (event) => {
    if (event.key === 'Enter') {
      submitPMIDList();
    }
  }*/

  const sleep = (ms) => {
    return new Promise(r => setTimeout(r, ms));
  }

  const updatePMIDConvertedResources = () => {
    if (!globalContext.userState.loading) {
      let idToken = globalContext.userState.idToken;

      const body = {
        'functionid': 'updateallmedlinecitations',
        'loadmyresources': false,
        'idToken': idToken
      };

      submitToFevirServer(globalContext, 1000, body, true, false);
/*
      axios({
        method: 'POST',
        //url: server_address + ':' + ports["master_server"] + '/',
        url: go_server_address,
        timeout: 20000,
        data: body
      }).then(async (resp) => {
        if (resp?.data?.success) {
          setTimeout(() => { }, 1000);
          for (let index in resp.data.resourceslist) {
            let resource = resp.data.resourceslist[index];
            console.log(resource)
            console.log(resource.tet.here)
            let title = resource.title;
            if (title?.includes(" Medline Base")) {
              let pmid = title.split(" Medline ")[0];
              console.log(pmid);
              await submitPMID(pmid, false);
              await sleep(400);
            }
          }
          globalContext.openAlert({ "header": "", "content": "All done!" });
        }
      });
*/
    }
  }
  
  useEffect((() => {
    if (!medlineEntryState.pmid) {
      changeMedlineEntryState("Submit", "submitButtonText");
    } else {
      if (medlineEntryState.pmid.match(/[^$\d\s,]/)) {
        changeMedlineEntryState("Submit Search Query", "submitButtonText");
      } else if (medlineEntryState.pmid.includes(",")) {
        changeMedlineEntryState("Submit PMID List", "submitButtonText");
      } else {
        changeMedlineEntryState("Submit PMID", "submitButtonText");
      }
    }
  }), [medlineEntryState.pmid]);

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Computable Publishing<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: MEDLINE-to-FEvIR Converter</h2>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <br /><br />
        {globalContext.userState.firebaseuid ?
          <>
            <TextField style={{ width: "480px" }} className="inputField" size="small" variant='outlined'
              placeholder='PMID, PMIDs List, or Search Query' //type='number'
              value={medlineEntryState.pmid} onKeyUp={handleKeyUp}
              onChange={(e) => { changeMedlineEntryState(e.target.value, "pmid"); }} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button className="formButton positive" content={medlineEntryState["submitButtonText"]} compact onClick={submitPMIDEntry} />
            {(globalContext.userState.name === "Khalid Shahin" || globalContext.userState.name === "Brian S. Alper" || globalContext.userState.name === "Joanne Dehnbostel") && <>
              <Button className="formButton positive" content="UPDATE ALL PMID Resources" compact
                onClick={updatePMIDConvertedResources} />
            </>}
            <br /><br />
            <TextField style={{ width: "220px" }} className="inputField" size="small" variant='outlined' placeholder='Project FOI (Optional)' //type='number'
              value={medlineEntryState.projectfoi} onKeyUp={handleKeyUp}
              onChange={(e) => { changeMedlineEntryState(e.target.value, "projectfoi"); }} />
            <br /><br />
            <img className={medlineEntryState.loadingSubmission ? 'visible' : 'invisible'} style={{ height: "22px" }} src="/spinner.gif"
              alt="Loading" />
            <div style={{ "textAlign": "right", "width": "100%" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>Enter a PMID to automatically create a HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Citation Resource (or enter multiple PMIDs separated by commas). HL7<sup>&#174;</sup>, and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
            </div>
            <br /><br /><br />
          </>
          :
          <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
        }
      </Segment>
    </div>
  );
};

export default MedlineConvertPage;