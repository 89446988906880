import React from 'react';
import { DataEntry } from './DataEntryFormFunctions';
import { SimpleResourceFieldViewer } from './ResourceFunctions';

const CodeSystemBuilder = ({ resourceState, setResourceState }) => {
  let fhirJson = resourceState.resourceJson;
  return <div style={{ marginTop: "12px" }}>
    <h3 id="description">Description</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={resourceState.title} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='name' fieldLabel='Name'
        startingValue={resourceState.name} setResourceState={setResourceState} />
      <DataEntry datatype='string' elementName='version' fieldLabel='Version'
        startingValue={resourceState.version} setResourceState={setResourceState} />
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={resourceState.description} setResourceState={setResourceState} />
      <DataEntry datatype='uri' elementName='valueSet' fieldLabel='Value set with entire code system'
        startingValue={resourceState.valueSet} setResourceState={setResourceState} />
    </div>
    <h3 id="properties">Properties</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='boolean' elementName='caseSensitive' fieldLabel='Case sensitive'
        storeFalse={true}
        startingValue={resourceState.caseSensitive} setResourceState={setResourceState} />
      <DataEntry datatype='code' elementName='hierarchyMeaning' fieldLabel='Hierarchy meaning'
        dataEntryStyle="dropdown" allowedValues={["grouped-by", "is-a", "part-of", "classified-with"]}
        startingValue={resourceState.hierarchyMeaning} setResourceState={setResourceState} />
      <DataEntry datatype='boolean' elementName='compositional' fieldLabel='Compositional'
        storeFalse={true}
        startingValue={resourceState.compositional} setResourceState={setResourceState} />
      <DataEntry datatype='boolean' elementName='versionNeeded' fieldLabel='Version needed'
        storeFalse={true}
        startingValue={resourceState.versionNeeded} setResourceState={setResourceState} />
      <DataEntry datatype='code' elementName='content' fieldLabel='Content'
        dataEntryStyle="dropdown" allowedValues={["not-present", "example", "fragment", "complete", "supplement"]}
        startingValue={resourceState.content} setResourceState={setResourceState} />
      <DataEntry asArray={true} datatype='CodeSystemProperty' elementName='property' fieldLabel='Property'
        startingValue={resourceState.property} setResourceState={setResourceState} />
    </div>
    <h3 id="concepts">Concepts</h3>
    <div style={{ marginLeft: "24px" }}>
      <SimpleResourceFieldViewer resource={fhirJson.concept} parentElement={""} />
    </div>
  </div>
}

export default CodeSystemBuilder;
