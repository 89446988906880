import React, { useContext } from 'react';
import { Switch, Route, Link } from "react-router-dom";
import { Button } from 'semantic-ui-react';
import './App.css';
import './Table.css';
import FevirContext from './FevirContext';
import { signInFirebase, signOutFirebase } from "./UserAccountFunctions";
//import { useToasts } from 'react-toast-notifications';

const FevirNavigationButtons = ({ }) => {
  const globalContext = useContext(FevirContext);

  const GoogleSignIn = async () => {
    signInFirebase(globalContext);
  }

  const SignOut = async () => {
    signOutFirebase(globalContext);
  }

  //const { addToast } = useToasts();
  return (
    <span>
      <Switch>
        <Route path={""} exact>
          <span style={{ paddingLeft: "20px" }}></span>
          {globalContext.userState.id ?
            <>
              {globalContext.userState.admin && <><Link to={`/admin`}><Button className="formButton adminButton" content="Admin" compact style={{ paddingLeft: "6px", paddingRight: "6px" }} onClick={() => { }} /></Link><span style={{ paddingLeft: "4px" }}></span></>}
              <Link to={`/editprofile`}><span style={{ textDecoration: 'underline' }}>{globalContext.userState.name || "Edit User Profile"}</span></Link>
              <span style={{ paddingLeft: "4px" }}></span>
              {/*  || !userState.affiliations || userState.affiliations.join('').trim() === '' */}
              {!globalContext.userState.notificationemail || globalContext.userState.notificationemail === '' ?
                <img style={{ height: '16px' }} src="/red-excl.png" 
                alt="Incomplete profile, please complete"
                title="Incomplete profile, please complete" />
                :
                <></>
              }
              <span style={{ paddingLeft: "10px" }}></span>
              <Button className="formButton logOutLogInButton" content="Log Out" compact onClick={SignOut} />
            </>
            :
            <>
              {globalContext.userState.displaythetermsofagreement ?
                <Button className="formButton logOutLogInButton" content="Log Out" compact onClick={SignOut} />
                :
                <Button className="formButton logOutLogInButton" style={{ color: "#000000" }} content="Sign In / Create Account" compact onClick={GoogleSignIn} />
              }
            </>
          }
        </Route>
      </Switch>
    </span>
  );
};

export default FevirNavigationButtons;