import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const EmptyBoxCharacter = ({}) => {
    return <span className="box emptyBox">☐&nbsp;</span>;
}

const GenericToggleButtons = ({ capitalize, uppercase, thin, medium, boxes, togglable, values, labels, fieldLabel, elementName, labelElementName, changeElementName, startingValue, setter }) => {
    let classes = "genericToggleButtonGroup buttonGroupWrap";
    if (capitalize) {
        classes = classes + " capitalize";
    } else if (uppercase) {
        classes = classes + " uppercase";
    }
    if (thin) {
        classes = classes + " thin";
    } else if (medium) {
        classes = classes + " medium";
    }

    const handleClick = (value, label) => {
        if (setter && elementName) {
            setter(prevState => {
                let newValue = value;
                if (togglable && prevState[elementName] === value) {
                    newValue = "";
                }
                let stateChanged = {
                    ...prevState,
                    [elementName]: newValue
                };
                if (labelElementName) {                    
                    stateChanged[labelElementName] = label;
                }
                if (changeElementName) {
                    stateChanged[changeElementName] = true;
                }
                return stateChanged;
            });
        }
    }

    return <ToggleButtonGroup
        className={classes}
        exclusive
        value={startingValue || ""}
        aria-label={fieldLabel}
        name={fieldLabel}
    //onClick={(e) => { handleClick(e.target.value) }}
    >
        {values.map((value, index) => {
            let label = value;
            if (labels) {
                label = labels[index];
            }
            return <ToggleButton key={index} className="genericToggleButton" color="primary"
                aria-label={label} value={value}
                onClick={() => { handleClick(value, label); }}>
                {boxes && <span>
                    {startingValue === value ? <span className="box">☑&nbsp;</span> : <EmptyBoxCharacter />}
                </span>}
                {label}
            </ToggleButton>
        })}
    </ToggleButtonGroup>
}

export default GenericToggleButtons;