import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';

import scrollTo from './ScrollTo';
//TODO - replace with importing AdaptationFunctions
import { navigateToAnotherAdaptationItem } from './AdaptationFunctions';
import { ExpandElementComponentsForComparisonButton } from './ComparisonDetailEdit';
import getStringFromFHIR from './getStringFromFHIR';
import FevirContext from './FevirContext';

const bulletPoints = ['◉', '⦾', '■', '○', '♦', '◘', '•'];

let separatorCharacter = ": ";

const NavigationCharacteristics = ({ arrayIndexNumber, topIndexNumber, parentJson, level, parentPathString, parentPath,
    parentPathIndexes, setFhirEntryState, formInputsStateRef, setResourceState,
    changeFormState, addToast, fromRightPanel, resourceState,
    fhirEntryState, resourceId, changeFhirEntryState, submitUpdatedFhirResource,
    topOfLine, globalContext, adaptationProfile }) => {

    let style = { paddingLeft: (level * 5).toString() + "px" };
    let separator = "";
    if (parentPathString) {
        separator = separatorCharacter;
    }

    let json;
    if (fhirEntryState.fhirEntryString) {
        json = JSON.parse(fhirEntryState.fhirEntryString);
    }

    const history = useHistory();
    const expandInnerComponent = (oldJson, arrayIndexNumber, newInnerItem, setState) => {
        let newJson = JSON.parse(JSON.stringify(oldJson));
        try {
            if (newJson[arrayIndexNumber].component[3].type.coding[0].code === 'ContainedElementAdaptation') {
                newJson[arrayIndexNumber].component[3].component = JSON.parse(JSON.stringify(newInnerItem));
            }
        } catch { }
        setState(newJson);
    }

    const [revisedAdaptationComponentState, setRevisedAdaptationComponentState] = useState(parentJson);
    const [arrayIndexNumberState, setArrayIndexNumberState] = useState(-1);
    const [revisedItemState, setRevisedItemState] = useState({ 'revisedItem': "", 'arrayIndexNumber': -1 });

    useEffect((() => {
        if (fromRightPanel && (topOfLine) &&
            JSON.stringify(revisedAdaptationComponentState) !== JSON.stringify(parentJson)) {
            setResourceState(JSON.parse(JSON.stringify(revisedAdaptationComponentState)));
        }
        if (fromRightPanel && !topOfLine &&
            JSON.stringify(revisedAdaptationComponentState) !== JSON.stringify(parentJson)) {
            expandInnerComponent(resourceState, arrayIndexNumber, revisedAdaptationComponentState, setResourceState);
        }
    }), [revisedAdaptationComponentState])

    useEffect((() => {
        if (fromRightPanel && revisedItemState.revisedItem) {
            expandInnerComponent(parentJson, arrayIndexNumberState, revisedItemState.revisedItem, setRevisedAdaptationComponentState);
        }
    }), [arrayIndexNumberState])

    return <>
        {Array.isArray(parentJson) &&
            parentJson.map((characteristic, characteristicIndex) => {
                let display = "";
                if (characteristic.path?.length > 0) {
                    display = characteristic.path[0];
                }
                let componentType;
                let alreadyExpanded = false;
                let summaryToBeExpanded;
                let originalContentDisplay;
                let revisedContentDisplay;
                if (display) {
                    if (characteristic.type?.coding?.[0]?.code === 'PrimitiveElement') {
                        componentType = 'PrimitiveElement';
                        originalContentDisplay = characteristic.component[0].summary;
                        if (originalContentDisplay === "No value in index Resource.") {
                            originalContentDisplay = characteristic.component[0].component[0].summary;
                        }
                        if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                            characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                            if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                characteristic.component[1].component &&
                                characteristic.component[1].component[0].classifier &&
                                characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                characteristic.component[0].component[0].summary) {
                                revisedContentDisplay = characteristic.component[0].component[0].summary;
                            } else if (characteristic.component[2].summary === "DELETEME") {
                                revisedContentDisplay = "DELETEME"
                            } else {
                                revisedContentDisplay = characteristic.component[2].summary;
                            }
                        }
                    }
                    if (characteristic.type?.coding?.[0]?.code === 'CodingElement') {
                        componentType = 'CodingElement';
                        if (characteristic.classifier[0].coding[0].code === "coding-array") {
                            if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                originalContentDisplay = JSON.parse(characteristic.component[0].component[1].summary).map((coding) => {
                                    return getStringFromFHIR.Coding(coding)
                                }).join('; ');
                            } else {
                                originalContentDisplay = JSON.parse(characteristic.component[0].component[0].summary).map((coding) => {
                                    return getStringFromFHIR.Coding(coding)
                                }).join('; ');
                            }
                            if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                    characteristic.component[1].component &&
                                    characteristic.component[1].component[0].classifier &&
                                    characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                    (characteristic.component[0].component[1].summary ||
                                        characteristic.component[0].component[1].component[0].summary)) {
                                    let itemToDisplay = characteristic.component[0].component[1].summary ||
                                        characteristic.component[0].component[1].component[0].summary;
                                    if (typeof itemToDisplay === 'string') {
                                        try {
                                            itemToDisplay = JSON.parse(itemToDisplay);
                                        } catch { }
                                    }
                                    if (Array.isArray(itemToDisplay)) {
                                        revisedContentDisplay = itemToDisplay.map((coding) => { return getStringFromFHIR.Coding(coding) }).join('; ');
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Coding(itemToDisplay);
                                    }
                                } else if (characteristic.component[2].summary === "DELETEME") {
                                    revisedContentDisplay = "DELETEME"
                                } else {
                                    revisedContentDisplay = characteristic.component[2].summary.map((coding) => { return getStringFromFHIR.Coding(coding) }).join('; ');
                                }
                            }
                        } else {
                            if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                originalContentDisplay = getStringFromFHIR.Coding(characteristic.component[0].component[1].summary);
                            } else {
                                originalContentDisplay = getStringFromFHIR.Coding(characteristic.component[0].component[0].summary);
                            }
                            if (characteristic.component[2] && characteristic.component[2].classifier && characteristic.component[2].classifier[0].coding &&
                                characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                    characteristic.component[1].component &&
                                    characteristic.component[1].component[0].classifier &&
                                    characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                    (characteristic.component[0].component[1].summary ||
                                        characteristic.component[0].component[1].component[0].summary)) {
                                    let itemToDisplay = characteristic.component[0].component[1].summary ||
                                        characteristic.component[0].component[1].component[0].summary;
                                    if (typeof itemToDisplay === 'string') {
                                        try {
                                            itemToDisplay = JSON.parse(itemToDisplay);
                                        } catch { }
                                    }
                                    if (Array.isArray(itemToDisplay)) {
                                        revisedContentDisplay = itemToDisplay.map((coding) => { return getStringFromFHIR.Coding(coding) }).join('; ');
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Coding(itemToDisplay);
                                    }
                                } else if (characteristic.component[2].summary === "DELETEME") {
                                    revisedContentDisplay = "DELETEME"
                                } else {
                                    revisedContentDisplay = getStringFromFHIR.Coding(characteristic.component[2].summary);
                                }
                            }
                        }
                    }
                    if (characteristic.type?.coding?.[0]?.code === 'ObjectElement') {
                        componentType = 'ObjectElement';
                        if (characteristic.classifier?.length > 0 && characteristic.classifier[0].coding) {
                            if (characteristic.classifier[0].coding[0].code === "manageAsCodeableConcept") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.CodeableConcept(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.CodeableConcept(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.CodeableConcept(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.CodeableConcept(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsReference") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Reference(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Reference(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Reference(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Reference(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsIdentifier") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Identifier(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Identifier(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Identifier(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Identifier(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsQuantity") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Quantity(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Quantity(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Quantity(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Quantity(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsRange") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Range(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Range(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Range(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Range(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsPeriod") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Period(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Period(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Period(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Period(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsAddress") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Address(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Address(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Address(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Address(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsAnnotation") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Annotation(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Annotation(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Annotation(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Annotation(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsContactDetail") {
                                let originalContactDetail;
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContactDetail = JSON.parse(characteristic.component[0].component[1].summary);
                                } else {
                                    originalContactDetail = JSON.parse(characteristic.component[0].component[0].summary);
                                }
                                originalContentDisplay = originalContactDetail.name || "";
                                if (originalContactDetail.telecom?.length) {
                                    originalContentDisplay += ' with telecom: ' + originalContactDetail.telecom[0]?.value;
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = itemToDisplay.name || "";
                                        if (itemToDisplay.telecom?.length) {
                                            revisedContentDisplay += ' with telecom: ' + itemToDisplay.telecom[0]?.value;
                                        }
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        let revisedContactDetail = characteristic.component[2].summary;
                                        revisedContentDisplay = revisedContactDetail.name || "";
                                        if (revisedContactDetail.telecom?.length) {
                                            revisedContentDisplay += ' with telecom: ' + revisedContactDetail.telecom[0]?.value;
                                        }
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsDosage") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Dosage(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Dosage(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Dosage(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Dosage(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsExpression") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Expression(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Expression(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Expression(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Expression(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsHumanName") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.HumanName(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.HumanName(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.HumanName(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.HumanName(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsRatio") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Ratio(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Ratio(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Ratio(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Ratio(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsTiming") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Timing(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Timing(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Timing(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Timing(characteristic.component[2].summary);
                                    }
                                }
                            } else if (characteristic.classifier[0].coding[0].code === "manageAsCoding") {
                                if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                    originalContentDisplay = getStringFromFHIR.Coding(JSON.parse(characteristic.component[0].component[1].summary));
                                } else {
                                    originalContentDisplay = getStringFromFHIR.Coding(JSON.parse(characteristic.component[0].component[0].summary));
                                }
                                if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                    characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                    if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                        characteristic.component[1].component &&
                                        characteristic.component[1].component[0].classifier &&
                                        characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                        (characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary)) {
                                        let itemToDisplay = characteristic.component[0].component[1].summary ||
                                            characteristic.component[0].component[1].component[0].summary;
                                        if (typeof itemToDisplay === 'string') {
                                            try {
                                                itemToDisplay = JSON.parse(itemToDisplay);
                                            } catch { }
                                        }
                                        revisedContentDisplay = getStringFromFHIR.Coding(itemToDisplay);
                                    } else if (characteristic.component[2].summary === "DELETEME") {
                                        revisedContentDisplay = "DELETEME"
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Coding(characteristic.component[2].summary);
                                    }
                                }
                            }
                        }
                    }
                    if (characteristic.type?.coding?.[0]?.code === 'ArrayElement') {
                        componentType = 'ArrayElement';
                        if (characteristic.classifier?.[0]?.coding?.[0]?.code === "manageAsCoding") {
                            if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                originalContentDisplay = JSON.parse(characteristic.component[0].component[1].summary).map((coding) => {
                                    return getStringFromFHIR.Coding(coding)
                                }).join('; ');
                            } else {
                                originalContentDisplay = JSON.parse(characteristic.component[0].component[0].summary).map((coding) => {
                                    return getStringFromFHIR.Coding(coding)
                                }).join('; ');
                            }
                            if (characteristic.component?.[2]?.classifier?.[0]?.coding[0]?.code === 'accepted-change') {
                                if (characteristic.component[1]?.type?.coding?.[0]?.code === 'ComparisonOfResources' &&
                                    characteristic.component[1].component?.[0]?.classifier?.[0]?.coding?.[0]?.code === 'suggested-replacement' &&
                                    (characteristic.component[0]?.component?.[1]?.summary ||
                                        characteristic.component[0]?.component?.[1]?.component?.[0]?.summary)) {
                                    let itemToDisplay = characteristic.component[0].component[1].summary ||
                                        characteristic.component[0].component[1].component[0].summary;
                                    if (typeof itemToDisplay === 'string') {
                                        try {
                                            itemToDisplay = JSON.parse(itemToDisplay);
                                        } catch { }
                                    }
                                    if (Array.isArray(itemToDisplay)) {
                                        revisedContentDisplay = itemToDisplay.map((coding) => { return getStringFromFHIR.Coding(coding) }).join('; ');
                                    } else {
                                        revisedContentDisplay = getStringFromFHIR.Coding(itemToDisplay);
                                    }
                                } else if (characteristic.component[2].summary === "DELETEME") {
                                    revisedContentDisplay = "DELETEME"
                                } else {
                                    revisedContentDisplay = characteristic.component[2].summary.map((coding) => { return getStringFromFHIR.Coding(coding) }).join('; ');
                                }
                            }
                        } else {
                            let arrayLength;
                            if (characteristic.component[0].component[0].summary === "No value in index Resource.") {
                                if (characteristic.component[0].component[1].summary) {
                                    arrayLength = JSON.parse(characteristic.component[0].component[1].summary).length;
                                } else {
                                    arrayLength = JSON.parse(characteristic.component[0].component[1].component[0].summary).length;
                                }
                            } else {
                                arrayLength = JSON.parse(characteristic.component[0].component[0].summary).length;
                            }
                            if (arrayLength === 1) {
                                originalContentDisplay = "[1 entry]";
                            } else if (arrayLength === 0) {
                                originalContentDisplay = "[Error in array count.]";
                            } else {
                                originalContentDisplay = "[" + arrayLength + " entries]";
                            }
                            if (characteristic.component[2] && characteristic.component[2].classifier?.length > 0 && characteristic.component[2].classifier[0].coding &&
                                characteristic.component[2].classifier[0].coding[0].code === 'accepted-change') {
                                if (characteristic.component[1].type.coding[0].code === 'ComparisonOfResources' &&
                                    characteristic.component[1].component &&
                                    characteristic.component[1].component[0].classifier &&
                                    characteristic.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement' &&
                                    (characteristic.component[0].component[1].summary ||
                                        characteristic.component[0].component[1].component[0].summary)) {
                                    let itemToDisplay = characteristic.component[0].component[1].summary ||
                                        characteristic.component[0].component[1].component[0].summary;
                                    if (typeof itemToDisplay === 'string') {
                                        try {
                                            itemToDisplay = JSON.parse(itemToDisplay);
                                        } catch { }
                                    }
                                    if (Array.isArray(itemToDisplay)) {
                                        try {
                                            let revisedArrayLength = itemToDisplay.length;
                                            revisedContentDisplay = "[" + revisedArrayLength + " entries]";
                                        } catch {
                                            revisedContentDisplay = "DELETEME";
                                        }
                                    } else {
                                        revisedContentDisplay = "DELETEME";
                                    }
                                } else if (characteristic.component[2].summary === "DELETEME") {
                                    revisedContentDisplay = "DELETEME";
                                } else {
                                    try {
                                        let revisedArrayLength = characteristic.component[2].summary.length;
                                        revisedContentDisplay = "[" + revisedArrayLength + " entries]";
                                    } catch {
                                        revisedContentDisplay = "DELETEME";
                                    }
                                }
                            }
                        }
                    }
                    if (characteristic.type?.coding?.[0]?.code === 'ContainedElementAdaptation') {
                        componentType = 'ContainedElementAdaptation';
                    }
                    let pathString = parentPathString + separator + display;
                    let path = [...parentPath, display];
                    let pathIndexes = [...parentPathIndexes, characteristicIndex];

                    try {
                        if (characteristic.component[2].classifier[0].coding[0].code === "accepted-change") {
                            display += ' [[[TO CHANGE]]]';
                        }
                    } catch { }

                    try {
                        if (componentType === 'ObjectElement' || componentType === 'ArrayElement') {
                            try {
                                if (characteristic.component[3].component[0].type) {
                                    alreadyExpanded = true;
                                }
                            } catch { }
                        }
                        if (!alreadyExpanded && characteristic.component[3].summary) {
                            summaryToBeExpanded = characteristic.component[3].summary;
                        }
                    } catch { }

                    let linkStyle = {};
                    let clickable = false;

                    if (JSON.stringify(pathIndexes) === JSON.stringify(fhirEntryState.conceptPathIndexes)) { // || (fhirEntryState.termChanged && fhirEntryState.conceptName === characteristic.display)                        
                        linkStyle = { cursor: "default", color: "#000000", fontWeight: "bold" };
                    } else {
                        clickable = true;
                    }
                    let comparedContainedElementComponentString;
                    if (characteristic.component && characteristic.component[0].component &&
                        characteristic.component[0].component.length > 1) {
                        if (characteristic.component[0].component[1].component && characteristic.component[0].component[1].component[0].summary) {
                            comparedContainedElementComponentString = characteristic.component[0].component[1].component[0].summary;
                        } else if (characteristic.component[0].component[1].summary) {
                            comparedContainedElementComponentString = characteristic.component[0].component[1].summary;
                        }
                        if (comparedContainedElementComponentString && comparedContainedElementComponentString.includes(' element value found in the Resource being compared')) {
                            comparedContainedElementComponentString = undefined;
                        }
                    }
                    return <span key={characteristicIndex} style={{ paddingBottom: "8px" }}
                        id={characteristic.path[0] ? "characteristicdetail-navigation-code-" + characteristic.path[0] : ''}>
                        {(componentType === 'PrimitiveElement' || componentType === 'CodingElement' || componentType === 'ObjectElement' || componentType === 'ArrayElement') &&
                            <span style={style}>
                                {//% bulletPoints.length
                                    bulletPoints[level % bulletPoints.length]}
                                &nbsp;<a style={linkStyle} onClick={() => {
                                    if (clickable) {
                                        navigateToAnotherAdaptationItem(history, fhirEntryState, setFhirEntryState, 'ArtifactAssessment',
                                            resourceId, json, characteristic, display, path, pathIndexes, pathString, formInputsStateRef,
                                            changeFhirEntryState, submitUpdatedFhirResource, globalContext, changeFormState, addToast);
                                    }
                                }}>{display}</a>
                                {(fromRightPanel &&
                                    (componentType === 'ObjectElement' || componentType === 'ArrayElement') &&
                                    summaryToBeExpanded) &&
                                    <>&nbsp;&nbsp;&nbsp;
                                        {adaptationProfile === "comparison" &&
                                            <ExpandElementComponentsForComparisonButton containedElementComponentString={summaryToBeExpanded}
                                                parentPath={characteristic.path[0] || ""}
                                                elementName={'revisedItem'}
                                                setInnerState={setRevisedItemState}
                                                postClickFunction={() => {
                                                    setArrayIndexNumberState(characteristicIndex);
                                                }}
                                                comparedContainedElementComponentString={comparedContainedElementComponentString}
                                            />}
                                    </>
                                }
                                {(fromRightPanel && originalContentDisplay) && <>&nbsp;&nbsp;&nbsp;{originalContentDisplay}</>}
                                {(fromRightPanel && revisedContentDisplay) && <>&nbsp;&nbsp;&nbsp;<b>{revisedContentDisplay}</b></>}
                                <div style={{ marginBottom: "6px" }} />
                            </span>}
                        {(alreadyExpanded && characteristic.component[3].component?.length > 0) &&
                            <NavigationCharacteristics arrayIndexNumber={characteristicIndex}
                                topIndexNumber={topOfLine ? characteristicIndex : topIndexNumber}
                                parentJson={characteristic.component[3].component} level={level + 1}
                                parentPathString={pathString} setFhirEntryState={setFhirEntryState} parentPath={path}
                                parentPathIndexes={pathIndexes} formInputsStateRef={formInputsStateRef}
                                changeFormState={changeFormState} adaptationProfile={adaptationProfile}
                                addToast={addToast} fromRightPanel={fromRightPanel} fhirEntryState={fhirEntryState}
                                resourceId={resourceId} changeFhirEntryState={changeFhirEntryState}
                                resourceState={revisedAdaptationComponentState}
                                setResourceState={setRevisedAdaptationComponentState}
                                submitUpdatedFhirResource={submitUpdatedFhirResource} globalContext={globalContext} />
                        }
                    </span>
                } else {
                    return <span key={characteristicIndex}>
                        {(characteristic.component?.length > 0) && <>
                            {false ? <></> :
                                <NavigationCharacteristics arrayIndexNumber={characteristicIndex}
                                    topIndexNumber={topOfLine ? characteristicIndex : topIndexNumber} parentJson={characteristic.component} level={level + 1}
                                    parentPathString={parentPathString} setFhirEntryState={setFhirEntryState} parentPath={parentPath}
                                    parentPathIndexes={parentPathIndexes} formInputsStateRef={formInputsStateRef}
                                    changeFormState={changeFormState} adaptationProfile={adaptationProfile}
                                    addToast={addToast} fromRightPanel={fromRightPanel} fhirEntryState={fhirEntryState}
                                    resourceId={resourceId} changeFhirEntryState={changeFhirEntryState} resourceState={revisedAdaptationComponentState}
                                    setResourceState={setRevisedAdaptationComponentState}
                                    submitUpdatedFhirResource={submitUpdatedFhirResource} globalContext={globalContext} />
                            }</>
                        }
                    </span>
                }
            })}
    </>
}

const NavigationAdaptationItemSegment = ({ resourceId, fhirEntryState, setFhirEntryState, formInputsStateRef,
    changeFhirEntryState, submitUpdatedFhirResource, changeFormState, addToast, fromRightPanel, resourceState,
    setResourceState }) => {
    let adaptationProfile;
    if (resourceState?.meta?.profile) {
        if (resourceState.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation" ||
            resourceState.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/adaptation") {
            adaptationProfile = "adaptation";
        }
        if (resourceState.meta.profile[0] === "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparison" ||
            resourceState.meta.profile[0] === "https://hl7.org/fhir/uv/ebm/StructureDefinition/comparison") {
            adaptationProfile = "comparison";
        }
    }
    const globalContext = useContext(FevirContext);
    const history = useHistory();

    let json;
    let contentJson;
    if (resourceState?.content) {
        contentJson = JSON.parse(JSON.stringify(resourceState.content));
    } else if (fhirEntryState.fhirEntryString) {
        json = JSON.parse(fhirEntryState.fhirEntryString);
        contentJson = json.content;
    }
    const [revisedAdaptationContentState, setRevisedAdaptationContentState] = useState(contentJson);

    useEffect(async () => {
        if (fhirEntryState.initialScrolled !== true && fhirEntryState.editMode !== true) {
            let scrollToId = "characteristicdetail-navigation-code-" + fhirEntryState.conceptCode;
            if (fhirEntryState.conceptPathIndexes?.length === 1 && parseInt(fhirEntryState.conceptPathIndexes[0]) === 0) {
                scrollToId = ""; //So it doesn't scroll for the top-most element
            }
            scrollTo("navigationCharacteristicDetailSegment", scrollToId, true);
            setFhirEntryState(prevState => { return { ...prevState, initialScrolled: true } })
        }
    }, [fhirEntryState]);

    useEffect((() => {
        if (fromRightPanel) {
            setResourceState(prevState => { return { ...prevState, content: revisedAdaptationContentState } });
        }
    }), [revisedAdaptationContentState])

    return <Segment id={`navigationAdaptationItemSegment`}
        className={`containerSegment maxNavigationCharacteristicSegment`}
        style={{ marginTop: "8px", padding: "2px 4px 6px 4px" }} raised>
        <h2 className={"unselectable"}
            style={{ fontSize: "22px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>
            {fromRightPanel ? <>
                {adaptationProfile === "adaptation" && <>
                    <p>To edit an element, select the item then click Adaptation Detail tab above.</p>
                </>}
                {adaptationProfile === "comparison" && <>
                    <p>To save expansions of element components, click Update on the left before leaving the Text View.</p>
                    To edit an element, select the item then click Comparison Detail tab above.</>}
            </>
                :
                <>Detail Navigation</>}
        </h2>
        <div className={`navigationCharacteristicCharacteristicDetailSection`} style={{ paddingTop: "4px" }}>
            {!fromRightPanel && <span>
                {fhirEntryState.conceptPath?.length > 0 ?
                    <span onClick={() => {
                        //TODO - remap variable names and values as needed
                        navigateToAnotherAdaptationItem(history, fhirEntryState, setFhirEntryState, 'ArtifactAssessment', resourceId,
                            json, undefined, "", [], [], [], formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource,
                            globalContext, changeFormState, addToast);
                    }}>
                        <a>TOP</a>
                    </span>
                    :
                    <b>TOP</b>
                }
                <br />
            </span>}
            <NavigationCharacteristics parentJson={contentJson} level={0} parentPathString={""} parentPath={[]}
                parentPathIndexes={[]} setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef}
                arrayIndexNumber={-1} topOfLine={true} topIndexNumber={-5} adaptationProfile={adaptationProfile}
                setResourceState={setRevisedAdaptationContentState} changeFormState={changeFormState}
                addToast={addToast} fromRightPanel={fromRightPanel} fhirEntryState={fhirEntryState}
                resourceId={resourceId} changeFhirEntryState={changeFhirEntryState} resourceState={revisedAdaptationContentState}
                submitUpdatedFhirResource={submitUpdatedFhirResource} globalContext={globalContext}
            />
        </div>
    </Segment>
}
export default NavigationAdaptationItemSegment;