import React, { useState, useEffect, memo } from 'react';
import { DataEntry } from './DataEntryFormFunctions';

const handleAdaptChange = (adaptationDictionary, path, value, setAdaptationReportState,
    setChangeAvailableToSaveState, elementName, setResourceState, rationale) => {
    let newDictionary = JSON.parse(JSON.stringify(adaptationDictionary));
    if (!newDictionary[path]) {
        newDictionary[path] = { "initialValue": null, "itemChanged": false };
    }
    newDictionary[path]["revisedValue"] = value;
    newDictionary[path]["itemChanged"] = true;
    if (rationale) {
        newDictionary[path]["rationale"] = rationale;
    }
    setAdaptationReportState(prevState => {
        return { ...prevState, adaptationDictionary: newDictionary };
    });
    setChangeAvailableToSaveState(true);
    setResourceState(prevState => { return { ...prevState, [elementName]: value } });
};

const AdaptItemDataEntry = memo(({ datatype, elementName, fieldLabel, startingValue, setResourceState,
    startCollapsed, required, allowedValues, allowedTypeValues, allowedContentTypes, allowedLanguages,
    dataEntryStyle, doNotShowFieldLabel, inTableCell, startEmptyArrayClosed, storeFalse, resourceType,
    setProfile, selectProfile, selectAnyProfile, addElementValues, addElementValuesArray, generatedNarrative,
    valueSet, referencedResourceTypes, deletable, enableCreation,
    valueSetForType, startingResourceType,
    numeratorValueSet, denominatorValueSet, typeValueSet, typeSpecificValueSets, classifierValueSet,
    systemChoices, setSystemChoices, systemChoicesOpen,
    extensionUrl, extensionValueType, classificationProfile, columnHeaders, columnHeader,
    addTableRowModalState, codeableConceptLevelValueSet, fullResourceState,
    setSourceJsonState, asArray, deletableArray,
    path, adaptationReportState,
    setAdaptationReportState, setChangeAvailableToSaveState }) => {

    const [revisedValueState, setRevisedValueState] = useState({ "revisedValue": null, "rationale": "" });
    const [startingValueState, setStartingValueState] = useState(JSON.parse(JSON.stringify(startingValue || null)));

    useEffect(() => {
        if (revisedValueState.revisedValue !== null && adaptationReportState.adaptationDictionary &&
            JSON.stringify(revisedValueState.revisedValue) !== JSON.stringify(adaptationReportState.adaptationDictionary[path]?.initialValue)) {
            let revisedValue = JSON.parse(JSON.stringify(revisedValueState.revisedValue));
            handleAdaptChange(adaptationReportState.adaptationDictionary, path,
                revisedValue,
                setAdaptationReportState, setChangeAvailableToSaveState, elementName,
                setResourceState, JSON.parse(JSON.stringify(revisedValueState.rationale)));
            setStartingValueState(revisedValue);
        }
    }, [revisedValueState]);

    return <>
        {adaptationReportState.adaptationDictionary[path]?.itemChanged && <>
            <p>Explain the rationale for the change to the {fieldLabel || elementName} value. (Optional)</p>
            <div style={{ marginLeft: "24px" }}>
            <DataEntry datatype='string' elementName='rationale' fieldLabel='Rationale for change'
                startingValue={revisedValueState.rationale} setResourceState={setRevisedValueState} />
                </div>
        </>}
        <br />
        <DataEntry asArray={asArray} datatype={datatype} elementName='revisedValue'
            fieldLabel={fieldLabel}
            startingValue={startingValueState || null}
            setResourceState={setRevisedValueState}
            referencedResourceTypes={referencedResourceTypes}
            startCollapsed={startCollapsed} startEmptyArrayClosed={startEmptyArrayClosed}
            startingResourceType={startingResourceType} setProfile={setProfile}
            selectProfile={selectProfile} selectAnyProfile={selectAnyProfile}
            required={required} allowedValues={allowedValues} allowedTypeValues={allowedTypeValues}
            allowedContentTypes={allowedContentTypes} allowedLanguages={allowedLanguages}
            dataEntryStyle={dataEntryStyle} doNotShowFieldLabel={doNotShowFieldLabel} inTableCell={inTableCell}
            storeFalse={storeFalse} resourceType={resourceType} deletableArray={deletableArray}
            addElementValues={addElementValues} addElementValuesArray={addElementValuesArray}
            generatedNarrative={generatedNarrative} valueSetForType={valueSetForType}
            valueSet={valueSet} deletable={deletable} enableCreation={enableCreation}
            numeratorValueSet={numeratorValueSet} denominatorValueSet={denominatorValueSet}
            typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets} classifierValueSet={classifierValueSet}
            systemChoices={systemChoices} setSystemChoices={setSystemChoices} systemChoicesOpen={systemChoicesOpen}
            extensionUrl={extensionUrl} extensionValueType={extensionValueType}
            classificationProfile={classificationProfile} columnHeaders={columnHeaders} columnHeader={columnHeader}
            addTableRowModalState={addTableRowModalState} codeableConceptLevelValueSet={codeableConceptLevelValueSet}
            fullResourceState={fullResourceState} setSourceJsonState={setSourceJsonState} />
        <br /> <br />
    </>

});

export { AdaptItemDataEntry, handleAdaptChange };