import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Beforeunload } from 'react-beforeunload';
import { useToasts } from 'react-toast-notifications';
import { Modal, Button, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import './App.css';
import FevirContext from './FevirContext';
import DropDownWithAdditions from './DropDownWithAdditions';
import submitToFevirServer from './SubmitToFevirServer';

const symbols = ["🅿️", "Ⓜ️", "🆔", "🔬", "📡", "📚", "🕵️‍♀️", "👩‍⚕️", "👩🏿‍⚕️", "👨🏿‍⚕️", "👨‍⚕️", "💭", "💡", "🩺", "😃", "👀", "👁‍🗨", "👁", "❀", "✪", "✮", "₱", "📈", "🏢", "🔗", "🧬️",];

const symbolOptions = symbols.map((symbol, index) => {
  let symbolOption = {
    key: symbol,
    text: symbol,
    value: symbol
  };
  return symbolOption;
});

const RecordRepoPage = ({ useTitle }) => {

  useTitle("FEvIR User's Record Repository");

  const globalContext = useContext(FevirContext);

  const [symbolOptionsState, setSymbolOptionsState] = useState({ "options": symbolOptions });

  const [userReposState, setUserReposState] = useState([]);

  const [alertModalHistoryState, setAlertModalHistoryState] = useState({ open: false });

  const [loading, setLoading] = useState(true);

  const [initialLoad, setInitialLoad] = useState(true);

  const history = useHistory();

  const CancelToHomeButton = () => {
    const history = useHistory();
    return (
      // history.goBack();
      <Button className="formButton negative" content="Cancel" onClick={() => { setLoading(true); history.push(""); }} compact />
    );
  }

  const { addToast } = useToasts();

  useEffect(async () => {
    if (globalContext.userState && !globalContext.userState.loading) {
      if (initialLoad) {
        //This reloads the userstate from the server (I hope, I need to test)
        await globalContext.continuedSessionLogin(globalContext, undefined, globalContext.setSignInStatusState);  //MAKE SURE THIS WORKS, go to one tab, and in another tab etc
        setInitialLoad(false);
        setLoading(false);
      }

      let symbol = symbols[Math.floor(Math.random() * symbols.length)];   //like "❀"
      let startingRecordRepos = [{ "name": "", "url": "", "symbol": symbol }];
      if (globalContext.userState?.recordrepo?.length > 0) {
        startingRecordRepos = [];
        for (let repo of globalContext.userState.recordrepo) {
          if (typeof (repo) === "string") {
            startingRecordRepos.push(JSON.parse(repo));
          } else {
            startingRecordRepos.push(JSON.parse(JSON.stringify(repo)));
          }
        }
      }
      setUserReposState(startingRecordRepos);
    }
  }, [globalContext.userState]);

  /*
  useEffect( async () => {
    changeSimpleState(setUserProfileState, true, "loading"); //Added back on January 11, 2022
  }, []);
  */

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        {console.log(globalContext.userState.loading)}
        {!globalContext.userState?.name ?
          <h3>Please login.</h3>
          :
          <>{loading &&
            <Dimmer className={"loadingDimmer"} active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>}<div style={{ position: "absolute", right: "3px", top: "3px" }}>
              <CancelToHomeButton />
            </div>
            <br />
            You can add your search engines and repository viewing of IDs. Example: ...
            <br />
            You can remove an entry by deleting the URL.
            <br />
            You can add your own symbol where it will appear as a clickable icon in certain contexts.
            <br />
            <br />

            {userReposState.map((recordRepo, index) => {
              return <div key={index}>
                <DropDownWithAdditions width={"94px"} currentValue={recordRepo.symbol || ''} labelText={'Symbol'} options={symbolOptionsState.options} setter={setSymbolOptionsState} stateSetter={setUserReposState} index={index} elementKeySubEntry={"symbol"} multiple={false} />
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Nickname'} size="small" variant='outlined'
                  value={recordRepo.name || ''}
                  onChange={(e) => { setUserReposState(prevstate => { prevstate[index]["name"] = e.target.value; return [...prevstate]; }) }}
                />
                &nbsp;&nbsp;&nbsp;
                <TextField style={{ width: "55%" }} className="inputField" type='text' label={'URL with %KEY (like %pmid or %doi or %s) in place of query'} size="small" variant='outlined'
                  value={recordRepo.url || ''}
                  onChange={(e) => { setUserReposState(prevstate => { prevstate[index]["url"] = e.target.value; return [...prevstate]; }) }}
                />
                <br /><br />
              </div>
            })}
            <br />
            <Button style={{ color: "#000000", width: "230px", float: "left" }} className="formButton"
              content="+ Add Record Repo"
              onClick={() => {
                setUserReposState(prevstate => {
                  let newstate = [...prevstate];
                  let symbol = symbols[Math.floor(Math.random() * symbols.length)];   //like "❀"
                  newstate.push({ "name": "", "url": "", "symbol": symbol });
                  return newstate
                })
              }}
            />

            <br /><br /><br /><br />

            <Button className="formButton" color='green' style={{ padding: "6px", paddingLeft: "60px", paddingRight: "60px" }} content="Save"
              onClick={async () => {
                setLoading(true);
                let currentRepos = [];
                for (let repo of userReposState) {
                  if (repo.url) {
                    currentRepos.push(repo);
                  }
                }

                const body = {
                  'functionid': "updateuserrepos",
                  'repos': currentRepos,
                  'idToken': ""
                };
                let response = await submitToFevirServer(globalContext, 5000, body, true, false);
                if (response?.success && response.userData) {
                  let loadedData = response.userData;
                  if (JSON.stringify(loadedData) !== JSON.stringify(globalContext.userState)) { //Added on 10-11-2021, and updated on 02-08-2022, doesn't work if the elements are out of order
                    if (loadedData["affiliations"] === undefined || loadedData["affiliations"].length === 0) {
                      loadedData["affiliations"] = [""];
                    }
                    globalContext.setUserState(loadedData); //Sets the userstate to the updated data in the database

                    addToast('Your repos have been saved.', { appearance: 'success' });
                  } else {
                    addToast('No changes have been made.', { appearance: 'success' });

                  }
                  //UPDATE USERSTATE
                  //history.push("/");
                } else {
                  addToast('There was a problem saving your repos. Please email us. support@computablepublishing.com', { appearance: 'error' });
                }
                setLoading(false);
              }}
            />

            <br />
          </>
        }
      </Segment>
    </div>
  );
};

export default RecordRepoPage;