const scrollTo = (segment, id, navigateById) => {
    if (navigateById) {
        let scrollToElement;
        let scroll = true;
        if (id != 0 && id !== "") {
            let scrollToElementById = document.getElementById(id);
            if (scrollToElementById) {
                scrollToElement = scrollToElementById.offsetTop;
            } else {
                scroll = false;
            }
        } else {
            scrollToElement = 0;
        }
        if (scroll && document.getElementById(segment)) {
            document.getElementById(segment).scrollTo({
                'behavior': 'smooth',
                'top': scrollToElement
            });
        }
    } else {

    }
};

export default scrollTo;