import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Segment, Dimmer, Loader, Table } from 'semantic-ui-react';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import axios from 'axios';
import server_config from './ServerConfiguration';
import './App.css';

const node_server_address = server_config["node_server_address"];

const RecommendationTableViewerPage = ( { useTitle, userState, getResource } ) => {

  useTitle("FEvIR Recommendations Table Viewer");

  const [recommendationResourcesState, setRecommendationResourcesState] = useState({
      "resourcesList": [], "loading": true,
      "ash_criticalcare": "", "ash_oxygensupport": "", "ash_norespiratorysupport": "",
      "ebm_criticalcare": "", "ebm_oxygensupport": "", "ebm_norespiratorysupport": "",
      "who_criticalcare": "", "who_oxygensupport": "", "who_norespiratorysupport": "",
      "australia_criticalcare": "", "australia_oxygensupport": "", "australia_norespiratorysupport": "",
      "ash_criticalcare_id": "", "ash_oxygensupport_id": "", "ash_norespiratorysupport_id": "",
      "ebm_criticalcare_id": "", "ebm_oxygensupport_id": "", "ebm_norespiratorysupport_id": "",
      "who_criticalcare_id": "", "who_oxygensupport_id": "", "who_norespiratorysupport_id": "",
      "australia_criticalcare_id": "", "australia_oxygensupport_id": "", "australia_norespiratorysupport_id": "",
      "ash_criticalcare_lastReviewDate": "", "ash_oxygensupport_lastReviewDate": "", "ash_norespiratorysupport_lastReviewDate": "",
      "ebm_criticalcare_lastReviewDate": "", "ebm_oxygensupport_lastReviewDate": "", "ebm_norespiratorysupport_lastReviewDate": "",
      "who_criticalcare_lastReviewDate": "", "who_oxygensupport_lastReviewDate": "", "who_norespiratorysupport_lastReviewDate": "",
      "australia_criticalcare_lastReviewDate": "", "australia_oxygensupport_lastReviewDate": "", "australia_norespiratorysupport_lastReviewDate": ""
    });

  const changeRecommendation = (cell, id, action, strength, lastReviewDate) => {
    let strengthText = "";
    if (strength) {
      strengthText = " Strength: " + strength;
    }
    setRecommendationResourcesState((prevState)  => {
      return {
        ...prevState,
        [cell]: action,
        [cell+"_strength"]: strengthText,
        [cell+"_id"]: id,
        [cell+"_lastReviewDate"]: lastReviewDate
      };
    });
  }

  const recommendationCellLookup = {
    "American Society of Hematology": {
      "COVID-19 - Critical Care": "ash_criticalcare",
      "COVID-19 - Hospitalized (not critical)": "ash_oxygensupport",
      "COVID-19 - Outpatient": "ash_norespiratorysupport"
    },
    "National Institutes of Health (NIH)": {
      "COVID-19 - Critical Care": "ebm_criticalcare",
      "COVID-19 - Hospitalized (not critical)": "ebm_oxygensupport",
      "COVID-19 - Outpatient": "ebm_norespiratorysupport"
    },
    "EBM Guidelines (Duodecim/Finland)": {
      "COVID-19 - Critical Care": "",
      "COVID-19 - Hospitalized (not critical)": "",
      "COVID-19 - Outpatient": ""
    },
    /*
    "Department of Veteran's Affairs": {
      "COVID-19 - Critical Care": "va_criticalcare",
      "COVID-19 - Hospitalized (not critical)": "va_oxygensupport",
      "COVID-19 - Outpatient": "va_norespiratorysupport"
    },
    */
    "World Health Organization (WHO)": {
      "COVID-19 - Critical Care": "who_criticalcare",
      "COVID-19 - Hospitalized (not critical)": "who_oxygensupport",
      "COVID-19 - Outpatient": "who_norespiratorysupport"
    },
    "Australian Guidelines": {
      "COVID-19 - Critical Care": "australia_criticalcare",
      "COVID-19 - Hospitalized (not critical)": "australia_oxygensupport",
      "COVID-19 - Outpatient": "australia_norespiratorysupport"
    }
  };

  const getRecommendationResourcesList = async () => {
    if (!userState.loading) {       //Before I had userState.loading !== undefined && in the conditional, but adding it will break it for unlogged in users
      //let idToken = userState.idToken;

      let idToken;
      if (userState?.idToken) {
        idToken = userState.idToken;
      } else {
        try {
          let user = firebase.auth().currentUser;
          await user.getIdToken(true).then( async (storedIdToken) => { idToken = storedIdToken });
        } catch (e) {

        }
      }
      //NEED TO UPDATE THIS SO IT PULLS JUST THE RESOURCES THAT WE WANT
      const body = {
        'functionid': 'getrecommendationresources',
        'idToken': idToken
      };

      axios({
        method: 'POST',
        //url: server_address + ':' + ports["master_server"] + '/',
        url: node_server_address,
        timeout: 5000,
        data: body
      }).then(async (resp) => {
        if (resp?.data?.success) {
          let recommendationResources = [];
          let recommendationResourcesUsed = [];
          for (let index in resp.data.resourceslist) {
            let resource = resp.data.resourceslist[index];
            let fhirJsonString = resource.toolstatestring;
            if (fhirJsonString) {
              let fhirJson = JSON.parse(fhirJsonString);
              if (fhirJson.recommender && fhirJson.recommender[0] && fhirJson.recommender[0].display && fhirJson.statement) {
                if (recommendationCellLookup[fhirJson.recommender[0].display] && recommendationCellLookup[fhirJson.recommender[0].display][fhirJson.statement.population]) {
                  changeRecommendation(recommendationCellLookup[fhirJson.recommender[0].display][fhirJson.statement.population], resource.id, fhirJson.statement.action, fhirJson.strength, fhirJson.lastReviewDate);
                  recommendationResourcesUsed.push(resource);
                }
              }
            }
            recommendationResources.push(resource);
  
          }
          setRecommendationResourcesState((prevState)  => {
            return {
              ...prevState,
              resourcesList: recommendationResourcesUsed,
              loading: false,
            };
          });
        }
      });
    } else {
      setRecommendationResourcesState((prevState)  => {
        return {
          ...prevState,
          loading: true,
        };
      })
    }
  };

  //const history = useHistory();

  useEffect( () => {
    getRecommendationResourcesList();
  }, [userState]);

  /*
  const fhirResourcesLinks = resourcesListState.resourcesList.map((resource, index) => {
    return <div key={index} style={{paddingTop: "0px", paddingBottom: "4px"}}>
        <Link to={`/resources/${resource.resourcetype}/${resource.id}`}>
          <span key={index} style={{fontSize: "24px", marginBottom: "280px"}}>{resource.title}</span>
        </Link>
        <br />
    </div>
  });
  */

  const FhirResourcesLinks = () => {
    
    let resourcesList = recommendationResourcesState.resourcesList;

    //Sorts by resourcetype (in alphabetical order), then by title (in alphabetical order), then by id
    resourcesList.sort((a, b) => {
      if (a.resourcetype > b.resourcetype) return 1;
      if (a.resourcetype < b.resourcetype) return -1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
    });

    return <div>
      <Table className={"RecommendationsTable"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>For COVID Patients In:</Table.HeaderCell><Table.HeaderCell>Critical Care</Table.HeaderCell><Table.HeaderCell>Hospitalized (not critical)</Table.HeaderCell><Table.HeaderCell>Outpatient</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell><b>ASH Recommends:</b></Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.ash_criticalcare_id}`}>{recommendationResourcesState.ash_criticalcare}</Link><br />{recommendationResourcesState.ash_criticalcare_strength}{recommendationResourcesState.ash_criticalcare_lastReviewDate && <><br />Last review date: {recommendationResourcesState.ash_criticalcare_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.ash_oxygensupport_id}`}>{recommendationResourcesState.ash_oxygensupport}</Link><br />{recommendationResourcesState.ash_oxygensupport_strength}{recommendationResourcesState.ash_oxygensupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.ash_oxygensupport_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.ash_norespiratorysupport_id}`}>{recommendationResourcesState.ash_norespiratorysupport}</Link><br />{recommendationResourcesState.ash_norespiratorysupport_strength}{recommendationResourcesState.ash_norespiratorysupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.ash_norespiratorysupport_lastReviewDate}</>}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>NIH Recommends:</b></Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.ebm_criticalcare_id}`}>{recommendationResourcesState.ebm_criticalcare}</Link><br />{recommendationResourcesState.ebm_criticalcare_strength}{recommendationResourcesState.ebm_criticalcare_lastReviewDate && <><br />Last review date: {recommendationResourcesState.ebm_criticalcare_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.ebm_oxygensupport_id}`}>{recommendationResourcesState.ebm_oxygensupport}</Link><br />{recommendationResourcesState.ebm_oxygensupport_strength}{recommendationResourcesState.ebm_oxygensupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.ebm_oxygensupport_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.ebm_norespiratorysupport_id}`}>{recommendationResourcesState.ebm_norespiratorysupport}</Link><br />{recommendationResourcesState.ebm_norespiratorysupport_strength}{recommendationResourcesState.ebm_norespiratorysupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.ebm_norespiratorysupport_lastReviewDate}</>}</Table.Cell>
          </Table.Row>
          {/*
          <Table.Row>
            <Table.Cell><b>VA Recommends:</b></Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.va_criticalcare_id}`}>{recommendationResourcesState.va_criticalcare}</Link><br />{recommendationResourcesState.va_criticalcare_strength}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.va_oxygensupport_id}`}>{recommendationResourcesState.va_oxygensupport}</Link><br />{recommendationResourcesState.va_oxygensupport_strength}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.va_norespiratorysupport_id}`}>{recommendationResourcesState.va_norespiratorysupport}</Link><br />{recommendationResourcesState.va_norespiratorysupport_strength}</Table.Cell>
          </Table.Row>
          */}
        <Table.Row>
            <Table.Cell><b>WHO Recommends:</b></Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.who_criticalcare_id}`}>{recommendationResourcesState.who_criticalcare}</Link><br />{recommendationResourcesState.who_criticalcare_strength}{recommendationResourcesState.who_criticalcare_lastReviewDate && <><br />Last review date: {recommendationResourcesState.who_criticalcare_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.who_oxygensupport_id}`}>{recommendationResourcesState.who_oxygensupport}</Link><br />{recommendationResourcesState.who_oxygensupport_strength}{recommendationResourcesState.who_oxygensupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.who_oxygensupport_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.who_norespiratorysupport_id}`}>{recommendationResourcesState.who_norespiratorysupport}</Link><br />{recommendationResourcesState.who_norespiratorysupport_strength}{recommendationResourcesState.who_norespiratorysupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.who_norespiratorysupport_lastReviewDate}</>}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell><b>Australian Guidelines Recommends:</b></Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.australia_criticalcare_id}`}>{recommendationResourcesState.australia_criticalcare}</Link><br />{recommendationResourcesState.australia_criticalcare_strength}{recommendationResourcesState.australia_criticalcare_lastReviewDate && <><br />Last review date: {recommendationResourcesState.australia_criticalcare_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.australia_oxygensupport_id}`}>{recommendationResourcesState.australia_oxygensupport}</Link><br />{recommendationResourcesState.australia_oxygensupport_strength}{recommendationResourcesState.australia_oxygensupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.australia_oxygensupport_lastReviewDate}</>}</Table.Cell>
            <Table.Cell><Link to={`/resources/Recommendation/${recommendationResourcesState.australia_norespiratorysupport_id}`}>{recommendationResourcesState.australia_norespiratorysupport}</Link><br />{recommendationResourcesState.australia_norespiratorysupport_strength}{recommendationResourcesState.australia_norespiratorysupport_lastReviewDate && <><br />Last review date: {recommendationResourcesState.australia_norespiratorysupport_lastReviewDate}</>}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <i>This demo was initially created through collaboration with the ACTS COVID-19 Collaborative and the COVID-19 Knowledge Accelerator.</i>
      <br /><br />
      <div className="resourceListHeader"><div style={{paddingTop: "20px"}} className="resourceListHeaderText"><span>{"Recommendation Resources used for Table"}</span></div></div>
      { recommendationResourcesState.resourcesList.map((resource, index) => {    
      return <span key={index}>
            <Link to={`/resources/${resource.resourcetype}/${resource.id}`}>
              <span style={{fontSize: "24px"}}>{resource.title}</span>
            </Link><span>{resource.status === "draft" && <>&nbsp;&nbsp;(draft)</>}</span>
            <br />
          </span>
    })
    }</div>
};

  //console.log(resourcesListState.resourcesList);
  return (
    <div>
      <Segment className={`containerSegment remainderOfPageSegment`} raised>
        <h2 style={{textAlign: "center", color: "#AC3B61"}}>Computable Publishing<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: Recommendations Table Viewer</h2>
        <h3 style={{marginTop: "6px", textAlign: "center", color: "#000000"}}>Anticoagulation for COVID-19 (Demo)</h3>
          {
          recommendationResourcesState.loading
          &&
            <Dimmer className={"loadingDimmer"} active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          }
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <br />
        <FhirResourcesLinks />
      </Segment>
    </div>
  );
};

  export default RecommendationTableViewerPage;