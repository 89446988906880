import React from 'react';
//import ReactGA from 'react-ga';
//import ReactGA from "react-ga4";
import GA4React from "ga-4-react";
import ReactDOM from 'react-dom';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword  } from "firebase/auth";
//import firebase from 'firebase/compat/app';
//import { initializeApp } from 'firebase/app';
//import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import packageJson from '../package.json';
import App from './components/App';

global.appVersion = packageJson.version;
global.appVersionDate = packageJson.versionDate;

document.addEventListener("wheel", function (e) {
  if (document.activeElement.type === "number") {
    e.preventDefault();
    document.activeElement.blur();
  }
}, { passive: false });

document.addEventListener("keydown", function (e) {
  if (document.activeElement.type === "number") {
    const key = e.key;
    if (key === "ArrowDown" || key === "ArrowUp") {
      e.preventDefault();
      document.activeElement.blur();
    }
  }
});

const firebaseConfig = {
  apiKey: "AIzaSyCDkIdL_jcbQSIyd4KTCPvIL3pqkyleqXU",
  authDomain: "computablepublishing-users.firebaseapp.com",
  databaseURL: "https://computablepublishing-users.firebaseio.com",
  projectId: "computablepublishing-users",
  storageBucket: "computablepublishing-users.appspot.com",
  messagingSenderId: "946549403314",
  appId: "1:946549403314:web:f50df1a462b7331b12e3ab"
};

//const firebaseApp = initializeApp();
//const auth = getAuth(firebaseApp);
//let firebaseGoogleProvider = new auth.GoogleAuthProvider();
firebase.initializeApp(firebaseConfig);
let firebaseGoogleProvider = new GoogleAuthProvider();
firebaseGoogleProvider.setCustomParameters({ prompt: 'select_account' });
//firebaseGoogleProvider.addScope('profile');
firebaseGoogleProvider.addScope('email');
//let user = firebase.auth().currentUser;

//let firebaseEmailAuthProvider = firebase.auth.EmailAuthProvider;
//
let firebaseEmailAuthProvider = () => {};


const firebaseCreateUserWithEmailAndPassword = (email, password, globalContext) => {
}

//ReactGA.initialize('G-HLMLETKFLR', []);
//ReactGA.pageview("https://fevir.net");

/*
ReactDOM.render(
    <App firebaseGoogleProvider={firebaseGoogleProvider} firebaseConfig={firebaseConfig} ga4react={""} />,
  document.getElementById('root')
);
*/
const ga4react = new GA4React("G-HLMLETKFLR");
ga4react.initialize().then(res => { /*console.log("Analytics Success.")*/ })
  .catch(err => console.log("Analytics Failure."))
  .finally(() => {
    ReactDOM.render(
      /* <React.StrictMode> */
      <App
        firebaseGoogleProvider={firebaseGoogleProvider}
        firebaseEmailAuthProvider={firebaseEmailAuthProvider}
        firebaseConfig={firebaseConfig}
        //createUserWithEmailAndPassword={createUserWithEmailAndPassword}
        ga4react={ga4react}
      />
      /* </React.StrictMode>*/,
      document.getElementById('root')
    );
  });