import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { provideInput, PatientDataBundleRadioButtons, getProjectsResourcesBySpecificTypesList, EligibilityCriteriaListCheckboxes } from './MatchingFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import submitToFevirServer from './SubmitToFevirServer';

const getProjectResourcesList = async (projectId, loadcitations, globalContext) => {
    let projectresources = [];
    let body = {
        'functionid': 'getprojectresourceslist',
        'idToken': "",
        'projectresourceid': projectId,
        'loadcitations': loadcitations
    };

    let projectresponse = await submitToFevirServer(globalContext, 60000, body, true, false);

    if (projectresponse) {
        projectresources = projectresponse.projectresources;
    }
    return projectresources;
}

const loadProjectResourcesList = async (setFhirEntryState, resourceType, projectId, loadcitations, globalContext) => {
    if (resourceType === "Project") {
        let projectresources = [];
        projectresources = await getProjectResourcesList(projectId, loadcitations, globalContext);
        setFhirEntryState(prevState => {
            if (projectresources) {
                try {
                    projectresources.sort((a, b) => {
                        if (a.resourcetype > b.resourcetype) return 1;
                        if (a.resourcetype < b.resourcetype) return -1;
                        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                        if (a.id > b.id) return 1;
                        if (a.id < b.id) return -1;
                    });
                } catch (e) { }
            }
            return {
                ...prevState,
                projectResources: projectresources,
                projectResourcesLoaded: true
            };
        });

    }
}

const projectTextViewChangesToJson = (fhirJson, projectState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = projectState.meta;
    fhirJson.language = projectState.language;
    fhirJson.text = projectState.text;
    fhirJson.contained = projectState.contained || "DELETEME";
    fhirJson.extension = projectState.extension || "DELETEME";
    fhirJson.modifierExtension = projectState.modifierExtension || "DELETEME";
    fhirJson.url = projectState.url || "DELETEME";
    fhirJson.identifier = projectState.identifier || "DELETEME";
    fhirJson.version = projectState.version || "DELETEME";
    fhirJson.versionAlgorithmString = projectState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = projectState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = projectState.name || "DELETEME";
    fhirJson.title = projectState.title || "DELETEME";
    fhirJson.status = projectState.status || "unknown";
    fhirJson.experimental = projectState.experimental || "DELETEME";
    if (projectState.experimental === false) {
        fhirJson.experimental = false;
    }
    fhirJson.date = projectState.date || "DELETEME";
    fhirJson.publisher = projectState.publisher || "DELETEME";
    fhirJson.contact = projectState.contact || "DELETEME";
    fhirJson.description = projectState.description || "DELETEME";
    fhirJson.useContext = projectState.useContext || "DELETEME";
    fhirJson.jurisdiction = projectState.jurisdiction || "DELETEME";
    fhirJson.purpose = projectState.purpose || "DELETEME";
    fhirJson.usage = projectState.usage || "DELETEME";
    fhirJson.copyright = projectState.copyright || "DELETEME";
    fhirJson.copyrightLabel = projectState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = projectState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = projectState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = projectState.effectivePeriod || "DELETEME";
    fhirJson.topic = projectState.topic || "DELETEME";
    fhirJson.author = projectState.author || "DELETEME";
    fhirJson.editor = projectState.editor || "DELETEME";
    fhirJson.reviewer = projectState.reviewer || "DELETEME"
    fhirJson.endorser = projectState.endorser || "DELETEME";
    fhirJson.relatedArtifact = projectState.relatedArtifact || "DELETEME";

    fhirJson.detail = projectState.detail || "DELETEME";
    fhirJson.action = projectState.action || "DELETEME";
    fhirJson.associatedLink = projectState.associatedLink || "DELETEME";
    fhirJson.softwareCode = projectState.softwareCode || "DELETEME";
    fhirJson.referenceList = projectState.referenceList || "DELETEME";
    fhirJson.showOnProjectsList = projectState.showOnProjectsList || "DELETEME";
    if (projectState.showOnProjectsList === false) {
        fhirJson.showOnProjectsList = projectState.showOnProjectsList;
    }
    fhirJson.enableRoBAT = projectState.enableRoBAT || "DELETEME";
    if (projectState.enableRoBAT === false) {
        fhirJson.enableRoBAT = projectState.enableRoBAT;
    }

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    return fhirJsonWithCiteAs;
}

const createNewClassificationJson = (projectState) => {
    let contentArray = projectState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "PlanDefinition";
    let classifiedFoi = projectState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = projectState.title || projectState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const toggleEnableRoBATinProjectJson = (projectJson, resourceId, fhirEntryState) => {
    let fhirJson = JSON.parse(JSON.stringify(projectJson));
    fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, projectJson);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    fhirJson.enableRoBAT = !(fhirJson.enableRoBAT || false);
    return fhirJson;
}

const builderUpdateReferenceListToProjectJson = (generatedReferenceList, projectJson, resourceId, fhirEntryState) => {
    let fhirJson = JSON.parse(JSON.stringify(projectJson));

    fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, projectJson);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    if (generatedReferenceList?.length > 0) {

        let referenceList = [];
        for (let x in generatedReferenceList) {
            let citation = generatedReferenceList[x];
            let referenceEntry = {};
            referenceEntry.reference = { "reference": citation.resourcetype + "/" + citation.id, "type": citation.resourcetype, "display": citation.title };
            if (citation.description) {
                referenceEntry.description = citation.description;
            }
            referenceEntry.summary = citation.summary;
            let riskofbiasratings = citation.riskofbiasratings;
            if (riskofbiasratings?.length > 0) {
                referenceEntry.robatRating = [];
                for (let ratingIndex in riskofbiasratings) {
                    let rating = riskofbiasratings[ratingIndex];
                    if (rating?.display) {
                        referenceEntry.robatRating.push(rating);
                    }
                }
            } else {
                delete referenceEntry.robatRating;
            }
            referenceList.push(referenceEntry);
        }
        referenceList.sort((a, b) => {
            let summaryA = a.summary;
            if (!summaryA && a.reference) {
                summaryA = a.reference.display;
            }
            let summaryB = b.summary;
            if (!summaryB && b.reference) {
                summaryB = b.reference.display;
            }
            if (summaryA && summaryB) {
                if (summaryA.toLowerCase() > summaryB.toLowerCase()) return 1;
                if (summaryA.toLowerCase() < summaryB.toLowerCase()) return -1;
            } else if (summaryA) {
                return 1;
            } else if (summaryB) {
                return -1;
            }
        });

        fhirJson.referenceList = referenceList;

        fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    return fhirJson
}

const builderUpdateProjectJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let projectState = formInputsStateRef.current.projectState;

    let fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = projectTextViewChangesToJson(fhirJson, projectState);
        fhirJson = jsonErrorCheck2("Project", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(projectState);

    return [fhirJson, newClassificationJson];
}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const dayOfWeekLookup = { "Sunday": 0, "sunday": 0, "Monday": 1, "monday": 1, "Tuesday": 2, "tuesday": 2, "Wednesday": 3, "wednesday": 3, "Thursday": 4, "thursday": 4, "Friday": 5, "friday": 5, "Saturday": 6, "saturday": 6 };
//This isn't perfect, there is a small window where this will give an inaccurate converted time. If someone further east than UTC+2 is looking at Monday the week before the daylight savings time switch in ET.
// The most likely scenario for it being wrong would be someone in Asia looking at Monday the week before the daylight savings time switch for Eastern US.
const getConvertedFutureTime = (day, timeHours, timeMinutes = 0, rejectIfSameTime = false) => {
    let localNextOfThatDayOfWeek = new Date();
    localNextOfThatDayOfWeek.setDate(localNextOfThatDayOfWeek.getDate() + (dayOfWeekLookup[day] + 7 - localNextOfThatDayOfWeek.getDay()) % 7);
    localNextOfThatDayOfWeek.setHours(timeHours, timeMinutes, 0, 0);
    let newYorkTime = new Date(localNextOfThatDayOfWeek).toLocaleString("en-US", { timeZone: "America/New_York" });
    localNextOfThatDayOfWeek.setMilliseconds(localNextOfThatDayOfWeek - new Date(newYorkTime));
    if (rejectIfSameTime && localNextOfThatDayOfWeek.toString() === (new Date(newYorkTime)).toString()) {
        return false;
    }
    return localNextOfThatDayOfWeek;
}

const getTimeAndAmOrPM = (singleTime) => {
    let time;
    let pm = false;
    let error = false;
    let beforeLastTwoCharacters = singleTime.substring(0, singleTime.length - 2).trim();
    if (beforeLastTwoCharacters !== "" && !isNaN(beforeLastTwoCharacters)) {
        let lastTwoCharacters = singleTime.substring(singleTime.length - 2);
        time = beforeLastTwoCharacters;
        if (lastTwoCharacters === "am") {
            //It's am
        } else if (lastTwoCharacters === "pm") {
            pm = true;
        } else {
            error = true;
            console.log("Error, couldn't find am or pm");
        }
    }
    if (time) {
        time = parseInt(time);
    }
    return [time, pm, error];
}

const processTimeTableRow = (row, longTimeZone = "", shortTimeZone = "") => {
    let error = false;
    let differentDay = "";
    let cells = row.replace("<tr>", "").replace("</tr>", "").split("<td");
    cells = cells.slice(1);
    let originalTimeCell = cells[0];
    originalTimeCell = (originalTimeCell.substring(originalTimeCell.indexOf(">") + 1, originalTimeCell.indexOf("</td"))).replaceAll("\n", " ").replaceAll("  ", " ").trim().toLowerCase();
    let dayOfWeek = originalTimeCell.split(" ")[0];
    let convertedTimeString = "";
    if (dayOfWeekLookup[dayOfWeek]) {
        let endTime;
        let startTime;
        let pm = false; //It's an AM time unless shown otherwise
        let restOfCell = originalTimeCell.substring(dayOfWeek.length + 1).replaceAll(" - ", "-").replaceAll(" -", "-").replaceAll("- ", "-").trim();
        let hyphenIndex = restOfCell.indexOf("-");
        if (hyphenIndex !== -1 && hyphenIndex !== 0) {
            let firstHalf = restOfCell.substring(0, hyphenIndex).replaceAll(".", "").trim();
            let secondHalf = restOfCell.substring(hyphenIndex + 1).replaceAll(".", "").trim();
            if (firstHalf !== "" && !isNaN(firstHalf)) {
                startTime = parseInt(firstHalf);
                //Need to get whether it's am or pm by looking at end time
                [endTime, pm, error] = getTimeAndAmOrPM(secondHalf);
                if (endTime && startTime > endTime && pm) {
                    //This may indicate that something is wrong, otherwise this is a VERY VERY long meeting
                    pm = false;	//So assuming that the first time is am
                }
            } else {
                //Possibly has the am or pm in the first half
                [startTime, pm, error] = getTimeAndAmOrPM(firstHalf);
            }
        } else {
            //assumes that this is just a start time and no end time specified
            [startTime, pm, error] = getTimeAndAmOrPM(restOfCell);
        }

        if (error === false && startTime) {
            if (pm === false && startTime === 12) {
                //Then it's midnight
                //startTime = 0;
                console.log("Warning, the start time is midnight. Defaulting to noon time, if you don't want this please uncomment code above.");
            } else if (pm && startTime !== 12) {
                startTime += 12;    //If it's pm and not noon, then add 12 to the time
            }
            let localTime = getConvertedFutureTime(dayOfWeek, startTime, 0, true);
            if (localTime) {
                convertedTimeString = localTime.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }); //It will show something like 3:00pm or 15:00 depending on the language settings

                //Checks if day of the week is different, for example 8pm ET on Monday is going to be 2am Prague Time on Tuesday
                if (localTime.getDay() !== dayOfWeekLookup[dayOfWeek]) {
                    differentDay = days[localTime.getDay()] + " ";
                }
            }
        }
    } else {
        error = true;
        console.log("Couldn't find day of week: " + dayOfWeek);
    }
    let localTimeCell = "<td>" + differentDay + convertedTimeString.toLowerCase() + " [" + shortTimeZone + "]" + "</td>";
    let newRow = "<tr>";
    for (let i = 0; i < cells.length; i++) {
        let cell = "<td" + cells[i];
        newRow += cell;
        if (i === 0) {
            newRow += localTimeCell
        }
    }
    newRow += "</tr>";
    if (error) {
        return false;
    }
    return newRow;
}

const convertTimeTable = (detail) => {
    let localTime = getConvertedFutureTime("Tuesday", 19, 0, true); //Just to check if different time zone
    if (localTime) {
        let timeTable = detail;
        let longTimeZone = localTime.toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4); //Will say something like Central European Summer Time
        let shortTimeZone = localTime.toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4); //Will say something like GMT+2 or CDT
        let timeZone = longTimeZone || shortTimeZone;
        timeZone = timeZone.replace(" Time", "");
        timeTable = timeTable.replace("<th>Day/Time (Eastern)</th>", "<th>Day/Time (Eastern US)</th><th>(" + timeZone + ")</th>");

        let tbodyStart = timeTable.indexOf("<tbody>") + 7;
        let tbodyEnd = timeTable.indexOf("</tbody>");
        let tbody = timeTable.substring(tbodyStart, tbodyEnd);

        let rows = tbody.split("<tr");
        rows = rows.slice(1);
        let changedTbody = "";
        for (let i = 0; i < rows.length; i++) {
            let row = "<tr" + rows[i];
            row = processTimeTableRow(row, longTimeZone, shortTimeZone);
            if (row) {
                changedTbody += row;
            } else {
                return false;
            }
        }
        timeTable = timeTable.substring(0, tbodyStart) + changedTbody + timeTable.substring(tbodyEnd);

        return timeTable;
    }
    return false;
}

export {
    provideInput, PatientDataBundleRadioButtons, getProjectResourcesList, getProjectsResourcesBySpecificTypesList,
    EligibilityCriteriaListCheckboxes, loadProjectResourcesList, builderUpdateReferenceListToProjectJson,
    toggleEnableRoBATinProjectJson, builderUpdateProjectJson, getConvertedFutureTime, convertTimeTable
};