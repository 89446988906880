import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const commentTextViewChangesToJson = (fhirJson, commentState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.resourceType = "ArtifactAssessment";
    fhirJson.meta = commentState.meta;
    fhirJson.language = commentState.language;
    fhirJson.text = commentState.text;
    fhirJson.contained = commentState.contained || "DELETEME";
    fhirJson.extension = commentState.extension || "DELETEME";
    fhirJson.modifierExtension = commentState.modifierExtension || "DELETEME";
    fhirJson.language = commentState.language || "DELETEME";
    fhirJson.identifier = commentState.identifier || "DELETEME";
    fhirJson.title = commentState.title || "DELETEME";
    fhirJson.date = commentState.date || "DELETEME";
    fhirJson.copyright = commentState.copyright || "DELETEME";
    fhirJson.approvalDate = commentState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = commentState.lastReviewDate || "DELETEME";
    fhirJson.artifactReference = commentState.artifactReference || "DELETEME";
    fhirJson.artifactCanonical = commentState.artifactCanonical || "DELETEME";
    fhirJson.artifactUri = commentState.artifactUri || "DELETEME";
    fhirJson.content = commentState.content || "DELETEME"

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (commentState) => {
    let contentArray = commentState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "ArtifactAssessment";
    let classifiedFoi = commentState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = commentState.title || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
  }

const builderUpdateCommentJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let commentState = formInputsStateRef.current.commentState;
    let fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    if (fhirEntryState.activeIndex === 0) {
        fhirJson = commentTextViewChangesToJson(fhirJson, commentState);
        fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }

    let newClassificationJson = createNewClassificationJson(commentState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateCommentJson };