import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';

const bundleTextViewChangesToJson = (fhirJson, bundleState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = bundleState.meta;
    fhirJson.implicitRules = bundleState.implicitRules || "DELETEME";
    fhirJson.language = bundleState.language || "DELETEME";
    fhirJson.identifier = bundleState.identifier || "DELETEME";
    fhirJson.type = bundleState.type || "DELETEME";
    fhirJson.timestamp = bundleState.timestamp || "DELETEME";
    fhirJson.total = !isNaN(bundleState.total) ? bundleState.total : "DELETEME";
    fhirJson.link = bundleState.link || "DELETEME";
    fhirJson.entry = bundleState.entry || "DELETEME";
    fhirJson.signature = bundleState.signature || "DELETEME";
    fhirJson.issues = bundleState.issues || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    return fhirJson;
}

const createNewClassificationJson = (bundleState) => {
    let contentArray = bundleState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "Bundle";
    let classifiedFoi = bundleState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = "Bundle " + bundleState.id;
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateBundleJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let bundleState = formInputsStateRef.current.bundleState;

    let fhirJson = jsonErrorCheck2("Bundle", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = bundleTextViewChangesToJson(fhirJson, bundleState);
        fhirJson = jsonErrorCheck2("Bundle", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(bundleState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateBundleJson };