import submitToFevirServer from './SubmitToFevirServer';

const addEntryToFoiList = (entry, foiList, fliList) => {
  if (Array.isArray(entry)) {
    if (entry.length > 0 && entry[0].reference?.split("/").length === 2) {
      foiList.push(entry[0].reference.split("/")[1]);
    } else if (entry.length > 0 && entry[0].identifier?.system === "https://fevir.net/FLI" && entry[0].identifier.value) {
      fliList.push(entry[0].identifier.value)
    }
  } else if (typeof entry === "object" && entry.reference?.split("/").length === 2) {
    foiList.push(entry.reference.split("/")[1]);
  } else if (typeof entry === "object" && entry.identifier?.system === "https://fevir.net/FLI" && entry.identifier.value) {
    fliList.push(entry.identifier.value)
  }
};

const getAllReferencedFois = async (fhirJson, globalContext) => {
  let foiList = [];
  let fliList = [];
  let fliToFoi = {};
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry?.resourceReference?.reference &&
        relatedArtifactEntry.resourceReference.reference.split("/").length === 2) {
        foiList.push(relatedArtifactEntry.resourceReference.reference.split("/")[1]);
      } else if (relatedArtifactEntry?.resourceReference?.identifier?.system === "https://fevir.net/FLI" && relatedArtifactEntry.resourceReference.identifier.value) {
        fliList.push(relatedArtifactEntry.resourceReference.identifier.value)
      }
    }
  }
  if (Array.isArray(fhirJson.section)) {
    for (const section of fhirJson.section) {
      if (section?.entry) {
        addEntryToFoiList(section.entry, foiList, fliList);
      }
      if (section?.focus) {
        addEntryToFoiList(section.focus, foiList, fliList);
      }
      if (section?.section) {
        for (const subsection of section.section) {
          if (subsection.entry) {
            addEntryToFoiList(subsection.entry, foiList, fliList);
          }
          if (subsection.focus) {
            addEntryToFoiList(subsection.focus, foiList, fliList);
          }
          if (Array.isArray(subsection.section)) {
            for (const subsubsection of subsection.section) {
              if (subsubsection.entry) {
                addEntryToFoiList(subsubsection.entry, foiList, fliList);
              }
            }
          }
        }
      }
    }
  }
  if (fliList.length > 0) {
    const body = {
      'functionid': 'getfoilistfromflilist',
      'fliList': fliList,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 100000, body, true, false);
    if (response?.success && response.foilist) {
      foiList = foiList.concat(response.foilist);
      fliToFoi = response.flitofoi;
    }
  }
  return [foiList, fliToFoi];
};

const getDerivedFromFois = async (fhirJson, globalContext) => {
  let howToCite;
  let foiList = [];
  let fliList = [];
  let fliToFoi = {};
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
      }
      if (relatedArtifactEntry.type === "derived-from" || relatedArtifactEntry.type === "composed-of" ||
        relatedArtifactEntry.type === "depends-on") {
        if (relatedArtifactEntry.resourceReference?.reference &&
          relatedArtifactEntry.resourceReference.reference.split("/").length === 2) {
          foiList.push(relatedArtifactEntry.resourceReference.reference.split("/")[1]);
        } else if (relatedArtifactEntry.resourceReference?.identifier?.system === "https://fevir.net/FLI" && relatedArtifactEntry.resourceReference.identifier.value) {
          fliList.push(relatedArtifactEntry.resourceReference.identifier.value)
        }
      }
    }
  }
  if (Array.isArray(fhirJson.section)) {
    for (const section of fhirJson.section) {
      if (section.entry) {
        addEntryToFoiList(section.entry, foiList, fliList);
      }
      if (section.focus) {
        addEntryToFoiList(section.focus, foiList, fliList);
      }
      if (section.section) {
        for (const subsection of section.section) {
          if (subsection.entry) {
            addEntryToFoiList(subsection.entry, foiList, fliList);
          }
          if (subsection.focus) {
            addEntryToFoiList(subsection.focus, foiList, fliList);
          }
          if (subsection.section) {
            for (const subsubsection of subsection.section) {
              if (subsubsection.entry) {
                addEntryToFoiList(subsubsection.entry, foiList, fliList);
              }
            }
          }
        }
      }
    }
  }
  if (fliList.length > 0) {
    const body = {
      'functionid': 'getfoilistfromflilist',
      'fliList': fliList,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 100000, body, true, false);
    if (response?.success && response.foilist) {
      foiList = foiList.concat(response.foilist);
      fliToFoi = response.flitofoi;
    }
  }
  return [howToCite, foiList, fliToFoi]
};

const getFoiFromReference = (referenceElement, resourceType, resourceDictionary = null) => {
  if (referenceElement) {
    let referenceEntry;
    if (Array.isArray(referenceElement) && referenceElement.length > 0) {
      referenceEntry = referenceElement[0];
    } else if (typeof (referenceElement) === "object") {
      referenceEntry = referenceElement;
    }
    if (referenceEntry?.reference?.split("/").length === 2) {
      if (!(resourceType && referenceEntry.reference.split("/")[0] !== resourceType)) {
        let foi = referenceEntry.reference.split("/")[1];
        if (foi) {
          return foi;
        }
      }
    }
    if (referenceEntry?.identifier?.system === "https://fevir.net/FLI" && referenceEntry.identifier.value) {
      let fli = referenceEntry.identifier.value;
      if (resourceDictionary?.fliToFoi) {
        let foi = resourceDictionary["fliToFoi"][fli];
        if (foi) {
          return foi;
        }
      }
      //If still no foi return, then call server to look up
    }
  }
};


const getFoisFromReference = (referenceElement, resourceType, resourceDictionary = null) => {
  if (referenceElement) {
    let foiList = [];
    if (Array.isArray(referenceElement) && referenceElement.length > 0) {
      foiList = referenceElement.map(referenceEntry => {
        if (referenceEntry?.reference?.split("/").length === 2) {
          if (!(resourceType && referenceEntry.reference.split("/")[0] !== resourceType)) {
            let foi = referenceEntry.reference.split("/")[1];
            if (foi) {
              return foi;
            }
          }
        }
        if (referenceEntry?.identifier?.system === "https://fevir.net/FLI" && referenceEntry.identifier.value) {
          let fli = referenceEntry.identifier.value;
          if (resourceDictionary?.fliToFoi) {
            let foi = resourceDictionary["fliToFoi"][fli];
            if (foi) {
              return foi;
            }
          }
          //If still no foi return, then call server to look up
        }
      })
    }
    return foiList;
  }
};

const getHowToCite = (fhirJson) => {
  let howToCite;
  if (Array.isArray(fhirJson.relatesTo)) {
    for (let relatedArtifactEntry of fhirJson.relatesTo) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation || relatedArtifactEntry.resourceReference?.display;
      }
    }
  }
  return howToCite;
};

const getJsonFromIdentifier = (resourceDictionary, referenceElement, resourceType) => {
  if (resourceDictionary) {
    let foi = getFoiFromReference(referenceElement, resourceType, resourceDictionary)
    if (foi) {
      return resourceDictionary[foi]
    }
  }
};

const getResourceDictionaryFromFoiList = async (globalContext, foiList, fliToFoi) => {
  if (Array.isArray(foiList) && foiList.length > 0) {
    const body = {
      'functionid': 'getresourcedictionaryfromfoilist',
      'foiList': foiList,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 100000, body, true, false);
    if (response?.success && response.resourceDictionary) {
      let resourceDictionary = response.resourceDictionary;
      resourceDictionary["fliToFoi"] = fliToFoi;
      return resourceDictionary;
    }
  }
  return null;
};

const getResourceDictionary = async (fhirJson, globalContext) => {
  let [foiList, fliToFoi] = await getAllReferencedFois(fhirJson, globalContext);
  let resourceDictionary = await getResourceDictionaryFromFoiList(globalContext, foiList, fliToFoi);
  return resourceDictionary;
};

export {
  getAllReferencedFois, getDerivedFromFois, getFoiFromReference, getFoisFromReference, getHowToCite,
  getJsonFromIdentifier, getResourceDictionaryFromFoiList, getResourceDictionary
}