import React, { useState, useEffect, useContext } from 'react';
//import { useHistory } from "react-router-dom";
import { Button, Segment, Dimmer, Loader } from 'semantic-ui-react';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const InvitedToResourcePage = ({ useTitle, inviteCode }) => {

  useTitle("FEvIR Resource Invite");

  //const history = useHistory();
  const globalContext = useContext(FevirContext);

  const [invitePageState, setInvitePageState] = useState({ 'loading': true, 'loaded': false });

  const acceptInvite = async () => {
    const body = {
      'functionid': 'acceptinvite',
      'invitecode': inviteCode,
      'idToken': '',
    };
    let response = await submitToFevirServer(globalContext, 8000, body, true, false);
    if (response?.success) {
      //
      setInvitePageState(prevState => { return { ...prevState, 'acceptedInvite': true }; });
    }
  }

  const loadInvite = async () => {
    const body = {
      'functionid': 'getinviteinformation',
      'invitecode': inviteCode,
      'idToken': '',
    };
    let response = await submitToFevirServer(globalContext, 8000, body, true, false);
    if (response?.success) {
      setInvitePageState({ 'loaded': true, 'loading': false, 'inviter': response.inviter, 'resourceId': response.resourceId, 'resourceTitle': response.resourceTitle, 'alreadyJoined': response.alreadyjoined });
    } else {
      setInvitePageState({ 'loading': false });
    }
  }

  useEffect(() => {
    if (globalContext.userState.firebaseuid && inviteCode) {
      loadInvite();
    } else if (!globalContext.userState.loading) {
      setInvitePageState({ 'loading': false });
    }
  }, [globalContext.userState]);

  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>

      {(invitePageState.loading || globalContext.userState.loading) &&
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      }
      {globalContext.userState.firebaseuid ?
        <>
          {invitePageState.loaded ?
            <>
              {invitePageState.acceptedInvite ?
                <><h2>You are now an editor for <a href={"/" + invitePageState.resourceId} target="_blank" rel="noopener noreferrer">{invitePageState.resourceTitle} ({invitePageState.resourceId})</a></h2></>
                :
                <>

                  <h2 style={{ textAlign: "center", color: "#AC3B61" }}>You've been invited by {invitePageState.inviter} to become an editor for resource: {invitePageState.resourceTitle} (<a href={"/" + invitePageState.resourceId} target="_blank" rel="noopener noreferrer">{invitePageState.resourceId}</a>)</h2>
                  <br /><br />
                  {invitePageState.alreadyJoined ?
                    <h2>You are already an editor for this resource.</h2>
                    :
                    <Button className="formButton positive" content="Join as an Editor for the Resource" compact onClick={() => {
                      /* CREATE SERVER SIDE FUNCTION TO BE ADDED TO PERMISSION GROUP, pass the invite code to the function*/
                      acceptInvite();
                    }} />
                  }
                </>
              }
            </>
            :
            <>{!invitePageState.loading && <h2>No invite has been found with that code. Perhaps the invite link was revoked.</h2>}</>
          }
        </>
        :
        <>{!globalContext.userState.loading && <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>}</>
      }
    </Segment>
  </div>
};

export default InvitedToResourcePage;