import React, { useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import "firebase/compat/auth";
import { DisplayFromFHIR, getCodeDisplayOrText, SimpleResourceFieldViewer } from './ResourceFunctions';
import QuestionnaireForm from './QuestionnaireForm';

const QuestionnaireDisplay = ({changeFormState, formInputsStateRef, fhirJson, fhirEntryState, userState}) => {

  useEffect( async () => {
    if (fhirEntryState.editMode) {
        //changeFormState("FEvIR®: Questionnaire Builder", "pageTitle");
    } else {
        changeFormState("FEvIR®: Questionnaire Viewer", "pageTitle");
    }

  }, [fhirEntryState]);

  return <div>
    <div style={{marginTop: "12px"}}>
      <h3 id="summary">Summary</h3>
      <div style={{marginLeft: "24px"}}>
        <p><b>Title: </b>{fhirJson.title}</p>
        {fhirJson.description && <><b>Description: </b><span style={{whiteSpace: "pre-wrap"}}><DisplayFromFHIR markdown={fhirJson.description} /></span><br /></>}
        {fhirJson.citeAsReference?.display && <p><b>Cite As: </b>{fhirJson.citeAsReference.display}
          {fhirJson.citeAsReference.reference && <> (<a href={"/resources/"+fhirJson.citeAsReference.reference} target="_blank" rel="noopener noreferrer">{fhirJson.citeAsReference.reference}</a>)</>}
        </p>}
        {fhirJson.subject?.characteristic?.length > 0 && <><p><b>Subject of Evidence Report: </b></p>
          <Table>
            {/*<Table.Header><Table.Row><Table.HeaderCell>Code</Table.HeaderCell><Table.HeaderCell>Display</Table.HeaderCell></Table.Row></Table.Header>*/}
            <Table.Body>
            {fhirJson.subject.characteristic.map((characteristic, characteristicIndex) => {
              let codeDisplay = getCodeDisplayOrText(characteristic.code);
              let valueDisplay = "";
              
              if (characteristic.valueReference?.display) {
                valueDisplay = characteristic.valueReference.display;
              } else if (characteristic.valueBoolean !== undefined) {
                valueDisplay = characteristic.valueBoolean.toString();
              } else if (characteristic.valueQuantity?.value !== undefined) {
                valueDisplay = characteristic.valueQuantity.comparator + " " + characteristic.valueQuantity.value + " " + characteristic.valueQuantity.unit;
              } else if (characteristic.valueRange && (characteristic.valueRange.low?.value || characteristic.valueRange.high?.value)) {
                if (characteristic.valueRange.low.value) {
                  valueDisplay = characteristic.valueRange.low.value + " " + characteristic.valueRange.low.unit;
                }
                if (characteristic.valueRange.high.value) {
                  if (valueDisplay) {
                    valueDisplay += " to ";
                  }
                  valueDisplay += characteristic.valueRange.high.value + " " + characteristic.valueRange.high.unit;
                }
              } else if (characteristic.valueCodeableConcept) {
                valueDisplay = getCodeDisplayOrText(characteristic.valueCodeableConcept);
              }
              return <Table.Row key={characteristicIndex}>
                  <Table.Cell>{codeDisplay}</Table.Cell>
                  <Table.Cell>{valueDisplay}</Table.Cell>
                </Table.Row>
            })}
            </Table.Body>
          </Table>
        </>}
      </div>
      <h3 id="table-view">Questionnaire</h3>
      <div style={{marginLeft: "24px"}}>
        <QuestionnaireForm formInputsStateRef={formInputsStateRef} fhirJson={fhirJson} fhirEntryState={fhirEntryState} userState={userState} dataEntryMode={false} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br/><br/>
    </div>
    {/*<SimpleResourceFieldViewer resource={JSON.parse(fhirEntryState.fhirEntryString)} parentElement={""} />*/}
  </div>
}

export default QuestionnaireDisplay;