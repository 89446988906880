import MatchCheckFunctions from './OpenLibrary/EligibilityCriteriaMatchingSoftware/MatchCheckFunctions';
import React, { useState, useEffect, useRef, useContext, memo } from 'react';
import { Button, Dimmer, Loader, Table, Modal } from 'semantic-ui-react';
import { RadioGroup, Radio, FormControlLabel, TextField } from '@mui/material';
import YesNoRadioCheckBoxSwitch from './YesNoRadioCheckBoxSwitch';
import scrollTo from './ScrollTo';
import { useToasts } from 'react-toast-notifications';
import submitToFevirServer from './SubmitToFevirServer';
import checkEligibilityMatch from './checkEligibilityMatch';
import InclusionExclusionCharacteristicTables from './InclusionExclusionCharacteristicTables';
import { loadReferencedResource, changeSimpleState, joinGroup } from './ResourceFunctions';
import {
  provideInput, PatientDataBundleRadioButtons, EligibilityCriteriaListCheckboxes,
  builderUpdateReferenceListToProjectJson, toggleEnableRoBATinProjectJson
} from './ProjectFunctions';
import DropDownWithAdditions from './DropDownWithAdditions';
import EvidenceVariableDisplayForMatchReport from './EvidenceVariableDisplayForMatchReport';
import EditUserProfileTextFields from './EditUserProfileTextFields';
import { getUserData, updateUserAccountWithUserProfile, getResource } from "./UserAccountFunctions";
import QuestionnaireForm from './QuestionnaireForm';
import CreateEvidenceReportSubjectModal from './CreateEvidenceReportSubject';
import FevirContext from './FevirContext';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { updateResourceWithModifiedRisks } from './SummaryOfFindingsAuthoringTool';

const checkIfEnrolledInResearchStudy = async (globalContext, researchStudyFoi) => {
  let returningdata = { 'success': false };
  try {
    const body = {
      'functionid': 'researchsubjectenrollmentcheck',
      'idToken': '',
      'userid': globalContext.userState.id,
      'researchstudyfoi': researchStudyFoi
    };
    let response = await submitToFevirServer(globalContext, 10000, body, true, false);
    if (response?.success) {
      returningdata = response;
    }
  } catch (e) { }

  return returningdata;
}

//--AddCitation Project Action-----------------------

const submitAddCitationToProject = async (addToast, resourceId, globalContext, projectAddCitationModalState,
  setProjectAddCitationModalState, fhirEntryState) => {
  if (projectAddCitationModalState.title) {
    const body = {
      'functionid': 'submitaddcitationtoproject',
      'idToken': "",
      'title': projectAddCitationModalState.title,
      'pmid': projectAddCitationModalState.pmid,
      'description': projectAddCitationModalState.description,
      'projectFOI': resourceId,
      'projectTitle': fhirEntryState.title,
      'username': globalContext.userState.name
    };
    try {
      let response = await submitToFevirServer(globalContext, 10000, body, true, false);
      if (response?.success) {
        addToast('Your Citation for this project has been submitted.', { appearance: 'success' });
        setProjectAddCitationModalState(prevState => { return { ...prevState, modalOpen: false, "title": "", "pmid": "", "description": "" }; });
        window.open("/resources/Citation/" + response.citationFOI, "_blank"); //opens newly created Citation resource in a new tab
      } else {
        alert("There was a problem with your submission. Perhaps the PMID you provided is non-existant");
      }
    } catch (e) { }
  } else {
    alert("A title is required.");
  }
}

const ProjectAddCitationModal = ({ addToast, globalContext, resourceId, fhirEntryState,
  projectAddCitationModalState, setProjectAddCitationModalState }) => {
  let modalContent = <div style={{
    paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setProjectAddCitationModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Add Citation to Project</h3>
    <br />
    <TextField style={{ width: "580px" }} className="inputField" type='text' label={'Citation Title'}
      size="small" variant='outlined' value={projectAddCitationModalState.title}
      onChange={(e) => {
        setProjectAddCitationModalState(prevState => { return { ...prevState, title: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "280px" }} className="inputField" type='number' label={'PMID (optional)'}
      size="small" variant='outlined' value={projectAddCitationModalState.pmid}
      onChange={(e) => {
        setProjectAddCitationModalState(prevState => { return { ...prevState, pmid: e.target.value }; });
      }} />
    <br /><br />
    <TextField style={{ width: "580px" }} multiline className="inputField" type='text'
      label={'Citation Description (optional)'} size="small" variant='outlined'
      value={projectAddCitationModalState.description}
      onChange={(e) => {
        setProjectAddCitationModalState(prevState => { return { ...prevState, description: e.target.value }; });
      }} />
    <br /><br />
    <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
      content="Submit Citation" positive
      onClick={() => {
        submitAddCitationToProject(addToast, resourceId, globalContext, projectAddCitationModalState,
          setProjectAddCitationModalState, fhirEntryState);
      }}
    />
    <br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={projectAddCitationModalState?.modalOpen}
      centered={false}
      content={modalContent}
    />
  )
}

const AddCitation = ({ globalContext, fhirEntryState, addToast, projectFOI }) => {

  const [projectAddCitationModalState, setProjectAddCitationModalState] = useState({ "modalOpen": false, "title": "", "pmid": "", "description": "" });

  return <>
    <ProjectAddCitationModal addToast={addToast} globalContext={globalContext} resourceId={projectFOI}
      fhirEntryState={fhirEntryState} projectAddCitationModalState={projectAddCitationModalState}
      setProjectAddCitationModalState={setProjectAddCitationModalState} />
    <td><Button className="formButton" style={{ color: "#000000", width: "100%" }} content={"Add Citation"}
      onClick={async () => {
        if (globalContext.userState.id) {
          if (fhirEntryState.editpermission) {
            setProjectAddCitationModalState(prevState => { return { ...prevState, modalOpen: true }; });
            // "Create a citation record and associate with this project."
            //Take the newly submitted Citation FOI, check on the server if the user has edit permission to that Project FOI, and then add entry of a pairing of the two in pairprojectandformstatetable if it doesn't already exist
          } else {
            //CHECK EDIT PERMISSION for the user, if they don't have permisison
            alert("You don't have permission to use this function for this Project.");
          }
        } else {
          alert("Please login to use this function.");
        }
      }}
      disabled={fhirEntryState.previousVersionLoaded}
    />
    </td>
    <td>Create a citation record and associate with this project.</td>
  </>
}

//--AddResearchSubjectAndConsent Project Action-------

const loadUserData = async (globalContext, setUserProfileState) => {
  try {
    await getUserData(globalContext, 0, undefined);
  } catch (e) { }
  changeSimpleState(setUserProfileState, false, "loading");
}

const createConsentAndResearchSubjectResources = async (addToast, fhirEntryState, globalContext, consentDocument, researchStudyFoi, enrollmentGroupFoi) => {
  let success = false;
  let enrolledResponse = await checkIfEnrolledInResearchStudy(globalContext, researchStudyFoi);
  if (enrolledResponse.success && enrolledResponse.notyetenrolled) {
    const body = {
      'functionid': 'submitresearchsubjectconsent',
      'idToken': '',
      'fhirEntry': '',
      'title': '',
      'userid': globalContext.userState.id,
      'consentdocument': JSON.stringify(consentDocument),
      'researchstudyfoi': researchStudyFoi,
      'enrollmentgroupfoi': enrollmentGroupFoi,
      'status': fhirEntryState.status
    };
    try {
      let response = await submitToFevirServer(globalContext, 10000, body, true, false);
      if (response?.success) {
        addToast('Your consent and enrollment have been processed.', { appearance: 'success' });
        success = true
      }
    } catch (e) { }
  }
  if (success === false) {
    addToast('Error, we could not enroll you. Please email us. support@computablepublishing.com', { appearance: 'error' });
  }
  return success;
}

const SubjectAndConsentModal = ({ groupFoi, researchStudyFoi, enrollmentGroupFoi, document, addToast, globalContext,
  fhirEntryState, userProfileState, setUserProfileState, consentDocumentPDF, referencedResourceState,
  subjectAndConsentState, setSubjectAndConsentState }) => {

  useEffect(() => {
    if (subjectAndConsentState.page === 2) {
      if (userProfileState.loading) {
        loadUserData(globalContext, setUserProfileState);
      }
    }
  }, [subjectAndConsentState]);

  let headerText = "Confirm you are eligible";
  let closeText = 'I am not eligible to participate';
  let modalHeight = "75%";
  if (subjectAndConsentState.page === 1) {
    headerText = "Consent to participate";
    closeText = "I decline to participate";
    modalHeight = "85%";
  } else if (subjectAndConsentState.page === 2) {
    headerText = "Confirm your email for notification";
    closeText = "Close";
  } else if (subjectAndConsentState.page === 3) {
    headerText = "ERROR";
    closeText = "Close";
  }
  let pdfData;
  if (document && consentDocumentPDF) {
    pdfData = consentDocumentPDF;
  }
  let modalContent = <div style={{ width: "100%", height: "100%", verticalAlign: "top" }}>
    <table className="noStyleTable" style={{ height: "100%", width: "100%" }}>
      <tbody>
        <tr><td style={{ verticalAlign: "top" }}>
          <h2 style={{ padding: "8px", margin: "0px" }}>{headerText}</h2>
        </td></tr>
        <tr style={{ height: "90%" }}><td style={{ verticalAlign: "top", backgroundColor: "#F5FAFD" }}>
          <hr className="separator" style={{ margin: "0px" }} />
          {subjectAndConsentState.page === 0 ?
            <div style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", width: "100%", height: "100%", overflow: "auto" }}>
              <span style={{ width: "100%" }}><h3 style={{ textAlign: "center" }}>{referencedResourceState["referencedGroup" + groupFoi] && referencedResourceState["referencedGroup" + groupFoi].name}</h3></span>
              <InclusionExclusionCharacteristicTables editMode={false} smallCriteriaHeader={false}
                groupInclusionCharacteristics={referencedResourceState["groupInclusionCharacteristics" + groupFoi]}
                groupExclusionCharacteristics={referencedResourceState["groupExclusionCharacteristics" + groupFoi]}
                stateSetter={undefined} changeState={undefined} groupCharacteristicKindOptions={undefined}
                setGroupCharacteristicKindOptions={undefined} />
            </div>
            :
            <>{subjectAndConsentState.page === 1 ?
              <div style={{ width: "100%", height: "100%" }}>
                {pdfData &&
                  <embed src={`data:application/pdf;base64,${pdfData}`} type="application/pdf" width="100%" height="100%" />}
              </div>
              :
              <>{subjectAndConsentState.page === 2 ?
                <div style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", width: "100%", height: "100%", overflow: "auto" }}>
                  {userProfileState.loading &&
                    <Dimmer className={"loadingDimmer"} active inverted>
                      <Loader inverted>Loading</Loader>
                    </Dimmer>
                  }
                  <EditUserProfileTextFields firebaseConfig={globalContext.firebaseConfig}
                    userState={globalContext.userState} setUserState={globalContext.setUserState}
                    userProfileState={userProfileState} setUserProfileState={setUserProfileState} />
                </div>
                :
                <div>Error, we could not enroll you. Please email us. support@computablepublishing.com</div>
              }</>
            }</>
          }
          <hr className="oppositeSeparator" style={{ margin: "0px", marginBottom: "0px" }} />
        </td></tr>
        <tr><td style={{ verticalAlign: "top" }}>
          <span style={{ paddingTop: "6px", width: "100%", display: "inline-block", textAlign: "right" }}>
            <span style={{ margin: "7px", float: "right" }} />
            {subjectAndConsentState.page === 0 ?
              <Button style={{ color: "#FFFFFF", width: "230px", float: "right" }} className="formButton" content="I am eligible to participate" positive onClick={() => { setSubjectAndConsentState(prevState => { return { ...prevState, page: 1 }; }); }} />
              :
              <>{subjectAndConsentState.page === 1 ?
                <Button style={{ color: "#FFFFFF", width: "230px", float: "right" }} className="formButton" content="I AGREE to participate" positive
                  onClick={async () => {
                    let success = await createConsentAndResearchSubjectResources(addToast, fhirEntryState, globalContext, consentDocumentPDF, researchStudyFoi, enrollmentGroupFoi);
                    if (success) {
                      setSubjectAndConsentState(prevState => { return { ...prevState, page: 2 }; });
                    } else {
                      setSubjectAndConsentState(prevState => { return { ...prevState, page: 4 }; });
                    }
                  }}
                />
                :
                <>{subjectAndConsentState.page === 2 ?
                  <Button style={{ color: "#FFFFFF", width: "230px", float: "right" }} className="formButton positive" content="Update Profile" compact
                    onClick={async () => {
                      let success = await updateUserAccountWithUserProfile(addToast, globalContext, userProfileState, setUserProfileState, undefined, undefined);
                      if (success) {
                        setSubjectAndConsentState(prevState => { return { ...prevState, modalOpen: false, page: 0 }; });
                        setUserProfileState(prevState => { return { ...prevState, loading: true }; });
                      }
                    }} />
                  :
                  <></>
                }</>
              }</>
            }
          </span>
        </td></tr>
        <tr><td style={{ verticalAlign: "top" }}>
          <span style={{ width: "100%", display: "inline-block", textAlign: "right" }}>
            <span style={{ margin: "7px", float: "right" }} />
            <Button style={{ color: "#FFFFFF", width: "230px", float: "right" }} className="formButton" content={closeText} negative onClick={() => { setSubjectAndConsentState(prevState => { return { ...prevState, modalOpen: false, page: 0 }; }); setUserProfileState(prevState => { return { ...prevState, loading: true }; }); }} />
          </span>
        </td></tr>
      </tbody>
    </table>
  </div>;
  return (
    <Modal
      style={{ width: "70%", height: modalHeight, padding: "0px", margin: "0px" }}
      open={subjectAndConsentState.modalOpen}
      centered={false}
      dimmer={(subjectAndConsentState.page !== 2 && subjectAndConsentState.page !== 4) && <Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      content={modalContent}
    />
  )
}

const AddResearchSubjectAndConsent = ({ parameters, label, description, globalContext, setSubjectAndConsentState,
  fhirEntryState, addToast, subjectAndConsentState,
  referencedResourceState, setReferencedResourceState, projectJson }) => {
  let consentDocumentPDF;
  if (projectJson?.relatedArtifact?.length > 0) {
    for (const relatedArtifact of projectJson.relatedArtifact) {
      if (relatedArtifact.label === "consent-form" && relatedArtifact.document?.contentType === "application/pdf") {
        consentDocumentPDF = relatedArtifact.document.data;
        break;
      }
    }
  }

  const startingUserProfileState = {
    loading: true,
    startLoad: true,
    name: '',
    notificationemail: '',
    additionalemail: '',
    shortbio: '',
    affiliations: [""],
    termsofuseacceptCheckbox: true,
    datapersonalagreementacceptCheckbox: true
  };

  const [userProfileState, setUserProfileState] = useState(startingUserProfileState);

  useEffect(() => {
    setUserProfileState((prevState) => {
      let prevUserState = { ...globalContext.userState };
      return {
        ...prevState,
        ...prevUserState
      };
    });
  }, [globalContext.userState]);

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {(parameters["Group-FOI"] && parameters["ResearchStudy-FOI"] && parameters["document"]) &&
        <>
          <Button style={{ color: "#000000", width: "100%" }} className="formButton" content={label}
            onClick={async () => {
              if (globalContext.userState.id) {
                //checks to see if already enrolled
                //userState.id
                //parameters["ResearchStudy-FOI"]
                let enrolledResponse = await checkIfEnrolledInResearchStudy(globalContext, parameters["ResearchStudy-FOI"]);
                if (enrolledResponse?.success) {
                  if (enrolledResponse.notyetenrolled) {
                    let loadedReferencedResource = await loadReferencedResource(getResource, globalContext.userState, setReferencedResourceState, undefined, "Group/" + parameters["Group-FOI"], "referencedGroup" + parameters["Group-FOI"], "referencedGroupString" + parameters["Group-FOI"], "groupCharacteristics" + parameters["Group-FOI"], "groupExclusionCharacteristics" + parameters["Group-FOI"], "groupInclusionCharacteristics" + parameters["Group-FOI"]);
                    if (loadedReferencedResource) {
                      setSubjectAndConsentState(prevState => { return { ...prevState, modalOpen: true, groupFoi: parameters["Group-FOI"], researchStudyFoi: parameters["ResearchStudy-FOI"], document: parameters["document"] }; });
                    }
                  } else {
                    alert("You're already enrolled in this research study.");
                  }
                } else {
                  alert("Error, could not check your enrollment status for this research study.");
                }
              } else {
                alert("Please login to use this function.");
              }
            }}
            disabled={fhirEntryState.previousVersionLoaded}
          />
          <SubjectAndConsentModal groupFoi={parameters["Group-FOI"]} researchStudyFoi={parameters["ResearchStudy-FOI"]}
            enrollmentGroupFoi={parameters["EnrollmentGroup-FOI"]} document={parameters["document"]} addToast={addToast}
            globalContext={globalContext} fhirEntryState={fhirEntryState} userProfileState={userProfileState}
            setUserProfileState={setUserProfileState} consentDocumentPDF={consentDocumentPDF}
            referencedResourceState={referencedResourceState} subjectAndConsentState={subjectAndConsentState}
            setSubjectAndConsentState={setSubjectAndConsentState} />
        </>
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      <>{description}</>
    </td>
  </>
}

//--AddUpdateReferenceList Project Action-------------

const addUpdateReferenceList = async (addToast, projectJson, fhirEntryState, history,
  setProjectState, globalContext, resourceId) => {

  const body = {
    'functionid': 'generatereferencelisttoproject',
    "projectresourceid": resourceId,
    'idToken': ""
  };
  let response = await submitToFevirServer(globalContext, 10000, body, true, false);
  if (response?.success) {
    setProjectState(prevState => { return { ...prevState, generatedReferenceList: response.referencelist }; });
    await new Promise(resolve => setTimeout(resolve, 500)); //Waits half a second
    let workingBlob = await builderUpdateReferenceListToProjectJson(response.referencelist, projectJson,
      resourceId, fhirEntryState);

    if (workingBlob) {
      let fhirEntry = JSON.stringify(workingBlob, null, 2);
      let updateBody = {
        'functionid': 'updatefhirresource',
        'idToken': '',
        'fhirEntry': fhirEntry,
        'resourcetype': "Project",
        'resourceid': resourceId,
        'title': fhirEntryState.title,
        'status': fhirEntryState.status,
        'bypasswarnings': false
      };
      let response = await submitToFevirServer(globalContext, 5000, updateBody, false, false);
      if (response) {
        if (response.success) {
          history.push("/");
          history.push(`/resources/Project/${resourceId}`);
          //REPLACE FHIR ENTRY STATE JSON WITH BLOB
          //PUT REFERENCE LIST IN PROJECT RESOURCE JSON, AND SUBMIT UPDATED RESOURCE TO SERVER
          addToast('Reference list to this Project has been updated.', { appearance: 'success' });
        } else {
          alert("Did not succeed in updating resource list. " + response.warningMessage);
        }
      }
    } else {
      alert("Did not succeed in updating resource list.");
    }
  } else {
    alert("There was a problem updating the reference list.");
  }
  try {

  } catch (e) { }

}

const AddUpdateReferenceList = ({ addToast, projectJson, fhirEntryState, history, setProjectState,
  globalContext, projectFOI }) => {
  let resourceId = projectFOI;

  return <>
    <td>
      <Button className="formButton" style={{ color: "#000000", width: "100%" }}
        content={"Add/Update Reference List"}
        onClick={async () => {
          if (fhirEntryState.editpermission) {
            addUpdateReferenceList(addToast, projectJson, fhirEntryState, history, setProjectState,
              globalContext, resourceId);
          } else {
            alert("You don't have permission to use this function for this Project.");
          }
        }}
        disabled={fhirEntryState.previousVersionLoaded}
      />
    </td>
    <td>Create or update a Reference List based on the Citation Resources associated with this project.</td>
  </>
}

//--ChangeBaselineRates Project Action------------

const getTargetBaselineRiskResourceDictionary = async (targetResources, targetComparatorOnlyEvidenceResources, globalContext, projectFOI) => {
  let getBody = {
    'functionid': 'gettargetbaselineriskresourcedictionary',
    'idToken': '',
    'targetResources': targetResources,
    'targetComparatorOnlyEvidenceResources': targetComparatorOnlyEvidenceResources,
    'userid': globalContext.userState.id, //TODO probably DELETE THIS, why is this here, this should be deleted
    'tool': 'ProjectWork' + projectFOI
  };
  let response = await submitToFevirServer(globalContext, 50000, getBody, false, false);
  return response;
}

const ModifiedRiskEntry = memo(({ elementName, fieldLabel, targetfoi, startingValue, changeBaselineRatesWorkState,
  setChangeBaselineRatesWorkState, setChangeAvailableToSaveState }) => {

  return <div>
    <TextField style={{ width: "100%", marginTop: "16px", maxWidth: "150px" }}
      className="inputField" type='number' label={fieldLabel} size="small" variant='outlined'
      value={startingValue}
      onChange={(e) => {
        handleChange(elementName, targetfoi, parseFloat(e.target.value) / 100, setChangeBaselineRatesWorkState,
          setChangeAvailableToSaveState)
      }} />
    <>%</>
  </div>
})

const ChangeBaselineRatesTable = ({ parameters, changeBaselineRatesWorkState, setChangeBaselineRatesWorkState,
  targetResources, setChangeAvailableToSaveState }) => {

  return <Table className='viewmyworktable'>
    <Table.Header>
      <Table.Row>
        {parameters.columnHeaders.map((header, headerIndex) => {
          return <Table.HeaderCell key={headerIndex} >
            <span>
              {header}
            </span>
          </Table.HeaderCell>
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {(targetResources.length > 0 && Object.keys(changeBaselineRatesWorkState).length > 0) &&
        targetResources.map((item, itemIndex) => {
          return <Table.Row key={itemIndex}>
            {parameters.columnValues.map((value, valueIndex) => {
              return <Table.Cell key={valueIndex}>
                {value === "resource.title" ?
                  <>
                    <a href={"/resources/" + item.resourcetype + "/" + item.id.toString()}
                      target="_blank" rel="noopener noreferrer" >
                      {item.titleTrimmed || item.title}
                    </a>
                  </>
                  :
                  <>
                    {value === "research-estimate" ?
                      <p>{parseFloat((changeBaselineRatesWorkState[item.id].defaultRisk * 100).toFixed(2))}%
                      </p>
                      :
                      <>{value === "self-reported" ?
                        <ModifiedRiskEntry elementName='modifiedRisk'
                          targetfoi={item.id}
                          changeBaselineRatesWorkState={changeBaselineRatesWorkState}
                          setChangeBaselineRatesWorkState={setChangeBaselineRatesWorkState}
                          fieldLabel={'Risk without treatment (as %)'}
                          startingValue={parseFloat((changeBaselineRatesWorkState[item.id]["modifiedRisk"] * 100).toFixed(2)).toString()}
                          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                        :
                        <>Unrecognized data input</>
                      }</>
                    }
                  </>
                }
              </Table.Cell>
            })}
          </Table.Row>
        })}
    </Table.Body>
  </Table>
}

const ChangeBaselineRatesModal = memo(({ parameters, globalContext, changeBaselineRatesModalState,
  setChangeBaselineRatesModalState, fhirEntryState, projectFOI }) => {

  const targetResources = fhirEntryState.projectResources
    .filter(resource => resource.resourcetype === "EvidenceVariable")
    .filter(resource => (resource.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-definition") || resource.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-definition")))
    .map(entry => {
      let titletrimmed = entry.title.replace("OutcomeDefinition: ", "");
      entry.titleTrimmed = titletrimmed;
      return entry;
    });

  let targetComparatorOnlyEvidenceResources = fhirEntryState.projectResources
    .filter(resource => resource.resourcetype === "Evidence")
    .filter(resource => (resource.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparator-only-evidence") || resource.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparator-only-evidence")))
      .map(entry => {
        let titletrimmed = entry.title.replace("ComparatorOnlyEvidence: ", "").replace("StudyOutcomeEvidence: ", "");
        entry.titleTrimmed = titletrimmed;
        return entry;
      });

  const [changeBaselineRatesWorkState, setChangeBaselineRatesWorkState] = useState({});
  const [saveChangesState, setSaveChangesState] = useState(false);
  const [changeAvailableToSaveState, setChangeAvailableToSaveState] = useState(false);
  const [dictionaryCreatedState, setDictionaryCreatedState] = useState(false);
  const [dataLoadingState, setDataLoadingState] = useState(false);

  useEffect(async () => {
    if (saveChangesState) {
      for (const key in changeBaselineRatesWorkState) {
        const item = changeBaselineRatesWorkState[key];
        if (item.itemChanged) {
          if (!item.exists) {
            let newComparatorOnlyEvidence = {
              "resourceType": "Evidence",
              "meta": {
                "profile": [
                  "http://hl7.org/fhir/uv/ebm/StructureDefinition/comparator-only-evidence"
                ]
              },
              "title": "Baseline risk of " + item.targettitleTrimmed + " for " + globalContext.userState.name,
              "status": "active",
              "author": [
                {
                  "name": globalContext.userState.name
                }
              ],
              "variableDefinition": [
                {
                  "description": globalContext.userState.name,
                  "variableRole": {
                    "coding": [
                      {
                        "system": "http://terminology.hl7.org/CodeSystem/variable-role",
                        "code": "population",
                        "display": "population"
                      }
                    ]
                  }
                },
                {
                  "description": "no intervention",
                  "variableRole": {
                    "coding": [
                      {
                        "system": "http://terminology.hl7.org/CodeSystem/variable-role",
                        "code": "exposure",
                        "display": "exposure"
                      }
                    ]
                  }
                },
                {
                  "description": item.targettitleTrimmed,
                  "variableRole": {
                    "coding": [
                      {
                        "system": "http://terminology.hl7.org/CodeSystem/variable-role",
                        "code": "measuredVariable",
                        "display": "measured variable"
                      }
                    ]
                  },
                  "observed": {
                    "reference": "EvidenceVariable/" + key,
                    "type": "EvidenceVariable",
                    "display": item.targettitle
                  }
                }
              ],
              "statistic": [
                {
                  "statisticType": {
                    "coding": [
                      {
                        "system": "http://terminology.hl7.org/3.1.0/CodeSystem-statistic-type.html",
                        "code": "C44256",
                        "display": "Proportion"
                      }
                    ]
                  },
                  "quantity": {
                    "value": item.modifiedRisk
                  }
                }
              ]
            }
            let newEvidenceBody = {
              'functionid': 'submitfhirresource',
              'idToken': "",
              'fhirEntry': JSON.stringify(newComparatorOnlyEvidence),
              'status': "active",
              'associatedProjectFoi': projectFOI,
              'aboutformstateid': key,
              'tool': 'ProjectWork' + projectFOI,
              'title': newComparatorOnlyEvidence.title
            }
            let response = await submitToFevirServer(globalContext, 120000, newEvidenceBody, false, false);
            if (response?.success) { } else {
              alert("Problem submitting ComparatorOnlyEvidence for FOI " + key);
            }
            let newDictionary = JSON.parse(JSON.stringify(changeBaselineRatesWorkState));
            newDictionary[key]["exists"] = true;
            newDictionary[key]["itemChanged"] = false;
            setChangeBaselineRatesWorkState(newDictionary);
          }
          if (item.exists) {
            let updatedResource = updateResourceWithModifiedRisks(item.resource, item.modifiedRisk);
            let updatedRiskBody = {
              'functionid': 'updatefhirresource',
              'idToken': "",
              'resourceid': item.resourceFOI,
              'resourcetype': "Evidence",
              'fhirEntry': JSON.stringify(updatedResource),
              'status': "active",
              'associatedProjectFoi': projectFOI,
              'aboutformstateid': key,
              'tool': 'ProjectWork' + projectFOI,
              'title': updatedResource.title
            }
            let response = await submitToFevirServer(globalContext, 120000, updatedRiskBody, false, false);
            if (response?.success) { } else {
              alert("Problem updating ComparatorOnlyEvidence for FOI " + key);
            }
            let newDictionary = JSON.parse(JSON.stringify(changeBaselineRatesWorkState));
            newDictionary[key]["exists"] = true;
            newDictionary[key]["itemChanged"] = false;
            setChangeBaselineRatesWorkState(newDictionary);
          }
        }
      }
      setSaveChangesState(false);
      setChangeAvailableToSaveState(false);
    } else {
      setDataLoadingState(true);
      let response = await getTargetBaselineRiskResourceDictionary(targetResources, targetComparatorOnlyEvidenceResources, globalContext, projectFOI);
      if (response?.success && response.targetBaselineRiskResourceDictionary) {
        setChangeBaselineRatesWorkState(response.targetBaselineRiskResourceDictionary);
        setDictionaryCreatedState(true);
        setDataLoadingState(false);
      }
    }
  }, [saveChangesState]);

  let modalContent = <div style={{
    paddingTop: "0px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    {/*<Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setChangeBaselineRatesModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />*/}
    <span style={{
      position: "absolute", backgroundColor: "#FFFFFF", width: "calc(90vw - 38px)", zIndex: 100,
      paddingTop: "6px", paddingBottom: "6px"
    }} >
      <Button style={{ color: changeAvailableToSaveState ? "#FFFFFF" : "#000000", width: "230px", float: "left" }}
        className="formButton" disabled={saveChangesState}
        content="Save Changes" positive={changeAvailableToSaveState}
        onClick={() => {
          setSaveChangesState(true);
        }}
      />
      &nbsp;&nbsp;&nbsp;
      <Button style={{ color: "#000000", width: "180px", float: "right" }} className="formButton"
        content="Close Window"
        onClick={() => {
          setChangeBaselineRatesModalState(prevState => { return { ...prevState, modalOpen: false }; });
        }}
      />
    </span>
    <br /><br /><br />
    {dataLoadingState ?
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      :
      <div className='tableFixHead'>
        <ChangeBaselineRatesTable parameters={parameters} targetResources={targetResources}
          changeBaselineRatesWorkState={changeBaselineRatesWorkState} setChangeBaselineRatesWorkState={setChangeBaselineRatesWorkState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      </div>}
    <br /><br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={changeBaselineRatesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      className="viewmywork"
    />
  )
})

const ChangeBaselineRates = memo(({ parameters, globalContext, description, label, previousVersionLoaded, fhirEntryState,
  projectFOI }) => {

  if (!parameters) {
    parameters = {};
  }

  const [changeBaselineRatesModalState, setChangeBaselineRatesModalState] = useState({ "modalOpen": false });

  return <>
    {changeBaselineRatesModalState.modalOpen &&
      <ChangeBaselineRatesModal parameters={parameters} globalContext={globalContext}
        fhirEntryState={fhirEntryState} projectFOI={projectFOI}
        changeBaselineRatesModalState={changeBaselineRatesModalState}
        setChangeBaselineRatesModalState={setChangeBaselineRatesModalState} />}
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={() => {
            if (globalContext.userState.id) {
              setChangeBaselineRatesModalState({ "modalOpen": true });
            } else {
              alert("Please login to use this function.");
            }
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
})

//--CheckEligibilityCriteriaMatch Project Action------

const sleep = (ms) => {
  return new Promise(r => setTimeout(r, ms));
}

const CriteriaMatchModal = ({ eligibilityCriteriaMatchModalState, setEligibilityCriteriaMatchModalState,
  changeFormState, formInputsStateRef, fhirEntryState, setFhirEntryState }) => {
  let modalContent = <div id="CriteriaMatchModalContent"
    style={{
      paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px",
      width: "100%", height: "100%", overflow: "auto"
    }}>
    <Button style={{ padding: "6px", position: "absolute", right: "14px" }}
      className="formButton negative" content="✖"
      onClick={() => {
        setEligibilityCriteriaMatchModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />
    <h3>Eligibility Criteria Match Report</h3>
    <br />
    <Table>
      <Table.Header><Table.Row>
        <Table.HeaderCell>Eligibility Criteria</Table.HeaderCell>
        <Table.HeaderCell>Match Result</Table.HeaderCell>
      </Table.Row></Table.Header>
      <Table.Body>
        {eligibilityCriteriaMatchModalState.eligibibilityMatch?.map((result, index) => {
          return <Table.Row key={index}>
            {result.success ?
              <>
                <Table.Cell>
                  <span className={"unselectable"} style={{ cursor: "pointer", color: "#2277CC" }}
                    onClick={async () => {
                      setEligibilityCriteriaMatchModalState(prevState => {
                        let prevObj = prevState["expandedResults"];
                        prevObj[index] = true;
                        return { ...prevState, "expandedResults": prevObj };
                      });
                      await sleep(200);
                      scrollTo("CriteriaMatchModalContent", "matchResult-" + index.toString(), true);
                    }}>
                    {result.title}
                  </span>
                </Table.Cell>
                <Table.Cell>{result.matchReportDisplay}</Table.Cell>
              </>
              :
              <>
                <Table.Cell>{result.description}</Table.Cell>
                <Table.Cell></Table.Cell>
              </>
            }
          </Table.Row>
        })}
      </Table.Body>
    </Table>
    <h4>Click to expand</h4>
    {eligibilityCriteriaMatchModalState.eligibibilityMatch?.map((result, index) => {
      let displayResult = eligibilityCriteriaMatchModalState["expandedResults"][index];
      let display = displayResult ? "" : "none";
      return <div key={index} id={"matchResult-" + index.toString()}>
        <h3 className={"unselectable"} style={{ cursor: "pointer" }}
          onClick={() => {
            setEligibilityCriteriaMatchModalState(prevState => {
              let prevObj = prevState["expandedResults"];
              prevObj[index] = !prevObj[index];
              return { ...prevState, "expandedResults": prevObj };
            })
          }}>
          {eligibilityCriteriaMatchModalState.expandedResults?.length >= eligibilityCriteriaMatchModalState.eligibibilityMatch.length &&
            <>
              {displayResult ?
                <>▼</>
                :
                <>►</>
              }
              &nbsp;&nbsp;
            </>
          }
          {result.title} — {result.matchReportDisplay}</h3>
        <div style={{ display: display, marginLeft: "24px", paddingLeft: "16px", paddingBottom: "16px", border: "1px solid #999999" }}>
          <EvidenceVariableDisplayForMatchReport changeFormState={changeFormState}
            formInputsStateRef={formInputsStateRef} fhirJson={result.matchResultReport}
            fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} />
        </div>
        <br />
      </div>;
    })}
    <br />
  </div>;
  return <Modal
    style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
    dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
    open={eligibilityCriteriaMatchModalState?.modalOpen}
    centered={false}
    content={modalContent}
  />
}

const CheckEligibilityCriteriaMatch = ({ parameters, label, description, functionid, globalContext,
  fhirEntryState, provideInputState, validPatientDataBundleState, eligibilityCriteriaCheckBoxesState,
  changeFormState, formInputsStateRef, setFhirEntryState }) => {

  const [eligibilityCriteriaMatchModalState, setEligibilityCriteriaMatchModalState] = useState({ modalOpen: false });

  return <>
    <CriteriaMatchModal eligibilityCriteriaMatchModalState={eligibilityCriteriaMatchModalState}
      setEligibilityCriteriaMatchModalState={setEligibilityCriteriaMatchModalState}
      changeFormState={changeFormState} formInputsStateRef={formInputsStateRef} fhirEntryState={fhirEntryState}
      setFhirEntryState={setFhirEntryState} />
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            if (functionid === "checkeligibilitycriteriamatch2") {
              if (validPatientDataBundleState == false) {
                alert("Please select a mock patient data Bundle.");
              } else {
                let input;
                if (provideInputState["patientDataBundle"]) {
                  input = provideInputState["patientDataBundle"]["input"] || provideInputState["patientDataBundle"]["radioinput"];
                }
                if (input) {
                  let patientDataBundle = JSON.parse(input);
                  let eligibiltyCriteriaEV = [];
                  for (let index in eligibilityCriteriaCheckBoxesState.resourcesList) {
                    let entry = eligibilityCriteriaCheckBoxesState.resourcesList[index];
                    if (entry.checked) {
                      eligibiltyCriteriaEV.push({ "id": entry.id, "resourceType": entry.resourcetype, "title": entry.title });
                    }
                  }
                  if (eligibiltyCriteriaEV.length == 0) {
                    alert("Please select one Eligibility Criteria.");
                  } else if (eligibiltyCriteriaEV.length > 1) {
                    alert("Please select ONLY one Eligibility Criteria.");
                  } else {
                    let matchCheckResponse = {
                      "result": "It did not work",
                      "details": [{ "result": "It did not work", "descriptor": "It did not work" }]
                    };
                    let selectedResource = await getResource(eligibiltyCriteriaEV[0].id, "EvidenceVariable", globalContext.userState.idToken);
                    if (selectedResource?.fhirResource) {
                      let evidenceVariableSelected = JSON.parse(selectedResource.fhirResource);
                      matchCheckResponse = await MatchCheckFunctions['checkEligibilityCriteriaMatch'](MatchCheckFunctions.getCriteriaArray(evidenceVariableSelected), patientDataBundle);
                    }
                    let matchCheckResult = matchCheckResponse.result || matchCheckResponse;
                    let matchCheckDetails = "Something went wrong.";
                    if (Array.isArray(matchCheckResponse.details)) {
                      matchCheckDetails = matchCheckResponse.details.map((detail) => {
                        return detail.result + ' - ' + detail.descriptor
                      }).join('\n');
                    }
                    let matchCheckResponseDisplay = matchCheckResult + '\n' + matchCheckDetails;
                    alert(matchCheckResponseDisplay);
                  }
                }
              }
            } else if (functionid === "checkeligibilitycriteriamatch") {
              if (validPatientDataBundleState == false) {
                alert("Please select a mock patient data Bundle.");
              } else {
                let input;
                if (provideInputState["patientDataBundle"]) {
                  input = provideInputState["patientDataBundle"]["input"] || provideInputState["patientDataBundle"]["radioinput"];
                }
                if (input) {
                  let patientDataBundle = JSON.parse(input);
                  let eligibiltyCriteriaEV = [];
                  for (let index in eligibilityCriteriaCheckBoxesState.resourcesList) {
                    let entry = eligibilityCriteriaCheckBoxesState.resourcesList[index];
                    if (entry.checked) {
                      eligibiltyCriteriaEV.push({ "id": entry.id, "resourceType": entry.resourcetype, "title": entry.title });
                    }
                  }
                  if (eligibiltyCriteriaEV.length == 0) {
                    alert("Please select one or more Eligibility Criteria.");
                  } else {
                    let eligibibilityMatch = "no-response";
                    eligibibilityMatch = await checkEligibilityMatch(patientDataBundle, eligibiltyCriteriaEV, globalContext);
                    if (eligibibilityMatch != "no-response") {
                      //alert("The report for checking for eligibility match is currently: " + eligibibilityMatch + ". We will update this shortly.");
                      setEligibilityCriteriaMatchModalState(prevState => { return { ...prevState, modalOpen: true, eligibiltyCriteriaEV: eligibiltyCriteriaEV, eligibibilityMatch: eligibibilityMatch, expandedResults: new Array(eligibibilityMatch.length).fill(eligibibilityMatch.length === 1 ? true : false) } });
                    }
                  }
                }
              }
            }
          }}
          disabled={fhirEntryState.previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--CreateEvidenceReportSubject Project Action--------

const CreateEvidenceReportSubject = ({ label, description, parameters, addToast, projectFOI, projecttitle,
  previousVersionLoaded }) => {

  const [evidenceReportSubjectModalState, setEvidenceReportSubjectModalState] = useState({ modalOpen: false });

  return <>
    <CreateEvidenceReportSubjectModal addToast={addToast}
      evidenceReportSubjectModalState={evidenceReportSubjectModalState}
      setEvidenceReportSubjectModalState={setEvidenceReportSubjectModalState}
      projectid={projectFOI} projecttitle={projecttitle} />
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            setEvidenceReportSubjectModalState(prevState => { return { ...prevState, modalOpen: true } });
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--GenerateEvidenceReport Project Action-------------

const GenerateEvidenceReport = ({ label, description, parameters, projectFOI, projecttitle,
  previousVersionLoaded, history }) => {

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            let projectTitleDeslashed = projecttitle.replaceAll("/", "_");
            history.push(`/generateevidencereport/${projectFOI}/${projectTitleDeslashed}`);
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--GenerateNetEffectReport Project Action-------------

const generateNetEffectReport = async (projectFOI, projecttitle, globalContext, targetResources, targetComparatorOnlyEvidenceResources, projectResources) => {
  let targetResourceRatingDictionary;
  let response1 = await getTargetResourceRatingDictionary(targetResources, globalContext, projectFOI);
  if (response1?.success && response1.targetResourceRatingDictionary) {
    targetResourceRatingDictionary = response1.targetResourceRatingDictionary;
  }
  let targetBaselineRiskResourceDictionary;
  let response2 = await getTargetBaselineRiskResourceDictionary(targetResources, targetComparatorOnlyEvidenceResources, globalContext, projectFOI);
  if (response2?.success && response2.targetBaselineRiskResourceDictionary) {
    targetBaselineRiskResourceDictionary = response2.targetBaselineRiskResourceDictionary;
  }

  const body = {
    'functionid': "generateneteffectreport",
    'projectFOI': projectFOI,
    'projecttitle': projecttitle,
    'projectResources': projectResources,
    'targetResources': targetResources,
    'targetResourceRatingDictionary': targetResourceRatingDictionary,
    'targetBaselineRiskResourceDictionary': targetBaselineRiskResourceDictionary,
    'idToken': ""
  };
  let response = await submitToFevirServer(globalContext, 80000, body, true, false);
  if (response?.success && response.compositionFoi) {
    return response.compositionFoi;
  }
}

const GenerateNetEffectReport = ({ label, description, parameters, projectFOI, projecttitle,
  previousVersionLoaded, history, globalContext, fhirEntryState }) => {

  const projectResources = fhirEntryState.projectResources || [];

  const targetResources = projectResources
    .filter(resource => resource.resourcetype === "EvidenceVariable")
    .filter(resource => (resource.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-definition") || resource.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-definition")))
    .map(entry => {
      let titletrimmed = entry.title.replace("OutcomeDefinition: ", "");
      entry.titleTrimmed = titletrimmed;
      return entry;
    });

  let targetComparatorOnlyEvidenceResources = projectResources
    .filter(resource => resource.resourcetype === "Evidence")
    .filter(resource => (resource.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/comparator-only-evidence") || resource.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/comparator-only-evidence")))
    .map(entry => {
      let titletrimmed = entry.title.replace("ComparatorOnlyEvidence: ", "").replace("StudyOutcomeEvidence: ", "");
      entry.titleTrimmed = titletrimmed;
      return entry;
    });

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            let netEffectReportFoi = await generateNetEffectReport(projectFOI, projecttitle, globalContext, targetResources, targetComparatorOnlyEvidenceResources, projectResources);
            if (netEffectReportFoi) {
              history.push('/');
              history.push('/resources/Composition/' + netEffectReportFoi);
            } else {
              alert("Problem generating net effect report.");
            }
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--GenerateSummaryOfFindings Project Action-------------

const generateSummaryOfFindings = async (projectFOI, projecttitle, globalContext, projectResources) => {
  let returningdata = { 'success': false };
  //generate new composition (SummaryOfFindings) and return formstateid
  const body = {
    'functionid': "generatesummaryoffindings",
    'projectFOI': projectFOI,
    'projecttitle': projecttitle,
    'projectResources': projectResources,
    'idToken': ""
  };
  let response = await submitToFevirServer(globalContext, 40000, body, true, false);
  if (response?.success && response.formstateid) {
    returningdata = { 'success': true, "formstateid": response.formstateid };
  }
  return returningdata;
}

const GenerateSummaryOfFindings = ({ label, description, projectFOI, projecttitle, globalContext, projectResources,
  previousVersionLoaded, history }) => {

  if (!label) {
    label = "Generate Summary of Findings";
  }
  if (!description) {
    description = "Create a Summary of Findings derived from the OutcomeDefinition, ComparativeEvidence, ComparatorOnlyEvidence, and InterventionOnlyEvidence Resources associated with the project.";
  }

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      <Button className="formButton" style={{ color: "#000000", width: "100%" }}
        content={label}
        onClick={async () => {
          let response = await generateSummaryOfFindings(projectFOI, projecttitle, globalContext, projectResources);
          if (response?.success && response.formstateid) {
            history.push('/');
            history.push(`/resources/Composition/${response.formstateid}`);
          }
        }}
        disabled={previousVersionLoaded}
      />
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--GoToUrl Project Action----------------------------

const GoToUrl = ({ label, description, parameters, previousVersionLoaded, history }) => {
  let url = "";
  if (parameters && parameters["url"]) {
    url = parameters["url"];
  }
  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b><a href={url}>{label}</a></b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={() => {
            window.open(url, "_blank").focus();
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--JoinProjectGroup Project Action-------------------

const joinProjectGroup = async (resourceId, globalContext) => {
  if (globalContext.userState.id) {
    const body = {
      'functionid': 'joinprojectgroup',
      'idToken': '',
      'aboutformstateid': resourceId
    };
    let response;
    try {
      response = await submitToFevirServer(globalContext, 100000, body, true, false);
    } catch (e) { }
    if (response === undefined || response === false || response.success === false) {
      if (response.error) {
        alert(response.error);
        return { "success": false };
      } else {
        alert("Joining the group was unsuccessful.");
        return { "success": false };
      }
    } else {
      let emailMessage = "We don't have a notification email on file for you. Please visit your user profile by clicking your name in the upper right corner and add one. Otherwise, you won't receive a notification when project updates are sent.";
      if (globalContext.userState.notificationemail) {
        emailMessage = `Your email on record is: ${globalContext.userState.notificationemail} which we will use to send you notices when project updates are sent. If you want to change your email address used for notification, please edit your user profile by clicking your name in the upper right corner.`;
      }
      alert(`Welcome to the ${response.groupname}! ${emailMessage}`);
      return { "success": true };
    }
  } else {
    alert("Please login to join the group.");
    return { "success": false };
  }
}

const JoinProjectGroup = ({ label, description, parameters, globalContext, projectFOI,
  previousVersionLoaded }) => {

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            await joinProjectGroup(projectFOI, globalContext);
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--JoinVotingPermissionGroup Project Action----------

const JoinVotingPermissionGroup = ({ label, description, parameters, globalContext,
  previousVersionLoaded }) => {

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            await joinGroup(parameters["resourceId"], globalContext);
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--LaunchQuestionnaire Project Action----------------

const checkIfFilledOutQuestionnaire = async (globalContext, researchStudyFoi, questionnaireFoi) => {
  let returningdata = { 'success': false };
  try {
    const body = {
      'functionid': 'questionnairefilledoutcheck',
      'idToken': '',
      'userid': globalContext.userState.id,
      'researchstudyfoi': researchStudyFoi,
      'questionnairefoi': questionnaireFoi
    };
    let response = await submitToFevirServer(globalContext, 10000, body, true, false);
    if (response?.success) {
      returningdata = response;
    }
  } catch (e) { }
  return returningdata;
}

const processQuestionnaireItemsAndAnswers = (questionnaireItems, answerItems, valueSetAnswers) => {
  let returningItems = [];
  if (questionnaireItems.length === answerItems.length) {
    for (let itemIndex in questionnaireItems) {
      let item = questionnaireItems[itemIndex];
      let answer = answerItems[itemIndex];
      if (item.type !== "display") {
        delete item.prefix;
        if (item.type === "coding") {
          if (valueSetAnswers[item.answerValueSet]) {
            let answerValueSet = valueSetAnswers[item.answerValueSet];
            if (answer?.userAnswer?.length > 0) {
              let answerCode = answer.userAnswer[0]["value"];
              if (answerValueSet[answerCode]) {
                let answerInfo = answerValueSet[answerCode];
                if (answer?.userAnswer?.length > 0) {
                  //TODO TO DO, NO SUPPORT FOR CHECKBOX coding (Questionnaire.item[].answerOption[].valueCoding)
                } else {
                  item.answer = [{ "valueCoding": {} }];
                  if (answerInfo["system"]) {
                    item.answer[0]["valueCoding"]["system"] = answerInfo["system"];
                  }
                  if (answerInfo["version"]) {
                    item.answer[0]["valueCoding"]["version"] = answerInfo["version"];
                  }
                  if (answerInfo["code"]) {
                    item.answer[0]["valueCoding"]["code"] = answerInfo["code"];
                  }
                  if (answerInfo["display"]) {
                    item.answer[0]["valueCoding"]["display"] = answerInfo["display"];
                  }
                }
              }
            }
          }
        } else if (item.type === "boolean") {
          let booleanAnswer;
          if (answer?.userAnswer?.length > 0) {
            if (answer.userAnswer[0]["value"] === "true" || answer.userAnswer[0]["value"] === true) {
              booleanAnswer = true;
            } else if (answer.userAnswer[0]["value"] === "false" || answer.userAnswer[0]["value"] === false) {
              booleanAnswer = false;
            }
          }
          if (booleanAnswer !== undefined) {
            item.answer = [{ "valueBoolean": booleanAnswer }];
          }
        } else if (item.type === "quantity") {
          item.answer = [{ "valueQuantity": { value: parseFloat(answer.userAnswer[0]["value"]), comparator: answer.userAnswer[0]["comparator"], unit: answer.userAnswer[0]["unit"] } }];
        } else if (item.type === "integer") {
          if (answer.answerChoices?.length > 0) {
            item.answer = [];
            for (let answerChoicesIndex in answer.answerChoices) {
              let answerChoice = answer.answerChoices[answerChoicesIndex]
              if (answerChoice.userAnswer?.length > 0 && answerChoice.userAnswer[0]["value"]) {
                item.answer.push({ "valueInteger": parseInt(answerChoice["code"]) });
              }
            }
          } else {
            if (answer.userAnswer?.length > 0 && answer.userAnswer[0]["value"] !== undefined && answer.userAnswer[0]["value"] !== "") {
              item.answer = [{ "valueInteger": parseInt(answer.userAnswer[0]["value"]) }];
            }
          }
        } else if (item.type === "decimal") {
          item.answer = [{ "valueDecimal": parseFloat(answer.userAnswer[0]["value"]) }];
          //TODO TO DO, NO SUPPORT FOR CHECKBOX Decimal because Questionnaire.item[].answerOption[].valueDecimal is not in the FHIR build, at least not yet.
        } else if (item.type === "date") {
          //item.answer = [{ "valueDate": answer.userAnswer[0]["value"] }];
          if (answer.answerChoices?.length > 0) {
            item.answer = [];
            for (let answerChoicesIndex in answer.answerChoices) {
              let answerChoice = answer.answerChoices[answerChoicesIndex]
              if (answerChoice.userAnswer?.length > 0 && answerChoice.userAnswer[0]["value"]) {
                item.answer.push({ "valueDate": parseInt(answerChoice["code"]) });
              }
            }
          } else {
            if (answer.userAnswer?.length > 0 && answer.userAnswer[0]["value"]) {
              item.answer = [{ "valueDate": answer.userAnswer[0]["value"] }];
            }
          }
        } else if (item.type === "dateTime") {
          item.answer = [{ "valueDateTime": answer.userAnswer[0]["value"] }];
        } else if (item.type === "time") {
          //item.answer = [{ "valueTime": answer.userAnswer[0]["value"] }];
          if (answer.answerChoices?.length > 0) {
            item.answer = [];
            for (let answerChoicesIndex in answer.answerChoices) {
              let answerChoice = answer.answerChoices[answerChoicesIndex]
              if (answerChoice.userAnswer?.length > 0 && answerChoice.userAnswer[0]["value"]) {
                item.answer.push({ "valueTime": parseInt(answerChoice["code"]) });
              }
            }
          } else {
            if (answer.userAnswer?.length > 0 && answer.userAnswer[0]["value"]) {
              item.answer = [{ "valueTime": answer.userAnswer[0]["value"] }];
            }
          }
        } else if (item.type === "url") {
          item.answer = [{ "valueUrl": answer.userAnswer[0]["value"] }];
        } else if (item.type === "attachment") {
          //TO DO
        } else if (item.type === "text" || item.type === "string") {
          if (answer.answerChoices?.length > 0) {
            item.answer = [];
            for (let answerChoicesIndex in answer.answerChoices) {
              let answerChoice = answer.answerChoices[answerChoicesIndex]
              if (answerChoice.userAnswer?.length > 0 && answerChoice.userAnswer[0]["value"]) {
                item.answer.push({ "valueString": answerChoice["code"] });
              }
            }
          } else {
            if (answer.userAnswer?.length > 0 && answer.userAnswer[0]["value"]) {
              item.answer = [{ "valueString": answer.userAnswer[0]["value"] }];
            }
          }
        } else if (item.type === "group" && item.item) {
          let subItems = answer.subItems;
          if (subItems === undefined) {
            subItems = [];
          }
          item.item = processQuestionnaireItemsAndAnswers(item.item, subItems, valueSetAnswers);
        }
        delete item.userAnswer;
        delete item.answerConstraint;
        delete item.answerValueSet;
        delete item.type;
        delete item.repeat;
        delete item.repeats;
        delete item.answerOption;
        returningItems.push(item);
      }
    }
  } else {
    //console.log("Questionnaire Questions and Answers are of different length.");
    alert("Questionnaire Questions and Answers are of different length.");
  }
  return returningItems;
}

const submitQuestionnaireResponse = async (addToast, globalContext, questionnaireFoi, researchStudyFoi,
  questionnaireModalState, setQuestionnaireModalState, questionnaireInputsStateRef, status) => {

  let toSubmit = false;
  if (questionnaireModalState[questionnaireFoi]["repeatable"]) {
    toSubmit = true;
  } else {
    let questionnaireFilledOutStatus = await checkIfFilledOutQuestionnaire(globalContext, researchStudyFoi, questionnaireFoi);
    toSubmit = questionnaireFilledOutStatus.success && questionnaireFilledOutStatus.notyetcompleted;
  }
  if (toSubmit) {
    let questionnaireFilledOutJson = {
      "resourceType": "QuestionnaireResponse",
      "extension": [
        {
          "url": "http://hl7.org/fhir/StructureDefinition/workflow-researchStudy",
          "valueReference": {
            "reference": "ResearchStudy/" + researchStudyFoi,
            "type": "ResearchStudy"
          }
        }
      ],
      "questionnaire": "https://fevir.net/resources/Questionnaire/" + questionnaireFoi,
      "status": status,
      "authored": "",
      "source": {},  //TO BE ADDED SERVER-SIDE
      "item": []
    };
    questionnaireFilledOutJson["item"] = processQuestionnaireItemsAndAnswers(questionnaireModalState[questionnaireFoi]["json"]["item"], questionnaireInputsStateRef["current"]["questionnaireItemsState"], questionnaireInputsStateRef["current"]["valueSetAnswersState"]);

    const body = {
      'functionid': 'submitquestionnaireresponse',
      'idToken': globalContext.userState.id, //TO DO todo this doesn't seem right, this should be a token or an empty string
      'fhirEntry': JSON.stringify(questionnaireFilledOutJson, null, 2),
      'title': '',
      'userid': globalContext.userState.id,
      'questionnairefoi': questionnaireFoi,
      'researchstudyfoi': researchStudyFoi,
      'repeatable': questionnaireModalState[questionnaireFoi]["repeatable"],
      'status': status
    };
    try {
      let response = await submitToFevirServer(globalContext, 10000, body, true, false);
      if (response?.success) {
        if (status === "completed") {
          addToast('Your questionnaire response has been submitted.', { appearance: 'success' });
          setQuestionnaireModalState(prevState => { let prevQuestionnaire = prevState[questionnaireFoi]; prevQuestionnaire["visible"] = false; return { ...prevState, [questionnaireFoi]: prevQuestionnaire }; });
        } else if (status === "in-progress") {
          addToast('Your questionnaire response has been saved, but not submitted.', { appearance: 'success' });
          //alert('Your questionnaire response has been saved, but not submitted.');
          //TO DO, don't close the modal after saving
          setQuestionnaireModalState(prevState => { let prevQuestionnaire = prevState[questionnaireFoi]; prevQuestionnaire["visible"] = false; return { ...prevState, [questionnaireFoi]: prevQuestionnaire }; });
        }
      }
    } catch (e) { }
  }
}

const LaunchQuestionnaire = ({ parameters, label, description, functionid, addToast, globalContext, fhirEntryState }) => {

  const [questionnaireModalState, setQuestionnaireModalState] = useState({});

  const QuestionnaireModal = ({ addToast, globalContext, fhirEntryState, questionnaireModalState,
    setQuestionnaireModalState, questionnaireFoi, researchStudyFoi }) => {
    const questionnaireInputsStateRef = useRef({});
    let questionnaireTitle = 'Questionnaire';
    if (questionnaireModalState && questionnaireModalState[questionnaireFoi] &&
      questionnaireModalState[questionnaireFoi].json?.title) {
      questionnaireTitle = questionnaireModalState[questionnaireFoi]["json"]["title"];
    }
    let modalContent = <div style={{
      paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
      paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
    }}>
      <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
        content="✖"
        onClick={() => {
          setQuestionnaireModalState(prevState => {
            let prevQuestionnaire = prevState[questionnaireFoi];
            prevQuestionnaire["visible"] = false;
            return { ...prevState, [questionnaireFoi]: prevQuestionnaire };
          });
        }} />
      <h3>{questionnaireTitle}</h3>
      {questionnaireModalState[questionnaireFoi] &&
        <QuestionnaireForm formInputsStateRef={questionnaireInputsStateRef}
          fhirJson={questionnaireModalState[questionnaireFoi]["json"]}
          fhirEntryState={fhirEntryState} userState={globalContext.userState} dataEntryMode={true}
          previousQuestionnaireResponseJson={questionnaireModalState[questionnaireFoi]["previousQuestionnaireResponseJson"]}
        />}
      <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
        content="Submit Survey Entry" positive
        onClick={() => {
          submitQuestionnaireResponse(addToast, globalContext, questionnaireFoi, researchStudyFoi, questionnaireModalState, setQuestionnaireModalState, questionnaireInputsStateRef, 'completed');
        }}
      />
      <Button className="formButton goldenButton" style={{ color: "#FFFFFF", width: "230px", float: "left", marginLeft: "20px" }} content="Save Work-In-Progress"
        onClick={() => {
          submitQuestionnaireResponse(addToast, globalContext, questionnaireFoi, researchStudyFoi, questionnaireModalState, setQuestionnaireModalState, questionnaireInputsStateRef, 'in-progress');
        }}
      />
      <br />
    </div>;

    return (
      <Modal
        style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
        dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
        open={(questionnaireModalState[questionnaireFoi] && questionnaireModalState[questionnaireFoi]["visible"])}
        centered={false}
        content={modalContent}
      />
    )
  }

  const hideShowQuestionnaire = async (questionnaireFoi, researchStudyFoi, previousQuestionnaiReresponseFoi,
    repeatable) => {
    if (questionnaireFoi) {
      let visibility = true;
      let questionnaireJson = {};
      let previousQuestionnaireResponseJson;
      if (questionnaireModalState[questionnaireFoi] !== undefined &&
        questionnaireModalState[questionnaireFoi]["visible"]) {
        visibility = false;
      } else {
        visibility = true;
        let questionnaireResourceEntry = await getResource(questionnaireFoi, "Questionnaire", globalContext.userState.idToken);
        questionnaireJson = JSON.parse(questionnaireResourceEntry["fhirResource"]);
      }
      if (previousQuestionnaiReresponseFoi) {
        let previousQuestionnaireResponse = await getResource(previousQuestionnaiReresponseFoi, "QuestionnaireResponse", globalContext.userState.idToken);
        try {
          previousQuestionnaireResponseJson = JSON.parse(previousQuestionnaireResponse["fhirResource"]);
        } catch (e) {
          console.log("ERROR Loading QuestionnaireResonse");
          console.log(previousQuestionnaireResponse);
        }
      }
      let repeatableStateSet = false;
      if (repeatable) {
        repeatableStateSet = true;
      }
      setQuestionnaireModalState(prevState => {
        return {
          ...prevState,
          [questionnaireFoi]: {
            visible: visibility,
            researchStudyFoi: researchStudyFoi,
            json: questionnaireJson,
            previousQuestionnaireResponseJson: previousQuestionnaireResponseJson,
            repeatable: repeatableStateSet
          },
        };
      });
    }
  }

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      <QuestionnaireModal addToast={addToast} globalContext={globalContext} fhirEntryState={fhirEntryState}
        questionnaireModalState={questionnaireModalState} setQuestionnaireModalState={setQuestionnaireModalState}
        questionnaireFoi={parameters["Questionnaire-FOI"]} researchStudyFoi={parameters["ResearchStudy-FOI"]} />
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            if (functionid === "launchquestionnaire2") {
              if (globalContext.userState.id) {
                await hideShowQuestionnaire(parameters["Questionnaire-FOI"], parameters["ResearchStudy-FOI"], null, true);
              } else {
                alert("Please login to use this function.");
              }
            } else if (functionid === "launchquestionnaire") {
              if (globalContext.userState.id) {
                let enrolledResponse = await checkIfEnrolledInResearchStudy(globalContext, parameters["ResearchStudy-FOI"]);
                if (enrolledResponse?.success) {
                  if (enrolledResponse.notyetenrolled) {
                    alert("You need to enroll in the study first.");
                  } else {
                    let questionnaireFilledOutStatus = await checkIfFilledOutQuestionnaire(globalContext, parameters["ResearchStudy-FOI"], parameters["Questionnaire-FOI"]);
                    let previousQuestionnaiReresponseFoi;
                    if (questionnaireFilledOutStatus.status != null && questionnaireFilledOutStatus.questionnaireresponsefoi) {
                      previousQuestionnaiReresponseFoi = questionnaireFilledOutStatus.questionnaireresponsefoi;
                    }

                    if (questionnaireFilledOutStatus.success) {
                      let repeatable = false;
                      if (parameters["repeatable"] === "true" || parameters["repeatable"] === true) {
                        repeatable = true;
                      }
                      if (questionnaireFilledOutStatus.status !== "draft" && repeatable) {
                        previousQuestionnaiReresponseFoi = undefined;
                      }
                      if (questionnaireFilledOutStatus.notyetcompleted || repeatable) {
                        await hideShowQuestionnaire(parameters["Questionnaire-FOI"], parameters["ResearchStudy-FOI"], previousQuestionnaiReresponseFoi, repeatable);
                      } else {
                        alert("You have already completed the baseline entry form.");
                      }
                    } else {
                      alert("Error, could not check your survey status.");
                    }
                  }
                }
              } else {
                alert("Please login to use this function.");
              }
            }
          }}
          disabled={fhirEntryState.previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
}

//--ProvideInput Project Action--------------------

const ProvideInput = ({ label, description, parameters, globalContext, projectFOI,
  previousVersionLoaded, provideInputState, setProvideInputState,
  validPatientDataBundleState, setValidPatientDataBundleState, patientDataBundleRadioButtonsState,
  setPatientDataBundleRadioButtonsState, eligibilityCriteriaCheckBoxesState,
  setEligibilityCriteriaCheckBoxesState, loadingPatientDataBundleRadioButtonsState,
  setLoadingPatientDataBundleRadioButtonsState, loadingEligibilityCriteriaCheckBoxesState,
  setLoadingEligibilityCriteriaCheckBoxesState }) => {
  let resourceId = projectFOI;
  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={async () => {
            await provideInput(parameters, provideInputState, setProvideInputState,
              setValidPatientDataBundleState, loadingPatientDataBundleRadioButtonsState,
              setLoadingPatientDataBundleRadioButtonsState, setPatientDataBundleRadioButtonsState,
              resourceId, globalContext);
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description && <>
        {description}
        <br />
        {parameters["inputForm"] === "textBox" &&
          <TextField style={{ width: "100%" }} className="inputField" type='text'
            label={parameters["variableName"]} size="small" variant='outlined'
            value={provideInputState[parameters["variableName"]]?.input || ""}
            onChange={(e) => {
              setProvideInputState(prevState => {
                return { ...prevState, [parameters["variableName"]]: { input: e.target.value, validated: false } };
              });
            }}
          />}
        {(parameters["inputForm"] === "radioButton" && parameters["variableName"] === "patientDataBundle") && <>
          <PatientDataBundleRadioButtons parameters={parameters} globalContext={globalContext}
            setProvideInputState={setProvideInputState}
            setValidPatientDataBundleState={setValidPatientDataBundleState}
            patientDataBundleRadioButtonsState={patientDataBundleRadioButtonsState}
            setPatientDataBundleRadioButtonsState={setPatientDataBundleRadioButtonsState}
            loadingPatientDataBundleRadioButtonsState={loadingPatientDataBundleRadioButtonsState}
            setLoadingPatientDataBundleRadioButtonsState={setLoadingPatientDataBundleRadioButtonsState} />
        </>}
        {parameters["inputForm"] === "checkboxList" && <>
          <EligibilityCriteriaListCheckboxes parameters={parameters}
            eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
            setEligibilityCriteriaCheckBoxesState={setEligibilityCriteriaCheckBoxesState}
            loadingEligibilityCriteriaCheckBoxesState={loadingEligibilityCriteriaCheckBoxesState}
            setLoadingEligibilityCriteriaCheckBoxesState={setLoadingEligibilityCriteriaCheckBoxesState} />
        </>}
      </>}
    </td>
  </>
}

//--RateRelativeImportance Project Action------------

const getTargetResourceRatingDictionary = async (targetResources, globalContext, projectFOI) => {
  let getBody = {
    'functionid': 'gettargetresourceratingdictionary',
    'idToken': '',
    'targetResources': targetResources,
    'userid': globalContext.userState.id,
    'tool': 'ProjectWork' + projectFOI
  };
  let response = await submitToFevirServer(globalContext, 50000, getBody, false, false);
  return response;
}

const updateResourceWithImportanceRatings = (resource, desirabilityCode, desirabilityDisplay, quantityValue) => {
  resource.content = resource.content.map(content => {
    if (content.type?.coding?.length > 0 && content.type.coding[0].code) {
      if (content.type.coding[0].code === "desirability") {
        content.classifier = [
          {
            "coding": [
              {
                "system": "https://fevir.net/resources/CodeSystem/112689",
                "code": desirabilityCode,
                "display": desirabilityDisplay
              }
            ]
          }
        ]
      }
      if (content.type.coding[0].code === "relative-importance") {
        content.quantity = {
          "value": quantityValue,
          "unit": "%",
          "system": "http://unitsofmeasure.org",
          "code": "%"
        }
      }
    }
    return content;
  })
  return resource;
}

const DesirabilityEntry = memo(({ targetfoi, startingValue, ratingImportanceWorkState,
  setRatingImportanceWorkState, setChangeAvailableToSaveState }) => {

  return <div>
    {/* FUTURE TO DO, NEEDS TO TEST
      <YesNoRadioCheckBoxSwitch elementName={"desirabilityCode"} labelElementName={"desirabilityDisplay"} thin={true} togglable={true} boxes={true} alternativeValues={["desirable", "undesirable"]} alternativeLabels={["Desirable", "Undesirable"]} parentElement={targetfoi} startingValue={ratingImportanceWorkState[targetfoi]["desirabilityCode"] || ""} setter={setRatingImportanceWorkState} setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    */}
    <RadioGroup row aria-label="desirability" name="desirability"
      value={startingValue}
      onChange={(e) => {
        handleChange("desirabilityCode", targetfoi, e.target.value.toLowerCase(),
          setRatingImportanceWorkState, setChangeAvailableToSaveState);
        handleChange("desirabilityDisplay", targetfoi, e.target.value,
          setRatingImportanceWorkState, setChangeAvailableToSaveState);
      }}>
      <FormControlLabel
        value="Desirable"
        control={<Radio color="primary" />}
        name="radio-button-control"
        color="default"
        inputprops={{ 'aria-label': 'Desirable' }}
        labelPlacement="top"
        label={<p style={{ textAlign: 'center' }}>Desirable</p>}
      />
      <FormControlLabel
        value="Undesirable"
        control={<Radio color="primary" />}
        color="default"
        name="radio-button-control"
        inputprops={{ 'aria-label': 'Undesirable' }}
        labelPlacement="top"
        label={<p style={{ textAlign: 'center' }}>Undesirable</p>}
      />
    </RadioGroup>
  </div>
})

const QuantityValueEntry = memo(({ elementName, fieldLabel, targetfoi, startingValue, ratingImportanceWorkState,
  setRatingImportanceWorkState, setChangeAvailableToSaveState }) => {

  return <div>
    <TextField style={{ width: "100%", marginTop: "16px", maxWidth: "150px" }}
      className="inputField" type='number' label={fieldLabel} size="small" variant='outlined'
      value={startingValue}
      onChange={(e) => {
        handleChange(elementName, targetfoi, parseFloat(e.target.value), setRatingImportanceWorkState,
          setChangeAvailableToSaveState)
      }} />
  </div>
})

const RateRelativeImportanceTable = ({ parameters, ratingImportanceWorkState, setRatingImportanceWorkState,
  targetResources, setChangeAvailableToSaveState }) => {

  return <Table className='viewmyworktable'>
    <Table.Header>
      <Table.Row>
        {parameters.columnHeaders.map((header, headerIndex) => {
          return <Table.HeaderCell key={headerIndex} >
            <span>
              {header}
            </span>
          </Table.HeaderCell>
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {(targetResources.length > 0 && Object.keys(ratingImportanceWorkState).length > 0) &&
        targetResources.map((item, itemIndex) => {
          return <Table.Row key={itemIndex}>
            {parameters.columnValues.map((value, valueIndex) => {
              return <Table.Cell key={valueIndex}>
                {value === "resource.title" ?
                  <>
                    <a href={"/resources/" + item.resourcetype + "/" + item.id.toString()}
                      target="_blank" rel="noopener noreferrer" >
                      {item.titleTrimmed || item.title}
                    </a>
                  </>
                  :
                  <>
                    {value === "desirability" ?
                      <DesirabilityEntry
                        targetfoi={item.id}
                        ratingImportanceWorkState={ratingImportanceWorkState}
                        setRatingImportanceWorkState={setRatingImportanceWorkState}
                        startingValue={ratingImportanceWorkState[item.id]["desirabilityDisplay"] || ""}
                        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                      :
                      <>{value === "relative-importance" ?
                        <QuantityValueEntry elementName='quantityValue'
                          targetfoi={item.id}
                          ratingImportanceWorkState={ratingImportanceWorkState}
                          setRatingImportanceWorkState={setRatingImportanceWorkState}
                          fieldLabel={'Relative Importance (as %)'}
                          startingValue={ratingImportanceWorkState[item.id]["quantityValue"].toString() || "0"}
                          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                        :
                        <>Unrecognized data input</>
                      }</>
                    }
                  </>
                }
              </Table.Cell>
            })}
          </Table.Row>
        })}
    </Table.Body>
  </Table>
}

const RateRelativeImportanceModal = memo(({ parameters, globalContext, rateRelativeImportanceModalState,
  setRateRelativeImportanceModalState, fhirEntryState, projectFOI }) => {

  const targetResources = fhirEntryState.projectResources
    .filter(resource => resource.resourcetype === "EvidenceVariable")
    .filter(resource => (resource.profile?.includes("https://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-definition") || resource.profile?.includes("http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-definition")))
    .map(entry => {
      let titletrimmed = entry.title.replace("OutcomeDefinition: ", "");
      entry.titleTrimmed = titletrimmed;
      return entry;
    });

  const [ratingImportanceWorkState, setRatingImportanceWorkState] = useState({});
  const [saveChangesState, setSaveChangesState] = useState(false);
  const [changeAvailableToSaveState, setChangeAvailableToSaveState] = useState(false);
  const [dictionaryCreatedState, setDictionaryCreatedState] = useState(false);
  const [dataLoadingState, setDataLoadingState] = useState(false);

  useEffect(async () => {
    if (saveChangesState) {
      for (const key in ratingImportanceWorkState) {
        const item = ratingImportanceWorkState[key];
        if (item.itemChanged) {
          if (!item.exists) {
            let newRating = {
              "resourceType": "ArtifactAssessment",
              "meta": {
                "profile": [
                  "http://hl7.org/fhir/uv/ebm/StructureDefinition/rating",
                  "http://hl7.org/fhir/uv/ebm/StructureDefinition/outcome-importance"
                ]
              },
              "extension": [
                {
                  "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
                  "valueCode": "active"
                },
                {
                  "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
                  "valueContactDetail": {
                    "name": globalContext.userState.name
                  }
                }
              ],
              "title": "Outcome Importance Rating of " + item.targettitleTrimmed + " by " + globalContext.userState.name,
              "artifactReference": {
                "reference": "EvidenceVariable/" + key,
                "type": "EvidenceVariable",
                "display": item.targettitle
              },
              "content": [
                {
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112689",
                        "code": "desirability",
                        "display": "Desirability"
                      }
                    ]
                  },
                  "classifier": [
                    {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/112689",
                          "code": item.desirabilityCode,
                          "display": item.desirabilityDisplay
                        }
                      ]
                    }
                  ]
                },
                {
                  "type": {
                    "coding": [
                      {
                        "system": "https://fevir.net/resources/CodeSystem/112689",
                        "code": "relative-importance",
                        "display": "Relative Importance"
                      }
                    ]
                  },
                  "quantity": {
                    "value": item.quantityValue,
                    "unit": "%",
                    "system": "http://unitsofmeasure.org",
                    "code": "%"
                  }
                }
              ]
            }
            let newRatingBody = {
              'functionid': 'submitfhirresource',
              'idToken': "",
              'fhirEntry': JSON.stringify(newRating),
              'status': "active",
              'associatedProjectFoi': projectFOI,
              'aboutformstateid': key,
              'tool': 'ProjectWork' + projectFOI,
              'title': newRating.title
            }
            let response = await submitToFevirServer(globalContext, 5000, newRatingBody, false, false);
            if (response?.success) { } else {
              alert("Problem submitting Rating for FOI " + key);
            }
            let newDictionary = JSON.parse(JSON.stringify(ratingImportanceWorkState));
            newDictionary[key]["exists"] = true;
            newDictionary[key]["itemChanged"] = false;
            setRatingImportanceWorkState(newDictionary);
          }
          if (item.exists) {
            let updatedResource = updateResourceWithImportanceRatings(item.resource, item.desirabilityCode, item.desirabilityDisplay, item.quantityValue);
            let updatedRatingBody = {
              'functionid': 'updatefhirresource',
              'idToken': "",
              'resourceid': item.resourceFOI,
              'resourcetype': "ArtifactAssessment",
              'fhirEntry': JSON.stringify(updatedResource),
              'status': "active",
              'associatedProjectFoi': projectFOI,
              'aboutformstateid': key,
              'tool': 'ProjectWork' + projectFOI,
              'title': updatedResource.title
            }
            let response = await submitToFevirServer(globalContext, 5000, updatedRatingBody, false, false);
            if (response?.success) { } else {
              alert("Problem updating Rating for FOI " + key);
            }
            let newDictionary = JSON.parse(JSON.stringify(ratingImportanceWorkState));
            newDictionary[key]["exists"] = true;
            newDictionary[key]["itemChanged"] = false;
            setRatingImportanceWorkState(newDictionary);
          }
        }
      }
      setSaveChangesState(false);
      setChangeAvailableToSaveState(false);
    } else {
      setDataLoadingState(true);
      let response = await getTargetResourceRatingDictionary(targetResources, globalContext, projectFOI);
      if (response?.success && response.targetResourceRatingDictionary) {
        setRatingImportanceWorkState(response.targetResourceRatingDictionary);
        setDictionaryCreatedState(true);
        setDataLoadingState(false);
      }
    }
  }, [saveChangesState]);

  let modalContent = <div style={{
    paddingTop: "0px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
  }}>
    {/*<Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setRateRelativeImportanceModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />*/}
    <span style={{
      position: "absolute", backgroundColor: "#FFFFFF", width: "calc(90vw - 38px)", zIndex: 100,
      paddingTop: "6px", paddingBottom: "6px"
    }} >
      <Button style={{ color: changeAvailableToSaveState ? "#FFFFFF" : "#000000", width: "230px", float: "left" }}
        className="formButton" disabled={saveChangesState}
        content="Save Changes" positive={changeAvailableToSaveState}
        onClick={() => {
          setSaveChangesState(true);
        }}
      />
      &nbsp;&nbsp;&nbsp;
      <Button style={{ color: "#000000", width: "180px", float: "right" }} className="formButton"
        content="Close Window"
        onClick={() => {
          setRateRelativeImportanceModalState(prevState => { return { ...prevState, modalOpen: false }; });
        }}
      />
    </span>
    <br /><br /><br />
    {dataLoadingState ?
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      :
      <div className='tableFixHead'>
        <RateRelativeImportanceTable parameters={parameters} targetResources={targetResources}
          ratingImportanceWorkState={ratingImportanceWorkState} setRatingImportanceWorkState={setRatingImportanceWorkState}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
      </div>}
    <br /><br />
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={rateRelativeImportanceModalState?.modalOpen}
      centered={false}
      content={modalContent}
      className="viewmywork"
    />
  )
})

const RateRelativeImportance = memo(({ parameters, globalContext, description, label, previousVersionLoaded, fhirEntryState,
  projectFOI }) => {

  if (!parameters) {
    parameters = {};
  }

  const [rateRelativeImportanceModalState, setRateRelativeImportanceModalState] = useState({ "modalOpen": false });

  return <>
    {rateRelativeImportanceModalState.modalOpen &&
      <RateRelativeImportanceModal parameters={parameters} globalContext={globalContext}
        fhirEntryState={fhirEntryState} projectFOI={projectFOI}
        rateRelativeImportanceModalState={rateRelativeImportanceModalState}
        setRateRelativeImportanceModalState={setRateRelativeImportanceModalState} />}
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={() => {
            if (globalContext.userState.id) {
              setRateRelativeImportanceModalState({ "modalOpen": true });
            } else {
              alert("Please login to use this function.");
            }
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
})

//--SelectResources Project Action--------------------

const SelectResources = ({ label, description, parameters, globalContext,
  previousVersionLoaded, provideInputState, setProvideInputState,
  validPatientDataBundleState, setValidPatientDataBundleState, patientDataBundleRadioButtonsState,
  setPatientDataBundleRadioButtonsState, eligibilityCriteriaCheckBoxesState,
  setEligibilityCriteriaCheckBoxesState, loadingPatientDataBundleRadioButtonsState,
  setLoadingPatientDataBundleRadioButtonsState, loadingEligibilityCriteriaCheckBoxesState,
  setLoadingEligibilityCriteriaCheckBoxesState }) => {

  return <>
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={() => { alert("No action associated with this function: selectresources"); }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description && <>
        {description}
        <br />
        {parameters["inputForm"] === "textBox" &&
          <TextField style={{ width: "100%" }} className="inputField" type='text'
            label={parameters["variableName"]} size="small" variant='outlined'
            value={provideInputState[parameters["variableName"]]?.input || ""}
            onChange={(e) => {
              setProvideInputState(prevState => {
                return { ...prevState, [parameters["variableName"]]: { input: e.target.value, validated: false } };
              });
            }}
          />}
        {(parameters["inputForm"] === "radioButton" && parameters["variableName"] === "patientDataBundle") && <>
          <PatientDataBundleRadioButtons parameters={parameters} globalContext={globalContext}
            setProvideInputState={setProvideInputState}
            setValidPatientDataBundleState={setValidPatientDataBundleState}
            patientDataBundleRadioButtonsState={patientDataBundleRadioButtonsState}
            setPatientDataBundleRadioButtonsState={setPatientDataBundleRadioButtonsState}
            loadingPatientDataBundleRadioButtonsState={loadingPatientDataBundleRadioButtonsState}
            setLoadingPatientDataBundleRadioButtonsState={setLoadingPatientDataBundleRadioButtonsState} />
        </>}
        {parameters["inputForm"] === "checkboxList" && <>
          <EligibilityCriteriaListCheckboxes parameters={parameters}
            eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
            setEligibilityCriteriaCheckBoxesState={setEligibilityCriteriaCheckBoxesState}
            loadingEligibilityCriteriaCheckBoxesState={loadingEligibilityCriteriaCheckBoxesState}
            setLoadingEligibilityCriteriaCheckBoxesState={setLoadingEligibilityCriteriaCheckBoxesState} />
        </>}
      </>}
    </td>
  </>
}

//--ToggleEnableRoBAT Project Action------------------

const ToggleEnableRoBAT = ({ projectState, setProjectState, fhirEntryState, projectFOI, projectJson,
  history, globalContext, addToast }) => {
  let resourceId = projectFOI;
  return <>
    <td><Button className="formButton" style={{ color: "#000000", width: "100%" }}
      content={projectState.enableRoBAT ? "Disallow RoBAT" : "Enable RoBAT"}
      onClick={async () => {
        if (fhirEntryState.editpermission) {
          setProjectState(prevState => {
            return { ...prevState, enableRoBAT: !(prevState.enableRoBAT || false) };
          });
          await new Promise(resolve => setTimeout(resolve, 500)); //Waits half a second
          let workingBlob = await toggleEnableRoBATinProjectJson(projectJson,
            resourceId, fhirEntryState);

          if (workingBlob) {
            let fhirEntry = JSON.stringify(workingBlob, null, 2);
            let updateBody = {
              'functionid': 'updatefhirresource',
              'idToken': '',
              'fhirEntry': fhirEntry,
              'resourcetype': "Project",
              'resourceid': resourceId,
              'title': fhirEntryState.title,
              'status': fhirEntryState.status,
              'bypasswarnings': false
            };
            let response = await submitToFevirServer(globalContext, 5000, updateBody, false, false);
            if (response?.success) {
              history.push("/");
              history.push(`/resources/Project/${resourceId}`);
              //REPLACE FHIR ENTRY STATE JSON WITH BLOB
              //PUT REFERENCE LIST IN PROJECT RESOURCE JSON, AND SUBMIT UPDATED RESOURCE TO SERVER
              addToast('RoBAT for this Project has been' + (projectState.enableRoBAT ? "RoBAT Enabled" : "RoBAT Disallowed") + '.', { appearance: 'success' });
            } else {
              alert("Did not succeed in changing RoBAT for this Project: " + response.warningMessage);
            }
          } else {
            alert("Did not succeed in changing RoBAT for this Project.");
          }
        } else {
          alert("You don't have permission to use this function for this Project.");
        }
      }}
      disabled={fhirEntryState.previousVersionLoaded}
    />
    </td>
    <td>
      {projectState.enableRoBAT ? "Disallow" : "Allow"} project viewers to use the Risk of Bias Assessment Tool (RoBAT) for items in the Reference List.
    </td>
  </>
}

//--ViewEligibilityCriteria Project Action------------

const ViewEligibilityCriteria = ({ parameters, referencedResourceState, setReferencedResourceState,
  globalContext, description, label, previousVersionLoaded }) => {

  const [eligibilityCriteriaVisibleState, setEligibilityCriteriaVisibleState] = useState({});

  const hideShowEligibilityCriteria = async (foi) => {
    if (foi) {
      let visibility = true;
      if (eligibilityCriteriaVisibleState[foi]) {
        visibility = false;
      } else {
        visibility = true;
        await loadReferencedResource(getResource, globalContext.userState, setReferencedResourceState,
          undefined, "Group/" + foi, "referencedGroup", "referencedGroupString", "groupCharacteristics",
          "groupExclusionCharacteristics", "groupInclusionCharacteristics");
      }
      setEligibilityCriteriaVisibleState(prevState => {
        return {
          ...prevState,
          [foi]: visibility,
        };
      });
    }
  }

  const EligibilityCriteriaBox = ({ foi, referencedResourceState }) => {
    return <div style={{ border: "2px solid #000000" }}>
      <table className="noStyleTable" style={{ width: "100%", padding: "0px", margin: "0px" }}>
        <tbody>
          <tr>
            <td style={{ minWidth: "80%", verticalAlign: "top", padding: "8px" }}>
              <h3>{referencedResourceState.referencedGroup.name}</h3>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "top" }}>
              <Button style={{ padding: "6px" }} className="formButton negative" content="✖"
                onClick={() => { hideShowEligibilityCriteria(foi); }} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ padding: "8px", paddingTop: "4px" }}>
        <InclusionExclusionCharacteristicTables editMode={false} smallCriteriaHeader={false}
          groupInclusionCharacteristics={referencedResourceState.groupInclusionCharacteristics}
          groupExclusionCharacteristics={referencedResourceState.groupExclusionCharacteristics}
          stateSetter={undefined} changeState={undefined} groupCharacteristicKindOptions={undefined}
          setGroupCharacteristicKindOptions={undefined} />
      </div>
    </div>
  }

  return <>
    {eligibilityCriteriaVisibleState[parameters["FOI"]] ?
      <td style={{ verticalAlign: "top" }} colSpan="2">
        <EligibilityCriteriaBox foi={parameters["FOI"]} referencedResourceState={referencedResourceState} />
      </td>
      :
      <>
        <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
          <Button className="formButton" style={{ color: "#000000", width: "100%" }}
            content={label}
            onClick={async () => { await hideShowEligibilityCriteria(parameters["FOI"]); }}
            disabled={previousVersionLoaded}
          />
        </td>
        <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
          {description}
        </td>
      </>
    }
  </>
}

//--ViewMyWork Project Action------------

const getTargetResourceClassificationDictionary = async (targetResources, globalContext, projectFOI) => {
  let getBody = {
    'functionid': 'gettargetresourceclassificationdictionary',
    'idToken': '',
    'targetResources': targetResources,
    'userid': globalContext.userState.id,
    //'tool': 'ProjectWork' + projectFOI,
    'projectFOI': projectFOI
  };
  let response = await submitToFevirServer(globalContext, 50000, getBody, false, false);
  return response;
}

const getRaterTableData = async (targetResources, globalContext, projectFOI) => {
  let getBody = {
    'functionid': 'gettargetresourceclassificationdictionary',
    'idToken': '',
    'targetResources': targetResources,
    'getAllUserClassifications': true,
    //'tool': 'ProjectWork' + projectFOI,
    'projectFOI': projectFOI
  };
  let response = await submitToFevirServer(globalContext, 50000, getBody, false, false);
  return response;
}
/*
const convertYesNoStringToBoolean = (yesNoString) => {
  let yesNoBoolean;
  if (yesNoString === "yes") {
    yesNoBoolean = true;
  } else if (yesNoString === "no") {
    yesNoBoolean = false;
  }
  return yesNoBoolean;
}
*/
const addOrDeleteClassifier = (content, value) => {
  if (value !== undefined && value !== "") {
    content["classifier"] = [{
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/115018",
          "code": value,
          "display": value.charAt(0).toUpperCase() + value.slice(1)
        }]
    }];
  } else {
    content["classifier"] = [{ "coding": [{}] }];
  }
}

const updateResourceWithDateRatings = (resource, systematicallyDerived, intendedToGuide, incorporatedIntoResults, articleDate, preprintDate, preprintCoding, preprintText,
  registryDate, studyRegistryCoding, studyRegistryText, resultsRegistryCoding, notesText) => {
  let notesContentEntryFound = false;
  resource.content = resource.content.map(content => {
    if (content.type?.coding?.length > 0 && content.type.coding[0].code) {
      if (content.type.coding[0].code === "systematically-derived") {
        addOrDeleteClassifier(content, systematicallyDerived);
      } else if (content.type.coding[0].code === "intended-to-guide") {
        addOrDeleteClassifier(content, intendedToGuide);
      } else if (content.type.coding[0].code === "incorporated-into-results") {
        addOrDeleteClassifier(content, incorporatedIntoResults);
      } else if (content.type.coding[0].code === "date-of-first-publication-of-article") {
        if (content.extension?.length > 0 &&
          (content.extension[0].url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating" ||
            content.extension[0].url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating")) {
          content.extension[0].valueDateTime = articleDate;
        } else {
          content.extension = [{
            "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating",
            "valueDateTime": articleDate
          }];
        }
      } else if (content.type.coding[0].code === "date-of-first-preprint-of-article") {
        if (content.extension?.length > 0 &&
          (content.extension[0].url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating" ||
            content.extension[0].url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating")) {
          content.extension[0].valueDateTime = preprintDate;
        } else {
          content.extension = [{
            "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating",
            "valueDateTime": preprintDate
          }]
        }
        content.classifier = [{
          "coding": preprintCoding,
          "text": preprintText
        }];
      } else if (content.type.coding[0].code === "date-of-first-registry-posting-of-results") {
        if (content.extension?.length > 0 &&
          (content.extension[0].url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating" ||
            content.extension[0].url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating")) {
          content.extension[0].valueDateTime = registryDate;
        } else {
          content.extension = [{
            "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating",
            "valueDateTime": registryDate
          }];
        }
        content.classifier = [
          {
            "coding": studyRegistryCoding,
            "text": studyRegistryText
          },
          {
            "coding": resultsRegistryCoding
          }
        ];
      }
    }
    if (!content.summary && notesText) {//TODO - changed from if (content.informationType === "comment") BUT IS THIS FUNCTION USED?
      content.summary = notesText;
      notesContentEntryFound = true;
    }
    return content;
  });

  if (notesContentEntryFound === false && notesText) {
    resource.content.push({ "summary": notesText });
  }

  return resource;
}

const handleChange = (name, targetfoi, value, setDateRatingWorkState, setChangeAvailableToSaveState) => {
  setDateRatingWorkState(prevState => {
    return {
      ...prevState,
      [targetfoi]: {
        ...prevState[targetfoi],
        [name]: value,
        "itemChanged": true
      }
    };
  });
  setChangeAvailableToSaveState(true);
}

const DateEntry = memo(({ elementName, fieldLabel, targetfoi, startingValue, dateRatingWorkState,
  setDateRatingWorkState, setChangeAvailableToSaveState, width }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const CalendarModal = () => {
    return <div className="calendarModal"><MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="yyyy-MM-dd"
        emptyLabel="yyyy-MM-dd"
        value={startingValue ? parseISO(startingValue, "yyyy-MM-dd") : null}
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        onChange={(e) => {
          handleChange(elementName, targetfoi, format(e, "yyyy-MM-dd"), setDateRatingWorkState, setChangeAvailableToSaveState);
        }}
      />
    </MuiPickersUtilsProvider></div>
  }

  return <div>
    <TextField style={{ width: "100%", maxWidth: width ? width : "150px" }}
      className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
      value={startingValue || ""}
      onChange={(e) => { handleChange(elementName, targetfoi, e.target.value, setDateRatingWorkState, setChangeAvailableToSaveState) }} />
    <Button style={{ marginTop: "2px", marginLeft: "6px", fontSize: "28px", padding: "0px", backgroundColor: "white" }} className="calendarButton"
      content="📅" onClick={() => { setCalendarOpen(true) }} />
    <CalendarModal />
  </div>
})

const PreprintDataEntry = memo(({ targetfoi, dateRatingWorkState,
  setDateRatingWorkState, setChangeAvailableToSaveState }) => {
  let startingPreprintYes = false;
  if (JSON.stringify(dateRatingWorkState[targetfoi]["results-in-preprint-coding"]) === JSON.stringify([
    {
      "system": "https://fevir.net/resources/CodeSystem/115018",
      "code": "results-in-preprint",
      "display": "Results in preprint"
    }
  ])) {
    startingPreprintYes = true;
  }
  const [preprintYesState, setPreprintYesState] = useState(startingPreprintYes);

  return <>
    <RadioGroup row aria-label="preprint" name="preprint"
      value={JSON.stringify(dateRatingWorkState[targetfoi]["results-in-preprint-coding"])}
      onChange={(e) => {
        handleChange("results-in-preprint-coding", targetfoi, JSON.parse(e.target.value),
          setDateRatingWorkState, setChangeAvailableToSaveState);
        setPreprintYesState(e.target.value === JSON.stringify([
          {
            "system": "https://fevir.net/resources/CodeSystem/115018",
            "code": "results-in-preprint",
            "display": "Results in preprint"
          }
        ]));
      }}>
      <FormControlLabel
        value={JSON.stringify([
          {
            "system": "https://fevir.net/resources/CodeSystem/115018",
            "code": "results-in-preprint",
            "display": "Results in preprint"
          }
        ])}
        control={<Radio color="primary" />}
        name="radio-button-control"
        color="default"
        inputprops={{ 'aria-label': 'Results in preprint' }}
        labelPlacement="top"
        label={<p style={{ textAlign: 'center' }}>Results in preprint</p>}
      />
      <FormControlLabel
        value={JSON.stringify([
          {
            "system": "https://fevir.net/resources/CodeSystem/115018",
            "code": "results-not-in-preprint",
            "display": "No preprint with results"
          }
        ])}
        control={<Radio color="primary" />}
        color="default"
        name="radio-button-control"
        inputprops={{ 'aria-label': 'No preprint' }}
        labelPlacement="top"
        label={<p style={{ textAlign: 'center' }}>No preprint</p>}
      />
    </RadioGroup>
    {preprintYesState && <>
      <br />
      <TextField style={{ width: "100%", marginTop: "16px", maxWidth: "150px" }}
        className="inputField" type='text' label="URL for Preprint" size="small" variant='outlined'
        value={dateRatingWorkState[targetfoi]["results-preprint-text"] || ""}
        onChange={(e) => {
          handleChange("results-preprint-text", targetfoi, e.target.value,
            setDateRatingWorkState, setChangeAvailableToSaveState)
        }} />
      <br />
      <DateEntry elementName='date-of-first-preprint-of-article'
        targetfoi={targetfoi}
        dateRatingWorkState={dateRatingWorkState}
        setDateRatingWorkState={setDateRatingWorkState}
        fieldLabel={'YYYY-MM-DD Preprint'}
        startingValue={dateRatingWorkState[targetfoi]["date-of-first-preprint-of-article"] || ""}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </>}
  </>
})

const RegistryDataEntry = memo(({ targetfoi, dateRatingWorkState,
  setDateRatingWorkState, setChangeAvailableToSaveState }) => {
  let startingRegistryYes = false;
  let startingRegistryResultsYes = false;
  if (JSON.stringify(dateRatingWorkState[targetfoi]["study-in-registry-coding"]) === JSON.stringify([
    {
      "system": "https://fevir.net/resources/CodeSystem/115018",
      "code": "study-in-registry",
      "display": "Study in registry"
    }
  ])) {
    startingRegistryYes = true;
  }
  if (JSON.stringify(dateRatingWorkState[targetfoi]["results-in-registry-coding"]) === JSON.stringify([
    {
      "system": "https://fevir.net/resources/CodeSystem/115018",
      "code": "results-in-registry",
      "display": "Results in registry"
    }
  ])) {
    startingRegistryResultsYes = true;
  }
  const [registryYesState, setRegistryYesState] = useState(startingRegistryYes);
  const [registryResultsYesState, setRegistryResultsYesState] = useState(startingRegistryResultsYes);

  return <>
    <RadioGroup row aria-label="studyinregistry" name="studyinregistry"
      value={JSON.stringify(dateRatingWorkState[targetfoi]["study-in-registry-coding"])}
      onChange={(e) => {
        setDateRatingWorkState(prevState => {
          return {
            ...prevState,
            [targetfoi]: {
              ...prevState[targetfoi],
              "study-in-registry-text": "",
              "study-in-registry-coding": JSON.parse(e.target.value),
              "results-in-registry-coding": "",
              'date-of-first-registry-posting-of-results': "",
              "itemChanged": true,
              "error": false
            }
          };
        });
        setChangeAvailableToSaveState(true);

        setRegistryYesState(e.target.value === JSON.stringify([
          {
            "system": "https://fevir.net/resources/CodeSystem/115018",
            "code": "study-in-registry",
            "display": "Study in registry"
          }
        ]));
      }}>
      <FormControlLabel
        value={JSON.stringify([
          {
            "system": "https://fevir.net/resources/CodeSystem/115018",
            "code": "study-in-registry",
            "display": "Study in registry"
          }
        ])}
        control={<Radio color="primary" />}
        name="radio-button-control"
        color="default"
        inputprops={{ 'aria-label': 'Study in registry' }}
        labelPlacement="top"
        label={<p style={{ textAlign: 'center' }}>Study in registry</p>}
      />
      <FormControlLabel
        value={JSON.stringify([
          {
            "system": "https://fevir.net/resources/CodeSystem/115018",
            "code": "study-not-in-registry",
            "display": "No registration of study"
          }
        ])}
        control={<Radio color="primary" />}
        color="default"
        name="radio-button-control"
        inputprops={{ 'aria-label': 'No registration of study' }}
        labelPlacement="top"
        label={<p style={{ textAlign: 'center' }}>No registration of study</p>}
      />
    </RadioGroup>
    {registryYesState && <>
      <br />
      <TextField style={{ width: "100%", marginBottom: "12px", maxWidth: "220px" }}
        className="inputField" type='text' label="ID or URL for Study Record" size="small" variant='outlined'
        value={dateRatingWorkState[targetfoi]["study-in-registry-text"] || ""}
        onChange={async (e) => {
          let possibleNctId = e.target.value.replaceAll(/\s/g, '').toUpperCase();
          if (possibleNctId.substring(0, 3) === "NCT" && possibleNctId.length === 11) {
            await axios({
              method: 'GET',
              url: `https://clinicaltrials.gov/api/v2/studies/${possibleNctId}?format=json`,
              timeout: 15000,
            }).then((response) => {
              let registryCoding = "[]";
              let firstPostDate = "";
              if (response?.data?.hasResults) {
                //select the radio button! which is changing the state of that radio
                registryCoding = JSON.stringify([
                  {
                    "system": "https://fevir.net/resources/CodeSystem/115018",
                    "code": "results-in-registry",
                    "display": "Results in registry"
                  }
                ]);
                if (response.data.protocolSection?.statusModule?.studyFirstPostDateStruct?.date) {
                  firstPostDate = response.data.protocolSection.statusModule.studyFirstPostDateStruct.date;
                }
              } else {
                //select the radio button! which is changing the state of that radio
                registryCoding = JSON.stringify([
                  {
                    "system": "https://fevir.net/resources/CodeSystem/115018",
                    "code": "results-not-in-registry",
                    "display": "No results posting in registry"
                  }
                ]);
              }
              setDateRatingWorkState(prevState => {
                return {
                  ...prevState,
                  [targetfoi]: {
                    ...prevState[targetfoi],
                    "study-in-registry-text": possibleNctId,
                    "results-in-registry-coding": JSON.parse(registryCoding),
                    'date-of-first-registry-posting-of-results': firstPostDate,
                    "itemChanged": true,
                    "error": false
                  }
                };
              });
              setRegistryResultsYesState(registryCoding === JSON.stringify([
                {
                  "system": "https://fevir.net/resources/CodeSystem/115018",
                  "code": "results-in-registry",
                  "display": "Results in registry"
                }
              ]));
              setChangeAvailableToSaveState(true);
            })
              .catch(err => {
                setDateRatingWorkState(prevState => {
                  return {
                    ...prevState,
                    [targetfoi]: {
                      ...prevState[targetfoi],
                      "study-in-registry-text": possibleNctId,
                      "itemChanged": true,

                      "error": true
                    }
                  };
                });
                setChangeAvailableToSaveState(true);
              });
          } else {
            handleChange("study-in-registry-text", targetfoi, e.target.value,
              setDateRatingWorkState, setChangeAvailableToSaveState);
          }
        }} />
      <span style={{ color: "red", visibility: dateRatingWorkState[targetfoi]["error"] ? "" : "hidden" }}>&nbsp;&nbsp;NCTID Not Found</span>
      <br />
      <RadioGroup row aria-label="resultsinregistry" name="resultsinregistry"
        value={JSON.stringify(dateRatingWorkState[targetfoi]["results-in-registry-coding"])}
        onChange={(e) => {
          handleChange("results-in-registry-coding", targetfoi, JSON.parse(e.target.value),
            setDateRatingWorkState, setChangeAvailableToSaveState);
          setRegistryResultsYesState(e.target.value === JSON.stringify([
            {
              "system": "https://fevir.net/resources/CodeSystem/115018",
              "code": "results-in-registry",
              "display": "Results in registry"
            }
          ]));
        }}>
        <FormControlLabel
          value={JSON.stringify([
            {
              "system": "https://fevir.net/resources/CodeSystem/115018",
              "code": "results-in-registry",
              "display": "Results in registry"
            }
          ])}
          control={<Radio color="primary" />}
          name="radio-button-control"
          color="default"
          inputprops={{ 'aria-label': 'Results in registry' }}
          labelPlacement="top"
          label={<p style={{ textAlign: 'center' }}>Results in registry</p>}
        />
        <FormControlLabel
          value={JSON.stringify([
            {
              "system": "https://fevir.net/resources/CodeSystem/115018",
              "code": "results-not-in-registry",
              "display": "No results posting in registry"
            }
          ])}
          control={<Radio color="primary" />}
          color="default"
          name="radio-button-control"
          inputprops={{ 'aria-label': 'No results posting in registry' }}
          labelPlacement="top"
          label={<p style={{ textAlign: 'center' }}>No results posting in registry</p>}
        />
      </RadioGroup>
      {registryResultsYesState && <>
        <br />
        <DateEntry elementName='date-of-first-registry-posting-of-results'
          targetfoi={targetfoi}
          dateRatingWorkState={dateRatingWorkState}
          setDateRatingWorkState={setDateRatingWorkState}
          fieldLabel={'YYYY-MM-DD Results Posted'}
          startingValue={dateRatingWorkState[targetfoi]["date-of-first-registry-posting-of-results"] || ""}
          setChangeAvailableToSaveState={setChangeAvailableToSaveState}
          width="220px" />
      </>}
    </>}
  </>
})

const ViewMyWorkResultsFirstPostedTable = ({ parameters, dateRatingWorkState, setDateRatingWorkState, targetResources, setChangeAvailableToSaveState }) => {
  console.log("The old View My Work Table.")
  return <Table className='viewmyworktable'>
    <Table.Header>
      <Table.Row>
        {parameters.columnHeaders.map((header, headerIndex) => {
          return <Table.HeaderCell key={headerIndex} style={{ verticalAlign: "bottom" }} >
            <span style={{ whiteSpace: "pre-wrap" }}>
              {header}
            </span>
          </Table.HeaderCell>
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {(targetResources?.length > 0 && Object.keys(dateRatingWorkState).length > 0) &&
        targetResources.map((item, itemIndex) => {
          return <Table.Row key={itemIndex}>
            {parameters.columnValues.map((value, valueIndex) => {
              return <Table.Cell key={valueIndex}>
                {value === "resource.title" ?
                  <>
                    <a href={"/resources/" + item.resourcetype + "/" + item.id.toString()}
                      target="_blank" rel="noopener noreferrer" >
                      {item.title}
                    </a>
                  </>
                  :
                  <>
                    {value === "date-of-first-publication-of-article" ?
                      <DateEntry elementName='date-of-first-publication-of-article'
                        targetfoi={item.id}
                        dateRatingWorkState={dateRatingWorkState}
                        setDateRatingWorkState={setDateRatingWorkState}
                        fieldLabel={'YYYY-MM-DD Article'}
                        startingValue={dateRatingWorkState[item.id]["date-of-first-publication-of-article"] || ""}
                        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                      :
                      <>{value === "date-of-first-publication-of-results" ?
                        <DateEntry elementName='date-of-first-publication-of-results'
                          targetfoi={item.id}
                          dateRatingWorkState={dateRatingWorkState}
                          setDateRatingWorkState={setDateRatingWorkState}
                          fieldLabel={'YYYY-MM-DD Results'}
                          startingValue={dateRatingWorkState[item.id]["date-of-first-publication-of-results"] || ""}
                          setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                        :
                        <>{value === "date-of-first-preprint-of-article" ?
                          <PreprintDataEntry targetfoi={item.id} dateRatingWorkState={dateRatingWorkState}
                            setDateRatingWorkState={setDateRatingWorkState}
                            setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                          :
                          <>{value === "date-of-first-registry-posting-of-results" ?
                            <RegistryDataEntry targetfoi={item.id} dateRatingWorkState={dateRatingWorkState}
                              setDateRatingWorkState={setDateRatingWorkState}
                              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                            :
                            <>{value === "notes" ?
                              <TextField style={{ width: "100%", marginTop: "16px", maxWidth: "150px" }}
                                className="inputField" type='text' label="Notes" size="small" variant='outlined'
                                value={dateRatingWorkState[item.id]["notes"] || ""}
                                onChange={(e) => {
                                  handleChange("notes", item.id, e.target.value,
                                    setDateRatingWorkState, setChangeAvailableToSaveState)
                                }} />
                              :
                              <>Unrecognized data input</>
                            }</>
                          }</>
                        }</>
                      }</>
                    }
                  </>
                }
              </Table.Cell>
            })}
          </Table.Row>
        })}
    </Table.Body>
  </Table>
}

const ViewMyWorkWhenResultsUsedTable = ({ parameters, dateRatingWorkState, setDateRatingWorkState, targetResources, setChangeAvailableToSaveState }) => {

  return <Table className='viewmyworktable'>
    <Table.Header>
      <Table.Row>
        {parameters.columnHeaders.map((header, headerIndex) => {
          return <Table.HeaderCell key={headerIndex} style={{ verticalAlign: "bottom" }} >
            <span style={{ whiteSpace: "pre-wrap" }}>
              {header}
            </span>
          </Table.HeaderCell>
        })}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {(targetResources?.length > 0 && Object.keys(dateRatingWorkState).length > 0) &&
        targetResources.map((item, itemIndex) => {

          return <Table.Row key={itemIndex}>
            {parameters.columnValues.map((value, valueIndex) => {
              let yesNoQuestion = (value === "systematically-derived" || value === "intended-to-guide" || value === "incorporated-into-results");
              let cellStyle = {};
              if (value === "resource.title") {
                if (dateRatingWorkState[item.id]["systematically-derived"] === "yes" && dateRatingWorkState[item.id]["intended-to-guide"] === "yes" && dateRatingWorkState[item.id]["incorporated-into-results"] === "yes" && dateRatingWorkState[item.id]["date-of-first-publication-of-article"]) {
                  //The row is completed (other than optional notes)
                  cellStyle = { "backgroundColor": "rgba(46, 225, 50, 0.26)" };
                } else if (dateRatingWorkState[item.id]["systematically-derived"] === "no" || dateRatingWorkState[item.id]["intended-to-guide"] === "no" || dateRatingWorkState[item.id]["incorporated-into-results"] === "no") {
                  cellStyle = { "backgroundColor": "rgba(245, 56, 70, 0.26)" };
                } else {

                }
              } else if (value === "date-of-first-publication-of-article") {
                cellStyle = { "textAlign": "left", "width": "222px", "paddingTop": "8px", "paddingBottom": "0px" };
              } else {
                cellStyle = { "textAlign": "center" };
              }
              return <Table.Cell key={valueIndex} style={cellStyle}>
                {value === "resource.title" ?
                  <>
                    <a href={"/resources/" + item.resourcetype + "/" + item.id.toString()}
                      target="_blank" rel="noopener noreferrer" >
                      {item.title}
                    </a>
                  </>
                  :
                  <>
                    {value === "date-of-first-publication-of-article" ?
                      <>
                        {(dateRatingWorkState[item.id]["systematically-derived"] === "yes" && dateRatingWorkState[item.id]["intended-to-guide"] === "yes" && dateRatingWorkState[item.id]["incorporated-into-results"] === "yes") &&
                          <DateEntry elementName='date-of-first-publication-of-article'
                            targetfoi={item.id}
                            dateRatingWorkState={dateRatingWorkState}
                            setDateRatingWorkState={setDateRatingWorkState}
                            fieldLabel={'YYYY-MM-DD'}
                            startingValue={dateRatingWorkState[item.id]["date-of-first-publication-of-article"] || ""}
                            setChangeAvailableToSaveState={setChangeAvailableToSaveState} width={"140px"} />
                        }
                      </>
                      :
                      <>{yesNoQuestion ?
                        <span style={{ align: "center" }}><YesNoRadioCheckBoxSwitch elementName={value} changeElementName={"itemChanged"} thin={true} togglable={true} boxes={true} parentElement={item.id} startingValue={dateRatingWorkState[item.id][value] || ""} setter={setDateRatingWorkState} setChangeAvailableToSaveState={setChangeAvailableToSaveState} /></span>
                        :
                        <>{value === "notes" ?
                          <TextField style={{ width: "100%", marginTop: "0px", maxWidth: "160px" }}
                            className="inputField" type='text' label="Notes" size="small" variant='outlined'
                            value={dateRatingWorkState[item.id]["notes"] || ""}
                            onChange={(e) => {
                              handleChange("notes", item.id, e.target.value,
                                setDateRatingWorkState, setChangeAvailableToSaveState)
                            }} />
                          :
                          <>Unrecognized data input</>
                        }</>
                      }</>
                    }
                  </>
                }
              </Table.Cell>
            })}
          </Table.Row>
        })}
    </Table.Body>
  </Table>
}

const ComparisonCompositeCell = ({ compositeTableState, setCompositeTableState, setCompositeResourceChangesState, setChangeAvailableToSaveState, targetFOI, fieldName }) => {

  const [loadedAdjudicatedState, setLoadedAdjudicatedState] = useState(compositeTableState[targetFOI]["adjudicated-" + fieldName]);
  const [adjudicatedOptionsState, setAdjudicatedOptionsState] = useState({ "options": [] });

  useEffect(() => {
    let uniqueResultsArray = compositeTableState[targetFOI]["unique-values-" + fieldName];

    const adjudicatedOptions = uniqueResultsArray.map((value, index) => {
      let typeObj = {
        key: value,
        text: value,
        value: value
      };
      return typeObj;
    });

    let adjudicatedValue = compositeTableState[targetFOI]["adjudicated-" + fieldName];
    if (adjudicatedValue && !uniqueResultsArray.includes(adjudicatedValue)) {
      adjudicatedOptions.push({ key: adjudicatedValue, text: adjudicatedValue, value: adjudicatedValue });
    }

    setAdjudicatedOptionsState({ "options": adjudicatedOptions });
  }, []); //compositeTableState //compositeTableState[targetFOI]["adjudicated-"+fieldName]

  useEffect(() => {
    if (compositeTableState[targetFOI]["adjudicated-" + fieldName] !== loadedAdjudicatedState) {
      setChangeAvailableToSaveState(true);
      setCompositeResourceChangesState(prevState => { return { ...prevState, [compositeTableState[targetFOI]["adjudicated-foi"]]: targetFOI } });
    }
  }, [compositeTableState[targetFOI]["adjudicated-" + fieldName]]); //compositeTableState[targetFOI]["adjudicated-"+fieldName]

  let uniqueResultsArray = compositeTableState[targetFOI]["unique-values-" + fieldName];
  let enteredCount = compositeTableState[targetFOI]["entered-count-" + fieldName];

  if (uniqueResultsArray?.length === 1 && enteredCount >= 2) {
    return <Table.Cell className="compositeComparisonSameCell"><i>{uniqueResultsArray[0]}</i></Table.Cell>
  } else if (uniqueResultsArray?.length > 1) {
    return <Table.Cell className={compositeTableState[targetFOI]["adjudicated-" + fieldName] ? "compositeComparisonAdjudicatedCell" : "compositeComparisonMismatchCell"}>
      {compositeTableState[targetFOI]["adjudicated-" + fieldName] ? <>Adjudicated{(loadedAdjudicatedState === compositeTableState[targetFOI]["adjudicated-" + fieldName]) && <>: {loadedAdjudicatedState}</>}</> : <>MISMATCH</>}
      {/*compositeTableState[targetFOI]["adjudicated-"+fieldName] ? <>{(loadedAdjudicatedState === compositeTableState[targetFOI]["adjudicated-"+fieldName]) ? <>{fieldLabelsLookup[fieldName]} : {loadedAdjudicatedState}</> : <>Adjudicated</>}</> : <>MISMATCH</>*/}
      {true && <div style={{ "paddingTop": "8px" }}>
        <DropDownWithAdditions currentValue={compositeTableState[targetFOI]["adjudicated-" + fieldName] || ""} labelText={'Adjudicated'} options={adjudicatedOptionsState.options} setter={setAdjudicatedOptionsState} stateSetter={setCompositeTableState} elementKeyEntry={targetFOI} elementKeySubEntry={"adjudicated-" + fieldName} />
      </div>}
    </Table.Cell>
  } else {
    return <Table.Cell></Table.Cell>
  }
}


const ViewMyWorkModal = memo(({ parameters, globalContext, viewMyWorkModalState, setViewMyWorkModalState, fhirEntryState, projectFOI }) => {

  const targetResources = fhirEntryState?.projectResources?.filter(resource => parameters.targetResourceType?.includes(resource.resourcetype));

  let targetFoisInAlphabeticalOrder = [];

  if (targetResources) {
    targetResources.sort((a, b) => {
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
    });
    targetFoisInAlphabeticalOrder = targetResources.map((resourceEntry) => resourceEntry.id);
  }

  const [dateRatingWorkState, setDateRatingWorkState] = useState({});
  const [saveChangesState, setSaveChangesState] = useState(false);
  const [changeAvailableToSaveState, setChangeAvailableToSaveState] = useState(false);
  const [dictionaryCreatedState, setDictionaryCreatedState] = useState(false);
  const [raterTableState, setRaterTableState] = useState({});
  const [compositeTableState, setCompositeTableState] = useState({});
  const [compositeResourceChangesState, setCompositeResourceChangesState] = useState({});
  const [dataLoadingState, setDataLoadingState] = useState(false);
  const [raterTableLoadingState, setRaterTableLoadingState] = useState(false);

  useEffect(async () => {
    if (saveChangesState) {
      for (const key in dateRatingWorkState) {
        const item = dateRatingWorkState[key];
        if (item.itemChanged) {
          if (item.exists) {
            let updatedResource = updateResourceWithDateRatings(item.resource,
              item["systematically-derived"],
              item["intended-to-guide"],
              item["incorporated-into-results"],
              item["date-of-first-publication-of-article"],
              item["date-of-first-preprint-of-article"],
              item["results-in-preprint-coding"],
              item["results-preprint-text"],
              item["date-of-first-registry-posting-of-results"],
              item["study-in-registry-coding"],
              item["study-in-registry-text"],
              item["results-in-registry-coding"],
              item["notes"]);
            let updatedClassificationBody = {
              'functionid': 'updatefhirresource',
              'idToken': "",
              'resourceid': item.resourceFOI,
              'resourcetype': "ArtifactAssessment",
              'fhirEntry': JSON.stringify(updatedResource),
              'status': "active",
              'associatedProjectFoi': projectFOI,
              'aboutformstateid': key,
              'tool': 'ProjectWork' + projectFOI,
              'title': updatedResource.title
            }
            let response = await submitToFevirServer(globalContext, 120000, updatedClassificationBody, false, false);
            if (response?.success) { } else {
              alert("Problem updating Classification for FOI " + key);
            }
            setDateRatingWorkState(prevState => { return { ...prevState, [key]: { ...prevState[key], exists: true, itemChanged: false } }; });
          } else {
            let newClassification;
            if (parameters?.columnHeaders?.includes("Date Article FIRST Published")) {
              //The old View My Work model
              newClassification = {
                "resourceType": "ArtifactAssessment",
                "meta": {
                  "profile": [
                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/classification",
                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/date-as-rating"
                  ]
                },
                "extension": [
                  {
                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
                    "valueCode": "active"
                  },
                  {
                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
                    "valueContactDetail": {
                      "name": globalContext.userState.name
                    }
                  }
                ],
                "title": "DateOfFirstPublication Classification of FOI " + key + " by " + globalContext.userState.name,
                "artifactReference": {
                  "reference": "Citation/" + key,
                  "type": "Citation"
                },
                "content": [
                  {
                    "extension": [
                      {
                        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating",
                        "valueDateTime": item["date-of-first-publication-of-article"]
                      }
                    ],
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": "date-of-first-publication-of-article",
                          "display": "Date of first publication of article"
                        }
                      ]
                    },
                    "freeToShare": true
                  },
                  {
                    "extension": [
                      {
                        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating",
                        "valueDateTime": item["date-of-first-preprint-of-article"]
                      }
                    ],
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": "date-of-first-preprint-of-article",
                          "display": "Date of first preprint of article"
                        }
                      ]
                    },
                    "classifier": [
                      {
                        "coding": item["results-in-preprint-coding"],
                        "text": item["results-preprint-text"]
                      }
                    ],
                    "freeToShare": true
                  },
                  {
                    "extension": [
                      {
                        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating",
                        "valueDateTime": item["date-of-first-registry-posting-of-results"]
                      }
                    ],
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": "date-of-first-registry-posting-of-results",
                          "display": "Date of first posting of results in trial registry"
                        }
                      ]
                    },
                    "classifier": [
                      {
                        "coding": item["study-in-registry-coding"],
                        "text": item["study-in-registry-text"]
                      },
                      {
                        "coding": item["results-in-registry-coding"]
                      }
                    ],
                    "freeToShare": true
                  },
                  {
                    "summary": item["notes"],
                    "freeToShare": true
                  }
                ]
              }
            } else {
              let systematicallyDerived = item["systematically-derived"];
              let intendedToGuide = item["intended-to-guide"];
              let incorporatedIntoResults = item["incorporated-into-results"];
              newClassification = {
                "resourceType": "ArtifactAssessment",
                "meta": {
                  "profile": [
                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/classification",
                    "http://hl7.org/fhir/uv/ebm/StructureDefinition/date-as-rating"
                  ]
                },
                "extension": [
                  {
                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-status",
                    "valueCode": "active"
                  },
                  {
                    "url": "http://hl7.org/fhir/StructureDefinition/artifact-author",
                    "valueContactDetail": {
                      "name": globalContext.userState.name
                    }
                  }
                ],
                "title": "DateOfFirstPublication Classification of FOI " + key + " by " + globalContext.userState.name,
                "artifactReference": {
                  "reference": "Citation/" + key,
                  "type": "Citation"
                },
                "content": [
                  {
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": "systematically-derived",
                          "display": "Systematically Derived"
                        }
                      ]
                    },
                    "classifier": [{
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": systematicallyDerived,
                          "display": systematicallyDerived.charAt(0).toUpperCase() + systematicallyDerived.slice(1)
                        }]
                    }],
                    "freeToShare": true
                  },
                  {
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": "intended-to-guide",
                          "display": "Intended to Guide Clinical Practice"
                        }
                      ]
                    },
                    "classifier": [{
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": intendedToGuide,
                          "display": intendedToGuide.charAt(0).toUpperCase() + intendedToGuide.slice(1)
                        }]
                    }],
                    "freeToShare": true
                  },
                  {
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": "incorporated-into-results",
                          "display": "Incorporated Into Results"
                        }
                      ]
                    },
                    "classifier": [{
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": incorporatedIntoResults,
                          "display": incorporatedIntoResults.charAt(0).toUpperCase() + incorporatedIntoResults.slice(1)
                        }]
                    }],
                    "freeToShare": true
                  },
                  {
                    "extension": [
                      {
                        "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating",
                        "valueDateTime": item["date-of-first-publication-of-article"]
                      }
                    ],
                    "type": {
                      "coding": [
                        {
                          "system": "https://fevir.net/resources/CodeSystem/115018",
                          "code": "date-of-first-publication-of-article",
                          "display": "Date of first publication of article"
                        }
                      ]
                    },
                    "freeToShare": true
                  },
                  {
                    "summary": item["notes"],
                    "freeToShare": true
                  }
                ]
              }
            }
            let newClassificationBody = {
              'functionid': 'submitfhirresource',
              'idToken': "",
              'fhirEntry': JSON.stringify(newClassification),
              'status': "active",
              'associatedProjectFoi': projectFOI,
              'aboutformstateid': key,
              'tool': 'ProjectWork' + projectFOI,
              'title': newClassification.title
            }
            let response = await submitToFevirServer(globalContext, 120000, newClassificationBody, false, false);
            if (response?.success) { } else {
              alert("Problem submitting Classification for FOI " + key);
            }
            setDateRatingWorkState(prevState => { return { ...prevState, [key]: { ...prevState[key], exists: true, itemChanged: false } }; });
          }
        }
      }

      //Handle adjudicated stuff
      for (let compositeFOI in compositeResourceChangesState) {
        if (compositeResourceChangesState[compositeFOI]) {
          //MAKE CHANGES TO IT, maybe some server-side calls?
          const compositeUpdateBody = {
            'functionid': 'updatecompositerating',
            'projectFOI': projectFOI,
            'compositeratingfoi': compositeFOI,
            'compositeratingfields': compositeTableState[compositeResourceChangesState[compositeFOI]],
            'idToken': ''
          };
          let response = await submitToFevirServer(globalContext, 10000, compositeUpdateBody, true, false);
          if (response?.success) {
            //
          }
        }
      }

      setSaveChangesState(false);
      setChangeAvailableToSaveState(false);
    } else if (!fhirEntryState.loading) {
      if (fhirEntryState.adminpermission) {
        setRaterTableLoadingState(true);
        let response = await getRaterTableData(targetResources, globalContext, projectFOI);
        if (response?.success && response.targetUserResourceClassificationDictionary) {
          setRaterTableState(response.targetUserResourceClassificationDictionary);
          setCompositeTableState(response.targetUserResourceClassificationComposites);
        }
        setRaterTableLoadingState(false);
      }
      setDataLoadingState(true);
      let response = await getTargetResourceClassificationDictionary(targetResources, globalContext, projectFOI);
      if (response?.success && response.targetResourceClassificationDictionary) {
        setDateRatingWorkState(response.targetResourceClassificationDictionary);
        setDictionaryCreatedState(true);
      }
      setDataLoadingState(false);
    }
  }, [saveChangesState, fhirEntryState.projectResources]);

  let modalContent = <div style={{
    paddingTop: "0px", paddingLeft: "20px", paddingRight: "20px",
    paddingBottom: "0px", width: "100%", height: "100%", overflow: "auto"
  }}>
    {/*<Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
      content="✖"
      onClick={() => {
        setViewMyWorkModalState(prevState => { return { ...prevState, modalOpen: false }; });
      }} />*/}
    <span style={{
      position: "absolute", backgroundColor: "#FFFFFF", width: "calc(90vw - 38px)", zIndex: 100,
      paddingTop: "6px", paddingBottom: "6px"
    }} >
      <Button style={{ color: changeAvailableToSaveState ? "#FFFFFF" : "#000000", width: "230px", float: "left" }}
        className="formButton" disabled={saveChangesState}
        content="Save Changes" positive={changeAvailableToSaveState}
        onClick={() => {
          setSaveChangesState(true);
        }}
      />
      <div style={{ float: "right" }}>
        &nbsp;&nbsp;&nbsp;
        <span style={{ fontSize: "18px" }}><b><span style={{ color: "#555555" }}>{targetFoisInAlphabeticalOrder.length}</span> Items Included</b></span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button style={{ color: "#000000", width: "180px" }} className="formButton"
          content="Close Window"
          onClick={() => {
            setViewMyWorkModalState(prevState => { return { ...prevState, modalOpen: false }; });
          }}
        />
      </div>
    </span>
    <br /><br /><br />
    <div className='viewMyWorkModalTableSection'>
      {dataLoadingState ?
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        :
        <div className='tableFixHead'>
          {parameters?.columnHeaders?.includes("Date Article FIRST Published") ?
            <ViewMyWorkResultsFirstPostedTable parameters={parameters} targetResources={targetResources}
              dateRatingWorkState={dateRatingWorkState} setDateRatingWorkState={setDateRatingWorkState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
            :
            <ViewMyWorkWhenResultsUsedTable parameters={parameters} targetResources={targetResources}
              dateRatingWorkState={dateRatingWorkState} setDateRatingWorkState={setDateRatingWorkState}
              setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
          }

        </div>}
      <br /><br />

      <div>
        {raterTableLoadingState ?
          <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          :
          <>
            {fhirEntryState.adminpermission &&
              <div className='tableFixHead'>
                <Table className='viewmyworkcompositetable'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Study ID</Table.HeaderCell>
                      <Table.HeaderCell>Rater</Table.HeaderCell>
                      <Table.HeaderCell>Date Results First Published</Table.HeaderCell>
                      <Table.HeaderCell>Preprint Y/N</Table.HeaderCell>
                      <Table.HeaderCell>Date Preprint</Table.HeaderCell>
                      <Table.HeaderCell>Registry Y/N</Table.HeaderCell>
                      <Table.HeaderCell>Results in Registry Y/N</Table.HeaderCell>
                      <Table.HeaderCell>Date Results Posted</Table.HeaderCell>
                      <Table.HeaderCell>Notes</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {console.log(raterTableState)}
                    {targetFoisInAlphabeticalOrder.map((targetFOI) => {
                      let targetFOIEntry = raterTableState[targetFOI];

                      return <>
                        {targetFOIEntry?.map((ratingEntry, ratingEntryIndex) => {

                          let preprintCodingDisplay = "";
                          if (ratingEntry["results-in-preprint-coding"] && ratingEntry["results-in-preprint-coding"].length) {
                            preprintCodingDisplay = ratingEntry["results-in-preprint-coding"][0].display;
                          }

                          let studyRegistryCodingDisplay = "";
                          if (ratingEntry["study-in-registry-coding"] && ratingEntry["study-in-registry-coding"].length) {
                            studyRegistryCodingDisplay = ratingEntry["study-in-registry-coding"][0].display;
                          }

                          let resultsRegistryCodingDisplay = "";
                          if ((ratingEntry["results-in-registry-coding"] && ratingEntry["results-in-registry-coding"].length)) {
                            resultsRegistryCodingDisplay = ratingEntry["results-in-registry-coding"][0].display;
                          }

                          return <Table.Row key={ratingEntryIndex}>
                            <Table.Cell>
                              <a href={"/resources/Citation/" + targetFOI.toString()}
                                target="_blank" rel="noopener noreferrer">
                                {ratingEntry.targetTitle}
                              </a>
                            </Table.Cell>
                            <Table.Cell>{ratingEntry["raterName"]}</Table.Cell>
                            <Table.Cell>{ratingEntry["date-of-first-publication-of-article"] && ratingEntry["date-of-first-publication-of-article"]}</Table.Cell>
                            <Table.Cell>{preprintCodingDisplay}</Table.Cell>
                            <Table.Cell>{ratingEntry["date-of-first-preprint-of-article"] && ratingEntry["date-of-first-preprint-of-article"]}</Table.Cell>
                            <Table.Cell>{studyRegistryCodingDisplay}</Table.Cell>
                            <Table.Cell>{resultsRegistryCodingDisplay}</Table.Cell>
                            <Table.Cell>{ratingEntry["date-of-first-registry-posting-of-results"] && ratingEntry["date-of-first-registry-posting-of-results"]}</Table.Cell>
                            <Table.Cell>{ratingEntry.notes}</Table.Cell>
                          </Table.Row>
                        })}
                        {targetFOIEntry?.length > 0 &&
                          <Table.Row className="compositeComparisonRow">
                            <Table.Cell>
                              <a href={"/resources/Citation/" + targetFOI.toString()}
                                target="_blank" rel="noopener noreferrer">
                                {targetFOIEntry[0].targetTitle}
                              </a>
                            </Table.Cell>
                            <Table.Cell><b>Composite</b></Table.Cell>
                            {["date-of-first-publication-of-article", "results-in-preprint-coding", "date-of-first-preprint-of-article", "study-in-registry-coding", "results-in-registry-coding", "date-of-first-registry-posting-of-results"].map((fieldName, fieldNameIndex) => {
                              if (compositeTableState && compositeTableState[targetFOI]) {
                                return <ComparisonCompositeCell key={fieldNameIndex} compositeTableState={compositeTableState} setCompositeTableState={setCompositeTableState} setCompositeResourceChangesState={setCompositeResourceChangesState} targetFOI={targetFOI} fieldName={fieldName} setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
                              } else {
                                return <Table.Cell key={fieldNameIndex}></Table.Cell>
                              }
                            })}
                            <Table.Cell></Table.Cell>
                          </Table.Row>
                        }
                      </>
                    })
                    }
                  </Table.Body>
                </Table>
                <br /><br />
              </div>}
          </>
        }
      </div>
    </div>
  </div>;
  return (
    <Modal
      style={{ padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={viewMyWorkModalState?.modalOpen}
      centered={false}
      content={modalContent}
      className="viewmywork"
    />
  )
})

const ViewMyWork = memo(({ parameters, globalContext, description, label, previousVersionLoaded, fhirEntryState,
  projectFOI }) => {

  if (!parameters) {
    parameters = {};
  }

  const [viewMyWorkModalState, setViewMyWorkModalState] = useState({ "modalOpen": fhirEntryState.viewMyWorkModalOpen ? fhirEntryState.viewMyWorkModalOpen : false });

  return <>
    {viewMyWorkModalState.modalOpen &&
      <ViewMyWorkModal parameters={parameters} globalContext={globalContext} fhirEntryState={fhirEntryState}
        projectFOI={projectFOI}
        viewMyWorkModalState={viewMyWorkModalState} setViewMyWorkModalState={setViewMyWorkModalState} />}
    <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
      {parameters["buttonDisplay"] === "noButton" ?
        <b>{label}</b>
        :
        <Button className="formButton" style={{ color: "#000000", width: "100%" }}
          content={label}
          onClick={() => {
            if (globalContext.userState.id) {
              setViewMyWorkModalState({ "modalOpen": true });
            } else {
              alert("Please login to use this function.");
            }
          }}
          disabled={previousVersionLoaded}
        />
      }
    </td>
    <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
      {description}
    </td>
  </>
})

//----Base Project Action Routing Functions-----------

const Action = ({ parameters, label, functionid, description, projectJson, editMode, fhirEntryState,
  referencedResourceState, setReferencedResourceState, history, provideInputState, setProvideInputState,
  validPatientDataBundleState, setValidPatientDataBundleState, patientDataBundleRadioButtonsState,
  setPatientDataBundleRadioButtonsState, eligibilityCriteriaCheckBoxesState,
  setEligibilityCriteriaCheckBoxesState, loadingPatientDataBundleRadioButtonsState,
  setLoadingPatientDataBundleRadioButtonsState, loadingEligibilityCriteriaCheckBoxesState,
  setLoadingEligibilityCriteriaCheckBoxesState, changeFormState, formInputsStateRef,
  setFhirEntryState }) => {

  let previousVersionLoaded = fhirEntryState?.previousVersionLoaded;
  let projecttitle = projectJson.title || projectJson.name || "[Untitled Project]";

  const globalContext = useContext(FevirContext);
  const { addToast } = useToasts();

  const projectBlankState = { "referenceListCitationsLoading": true };
  let projectStartingView = projectBlankState;
  projectStartingView.startingRelatedArtifactsCount = 0;
  if (projectJson.referenceList) {
    projectStartingView.referenceList = projectJson.referenceList;
  }
  if (typeof projectJson.enableRoBAT === "boolean") {
    projectStartingView.enableRoBAT = projectJson.enableRoBAT;
  }

  const loadAssociatedResource = () => {
    if (fhirEntryState.projectResourcesLoaded && projectState.referenceListCitationsLoading === true) {
      let referenceListCitations = [];
      for (let x in fhirEntryState.projectResources) {
        let resourceEntry = fhirEntryState.projectResources[x];
        if (resourceEntry.resourcetype === "Citation") {
          referenceListCitations.push(resourceEntry);
        }
      }
      setProjectState(prevState => { return { ...prevState, referenceListCitations: referenceListCitations, referenceListCitationsLoading: false }; });
    }
  }

  useEffect(() => {
    loadAssociatedResource();
  }, [fhirEntryState]);

  const [projectState, setProjectState] = useState(projectStartingView);
  const [subjectAndConsentState, setSubjectAndConsentState] = useState({ "modalOpen": false, "page": 0 });

  const loadSubjectAndConsentResource = async (groupFoi, researchStudyFoi) => {
    let groupJson;
    let researchStudyJson;
    try {
      let groupResourceEntry = await getResource(groupFoi, "Group", globalContext.userState.idToken);
      let groupJsonString = groupResourceEntry["fhirResource"];
      groupJson = JSON.parse(groupJsonString);
    } catch (e) {
    }
    setSubjectAndConsentState(prevState => {
      return {
        ...prevState,
        groupResource: groupJson,
        researchStudyResource: researchStudyJson,
      };
    });
  }

  if (!functionid) {
    return <>
      <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
        <b>{label}</b>
      </td>
      <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
        {description}
      </td>
    </>
  }

  switch (functionid) {
    case "addcitation":
      return <AddCitation globalContext={globalContext} fhirEntryState={fhirEntryState} addToast={addToast}
        projectFOI={projectJson.id} />
    case "addresearchsubjectandconsent":
      if (parameters["Group-FOI"] && parameters["ResearchStudy-FOI"] && parameters["document"]) {
        if (subjectAndConsentState.groupResource === undefined) {
          loadSubjectAndConsentResource(parameters["Group-FOI"], parameters["ResearchStudy-FOI"]);
        }
      }
      return <AddResearchSubjectAndConsent parameters={parameters} label={label} description={description}
        globalContext={globalContext} fhirEntryState={fhirEntryState} addToast={addToast}
        subjectAndConsentState={subjectAndConsentState} setSubjectAndConsentState={setSubjectAndConsentState}
        referencedResourceState={referencedResourceState}
        setReferencedResourceState={setReferencedResourceState}
        projectJson={projectJson}
      />
    case "addupdatereferencelist":
      return <AddUpdateReferenceList globalContext={globalContext} fhirEntryState={fhirEntryState} addToast={addToast}
        projectFOI={projectJson.id} projectJson={projectJson} history={history}
        setProjectState={setProjectState}
      />
    case "changebaselinerates":
      return <ChangeBaselineRates parameters={parameters} globalContext={globalContext} fhirEntryState={fhirEntryState}
        projectFOI={projectJson.id}
        description={description} label={label} previousVersionLoaded={previousVersionLoaded} />
    case "checkeligibilitycriteriamatch":
      return <CheckEligibilityCriteriaMatch parameters={parameters} functionid={functionid} globalContext={globalContext}
        fhirEntryState={fhirEntryState} label={label} description={description}
        provideInputState={provideInputState}
        validPatientDataBundleState={validPatientDataBundleState}
        eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
        changeFormState={changeFormState} formInputsStateRef={formInputsStateRef}
        setFhirEntryState={setFhirEntryState} />
    case "checkeligibilitycriteriamatch2":
      return <CheckEligibilityCriteriaMatch parameters={parameters} functionid={functionid} globalContext={globalContext}
        fhirEntryState={fhirEntryState} label={label} description={description} eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
        provideInputState={provideInputState} />
    case "createevidencereportsubject":
      return <CreateEvidenceReportSubject parameters={parameters} label={label} description={description}
        addToast={addToast} projectFOI={projectJson.id} projecttitle={projecttitle}
        previousVersionLoaded={previousVersionLoaded} />
    case "generateevidencereport":
      return <GenerateEvidenceReport parameters={parameters} label={label} description={description}
        projectFOI={projectJson.id} projecttitle={projecttitle}
        previousVersionLoaded={previousVersionLoaded} history={history} />
    case "generateneteffectreport":
      return <GenerateNetEffectReport parameters={parameters} label={label} description={description}
        projectFOI={projectJson.id} projecttitle={projecttitle}
        previousVersionLoaded={previousVersionLoaded} history={history}
        globalContext={globalContext} fhirEntryState={fhirEntryState} />
    case "generatesummaryoffindings":
      return <GenerateSummaryOfFindings label={label} description={description}
        projectFOI={projectJson.id} projecttitle={projecttitle}
        previousVersionLoaded={previousVersionLoaded} history={history}
        globalContext={globalContext} projectResources={fhirEntryState.projectResources} />
    case "gotourl":
      return <GoToUrl parameters={parameters} label={label} description={description}
        previousVersionLoaded={previousVersionLoaded} history={history} />
    case "joinprojectgroup":
      return <JoinProjectGroup parameters={parameters} label={label} description={description}
        projectFOI={projectJson.id}
        globalContext={globalContext} previousVersionLoaded={previousVersionLoaded} />
    case "joinvotingpermissiongroup":
      return <JoinVotingPermissionGroup parameters={parameters} label={label} description={description}
        globalContext={globalContext} previousVersionLoaded={previousVersionLoaded} />
    case "launchquestionnaire":
      return <LaunchQuestionnaire parameters={parameters} functionid={functionid} addToast={addToast} globalContext={globalContext}
        fhirEntryState={fhirEntryState} label={label} description={description} />
    case "launchquestionnaire2":
      return <LaunchQuestionnaire parameters={parameters} functionid={functionid} addToast={addToast} globalContext={globalContext}
        fhirEntryState={fhirEntryState} label={label} description={description} />
    case "provideinput":
      return <ProvideInput parameters={parameters} label={label} description={description}
        globalContext={globalContext} previousVersionLoaded={previousVersionLoaded}
        projectFOI={projectJson.id}
        provideInputState={provideInputState} setProvideInputState={setProvideInputState}
        validPatientDataBundleState={validPatientDataBundleState}
        setValidPatientDataBundleState={setValidPatientDataBundleState}
        patientDataBundleRadioButtonsState={patientDataBundleRadioButtonsState}
        setPatientDataBundleRadioButtonsState={setPatientDataBundleRadioButtonsState}
        eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
        setEligibilityCriteriaCheckBoxesState={setEligibilityCriteriaCheckBoxesState}
        loadingPatientDataBundleRadioButtonsState={loadingPatientDataBundleRadioButtonsState}
        setLoadingPatientDataBundleRadioButtonsState={setLoadingPatientDataBundleRadioButtonsState}
        loadingEligibilityCriteriaCheckBoxesState={loadingEligibilityCriteriaCheckBoxesState}
        setLoadingEligibilityCriteriaCheckBoxesState={setLoadingEligibilityCriteriaCheckBoxesState} />
    case "raterelativeimportance":
      return <RateRelativeImportance parameters={parameters} globalContext={globalContext} fhirEntryState={fhirEntryState}
        projectFOI={projectJson.id}
        description={description} label={label} previousVersionLoaded={previousVersionLoaded} />
    case "selectresources":
      return <SelectResources parameters={parameters} label={label} description={description}
        globalContext={globalContext} previousVersionLoaded={previousVersionLoaded}
        provideInputState={provideInputState} setProvideInputState={setProvideInputState}
        validPatientDataBundleState={validPatientDataBundleState}
        setValidPatientDataBundleState={setValidPatientDataBundleState}
        patientDataBundleRadioButtonsState={patientDataBundleRadioButtonsState}
        setPatientDataBundleRadioButtonsState={setPatientDataBundleRadioButtonsState}
        eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
        setEligibilityCriteriaCheckBoxesState={setEligibilityCriteriaCheckBoxesState}
        loadingPatientDataBundleRadioButtonsState={loadingPatientDataBundleRadioButtonsState}
        setLoadingPatientDataBundleRadioButtonsState={setLoadingPatientDataBundleRadioButtonsState}
        loadingEligibilityCriteriaCheckBoxesState={loadingEligibilityCriteriaCheckBoxesState}
        setLoadingEligibilityCriteriaCheckBoxesState={setLoadingEligibilityCriteriaCheckBoxesState} />
    case "toggleenablerobat":
      return <>
        {projectState.referenceList?.length > 0 &&
          <ToggleEnableRoBAT projectState={projectState} setProjectState={setProjectState}
            fhirEntryState={fhirEntryState} projectFOI={projectJson.id} projectJson={projectJson}
            globalContext={globalContext} history={history} addToast={addToast} />}
      </>
    case "vieweligibilitycriteria":
      return <ViewEligibilityCriteria parameters={parameters} referencedResourceState={referencedResourceState}
        setReferencedResourceState={setReferencedResourceState} globalContext={globalContext}
        description={description} label={label} previousVersionLoaded={previousVersionLoaded} />
    case "viewmywork":
      console.log(JSON.stringify(parameters))
      return <ViewMyWork parameters={parameters} globalContext={globalContext} fhirEntryState={fhirEntryState}
        projectFOI={projectJson.id}
        description={description} label={label} previousVersionLoaded={previousVersionLoaded} />
    case "viewmyworkone":
      return <ViewMyWork parameters={parameters} globalContext={globalContext} fhirEntryState={fhirEntryState}
        projectFOI={projectJson.id}
        description={description} label={label} previousVersionLoaded={previousVersionLoaded} />
    case "viewmyworktwo":
      const viewMyWorkTwoParameters = {
        "targetResourceType": ["Citation"],
        "columnHeaders": [
          "Original Study Publication (click to view in new tab)",
          "Systematically Derived?",
          "Date Results in Preprint (if noted in the article)",
          "Registry Listing and Date Results Posted in Registry", "Notes"
        ],
        "columnValues": [
          "resource.title",
          "systematically-derived",
          "date-of-first-preprint-of-article",
          "date-of-first-registry-posting-of-results", "notes"
        ]
      };
      return <ViewMyWork parameters={viewMyWorkTwoParameters} globalContext={globalContext} fhirEntryState={fhirEntryState}
        projectFOI={projectJson.id}
        description={description} label={label} previousVersionLoaded={previousVersionLoaded} />
    default:
      return <>
        <td style={{ maxWidth: "300px", verticalAlign: "top" }}>
          {parameters["buttonDisplay"] === "noButton"
            ?
            <b>{label}</b>
            :
            <Button className="formButton" style={{ color: "#000000", width: "100%" }} content={label}
              onClick={() => { alert("No action associated with this function: " + functionid); }}
              disabled={previousVersionLoaded}
            />
          }
        </td>
        <td style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}>
          {description}
        </td>
      </>
  }
}

const ProjectActionDisplay = ({ actionJson, projectJson, editMode, fhirEntryState, referencedResourceState,
  setReferencedResourceState, history, provideInputState, setProvideInputState,
  validPatientDataBundleState, setValidPatientDataBundleState, patientDataBundleRadioButtonsState,
  setPatientDataBundleRadioButtonsState, eligibilityCriteriaCheckBoxesState,
  setEligibilityCriteriaCheckBoxesState, loadingPatientDataBundleRadioButtonsState,
  setLoadingPatientDataBundleRadioButtonsState, loadingEligibilityCriteriaCheckBoxesState,
  setLoadingEligibilityCriteriaCheckBoxesState, changeFormState, formInputsStateRef,
  setFhirEntryState }) => {
  let parameters = {};
  for (let parameterIndex in actionJson.parameter) {
    let parameter = actionJson.parameter[parameterIndex];
    if (parameter.element) {
      parameters[parameter.element] = parameter.valueString || parameter.valueArray;
    }
  }
  let label = actionJson.label;
  let functionid = actionJson.function;
  let description = actionJson.description;
  return <Action parameters={parameters} label={label} functionid={functionid} description={description}
    projectJson={projectJson} editMode={editMode} fhirEntryState={fhirEntryState}
    referencedResourceState={referencedResourceState} setReferencedResourceState={setReferencedResourceState}
    history={history} provideInputState={provideInputState}
    setProvideInputState={setProvideInputState}
    validPatientDataBundleState={validPatientDataBundleState}
    setValidPatientDataBundleState={setValidPatientDataBundleState}
    patientDataBundleRadioButtonsState={patientDataBundleRadioButtonsState}
    setPatientDataBundleRadioButtonsState={setPatientDataBundleRadioButtonsState}
    eligibilityCriteriaCheckBoxesState={eligibilityCriteriaCheckBoxesState}
    setEligibilityCriteriaCheckBoxesState={setEligibilityCriteriaCheckBoxesState}
    loadingPatientDataBundleRadioButtonsState={loadingPatientDataBundleRadioButtonsState}
    setLoadingPatientDataBundleRadioButtonsState={setLoadingPatientDataBundleRadioButtonsState}
    loadingEligibilityCriteriaCheckBoxesState={loadingEligibilityCriteriaCheckBoxesState}
    setLoadingEligibilityCriteriaCheckBoxesState={setLoadingEligibilityCriteriaCheckBoxesState}
    changeFormState={changeFormState} formInputsStateRef={formInputsStateRef}
    setFhirEntryState={setFhirEntryState} />
}

export { ProjectActionDisplay };