import React, { useEffect } from 'react';
import { Button, Segment, TextArea } from 'semantic-ui-react';
import "firebase/compat/auth";
import './App.css';

import { SimpleResourceFieldViewer } from './ResourceFunctions';

const ConvertedCTgovPage = ( { useTitle, userState, formState } ) => {

  useTitle("FEvIR to ClinicalTrials.gov Converter");

  //const history = useHistory();

  useEffect( () => {
    //changeFhirEntryState([{"name": userState.name, "role": "Creator"}], "evidenceResourceContributors");
  }, [userState]);

  return (
    <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <h2 style={{textAlign: "center", color: "#AC3B61"}}>Computable Publishing<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: FEvIR-to-ClinicalTrials.gov Converter</h2>
      {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
      <Button content="Copy Converted ClinicalTrials.gov JSON to Clipboard" 
      onClick={() => {navigator.clipboard.writeText(formState.CTgovJSONString)}} />
      {(Array.isArray(formState.warningMessages) && formState.warningMessages.length > 0) &&
        <><h3>Warning Messages:</h3>
        <div style={{paddingLeft: "40px"}}>
          {formState.warningMessages.map((warningMessage, index) => {
            return <span key={index}>{warningMessage}<br/></span>
          })}
        </div>
        </>  
      }
      <br />
      <h3>Tree View</h3>
      {formState.CTgovJSONString && <SimpleResourceFieldViewer resource={JSON.parse(formState.CTgovJSONString)} parentElement={""} />}
      <br/>
      <h3>JSON</h3>
      {formState.CTgovJSONString && <TextArea style={{width: "100%", minHeight: "1000px"}} spellCheck="false" value={formState.CTgovJSONString} readonly />}
      <br/><br/>
      <span>HL7<sup>&#174;</sup>, and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
      </Segment>
    </div>
  );
};

  export default ConvertedCTgovPage;