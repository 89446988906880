import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Segment, Dropdown, Dimmer, Loader, Tab } from 'semantic-ui-react';
import { Checkbox } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getProjectResourcesList } from './ProjectFunctions';
import scrollTo from './ScrollTo';
import FevirContext from './FevirContext';

const KnowledgePortalDemoPage = ( { useTitle, changeFormState } ) => {

    useTitle("FEvIR Knowledge Portal (Demo)");

    let projectId = 27019;

    let singularToPlural = {
        "Recommendation": "Recommendations",
        "Guideline": "Guidelines",
        "Event-Condition-Action Rule": "Event-Condition-Action Rules",
        "Interactive Form": "Interactive Forms",
        "Order Set": "Order Sets",
        "Patient Education": "Patient Education",
        "Decision Aid": "Decision Aids",
        "Other CDS Type": "Other CDS Type",
        "Organized Collection of Guidelines": "Organized Collections of Guidelines",
        "List of Guidelines": "Lists of Guidelines",
        "Single Guideline": "Single Guidelines",
        "Organized Collection of Recommendations": "Organized Collections of Recommendations",
        "List of Recommendations": "Lists of Recommendations",
        "Single Recommendation": "Single Recommendations",
        "Organized Collection of Systematic Reviews": "Organized Collections of Systematic Reviews",
        "List of Systematic Reviews": "Lists of Systematic Reviews",
        "Single Systematic Review": "Single Systematic Reviews",
        "Organized Collection of Studies": "Organized Collections of Studies",
        "List of Studies": "Lists of Studies",
        "Single Study": "Single Studies",
        "Quality Improvement/Innovation": "Quality Improvement & Innovation",
        "Resource Collection": "Resource Collections & Toolkits",
        "Study Protocol": "Planned Studies"
    }

    let headersClassifiersMatch = {
        "Guidance": "Guidance Type",
        "Clinical Decision Support": "Clinical Decision Support Type",
        "Guideline": "Guideline Synthesis Level",        
        "Evidence": "Evidence Synthesis Level",
        "Recommendation": "Recommendation Synthesis Level",
        "Quality Improvement/Innovation": "QI Type",
        "Resource Collections & Toolkits": "Resource Collection"
    };

    let headersAndData = {
        "Guidance": {
            "Clinical Decision Support": {
                "Event-Condition-Action Rules": [],
                "Interactive Forms": [],
                "Order Sets": [],
                "Patient Education": [],
                "Decision Aids": [],
                "Other CDS Type": []
            },
            "Guidelines": {
                "Organized Collections of Guidelines": [],
                "Lists of Guidelines": [],
                "Single Guidelines": []
            },
            "Recommendations": {
                "Organized Collections of Recommendations": [],
                "Lists of Recommendations": [],
                "Single Recommendations": []
            }
        },
        "Evidence": {
            "Organized Collections of Systematic Reviews": [],
            "Lists of Systematic Reviews": [],
            "Single Systematic Reviews": [],
            "Organized Collections of Studies": [],
            "Lists of Studies": [],
            "Single Studies": [],
            "Planned Studies": []
        },
        "Quality Improvement & Innovation": {
            "Quality Measurement": [],
            "Seek Evidence": [],
            "Implement QI": [],
            "Optimize Health IT": [],
            "Create Care Teams": [],
            "Engage with Patients and Families": [],
            "Nurture Leadership": [],
            "Change Management": [],
            "Implementation Tools": []
        },
        "Healthcare Data and Analytics": [],
        "Resource Collections & Toolkits": []
    }

    const createCheckedStructure = (structure) => {
        for (let key in structure) {
            if (Array.isArray(structure[key])) {
                structure[key] = {"CHE-CKED": true};
            } else {
                structure[key] = createCheckedStructure(structure[key]);
                structure[key]["CHE-CKED"] = true;
            }
        }
        return structure
    }
    const globalContext = useContext(FevirContext);

    const [portalHeadersCheckedState, setPortalHeadersCheckedState] = useState(createCheckedStructure(JSON.parse(JSON.stringify(headersAndData))));
    const [portalHeadersExpandState, setPortalHeadersExpandState] = useState(createCheckedStructure(JSON.parse(JSON.stringify(headersAndData))));

    const [portalSearchOptionsState, setPortalSearchOptionsState] = useState([]);

    const [portalSearchState, setPortalSearchState] = useState("");
    const [portalHeadersState, setPortalHeadersState] = useState(headersAndData);
    createCheckedStructure(JSON.parse(JSON.stringify(headersAndData)));
    const [portalLoadingState, setPortalLoadingState] = useState(true);
    const [portalTabIndexState, setPortalTabIndexState] = useState(0);
    const [projectResourcesState, setProjectResourcesState] = useState([]);
  
    const [dateLimitState, setDateLimitState] = useState(false);
    const [startDateState, setStartDateState] = useState();
    const [endDateState, setEndDateState] = useState();

    const readCitations = async (projectResources) => {
        let allTopics = [];
        let allTopicsOptions = [];
        for (let resourceIndex in projectResources) {
            let resource = projectResources[resourceIndex];
            //if (resource.resourcetype === "Citation") { }
            //let citationResponse = await getResource(resource.id, "Citation", userState.idToken);
            //let citationJson = JSON.parse(citationResponse.fhirResource);
            //if (citationResponse) {
            let citationJson = JSON.parse(resource.fhirjsonstring);
            let citationTitle = citationJson.title;
            let citationHyperlink;
            let citationOwner;
            let citationVersion;
            let citationAhrqCreated;
            let citationCallout;
            let citationDescription;
            let citationDate;
            let citationMethodsQuality;
            let citationResultsQuality;
            let citationUserRating;
            let citationTopics = [];
            if (citationJson.citedArtifact) {
                let citedArtifact = citationJson.citedArtifact;
                for (let titleIndex in citedArtifact.title) {
                    let title = citedArtifact.title[titleIndex];
                    let primary = false;
                    if (title.type) {
                        for (let codingIndex in title.type.coding) {
                            let coding = title.type.coding[codingIndex];
                            if (coding.code === "primary") {
                                primary = true;
                                break;
                            }
                        }
                    }
                    if (primary && title.text) {
                        citationTitle = title.text;
                        break;
                    }
                }
                //
                for (let webLocationIndex in citedArtifact.webLocation) {
                    let webLocation = citedArtifact.webLocation[webLocationIndex];
                    if (webLocation.url) {
                        citationHyperlink = webLocation.url;
                        break;
                    }
                }

                for (let abstractIndex in citedArtifact.abstract) {
                    let abstract = citedArtifact.abstract[abstractIndex];
                    if (abstract.text) {
                        if (abstract.type?.text === "Description") {
                            citationDescription = abstract.text;
                            //break;
                        } else {
                            if (citationDescription === undefined) {
                                citationDescription = abstract.text;  
                            }
                        }
                    }
                }
                if (citationDescription === undefined && citationJson.description) {
                    citationDescription = citationJson.description;
                }

                if (citedArtifact.version?.value) {
                    citationVersion = citedArtifact.version.value;
                }
                
                for (let publicationFormIndex in citedArtifact.publicationForm) {
                    let publicationForm = citedArtifact.publicationForm[publicationFormIndex];
                    if (publicationForm.lastRevisionDate) {
                        citationDate = publicationForm.lastRevisionDate;
                        break;
                    } else if (publicationForm.periodicRelease?.dateOfPublication?.date) {
                        citationDate = publicationForm.periodicRelease.dateOfPublication.date;
                        break;
                    } else if (publicationForm.articleDate){
                        citationDate = publicationForm.articleDate;
                        break;
                    }
                }
                if (citationDate === undefined) {
                    if (citationJson.dateApproved || citationJson.approvalDate) {
                        citationDate = citationJson.dateApproved || citationJson.approvalDate;
                    } else if (citationJson.date) {
                        citationDate = citationJson.date;
                    }
                }

                let classificationChain = {};
                for (let classificationIndex in citedArtifact.classification) {
                    let classification = citedArtifact.classification[classificationIndex];
                    let classificationType;
                    let classificationTypeText;
                    let classificationClassifiers = [];
                    if (classification.type) {
                        classificationType = classification.type.text;
                        classificationTypeText = classification.type.text;
                        for (let codingIndex in classification.type.coding) {
                            if (classification.type.coding[codingIndex].code) {
                                classificationType = classification.type.coding[codingIndex].code;
                                if (classificationTypeText === undefined || classificationTypeText === "") {
                                    classificationTypeText = classification.type.coding[codingIndex].display;
                                }
                            } else if (classification.type.coding[codingIndex].display) {
                                classificationType = classification.type.coding[codingIndex].display;
                                if (classificationTypeText === undefined || classificationTypeText === "") {
                                    classificationTypeText = classificationType;
                                }
                            }
                        }
                    }
                    if ((classificationTypeText === undefined || classificationTypeText === "") && classificationType === "knowledge-artifact-type") {
                        classificationTypeText = "Knowledge Artifact Type";
                    }
                    let classifierEntryText;
                    for (let classifierIndex in classification.classifier) {
                        let classifier = classification.classifier[classifierIndex];
                        let classifierEntry = classifier.text;
                        classifierEntryText = classifier.text;
                        for (let codingIndex in classifier.coding) {
                            if (classifier.coding[codingIndex].code) {
                                classifierEntry = classifier.coding[codingIndex].code;
                                if (classifierEntryText === undefined || classifierEntryText === "") {
                                    classifierEntryText = classifier.coding[codingIndex].display;
                                }
                                classificationClassifiers.push(classifierEntryText);
                            } else if (classifier.coding[codingIndex].display) {
                                classifierEntry = classifier.coding[codingIndex].display;
                                if (classifierEntryText === undefined || classifierEntryText === "") {
                                    classifierEntryText = classifierEntry;
                                }
                                classificationClassifiers.push(classifierEntryText);
                            }
                        }
                        if (classifierEntry) {
                            classificationClassifiers.push(classifierEntryText);
                        }
                    }
                    if (classificationTypeText === "Topic") {
                        //classificationChain[classificationTypeText] = {"title": citationTitle, "url": citationHyperlink, "classifiers": classificationClassifiers};
                        citationTopics = classificationClassifiers;
                    } else if (classificationTypeText === "Owner/Steward") {
                        citationOwner = classifierEntryText;
                    } else if (classificationTypeText === "AHRQ-created") {
                        citationAhrqCreated = classifierEntryText;
                    } else if (classificationTypeText === "Callout") {
                        citationCallout = classifierEntryText;
                    } else if (classificationTypeText === "Methods Quality") {
                        citationMethodsQuality = classifierEntryText
                    } else if (classificationTypeText === "Results Quality") {
                        citationResultsQuality = classifierEntryText;
                    } else if (classificationTypeText === "User Rating") {
                        citationUserRating = classifierEntryText;
                    } else {
                        classificationChain[classificationTypeText] = classificationClassifiers;
                    }
                }
                //console.log(assumedPath);
                /*
                console.log(classificationChain);
                const walkThrough = (path, header) => {
                    //console.log(header);
                    if (header === "Topic") {
                        //SHOULD NOT HAPPEN, see IF statement below
                    } else {
                        path.push(header);
                        let nextHeaders = classificationChain[classificationChain[header] + " Type"];
                        if (nextHeaders === undefined) {
                            nextHeaders = classificationChain["Topic"];
                            //console.log(path);
                        } else {
                            for (let headerIndex in nextHeaders) {
                                walkThrough(path, nextHeaders[headerIndex]);
                            }
                        }
                    }
                }
                */
                const walkThrough = (path, displayPath, header) => {
                    let headerForDisplay = header;
                    if (singularToPlural[header]) {
                        headerForDisplay = singularToPlural[header];
                    }
                    if (displayPath[headerForDisplay] === undefined) {
                        //
                    } else {
                        path.push(headerForDisplay);
                        if (headersClassifiersMatch[header] && classificationChain[headersClassifiersMatch[header]]) {
                            for (let classifierIndex in classificationChain[headersClassifiersMatch[header]]) {
                                walkThrough(path, displayPath[headerForDisplay], classificationChain[headersClassifiersMatch[header]][classifierIndex]);
                            }
                        } else {
                            if (Array.isArray(displayPath[headerForDisplay])) {

                                displayPath[headerForDisplay].push({"id": resource.id, "title": citationTitle, "url": citationHyperlink, "description": citationDescription, "date": citationDate, "owner": citationOwner, "version": citationVersion, "ahrqCreated": citationAhrqCreated, "callout": citationCallout, "topics": citationTopics, "methodsQuality": citationMethodsQuality, "resultsQuality": citationResultsQuality, "userRating": citationUserRating});

                                for (let topicIndex in citationTopics) {
                                    let topic = citationTopics[topicIndex];
                                    if (!allTopics.includes(topic)) {
                                        allTopics.push(topic);
                                    }
                                }
                            } else {
                                //
                            }
                        }
                    }
                }
                
                for (let topLevelIndex in classificationChain["Knowledge Artifact Type"]) {
                    //walkThrough([], classificationChain["Knowledge Artifact Type"][topLevelIndex]);
                    let topLevel = classificationChain["Knowledge Artifact Type"][topLevelIndex];
                    walkThrough([], headersAndData, topLevel)
                    
                }
                //
            }
        }
        setPortalHeadersState(headersAndData);
        allTopics.sort();
        for (let topicIndex in allTopics) {
            let topic = allTopics[topicIndex];
            allTopicsOptions.push({"key": topic, "value": topic, "text": topic});
        }
        setPortalSearchOptionsState(allTopicsOptions);
    }
/*
    const ProjectContentDisplay = (margin) => {
        let headers = [];
        for (let header in portalHeadersState) {
            if (Array.isArray(portalHeadersState[header])) {
                headers.push(header);
            } else {
                headers.push(header);
            }
        }
        return <>{headers.map((header, headerIndex) => { return <div key={headerIndex}>
            <h3 style={{margin: "0px"}}>{header}</h3>
            {Array.isArray(portalHeadersState[header]) && <>{portalHeadersState[header].map((entry, entryIndex) => {
                return <div key={entryIndex} style={{margin: "0px", paddingLeft: (margin+40).toString() + "px"}}><a href={entry.url} target="_blank" rel="noopener noreferrer">{entry.title}</a><br/></div>;
            })}</>}
        </div>})}</>;
    }
*/
    const subSubItemCount = (parentEntry) => {
        let count = 0;
        for (let index in parentEntry) {
            let entry = parentEntry[index];
            if (portalSearchState) {
                if (entry.topics.includes(portalSearchState)) {
                    if (filterDateChecker(entry.date) === false) {
                        count += 1;
                    }
                }
            } else {
                if (filterDateChecker(entry.date) === false) {
                    count += 1;
                }
            }
        }
        return count;
    }

    const subItemCount = (parent) => {
        let count = 0;
        for (let key in parent) {
            if (Array.isArray(parent[key])) {
                count += subSubItemCount(parent[key]);
            } else {
                count += subItemCount(parent[key]);
            }
        }
        return count
    }

    const itemCount = (parent) => {
        if (Array.isArray(parent)) {
            return subSubItemCount(parent);
        } else {
            return subItemCount(parent);
        }
    }

    const ItemCountDisplay = ({parent}) => {
        let count = itemCount(parent);
        if (count != 0) {
            //return <span>({count})</span>
            return <><span style={{border: "1px solid #C7C7C7", lineHeight: "0px", padding: "0px", paddingLeft: "3px", paddingRight: "3px", fontSize: "11px"}}>
                    {count}
                </span></>
        } else {
            return <></>
        }
    }

    const getLastPossibleDate = (dateString) => {
        let lastDate;
        let numberOfDashes = dateString.split("-").length - 1;
        let entryDate = new Date(dateString);
        if (numberOfDashes >= 2) {
            lastDate = Date.UTC(entryDate.getUTCFullYear(), entryDate.getUTCMonth(), entryDate.getUTCDate());
        } else if (numberOfDashes == 1) {
            lastDate = Date.UTC(entryDate.getUTCFullYear(), entryDate.getUTCMonth()+1, 0);
        } else if (numberOfDashes == 0) {
            lastDate = Date.UTC(entryDate.getUTCFullYear(), 12, 0);
        }
        return lastDate;
    }

    const filterDateChecker = (dateString) => {
        let hide = false;
        if (dateLimitState) {
            if (dateString) {
                let entryDate = new Date(dateString);
                if (startDateState) {
                    //let simpleDate = Date.UTC(entryDate.getUTCFullYear(), entryDate.getUTCMonth(), entryDate.getUTCDate());
                    let simpleDate = getLastPossibleDate(dateString);
                    let startDate = Date.UTC(startDateState.getUTCFullYear(), startDateState.getUTCMonth(), startDateState.getUTCDate());
                    if (simpleDate < startDate) {
                        hide = true;
                    }
                }
                if (endDateState) {
                    //let simpleDate = getLastPossibleDate(dateString);
                    let simpleDate = Date.UTC(entryDate.getUTCFullYear(), entryDate.getUTCMonth(), entryDate.getUTCDate());
                    let endDate = getLastPossibleDate(endDateState.toISOString());
                    //let endDate = Date.UTC(endDateState.getUTCFullYear(), endDateState.getUTCMonth(), endDateState.getUTCDate());
                    if (simpleDate > endDate) {
                        hide = true;
                    }
                }
            } else {
                hide = true;
            }
        }
        return hide;
    }

    const HeaderEntriesDisplay = ({header}) => {
        return <div>
            {header.map((entry, entryIndex) => {
                let style = {};
                if (portalSearchState && !entry.topics.includes(portalSearchState)) {
                    style["display"] = "none";
                }
                if (filterDateChecker(entry.date)) {
                    style["display"] = "none";
                }
                if (portalTabIndexState == 0) {
                    return <div key={entryIndex} style={style}>
                            <div style={{paddingBottom: "3px", margin: "0px", paddingLeft: "120px"}}>
                                <div style={{paddingBottom: "1px"}}>
                                    <div> {/*style={{borderLeft: "1px inset", paddingLeft: "6px"}}*/}
                                        <a href={entry.url} style={{color: "#3F51B5"}} target="_blank" rel="noopener noreferrer">{entry.title}</a>
                                        {entry.date && <span>&nbsp;{/*<b>Latest date: </b>*/}({entry.date})</span>}
                                        {entry.ahrqCreated?.toLowerCase() === "yes" && <span>
                                                &nbsp;&nbsp;
                                                <img style={{height: '14px' }} src="/ahrq_logo.png" 
                                                alt="AHRQ Created"
                                                title="AHRQ Created"/>
                                            </span>
                                        }
                                        {entry.callout && <span><b><i> {entry.callout}</i></b></span>}
                                    </div>
                                </div>
                                {entryIndex < header.length - 1 && <hr className="separator" style={{margin: "0px", width: "60%"}} />}
                            </div>
                        </div>;
                } else if (portalTabIndexState == 1) {
                    return <div key={entryIndex} style={style}>
                        <div style={{margin: "0px", paddingLeft: "120px"}}>
                        <div style={{borderLeft: "1px inset", borderTop: "1px outset", borderBottom: "1px outset", paddingLeft: "11px", backgroundColor: "#FFFFFF", whiteSpace: "pre-wrap"}}>
                            <span><b><a href={entry.url} style={{color: "#3F51B5"}} target="_blank" rel="noopener noreferrer">{entry.title}</a></b></span>
                            <br/>
                            <span>
                                {entry.owner && <>
                                    <span><b>Source: </b>{entry.owner}</span>
                                </>}
                                <span>{entry.owner ? <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</>: <></>}<b>Latest Date: </b>{entry.date}</span>
                                {entry.version && <span>
                                    {(entry.owner || entry.date) ? <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</>: <></>}
                                    <b>Version:</b> {entry.version}
                                    </span>}
                                {entry.ahrqCreated?.toLowerCase() === "yes" && <span>
                                    {(entry.owner || entry.date || entry.version) ? <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</>: <></>}
                                    <i>AHRQ Created</i>
                                </span>}
                                <br/>
                            </span>
                            {(entry.methodsQuality || entry.resultsQuality || entry.userRating) &&
                                <span>
                                    {entry.methodsQuality && <>
                                        <span><b>Methods Quality: </b>{entry.methodsQuality}</span>
                                    </>}
                                    {entry.resultsQuality && <>
                                        {entry.methodsQuality && <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</>}
                                        <span><b>Results Quality: </b>{entry.resultsQuality}</span>
                                    </>}
                                    {entry.userRating && <>
                                        {entry.resultsQuality && <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</>}
                                        <span><b>User Rating: </b>{entry.userRating}</span>
                                    </>}
                                    <br/>
                                </span>
                            }
                            {/*<span>{entry.description.length < 10000 ? <>{entry.description}</> : <></> }</span>*/}
                            {entry.description && <span><b>Description: </b>{entry.description}<br/></span>}
                            <b><a href={"/resources/Citation/" + entry.id} style={{color: "#3F51B5"}} target="_blank" rel="noopener noreferrer">Resource metadata</a></b>
                            {/*<span><br/><b>Topics: </b>{entry.topics.toString()}</span>*/}
                        </div>
                        {entryIndex < header.length - 1 ? <div style={{margin: "12px"}} /> : <div style={{margin: "10px"}} />}
                        </div>
                    </div>
                }
            })}
        </div>
    }

    const ViewTabPane = () => ( <Tab menu={{ secondary: true, pointing: true }} panes={[ { menuItem: 'List View' }, { menuItem: 'Detail View' } ]} activeIndex={portalTabIndexState} onTabChange={(e, data) => { setPortalTabIndexState(data.activeIndex); } } />);
    
    useEffect(async () => {
        if (dateLimitState) {
            if (startDateState && endDateState) {
                if (endDateState < startDateState) {
                    alert("The end date can't be before the start date.");
                    setDateLimitState(false);
                }
            }
        }
    }, [dateLimitState, startDateState, endDateState]);

    useEffect(async () => {
        changeFormState("Learning Health Systems Portal (Demo)", "pageTitle");
        let projectresources = [];
        projectresources = await getProjectResourcesList(projectId, true, globalContext);
        setProjectResourcesState(projectresources);
        await readCitations(projectresources);
        setPortalLoadingState(false);
    }, []);

    
    //const history = useHistory();

    const years = [1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const AboutPortalButton = () => {
        return (
            <Modal
            trigger={<Button className="formButton" content="About Portal" />}
            header='About the Portal View'
            centered={false}
            content={<div style={{paddingLeft: "20px"}}>
                <br/>
                <p><b>Problem to Solve:</b> A billion bits of knowledge across reliable sources are not discoverable, findable, and understandable in an easy-to-use way.</p>

                <b style={{margin: "0px", padding: "0px"}}>Feature Highlights:</b>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	Knowledge of many types can be searched, filtered, and sorted in one easy-to-use tool. (<i>One-stop shopping</i>)</p>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	Search results are sorted from more-comprehensive to less-comprehensive types of knowledge. (<i>Collected stuff first</i>)</p>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	The view is derived from the available data in computable form. (<i>Instant currency</i>)</p>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	Each entry has a direct link to the knowledge source. (<i>Results only 1 click away</i>)</p>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	Detail View provides many knowledge qualifiers, including source, last revision date, methods quality, results quality, etc. (<i>Select by quality</i>)</p>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	Search by topic and get results specific to your clinical interest. (<i>Choose your interest</i>)</p>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	Quickly select which types of knowledge to include. (<i>Choose your focus</i>)</p>
                <p style={{margin: "0px", padding: "0px", paddingLeft: "20px"}}>•	Shows the number of search results for each knowledge type. (<i>Know what’s there</i>)</p>
                <br/>
            </div>}
            actions={[{ key: 'done', content: 'Close', negative: true }]}
            />
        )
    }

    return (
        <div>
          <div style={{display: "flex", width: "100%"}}>

            <div style={{float: "left", minWidth: "480px", marginRight: "18px"}}><div style={{position: "fixed", width: "480px"}}>
                <Dropdown
                    name="portalsearch"
                    placeholder="Search 🔍"
                    closeOnChange search selection clearable
                    selectOnBlur={false} autoComplete="off"
                    style={{width: "480px"}}
                    options={portalSearchOptionsState}
                    value={portalSearchState}
                    onChange={(e, data) => { setPortalSearchState(data.value); }}
                />
                <br />
                <div className={"unselectable"} style={{display: "flex", whiteSpace:"nowrap", marginTop: "6px", marginBottom: "6px"}}>
                <Checkbox checked={dateLimitState} onChange={(e) => { setDateLimitState(e.target.checked); }} size="small" color="primary" style={{ padding: "0px"}} />
                <div>
                    <b>&nbsp;Limit to after&nbsp;</b>
                    <DatePicker
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div>
                            <b>Start Date</b><br/>
                            <div
                            style={{
                                margin: 0,
                                display: "flex",
                                justifyContent: "center",
                            }}
                            >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                            </button>
                            <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                                ))}
                            </select>

                            <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                                ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                            </button>
                            </div>
                            </div>
                        )}
                        selected={startDateState}
                        selectsStart
                        startDate={startDateState}
                        endDate={endDateState}
                        openToDate={startDateState ? startDateState : new Date()}
                        showPopperArrow={false}
                        isClearable={startDateState && true}
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => setStartDateState(date)}
                    />
                </div>
                <div>
                <b>&nbsp;and before&nbsp;</b>
                <DatePicker
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div>
                        <b>End Date</b><br/>
                        <div
                        style={{
                            margin: 0,
                            display: "flex",
                            justifyContent: "center",
                        }}
                        >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </button>
                        <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </select>

                        <select
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </select>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </button>
                        </div>
                        </div>
                    )}
                    selected={endDateState}
                    selectsEnd
                    startDate={startDateState}
                    endDate={endDateState}
                    openToDate={endDateState ? endDateState : new Date()}
                    popperPlacement="top-end"
                    showPopperArrow={false}
                    isClearable={endDateState && true}
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => setEndDateState(date)}
                />
                </div>
                </div>
                <Segment className={`containerSegment maxPortalNavigationSegment`} style={{marginTop: "4px", paddingTop: "2px", paddingBottom: "2px"}} raised>

                    {Object.keys(portalHeadersState).map((topKey, topKeyIndex) => {
                        let containsContent = true;
                        if (!portalHeadersCheckedState[topKey]["CHE-CKED"] || (Array.isArray(portalHeadersState[topKey]) && itemCount(portalHeadersState[topKey]) == 0)) {
                            containsContent = false;
                        }
                        let headerId = topKey;
                        if (topKeyIndex == 0) {
                            headerId = "";
                        }
                        return <span className="unselectable" key={topKeyIndex}>
                            <b style={{margin: "0px"}}>
                                <Checkbox checked={portalHeadersCheckedState[topKey]["CHE-CKED"]} onChange={(e) => { setPortalHeadersCheckedState(prevState => { let newObj = prevState[topKey]; newObj["CHE-CKED"] = e.target.checked; return { ...prevState, [topKey]: newObj } }) }} size="small" color="primary" style={{ padding: "0px"}} />
                                &nbsp;
                                {containsContent ? <span style={{color: "#3F51B5", cursor: "pointer"}} onClick={() => scrollTo("portalViewSegment", headerId, true)}>{topKey}</span> : <>{topKey}</>}
                                </b>&nbsp;
                                { !Array.isArray(portalHeadersState[topKey]) ?
                                    <span style={{cursor: "pointer"}} onClick={() => { setPortalHeadersExpandState(prevState => { let newObj = prevState[topKey]; newObj["CHE-CKED"] = !newObj["CHE-CKED"]; return { ...prevState, [topKey]: newObj } }) }}>
                                        {portalHeadersExpandState[topKey]["CHE-CKED"] ?
                                            <>▼</>
                                        :
                                            <>►&nbsp;<ItemCountDisplay parent={portalHeadersState[topKey]} /></>
                                        }
                                        </span>
                                :
                                    <ItemCountDisplay parent={portalHeadersState[topKey]} />
                                }
                            <br/>
                            { (!Array.isArray(portalHeadersState[topKey]) && portalHeadersExpandState[topKey]["CHE-CKED"]) &&
                                    <>
                                    {Object.keys(portalHeadersState[topKey]).map((secondKey, secondKeyIndex) => {
                                        let containsContent = true;
                                        if (!(portalHeadersCheckedState[topKey]["CHE-CKED"] && portalHeadersCheckedState[topKey][secondKey]["CHE-CKED"]) || (Array.isArray(portalHeadersState[topKey][secondKey]) && itemCount(portalHeadersState[topKey][secondKey]) == 0)) {
                                            containsContent = false;
                                        }          
                                        return <span className="unselectable" key={secondKeyIndex}>
                                            <><b style={{margin: "0px", paddingLeft: "40px"}}>
                                                <Checkbox checked={portalHeadersCheckedState[topKey][secondKey]["CHE-CKED"] && portalHeadersCheckedState[topKey]["CHE-CKED"]} onChange={(e) => { setPortalHeadersCheckedState(prevState => { let newObj = prevState[topKey]; if (newObj["CHE-CKED"] === false) { newObj["CHE-CKED"] = true; for (let siblingKey in portalHeadersState[topKey]) {newObj[siblingKey]["CHE-CKED"] = false;}} newObj[secondKey]["CHE-CKED"] = e.target.checked; return { ...prevState, [topKey]: newObj } }) }} size="small" color="primary" style={{ padding: "0px"}} />
                                                &nbsp;
                                                {containsContent ? <span style={{color: "#3F51B5", cursor: "pointer"}} onClick={() => scrollTo("portalViewSegment", topKey+"."+secondKey, true)}>{secondKey}</span> : <>{secondKey}</>}
                                                </b>&nbsp;
                                                { !Array.isArray(portalHeadersState[topKey][secondKey]) ?
                                                    <span style={{cursor: "pointer"}} onClick={() => { setPortalHeadersExpandState(prevState => { let newObj = prevState[topKey]; newObj[secondKey]["CHE-CKED"] = !newObj[secondKey]["CHE-CKED"]; return { ...prevState, [topKey]: newObj } }) }}>
                                                        {portalHeadersExpandState[topKey][secondKey]["CHE-CKED"] ?
                                                            <>▼</>
                                                        :
                                                            <>►&nbsp;<ItemCountDisplay parent={portalHeadersState[topKey][secondKey]} /></>
                                                        }
                                                    </span>
                                                :
                                                    <ItemCountDisplay parent={portalHeadersState[topKey][secondKey]} />
                                                }
                                                <br/></>
                                                { (!Array.isArray(portalHeadersState[topKey][secondKey]) && portalHeadersExpandState[topKey][secondKey]["CHE-CKED"]) &&
                                                    <>
                                                    {Object.keys(portalHeadersState[topKey][secondKey]).map((key, keyIndex) => {
                                                        let containsContent = true;
                                                        if (!(portalHeadersCheckedState[topKey]["CHE-CKED"] && portalHeadersCheckedState[topKey][secondKey]["CHE-CKED"] && portalHeadersCheckedState[topKey][secondKey][key]["CHE-CKED"]) || (Array.isArray(portalHeadersState[topKey][secondKey][key])  && itemCount(portalHeadersState[topKey][secondKey][key]) == 0)) {
                                                            containsContent = false;
                                                        }   
                                                        return <span className="unselectable" key={keyIndex}>
                                                                {Array.isArray(portalHeadersState[topKey][secondKey][key]) &&
                                                                    <>
                                                                        <b style={{margin: "0px", paddingLeft: "80px"}}>
                                                                            <Checkbox checked={portalHeadersCheckedState[topKey][secondKey][key]["CHE-CKED"] && portalHeadersCheckedState[topKey][secondKey]["CHE-CKED"] && portalHeadersCheckedState[topKey]["CHE-CKED"]} onChange={(e) => { setPortalHeadersCheckedState(prevState => { let newObj = prevState[topKey]; if (newObj["CHE-CKED"] === false) { newObj["CHE-CKED"] = true; for (let siblingKey in portalHeadersState[topKey]) {newObj[siblingKey]["CHE-CKED"] = false;}} if (newObj[secondKey]["CHE-CKED"] === false) { newObj[secondKey]["CHE-CKED"] = true; for (let siblingKey in portalHeadersState[topKey][secondKey]) {newObj[secondKey][siblingKey]["CHE-CKED"] = false;}} newObj[secondKey][key]["CHE-CKED"] = e.target.checked; return { ...prevState, [topKey]: newObj } }) }} size="small" color="primary" style={{ padding: "0px"}} />
                                                                            &nbsp;
                                                                            {containsContent ? <span style={{color: "#3F51B5", cursor: "pointer"}} onClick={() => scrollTo("portalViewSegment", topKey+"."+secondKey+"."+key, true)}>{key}</span> : <>{key}</>}
                                                                            </b>&nbsp;
                                                                            { !Array.isArray(portalHeadersState[topKey][secondKey][key]) ?
                                                                                <span>▼</span>
                                                                            :
                                                                                <ItemCountDisplay parent={portalHeadersState[topKey][secondKey][key]} />
                                                                            }
                                                                        <br />
                                                                    </>
                                                                }
                                                            </span>
                                                    })}
                                                    </>
                                                }
                                        </span>
                                    })}
                                </>
                            }
                        </span>
                    })}

                    <div className={`navigationLinksSection`} style={{paddingTop: "4px"}}>
                        
                    {/*elements.map((element, index) => {
                        let id = "";
                        if (index != 0) {
                            id = element.toLowerCase().replace(/\s/g, "-");
                        }
                        return <span key={index}>
                                <a onClick={() => segmentScrollTo(id, true)} >{element}</a>
                                {index !== elements.length-1 && <><br/><div style={{height: "4px"}}></div></>}
                            </span>
                    })*/}
                    </div>
                </Segment>
                </div>
            </div>

            <div style={{float: "right", width: "100%"}}>
                <Segment id="portalViewSegment" name="portalViewSegment" className={`containerSegment remainderOfPageSegment`} raised>  
                    <>
                        {portalLoadingState &&
                        <Dimmer className={"loadingDimmer"} active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        }
                    </>                  
                    <ViewTabPane />
                    {(portalTabIndexState == 0 || portalTabIndexState == 1) &&
                        <div style={{paddingTop: "12px"}}>
                            {Object.keys(portalHeadersState).map((topKey, topKeyIndex) => {
                                let style = {};
                                if (!(portalHeadersCheckedState[topKey]["CHE-CKED"])){
                                    style["display"] = "none";
                                }
                                let headerId = topKey;
                                if (topKeyIndex == 0) {
                                    headerId = "";
                                }
                                return <span id={headerId} key={topKeyIndex} style={style}>
                                    <h3 style={{margin: "0px", marginBottom: "1px"}}>{topKey}{(Array.isArray(portalHeadersState[topKey]) && portalHeadersState[topKey].length !== 0) && <span style={{fontWeight:"normal", fontSize: "11px"}}>&nbsp;&nbsp;<ItemCountDisplay parent={portalHeadersState[topKey]} /></span>}</h3>
                                    { !Array.isArray(portalHeadersState[topKey]) ?
                                            <>
                                            {Object.keys(portalHeadersState[topKey]).map((secondKey, secondKeyIndex) => {        
                                                let style = {};
                                                if (!(portalHeadersCheckedState[topKey]["CHE-CKED"] && portalHeadersCheckedState[topKey][secondKey]["CHE-CKED"])){
                                                    style["display"] = "none";
                                                }                 
                                                return <span id={topKey+"."+secondKey} key={secondKeyIndex} style={style}>
                                                    <h4 style={{margin: "0px", marginBottom: "1px", paddingLeft: "40px"}}>{secondKey}{(Array.isArray(portalHeadersState[topKey][secondKey]) && portalHeadersState[topKey][secondKey].length !== 0) && <span style={{fontWeight:"normal", fontSize: "11px"}}>&nbsp;&nbsp;<ItemCountDisplay parent={portalHeadersState[topKey][secondKey]} /></span>}</h4>
                                                    { !Array.isArray(portalHeadersState[topKey][secondKey]) ?
                                                        <>
                                                            {Object.keys(portalHeadersState[topKey][secondKey]).map((key, keyIndex) => {
                                                            let style = {};
                                                            if (!(portalHeadersCheckedState[topKey]["CHE-CKED"] && portalHeadersCheckedState[topKey][secondKey]["CHE-CKED"] && portalHeadersCheckedState[topKey][secondKey][key]["CHE-CKED"])){
                                                                style["display"] = "none";
                                                            }
                                                            return <span key={keyIndex} style={style}>
                                                                    <h5 style={{margin: "0px", marginBottom: "1px", paddingLeft: "80px"}} id={topKey+"."+secondKey+"."+key}>{key}{(Array.isArray(portalHeadersState[topKey][secondKey][key]) && portalHeadersState[topKey][secondKey][key].length !== 0) && <span style={{fontWeight:"normal", fontSize: "11px"}}>&nbsp;&nbsp;<ItemCountDisplay parent={portalHeadersState[topKey][secondKey][key]} /></span>}</h5>
                                                                    <HeaderEntriesDisplay header={portalHeadersState[topKey][secondKey][key]} />
                                                                </span>
                                                            })}
                                                        </>
                                                    :
                                                        <>
                                                            <HeaderEntriesDisplay header={portalHeadersState[topKey][secondKey]} />
                                                        </>
                                                    }
                                                </span>
                                            })}
                                            </>
                                        :
                                            <HeaderEntriesDisplay header={portalHeadersState[topKey]} />
                                    }
                                </span>
                            })}
                        </div>
                    }
                    <span style={{position: "absolute", right: "3px", top: "3px"}}>
                        <AboutPortalButton />
                        {/*}
                        <span style={{padding: "2px"}} />
                        <Button className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />
                        */}
                    </span>

                </Segment>
            </div>
        </div>
    </div>
    );
};

export default KnowledgePortalDemoPage;