import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import "firebase/compat/auth";
import { DisplayFromFHIR, getCodeDisplayOrText, ElementNote, getStringFromFHIR } from './ResourceFunctions';
import { CertaintyRows } from './EvidenceFunctions';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';
import EvidenceVariableCriteriaDisplay from './EvidenceVariableCriteriaDisplay';
import getVariableDefinitionResources from './getVariableDefinitionResources';
import ResourceBottomDisplay from './ResourceBottomDisplay';

import './App.css';
import './Table.css';

const ModelCharacteristicDisplay = ({ modelCharactersticInstance }) => {
    let modelVariable = <></>;
    let modelVariableFound = false;
    if (modelCharactersticInstance.variable) {
        modelVariable = <>Adjusted for:<ul style={{ marginTop: "0px" }}>{modelCharactersticInstance.variable.map((variable, variableIndex) => {
            if (variable.variableDefinition) {
                modelVariableFound = true;
                let includeIfFound = false;
                let includeIfString;
                if (variable.extension?.length > 0) {
                    for (const extension of variable.extension) {
                        if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if" ||
                            extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if") {
                            includeIfFound = true;
                            includeIfString = " {{ include if ";
                            for (const ext of extension.extension) {
                                if (ext.url === "attribute") {
                                    includeIfString += getStringFromFHIR.CodeableConcept(ext.valueCodeableConcept) || "";
                                    includeIfString += " IS "
                                } else if (ext.url === "value") {
                                    if (typeof ext.valueBoolean === 'boolean') {
                                        includeIfString += getStringFromFHIR.boolean(ext.valueBoolean);
                                    } else if (ext.valueCodeableConcept) {
                                        includeIfString += getStringFromFHIR.CodeableConcept(ext.valueCodeableConcept);
                                    } else if (ext.valueQuantity) {
                                        includeIfString += getStringFromFHIR.Quantity(ext.valueQuantity);
                                    } else if (ext.valueRange) {
                                        includeIfString += getStringFromFHIR.Range(ext.valueRange);
                                    } else if (ext.valueExpression) {
                                        includeIfString += ext.valueExpression.name + " " + ext.valueExpression.description + " " + ext.valueExpression.expression;
                                    }
                                }
                            }
                            includeIfString += " }}";
                        }
                    }
                }
                return <li key={variableIndex}>{variable.variableDefinition.display && <>{variable.variableDefinition.display}</>} {variable.handling && <>({variable.handling})</>} {includeIfFound && <>{includeIfString}</>}</li>
            } else {
                return <></>
            }
        })}</ul></>
    }
    let modelAttributeEstimate = <></>;
    let modelAttributeEstimateFound = false;
    if (modelCharactersticInstance.attribute || modelCharactersticInstance.attributeEstimate) {
        modelAttributeEstimate = <><p>Placeholder -- this model characteristic contains one or more attribute estimates</p></>;
        modelAttributeEstimateFound = true;
    }
    let includeIfFound = false;
    let includeIfExtension;
    if (modelCharactersticInstance.extension?.length > 0) {
        for (const extension of modelCharactersticInstance.extension) {
            if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if" ||
                extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/statistic-model-include-if") {
                includeIfFound = true;
                includeIfExtension = {};
                for (const ext of extension.extension) {
                    if (ext.url === "attribute") {
                        includeIfExtension["attribute"] = ext.valueCodeableConcept || "";
                    } else if (ext.url === "value") {
                        if (typeof ext.valueBoolean === 'boolean') {
                            includeIfExtension["valueBoolean"] = ext.valueBoolean;
                        } else if (ext.valueCodeableConcept) {
                            includeIfExtension["valueCodeableConcept"] = ext.valueCodeableConcept;
                        } else if (ext.valueQuantity) {
                            includeIfExtension["valueQuantity"] = ext.valueQuantity;
                        } else if (ext.valueRange) {
                            includeIfExtension["valueRange"] = ext.valueRange;
                        } else if (ext.valueExpression) {
                            includeIfExtension["valueExpression"] = ext.valueExpression;
                        }
                    }
                }
            }
        }
    }
    return <>
        {includeIfFound && <div>
            <b>Include if: </b>
            <div style={{ marginLeft: "24px" }}>
                <div>
                    <b>Attribute: </b>
                    <DisplayFromFHIR codeableConcept={includeIfExtension.attribute} />
                </div>
                <div>
                    <b>Value: </b>
                    {typeof includeIfExtension.valueBoolean === 'boolean' &&
                        getStringFromFHIR.boolean(includeIfExtension.valueBoolean)}
                    {includeIfExtension.valueCodeableConcept &&
                        <DisplayFromFHIR codeableConcept={includeIfExtension.valueCodeableConcept} />}
                    {includeIfExtension.valueQuantity &&
                        getStringFromFHIR.Quantity(includeIfExtension.valueQuantity)}
                    {includeIfExtension.valueRange &&
                        getStringFromFHIR.Range(includeIfExtension.valueRange)}
                    {includeIfExtension.valueExpression &&
                        <DisplayFromFHIR expression={includeIfExtension.valueExpression} />}
                </div>
            </div>
        </div>}
        {modelCharactersticInstance.conditionCodeableConcept && <span>
            <b>If this condition is met: </b>
            <DisplayFromFHIR codeableConcept={modelCharactersticInstance.conditionCodeableConcept} />
        </span>}
        {modelCharactersticInstance.conditionExpression && <span>
            <b>If this condition is met: </b>
            <DisplayFromFHIR expression={modelCharactersticInstance.conditionExpression} />
        </span>}
        {modelCharactersticInstance.code && <span>
            <br />
            <b>Code: </b>
            <DisplayFromFHIR codeableConcept={modelCharactersticInstance.code} />
        </span>}
        {modelCharactersticInstance.value && <span>
            <br />
            <b>Value: </b>
            {getStringFromFHIR.Quantity(modelCharactersticInstance.value)}
        </span>}
        {modelCharactersticInstance.valueQuantity && <span>
            <br />
            <b>Value: </b>
            {getStringFromFHIR.Quantity(modelCharactersticInstance.valueQuantity)}
        </span>}
        {modelCharactersticInstance.valueRange && <span>
            <br />
            <b>Value: </b>
            {getStringFromFHIR.Range(modelCharactersticInstance.valueRange)}
        </span>}
        {modelCharactersticInstance.valueCodeableConcept && <span>
            <br />
            <b>Value: </b>
            <DisplayFromFHIR codeableConcept={modelCharactersticInstance.valueCodeableConcept} />
        </span>}
        {modelCharactersticInstance.intended && <p><b>Intended: </b>True</p>}
        {modelCharactersticInstance.applied && <p><b>Applied: </b>True</p>}
        {modelVariableFound && modelVariable}
        {modelAttributeEstimateFound && modelAttributeEstimate}
        {(Array.isArray(modelCharactersticInstance.modelCharacteristic) &&
            modelCharactersticInstance.modelCharacteristic.length > 0) &&
            modelCharactersticInstance.modelCharacteristic.map((item, itemIndex) => {
                return <div key={itemIndex}>
                    <p><b>Model Characteristic {itemIndex + 1} of {modelCharactersticInstance.modelCharacteristic.length}</b></p>
                    <ModelCharacteristicDisplay modelCharactersticInstance={item} />
                </div>
            })}
    </>
}

const EvidenceDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

    const [variableDefinitionMap, setVariableDefinitionMap] = useState({});

    const setVDMap = async () => {
        setVariableDefinitionMap((await getVariableDefinitionResources(fhirJson)) || {});
    };

    useEffect(() => {
        setVDMap();
    }, [])

    let howToCite;
    if (Array.isArray(fhirJson.relatedArtifact)) {
        for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
            if (relatedArtifactEntry.type === "cite-as") {
                howToCite = relatedArtifactEntry.citation;
                break;
            }
        }
    }

    let synthesisType = getCodeDisplayOrText(fhirJson.synthesisType);
    let studyType = getCodeDisplayOrText(fhirJson.studyType);
    let studyDesignList = [];
    if (fhirJson.studyDesign?.length >= 0) {
        for (let studyDesignEntry in fhirJson.studyDesign) {
            studyDesignList.push(getCodeDisplayOrText(fhirJson.studyDesign[studyDesignEntry]));
        }
    }
    let studyDesign = studyDesignList.join('; ') || studyType || "";

    let dataSourceList = [];
    let dataSource = "";
    let dataSourceURL = "";
    for (let relatedArtifactIndex in fhirJson.relatedArtifact) {
        let relatedArtifact = fhirJson.relatedArtifact[relatedArtifactIndex];
        if (relatedArtifact.label === "data source") {
            if (relatedArtifact.display) {
                dataSource = '{' + relatedArtifact.display + '}: ';
            }
            if (relatedArtifact.citation) {
                dataSource += relatedArtifact.citation;
            }
            if (relatedArtifact.url) {
                dataSourceURL = relatedArtifact.url;
            }
        }
        if (relatedArtifact.type === "derived-from") {
            dataSourceList.push(relatedArtifact);
        }
    }
    return <div>
        <div style={{ marginTop: "12px" }}>
            <h3 id="summary">Summary</h3>
            <div style={{ marginLeft: "24px" }}>
                <><b>Title: </b> {fhirJson.title}</>
                <br /><br />
                <b>Description: </b> <span style={{ whiteSpace: "pre-wrap" }}><DisplayFromFHIR markdown={fhirJson.description} /></span>
                <br /><br />
                {fhirJson.assertion && <><b>Assertion: </b> <DisplayFromFHIR markdown={fhirJson.assertion} /><br /><br /></>}
                {Array.isArray(fhirJson.note) &&
                    fhirJson.note.map((note, noteIndex) => { return <p key={noteIndex}><b>Note: </b> <DisplayFromFHIR annotation={note} /></p> })}
                {dataSource && <p><b>Data Source: </b>{dataSource} {dataSourceURL && <>(<a href={dataSourceURL} target="_blank" rel="noopener noreferrer">{dataSourceURL}</a>)</>}</p>}
                {dataSourceList.length > 0 && <>
                    <p><b>Data Source(s): </b></p>
                    {dataSourceList.map((source, sourceIndex) => {
                        return <div key="sourceIndex">
                            <DisplayFromFHIR relatedArtifact={source} />
                            <br />
                        </div>
                    })}</>
                }
                {(fhirJson.text?.div && fhirJson.text.status && fhirJson.text.status !== "empty") && <>
                    <h4>Narrative Summary:</h4>
                    <div style={{ marginLeft: "24px" }}>
                        <DisplayFromFHIR xhtml={fhirJson.text.div} />
                    </div>
                </>}
            </div>
            <h3 id="population">Population</h3>
            <div style={{ marginLeft: "24px" }}>
                <br />
                {variableDefinitionMap.population && <>
                    {variableDefinitionMap.population.description && <><b>Description: </b><DisplayFromFHIR markdown={variableDefinitionMap.population.description} /><br /></>}
                    {variableDefinitionMap.population.roleSubtype && <><b>Variable Role Subtype: </b><DisplayFromFHIR codeableConcept={variableDefinitionMap.population.roleSubtype} /><br /></>}
                    {variableDefinitionMap.population.directnessMatch?.coding?.length > 0 && <><b>Directness match: </b>{variableDefinitionMap.population.directnessMatch.coding[0].display}<br /><br /></>}
                    <ElementNote element={variableDefinitionMap.population} />
                    {variableDefinitionMap.population.observed && <>
                        <b>Observed Population</b>
                        {variableDefinitionMap.populationObservedResource?.resourceType &&
                            <>
                                <div style={{ marginLeft: "24px" }}><p><b></b> {variableDefinitionMap.populationObservedResource.title || variableDefinitionMap.populationObservedResource.name || ""}</p></div>
                                {variableDefinitionMap.populationObservedResource.resourceType === "Group" ?
                                    <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.populationObservedResource} />
                                    :
                                    <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.populationObservedResource} />
                                }
                                <br />
                            </>}
                        <div style={{ marginLeft: "24px" }}>
                            <b>Resource Reference</b>
                            <div style={{ marginLeft: "24px" }}>
                                {/*variableDefinitionMap.population.observed.display && <span>{variableDefinitionMap.population.observed.display}<br /></span>*/}
                                <DisplayFromFHIR reference={variableDefinitionMap.population.observed} />
                                {/*variableDefinitionMap.populationObservedReference && <span><a href={"/resources/" + variableDefinitionMap.populationObservedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.populationObservedReference}</b></a></span>*/}
                            </div>
                        </div>
                    </>}
                    {variableDefinitionMap.population.intended && <>
                        {variableDefinitionMap.population.observed && <br />}
                        <b>Intended Population</b>
                        {variableDefinitionMap.populationIntendedResource?.resourceType &&
                            <>
                                <div style={{ marginLeft: "24px" }}><p> {variableDefinitionMap.populationIntendedResource.title || variableDefinitionMap.populationIntendedResource.name || ""}</p></div>
                                {variableDefinitionMap.populationIntendedResource.resourceType === "Group" ?
                                    <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.populationIntendedResource} />
                                    :
                                    <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.populationIntendedResource} />
                                }
                                <br />
                            </>}
                        <div style={{ marginLeft: "24px" }}>
                            <b>Resource Reference</b>
                            <div style={{ marginLeft: "24px" }}>
                                {/*variableDefinitionMap.population.intended.display && <span>{variableDefinitionMap.population.intended.display}<br /></span>*/}
                                <DisplayFromFHIR reference={variableDefinitionMap.population.intended} />
                                {/*variableDefinitionMap.populationIntendedReference && <span><a href={"/resources/" + variableDefinitionMap.populationIntendedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.populationIntendedReference}</b></a></span>*/}
                            </div>
                        </div>
                    </>}
                </>}
                <br />
            </div>
            <h3 id="exposures">Exposures</h3>
            <div style={{ marginLeft: "24px" }}>
                {variableDefinitionMap.exposure && <>
                    <b>Exposure</b>
                    <div style={{ marginLeft: "24px" }}>
                        {variableDefinitionMap.exposure.description && <><b>Description: </b><DisplayFromFHIR markdown={variableDefinitionMap.exposure.description} /><br /></>}
                        {variableDefinitionMap.exposure.roleSubtype && <><b>Variable Role Subtype: </b><DisplayFromFHIR codeableConcept={variableDefinitionMap.exposure.roleSubtype} /><br /></>}
                        {(variableDefinitionMap.exposure.directnessMatch?.coding && variableDefinitionMap.exposure.directnessMatch?.coding[0]) && <><b>Directness match: </b>{variableDefinitionMap.exposure.directnessMatch.coding[0].display}<br /><br /></>}
                        <ElementNote element={variableDefinitionMap.exposure} />
                        {variableDefinitionMap.exposure.observed && <>
                            <b>Observed Exposure</b>
                            {variableDefinitionMap.exposureObservedResource?.resourceType &&
                                <>
                                    <div style={{ marginLeft: "24px" }}><p><b></b> {variableDefinitionMap.exposureObservedResource.title || variableDefinitionMap.exposureObservedResource.name || ""}</p></div>
                                    {variableDefinitionMap.exposureObservedResource.resourceType === "Group" ?
                                        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.exposureObservedResource} />
                                        :
                                        <>
                                            {variableDefinitionMap.exposureObservedResource.handling && <div style={{ marginLeft: "24px" }}><b>Handling:</b> {variableDefinitionMap.exposureObservedResource.handling}<br /></div>}
                                            {variableDefinitionMap.exposureObservedResource.category &&
                                                <div style={{ marginLeft: "24px" }}>
                                                    <b>Categories:</b>
                                                    <br />
                                                    {variableDefinitionMap.exposureObservedResource.category.map((categoryInstance, index) => {
                                                        return <div key={index}>
                                                            <b>o </b>{categoryInstance.name}
                                                        </div>
                                                    })}
                                                    <br />
                                                </div>}
                                            <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.exposureObservedResource} />
                                        </>
                                    }
                                    <br />
                                </>}
                            <div style={{ marginLeft: "24px" }}>
                                <b>Resource Reference</b>
                                <div style={{ marginLeft: "24px" }}>
                                    {/*variableDefinitionMap.exposure.observed.display && <span>{variableDefinitionMap.exposure.observed.display}<br /></span>*/}
                                    <DisplayFromFHIR reference={variableDefinitionMap.exposure.observed} />
                                    {/*variableDefinitionMap.exposureObservedReference && <span><a href={"/resources/" + variableDefinitionMap.exposureObservedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.exposureObservedReference}</b></a></span>*/}
                                </div>
                            </div>
                        </>}
                        {variableDefinitionMap.exposure.intended && <>
                            {variableDefinitionMap.exposure.observed && <br />}
                            <b>Intended Exposure</b>
                            {variableDefinitionMap.exposureIntendedResource?.resourceType &&
                                <>
                                    <div style={{ marginLeft: "24px" }}><p><b></b> {variableDefinitionMap.exposureIntendedResource.title || variableDefinitionMap.exposureIntendedResource.name || ""}</p></div>
                                    {variableDefinitionMap.exposureIntendedResource.resourceType === "Group" ?
                                        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.exposureIntendedResource} />
                                        :
                                        <>
                                            {variableDefinitionMap.exposureIntendedResource.handling && <div style={{ marginLeft: "24px" }}><b>Handling:</b> {variableDefinitionMap.exposureIntendedResource.handling}<br /></div>}
                                            {variableDefinitionMap.exposureIntendedResource.category &&
                                                <div style={{ marginLeft: "24px" }}>
                                                    <b>Categories:</b>
                                                    <br />
                                                    {variableDefinitionMap.exposureIntendedResource.category.map((categoryInstance, index) => {
                                                        return <div key={index}>
                                                            <b>o </b>{categoryInstance.name}
                                                        </div>
                                                    })}
                                                    <br />
                                                </div>}
                                            <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.exposureIntendedResource} />
                                        </>
                                    }
                                    <br />
                                </>}
                            <div style={{ marginLeft: "24px" }}>
                                <b>Resource Reference</b>
                                <div style={{ marginLeft: "24px" }}>
                                    {/*variableDefinitionMap.exposure.intended.display && <span>{variableDefinitionMap.exposure.intended.display}<br /></span>*/}
                                    <DisplayFromFHIR reference={variableDefinitionMap.exposure.intended} />
                                    {/*variableDefinitionMap.exposureIntendedReference && <span><a href={"/resources/" + variableDefinitionMap.exposureIntendedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.exposureIntendedReference}</b></a></span>*/}
                                </div>
                            </div>
                        </>}
                    </div>
                    {variableDefinitionMap.exposure.comparatorCategory && <><br />
                        <b>Control Exposure</b>
                        <div style={{ marginLeft: "24px" }}>
                            {variableDefinitionMap.exposure.comparatorCategory}
                        </div>
                    </>}
                </>}
                {variableDefinitionMap.referenceExposure && <><br />
                    <b>Control Exposure</b>
                    <div style={{ marginLeft: "24px" }}>
                        {variableDefinitionMap.referenceExposure.description && <><b>Description: </b><DisplayFromFHIR markdown={variableDefinitionMap.referenceExposure.description} /><br /></>}
                        {variableDefinitionMap.referenceExposure.directnessMatch?.coding?.length > 0 && <><b>Directness match: </b>{variableDefinitionMap.referenceExposure.directnessMatch.coding[0].display}<br /><br /></>}
                        <ElementNote element={variableDefinitionMap.referenceExposure} />
                        {variableDefinitionMap.referenceExposure.observed && <>
                            <b>Observed Reference Exposure</b>
                            {variableDefinitionMap.referenceExposureObservedResource?.resourceType &&
                                <>
                                    <div style={{ marginLeft: "24px" }}><p><b></b> {variableDefinitionMap.referenceExposureObservedResource.title || variableDefinitionMap.referenceExposureObservedResource.name || ""}</p></div>
                                    {variableDefinitionMap.referenceExposureObservedResource.resourceType === "Group" ?
                                        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.referenceExposureObservedResource} />
                                        :
                                        <>
                                            {variableDefinitionMap.referenceExposureObservedResource.handling && <div style={{ marginLeft: "24px" }}><b>Handling:</b> {variableDefinitionMap.referenceExposureObservedResource.handling}<br /></div>}
                                            {variableDefinitionMap.referenceExposureObservedResource.category &&
                                                <div style={{ marginLeft: "24px" }}>
                                                    <b>Categories:</b>
                                                    <br />
                                                    {variableDefinitionMap.referenceExposureObservedResource.category.map((categoryInstance, index) => {
                                                        return <div key={index}>
                                                            <b>o </b>{categoryInstance.name}
                                                        </div>
                                                    })}
                                                    <br />
                                                </div>}
                                            <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.referenceExposureObservedResource} />
                                        </>
                                    }
                                    <br />
                                </>}
                            <div style={{ marginLeft: "24px" }}>
                                <b>Resource Reference</b>
                                <div style={{ marginLeft: "24px" }}>
                                    {/*variableDefinitionMap.referenceExposure.observed.display && <span>{variableDefinitionMap.referenceExposure.observed.display}<br /></span>*/}
                                    <DisplayFromFHIR reference={variableDefinitionMap.referenceExposure.observed} />
                                    {/*variableDefinitionMap.referenceExposureObservedReference && <span><a href={"/resources/" + variableDefinitionMap.referenceExposureObservedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.referenceExposureObservedReference}</b></a></span>*/}
                                </div>
                            </div>
                        </>}
                        {variableDefinitionMap.referenceExposure.intended && <>
                            {variableDefinitionMap.referenceExposure.observed && <br />}
                            <b>Intended Reference Exposure</b>
                            {variableDefinitionMap.referenceExposureIntendedResource?.resourceType &&
                                <>
                                    <div style={{ marginLeft: "24px" }}><p><b></b> {variableDefinitionMap.referenceExposureIntendedResource.title || variableDefinitionMap.referenceExposureIntendedResource.name || ""}</p></div>
                                    {variableDefinitionMap.referenceExposureIntendedResource.resourceType === "Group" ?
                                        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.referenceExposureIntendedResource} />
                                        :
                                        <>
                                            {variableDefinitionMap.referenceExposureIntendedResource.handling && <div style={{ marginLeft: "24px" }}><b>Handling:</b> {variableDefinitionMap.referenceExposureIntendedResource.handling}<br /></div>}
                                            {variableDefinitionMap.referenceExposureIntendedResource.category &&
                                                <div style={{ marginLeft: "24px" }}>
                                                    <b>Categories:</b>
                                                    <br />
                                                    {variableDefinitionMap.referenceExposureIntendedResource.category.map((categoryInstance, index) => {
                                                        return <div key={index}>
                                                            <b>o </b>{categoryInstance.name}
                                                        </div>
                                                    })}
                                                    <br />
                                                </div>}

                                            <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.referenceExposureIntendedResource} />
                                        </>
                                    }
                                    <br />
                                </>}
                            <div style={{ marginLeft: "24px" }}>
                                <b>Resource Reference</b>
                                <div style={{ marginLeft: "24px" }}>
                                    {/*variableDefinitionMap.referenceExposure.intended.display && <span>{variableDefinitionMap.referenceExposure.intended.display}<br /></span>*/}
                                    <DisplayFromFHIR reference={variableDefinitionMap.referenceExposure.intended} />
                                    {/*variableDefinitionMap.referenceExposureIntendedReference && <span><a href={"/resources/" + variableDefinitionMap.referenceExposureIntendedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.referenceExposureIntendedReference}</b></a></span>*/}
                                </div>
                            </div>
                        </>}
                    </div>
                </>}
                <br />
            </div>
            <h3 id="outcome">Outcome</h3>
            <div style={{ marginLeft: "24px" }}>
                <br />
                {variableDefinitionMap.outcome &&
                    <div>
                        {variableDefinitionMap.outcome.description && <><b>Description: </b><DisplayFromFHIR markdown={variableDefinitionMap.outcome.description} /><br /></>}
                        {variableDefinitionMap.outcome.roleSubtype && <><b>Variable Role Subtype: </b><DisplayFromFHIR codeableConcept={variableDefinitionMap.outcome.roleSubtype} /><br /></>}
                        {variableDefinitionMap.outcome.directnessMatch?.coding?.length > 0 && <><b>Directness match: </b>{variableDefinitionMap.outcome.directnessMatch.coding[0].display}<br /><br /></>}
                        <ElementNote element={variableDefinitionMap.outcome} />
                        {variableDefinitionMap.outcome.observed && <>
                            <b>Observed Outcome</b>
                            {variableDefinitionMap.outcomeObservedResource?.resourceType &&
                                <>
                                    <div style={{ marginLeft: "24px" }}><p><b></b> {variableDefinitionMap.outcomeObservedResource.title || variableDefinitionMap.outcomeObservedResource.name || ""}</p></div>
                                    {variableDefinitionMap.outcomeObservedResource.resourceType === "Group" ?
                                        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.outcomeObservedResource} />
                                        :
                                        <>
                                            {variableDefinitionMap.outcomeObservedResource.handling && <div style={{ marginLeft: "24px" }}><b>Handling:</b> {variableDefinitionMap.outcomeObservedResource.handling}<br /></div>}
                                            {variableDefinitionMap.outcomeObservedResource.category &&
                                                <div style={{ marginLeft: "24px" }}>
                                                    <b>Categories:</b>
                                                    <br />
                                                    {variableDefinitionMap.outcomeObservedResource.category.map((categoryInstance, index) => {
                                                        return <div key={index}>
                                                            <b>o </b>{categoryInstance.name}
                                                        </div>
                                                    })}
                                                    <br />
                                                </div>}
                                            <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.outcomeObservedResource} />
                                        </>
                                    }
                                    <br />
                                </>}
                            <div style={{ marginLeft: "24px" }}>
                                <b>Resource Reference</b>
                                <div style={{ marginLeft: "24px" }}>
                                    {/*variableDefinitionMap.outcome.observed.display && <span>{variableDefinitionMap.outcome.observed.display}<br /></span>*/}
                                    <DisplayFromFHIR reference={variableDefinitionMap.outcome.observed} />
                                    {/*variableDefinitionMap.outcomeObservedReference && <span><a href={"/resources/" + variableDefinitionMap.outcomeObservedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.outcomeObservedReference}</b></a></span>*/}
                                </div>
                            </div>
                        </>}
                        {variableDefinitionMap.outcome.intended && <>
                            {variableDefinitionMap.outcome.observed && <br />}
                            <b>Intended Outcome</b>
                            {variableDefinitionMap.outcomeIntendedResource?.resourceType &&
                                <>
                                    <div style={{ marginLeft: "24px" }}><p><b></b> {variableDefinitionMap.outcomeIntendedResource.title || variableDefinitionMap.outcomeIntendedResource.name || ""}</p></div>
                                    {variableDefinitionMap.outcomeIntendedResource.resourceType === "Group" ?
                                        <ManageInclusionExclusionEnhancedCharacteristicTables editMode={false} fhirJson={variableDefinitionMap.outcomeIntendedResource} />
                                        :
                                        <>
                                            {variableDefinitionMap.outcomeIntendedResource.handling && <div style={{ marginLeft: "24px" }}><b>Handling:</b> {variableDefinitionMap.outcomeIntendedResource.handling}<br /></div>}
                                            {variableDefinitionMap.outcomeIntendedResource.category &&
                                                <div style={{ marginLeft: "24px" }}>
                                                    <b>Categories:</b>
                                                    <br />
                                                    {variableDefinitionMap.outcomeIntendedResource.category.map((categoryInstance, index) => {
                                                        return <div key={index}>
                                                            <b>o </b>{categoryInstance.name}
                                                        </div>
                                                    })}
                                                    <br />
                                                </div>}
                                            <EvidenceVariableCriteriaDisplay fhirJson={variableDefinitionMap.outcomeIntendedResource} />
                                        </>
                                    }
                                    <br />
                                </>}
                            <div style={{ marginLeft: "24px" }}>
                                <b>Resource Reference</b>
                                <div style={{ marginLeft: "24px" }}>
                                    {/*variableDefinitionMap.outcome.intended.display && <span>{variableDefinitionMap.outcome.intended.display}<br /></span>*/}
                                    <DisplayFromFHIR reference={variableDefinitionMap.outcome.intended} />
                                    {/*variableDefinitionMap.outcomeIntendedReference && <span><a href={"/resources/" + variableDefinitionMap.outcomeIntendedReference} target="_blank" rel="noopener noreferrer"><b>Resource link {variableDefinitionMap.outcomeIntendedReference}</b></a></span>*/}
                                </div>
                            </div>
                        </>}
                    </div>
                }
                <br />
            </div>
            <h3 id="study-design">Study Design</h3>
            <div style={{ marginLeft: "24px" }}>
                <span><b>Synthesis Type: </b> {synthesisType ? synthesisType : <>[Not Reported]</>}</span>
                <br />
                <span><b>Study Design: </b> {studyDesign ? studyDesign : <>[Not Reported]</>}</span>
            </div>
            <h3 id="statistics">Statistics</h3>
            <div style={{ marginLeft: "24px" }}>
                {Array.isArray(fhirJson.statistic) && fhirJson.statistic.map((statistic, statisticIndex) => {
                    let statisticTypeDisplay = "";
                    if (statistic.statisticType) {
                        if (statistic.statisticType.coding?.length > 0) {
                            if (statistic.statisticType.coding[0].display) {
                                statisticTypeDisplay = statistic.statisticType.coding[0].display;
                            } else if (statistic.statisticType.coding[0].code) {
                                statisticTypeDisplay = statistic.statisticType.coding[0].code;
                            }
                        }
                        if (statistic.statisticType.text) {
                            statisticTypeDisplay += "   " + statistic.statisticType.text;
                        }
                    }
                    let statisticValue = "";
                    if (statistic.quantity) {
                        let comparator = "";
                        if (statistic.quantity.comparator) {
                            comparator = statistic.quantity.comparator + " ";
                        }
                        let unit = "";
                        if (statistic.quantity.unit) {
                            unit = " " + statistic.quantity.unit;
                        }
                        let value = statistic.quantity.value;
                        if (value && !unit && statisticTypeDisplay.replace("   ", "").toLowerCase() === "percentage") {
                            unit = "%";
                        }
                        statisticValue = comparator + value + unit;
                    }
                    let sampleSizeQuantified = "";
                    if (statistic.sampleSize) {
                        if (statistic.sampleSize.numberOfStudies) {
                            sampleSizeQuantified = statistic.sampleSize.numberOfStudies + " studies";
                        }
                        if (statistic.sampleSize.numberOfParticipants) {
                            if (sampleSizeQuantified) {
                                sampleSizeQuantified += ", ";
                            }
                            sampleSizeQuantified += statistic.sampleSize.numberOfParticipants + " participants";
                        }
                        if (statistic.sampleSize.knownDataCount) {
                            if (sampleSizeQuantified) {
                                sampleSizeQuantified += ", ";
                            }
                            sampleSizeQuantified += statistic.sampleSize.knownDataCount + " counted";
                        }
                    }

                    return <div key={statisticIndex}>
                        <br />
                        {fhirJson.statistic.length > 1 && <b style={{ marginBottom: "0px" }}>Statistic #{statisticIndex + 1}</b>}
                        {statistic.category && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Category:</b> {statistic.category.text}</span>}
                        <div style={{ marginLeft: "24px" }}>
                            {statistic.description && <span><b>Description: </b><DisplayFromFHIR markdown={statistic.description} /><br /></span>}
                            {Array.isArray(statistic.note) && statistic.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note:</b> {note.text}<br /></span> })}
                            <Table style={{ margin: "4px", width: "600px" }}><Table.Body><Table.Row><Table.Cell style={{ width: "280px", paddingTop: "6px", paddingBottom: "6px", paddingLeft: "12px" }}><b>{statisticTypeDisplay}</b></Table.Cell><Table.Cell style={{ paddingTop: "6px", paddingBottom: "6px", paddingLeft: "12px" }}><b>{statisticValue}</b></Table.Cell></Table.Row></Table.Body></Table>
                            {!isNaN(statistic.numberOfEvents) && <span><b>Number of Events: </b>{statistic.numberOfEvents}<br /></span>}
                            {!isNaN(statistic.numberAffected) && <span><b>Number Affected: </b>{statistic.numberAffected}<br /></span>}
                            {statistic.sampleSize && <><b>Sample Size: </b>
                                <div style={{ marginLeft: "24px" }}>
                                    {sampleSizeQuantified && <><b></b>{sampleSizeQuantified}<br /></>}
                                    {statistic.sampleSize.description && <span><b>Sample Size Description: </b><DisplayFromFHIR markdown={statistic.sampleSize.description} /><br /></span>}
                                    {Array.isArray(statistic.sampleSize.note) && statistic.sampleSize.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note:</b> {note.text}<br /></span> })}
                                </div>
                            </>}
                            {statistic.attributeEstimate?.length > 0 &&
                                <Table style={{ margin: "4px" }}>
                                    <Table.Header><Table.Row><Table.HeaderCell className={"leftAlignedCell"} style={{ padding: "6px", width: "360px" }}>Attribute</Table.HeaderCell><Table.HeaderCell style={{ padding: "6px" }}>Value</Table.HeaderCell><Table.HeaderCell style={{ padding: "6px" }}>Notes</Table.HeaderCell></Table.Row></Table.Header>
                                    <Table.Body>
                                        {statistic.attributeEstimate.map((attributeEstimate, aeIndex) => {
                                            let calculatedPercentageAttribute = false;
                                            let attribute = "";
                                            if (attributeEstimate.level) {
                                                attribute = 100 * attributeEstimate.level + "% ";
                                            }
                                            if (attributeEstimate.type) {
                                                if (attributeEstimate.type.coding?.length > 0) {
                                                    if (attributeEstimate.type.coding[0].display) {
                                                        attribute += attributeEstimate.type.coding[0].display;
                                                    } else if (attributeEstimate.type.coding[0].code) {
                                                        attribute += attributeEstimate.type.coding[0].code;
                                                    }
                                                }
                                                if (attributeEstimate.type.text) {
                                                    if (attributeEstimate.type.text.toLowerCase() === "percentage") {
                                                        calculatedPercentageAttribute = true;
                                                    }
                                                    attribute += " " + attributeEstimate.type.text;
                                                }
                                            }
                                            let attributeValue = "";
                                            if (attributeEstimate.quantity) {
                                                let comparator = "";
                                                if (attributeEstimate.quantity.comparator) {
                                                    comparator = attributeEstimate.quantity.comparator + " ";
                                                }
                                                let unit = "";
                                                if (attributeEstimate.quantity.unit) {
                                                    unit = " " + attributeEstimate.quantity.unit;
                                                }
                                                let attributeEstimateValue = "";
                                                if (attributeEstimate.quantity.value !== undefined) {
                                                    attributeEstimateValue = attributeEstimate.quantity.value
                                                }
                                                attributeValue = comparator + attributeEstimate.quantity.value + unit;
                                                if (calculatedPercentageAttribute && attributeEstimate.quantity.value && (comparator === "" || comparator === " ") && attributeEstimate.quantity.unit === "%") {
                                                    try {
                                                        //The plus symbol belongs here, +parseFloat(4).toFixed(2) === 4 as an int vs parseFloat(4).toFixed(2) === "4.00" as a string
                                                        attributeEstimateValue = +parseFloat(attributeEstimate.quantity.value).toFixed(2);
                                                        if (attributeEstimateValue.toString() !== "NaN") {
                                                            attributeValue = attributeEstimateValue + unit;
                                                        }
                                                    } catch (e) {
                                                    }
                                                }
                                            }
                                            if (attributeValue === "" && attributeEstimate.range) {
                                                let lowValue = "No Lower Bound";
                                                let highValue = "No Upper Bound";
                                                if (attributeEstimate.range.low?.value !== undefined) {
                                                    lowValue = attributeEstimate.range.low.value;
                                                    if (attributeEstimate.range.low.unit) {
                                                        lowValue += " " + attributeEstimate.range.low.unit;
                                                    }
                                                }
                                                if (attributeEstimate.range.high?.value !== undefined) {
                                                    highValue = attributeEstimate.range.high.value;
                                                    if (attributeEstimate.range.high.unit) {
                                                        highValue += " " + attributeEstimate.range.high.unit;
                                                    }
                                                }
                                                attributeValue = lowValue + " to " + highValue;
                                            }
                                            return <Table.Row key={aeIndex}>
                                                <Table.Cell>{attribute}</Table.Cell>
                                                <Table.Cell>{attributeValue}</Table.Cell>
                                                <Table.Cell>
                                                    {attributeEstimate.description && <><b>Description: </b><DisplayFromFHIR markdown={attributeEstimate.description} /><br /></>}
                                                    {attributeEstimate.note && <>{attributeEstimate.note.map((note, noteIndex) => { return <span key={noteIndex}><b>Note:</b> {note.text}<br /></span> })}</>}
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                        )}
                                    </Table.Body>
                                </Table>}
                            {(Array.isArray(statistic.modelCharacteristic) &&
                                statistic.modelCharacteristic.length > 0) &&
                                statistic.modelCharacteristic.map((item, itemIndex) => {
                                    return <div key={itemIndex}>
                                        <p><b>Model Characteristic {itemIndex + 1} of {statistic.modelCharacteristic.length}</b></p>
                                        <div style={{ marginLeft: "24px" }}>
                                            <ModelCharacteristicDisplay modelCharactersticInstance={item} />
                                        </div>
                                    </div>
                                })}
                            {/*statistic.analysisPlan?.length > 0 && <>
                                <b>Analysis Plan:</b>
                                <SimpleResourceFieldViewer resource={statistic.analysisPlan} parentElement={""} />
                            </>*/}
                        </div>
                        <br />
                    </div>
                })}
            </div>
            <h3 id="certainty">Certainty</h3>
            <div style={{ marginLeft: "24px" }}>
                {fhirJson.certainty?.length > 0 &&
                    <Table style={{ margin: "4px" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ padding: "6px" }}>Type</Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: "6px" }}>Rating</Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>Description</Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>Notes</Table.HeaderCell>
                                <Table.HeaderCell style={{ padding: "6px" }}>Rater</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <CertaintyRows certaintyEntries={fhirJson.certainty} level={0} editMode={false} />
                        </Table.Body>
                    </Table>
                }
            </div>
            <ResourceBottomDisplay howToCite={howToCite} fhirJson={fhirJson}
                classificationsLoadedState={classificationsLoadedState} classificationsArray={classificationsArrayState}
            />
        </div>
    </div>
}

export { EvidenceDisplay, ModelCharacteristicDisplay };