import React, { useState, useEffect, useContext, useImperativeHandle, memo } from 'react';
import { getStringFromFHIR, DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { Button } from 'semantic-ui-react';
import { createAdaptedProductResource } from './AdaptationFunctions';
import NavigationAdaptationItemSegment from './NavigationAdaptationItemSegment';
import { useHistory } from "react-router-dom";
import { DisplayClassifiers } from './MetadataPatternDisplay';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
import getFhirDatatype from './getFhirDatatype';
/*
const ComparedArtifactReferenceEntry = memo(({ fieldLabel, startingValue, setResourceState }) => {
  let startingReference = { "reference": "", "type": "", "identifier": "", "display": "" };
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingReference.extension = startingValue.extension; }
    if (startingValue.reference) { startingReference.reference = startingValue.reference; }
    if (startingValue.type) { startingReference.type = startingValue.type; }
    if (startingValue.identifier) { startingReference.identifier = startingValue.identifier; }
    if (startingValue.display) { startingReference.display = startingValue.display; }
  }

  const [referenceState, setReferenceState] = useState(startingReference);

  useEffect((() => {
    if (Object.keys(referenceState).length) {
      let newReference = {};
      if (referenceState.extension) { newReference.extension = referenceState.extension; }
      if (referenceState.reference) { newReference.reference = referenceState.reference; }
      if (referenceState.type) { newReference.type = referenceState.type; }
      if (referenceState.identifier && Object.keys(referenceState.identifier).length) {
        newReference.identifier = referenceState.identifier;
      }
      if (referenceState.display) { newReference.display = referenceState.display; }
      if (Object.keys(newReference).length === 0) {
        newReference = null;
      }
      setResourceState(prevState => {
        let newExtension = prevState.extension;
        newExtension.push({
          "url": "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared",
          "valueReference": newReference
        })
        return { ...prevState, "extension": newExtension }
      })
    }
  }), [referenceState]);

  return <>
    <p><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='display' fieldLabel={'Display of ' + fieldLabel}
        startingValue={referenceState.display} setResourceState={setReferenceState} />
      <DataEntry datatype='string' elementName='reference' fieldLabel='Referenced resource absolute or relative URI' startingValue={referenceState.reference} setResourceState={setReferenceState} />
      <DataEntry datatype='string' elementName='type' fieldLabel='Resource Type' startingValue={referenceState.type} setResourceState={setReferenceState} />
      <DataEntry datatype='Identifier' elementName='identifier'
        fieldLabel='Referenced Resource Identifier' startCollapsed={true}
        startingValue={referenceState.identifier} setResourceState={setReferenceState}
        fromIdentifier={false} fromReference={true} />
    </div>
  </>
})
*/
const createComparisonContent = (resourceJsonA, resourceJsonB) => {
  //for each key in resourceJsonA, create a content entry similar to initial creation of Adaptation
  //for each key in resourceJsonA, if the same key is found in resourceJsonB
  //// add a ComparedContentComponent to the content entry from resourceJsonB
  //// delete the key from resourceJsonB
  //// compare the OriginalResourceContent and ComparedContent and create a ComparisonComponent with Rating (Same, Similar or Different)
  //for each key in resourceJsonA, if no same key found in resourceJsonB, create a ComparisonComponent with MissingInB Rating
  let comparisonContent = [];
  if (resourceJsonA && Object.keys(resourceJsonA).length) {
    for (let key of Object.keys(resourceJsonA)) {
      let valueType;
      let valueTypeClassifier;
      let typeValue;
      let keyValue;
      let componentComponents;
      let adaptationComponent;
      let comparisonRating = {
        "coding": [{
          "system": "https://fevir.net/resources/CodeSystem/112109",
          "code": "missing-comparator-value",
          "display": "Missing key in comparator element"
        }]
      }
      if (resourceJsonB[key] !== undefined) {
        if (resourceJsonA[key] === resourceJsonB[key] ||
          JSON.stringify(resourceJsonA[key]) === JSON.stringify(resourceJsonB[key])) {
          comparisonRating.coding[0].code = "same";
          comparisonRating.coding[0].display = "Same";
        } else if (resourceJsonA[key] == resourceJsonB[key] ||
          JSON.stringify(resourceJsonA[key]) == JSON.stringify(resourceJsonB[key])) {
          comparisonRating.coding[0].code = "similar";
          comparisonRating.coding[0].display = "Similar";
        } else {
          comparisonRating.coding[0].code = "different";
          comparisonRating.coding[0].display = "Different";
        }
      }
      if (typeof (resourceJsonA[key]) === "string" || typeof (resourceJsonA[key]) === "number" ||
        typeof (resourceJsonA[key]) === "boolean") {
        valueType = 'primitive';
        valueTypeClassifier = {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112109",
            "code": typeof (resourceJsonA[key]) + "-datatype",
            "display": typeof (resourceJsonA[key]) + " Datatype"
          }]
        };
      } else if (key === 'coding') {
        valueType = 'Coding';
        if (Array.isArray(resourceJsonA[key])) {
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "coding-array",
              "display": "Coding Array"
            }]
          };
        } else if (typeof (resourceJsonA[key]) === 'object') {
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "single-coding-object",
              "display": "Single Coding Object"
            }]
          };
        }
      } else if (Array.isArray(resourceJsonA[key])) {
        valueType = 'array';
        let arrayValueDatatype = getFhirDatatype(key, resourceJsonA[key]);
        if (arrayValueDatatype !== "ERROR--Element value is an Array not matched.") {
          arrayValueDatatype = arrayValueDatatype.replace("array-of-", "");
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "manageAs" + arrayValueDatatype,
              "display": "Manage as " + arrayValueDatatype
            }]
          };
        }
        if (arrayValueDatatype === "ERROR--Element value is an Array not matched.") {
          console.log(key + ' -- ERROR--Element value is an Array not matched.');
        }
      } else if (typeof (resourceJsonA[key]) === 'object') {
        valueType = 'object';
        let objectValueDatatype = getFhirDatatype(key, resourceJsonA[key]);
        valueTypeClassifier = {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112109",
            "code": "manageAs" + objectValueDatatype,
            "display": "Manage as " + objectValueDatatype
          }]
        };
      }
      let comparedResourceContentComponent = {
        
        "summary": "No " + key + " element value found in the Resource being compared",
        "type": {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "ComparedResourceContent",
            "display": "Compared Resource Content"
          }]
        },
        "classifier": [{ "coding": [{ "code": "null", "display": "Null" }] }]
      };
      switch (valueType) {
        case 'primitive':
          typeValue = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "PrimitiveElement",
              "display": "Primitive Element"
            }]
          };
          if (typeof (resourceJsonA[key]) === 'string') {
            keyValue = resourceJsonA[key];
          }
          if (typeof (resourceJsonA[key]) === 'number' || typeof (resourceJsonA[key]) === 'boolean') {
            keyValue = resourceJsonA[key].toString();
          }
          if (resourceJsonB[key] !== undefined) {
            if (typeof (resourceJsonB[key]) === 'string') {
              comparedResourceContentComponent.summary = resourceJsonB[key];
              comparedResourceContentComponent.classifier = [{
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112109",
                  "code": "string-datatype",
                  "display": "string Datatype"
                }]
              }];
            }
            if (typeof (resourceJsonB[key]) === 'number') {
              comparedResourceContentComponent.summary = resourceJsonB[key].toString();
              comparedResourceContentComponent.classifier = [{
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112109",
                  "code": "number-datatype",
                  "display": "number Datatype"
                }]
              }];
            }
            if (typeof (resourceJsonB[key]) === 'boolean') {
              comparedResourceContentComponent.summary = resourceJsonB[key].toString();
              comparedResourceContentComponent.classifier = [{
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112109",
                  "code": "boolean-datatype",
                  "display": "boolean Datatype"
                }]
              }];
            }
            if (typeof (resourceJsonB[key]) === 'object') {
              comparedResourceContentComponent.summary = JSON.stringify(resourceJsonB[key]);
              let objectValueDatatype = getFhirDatatype(key, resourceJsonB[key]);
              comparedResourceContentComponent.classifier = [{
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112109",
                  "code": "manageAs" + objectValueDatatype,
                  "display": "Manage as " + objectValueDatatype
                }]
              }];
            }
          }
          componentComponents = [
            {
              
              "summary": keyValue,
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContent",
                  "display": "Original Resource Content"
                }]
              },
              "component": [comparedResourceContentComponent]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "ComparisonOfResources",
                  "display": "Comparison of Resources Content"
                }]
              },
              "classifier": [comparisonRating]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "SuggestedChange",
                  "display": "Suggested Change to Resource Content"
                }]
              }
            }
          ];
          adaptationComponent = {
            
            "type": typeValue,
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": componentComponents
          };
          comparisonContent.push(adaptationComponent);
          break;
        case 'Coding':
          typeValue = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "CodingElement",
              "display": "Coding Element"
            }]
          };
          keyValue = resourceJsonA[key];
          let codingDisplay;
          if (Array.isArray(keyValue)) {
            codingDisplay = keyValue.map(coding => getStringFromFHIR.Coding(coding)).join('; ');
          } else {
            codingDisplay = getStringFromFHIR.Coding(keyValue);
          }
          if (resourceJsonB[key]) {
            if (Array.isArray(resourceJsonB[key])) {
              comparedResourceContentComponent.summary = resourceJsonB[key].map(coding => getStringFromFHIR.Coding(coding)).join('; ');
            } else {
              comparedResourceContentComponent.summary = getStringFromFHIR.Coding(resourceJsonB[key]);
            }
            let objectValueDatatype = getFhirDatatype(key, resourceJsonB[key]);
            comparedResourceContentComponent.classifier = [{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "manageAs" + objectValueDatatype,
                "display": "Manage as " + objectValueDatatype
              }]
            }];
            comparedResourceContentComponent.component = [{
              
              "summary": JSON.stringify(resourceJsonB[key]),
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }]
              }
            }]
          }
          componentComponents = [
            {
              
              "summary": codingDisplay,
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContent",
                  "display": "Original Resource Content"
                }]
              },
              "component": [
                {
                  
                  "summary": JSON.stringify(keyValue),
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                }, comparedResourceContentComponent
              ]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "ComparisonOfResources",
                  "display": "Comparison of Resources Content"
                }]
              },
              "classifier": [comparisonRating]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "SuggestedChange",
                  "display": "Suggested Change to Resource Content"
                }]
              }
            }
          ];
          adaptationComponent = {
            
            "type": typeValue,
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": componentComponents
          };
          comparisonContent.push(adaptationComponent);
          break;
        case 'array':
          //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
          keyValue = JSON.stringify(resourceJsonA[key]);
          if (resourceJsonB[key]) {
            delete comparedResourceContentComponent.summary;
            let objectValueDatatype = getFhirDatatype(key, resourceJsonB[key]);
            comparedResourceContentComponent.classifier = [{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "manageAs" + objectValueDatatype,
                "display": "Manage as " + objectValueDatatype
              }]
            }];
            comparedResourceContentComponent.component = [{
              
              "summary": JSON.stringify(resourceJsonB[key]),
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }]
              }
            }]
          }
          adaptationComponent = {
            
            "type": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "ArrayElement",
                "display": "Array Element"
              }]
            },
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": [
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContent",
                    "display": "Original Resource Content"
                  }]
                },
                "component": [{
                  
                  "summary": keyValue,
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                },
                  comparedResourceContentComponent
                ]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ComparisonOfResources",
                    "display": "Comparison of Resources Content"
                  }]
                },
                "classifier": [comparisonRating]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "SuggestedChange",
                    "display": "Suggested Change to Resource Content"
                  }]
                }
              },
              {
                
                "summary": keyValue,
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ContainedElementAdaptation",
                    "display": "Contained Element Adaptation"
                  }]
                },
                "path": [key]
              }
            ]
          };
          comparisonContent.push(adaptationComponent);
          break;
        case 'object':
          //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
          keyValue = JSON.stringify(resourceJsonA[key]);
          if (resourceJsonB[key]) {
            delete comparedResourceContentComponent.summary;
            let objectValueDatatype = getFhirDatatype(key, resourceJsonB[key]);
            comparedResourceContentComponent.classifier = [{
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112109",
                "code": "manageAs" + objectValueDatatype,
                "display": "Manage as " + objectValueDatatype
              }]
            }];
            comparedResourceContentComponent.component = [{
              
              "summary": JSON.stringify(resourceJsonB[key]),
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }]
              }
            }]
          }
          adaptationComponent = {
            
            "type": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "ObjectElement",
                "display": "Object Element"
              }]
            },
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": [
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContent",
                    "display": "Original Resource Content"
                  }]
                },
                "component": [{
                  
                  "summary": keyValue,
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                },
                  comparedResourceContentComponent
                ]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ComparisonOfResources",
                    "display": "Comparison of Resources Content"
                  }]
                },
                "classifier": [comparisonRating]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "SuggestedChange",
                    "display": "Suggested Change to Resource Content"
                  }]
                }
              },
              {
                
                "summary": keyValue,
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ContainedElementAdaptation",
                    "display": "Contained Element Adaptation"
                  }]
                },
                "path": [key]
              }
            ]
          };
          comparisonContent.push(adaptationComponent);
          break;
        default:
          console.log(key + 'has unrecognized datatype')
      }
      if (resourceJsonB[key]) {
        delete resourceJsonB[key];
      }
    }
  }
  //for each key in resourceJsonB (not found in A), create a new content entry to note MissingInA Rating
  if (Object.keys(resourceJsonB).length) {
    for (let key of Object.keys(resourceJsonB)) {
      let valueType;
      let valueTypeClassifier;
      let typeValue;
      let componentComponents;
      let adaptationComponent;
      let comparisonRating = {
        "coding": [{
          "system": "https://fevir.net/resources/CodeSystem/112109",
          "code": "missing-index-value",
          "display": "Missing key in index element"
        }]
      };
      if (typeof (resourceJsonB[key]) === "string" || typeof (resourceJsonB[key]) === "number" ||
        typeof (resourceJsonB[key]) === "boolean") {
        valueType = 'primitive';
        valueTypeClassifier = {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112109",
            "code": typeof (resourceJsonB[key]) + "-datatype",
            "display": typeof (resourceJsonB[key]) + " Datatype"
          }]
        };
      } else if (key === 'coding') {
        valueType = 'Coding';
        if (Array.isArray(resourceJsonB[key])) {
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "coding-array",
              "display": "Coding Array"
            }]
          };
        } else if (typeof (resourceJsonB[key]) === 'object') {
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "single-coding-object",
              "display": "Single Coding Object"
            }]
          };
        }
      } else if (Array.isArray(resourceJsonB[key])) {
        valueType = 'array';
        let arrayValueDatatype = getFhirDatatype(key, resourceJsonB[key]);
        if (arrayValueDatatype !== "ERROR--Element value is an Array not matched.") {
          arrayValueDatatype = arrayValueDatatype.replace("array-of-", "");
          valueTypeClassifier = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112109",
              "code": "manageAs" + arrayValueDatatype,
              "display": "Manage as " + arrayValueDatatype
            }]
          };
        }
        if (arrayValueDatatype === "ERROR--Element value is an Array not matched.") {
          console.log(key + ' -- ERROR--Element value is an Array not matched.');
        }
      } else if (typeof (resourceJsonA[key]) === 'object') {
        valueType = 'object';
        let objectValueDatatype = getFhirDatatype(key, resourceJsonB[key]);
        valueTypeClassifier = {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112109",
            "code": "manageAs" + objectValueDatatype,
            "display": "Manage as " + objectValueDatatype
          }]
        };
      }
      let comparedResourceContentComponent = {
        
        "summary": "No " + key + " element value found in the Index Resource being compared",
        "type": {
          "coding": [{
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "ComparedResourceContent",
            "display": "Compared Resource Content"
          }]
        }
      };
      switch (valueType) {
        case 'primitive':
          typeValue = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "PrimitiveElement",
              "display": "Primitive Element"
            }]
          };
          if (resourceJsonB[key] !== undefined) {
            if (typeof (resourceJsonB[key]) === 'string') {
              comparedResourceContentComponent.summary = resourceJsonB[key];
            }
            if (typeof (resourceJsonB[key]) === 'number' || typeof (resourceJsonB[key]) === 'boolean') {
              comparedResourceContentComponent.summary = resourceJsonB[key].toString();
            }
          }
          componentComponents = [
            {
              
              "summary": "No value in index Resource.",
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContent",
                  "display": "Original Resource Content"
                }]
              },
              "component": [comparedResourceContentComponent]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "ComparisonOfResources",
                  "display": "Comparison of Resources Content"
                }]
              },
              "classifier": [comparisonRating]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "SuggestedChange",
                  "display": "Suggested Change to Resource Content"
                }]
              }
            }
          ];
          adaptationComponent = {
            
            "type": typeValue,
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": componentComponents
          };
          comparisonContent.push(adaptationComponent);
          break;
        case 'Coding':
          typeValue = {
            "coding": [{
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "CodingElement",
              "display": "Coding Element"
            }]
          };
          if (resourceJsonB[key]) {
            if (Array.isArray(resourceJsonB[key])) {
              comparedResourceContentComponent.summary = resourceJsonB[key].map(coding => getStringFromFHIR.Coding(coding)).join('; ');
            } else {
              comparedResourceContentComponent.summary = getStringFromFHIR.Coding(resourceJsonB[key]);
            }
            comparedResourceContentComponent.component = [{
              
              "summary": JSON.stringify(resourceJsonB[key]),
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }]
              }
            }]
          }
          componentComponents = [
            {
              
              "summary": "No value in index Resource.",
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContent",
                  "display": "Original Resource Content"
                }]
              },
              "component": [
                {
                  
                  "summary": "No value in index Resource.",
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                }, comparedResourceContentComponent
              ]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "ComparisonOfResources",
                  "display": "Comparison of Resources Content"
                }]
              },
              "classifier": [comparisonRating]
            },
            {
              
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "SuggestedChange",
                  "display": "Suggested Change to Resource Content"
                }]
              }
            }
          ];
          adaptationComponent = {
            
            "type": typeValue,
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": componentComponents
          };
          comparisonContent.push(adaptationComponent);
          break;
        case 'array':
          //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
          if (resourceJsonB[key]) {
            delete comparedResourceContentComponent.summary;
            comparedResourceContentComponent.component = [{
              
              "summary": JSON.stringify(resourceJsonB[key]),
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }]
              }
            }]
          }
          adaptationComponent = {
            
            "type": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "ArrayElement",
                "display": "Array Element"
              }]
            },
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": [
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContent",
                    "display": "Original Resource Content"
                  }]
                },
                "component": [{
                  
                  "summary": "No value in index Resource.",
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                },
                  comparedResourceContentComponent
                ]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ComparisonOfResources",
                    "display": "Comparison of Resources Content"
                  }]
                },
                "classifier": [comparisonRating]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "SuggestedChange",
                    "display": "Suggested Change to Resource Content"
                  }]
                }
              },
              {
                
                "summary": JSON.stringify(resourceJsonB[key]),
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ContainedElementAdaptation",
                    "display": "Contained Element Adaptation"
                  }]
                },
                "path": [key]
              }
            ]
          };
          comparisonContent.push(adaptationComponent);
          break;
        case 'object':
          //This can later be converted to routing array and object entries to path values and converting this to components with end-units of primitive datatypes
          if (resourceJsonB[key]) {
            delete comparedResourceContentComponent.summary;
            comparedResourceContentComponent.component = [{
              
              "summary": JSON.stringify(resourceJsonB[key]),
              "type": {
                "coding": [{
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }]
              }
            }]
          }
          adaptationComponent = {
            
            "type": {
              "coding": [{
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "ObjectElement",
                "display": "Object Element"
              }]
            },
            "classifier": [valueTypeClassifier],
            "path": [key],
            "component": [
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "OriginalResourceContent",
                    "display": "Original Resource Content"
                  }]
                },
                "component": [{
                  
                  "summary": "No value in index Resource.",
                  "type": {
                    "coding": [{
                      "system": "https://fevir.net/resources/CodeSystem/112108",
                      "code": "OriginalResourceContentJSON",
                      "display": "Original Resource Content JSON"
                    }]
                  }
                },
                  comparedResourceContentComponent
                ]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ComparisonOfResources",
                    "display": "Comparison of Resources Content"
                  }]
                },
                "classifier": [comparisonRating]
              },
              {
                
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "SuggestedChange",
                    "display": "Suggested Change to Resource Content"
                  }]
                }
              },
              {
                
                "summary": JSON.stringify(resourceJsonB[key]),
                "type": {
                  "coding": [{
                    "system": "https://fevir.net/resources/CodeSystem/112108",
                    "code": "ContainedElementAdaptation",
                    "display": "Contained Element Adaptation"
                  }]
                },
                "path": [key]
              }
            ]
          };
          comparisonContent.push(adaptationComponent);
          break;
        default:
          console.log(key + 'has unrecognized datatype')
      }
    }
  }
  return comparisonContent
}

const createComparison = async (resourceState, setResourceState, globalContext) => {
  //check if resourceState.artifactReference, resourceState.artifactCanonical or resourceState.artifactUri else alert
  if (!resourceState.artifactReference && !resourceState.artifactCanonical && !resourceState.artifactUri) {
    alert("Please enter a Resource to be compared or adapted.")
    return
  }
  //check if resourceState.artifact[x] maps to FEvIR Resource else alert
  let foiA;
  if (resourceState.artifactReference && resourceState.artifactReference.reference.split('/').length === 2 &&
    !isNaN(resourceState.artifactReference.reference.split('/')[1])) {
    foiA = resourceState.artifactReference.reference.split('/')[1];
  }
  if (resourceState.artifactCanonical && resourceState.artifactCanonical.includes('fevir.net') &&
    resourceState.artifactCanonical.split('/').length > 1) {
    let splitLength = resourceState.artifactCanonical.split('/').length;
    if (!isNaN(resourceState.artifactCanonical.split('/')[splitLength - 1])) {
      foiA = resourceState.artifactCanonical.split('/')[splitLength - 1];
    }
  }
  if (resourceState.artifactUri && resourceState.artifactUri.includes('fevir.net') &&
    resourceState.artifactUri.split('/').length > 1) {
    let splitLength = resourceState.artifactUri.split('/').length;
    if (!isNaN(resourceState.artifactUri.split('/')[splitLength - 1])) {
      foiA = resourceState.artifactUri.split('/')[splitLength - 1];
    }
  }
  if (!foiA) {
    alert("Please enter a Resource with a FEvIR Object Identifier (FOI) to be compared or adapted.")
    return
  }
  //create resourceJsonA as JSON of resourceState.artifact[x]
  let resourceJsonA;
  const bodyA = {
    'functionid': "getfhirresource",
    'idToken': "",
    'resourceid': foiA
  };
  let responseA = await submitToFevirServer(globalContext, 5000, bodyA, true, false);
  if (responseA.success) {
    resourceJsonA = JSON.parse(responseA.fhirjsonstring);
  };
  //check if resourceState.extension contains a reference/canonical/uri to a FEvIR Resource else alert
  if (!resourceState.extension) {
    alert("Please enter a Resource being compared.")
    return
  }
  let comparedArtifactReferenceB;
  let comparedArtifactUriB;
  if (Array.isArray(resourceState.extension) && resourceState.extension.length > 0) {
    for (const extension of resourceState.extension) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared" ||
        extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared") {
        if (extension.valueReference) {
          comparedArtifactReferenceB = extension.valueReference;
          break;
        }
        if (extension.valueCanonical) {
          comparedArtifactUriB = extension.valueCanonical;
          break;
        }
        if (extension.valueUri) {
          comparedArtifactUriB = extension.valueUri;
          break;
        }
      }
    }
  }
  if (!comparedArtifactReferenceB && !comparedArtifactUriB) {
    alert("Please enter a Resource being compared.")
    return
  }
  //check if resourceState.extension maps to FEvIR Resource else alert
  let foiB;
  if (comparedArtifactReferenceB && comparedArtifactReferenceB.reference.split('/').length === 2 &&
    !isNaN(comparedArtifactReferenceB.reference.split('/')[1])) {
    foiB = comparedArtifactReferenceB.reference.split('/')[1];
  }
  if (comparedArtifactUriB && comparedArtifactUriB.includes('fevir.net') &&
    comparedArtifactUriB.split('/').length > 1) {
    let splitLength = comparedArtifactUriB.split('/').length;
    if (!isNaN(comparedArtifactUriB.split('/')[splitLength - 1])) {
      foiB = comparedArtifactUriB.split('/')[splitLength - 1];
    }
  }
  if (!foiB) {
    alert("Please enter a Resource with a FEvIR Object Identifier (FOI) to be compared.")
    return
  }
  //create resourceJsonB as JSON of first resourceState.extension
  let resourceJsonB;
  const bodyB = {
    'functionid': "getfhirresource",
    'idToken': "",
    'resourceid': foiB
  };
  let responseB = await submitToFevirServer(globalContext, 5000, bodyB, true, false);
  if (responseB.success) {
    resourceJsonB = JSON.parse(responseB.fhirjsonstring);
  };
  //check if resourceJsonA.resourceType === resourceJsonB.resourceType else alert
  if (resourceJsonA.resourceType !== resourceJsonB.resourceType) {
    alert("Unable to compare " + resourceJsonA.resourceType + " Resource against " + resourceJsonB.resourceType + " Resource.")
    return
  }
  //for each key in resourceJsonA, create a content entry similar to initial creation of Adaptation
  //for each key in resourceJsonA, if the same key is found in resourceJsonB
  //// add a ComparedContentComponent to the content entry from resourceJsonB
  //// delete the key from resourceJsonB
  //// compare the OriginalResourceContent and ComparedContent and create a ComparisonComponent with Rating (Same, Similar or Different)
  //for each key in resourceJsonA, if no same key found in resourceJsonB, create a ComparisonComponent with MissingInB Rating
  //for each key in resourceJsonB (not found in A), create a new content entry to note MissingInA Rating
  let newContent = createComparisonContent(resourceJsonA, resourceJsonB);
  //setResourceState(...prevState, content: newContent)
  if (newContent && newContent.length > 0) {
    setResourceState(prevState => { return { ...prevState, 'content': newContent } });
  } else {
    alert("Something went wrong.")
  }
}

const ComparisonEdit = ({ fhirJson, fhirEntryState, formInputsStateRef, resourceId, setFhirEntryState,
  submitUpdatedFhirResource, changeFormState, addToast, citationSummary,
  citationJson, classificationsArrayState, classificationsLoadedState }) => {

  const globalContext = useContext(FevirContext);

  let startingArtifactReferenceDatatype = 'none';
  if (fhirJson.artifactReference) { startingArtifactReferenceDatatype = 'Reference'; }
  if (fhirJson.artifactCanonical) { startingArtifactReferenceDatatype = 'canonical'; }
  if (fhirJson.artifactUri) { startingArtifactReferenceDatatype = 'uri'; }

  let startingComparedArtifactDatatype = 'none';
  let comparedArtifactReference = [];
  let comparedArtifactUri = [];
  if (Array.isArray(fhirJson.extension) && fhirJson.extension.length > 0) {
    for (const extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared" ||
        extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared") {
        if (extension.valueReference) {
          comparedArtifactReference.push(extension.valueReference);
          startingComparedArtifactDatatype = 'Reference';
        }
        if (extension.valueCanonical) {
          comparedArtifactUri.push(extension.valueCanonical);
          startingComparedArtifactDatatype = 'canonical';
        }
        if (extension.valueUri) {
          comparedArtifactUri.push(extension.valueUri);
          startingComparedArtifactDatatype = 'uri';
        }
      }
    }
  }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "ArtifactAssessment", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "artifactReference": fhirJson.artifactReference, "artifactCanonical": fhirJson.artifactCanonical, "artifactUri": fhirJson.artifactUri,
    "content": fhirJson.content, "workflowStatus": fhirJson.workflowStatus, "disposition": fhirJson.disposition,
    "citeAsReference": fhirJson.citeAsReference, "citeAsMarkdown": fhirJson.citeAsMarkdown
  });

  const [resultingJsonExpandedState, setResultingJsonExpandedState] = useState(false);
  const [artifactReferenceEditState, setArtifactReferenceEditState] = useState(false);
  const [artifactReferenceDatatypeState, setArtifactReferenceDatatypeState] = useState(startingArtifactReferenceDatatype);
  const [comparedArtifactEditState, setComparedArtifactEditState] = useState(false);
  const [comparedArtifactDatatypeState, setComparedArtifactDatatypeState] = useState(startingComparedArtifactDatatype);

  const history = useHistory();

  const submitNewResource = async (newResourceJson, globalContext) => {
    const setUserState = globalContext.setUserState;
    let title = newResourceJson.title || newResourceJson.name || "";
    if (!title || title.trim() === "") {
      title = prompt("Please give the new Resource a title.")
    }

    let fhirEntryString = JSON.stringify(newResourceJson, null, 2);

    const body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': fhirEntryString,
      'title': title,
      'status': newResourceJson.status || 'active',
    };

    let response = await submitToFevirServer(globalContext, 5000, body, true, false);

    if (response.success) {
      history.push("/");
      history.push(`/resources/${response.resourcetype}/${response.formstateid}`);
      setUserState(prevState => { return { ...prevState } }); //I guess this is to trigger the userState useEffects?
    }
  };

  const submitUpdatedAdaptedFhirResource = async (updatedResourceJson, history, addToast) => {
    let title;
    let resourceType = updatedResourceJson.resourceType;
    let resourceId = updatedResourceJson.id;
    let status = updatedResourceJson.status || 'active';

    let workingBlob;
    try {
      workingBlob = JSON.stringify(updatedResourceJson);
    } catch { }
    if (workingBlob) {
      let workingBlobJson = JSON.parse(workingBlob);
      if (workingBlobJson.title && typeof workingBlobJson.title === "string" && workingBlobJson.title.trim()) {
        title = workingBlobJson.title;
      } else if (workingBlobJson.name) {
        if (typeof workingBlobJson.name === "string" && workingBlobJson.name.trim()) {
          title = workingBlobJson.name;
        } else if (Array.isArray(workingBlobJson.name) && workingBlobJson.name.length > 0 && workingBlobJson.name[0].text && workingBlobJson.name[0].text.trim() !== "") {
          title = workingBlobJson.name[0].text;
        }
      }
      let body = {
        'functionid': 'updatefhirresource',
        'idToken': '',
        'fhirEntry': workingBlob,
        'resourcetype': resourceType,
        'resourceid': resourceId,
        'title': title,
        'status': status,
        'bypasswarnings': false
      };
      let response = await submitToFevirServer(globalContext, 5000, body, false, false);

      //To refresh the page (without reloading the website)
      if (response?.success) {
        if (addToast) {
          addToast('The resource has been updated.', { appearance: 'success' });
        }
        history.push("/");
        history.push(`/resources/${resourceType}/${resourceId}`);
        return true;
      } else {
        if (response?.warningMessage) {
          if (window.confirm(response.warningMessage)) {
            body["bypasswarnings"] = true;
            let response = await submitToFevirServer(globalContext, 5000, body, false, false);
            if (response?.success) {
              history.push("/");
              history.push(`/resources/${resourceType}/${resourceId}`);
            } else {
              alert("Changes weren't saved.");
            }
          }
        }
      }
    } else {
      return false;
    }
  }

  useImperativeHandle(formInputsStateRef, () => ({
    comparisonState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (artifactReferenceDatatypeState === 'uri') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'artifactUri': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'canonical') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactUri': null } })
    }
  }), [artifactReferenceDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="compared-items">Compared Items</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title of this Comparison Resource'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <br /><br />
        <div><b>The Index Resource Being Compared or Adapted: </b>
          <DisplayFromFHIR reference={resourceState.artifactReference} />
          <DisplayFromFHIR uri={resourceState.artifactUri || resourceState.artifactCanonical} />
          <span className={"unselectable"} style={{ marginLeft: "24px", cursor: "pointer" }}
            onClick={() => { setArtifactReferenceEditState(!artifactReferenceEditState) }}>
            <i>Change the Resource Being Adapted</i>&nbsp;&nbsp;
            {artifactReferenceEditState ? <>▼</> : <>►</>}
          </span>
        </div>
        {artifactReferenceEditState &&
          <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
            <DatatypeSelector elementXName='artifact[x]' allowedDatatypes={['Reference', 'canonical', 'uri']}
              datatypeState={artifactReferenceDatatypeState} setDatatypeState={setArtifactReferenceDatatypeState} />
            {(artifactReferenceDatatypeState === 'uri') &&
              <DataEntry datatype='uri' elementName='artifactUri'
                fieldLabel='The Resource Being Adapted (by URL)' startingValue={resourceState.artifactUri || null}
                setResourceState={setResourceState} />}
            {(artifactReferenceDatatypeState === 'Reference') &&
              <DataEntry datatype='Reference' elementName='artifactReference' fieldLabel='The Resource Being Adapted'
                startingValue={resourceState.artifactReference || null} setResourceState={setResourceState} />}
            {(artifactReferenceDatatypeState === 'canonical') &&
              <DataEntry datatype='uri' elementName='artifactCanonical' fieldLabel='The Resource Being Adapted (by Canonical URI)'
                startingValue={resourceState.artifactCanonical || null} setResourceState={setResourceState} />}
            <br />
          </div>
        }
        <div>
          <b>The Resource(s) Being Compared</b>
          {resourceState.extension.map((extension, extensionIndex) => {
            return <div key={extensionIndex}>
              {(extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared" ||
                extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared") &&
                <>
                  {extension.valueReference && <DisplayFromFHIR reference={extension.valueReference} />}
                  {extension.valueCanonical && <DisplayFromFHIR uri={extension.valueCanonical} />}
                  {extension.valueUri && <DisplayFromFHIR uri={extension.valueUri} />}
                </>}
            </div>
          })}
          <span className={"unselectable"} style={{ marginLeft: "24px", cursor: "pointer" }}
            onClick={() => { setComparedArtifactEditState(!comparedArtifactEditState) }}>
            <i>Change the Resource(s) Being Compared (i.e. Edit the Extensions)</i>&nbsp;&nbsp;
            {comparedArtifactEditState ? <>▼</> : <>►</>}
          </span>
        </div>
        {comparedArtifactEditState &&
          <DataEntry asArray={true} datatype='Extension' elementName='extension'
            fieldLabel='The Resource Being Compared'
            extensionUrl='http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-compared'
            startingValue={resourceState.extension} setResourceState={setResourceState} />
        }
        {!resourceState.content &&
          <Button className="formButton positive" content="Create Comparison" compact
            onClick={() => {
              createComparison(resourceState, setResourceState, globalContext)
            }} />
        }
      </div>
      <h3 id="comparisons">Comparisons</h3>
      <NavigationAdaptationItemSegment resourceType={'ArtifactAssessment'} resourceId={resourceId}
        fhirEntryState={fhirEntryState}
        setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef}
        submitUpdatedFhirResource={submitUpdatedFhirResource} changeFormState={changeFormState}
        fromRightPanel={true} elementName='content' resourceState={resourceState} setResourceState={setResourceState} />

      <h3 id="adaptation-status">Adaptation Status</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='code' elementName='workflowStatus' fieldLabel='Workflow Status'
          allowedValues={['submitted', 'triaged', 'waiting-for-input', 'resolved-no-change', 'resolved-change-required', 'deferred', 'duplicate', 'applied', 'published']}
          startingValue={resourceState.workflowStatus} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='disposition' fieldLabel='Disposition'
          allowedValues={['unresolved', 'not-persuasive', 'persuasive', 'persuasive-with-modification', 'not-persuasive-with-modification']}
          startingValue={resourceState.disposition} setResourceState={setResourceState} />
        <span>
          <b>Resulting JSON</b>
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setResultingJsonExpandedState(!resultingJsonExpandedState) }}>
            {resultingJsonExpandedState ? <>▼</> : <>►</>}
          </span>
        </span>
        <br />
        {resultingJsonExpandedState &&
          <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
            {JSON.stringify(createAdaptedProductResource(fhirJson), null, 2)}
          </div>
        }
        <Button className="formButton positive" content="Submit New (Adapted) Resource" compact
          onClick={() => {
            submitNewResource(createAdaptedProductResource(fhirJson), globalContext)
          }} />
        &nbsp;&nbsp;&nbsp;
        <Button className="formButton positive" content="Update Existing Resource with Changes" compact
          onClick={() => {
            submitUpdatedAdaptedFhirResource(createAdaptedProductResource(fhirJson), history, addToast);
          }} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export { ComparisonEdit };