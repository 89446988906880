import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Beforeunload } from 'react-beforeunload';
import { Modal, Button, Segment, Table, Dimmer, Loader } from 'semantic-ui-react';
import { TextField, FormControlLabel } from '@mui/material';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import YesNoSwitch from './YesNoSwitch';

import { DisplayFromFHIR, votePermissionCheck } from './ResourceFunctions';
import { getTermsToBeVotedOn, submitCodeSystemComment } from './CodeSystemFunctions';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const BallotAlertModal = ({ sendingInVotesState, ballotAlertModalOpenState, setBallotAlertModalOpenState, history }) => {

  const modalClose = () => {
    setBallotAlertModalOpenState(false);
    if (sendingInVotesState.redirect) {
      //NEEDS TO RELOAD THIS PERSON'S VOTES
      history.push("/");
    }
  }

  return (
    <Modal
      style={{ width: "60%", padding: "16px", margin: "16px" }}
      centered={false}
      dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
      open={ballotAlertModalOpenState}
      closeOnEscape
      closeOnDimmerClick
      onClose={modalClose}
      content={<div style={{ whiteSpace: "pre-wrap" }}>
        <Button style={{ padding: "6px", position: "absolute", top: "8px", right: "8px" }} className="formButton negative" content="✖"
          onClick={() => { modalClose(); }}
        />
        <h2>{sendingInVotesState.message}</h2>
      </div>}
    />
  )
}

const MyBallotPage = ({ useTitle, changeFormState }) => {

  useTitle("FEvIR My Ballot");

  const globalContext = useContext(FevirContext);

  const history = useHistory();

  const codeSystemsToWatch = [27270, 27833];
  //const codeSystemsToWatch = [27270];

  const [codeSystemsTermsReadyForVoteState, setCodeSystemsTermsReadyForVoteState] = useState({});
  const [numberOfVotingGroupsState, setNumberOfVotingGroupsState] = useState(null);
  const [loadedState, setLoadedState] = useState(false);
  const [fullyLoadedState, setFullyLoadedState] = useState(false);
  const [sendingInVotesState, setSendingInVotesState] = useState({ loading: false, message: "", redirect: false });
  const [ballotAlertModalOpenState, setBallotAlertModalOpenState] = useState(false);
  const [fhirEntryState, setFhirEntryState] = useState({});

  const [changeMadeToMyBallot, setChangeMadeToMyBallot] = useState(false);

  useEffect(() => {
    if (sendingInVotesState.message) {
      setBallotAlertModalOpenState(true);
    }
  }, [sendingInVotesState]);

  const loadCodeSystemsTermsReadyForVote = async () => {

    if (globalContext.userState.firebaseuid && loadedState === false) {
      let numberOfVotingGroups = 0;
      setLoadedState(true);

      let codeSystemsTermsReadyForVote = {};

      for (let index in codeSystemsToWatch) {
        let codeSystemId = codeSystemsToWatch[index];

        //CHECKS TO SEE IF USER HAS PERMISSION TO VOTE IN CODE SYSTEM
        let voteResponse = await votePermissionCheck(firebase, globalContext, codeSystemId, fhirEntryState, setFhirEntryState);

        const body = {
          'functionid': 'getfhirresource',
          'resourceid': codeSystemId,
          'resourcetype': "CodeSystem",
          'justVotes': true,
          'idToken': ""
        };

        let response = await submitToFevirServer(globalContext, 20000, body, true, false);
        if (response?.success && response.fhirjsonstring) {

          let json = JSON.parse(response.fhirjsonstring);

          codeSystemsTermsReadyForVote[codeSystemId] = {};
          codeSystemsTermsReadyForVote[codeSystemId]["name"] = json.name;
          codeSystemsTermsReadyForVote[codeSystemId]["title"] = json.title;
          codeSystemsTermsReadyForVote[codeSystemId]["changeMadeToCodeSystem"] = false;
          if (voteResponse) {
            let termsToBeVotedOn = getTermsToBeVotedOn(json, response.myvotesdict);
            codeSystemsTermsReadyForVote[codeSystemId]["termsToBeVotedOn"] = termsToBeVotedOn;
            codeSystemsTermsReadyForVote[codeSystemId]["joinedGroup"] = true;
            numberOfVotingGroups += 1;
          } else {
            codeSystemsTermsReadyForVote[codeSystemId]["joinedGroup"] = false;
          }
        }

      }
      setCodeSystemsTermsReadyForVoteState(codeSystemsTermsReadyForVote);
      setNumberOfVotingGroupsState(numberOfVotingGroups);
      setFullyLoadedState(true);
    }
  };

  useEffect(() => {
    loadCodeSystemsTermsReadyForVote();
  }, [globalContext.userState]);

  useEffect(() => {
    changeFormState("FEvIR®: My Ballot", "pageTitle");
  }, []);


  const submitVotes = async (codeSystemId, setSendingInVotesState, codeSystemSet) => {
    setSendingInVotesState({ loading: true, message: "", redirect: false });
    setCodeSystemsTermsReadyForVoteState(prevState => {
      let newObj = prevState[codeSystemId];
      newObj["changeMadeToCodeSystem"] = false;
      return { ...prevState, [codeSystemId]: newObj }
    });

    let noProblems = true;
    let alertMessage = "";

    //Validation loop
    for (let termIndex in codeSystemSet.termsToBeVotedOn) {
      let term = codeSystemSet.termsToBeVotedOn[termIndex];
      if (term.changeMade) {
        if (term.voteLabel) {
          if (term.voteLabel === "No") {
            if (term.voteComment) {

            } else {
              alertMessage += "All No votes must have a comment. Please check: " + term.display + "\n";
            }

          }
        } else {
          alertMessage += "Comment added, but Yes or No were not selected for: " + term.display + "\n";
        }
      }
    }
    if (alertMessage) {
      alertMessage = "You cannot submit votes until correcting the following:\n\n" + alertMessage;
      //alert(alertMessage);
    } else {
      //Submit loop
      let votes = [];
      let voteDisplays = []
      let votesSubmitted = [];
      let votesFailed = [];
      for (let termIndex in codeSystemSet.termsToBeVotedOn) {
        let term = codeSystemSet.termsToBeVotedOn[termIndex];
        if (term.changeMade) {
          let termVoteBoolean = term.voteLabel.toLowerCase() !== "no";
          let termCopy = JSON.parse(JSON.stringify(term));
          delete termCopy.comments;
          delete termCopy.votes;
          delete termCopy.myvotes;
          delete termCopy.latestValidVote;
          delete termCopy.systemId;
          delete termCopy.systemTitle;
          delete termCopy.systemName;
          delete termCopy.voteLabel;
          delete termCopy.voteComment;
          delete termCopy.changeMade;
          let vote = {
            'codesystem': codeSystemId,
            'termcode': term.code,
            'termconcept': termCopy,
            'vote': termVoteBoolean,  //How the user voted true/false or yes/no
            'votechoice': term.voteLabel,   //For something other than true/false or yes/no, but I'll record the yes/no anyways.
            'votecomment': term.voteComment
          };
          votes.push(vote);
          voteDisplays.push(term.display);
        }
      }
      if (votes.length > 0) {
        let response = await submitCodeSystemComment(globalContext, "vote", firebase, codeSystemId, codeSystemSet.title, null, null, null, null, null, null, null, false, undefined, votes);
        if (response?.success) {
          votesSubmitted = voteDisplays;
        } else {
          votesFailed = voteDisplays;
        }
      }
      if (noProblems) {
        let redirect = true;
        setCodeSystemsTermsReadyForVoteState(prevState => {
          let newObj = prevState[codeSystemId];
          newObj["changeMadeToCodeSystem"] = false;
          for (let termIndex in newObj["termsToBeVotedOn"]) {
            newObj["termsToBeVotedOn"][termIndex]["changeMade"] = false;
          }
          return { ...prevState, [codeSystemId]: newObj }
        });
        for (let codeSystemKey in codeSystemsTermsReadyForVoteState) {
          if (codeSystemKey !== codeSystemId && codeSystemsTermsReadyForVoteState[codeSystemKey]["changeMadeToCodeSystem"]) {
            redirect = false;
            break;
          }
        }
        setSendingInVotesState({ loading: false, message: "All votes submitted: " + votesSubmitted.join(', '), redirect: redirect });
        if (redirect) {
          setChangeMadeToMyBallot(false);
          //history.push("/");
        }
      } else {
        alertMessage = "Not all votes submitted. These terms had a problem being submitted: " + votesFailed.join(', ');
      }
    }
    if (alertMessage) {
      setSendingInVotesState({ loading: false, message: alertMessage, redirect: false });
      setCodeSystemsTermsReadyForVoteState(prevState => {
        let newObj = prevState[codeSystemId];
        newObj["changeMadeToCodeSystem"] = true;
        return { ...prevState, [codeSystemId]: newObj }
      });
    }
  }

  return (
    <div>
      {/*Loading spinner when a user submits their votes until it's fully submitted*/}
      {sendingInVotesState.loading
        &&
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      }
      <BallotAlertModal sendingInVotesState={sendingInVotesState} ballotAlertModalOpenState={ballotAlertModalOpenState} setBallotAlertModalOpenState={setBallotAlertModalOpenState} history={history} />
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        {/*<br /><br />*/}
        {/*Loading spinner until all info is loaded.*/}
        {((globalContext.userState.id || globalContext.userState.loading) && fullyLoadedState === false)
          &&
          <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        }
        <div>
          {changeMadeToMyBallot && <Beforeunload onBeforeunload={(event) => { event.preventDefault(); }} />}
          {globalContext.userState.firebaseuid ?
            <>
              {numberOfVotingGroupsState === 0 ?
                <h2>You are not signed up with any voting group.</h2>
                :
                <>
                  {Object.keys(codeSystemsTermsReadyForVoteState).map((codeSystemId, index) => {
                    let codeSystemSet = codeSystemsTermsReadyForVoteState[codeSystemId];
                    let termsAvailable = codeSystemSet.termsToBeVotedOn?.length > 0;
                    return <div key={index}>
                      {/*<h2>{codeSystemSet.title}</h2>*/}
                      {codeSystemSet.joinedGroup ?
                        <>
                          <Table>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell colSpan="6" style={{ backgroundColor: "#F0F4FC" }}>
                                  <table className="noStyleTable" style={{ width: "100%" }}><tbody><tr>
                                    <td style={{ padding: "0px", margin: "0px" }}><span style={{ fontSize: "22px", fontWeight: "bold" }}>{codeSystemSet.title}</span></td>
                                    <td style={{ textAlign: "right", padding: "0px", margin: "0px", width: "125px" }}><Button className="formButton" content="Submit Votes" style={{ color: "#000000" }} compact disabled={!codeSystemSet.changeMadeToCodeSystem} onClick={() => { submitVotes(codeSystemId, setSendingInVotesState, codeSystemSet); }} /></td>
                                  </tr></tbody></table>
                                </Table.HeaderCell>
                              </Table.Row>
                              <Table.Row>
                                <Table.HeaderCell>Term</Table.HeaderCell>
                                <Table.HeaderCell>Definition</Table.HeaderCell>
                                <Table.HeaderCell>Alternative Terms<br />(if any)</Table.HeaderCell>
                                <Table.HeaderCell>Comment for application<br />(if any)</Table.HeaderCell>
                                <Table.HeaderCell>Vote</Table.HeaderCell>
                                <Table.HeaderCell>Comment</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {termsAvailable ? codeSystemSet.termsToBeVotedOn.map((term, termIndex) => {
                                //let latestValidVote = term.latestValidVote;
                                let rowStyle = {};
                                if (term.voteLabel === "Yes") {
                                  rowStyle["backgroundColor"] = "#00FF0012";
                                } else if (term.voteLabel === "No") {
                                  rowStyle["backgroundColor"] = "#FF000012";
                                }
                                return <Table.Row key={termIndex} style={rowStyle}>
                                  <Table.Cell style={{ verticalAlign: "top" }}>{term.code ? <a href={"/resources/CodeSystem/" + codeSystemId.toString() + "#" + term.code} target="_blank" rel="noopener noreferrer">{term.display}</a> : term.display}</Table.Cell>
                                  <Table.Cell style={{ verticalAlign: "top" }}>{term.definition}</Table.Cell>
                                  <Table.Cell style={{ verticalAlign: "top", paddingLeft: "0px" }}>
                                    <ul style={{ margin: "0px" }}>
                                      {term.alternativeTerms.map((alternativeTerm, alternativeTermIndex) => {
                                        return <li key={alternativeTermIndex}>{alternativeTerm}</li>
                                      })}
                                    </ul>
                                  </Table.Cell>
                                  <Table.Cell style={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}><DisplayFromFHIR markdown={term.commentsForApplication} /></Table.Cell>
                                  <Table.Cell style={{ align: "center", padding: "0px" }}>
                                    {term.voteLabel ?
                                      <>
                                        <FormControlLabel className="yesNoSliderLabel" style={{ marginLeft: "0px", marginRight: "0px", width: "100%" }}
                                          control={<YesNoSwitch className="yesNoSliderSwitch" value={term.voteLabel} checked={term.voteLabel === "Yes" ? true : false}
                                            onClick={(e, data) => {
                                              setCodeSystemsTermsReadyForVoteState(prevState => {
                                                let newObj = prevState[codeSystemId];

                                                if (term.voteLabel === "No") {
                                                  newObj["termsToBeVotedOn"][termIndex]["voteLabel"] = "Yes";
                                                } else {
                                                  newObj["termsToBeVotedOn"][termIndex]["voteLabel"] = "No";
                                                }
                                                newObj["termsToBeVotedOn"][termIndex]["changeMade"] = true;
                                                newObj["changeMadeToCodeSystem"] = true;
                                                return { ...prevState, [codeSystemId]: newObj }
                                              });
                                              setChangeMadeToMyBallot(true);
                                            }} />}
                                        />
                                      </>
                                      :
                                      <div style={{ align: "center", width: "100%", padding: "8px" }}>
                                        <Button className="formButton positive" content="Yes" compact style={{ width: "48px", paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
                                          onClick={() => {
                                            setCodeSystemsTermsReadyForVoteState(prevState => {
                                              let newObj = prevState[codeSystemId];
                                              newObj["termsToBeVotedOn"][termIndex]["voteLabel"] = "Yes";
                                              newObj["termsToBeVotedOn"][termIndex]["changeMade"] = true;
                                              newObj["changeMadeToCodeSystem"] = true;
                                              return { ...prevState, [codeSystemId]: newObj }
                                            });
                                            setChangeMadeToMyBallot(true);
                                          }} />
                                        <br />
                                        <div style={{ paddingTop: "8px" }} />
                                        <Button className="formButton negative" content="No" compact
                                          style={{ width: "48px", paddingLeft: "0px", paddingRight: "0px", margin: "0px" }}
                                          onClick={() => {
                                            setCodeSystemsTermsReadyForVoteState(prevState => {
                                              let newObj = prevState[codeSystemId];
                                              newObj["termsToBeVotedOn"][termIndex]["voteLabel"] = "No";
                                              newObj["termsToBeVotedOn"][termIndex]["changeMade"] = true;
                                              newObj["changeMadeToCodeSystem"] = true;
                                              return { ...prevState, [codeSystemId]: newObj }
                                            });
                                            setChangeMadeToMyBallot(true);
                                          }} />
                                      </div>
                                    }
                                  </Table.Cell>
                                  <Table.Cell style={{ verticalAlign: "top" }}>
                                    <TextField style={{ minWidth: "400px", height: "100%" }} multiline className="inputField" type='text' label={'Vote Comment'} size="small" variant='outlined' value={term.voteComment}
                                      onChange={(e) => {
                                        setCodeSystemsTermsReadyForVoteState(prevState => {
                                          let newObj = prevState[codeSystemId];
                                          newObj["termsToBeVotedOn"][termIndex]["voteComment"] = e.target.value;
                                          newObj["termsToBeVotedOn"][termIndex]["changeMade"] = true;
                                          newObj["changeMadeToCodeSystem"] = true;
                                          return { ...prevState, [codeSystemId]: newObj }
                                        });
                                        setChangeMadeToMyBallot(true);
                                      }}

                                    />
                                  </Table.Cell>
                                </Table.Row>
                              })
                                :
                                <Table.Row>
                                  <Table.Cell colSpan="6" style={{ textAlign: "center" }}><h4>There are no terms open for vote at this time.</h4></Table.Cell>
                                </Table.Row>
                              }
                              <Table.Row>
                                <Table.Cell colSpan="6" style={{ textAlign: "right" }}><Button className="formButton" content="Submit Votes" style={{ color: "#000000" }} compact disabled={!codeSystemSet.changeMadeToCodeSystem} onClick={() => { submitVotes(codeSystemId, setSendingInVotesState, codeSystemSet); }} /></Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                          <div style={{ width: "100%", textAlign: "right" }}>{termsAvailable && <><br /></>}</div>
                        </>
                        :
                        <>{/*<h3>For voting privileges, please join the {codeSystemSet.title} group.</h3>*/}</>
                      }

                      <br /><br />
                    </div>
                  })}
                </>
              }
            </>
            :
            <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
          }
        </div>
      </Segment>
    </div>
  );
};

export default MyBallotPage;