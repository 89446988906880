const SEVCO = {
  studyDesign: [
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01001",
      "display": "Interventional research"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01003",
      "display": "randomized assignment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01006",
      "display": "simple randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01007",
      "display": "stratified randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01008",
      "display": "block randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01009",
      "display": "adaptive randomization"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01005",
      "display": "Non-randomized assignment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01004",
      "display": "Quasi-Randomized assignment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01029",
      "display": "Clinical trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01041",
      "display": "Pragmatic clinical trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01038",
      "display": "Expanded Access study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01030",
      "display": "Phase 1 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01031",
      "display": "Exploratory investigational new drug study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01032",
      "display": "Phase 1/Phase 2 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01033",
      "display": "Phase 2 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01034",
      "display": "Phase 2/Phase 3 trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01035",
      "display": "Phase 3 Trial"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01036",
      "display": "Post-marketing study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01002",
      "display": "Observational research"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01037",
      "display": "Post-Marketing Surveillance study"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01010",
      "display": "Comparative study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01011",
      "display": "Parallel cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01012",
      "display": "Crossover cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01024",
      "display": "Controlled crossover cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01025",
      "display": "Single-arm crossover design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01013",
      "display": "Case control design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01014",
      "display": "Matching for comparison"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01020",
      "display": "Family study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01021",
      "display": "Twin study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01015",
      "display": "Cluster as unit of allocation"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01023",
      "display": "Non-comparative study design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01016",
      "display": "Uncontrolled cohort design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01017",
      "display": "Case report"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01022",
      "display": "Population-based design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01044",
      "display": "Ecological design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01027",
      "display": "Cross sectional data collection "
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01028",
      "display": "Longitudinal data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01018",
      "display": "Time series design"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01019",
      "display": "Before and after comparison"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01045",
      "display": "Primary data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01026",
      "display": "Real world data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01039",
      "display": "Real world data collection from healthcare records"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01050",
      "display": "Real world data collection from personal health records"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01040",
      "display": "Real world data collection from healthcare financing records"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01048",
      "display": "Real world data collection from testing procedures"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01046",
      "display": "Real world data collection from monitoring procedures"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01049",
      "display": "Secondary data collection from prior research"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01042",
      "display": "Secondary data collection from a registry"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01051",
      "display": "Multisite data collection"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01086",
      "display": "Quantitative analysis"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01087",
      "display": "Qualitative analysis"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01060",
      "display": "Blinding of study participants"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01061",
      "display": "Blinding of intervention providers"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01062",
      "display": "Blinding of outcome assessors"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01063",
      "display": "Blinding of data analysts"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01064",
      "display": "Allocation concealment"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01043",
      "display": "Multicentric"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01052",
      "display": "Includes patient-reported outcome"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01053",
      "display": "Includes patient-centered outcome"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01054",
      "display": "Includes disease-oriented outcome"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01085",
      "display": "Includes process measure"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01089",
      "display": "Study Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01096",
      "display": "Evaluation Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01097",
      "display": "Derivation Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01098",
      "display": "Validation Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01088",
      "display": "Comparison Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01091",
      "display": "Comparative Effectiveness Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01090",
      "display": "Comparative Efficacy Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01092",
      "display": "Comparative Safety Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01093",
      "display": "Equivalence Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01094",
      "display": "Non-inferiority Goal"
    },
    {
      "system": "https://fevir.net/sevco",
      "code": "SEVCO:01095",
      "display": "Superiority Goal"
    }
  ],
  statisticType: [
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000047",
      "display": "Count"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000001",
      "display": "Sum"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000002",
      "display": "Maximum Observed Value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000003",
      "display": "Minimum Observed Value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000004",
      "display": "Maximum Possible Value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000005",
      "display": "Minimum Possible Value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000006",
      "display": "Threshold"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000069",
      "display": "Degrees of Freedom"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000029",
      "display": "Measure of Central Tendency"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000573",
      "display": "Mean"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000177",
      "display": "Mean of differences"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000658",
      "display": "Mean time-to-event"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000396",
      "display": "Geometric Mean"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000574",
      "display": "Median"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000659",
      "display": "Median time-to-event"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000033",
      "display": "Mode"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000397",
      "display": "Harmonic Mean"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000613",
      "display": "Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000614",
      "display": "Absolute Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000616",
      "display": "Count Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000457",
      "display": "Difference in means"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000617",
      "display": "Difference in medians"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000424",
      "display": "Risk Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000178",
      "display": "Difference-in-differences"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000180",
      "display": "Relative Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000615",
      "display": "Relative Mean Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000010",
      "display": "Relative Risk Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000100",
      "display": "Standardized Mean Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000618",
      "display": "Cohen’s d statistic"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000135",
      "display": "Strictly standardized mean difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000319",
      "display": "Hedges’s g"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000320",
      "display": "Glass’s delta"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000634",
      "display": "Reciprocal of Difference"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000635",
      "display": "Number Needed to Treat "
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000637",
      "display": "Number Needed to Screen to Detect"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000636",
      "display": "Number Needed to Screen to Prevent"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000638",
      "display": "Number Needed to Harm"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000184",
      "display": "Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000639",
      "display": "Percentage"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:MeasAccu",
      "display": "Measurement Accuracy"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000607",
      "display": "Proportion"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000413",
      "display": "Incidence"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000412",
      "display": "Prevalence"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000233",
      "display": "Sensitivity"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000134",
      "display": "Specificity"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000022",
      "display": "Positive Predictive Value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000619",
      "display": "Negative Predictive Value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000621",
      "display": "Diagnostic Yield"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000620",
      "display": "Risk"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000627",
      "display": "Odds"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000645",
      "display": "Rate"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000024",
      "display": "Incidence Rate"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000025",
      "display": "Hazard Rate"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000168",
      "display": "Event Rate"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000171",
      "display": "Event Frequency Rate"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000170",
      "display": "Event Frequency"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000172",
      "display": "Density"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000173",
      "display": "Number Density"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000175",
      "display": "Concentration"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000610",
      "display": "Measure of Association"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000622",
      "display": "Ratio-based Measure of Association"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000026",
      "display": "Hazard Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000027",
      "display": "Incidence Rate Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000032",
      "display": "Standardized Incidence Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000182",
      "display": "Odds Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000028",
      "display": "Prevalence Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000245",
      "display": "Risk Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000411",
      "display": "Likelihood Ratio Positive"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000410",
      "display": "Likelihood Ratio Negative"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000029",
      "display": "Positive Clinical Utility Index"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000030",
      "display": "Negative Clinical Utility Index"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000415",
      "display": "Diagnostic Accuracy"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000033",
      "display": "Diagnostic Odds Ratio"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000524",
      "display": "Phi coefficient"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000623",
      "display": "Measure of Agreement"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000034",
      "display": "Kappa"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000037",
      "display": "Simple chance-corrected agreement coefficient"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000630",
      "display": "Cohen’s Kappa"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000631",
      "display": "Modified Cohen’s Kappa for more than 2 raters"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000629",
      "display": "Scott’s pi"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000632",
      "display": "Misclassification Rate"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000628",
      "display": "F1-score"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000611",
      "display": "Measure of Correlation"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000301",
      "display": "Covariance"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000280",
      "display": "Pearson Correlation Coefficient"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000201",
      "display": "Spearman Rank-Order Correlation Coefficient"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000240",
      "display": "Kendall Correlation Coefficient"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000612",
      "display": "Goodman and Kruskal’s Gamma"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000565",
      "display": "Regression Coefficient"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000044",
      "display": "Measure of Calibration"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000045",
      "display": "Mean calibration"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000046",
      "display": "Calibration-in-the-large"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000047",
      "display": "Calibration intercept"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000048",
      "display": "Calibration slope"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000028",
      "display": "Measure of Dispersion"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000035",
      "display": "Range"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:00000164",
      "display": "Interquartile range"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000237",
      "display": "Standard deviation"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000051",
      "display": "distribution standard deviation"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000052",
      "display": "Standard deviation for sample"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000113",
      "display": "Variance"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000053",
      "display": "distribution variance"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000055",
      "display": "Variance for sample"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000056",
      "display": "variance of the sampling distribution"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000624",
      "display": "Gini index"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000562",
      "display": "Standard error"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000037",
      "display": "Standard error of the mean"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000647",
      "display": "Standard error of the proportion"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000648",
      "display": "Standard error of the difference between independent means"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000649",
      "display": "Standard error of the difference between independent proportions"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000455",
      "display": "Credible interval"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000196",
      "display": "Confidence interval"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000066b",
      "display": "area under the precision-recall curve"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000209",
      "display": "Area Under the Curve"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000608",
      "display": "area under the ROC curve"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000418",
      "display": "Measure of Heterogeneity"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000066a",
      "display": "area under the value-time curve"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000419",
      "display": "Cochran’s Q"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000420",
      "display": "I-squared"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000421",
      "display": "Tau squared"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000609",
      "display": "Hypothesis Testing Measure"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000074",
      "display": "Chi square for independence"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000075",
      "display": "Chi square for trend"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000076",
      "display": "P-value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000661",
      "display": "p-value for one-sided test"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000662",
      "display": "p-value for two-sided test"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000104",
      "display": "Z-score"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000376",
      "display": "Z-statistic"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000176",
      "display": "t-statistic"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000266",
      "display": "Bayes factor"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "TBD:0000179",
      "display": "Absolute Value"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000291",
      "display": "Quantile"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000293",
      "display": "Percentile"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000292",
      "display": "Decile"
    },
    {
      "system": "https://fevir.net/resources/CodeSystem/27270",
      "code": "STATO:0000152",
      "display": "Quartile"
    }
  ]
}

export default SEVCO;