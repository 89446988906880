import React, { useState, useEffect, useContext, useImperativeHandle } from 'react';
import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { Button } from 'semantic-ui-react';
import { createAdaptedProductResource } from './AdaptationFunctions';
import NavigationAdaptationItemSegment from './NavigationAdaptationItemSegment';
import { useHistory } from "react-router-dom";
import { DisplayClassifiers } from './MetadataPatternDisplay';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const AdaptationEdit = ({ fhirJson, fhirEntryState, formInputsStateRef, resourceId, setFhirEntryState,
  submitUpdatedFhirResource, changeFormState, addToast, citationSummary,
  citationJson, classificationsArrayState, classificationsLoadedState }) => {

  const globalContext = useContext(FevirContext);

  let startingArtifactReferenceDatatype = 'none';
  if (fhirJson.artifactReference) { startingArtifactReferenceDatatype = 'Reference'; }
  if (fhirJson.artifactCanonical) { startingArtifactReferenceDatatype = 'canonical'; }
  if (fhirJson.artifactUri) { startingArtifactReferenceDatatype = 'uri'; }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "ArtifactAssessment", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "artifactReference": fhirJson.artifactReference, "artifactCanonical": fhirJson.artifactCanonical, "artifactUri": fhirJson.artifactUri,
    "content": fhirJson.content, "workflowStatus": fhirJson.workflowStatus, "disposition": fhirJson.disposition,
    "citeAsReference": fhirJson.citeAsReference, "citeAsMarkdown": fhirJson.citeAsMarkdown
  });

  const [resultingJsonExpandedState, setResultingJsonExpandedState] = useState(false);
  const [artifactReferenceEditState, setArtifactReferenceEditState] = useState(false);
  const [artifactReferenceDatatypeState, setArtifactReferenceDatatypeState] = useState(startingArtifactReferenceDatatype);

  const history = useHistory();

  const submitNewResource = async (newResourceJson, globalContext) => {
    const setUserState = globalContext.setUserState;
    let title = newResourceJson.title || newResourceJson.name || "";
    if (!title || title.trim() === "") {
      title = prompt("Please give the new Resource a title.")
    }

    let fhirEntryString = JSON.stringify(newResourceJson, null, 2);

    const body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': fhirEntryString,
      'title': title,
      'status': newResourceJson.status || 'active',
    };

    let response = await submitToFevirServer(globalContext, 5000, body, true, false);

    if (response.success) {
      history.push("/");
      history.push(`/resources/${response.resourcetype}/${response.formstateid}`);
      setUserState(prevState => { return { ...prevState } }); //I guess this is to trigger the userState useEffects?
    }
  };


  const submitUpdatedAdaptedFhirResource = async (updatedResourceJson, history, addToast) => {
    let title;
    let resourceType = updatedResourceJson.resourceType;
    let resourceId = updatedResourceJson.id;
    let status = updatedResourceJson.status || 'active';

    let workingBlob;
    try {
      workingBlob = JSON.stringify(updatedResourceJson);
    } catch { }
    if (workingBlob) {
      let workingBlobJson = JSON.parse(workingBlob);
      if (workingBlobJson.title && typeof workingBlobJson.title === "string" && workingBlobJson.title.trim()) {
        title = workingBlobJson.title;
      } else if (workingBlobJson.name) {
        if (typeof workingBlobJson.name === "string" && workingBlobJson.name.trim()) {
          title = workingBlobJson.name;
        } else if (Array.isArray(workingBlobJson.name) && workingBlobJson.name.length > 0 && workingBlobJson.name[0].text && workingBlobJson.name[0].text.trim() !== "") {
          title = workingBlobJson.name[0].text;
        }
      }
      let body = {
        'functionid': 'updatefhirresource',
        'idToken': '',
        'fhirEntry': workingBlob,
        'resourcetype': resourceType,
        'resourceid': resourceId,
        'title': title,
        'status': status,
        'bypasswarnings': false
      };
      let response = await submitToFevirServer(globalContext, 5000, body, false, false);

      //To refresh the page (without reloading the website)
      if (response?.success) {
        if (addToast) {
          addToast('The resource has been updated.', { appearance: 'success' });
        }
        history.push("/");
        history.push(`/resources/${resourceType}/${resourceId}`);
        return true;
      } else {
        if (response?.warningMessage) {
          if (window.confirm(response.warningMessage)) {
            body["bypasswarnings"] = true;
            let response = await submitToFevirServer(globalContext, 5000, body, false, false);
            if (response?.success) {
              history.push("/");
              history.push(`/resources/${resourceType}/${resourceId}`);
            } else {
              globalContext.openAlert({ "header": "Error", "content": "Changes weren't saved." });
            }
          }
        }
      }
    } else {
      return false;
    }
  }

  useImperativeHandle(formInputsStateRef, () => ({
    adaptationState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (artifactReferenceDatatypeState === 'uri') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'artifactUri': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'canonical') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactUri': null } })
    }
  }), [artifactReferenceDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="adapted-item">Adapted Item</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title of this Adaptation Resource'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <br /><br />
        <div><b>The Resource Being Adapted: </b>
          <DisplayFromFHIR reference={resourceState.artifactReference} />
          <DisplayFromFHIR uri={resourceState.artifactUri} />
          <span className={"unselectable"} style={{ marginLeft: "24px", cursor: "pointer" }} onClick={() => { setArtifactReferenceEditState(!artifactReferenceEditState) }}>
            <i>Change the Resource Being Adapted</i>&nbsp;&nbsp;
            {artifactReferenceEditState ? <>▼</> : <>►</>}
          </span>
        </div>
        {artifactReferenceEditState &&
          <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
            <DatatypeSelector elementXName='artifact[x]' allowedDatatypes={['Reference', 'canonical', 'uri']}
              datatypeState={artifactReferenceDatatypeState} setDatatypeState={setArtifactReferenceDatatypeState} />
            {(artifactReferenceDatatypeState === 'uri') &&
              <DataEntry datatype='uri' elementName='artifactUri'
                fieldLabel='The Resource Being Adapted (by URL)' startingValue={resourceState.artifactUri || null}
                setResourceState={setResourceState} />}
            {(artifactReferenceDatatypeState === 'Reference') &&
              <DataEntry datatype='Reference' elementName='artifactReference' fieldLabel='The Resource Being Adapted'
                startingValue={resourceState.artifactReference || null} setResourceState={setResourceState} />}
            {(artifactReferenceDatatypeState === 'canonical') &&
              <DataEntry datatype='uri' elementName='artifactCanonical' fieldLabel='The Resource Being Adapted (by Canonical URI)'
                startingValue={resourceState.artifactCanonical || null} setResourceState={setResourceState} />}
            <br />
          </div>
        }
      </div>
      <h3 id="adaptations">Adaptations</h3>
      <NavigationAdaptationItemSegment resourceType={'ArtifactAssessment'} resourceId={resourceId} fhirEntryState={fhirEntryState}
        setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} 
        submitUpdatedFhirResource={submitUpdatedFhirResource} changeFormState={changeFormState}
        fromRightPanel={true} elementName='content' resourceState={resourceState} setResourceState={setResourceState} />

      <h3 id="adaptation-status">Adaptation Status</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='code' elementName='workflowStatus' fieldLabel='Workflow Status'
          allowedValues={['submitted', 'triaged', 'waiting-for-input', 'resolved-no-change', 'resolved-change-required', 'deferred', 'duplicate', 'applied', 'published']}
          startingValue={resourceState.workflowStatus} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='disposition' fieldLabel='Disposition'
          allowedValues={['unresolved', 'not-persuasive', 'persuasive', 'persuasive-with-modification', 'not-persuasive-with-modification']}
          startingValue={resourceState.disposition} setResourceState={setResourceState} />
        <span>
          <b>Resulting JSON</b>
          <span className={"unselectable"} style={{ cursor: "pointer" }}
            onClick={() => { setResultingJsonExpandedState(!resultingJsonExpandedState) }}>
            {resultingJsonExpandedState ? <>▼</> : <>►</>}
          </span>
        </span>
        <br />
        {resultingJsonExpandedState &&
          <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
            {JSON.stringify(createAdaptedProductResource(fhirJson), null, 2)}
          </div>
        }
        <Button className="formButton positive" content="Submit New (Adapted) Resource" compact
          onClick={() => {
            submitNewResource(createAdaptedProductResource(fhirJson), globalContext)
          }} />
        &nbsp;&nbsp;&nbsp;
        <Button className="formButton positive" content="Update Existing Resource with Changes" compact
          onClick={() => {
            submitUpdatedAdaptedFhirResource(createAdaptedProductResource(fhirJson), history, addToast);
          }} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default AdaptationEdit;