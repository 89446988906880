import { Button, Modal, Dropdown, Table } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import DropDownWithAdditions from './DropDownWithAdditions';
import { addResourceElementState } from './ResourceFunctions';
import { characteristicValuePresetOptions, groupCharacteristicKindLookup } from './CodeSystemLookup';

const DeleteCharacteristicRowButton = ({state, changeState, stateSetter, stateEntryKey, index, characteristicString}) => {
    return (
        <Modal
        trigger={<span className={"unselectable"} style={{cursor: "pointer", fontWeight: "normal", fontSize: "42px"}} title="Remove this entire row">🗑</span>}
        header='Are you sure you want to delete the Characteristic Row?'
        centered={false}
        content={<div style={{paddingLeft: "20px"}}>
            {characteristicString && <span><b>Delete: </b><span style={{whiteSpace: "pre-wrap"}}>{characteristicString}</span></span>}
        </div>}
        actions={[
            {
                key: 'delete',
                positive: false,
                content: 'Delete',
                onClick: () => changeState(stateSetter, true, undefined, undefined, stateEntryKey, index, "delete")
            },
            {
                key: 'done',
                content: 'Close',
                negative: true
            }
        ]}
        />
    )
}

const CharacteristicsTable = ({criteriaType, editMode, smallCriteriaHeader, groupCriteriaCharacteristics, stateSetter, changeState, groupCharacteristicKindOptions, setGroupCharacteristicKindOptions}) => {

    let headerCellColor = "#F9FAFB";
    if (criteriaType.toLowerCase() === "inclusion") {
        headerCellColor = "#EAFCEA";
    } else if (criteriaType.toLowerCase() === "exclusion") {
        headerCellColor = "#FCEAEA";
    }

    return <>
        {smallCriteriaHeader === false && <h3 id={criteriaType.toLowerCase()+"-criteria"} style={{marginTop: "0px"}}>{criteriaType} Criteria</h3> }
        <div style={{marginLeft: "24px"}}>
        {groupCriteriaCharacteristics?.length > 0 && <>
            {smallCriteriaHeader && <b>{criteriaType} Criteria</b>}
            <Table style={{margin: "0px"}}>
            <Table.Header>
                <Table.Row>
                {editMode && <Table.HeaderCell>Delete</Table.HeaderCell>}
                <Table.HeaderCell style={{width: "30%", backgroundColor: headerCellColor}}>Type of Characteristic</Table.HeaderCell>
                <Table.HeaderCell style={{width: "70%", backgroundColor: headerCellColor}}>Characteristic Value</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {groupCriteriaCharacteristics.map((characteristic, index) => {
                    let reference = "";
                    if (characteristic.valueInternalReference) {
                        let slashesFound = characteristic.valueInternalReference.toString().match(/\//g);
                        if (slashesFound?.length === 1) {
                        reference = characteristic.valueInternalReference;
                        }
                    }

                    let typeCode = characteristic.typeCode;
                    let valueType = characteristic.valueType;
                    let valuePickList = [{key: "codeableConcept", value: "codeableConcept", text: "Codeable Concept"}, {key: "reference", value: "reference", text: "Reference"}, {key: "quantity", value: "quantity", text: "Quantity"}, {key: "range", value: "range", text: "Range"}, {key: "boolean", value: "boolean", text: "Boolean"}, {key: "expression", value: "expression", text: "Expression"}];
                    let chooseValueDataType = true

                    /*if (typeCode === "64572001" || typeCode === "103579009" || typeCode === "186034007" || typeCode === "246112005" || typeCode === "260905004" || typeCode === "263495000") {*/
                    if (typeCode.toLowerCase() === "defined by codeableconcept") { 
                        valueType = "codeableConcept";
                        chooseValueDataType = false;
                    } else if (typeCode === "In Group of" || typeCode === "Research Study from which this is the observed sample") {
                        valueType = "reference";
                        chooseValueDataType = false;
                    }
                    //console.log(valueType);
                    if (chooseValueDataType) {
                        if (typeCode === "Exposure (intervention)" || typeCode === "Medication (exposure or intervention)" || typeCode === "246267002" || typeCode === "305335007" || typeCode === "64572001" || typeCode === "103579009" || typeCode === "186034007" || typeCode === "246112005" || typeCode === "260905004" || typeCode === "263495000") {
                            valuePickList = [{key: "codeableConcept", value: "codeableConcept", text: "Codeable Concept"}, {key: "reference", value: "reference", text: "Reference"}];
                        } else if (typeCode === "397669002") {
                            valuePickList = [{key: "codeableConcept", value: "codeableConcept", text: "Codeable Concept"}, {key: "quantity", value: "quantity", text: "Quantity"}, {key: "range", value: "range", text: "Range"}];
                        } else if (typeCode === "Measurement values") {
                            valuePickList = [{key: "codeableConcept", value: "codeableConcept", text: "Codeable Concept"}, {key: "expression", value: "expression", text: "Expression"}];
                        }
                    }
                    if (chooseValueDataType ) {
                        if (valueType === "" || valueType === undefined) {
                            if (typeCode === "246267002" || typeCode === "305335007" || typeCode === "Exposure (intervention)" || typeCode === "Medication (exposure or intervention)" || typeCode === "Measurement values") {
                                valueType = "codeableConcept";
                                chooseValueDataType = true;
                            } else if (typeCode === "397669002") {
                                valueType = "range";
                                chooseValueDataType = true;
                            }
                        }
                    }
                    let rowStyle = {};
                    if (characteristic.delete) {
                        rowStyle["display"] = "none";
                    }
                    return <Table.Row key={index} style={rowStyle}>
                        {editMode ?
                        <>
                            <Table.Cell style={{verticalAlign: "top", align: "center"}}>
                                <div style={{width: "100%", textAlign: "center", paddingTop: "10px"}}>
                                    <DeleteCharacteristicRowButton changeState={changeState} stateSetter={stateSetter} stateEntryKey={"group"+criteriaType+"Characteristics"} index={index} characteristicString={JSON.stringify(Object.fromEntries(Object.entries(characteristic).filter(([_, v]) => v != "")), null, 2)} />
                                </div>
                            </Table.Cell>
                            <Table.Cell style={{verticalAlign: "top"}}>
                                <DropDownWithAdditions currentValue={characteristic.typeCode} labelText={'Pick Characteristic Type'} options={groupCharacteristicKindOptions.options} optionsLookup={groupCharacteristicKindLookup} setter={setGroupCharacteristicKindOptions} stateSetter={stateSetter} changeElementState={changeState} index={index} elementKeyEntry={"group"+criteriaType+"Characteristics"} elementKeySubEntry={"typeCode"} multiple={false} />
                                <div style={{margin: "8px"}}></div>
                            </Table.Cell>
                            <Table.Cell style={{verticalAlign: "top"}}>
                            {chooseValueDataType && <>
                                <Dropdown
                                    name="characteristicvaluetype"
                                    placeholder="Characteristic Value Type"
                                    closeOnChange selection selectOnBlur={false} autoComplete="off"
                                    style={{minWidth: "25%", width: "25%"}}
                                    options={valuePickList}
                                    value={valueType}
                                    onChange={(e, data) => {
                                        if ((characteristic.coding?.length > 0 && (characteristic.coding[0].valueCode || characteristic.coding[0].valueDisplay || characteristic.coding[0].valueSystem)) ||
                                            characteristic.value || characteristic.valueInternalReference || characteristic.valueReferenceDisplay ||
                                            characteristic.valueLow || characteristic.valueLowUnit || characteristic.valueHigh || characteristic.valueHighUnit ||
                                            characteristic.valueQuantity || characteristic.valueUnit ||
                                            (characteristic.valueComparator && characteristic.valueComparator !== "=") ||
                                            (characteristic.valueBoolean !== "" && characteristic.valueBoolean !== undefined)) {
                                                alert("You switched datatypes, so previously entered data was lost for this characteristic value.");
                                        }
                                        changeState(stateSetter, data.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueType");
                                        if (data.value === "codeableConcept" && (characteristic.coding === undefined || characteristic.coding.length === 0)) {
                                            changeState(stateSetter, [{}], undefined, undefined, "group"+criteriaType+"Characteristics", index, "coding");
                                        }
                                    }}
                                />&nbsp;&nbsp;&nbsp;<br/><br/>
                            </>}
                            {valueType === "codeableConcept" && 
                                <>
                                    {characteristic.coding.map((coding, codingIndex)=> {
                    
                                        let presetPicked = false;
                                        if (characteristic.typeCode && characteristicValuePresetOptions[characteristic.typeCode] && coding.valuePreset !== undefined && coding.valuePreset !== "" && characteristicValuePresetOptions[characteristic.typeCode][coding.valuePreset]?.code === coding.valueCode) {
                                            presetPicked = true;
                                        }
                                        return <div key={codingIndex} style={{border: "2px solid #BBBBBB", borderTop: "1px solid #BBBBBB", borderBottom: "1px solid #BBBBBB", padding: "10px"}}>
                                        {(characteristic.typeCode && characteristicValuePresetOptions[characteristic.typeCode]) &&                 
                                            <><Dropdown
                                            name="characteristicvaluepreset"
                                            placeholder="Preset"
                                            closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                                            style={{minWidth: "25%", width: "25%"}}
                                            options={characteristicValuePresetOptions[characteristic.typeCode]}
                                            value={characteristicValuePresetOptions[characteristic.typeCode][coding.valuePreset]?.code === coding.valueCode && coding.valuePreset}
                                            onChange={(e, data) => {
                                                let valueSystem = "";
                                                let valueCode = "";
                                                let valueDisplay = "";
                                                if (data.value !== "") {
                                                    let codeData = characteristicValuePresetOptions[characteristic.typeCode][data.value];
                                                    valueSystem = codeData.system;
                                                    valueCode = codeData.code;
                                                    valueDisplay = codeData.display;
                                                }
                                                //changeState(stateSetter, data.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valuePreset");
                                                //changeState(stateSetter, valueSystem, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueSystem");
                                                //changeState(stateSetter, valueCode, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueCode");
                                                //changeState(stateSetter, valueDisplay, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueDisplay");

                                                changeState(stateSetter, data.value, "group"+criteriaType+"Characteristics", index, "coding", codingIndex, "valuePreset");
                                                changeState(stateSetter, valueCode, "group"+criteriaType+"Characteristics", index, "coding", codingIndex, "valueCode");
                                                changeState(stateSetter, valueSystem, "group"+criteriaType+"Characteristics", index, "coding", codingIndex, "valueSystem");
                                                changeState(stateSetter, valueDisplay, "group"+criteriaType+"Characteristics", index, "coding", codingIndex, "valueDisplay");
                                            }}
                                            />&nbsp;&nbsp;&nbsp;</>
                                        }
                                        <TextField style={{width: "20%"}} multiline className="inputField" type='text' label={'Value System'} size="small" variant='outlined' value={coding.valueSystem || ''} onChange={(e) => { changeState(stateSetter, e.target.value, "group"+criteriaType+"Characteristics", index, "coding", codingIndex, "valueSystem"); } } disabled={presetPicked}/>
                                        &nbsp;&nbsp;&nbsp;
                                        <TextField style={{width: "15%"}} multiline className="inputField" type='text' label={'Value Code'} size="small" variant='outlined' value={coding.valueCode || ''} onChange={(e) => { changeState(stateSetter, e.target.value, "group"+criteriaType+"Characteristics", index, "coding", codingIndex, "valueCode"); } } disabled={presetPicked} />
                                        &nbsp;&nbsp;&nbsp;
                                        <TextField style={{width: "30%"}} multiline className="inputField" type='text' label={'Value Display'} size="small" variant='outlined' value={coding.valueDisplay || ''} onChange={(e) => { changeState(stateSetter, e.target.value, "group"+criteriaType+"Characteristics", index, "coding", codingIndex, "valueDisplay"); } } disabled={presetPicked} />
                                        <span style={{position: "relative", verticalAlign:"bottom", top: "21px", left: "12px", backgroundColor: "#FFFFFF", padding: "4px"}}>{(characteristic.coding.length > 1 && codingIndex < characteristic.coding.length - 1) && <b>OR</b>}</span>
                                        </div>
                                    })}
                                    
                                    <>
                                    <div style={{marginTop: "10px"}} />
                                        <Button className="formButton" style={{color: "#000000"}} content={"+ Add Coding"} onClick={() => { addResourceElementState(stateSetter, "group"+criteriaType+"Characteristics", index, "coding", {"valueSystem": "", "valueCode": "", "valueDisplay": "" }); } } />
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(To express additional values combined with OR)</span>
                                    <div style={{marginTop: "10px"}} />
                                    </>
                                    <TextField style={{width: "100%"}} multiline className="inputField" type='text' label={'Value Text'} size="small" variant='outlined' value={characteristic.valueText || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueText"); } } />
                                </>
                            }
                            {valueType === "reference" &&
                                <>
                                    <TextField style={{width: "35%"}} multiline className="inputField" type='text' label={'Internal Reference'} size="small" variant='outlined' value={characteristic.valueInternalReference || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueInternalReference"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "25%"}} multiline className="inputField" type='text' label={'Name/Description'} size="small" variant='outlined' value={characteristic.valueReferenceDisplay || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueReferenceDisplay"); } } />
                                </>
                            }
                            {valueType === "quantity" &&
                                <>
                                    <Dropdown
                                        name="characteristicvaluecompator"
                                        placeholder="Comparator"
                                        closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                                        style={{minWidth: "25%", width: "25%"}}
                                        options={[{"key": "0", "value": "=", "text": "="}, {"key": "1", "value": "<", "text": "<"}, {"key": "2", "value": "<=", "text": "<="}, {"key": "3", "value": ">", "text": ">"}, {"key": "4", "value": ">=", "text": ">="}, {"key": "5", "value": "ad", "text": "ad"}]}
                                        value={characteristic.valueComparator}
                                        onChange={(e, data) => {
                                            changeState(stateSetter, data.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueComparator");
                                        }}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "15%"}} className="inputField" type='number' label={'Value'} size="small" variant='outlined' value={characteristic.valueQuantity || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueQuantity"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "25%"}} className="inputField" type='text' label={'Units'} size="small" variant='outlined' value={characteristic.valueUnit || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueUnit"); } } />
                                </>
                            }
                            {valueType === "range" ?
                                <>
                                    <TextField style={{width: "20%"}} className="inputField" type='number' label={'Low'} size="small" variant='outlined' value={characteristic.valueLow || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueLow"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "25%"}} className="inputField" type='text' label={'Low Units'} size="small" variant='outlined' value={characteristic.valueLowUnit || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueLowUnit"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "20%"}} className="inputField" type='number' label={'High'} size="small" variant='outlined' value={characteristic.valueHigh || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueHigh"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "25%"}} className="inputField" type='text' label={'High Units'} size="small" variant='outlined' value={characteristic.valueHighUnit || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueHighUnit"); } } />
                                </>
                            :
                                <>{characteristic.period && <><br/><b>Period: </b>{characteristic.period}</>}</>
                            }
                            {valueType === "boolean" &&
                                <Dropdown
                                    name="characteristicvalueboolean"
                                    placeholder="Value Boolean"
                                    closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
                                    style={{minWidth: "25%", width: "25%"}}
                                    options={[{key: "true", value: true, text: "True"}, {key: "false", value: false, text: "False"}]}
                                    value={characteristic.valueBoolean}
                                    onChange={(e, data) => {
                                        changeState(stateSetter, data.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueBoolean");
                                    }}
                                />
                            }
                            {valueType === "expression" &&
                                <>
                                    <TextField style={{width: "30%"}} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined' value={characteristic.valueExpressionDescription || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueExpressionDescription"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "10%"}} multiline className="inputField" type='text' label={'Language'} size="small" variant='outlined' value={characteristic.valueExpressionLanguage || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueExpressionLanguage"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "35%"}} multiline className="inputField" type='text' label={'Expression'} size="small" variant='outlined' value={characteristic.valueExpressionExpression || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueExpressionExpression"); } } />
                                    &nbsp;&nbsp;&nbsp;
                                    <TextField style={{width: "20%"}} multiline className="inputField" type='text' label={'Expression Reference'} size="small" variant='outlined' value={characteristic.valueExpressionReference || ''} onChange={(e) => { changeState(stateSetter, e.target.value, undefined, undefined, "group"+criteriaType+"Characteristics", index, "valueExpressionReference"); } } />
                                </>
                            }
                            </Table.Cell>
                        </>
                        :
                        <>
                            <Table.Cell style={{verticalAlign: "top"}}>{characteristic.type}</Table.Cell>
                            <Table.Cell style={{verticalAlign: "top"}}>
                            {valueType === "reference" ?
                                <>{(characteristic.value && characteristic.value !== reference) && <>{characteristic.value}</>} (<a href={"/resources/" + reference} target="_blank" rel="noopener noreferrer">{reference}</a>)</>
                            :
                                <>
                                    {valueType === "codeableConcept" ?
                                        <>{characteristic.values.length == 1 ?
                                                <>{characteristic.values[0]}</>
                                            :
                                                <>{characteristic.values.length >= 2 &&
                                                        <>
                                                            <b>Any of:</b>
                                                            <ul style={{margin: "0px"}}>
                                                                {characteristic.values.map((value, valueIndex) => {return <li key={valueIndex}>{value}</li>})}
                                                            </ul>
                                                        </>
                                                }
                                                </>
                                        }
                                        {(characteristic.values.length === 1 && characteristic.valueText) && <br/>}
                                        {characteristic.valueText}
                                        </>
                                    :
                                        <>{characteristic.value.toString()}</>
                                    }
                                    {characteristic.period && <><br/><b>Period: </b>{characteristic.period}</>}
                                    </>
                            }
                            </Table.Cell>
                        </>
                        }
                    </Table.Row>
                })
                }
            </Table.Body>
            </Table>
        </>}
        {smallCriteriaHeader === false && 
            <span>
                {editMode && <><Button className="formButton" style={{color: "#000000"}} content={"+ Add "+criteriaType+" Criteria"} onClick={() => {
                addResourceElementState(stateSetter, null, null, "group"+criteriaType+"Characteristics", {"valueType": "", "type": "", "typeCode": "", "value": "", "valueText": "", "coding": [{}], "reference": "", "valueInternalReference": "", "valueReferenceDisplay": "", "valueBoolean": "", "period": "", "valueComparator": "", "valueQuantity": "", "valueUnit": "", "valueLow": "", "valueLowUnit": "", "valueHigh": "", "valueHighUnit": "", "valueExpressionDescription": "", "valueExpressionLanguage": "", "valueExpressionExpression" : "", "valueExpressionReference": "" });
                } } />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(To express additional criteria combined with AND{criteriaType === "Exclusion" && <> NOT</>})
                </>}
                
                <br/>
            </span>
        }
        </div>
    </>
}

export default CharacteristicsTable;