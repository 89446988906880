import React from 'react';
import {
    Segment,
  } from 'semantic-ui-react';
import './App.css';

const PageNotFound = ( { useTitle } ) => {

  useTitle("FEvIR Platform Page Not Found");

  return (
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <br /><h1>ERROR: Page Not Found</h1><br /><br />
    </Segment>
  );
};

  export default PageNotFound;