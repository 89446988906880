import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, Dropdown, Table, Dimmer, Loader, Input } from 'semantic-ui-react';
import { TextField, Checkbox } from '@mui/material';
import TextareaAutosize from 'react-textarea-autosize';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
import { submitBulkResources } from './ResourceFunctions';
import { format } from 'date-fns';
import { RIStoFHIRConversionForm, convertRIStoFHIR } from './RIStoFHIRConversionForm';

const createProject = async (jsonArray, projectName, history, globalContext, setSubmittingProjectState) => {
  setSubmittingProjectState(true);
  let projectFoi;
  //create a Project Resource with title = projectName and know the Project FOI (or projectid)
  //bulk submit new Resources to cpdatabase from jsonArray and associate each one with the projectid/FOI
  //return a response that it worked else return success/false

  //let makeNewProject = false;
  //makeNewProject = true;

  let currentTime = new Date();
  let stringTime = format(currentTime, "yyyy-MM-dd") + "T" + format(currentTime, "HH:mm:ss");
  projectName = projectName || "Converted RIS Entries for " + globalContext.userState.name + " at " + stringTime;
  let fhirJson = { "resourceType": "Project", "title": projectName };
  const body = {
    'functionid': 'submitfhirresource',
    'idToken': '',
    'fhirEntry': JSON.stringify(fhirJson, null, 2),
    'title': projectName,
    'status': "active",
  };

  let response = await submitToFevirServer(globalContext, 9000, body, true, false);
  if (response?.success) {
    projectFoi = response.formstateid;
  } else {
    alert("Problem creating Project.");
  }

  if (projectFoi) {
    let fois = await submitBulkResources(globalContext, history, { projectFoi: projectFoi, tool: "ristofevir", status: "active", 'fhirBulkEntry': jsonArray }, true);
    if (fois) {
      return projectFoi;
    } else {
      alert("Problem associating resources.");
    }
  }

  setSubmittingProjectState(false);
  return false;
};

const RIStoFEvIRConverterPage = ({ useTitle }) => {

  useTitle("FEvIR RIS-to-FEvIR Converter");
  const globalContext = useContext(FevirContext);
  const history = useHistory();

  const [fhirEntryState, setFhirEntryState] = useState({ "inputChanged": true, "codings": [{ "systemName": "", "systemUri": "", "code": "", "display": "", "qualifiers": [], "preset": "" }], text: "", risFile: "" });
  const [risMappingState, setRisMappingState] = useState([]);
  const [fhirOutputState, setFhirOutputState] = useState("");
  const [fhirOutputValidState, setFhirOutputValidState] = useState("#000000");
  const [submittingProjectState, setSubmittingProjectState] = useState(false);

  /*
    const separateRisConverterOutput = (risConverterOuput) => {
      if (risConverterOuput == "") return ""
      if (risConverterOuput == undefined) return ""
      let jsonarray = JSON.parse(risConverterOuput);
      let risMappingAppliedJson = jsonarray.shift();
      let risMappingApplied = JSON.stringify(risMappingAppliedJson, null, 2);
      return risMappingApplied
    }
   
    const separateRisConverterOutput2 = (risConverterOuput) => {
      if (risConverterOuput == "") return ""
      if (risConverterOuput == undefined) return ""
      let jsonarray = JSON.parse(risConverterOuput);
      jsonarray.shift();
      let citationArrayJson = jsonarray;
      let citationArrayString = JSON.stringify(citationArrayJson, null, 2);
      return citationArrayString
    }
    const separateUnusedRisItemsFromOutput = (risConverterOuput) => {
      if (risConverterOuput == "") return ""
      if (risConverterOuput == undefined) return ""
      let jsonarray = JSON.parse(risConverterOuput);
      let risMappingAppliedJson = jsonarray.shift();
      let unusedRisCodesAndValues = risMappingAppliedJson["RIS Codes Present but Not Mapped:"]
      let unusuedRisCodesAndValuesArray = Object.keys(unusedRisCodesAndValues).map(function (key) {
        return [key, unusedRisCodesAndValues[key]];
      });
      return unusuedRisCodesAndValuesArray
    }
  */

  const JsonCreateProjectButtons = () => {
    return <>
      <Button className="formButton positive" content="Create Project" compact
        onClick={async () => {
          let projectFoi = await createProject(fhirOutputState, fhirEntryState.projectName, history, globalContext, setSubmittingProjectState);
          if (!projectFoi) {
            alert("Problem submitting.");
          }
        }}
        disabled={(!fhirOutputState || !fhirEntryState.projectName || submittingProjectState) ? true : false} />
      &nbsp;&nbsp;&nbsp;
      <Button className="formButton" content="Copy JSON" compact
        onClick={() => { navigator.clipboard.writeText(fhirOutputState); }}
        disabled={!fhirOutputState ? true : false} />
    </>
  }

  return (
    <div>
      {submittingProjectState &&
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      }
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Computable Publishing<sup style={{ verticalAlign: "super", fontSize: "x-small" }}>®</sup>: RIS-to-FEvIR Converter</h2>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <br />
        <TextField style={{ width: "40%" }} className="inputField" type='text'
          label={'Name of Project'} size="small" variant='outlined'
          value={fhirEntryState.projectName || ''}
          onChange={(e) => { setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, projectName: e.target.value }; }); }} />
        <br />
        <br />
        <RIStoFHIRConversionForm fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} risMappingState={risMappingState} setRisMappingState={setRisMappingState} />
        {/* <TextareaAutosize style={{width: "98%", color: fhirOutputValidState}} spellCheck="false" value={separateRisConverterOutput(fhirOutputState)} /> */}
        <br />
        <Button className="formButton positive" content="Convert RIS to FEvIR" compact onClick={() => { convertRIStoFHIR(globalContext, fhirEntryState, risMappingState, setFhirOutputState); }} disabled={risMappingState.length === 0 ? true : false} />
        <br />
        {fhirOutputState && <><br /><JsonCreateProjectButtons /></>}
        <h3>JSON Output</h3>
        {/*<textarea style={{width: "98%", height: "800px"}} value={fhirOutputState} />*/}
        <TextareaAutosize style={{ width: "98%", color: fhirOutputValidState }}
          spellCheck="false"
          value={fhirOutputState} /* onChange={(e) => {  setFhirOutputState(e.target.value);  }} */ />
        <br /><br />
        <JsonCreateProjectButtons />
      </Segment>
    </div>
  );
};

export default RIStoFEvIRConverterPage;