import { Segment } from 'semantic-ui-react';
import scrollTo from './ScrollTo';
import { FavoriteResourceStar } from './FavoriteResourcesPage';

const NavigationSegment = ({ elements, resourceId, loading }) => {
    if (elements.length === 0) {
        return <>{!loading && <FavoriteResourceStar resourceId={resourceId} absolutePosition={true} topOffset={"-22px"} rightOffset={"-18px"} />}</>
    } else {
        return <Segment className={`containerSegment maxNavigationSegment unselectable`} style={{ marginTop: "8px", paddingTop: "2px", paddingBottom: "6px" }} raised>
            <h2 style={{ fontSize: "22px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>Navigation</h2>
            <FavoriteResourceStar resourceId={resourceId} absolutePosition={true} />
            <div className={`navigationLinksSection`} style={{ paddingTop: "4px" }}>
                {elements.map((element, index) => {
                    let id = "";
                    if (index != 0) {
                        id = element.toLowerCase().replace(/\s/g, "-").replace(/\//g, "-");
                    }
                    return <span key={index}>
                        <a onClick={() => scrollTo("resourceTextViewSegment", id, true)} >{element}</a>
                        {index !== elements.length - 1 && <><br /><div style={{ height: "4px" }} /></>}
                    </span>
                })}
            </div>
        </Segment>
    }

}
export default NavigationSegment;