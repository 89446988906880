import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { TextField, RadioGroup, Radio, FormLabel, FormControl, FormControlLabel } from '@mui/material';
import { DataEntry } from './DataEntryFormFunctions';
import { getCharacteristicValuesFromFhirJson } from './EvidenceVariableFunctions';
import { ChildCriteriaTable } from './EvidenceVariableCharacteristicDisplay';
import { characteristicNavigationEntryDisplay } from './NavigationCharacteristicSegment';
import { EvidenceVariableCharacteristicDisplay } from './EvidenceVariableCharacteristicDisplay';
import { NavigationCharacteristicSegment } from './NavigationCharacteristicSegment';

const ValueTypeDisplayForm = ({ characteristicState, setCharacteristicState, valueTypesToChoose }) => {
  const valueTypes = [
    { type: "code", typeLabel: "Define by code", description: "a single code that defines the value" },
    { type: "quantity", typeLabel: "Define by quantity", description: "(e.g. 7 meters or ≥130 mEq/L)" },
    { type: "range", typeLabel: "Define by range", description: "two quantities, a low value and a high value" },
    { type: "text", typeLabel: "Simple text", description: "describe the characteristic" },
    { type: "identifier", typeLabel: "Define by Identifier", description: "reference to a resource or characteristic ID" },
    { type: "boolean", typeLabel: "Define by Boolean", description: 'value is either "true" or "false"' },
  ];
  let valueTypesToChoosePicked = valueTypes;
  if (valueTypesToChoose) {
    valueTypesToChoosePicked = [];
    for (let x in valueTypes) {
      if (valueTypesToChoose.includes(valueTypes[x].type)) {
        valueTypesToChoosePicked.push(valueTypes[x]);
      }
    }
  }
  return <div>
    <h3>Choose one data type to define the value</h3>
    <div style={{ border: "1px solid", padding: "10px", marginTop: "6px", marginLeft: "20px", backgroundColor: "#E8E8E8" }}>
      <FormControl>
        <RadioGroup aria-label="radiovaluetype" name="radiovaluetype"
          value={characteristicState.valueType}
          onChange={(e) => {
            if (characteristicState.valueTypeInformation) {
              alert("By switching data type for the value, you will lose existing data type information.");
            }
            setCharacteristicState(prevState => { return { ...prevState, valueType: e.target.value, termChanged: true } });
          }}>
          {valueTypesToChoosePicked.map((valueType, valueTypeIndex) => {
            return <FormControlLabel
              key={valueTypeIndex}
              value={valueType.typeLabel}
              control={<Radio color="primary" style={{ paddingTop: "4px", paddingBottom: "4px" }} />}
              name="radio-button-control"
              color="default"
              inputprops={{ 'aria-label': valueType.typeLabel }}
              label={<span style={{ textAlign: 'center' }}><b>{valueType.typeLabel}</b> — {valueType.description}</span>}
              style={{ paddingLeft: "16px" }}
            />
          })}
        </RadioGroup>
      </FormControl>
      {characteristicState.valueType && <div style={{ border: "1px solid", marginTop: "8px", marginLeft: "40px", padding: "10px", backgroundColor: "#FFFFFF" }}>
        {characteristicState.valueType === "Define by code" && <>
          <TextField style={{ width: "300px" }} multiline className="inputField" type='text'
            label={'Value System'} size="small" variant='outlined'
            value={characteristicState.valueCodeableCoding?.system || ""}
            onChange={(e) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueCodeableCoding": {
                    ...prevState["valueCodeableCoding"],
                    "system": e.target.value
                  },
                  "valueBooleanRadioValue": null, "valueValueText": null,
                  "valueComparator": null, "valueValue": null, "valueUnit": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true,
                  "valueTypeInformation": true
                }
              });
            }} />
          <TextField style={{ width: "200px", marginLeft: "8px" }} multiline className="inputField" type='text'
            label={'Value Code'} size="small" variant='outlined'
            value={characteristicState.valueCodeableCoding?.code || ""}
            onChange={(e) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueCodeableCoding": {
                    ...prevState["valueCodeableCoding"],
                    "code": e.target.value
                  },
                  "valueBooleanRadioValue": null, "valueValueText": null,
                  "valueComparator": null, "valueValue": null, "valueUnit": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true,
                  "valueTypeInformation": true
                }
              });
            }} />
          <TextField style={{ width: "300px", marginLeft: "8px" }} multiline className="inputField" type='text'
            label={'Value Display'} size="small" variant='outlined'
            value={characteristicState.valueCodeableCoding?.display || ""}
            onChange={(e) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueCodeableCoding": {
                    ...prevState["valueCodeableCoding"],
                    "display": e.target.value
                  },
                  "valueBooleanRadioValue": null, "valueValueText": null,
                  "valueComparator": null, "valueValue": null, "valueUnit": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true,
                  "valueTypeInformation": true
                }
              });
            }} />
          <div style={{ "paddingTop": "8px" }} />
          <table className="noStyleTable"><tbody><tr>
            <td><b style={{ fontSize: "1.1em" }}>Or add from Terminology on FEvIR: </b></td>
            <td style={{ paddingLeft: "8px" }}>TBD</td>
          </tr></tbody></table>
        </>}
        {characteristicState.valueType === "Define by quantity" && <>
          <Dropdown
            name="characteristicvaluecompator"
            placeholder="Comparator"
            closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
            style={{ minWidth: "25%", width: "25%" }}
            options={[{ "key": "0", "value": "=", "text": "=" }, { "key": "1", "value": "<", "text": "<" }, { "key": "2", "value": "<=", "text": "<=" }, { "key": "3", "value": ">", "text": ">" }, { "key": "4", "value": ">=", "text": ">=" }, { "key": "5", "value": "ad", "text": "ad" }]}
            value={characteristicState.valueComparator}
            onChange={(e, data) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueComparator": data.value,
                  "valueBooleanRadioValue": null, "valueCodeableCoding": null, "valueValueText": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                }
              });
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "15%" }} className="inputField" type='number' label={'Value'} size="small"
            variant='outlined'
            value={characteristicState.valueValue !== undefined ? characteristicState.valueValue : ''}
            onChange={(e) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueValue": e.target.value,
                  "valueBooleanRadioValue": null, "valueCodeableCoding": null, "valueValueText": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                }
              });
            }} />
          &nbsp;&nbsp;&nbsp;
          <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Units'} size="small"
            variant='outlined' value={characteristicState.valueUnit || ''}
            onChange={(e) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueUnit": e.target.value,
                  "valueBooleanRadioValue": null, "valueCodeableCoding": null, "valueValueText": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                }
              });
            }} />
        </>}
        {characteristicState.valueType === "Define by range" &&
          <>
            <TextField style={{ width: "20%" }} className="inputField" type='number' label={'Low'} size="small"
              variant='outlined'
              value={characteristicState.valueLow !== undefined ? characteristicState.valueLow : ''}
              onChange={(e) => {
                setCharacteristicState(prevState => {
                  return {
                    ...prevState, "valueLow": e.target.value,
                    "valueBooleanRadioValue": null, "valueCodeableCoding": null, "valueValueText": null,
                    "valueComparator": null, "valueValue": null, "valueUnit": null,
                    "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                  }
                });
              }} />
            &nbsp;&nbsp;&nbsp;
            <TextField style={{ width: "25%" }} className="inputField" type='text' label={'Low Units'}
              size="small" variant='outlined' value={characteristicState.valueLowUnit || ''}
              onChange={(e) => {
                setCharacteristicState(prevState => {
                  return {
                    ...prevState, "valueLowUnit": e.target.value,
                    "valueBooleanRadioValue": null, "valueCodeableCoding": null, "valueValueText": null,
                    "valueComparator": null, "valueValue": null, "valueUnit": null,
                    "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                  }
                });
              }} />
            &nbsp;&nbsp;&nbsp;
            <TextField style={{ width: "20%" }} className="inputField" type='number' label={'High'} size="small"
              variant='outlined'
              value={characteristicState.valueHigh !== undefined ? characteristicState.valueHigh : ''}
              onChange={(e) => {
                setCharacteristicState(prevState => {
                  return {
                    ...prevState, "valueHigh": e.target.value,
                    "valueBooleanRadioValue": null, "valueCodeableCoding": null, "valueValueText": null,
                    "valueComparator": null, "valueValue": null, "valueUnit": null,
                    "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                  }
                });
              }} />
            &nbsp;&nbsp;&nbsp;
            <TextField style={{ width: "25%" }} className="inputField" type='text' label={'High Units'}
              size="small" variant='outlined' value={characteristicState.valueHighUnit || ''}
              onChange={(e) => {
                setCharacteristicState(prevState => {
                  return {
                    ...prevState, "valueHighUnit": e.target.value,
                    "valueBooleanRadioValue": null, "valueCodeableCoding": null, "valueValueText": null,
                    "valueComparator": null, "valueValue": null, "valueUnit": null,
                    "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                  }
                });
              }} />
          </>}
        {characteristicState.valueType === "Simple text" && <>
          <TextField style={{ width: "500px" }} multiline className="inputField" type='text' label={'Value Text'}
            size="small" variant='outlined' value={characteristicState.valueValueText || ""}
            onChange={(e) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueValueText": e.target.value, "valueCodeableCoding": null,
                  "valueBooleanRadioValue": null,
                  "valueComparator": null, "valueValue": null, "valueUnit": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                }
              });
            }} />
        </>}
        {characteristicState.valueType === "Define by Identifier" && <>TBD
        </>}
        {characteristicState.valueType === "Define by Boolean" &&
          <Dropdown
            name="characteristicvalueboolean"
            placeholder="Value Boolean"
            closeOnChange selection clearable selectOnBlur={false} autoComplete="off"
            style={{ minWidth: "25%", width: "25%" }}
            options={[{ key: "true", value: true, text: "True" }, { key: "false", value: false, text: "False" }]}
            value={characteristicState.valueBooleanRadioValue}
            onChange={(e, data) => {
              setCharacteristicState(prevState => {
                return {
                  ...prevState, "valueBooleanRadioValue": data.value, "valueCodeableCoding": null, "valueValueText": null,
                  "valueComparator": null, "valueValue": null, "valueUnit": null,
                  "valueLow": null, "valueLowUnit": null, "valueHigh": null, "valueHighUnit": null,
                  "termChanged": true, "definitionDetailTypeInformation": true, "valueTypeInformation": true
                }
              });
            }}
          />}
      </div>
      }
    </div>
  </div>
}

const EvidenceVariableCharacteristicEdit = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, 
  history, changeFhirEntryState, submitUpdatedFhirResource, changeFormState, addToast }) => {

  const [characteristicState, setCharacteristicState] = useState(getCharacteristicValuesFromFhirJson(fhirEntryState, { loaded: false, termChanged: false }));

  useImperativeHandle(formInputsStateRef, () => ({
    characteristicState,
  }), [characteristicState]);

  useEffect(async () => {
    //if (fhirEntryState.editMode && characteristicState.termChanged) {
    //console.log("CHARACTERISTIC CHANGED");
    //alert("You changed the characteristic, please click Update to save your changes.")
    //}
    let characteristicValues = getCharacteristicValuesFromFhirJson(fhirEntryState, characteristicState);
    setCharacteristicState(prevState => { return { ...prevState, ...characteristicValues } });
  }, [fhirEntryState]);

  let characteristic = fhirEntryState.characteristicSelected;

  let conceptPath = fhirEntryState.conceptPath;

  const characteristicTypes = [
    { type: "Direct Link", description: "a URL that defines the characteristic (or an ID to reuse a characteristic, or an FOI for a FEvIR Resource)" },
    { type: "Define by code", description: "a single code that defines the characteristic" },
    { type: "Define by type and value", description: "describes in 2 parts (e.g. history of diagnoses includes diabetes, or serum sodium level > 130 mEq/L)" },
    { type: "Define by combining characteristics", description: "any of, all of, at least n, at most n, statistical" },
    { type: "Simple text", description: "describe the characteristic" }
  ];

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          {fhirEntryState.conceptDelete ?
            <><h1 style={{ color: "#FF0000" }}>Concept "{fhirEntryState.conceptName}" deletion pending, please click "Update" to complete deletion.</h1></>
            :
            <>
              <h3 style={{ margin: "0px" }}>{fhirEntryState.conceptName}</h3>
              <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
                startingValue={characteristicState.description} setResourceState={setCharacteristicState} />
              <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
                startEmptyArrayClosed={true} deletableArray={true}
                startingValue={characteristicState.note} setResourceState={setCharacteristicState} />
              <br />
              <RadioGroup row aria-label="radioincludeexclude" name="radioincludeexclude"
                value={characteristicState.includeExcludeRadioValue} onChange={(e) => {
                  setCharacteristicState(prevState => {
                    let exclude = prevState.exclude;
                    let excludeInclude = e.target.value;
                    if (excludeInclude === "Include") {
                      exclude = false;
                      characteristic.exclude = false;
                    } else if (excludeInclude === "Exclude") {
                      exclude = true;
                      characteristic.exclude = true;
                    }
                    return { ...prevState, exclude: exclude, includeExcludeRadioValue: excludeInclude, termChanged: true }
                  });
                  setFhirEntryState(prevState => { return { ...prevState, conceptName: characteristicNavigationEntryDisplay(characteristic)[1] } });
                }}>
                <FormControlLabel
                  value="Include"
                  control={<Radio color="primary" />}
                  name="radio-button-control"
                  color="default"
                  inputprops={{ 'aria-label': 'Include' }}
                  labelPlacement="top"
                  label={<h3 style={{ textAlign: 'center' }}>Include</h3>}
                />
                <FormControlLabel
                  value="Exclude"
                  control={<Radio color="primary" />}
                  color="default"
                  name="radio-button-control"
                  inputprops={{ 'aria-label': 'Exclude' }}
                  labelPlacement="top"
                  label={<h3 style={{ textAlign: 'center' }}>Exclude</h3>}
                />
              </RadioGroup>
              <br />
              <FormControl>
                <FormLabel id="radiocharacteristictype-label" style={{ color: "#000000", paddingBottom: "6px" }}>Choose one way to define this characteristic: <i>(If you Update with a different definition method, you will lose previous definition data.)</i></FormLabel>
                <RadioGroup aria-label="radiocharacteristictype" name="radiocharacteristictype"
                  value={characteristicState.characteristicDefineType}
                  onChange={(e) => {
                    if (characteristicState.definitionDetailTypeInformation) {
                      alert("By switching criteria definition type, you will lose existing definition data.");
                    }
                    setCharacteristicState(prevState => {
                      return { ...prevState, characteristicDefineType: e.target.value, termChanged: true }
                    });
                  }}>
                  {characteristicTypes.map((characteristicType, characteristicTypeIndex) => {
                    return <FormControlLabel
                      key={characteristicTypeIndex}
                      value={characteristicType.type}
                      control={<Radio color="primary" style={{ paddingTop: "4px", paddingBottom: "4px" }} />}
                      name="radio-button-control"
                      color="default"
                      inputprops={{ 'aria-label': characteristicType.type }}
                      label={<span style={{ textAlign: 'center' }}><b>{characteristicType.type}</b> — {characteristicType.description}</span>}
                      style={{ paddingLeft: "16px" }}
                    />
                  })}
                </RadioGroup>
              </FormControl>
              <br /><br />
              {characteristicState.characteristicDefineType &&
                <div style={{ border: "1px solid", padding: "10px", backgroundColor: "#FFFFFF" }}>
                  {characteristicState.characteristicDefineType === "Direct Link" && <>
                    <table className="noStyleTable"><tbody><tr>
                      <td><b style={{ fontSize: "1.1em" }}>URL: </b></td>
                      <td style={{ paddingLeft: "8px" }}>
                        <TextField style={{ width: "500px" }} multiline className="inputField" type='text'
                          label={'Link'} size="small" variant='outlined' value={characteristicState.definitionCanonical || ""}
                          onChange={(e) => {
                            setCharacteristicState(prevState => {
                              return {
                                ...prevState, "definitionCanonical": e.target.value, "termChanged": true,
                                "definitionDetailTypeInformation": true
                              }
                            })
                          }} />
                      </td>
                    </tr></tbody></table>
                    <div style={{ "paddingTop": "8px" }} />
                    <table className="noStyleTable"><tbody><tr>
                      <td><b style={{ fontSize: "1.1em" }}>Alternatives: </b></td>
                      <td>
                        <Button style={{ color: "#000000", width: "130px", marginLeft: "12px" }}
                          className="formButton" content="Enter id" onClick={() => { }} disabled />
                        <Button style={{ color: "#000000", width: "130px", marginLeft: "12px" }}
                          className="formButton" content="Enter FOI" onClick={() => { }} disabled />
                      </td>
                    </tr></tbody></table>
                  </>}
                  {(characteristicState.characteristicDefineType === "Define by code" ||
                    characteristicState.characteristicDefineType === "Simple text") &&
                    <DataEntry datatype='CodeableConcept' elementName='definitionCodeableConcept'
                      fieldLabel='Define by Codeable Concept' startingValue={characteristicState.definitionCodeableConcept || null}
                      setResourceState={setCharacteristicState} />}
                  {characteristicState.characteristicDefineType === "Define by type and value" && <>
                    <h3>Define the type of characteristic (attribute in attribute-value pair)</h3>
                    <div style={{ border: "1px solid", padding: "10px", marginTop: "6px", marginLeft: "20px", backgroundColor: "#E8E8E8" }}>
                      <b style={{ fontSize: "1.1em" }}>With a code</b>
                      {/*TO DO todo CHANGE THESE*/}
                      <div style={{ border: "1px solid", padding: "6px", marginLeft: "20px", marginBottom: "12px", backgroundColor: "#FDFDFD" }}>
                        <TextField style={{ width: "300px" }} multiline className="inputField" type='text' label={'Value Type System'} size="small" variant='outlined' value={characteristicState.typeCodeableCodingJson?.system || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "typeCodeableCodingJson": { ...prevState["typeCodeableCodingJson"], "system": e.target.value }, "termChanged": true, "definitionDetailTypeInformation": true } }); }} />
                        <TextField style={{ width: "200px", marginLeft: "8px" }} multiline className="inputField" type='text' label={'Value Type Code'} size="small" variant='outlined' value={characteristicState.typeCodeableCodingJson?.code || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "typeCodeableCodingJson": { ...prevState["typeCodeableCodingJson"], "code": e.target.value }, "termChanged": true, "definitionDetailTypeInformation": true } }); }} />
                        <TextField style={{ width: "300px", marginLeft: "8px" }} multiline className="inputField" type='text' label={'Value Type Display'} size="small" variant='outlined' value={characteristicState.typeCodeableCodingJson?.display || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "typeCodeableCodingJson": { ...prevState["typeCodeableCodingJson"], "display": e.target.value }, "termChanged": true, "definitionDetailTypeInformation": true } }); }} />
                      </div>
                      <b style={{ fontSize: "1.1em" }}>Or with simple text</b>
                      <div style={{ border: "1px solid", padding: "6px", marginLeft: "20px", backgroundColor: "#FDFDFD" }}>
                        <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Value Text'} size="small" variant='outlined' value={characteristicState.valueText || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "valueText": e.target.value, "termChanged": true, "definitionDetailTypeInformation": true } }) }} />
                      </div>
                      <div style={{ "paddingTop": "8px" }} />
                      <table className="noStyleTable"><tbody><tr>
                        <td><b style={{ fontSize: "1.1em" }}>Or add from Terminology on FEvIR: </b></td>
                        <td style={{ paddingLeft: "8px" }}>TBD</td>
                      </tr></tbody></table>
                    </div>
                    <br />
                    <ValueTypeDisplayForm characteristicState={characteristicState}
                      setCharacteristicState={setCharacteristicState} />
                  </>}
                  {characteristicState.characteristicDefineType === "Define by combining characteristics" &&
                    <>TBD</>}
                </div>}
              <br /><br />
              {characteristicState.characteristicDefineType === "Define by combining characteristics" &&
                <ChildCriteriaTable parentJson={fhirEntryState.characteristicSelected && fhirEntryState.characteristicSelected}
                  setCharacteristicState={setCharacteristicState}
                  resourceId={resourceId} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
                  formInputsStateRef={formInputsStateRef} history={history} changeFhirEntryState={changeFhirEntryState}
                  submitUpdatedFhirResource={submitUpdatedFhirResource} 
                  changeFormState={changeFormState} addToast={addToast} />}
              <br /><br />
              <p>Define by Expression</p>
              <>
                    <TextField style={{ width: "25%" }} multiline className="inputField" type='text' label={'Language/System'} size="small" variant='outlined' value={characteristicState.expressionLanguage || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "expressionLanguage": e.target.value, "termChanged": true, "definitionExpressionInformation": true } }) }} />
                    <TextField style={{ width: "70%", marginLeft: "8px" }} multiline className="inputField" type='text' label={'Expression'} size="small" variant='outlined' value={characteristicState.expressionExpression || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "expressionExpression": e.target.value, "termChanged": true, "definitionExpressionInformation": true } }) }} />
                    <br /><br />
                    <TextField style={{ width: "96%" }} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined' value={characteristicState.expressionDescription || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "expressionDescription": e.target.value, "termChanged": true, "definitionExpressionInformation": true } }) }} />
                    <br /><br />
                    <TextField style={{ width: "25%" }} multiline className="inputField" type='text' label={'Name'} size="small" variant='outlined' value={characteristicState.expressionName || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "expressionName": e.target.value, "termChanged": true, "definitionExpressionInformation": true } }) }} />
                    <TextField style={{ width: "70%", marginLeft: "8px" }} multiline className="inputField" type='text' label={'Source URL'} size="small" variant='outlined' value={characteristicState.expressionUrl || ""} onChange={(e) => { setCharacteristicState(prevState => { return { ...prevState, "expressionUrl": e.target.value, "termChanged": true, "definitionExpressionInformation": true } }) }} />
                  </>
              <div style={{ width: "100%" }}>
                <Button style={{ color: "#000000", float: "right" }} className="formButton" content="Delete Criteria"
                  onClick={() => {
                    let tempPathString = characteristicNavigationEntryDisplay(characteristic);
                    if (window.confirm("Are you sure you want to permanately remove the term \"" + tempPathString[1] + "\" and all related concepts including child concepts (if any)?")) {
                      setFhirEntryState(prevState => { return { ...prevState, "conceptDelete": true, termChanged: true, "definitionDetailTypeInformation": true }; });
                    }
                  }} /></div>
              <br /><br />
            </>
          }
        </>
        :
        <>
          <span>Please select a term</span>
          <br />
          <br />
          <ChildCriteriaTable parentJson={fhirEntryState.fhirEntryString && JSON.parse(fhirEntryState.fhirEntryString)}
            resourceId={resourceId} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
            formInputsStateRef={formInputsStateRef} history={history} />
        </>
      }
    </div>
  </div>
}

const EvidenceVariableCharacteristicDetailTabRouting = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, history,
  changeFhirEntryState, submitUpdatedFhirResource, changeFormState, addToast }) => {
  if (fhirEntryState.editMode) {
    return <EvidenceVariableCharacteristicEdit formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history}
      changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
      changeFormState={changeFormState} addToast={addToast} />
  } else {
    return <EvidenceVariableCharacteristicDisplay formInputsStateRef={formInputsStateRef} resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history}
      changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
      changeFormState={changeFormState} addToast={addToast} />
  }
}

const EvidenceVariableCharacteristicDetailLeftSideDisplay = ({ loading, setLoadingSubmit, fhirEntryState, setFhirEntryState, formState,
  resourceId, resourceType, formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource, changeFormState,
  addToast, globalContext, history, setPageEditModeState
}) => {
  return <div style={{ float: "left", minWidth: "520px", marginRight: "18px" }}>
  <div style={{ position: "fixed", width: "520px" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>}
    <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
      {fhirEntryState.title ?
        <>{fhirEntryState.title}</>
        :
        <>{formState.tempResourceId === resourceId ?
          <>{formState.tempResourceTitle}</>
          :
          <><br /></>}</>}
    </h3>
    <NavigationCharacteristicSegment resourceType={resourceType} resourceId={resourceId} fhirEntryState={fhirEntryState}
      setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} changeFhirEntryState={changeFhirEntryState}
      submitUpdatedFhirResource={submitUpdatedFhirResource} changeFormState={changeFormState}
      addToast={addToast} />
    <>
      {resourceType === "EvidenceVariable" &&
        <div>
          <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
            <div style={{ width: "100%", float: "left", textAlign: "left" }}>
              <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%", margin: "0px" }} content={fhirEntryState.editMode ? "Update" : "Edit Criteria"}
                onClick={async () => {
                  if (fhirEntryState.editMode) {
                    setLoadingSubmit(true);
                    await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef,
                      fhirEntryState, setFhirEntryState, globalContext, history, changeFormState,
                      false, undefined);

                    setLoadingSubmit(false);
                  } else {
                    changeFhirEntryState(true, "editMode");
                    setPageEditModeState(resourceId);
                  }
                }}
                disabled={(!fhirEntryState.previousVersionLoaded && globalContext.userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission) ? false : true}
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }} />
          <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
            <div style={{ width: "100%", float: "left", textAlign: "left" }}>
              <Button className="formButton navigationButton" style={{ color: "#000000", width: "100%" }} content={"Copy from Existing Criteria"} disabled />
            </div>
          </div>
          <div style={{ marginTop: "10px" }} />
          <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
            <div style={{ width: "50%", float: "left", textAlign: "left" }}>
              <Button className="formButton navigationButton" style={{ color: "#000000", width: "98%", margin: "0px" }} content={"Create Criteria from JSON"} disabled />
            </div>
            <div style={{ width: "50%", float: "right", textAlign: "right" }}>
              <Button className="formButton navigationButton" style={{ color: "#000000", width: "98%", margin: "0px" }} content={"Delete This Criteria"} disabled />
            </div>
          </div>
        </div>
      }
    </>
  </div>
</div>

}

export {EvidenceVariableCharacteristicDetailLeftSideDisplay, EvidenceVariableCharacteristicDetailTabRouting};