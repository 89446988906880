import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';
import React, { useEffect, useState, useContext } from 'react';
import { Table } from 'semantic-ui-react';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const getCharacteristicCombinationResources = async (foiList, globalContext) => {
  const body = {
    'functionid': "getmultiplefhirresources",
    'resourceids': foiList,
    'idToken': ""
  };
  let response = await submitToFevirServer(globalContext, 8000, body, true, false);

  if (response?.success && response.resourceentries) {
    return response.resourceentries;
  } else {
    alert("Problem getting Characteristic Resources from definitionByCombination.characteristic.");
    return null
  }
}

const CharacteristicTable = ({ criterias, criteriaType, matchReport }) => {

  let headerCellColor = "#F9FAFB";
  if (criteriaType) {
    if (criteriaType.toLowerCase() === "inclusion") {
      headerCellColor = "#EAFCEA";
    } else if (criteriaType.toLowerCase() === "exclusion") {
      headerCellColor = "#FCEAEA";
    }
  }

  return <Table>
    <Table.Header>
      <Table.Row>
        {matchReport && <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Match Result</Table.HeaderCell>}
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Description</Table.HeaderCell>
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Definition</Table.HeaderCell>
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Qualifiers (Timing)</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {criterias.map((criteria, criteriaIndex) => {
        let backgroundColor = "#FFFFFF";
        let fullRowPadding = '';
        let otherRowPadding = '';
        //criteria.level for future use if showing nested characteristics
        if (criteria.level !== 0) {
          fullRowPadding = `${60 * criteria.level}px`;
          otherRowPadding = `${(60 * criteria.level) - 30}px`;
          if (criteria.level === 1) {
            backgroundColor = "#EBEBEB";
          } else if (criteria.level === 2) {
            backgroundColor = "#D0D0D0";
          } else if (criteria.level === 3) {
            backgroundColor = "#CCCCCC";
          } else if (criteria.level >= 4) {
            backgroundColor = "#B1B1B1";
          }
        }
        return <Table.Row key={criteriaIndex} style={{ backgroundColor: backgroundColor }}>
          {criteria.fullRow ?
            <>
              {matchReport && <Table.Cell style={{ verticalAlign: "top", textAlign: "center" }}>{criteria.matchResult && criteria.matchResult}</Table.Cell>}
              <Table.Cell style={{ verticalAlign: "top" }}>
                <p><a href={'/resources/Characteristic/' + criteria.foi}
                  target='_blank' rel='noopener noreferrer' >
                  {criteria.title}
                </a></p>
                {criteria.resourceJson.description !== undefined &&
                  <span><b>Description: </b><DisplayFromFHIR markdown={criteria.resourceJson.description} /></span>}
                {Array.isArray(criteria.resourceJson.note) &&
                  criteria.resourceJson.note.map((note, noteIndex) => {
                    return <div key={noteIndex}><br /><b>Note: </b><DisplayFromFHIR annotation={note} /></div>
                  })}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top", paddingLeft: fullRowPadding }}>
                {criteria.resourceJson.definitionReference && <span><b>Defined at: </b><DisplayFromFHIR reference={criteria.resourceJson.definitionReference} /></span>}
                {(criteria.resourceJson.definitionUri || criteria.resourceJson.definitionCanonical) && <span>
                  <b>Defined at: </b><DisplayFromFHIR uri={criteria.resourceJson.definitionUri || criteria.resourceJson.definitionCanonical} />
                  </span>}
                {criteria.resourceJson.definitionCodeableConcept && <span><b>Defined as: </b><DisplayFromFHIR codeableConcept={criteria.resourceJson.definitionCodeableConcept} /></span>}
                {criteria.resourceJson.definitionByTypeAndValue && <div>
                  <div>
                    <b>Attribute: </b><DisplayFromFHIR codeableConcept={criteria.resourceJson.definitionByTypeAndValue.type} />
                    {Array.isArray(criteria.resourceJson.definitionByTypeAndValue.method) &&
                      criteria.resourceJson.definitionByTypeAndValue.method.map((method, methodIndex) => {
                        return <div style={{ marginLeft: "24px" }} key={methodIndex}>
                          <b>Determined by: </b><DisplayFromFHIR codeableConcept={method} />
                        </div>
                      })}
                    {criteria.resourceJson.definitionByTypeAndValue.device && <div style={{ marginLeft: "24px" }}>
                      <b>Determined with: </b><DisplayFromFHIR reference={criteria.resourceJson.definitionByTypeAndValue.device} />
                    </div>}
                    <br /><br />
                  </div>
                  <div>
                    <p><b>Matching Value of:</b></p>
                    <div style={{ marginLeft: "24px" }}>
                      {criteria.resourceJson.definitionByTypeAndValue.valueCodeableConcept && <DisplayFromFHIR codeableConcept={criteria.resourceJson.definitionByTypeAndValue.valueCodeableConcept} />}
                      {criteria.resourceJson.definitionByTypeAndValue.valueBoolean === true && <>true</>}
                      {criteria.resourceJson.definitionByTypeAndValue.valueBoolean === false && <>false</>}
                      {criteria.resourceJson.definitionByTypeAndValue.valueQuantity && getStringFromFHIR.Quantity(criteria.resourceJson.definitionByTypeAndValue.valueQuantity)}
                      {criteria.resourceJson.definitionByTypeAndValue.valueRange && getStringFromFHIR.Range(criteria.resourceJson.definitionByTypeAndValue.valueRange)}
                      {criteria.resourceJson.definitionByTypeAndValue.valueReference && <DisplayFromFHIR reference={criteria.resourceJson.definitionByTypeAndValue.valueReference} />}
                      {criteria.resourceJson.definitionByTypeAndValue.valueCanonical && <DisplayFromFHIR uri={criteria.resourceJson.definitionByTypeAndValue.valueCanonical} />}
                      {criteria.resourceJson.definitionByTypeAndValue.offset && <div style={{ marginLeft: "24px" }}>
                        <b>Offset: </b>
                        <DisplayFromFHIR codeableConcept={criteria.resourceJson.definitionByTypeAndValue.offset} />
                      </div>}
                    </div>
                  </div>
                </div>}
                {criteria.resourceJson.definitionByCombination && <div>
                  <p>
                    <b>Method of Combination: </b>
                    {criteria.resourceJson.definitionByCombination.code}
                    &nbsp;&nbsp;&nbsp;
                    {criteria.resourceJson.definitionByCombination.threshold && criteria.resourceJson.definitionByCombination.threshold}
                  </p>
                  {criteria.resourceJson.definitionByCombination.characteristic.map((characteristic, characteristicIndex) => {
                    return <div key={characteristicIndex} ><DisplayFromFHIR reference={characteristic} /><br /></div>
                  })}
                </div>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {criteria.resourceJson.instancesQuantity && <p>
                  <b>Number of instances: </b>
                  {getStringFromFHIR.Quantity(criteria.resourceJson.instancesQuantity)}
                </p>}
                {criteria.resourceJson.instancesRange && <p>
                  <b>Number of instances: </b>
                  {getStringFromFHIR.Range(criteria.resourceJson.instancesRange)}
                </p>}
                {criteria.resourceJson.durationQuantity && <p>
                  <b>Length of time meeting definition: </b>
                  {getStringFromFHIR.Quantity(criteria.resourceJson.durationQuantity)}
                </p>}
                {criteria.resourceJson.durationRange && <p>
                  <b>Length of time meeting definition: </b>
                  {getStringFromFHIR.Range(criteria.resourceJson.durationRange)}
                </p>}
                {Array.isArray(criteria.resourceJson.timeFromEvent) &&
                  criteria.resourceJson.timeFromEvent.map((timing, timingIndex) => {
                    return <div key={timingIndex}>
                      <p><b>Timing from Event:</b></p>
                      <div style={{ marginLeft: "24px" }}>
                        {timing.description && <><b>Description: </b><DisplayFromFHIR markdown={timing.description} /><br /></>}
                        {timing.eventCodeableConcept && <><b>Event: </b><DisplayFromFHIR codeableConcept={timing.eventCodeableConcept} /><br /></>}
                        {timing.eventReference && <><b>Event: </b><DisplayFromFHIR reference={timing.eventReference} /><br /></>}
                        {timing.eventDateTime && <><b>Event: </b>{getStringFromFHIR.dateTime(timing.eventDateTime)}<br /></>}
                        {timing.quantity && <><b>At: </b>{getStringFromFHIR.Quantity(timing.quantity)}<br /></>}
                        {timing.range && <><b>Within: </b>{getStringFromFHIR.Range(timing.range)}<br /></>}
                        {timing.note?.length === 1 && <div><b>Note: </b><DisplayFromFHIR annotation={timing.note[0]} /></div>}
                        {timing.note?.length > 1 && timing.note.map((note, noteIndex) => {
                          return <div key={noteIndex}><div><b>Note: </b><DisplayFromFHIR annotation={note} /></div></div>
                        })}
                      </div>
                    </div>
                  })}
              </Table.Cell>
            </>
            :
            <Table.Cell style={{ verticalAlign: "top", paddingLeft: otherRowPadding }} colSpan={matchReport ? "4" : "3"}><b>{criteria.text}</b></Table.Cell>
          }
        </Table.Row>
      })}
    </Table.Body>
  </Table>
}

const DisplayCharacteristicCombinationTable = ({ fhirJson }) => {
  const globalContext = useContext(FevirContext);
  const [characteristicCombinationState, setCharacteristicCombinationState] = useState({
    inclusionCriteria: [],
    exclusionCriteria: [],
    matchReport: false
  });

  useEffect((async () => {
    if (globalContext.userState.loading === false) {
      let foiList = [];
      let resourceSet = [];
      let characteristicSet = fhirJson.definitionByCombination.characteristic;
      if (!characteristicSet) {
        alert('ERROR recognizing Characteristic Resources in definitionByCombination.characteristic');
      }
      for (const reference of characteristicSet) {
        if (reference.reference) {
          foiList.push(reference.reference.replace("Characteristic/", ""));
        }
      }
      let referencedResourceSet = await getCharacteristicCombinationResources(foiList, globalContext);
      if (referencedResourceSet) {
        resourceSet = referencedResourceSet.map((resource) => {
          return {
            foi: resource.id,
            title: resource.title,
            resourceJson: JSON.parse(resource.toolstatestring),
            level: 0,
            fullRow: true,
            publiconly: true //added for now because server throws error Undefined binding(s) detected when compiling SELECT. Undefined column(s): [permissiongroupid] query: select * from "pairpermissiongroupanduserstable" where "permissiongroupid" = ? and "userid" = ? RELATED TO simpleResourceReadPermissionCheck
          }
        })
      } else {
        alert('ERROR retrieving Characteristic Resources from definitionByCombination.characteristic')
      }
      let inclusionCriteria = [];
      let exclusionCriteria = [];
      for (const entry of resourceSet) {
        if (entry.resourceJson.exclude) {
          exclusionCriteria.push(entry);
        } else {
          inclusionCriteria.push(entry);
        }
      }
      setCharacteristicCombinationState({
        inclusionCriteria: inclusionCriteria,
        exclusionCriteria: exclusionCriteria,
        matchReport: false
      })
    }
  }), [globalContext.userState])

  return <>
    <div style={{ marginLeft: "24px" }}>
      <div style={{ paddingTop: "12px" }} />
      <b>Inclusion Criteria</b>
      <div style={{ marginLeft: "24px" }}>
        {characteristicCombinationState.inclusionCriteria.length > 0 ?
          <CharacteristicTable criterias={characteristicCombinationState.inclusionCriteria} criteriaType={"Inclusion"} matchReport={characteristicCombinationState.matchReport} />
          :
          <div style={{ marginLeft: "8px" }}>None</div>
        }
      </div>
      <div style={{ paddingTop: "12px" }} />
      <b>Exclusion Criteria</b>
      <div style={{ marginLeft: "24px" }}>
        {characteristicCombinationState.exclusionCriteria.length > 0 ?
          <CharacteristicTable criterias={characteristicCombinationState.exclusionCriteria} criteriaType={"Exclusion"} matchReport={characteristicCombinationState.matchReport} />
          :
          <div style={{ marginLeft: "8px" }}>None</div>
        }
      </div>
    </div>
  </>
}

const CharacteristicResourceDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {
  let howToCite;
  if (Array.isArray(fhirJson.relatedArtifact)) {
    for (let relatedArtifactEntry of fhirJson.relatedArtifact) {
      if (relatedArtifactEntry.type === "cite-as") {
        howToCite = relatedArtifactEntry.citation;
        break;
      }
    }
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="description">Description</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.title && <p><b>Title: </b>{fhirJson.title}</p>}
        {fhirJson.name && <p><b>Name (for machine use): </b>{fhirJson.name}</p>}
        {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
        {Array.isArray(fhirJson.note) && fhirJson.note.map((note, noteIndex) => {
          return <div key={noteIndex}><div><b>Note: </b><DisplayFromFHIR annotation={note} /></div></div>
        })}
      </div>
      <h3 id="definition">Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.exclude ? <p><b>EXCLUDE</b> subjects matching this characteristic definition</p> :
          <p>INCLUDE subjects matching this characteristic definition</p>}
        {fhirJson.definitionReference && <span><b>Defined at: </b><DisplayFromFHIR reference={fhirJson.definitionReference} /></span>}
        {(fhirJson.definitionUri || fhirJson.definitionCanonical) && <span>
          <b>Defined at: </b><DisplayFromFHIR uri={fhirJson.definitionUri || fhirJson.definitionCanonical} />
          </span>}
        {fhirJson.definitionCodeableConcept && <span><b>Defined as: </b><DisplayFromFHIR codeableConcept={fhirJson.definitionCodeableConcept} /></span>}
        {fhirJson.definitionByTypeAndValue && <div>
          <div>
            <b>Attribute: </b><DisplayFromFHIR codeableConcept={fhirJson.definitionByTypeAndValue.type} />
            {Array.isArray(fhirJson.definitionByTypeAndValue.method) &&
              fhirJson.definitionByTypeAndValue.method.map((method, methodIndex) => {
                return <div style={{ marginLeft: "24px" }} key={methodIndex}>
                  <b>Determined by: </b><DisplayFromFHIR codeableConcept={method} />
                </div>
              })}
            {fhirJson.definitionByTypeAndValue.device && <div style={{ marginLeft: "24px" }}>
              <b>Determined with: </b><DisplayFromFHIR reference={fhirJson.definitionByTypeAndValue.device} />
            </div>}
            {fhirJson.definitionByTypeAndValue.calculatedAs && <div style={{ marginLeft: "24px" }}>
              <b>Calculated with: </b><DisplayFromFHIR expression={fhirJson.definitionByTypeAndValue.calculatedAs} />
            </div>}
            <br /><br />
          </div>
          <div>
            <p><b>Matching Value of:</b></p>
            <div style={{ marginLeft: "24px" }}>
              {fhirJson.definitionByTypeAndValue.valueCodeableConcept && <DisplayFromFHIR codeableConcept={fhirJson.definitionByTypeAndValue.valueCodeableConcept} />}
              {fhirJson.definitionByTypeAndValue.valueBoolean === true && <>true</>}
              {fhirJson.definitionByTypeAndValue.valueBoolean === false && <>false</>}
              {fhirJson.definitionByTypeAndValue.valueQuantity && getStringFromFHIR.Quantity(fhirJson.definitionByTypeAndValue.valueQuantity)}
              {fhirJson.definitionByTypeAndValue.valueRange && getStringFromFHIR.Range(fhirJson.definitionByTypeAndValue.valueRange)}
              {fhirJson.definitionByTypeAndValue.valueReference && <DisplayFromFHIR reference={fhirJson.definitionByTypeAndValue.valueReference} />}
              {fhirJson.definitionByTypeAndValue.valueCanonical && <DisplayFromFHIR uri={fhirJson.definitionByTypeAndValue.valueCanonical} />}
              {fhirJson.definitionByTypeAndValue.valueExpression && <DisplayFromFHIR expression={fhirJson.definitionByTypeAndValue.valueExpression} />}
               {fhirJson.definitionByTypeAndValue.offset && <div style={{ marginLeft: "24px" }}>
                <b>Offset: </b>
                <DisplayFromFHIR codeableConcept={fhirJson.definitionByTypeAndValue.offset} />
              </div>}
            </div>
          </div>
        </div>
        }
        {fhirJson.definitionByCombination && <div>
          <p>
            <b>Method of Combination: </b>
            {fhirJson.definitionByCombination.code}
            &nbsp;&nbsp;&nbsp;
            {fhirJson.definitionByCombination.threshold && fhirJson.definitionByCombination.threshold}
          </p>
          {(fhirJson.definitionByCombination.characteristic &&
            Array.isArray(fhirJson.definitionByCombination.characteristic) &&
            fhirJson.definitionByCombination.characteristic.length > 0) &&
            <DisplayCharacteristicCombinationTable fhirJson={fhirJson} />
          }
        </div>}
        <br /><br />
        {fhirJson.instancesQuantity && <p>
          <b>Number of instances matching characteristic definition: </b>
          {getStringFromFHIR.Quantity(fhirJson.instancesQuantity)}
        </p>}
        {fhirJson.instancesRange && <p>
          <b>Number of instances matching characteristic definition: </b>
          {getStringFromFHIR.Range(fhirJson.instancesRange)}
        </p>}
        {fhirJson.durationQuantity && <p>
          <b>Length of time meeting characteristic definition: </b>
          {getStringFromFHIR.Quantity(fhirJson.durationQuantity)}
        </p>}
        {fhirJson.durationRange && <p>
          <b>Length of time meeting characteristic definition: </b>
          {getStringFromFHIR.Range(fhirJson.durationRange)}
        </p>}
        {Array.isArray(fhirJson.timeFromEvent) &&
          fhirJson.timeFromEvent.map((timing, timingIndex) => {
            return <div key={timingIndex}>
              <p><b>Timing from Event:</b></p>
              <div style={{ marginLeft: "24px" }}>
                {timing.description && <><b>Description: </b><DisplayFromFHIR markdown={timing.description} /><br /></>}
                {timing.eventCodeableConcept && <><b>Event: </b><DisplayFromFHIR codeableConcept={timing.eventCodeableConcept} /><br /></>}
                {timing.eventReference && <><b>Event: </b><DisplayFromFHIR reference={timing.eventReference} /><br /></>}
                {timing.eventDateTime && <><b>Event: </b>{getStringFromFHIR.dateTime(timing.eventDateTime)}<br /></>}
                {timing.quantity && <><b>At: </b>{getStringFromFHIR.Quantity(timing.quantity)}<br /></>}
                {timing.range && <><b>Within: </b>{getStringFromFHIR.Range(timing.range)}<br /></>}
                {(Array.isArray(timing.note) && timing.note.length === 1) && <div><b>Note: </b><DisplayFromFHIR annotation={timing.note[0]} /></div>}
                {(Array.isArray(timing.note) && timing.note.length > 1) && timing.note.map((note, noteIndex) => {
                  return <div key={noteIndex}><div><b>Note: </b><DisplayFromFHIR annotation={note} /></div></div>
                })}
              </div>
            </div>
          })}
      </div>
      <h3 id="expression">Expression</h3>
      <div style={{ marginLeft: "24px" }}>
        {((Array.isArray(fhirJson.expression) && fhirJson.expression.length > 0) ||
          (Array.isArray(fhirJson.executableExpression) && fhirJson.executableExpression.length > 0))
          ? <>
            {fhirJson.expression?.map((expression, expressionIndex) => {
              return <div key={expressionIndex}>
                <p><b>Expression: </b></p>
                <div style={{ marginLeft: "24px" }}>
                  <DisplayFromFHIR expression={expression} />
                </div>
              </div>
            })}
            {fhirJson.executableExpression?.map((executableExpression, executableExpressionIndex) => {
              return <div key={executableExpressionIndex}>
                <p><b>Expression: </b></p>
                <div style={{ marginLeft: "24px" }}>
                  <DisplayFromFHIR expression={executableExpression.expression} />
                  {Array.isArray(executableExpression.classifier) &&
                    executableExpression.classifier.map((classifier, classifierIndex) => {
                      return <span key={classifierIndex}>
                        <br /><b>Expression classifier: </b><DisplayFromFHIR codeableConcept={classifier} />
                      </span>
                    })
                  }
                </div>
              </div>
            })}
          </>
          :
          <p>No expressions.</p>
        }
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ? 
      <DisplayClassifiers classificationsArray={classificationsArrayState} />
      :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      } 
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export { CharacteristicResourceDisplay, DisplayCharacteristicCombinationTable };