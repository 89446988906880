import { Button, Modal, Dropdown, Table } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { getStringFromFHIR, DisplayFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector } from './DataEntryFormFunctions';

const DeleteCharacteristicRowButton = ({ criteriaType, setGroupCharacteristicsState, index, characteristicString }) => {
    return (
        <Modal
            trigger={<span className={"unselectable"}
                style={{ cursor: "pointer", fontWeight: "normal", fontSize: "42px" }}
                title="Remove this entire row">🗑</span>}
            header='Are you sure you want to delete the Characteristic Row?'
            centered={false}
            content={<div style={{ paddingLeft: "20px" }}>
                {characteristicString && <span><b>Delete: </b><span style={{ whiteSpace: "pre-wrap" }}>{characteristicString}</span></span>}
            </div>}
            actions={[
                {
                    key: 'delete',
                    positive: false,
                    content: 'Delete',
                    onClick: () => {
                        if (criteriaType.toLowerCase() === "inclusion") {
                            setGroupCharacteristicsState(prevState => {
                                let inclusionCriteria = [...prevState.groupInclusionCharacteristics];
                                inclusionCriteria.splice(index, 1); //deleting the item from the array
                                return { ...prevState, groupInclusionCharacteristics: inclusionCriteria };
                            })
                        }
                        if (criteriaType.toLowerCase() === "exclusion") {
                            setGroupCharacteristicsState(prevState => {
                                let exclusionCriteria = [...prevState.groupExclusionCharacteristics];
                                exclusionCriteria.splice(index, 1);
                                return { ...prevState, groupExclusionCharacteristics: exclusionCriteria };
                            })
                        }
                    }
                },
                {
                    key: 'done',
                    content: 'Close',
                    negative: true
                }
            ]}
        />
    )
}

const RelativeTimeModal = ({ startingValue, relativeTimeModalState, setCharacteristicState, setRelativeTimeModalState }) => {
    let modalContent = <div style={{
        paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px",
        paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto"
    }}>
        <Button style={{ padding: "6px", position: "absolute", right: "14px" }} className="formButton negative"
            content="✖"
            onClick={() => {
                setRelativeTimeModalState(prevState => { return { ...prevState, modalOpen: false }; });
            }} />
        <DataEntry asArray={true} datatype='RelativeTime' elementName='timing'
            fieldLabel='Timing' deletable={true} deletableArray={true} startCollapsed
            startingValue={startingValue}
            setResourceState={setCharacteristicState} />
        <br /><br />
        <Button style={{ color: "#FFFFFF", width: "230px", float: "left" }} className="formButton"
            content="Submit" positive
            onClick={() => {
                setRelativeTimeModalState(prevState => { return { ...prevState, contentChanged: true, modalOpen: false }; });
            }}
        />
        <br />
    </div>;
    return (
        <Modal
            style={{ padding: "0px", margin: "0px" }}
            dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
            open={relativeTimeModalState?.modalOpen}
            centered={false}
            content={modalContent}
        />
    )
}

const characteristicDotDeterminedByAllowedDatatypes = ['Reference', 'Expression'];
const characteristicDotDeterminedByReferenceResourceTypes = ['Device', 'DeviceDefinition', 'DeviceMetric'];
const characteristicOffsetValueSet = [{
    "system": "http://hl7.org/fhir/characteristic-offset",
    "code": "UNL",
    "display": "Upper Normal Limit"
}, {
    "system": "http://hl7.org/fhir/characteristic-offset",
    "code": "LNL",
    "display": "Lower Normal Limit"
}];
const characteristicDotInstancesAllowedDatatypes = ['Quantity', 'Range'];
const characteristicDotDurationAllowedDatatypes = ['Duration', 'Range'];
const codeableConceptLevelValueSet = [
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "397669002",
            "display": "Age"
        }]
    },
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "260905004",
            "display": "Condition"
        }]
    },
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "64572001",
            "display": "Disease (disorder)"
        }]
    },
    {
        "coding": [
            {
                "system": "http://snomed.info/sct",
                "code": "71388002",
                "display": "Procedure (procedure)"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://hl7.org/fhir/fhir-types",
                "code": "MedicationRequest",
                "display": "MedicationRequest"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://hl7.org/fhir/fhir-types",
                "code": "Observation",
                "display": "Observation"
            }
        ]
    },
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "263495000",
            "display": "Gender"
        }]
    },
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "103579009",
            "display": "Race"
        }]
    },
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "186034007",
            "display": "Ethnicity / related nationality data"
        }]
    },
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "246112005",
            "display": "Severity"
        }]
    },
    {
        "coding": [{
            "system": "http://snomed.info/sct",
            "code": "305335007",
            "display": "Admission to establishment (procedure)"
        }]
    },
    {
        "text": "Defined by CodeableConcept"
    },
    {
        "text": "Defined by Expression"
    },
    {
        "text": "Defined by Reference"
    },
    {
        "text": "Eligibility Criteria"
    },
    {
        "text": "Member of"
    },
    {
        "text": "Research Study (Participation in)"
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "39156-5",
                "display": "Body mass index (BMI) [Ratio]"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "2089-1",
                "display": "Cholesterol in LDL [Mass/volume] in Serum or Plasma"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "2160-0",
                "display": "Creatinine [Mass/volume] in Serum or Plasma"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "8462-4",
                "display": "Diastolic blood pressure"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "59261-8",
                "display": "Hemoglobin A1c/Hemoglobin.total in Blood"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "718-7",
                "display": "Hemoglobin [Mass/volume] in Blood"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "8480-6",
                "display": "Systolic blood pressure"
            }
        ]
    },
    {
        "coding": [
            {
                "system": "http://loinc.org",
                "code": "20152-5",
                "display": "Volume expired during 1.0 s of forced expiration/Predicted"
            }
        ]
    }
];
const codeableConceptLevelValueSetDictionary = {
    "64572001": [ //Disease (disorder)
        {
            "coding": [{ system: "http://snomed.info/sct", code: "840539006", display: "COVID-19" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "840544004", display: "Suspected COVID-19" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "64779008", display: "Coagulopathy" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "371039008", display: "Thromboembolic disorder" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "236423003", display: "Impaired renal function" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "44054006", display: "Diabetes mellitus type 2 (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "703136005", display: "Diabetes mellitus in remission (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "35489007", display: "Depressive disorder (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "57054005", display: "Acute myocardial infarction (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "422504002", display: "Ischemic stroke (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "22298006", display: "Myocardial infarction (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "394659003", display: "Acute coronary syndrome (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "230690007", display: "Cerebrovascular accident (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "59282003", display: "Pulmonary embolism (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "64156001", display: "Thrombophlebitis (disorder)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "363346000", display: "Malignant neoplastic disease (disorder)" }]
        }
    ],
    "260905004": [ //Condition
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "77386006", display: "Pregnant (finding)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "236423003", display: "Impaired renal function" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "419620001", display: "Death (event)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "305450004", display: "Under care of doctor (finding)" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "395100000", display: "No evidence of cancer found (situation)" }]
        }
    ],
    "246112005": [ //Severity
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "255604002", display: "Mild" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "6736007", display: "Moderate" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "24484000", display: "Severe" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "371923003", display: "Mild to moderate" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "371924009", display: "Moderate to severe" }]
        },
        {
            "coding": [
                { system: "http://snomed.info/sct", code: "442452003", display: "Life threatening severity" }]
        }
    ],
    "305335007": [ //Admission to establishment (procedure)
        {
            "coding": [{ system: "http://snomed.info/sct", code: "32485007", display: "Hospital admission" }]
        }
    ],
    "397669002": [ //Age
        {
            "coding": [{ system: "https://meshb.nlm.nih.gov/", code: "D007223", display: "Infant" }]
        },
        {
            "coding": [
                { system: "https://meshb.nlm.nih.gov/", code: "D002648", display: "Child" }]
        },
        {
            "coding": [
                { system: "https://meshb.nlm.nih.gov/", code: "D000293", display: "Adolescent" }]
        },
        {
            "coding": [
                { system: "https://meshb.nlm.nih.gov/", code: "D000328", display: "Adult" }]
        }
    ],
    "71388002": [ // Procedure (procedure)
        {
            "coding": [{ system: "http://snomed.info/sct", code: "81266008", display: "Heart revascularization (procedure)" }]
        }
    ],
    "MedicationRequest": [
        {
            "coding": [{ system: "http://www.nlm.nih.gov/research/umls/rxnorm", code: "2284960", display: "Remdesivir 100 mg Injection" }]
        }
    ],
    "Observation": [
        {
            "coding": [{
                "system": "http://loinc.org",
                "code": "39156-5",
                "display": "Body mass index (BMI) [Ratio]"
            }]
        },
        {
            "coding": [
                {
                    "system": "http://loinc.org",
                    "code": "2089-1",
                    "display": "Cholesterol in LDL [Mass/volume] in Serum or Plasma"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://loinc.org",
                    "code": "2160-0",
                    "display": "Creatinine [Mass/volume] in Serum or Plasma"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://loinc.org",
                    "code": "8462-4",
                    "display": "Diastolic blood pressure"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://loinc.org",
                    "code": "59261-8",
                    "display": "Hemoglobin A1c/Hemoglobin.total in Blood"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://loinc.org",
                    "code": "718-7",
                    "display": "Hemoglobin [Mass/volume] in Blood"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://loinc.org",
                    "code": "8480-6",
                    "display": "Systolic blood pressure"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://loinc.org",
                    "code": "20152-5",
                    "display": "Volume expired during 1.0 s of forced expiration/Predicted"
                }]
        }
    ],
    "263495000": [ // Gender
        {
            "coding": [
                {
                    "system": "http://hl7.org/fhir/administrative-gender",
                    "code": "male",
                    "display": "Male"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://hl7.org/fhir/administrative-gender",
                    "code": "female",
                    "display": "Female"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://hl7.org/fhir/administrative-gender",
                    "code": "other",
                    "display": "Other"
                }]
        },
        {
            "coding": [
                {
                    "system": "http://hl7.org/fhir/administrative-gender",
                    "code": "unknown",
                    "display": "Unknown"
                }]
        }
    ],
    "103579009": [ // Race
        {
            "coding": [{ system: "urn:oid:2.16.840.1.113883.6.238", code: "1002-5", display: "American Indian or Alaska Native" }]
        },
        {
            "coding": [
                { system: "urn:oid:2.16.840.1.113883.6.238", code: "2028-9", display: "Asian" }]
        },
        {
            "coding": [
                { system: "urn:oid:2.16.840.1.113883.6.238", code: "2054-5", display: "Black or African American" }]
        },
        {
            "coding": [
                { system: "urn:oid:2.16.840.1.113883.6.238", code: "2076-8", display: "Native Hawaiian or Other Pacific Islander" }]
        },
        {
            "coding": [
                { system: "urn:oid:2.16.840.1.113883.6.238", code: "2106-3", display: "White" }]
        },
        {
            "coding": [
                { system: "urn:oid:2.16.840.1.113883.6.238", code: "2131-1", display: "Other Race" }]
        },
        {
            "coding": [
                { system: "http://terminology.hl7.org/CodeSystem/v3-NullFlavor", code: "ASKU", display: "asked but unknown" }]
        },
        {
            "coding": [
                { system: "http://terminology.hl7.org/CodeSystem/v3-NullFlavor", code: "UNK", display: "unknown" }]
        }
    ],
    "186034007": [ // Ethnicity / related nationality data
        {
            "coding": [
                { system: "http://terminology.hl7.org/CodeSystem/v3-Ethnicity", code: "2135-2", display: "Hispanic or Latino" }]
        },
        {
            "coding": [
                { system: "http://terminology.hl7.org/CodeSystem/v3-Ethnicity", code: "2137-8", display: "Spaniard" }]
        }
    ]
};

const valueCodeableConceptSystemChoices = [
    { 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' },
    { 'uri': 'http://hl7.org/fhir/sid/icd-10', 'display': 'ICD-10' },
    { 'uri': 'http://loinc.org', 'display': 'LOINC' },
    { 'uri': 'http://www.nlm.nih.gov/research/umls/rxnorm', 'display': 'RxNorm' },
    { 'uri': 'https://www.whocc.no/atc_ddd_index/', 'display': 'ATC' },
    { 'uri': 'http://unitsofmeasure.org', 'display': 'UCUM' }
];


const EnhancedCharacteristicTableRow = ({ characteristic, index, setGroupCharacteristicsState, criteriaType,
    editMode, fullResourceState, setSourceJsonState, globalContext }) => {
    let cJson = characteristic || {};
    let startingCharacteristicState = {
        "characteristicJson": cJson, "extension": cJson.extension || [],
        "description": cJson.description || "", "code": cJson.code || "", "method": cJson.method || [],
        "determinedByExpression": cJson.determinedByExpression || "", "determinedByReference": cJson.determinedByReference || "",
        "valueCodeableConcept": cJson.valueCodeableConcept || "", "valueQuantity": cJson.valueQuantity || "",
        "valueRange": cJson.valueRange || "", "valueReference": cJson.valueReference || "",
        "offset": cJson.offset || "", "valueBoolean": cJson.valueBoolean ?? "",
        "valueExpression": cJson.valueExpression || "", "valueUri": cJson.valueUri || "",
        "exclude": cJson.exclude || false, "instancesQuantity": cJson.instancesQuantity || "",
        "instancesRange": cJson.instancesRange || "", "durationDuration": cJson.durationDuration || "",
        "durationRange": cJson.durationRange || "", "timing": cJson.timing || [], "period": cJson.period || ""
    };
    let startingTypeSpecificCodeableConceptLevelValueSet = [];
    if (cJson.code && cJson.code.coding && cJson.code.coding[0] && cJson.code.coding[0].code) {
        startingTypeSpecificCodeableConceptLevelValueSet = codeableConceptLevelValueSetDictionary[cJson.code.coding[0].code];
    }
    const [characteristicState, setCharacteristicState] = useState(startingCharacteristicState);
    const [systemChoicesState, setSystemChoicesState] = useState(valueCodeableConceptSystemChoices);
    const [typeSpecificCodeableConceptLevelValueSetState, setTypeSpecificCodeableConceptLevelValueSetState] = useState(startingTypeSpecificCodeableConceptLevelValueSet);

    let characteristicCodeDisplay = "";
    let typeCode = "";
    if (characteristicState.code) {
        if (characteristicState.code.coding?.length > 0) {
            if (characteristicState.code.coding[0].code) {
                typeCode = characteristicState.code.coding[0].code;
            }
            if (characteristicState.code.coding.length === 1) {
                characteristicCodeDisplay = characteristicState.code.coding[0].display || characteristicState.code.coding[0].code;
            } else {
                characteristicCodeDisplay = characteristicState.code.coding.map(coding => { return coding.display || coding.code }).join(", ");
            }
        }
        if (characteristicState.code.text) {
            if (characteristicCodeDisplay) {
                characteristicCodeDisplay += ", " + characteristicState.code.text;
            } else {
                characteristicCodeDisplay = characteristicState.code.text;
            }
        }
    }
    let characteristicDeterminedByDisplay = "";
    let methodDisplay = "";
    let startingDeterminedByDatatype = 'none';
    let startingDeterminedByOpenState = false;

    if (cJson.determinedByExpression && Object.keys(cJson.determinedByExpression).length > 0) {
        startingDeterminedByDatatype = "Expression";
        startingDeterminedByOpenState = true;
        characteristicDeterminedByDisplay = getStringFromFHIR.Expression(cJson.determinedByExpression);
    }
    if (cJson.determinedByReference && Object.keys(cJson.determinedByReference).length > 0) {
        startingDeterminedByDatatype = "Reference";
        startingDeterminedByOpenState = true;
        characteristicDeterminedByDisplay = getStringFromFHIR.Reference(cJson.determinedByReference);
    }
    if (cJson.method?.length > 0) {
        startingDeterminedByOpenState = true;
        methodDisplay = cJson.method.map(item => {
            return getStringFromFHIR.CodeableConcept(item)
        }).join('; ');
    }

    let startingInstancesDatatype = 'none';
    let startingDurationDatatype = 'none';
    let startingInstancesOpenState = false;
    let startingDurationOpenState = false;
    if (cJson.instancesQuantity) {
        startingInstancesDatatype = "Quantity";
        startingInstancesOpenState = true;
    }
    if (cJson.instancesRange) {
        startingInstancesDatatype = "Range";
        startingInstancesOpenState = true;
    }
    if (cJson.durationDuration) {
        startingDurationDatatype = "Duration";
        startingDurationOpenState = true;
    }
    if (cJson.durationRange) {
        startingDurationDatatype = "Range";
        startingDurationOpenState = true;
    }

    let startingCharacteristicValueDatatype = 'none';

    let valuePickList = [
        { key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" },
        { key: "reference", value: "Reference", text: "Reference" },
        { key: "quantity", value: "Quantity", text: "Quantity" },
        { key: "range", value: "Range", text: "Range" },
        { key: "boolean", value: "boolean", text: "Boolean" },
        { key: "expression", value: "Expression", text: "Expression" },
        { key: "uri", value: "uri", text: "URI" }
    ];
    let chooseValueDataType = true;

    if (typeCode.toLowerCase() === "defined by codeableconcept") {
        startingCharacteristicValueDatatype = "codeableConcept";
        chooseValueDataType = false;
    } else if (typeCode === "In Group of" || typeCode === "Research Study from which this is the observed sample") {
        startingCharacteristicValueDatatype = "Reference";
        chooseValueDataType = false;
    }

    if (chooseValueDataType) {
        if (typeCode === "Exposure (intervention)" || typeCode === "Medication (exposure or intervention)" || typeCode === "246267002" || typeCode === "305335007") {
            valuePickList = [{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" }, { key: "reference", value: "Reference", text: "Reference" }, { key: "uri", value: "uri", text: "URI" }];
        } else if (typeCode === "397669002") {
            valuePickList = [{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" }, { key: "quantity", value: "Quantity", text: "Quantity" }, { key: "range", value: "Range", text: "Range" }];
        } else if (typeCode === "Measurement values") {
            valuePickList = [{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" }, { key: "expression", value: "Expression", text: "Expression" }];
        }

        if (typeCode === "246267002" || typeCode === "305335007" || typeCode === "Exposure (intervention)" || typeCode === "Medication (exposure or intervention)" || typeCode === "Measurement values") {
            startingCharacteristicValueDatatype = "CodeableConcept";
            chooseValueDataType = true;
        } else if (typeCode === "397669002") {
            startingCharacteristicValueDatatype = "Range";
            chooseValueDataType = true;
        }

    }
    if (cJson.valueCodeableConcept) {
        startingCharacteristicValueDatatype = "CodeableConcept";
    }
    if (cJson.valueReference) {
        startingCharacteristicValueDatatype = "Reference";
    }
    if (cJson.valueQuantity) {
        startingCharacteristicValueDatatype = "Quantity";
    }
    if (cJson.valueRange) {
        startingCharacteristicValueDatatype = "Range";
    }
    if (typeof cJson.valueBoolean === "boolean") {
        startingCharacteristicValueDatatype = "boolean";
    }
    if (cJson.valueExpression) {
        startingCharacteristicValueDatatype = "Expression";
    }
    if (cJson.valueUri) {
        startingCharacteristicValueDatatype = "uri";
    }

    const [determinedByDatatypeState, setDeterminedByDatatypeState] = useState(startingDeterminedByDatatype);
    const [determinedByOpenState, setDeterminedByOpenState] = useState(startingDeterminedByOpenState);
    const [characteristicValueDatatypeState, setCharacteristicValueDatatypeState] = useState(startingCharacteristicValueDatatype);
    const [chooseValueDataTypeState, setChooseValueDatatypeState] = useState(chooseValueDataType);
    const [valuePickListState, setValuePickListState] = useState(valuePickList);
    const [instancesDatatypeState, setInstancesDatatypeState] = useState(startingInstancesDatatype);
    const [instancesOpenState, setInstancesOpenState] = useState(startingInstancesOpenState);
    const [durationDatatypeState, setDurationDatatypeState] = useState(startingDurationDatatype);
    const [durationOpenState, setDurationOpenState] = useState(startingDurationOpenState);
    const [relativeTimeModalState, setRelativeTimeModalState] = useState({ "modalOpen": false });
    const [ageEntryState, setAgeEntryState] = useState(false);
    const [eligibilityCriteriaEntryState, setEligibilityCriteriaEntryState] = useState(false);

    useEffect((() => {
        if (determinedByDatatypeState === 'Reference') {
            setCharacteristicState(prevState => { return { ...prevState, 'determinedByExpression': null } })
        }
        if (determinedByDatatypeState === 'Expression') {
            setCharacteristicState(prevState => { return { ...prevState, 'determinedByReference': null } })
        }
    }), [determinedByDatatypeState]);

    useEffect((() => {
        if (instancesDatatypeState === 'Quantity') {
            setCharacteristicState(prevState => { return { ...prevState, 'instancesRange': null } })
        }
        if (instancesDatatypeState === 'Range') {
            setCharacteristicState(prevState => { return { ...prevState, 'instancesQuantity': null } })
        }
    }), [instancesDatatypeState]);

    useEffect((() => {
        if (durationDatatypeState === 'Duration') {
            setCharacteristicState(prevState => { return { ...prevState, 'durationRange': null } })
        }
        if (durationDatatypeState === 'Range') {
            setCharacteristicState(prevState => { return { ...prevState, 'durationDuration': null } })
        }
    }), [durationDatatypeState]);

    useEffect((() => {
        if (characteristicValueDatatypeState === 'CodeableConcept') {
            setCharacteristicState(prevState => {
                return {
                    ...prevState,
                    'valueReference': null, 'valueExpression': null, 'valueQuantity': null,
                    'valueRange': null, 'valueBoolean': null, 'valueUri': null
                }
            })
        }
        if (characteristicValueDatatypeState === 'Reference') {
            setCharacteristicState(prevState => {
                return {
                    ...prevState,
                    'valueExpression': null, 'valueCodeableConcept': null, 'valueQuantity': null,
                    'valueRange': null, 'valueBoolean': null, 'valueUri': null
                }
            })
        }
        if (characteristicValueDatatypeState === 'Expression') {
            setCharacteristicState(prevState => {
                return {
                    ...prevState,
                    'valueReference': null, 'valueCodeableConcept': null, 'valueQuantity': null,
                    'valueRange': null, 'valueBoolean': null, 'valueUri': null
                }
            })
        }
        if (characteristicValueDatatypeState === 'Quantity') {
            setCharacteristicState(prevState => {
                return {
                    ...prevState,
                    'valueReference': null, 'valueExpression': null, 'valueCodeableConcept': null,
                    'valueRange': null, 'valueBoolean': null, 'valueUri': null
                }
            })
        }
        if (characteristicValueDatatypeState === 'Range') {
            setCharacteristicState(prevState => {
                return {
                    ...prevState,
                    'valueReference': null, 'valueExpression': null, 'valueCodeableConcept': null,
                    'valueQuantity': null, 'valueBoolean': null, 'valueUri': null
                }
            })
        }
        if (characteristicValueDatatypeState === 'boolean') {
            setCharacteristicState(prevState => {
                return {
                    ...prevState,
                    'valueReference': null, 'valueExpression': null, 'valueCodeableConcept': null,
                    'valueQuantity': null, 'valueRange': null, 'valueUri': null
                }
            })
        }
        if (characteristicValueDatatypeState === 'uri') {
            setCharacteristicState(prevState => {
                return {
                    ...prevState,
                    'valueReference': null, 'valueExpression': null, 'valueCodeableConcept': null,
                    'valueQuantity': null, 'valueRange': null, 'valueBoolean': null
                }
            })
        }
    }), [characteristicValueDatatypeState]);

    useEffect((() => {
        let typeCode = "";
        if (characteristicState.code) {
            if (characteristicState.code.coding?.length > 0) {
                if (characteristicState.code.coding[0].code) {
                    typeCode = characteristicState.code.coding[0].code;
                }
            }
            if (!typeCode && characteristicState.code.text) {
                typeCode = characteristicState.code.text;
            }
        }
        if (typeCode.toLowerCase() === "defined by codeableconcept") {
            setCharacteristicValueDatatypeState("CodeableConcept");
            setChooseValueDatatypeState(false);
            return;
        } else if (typeCode === "In Group of" || typeCode === "Member of" ||
            typeCode === "Research Study from which this is the observed sample" ||
            typeCode.toLowerCase() === "defined by reference" || typeCode.toLowerCase() === "eligibility criteria") {
            setCharacteristicValueDatatypeState("Reference");
            setChooseValueDatatypeState(false);
            if (typeCode.toLowerCase() === "eligibility criteria" || typeCode === "In Group of" || typeCode === "Member of") {
                setEligibilityCriteriaEntryState(true);
            } else {
                setEligibilityCriteriaEntryState(false);
            }
            return;
        } else if (typeCode.toLowerCase() === "defined by expression") {
            setCharacteristicValueDatatypeState("Expression");
            setChooseValueDatatypeState(false);
            return;
        }
        setChooseValueDatatypeState(true);

        let valuePickList = [
            { key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" },
            { key: "reference", value: "Reference", text: "Reference" },
            { key: "quantity", value: "Quantity", text: "Quantity" },
            { key: "range", value: "Range", text: "Range" },
            { key: "boolean", value: "boolean", text: "Boolean" },
            { key: "expression", value: "Expression", text: "Expression" },
            { key: "uri", value: "uri", text: "URI" }
        ];
        if (typeCode === "397669002") {
            valuePickList = [{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" }, { key: "quantity", value: "Quantity", text: "Quantity" }, { key: "range", value: "Range", text: "Range" }];
            setAgeEntryState(true);
        } else {
            setAgeEntryState(false);
            if (typeCode === "64572001" || typeCode === "103579009" || typeCode === "186034007" || typeCode === "246112005" || typeCode === "260905004" || typeCode === "263495000") {
                valuePickList = [{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" }, { key: "reference", value: "Reference", text: "Reference" }];
            } else if (typeCode === "Exposure (intervention)" || typeCode === "Medication (exposure or intervention)" || typeCode === "246267002" || typeCode === "305335007") {
                valuePickList = [{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" }, { key: "reference", value: "Reference", text: "Reference" }, { key: "uri", value: "uri", text: "URI" }];
            } else if (typeCode === "Measurement values") {
                valuePickList = [{ key: "codeableConcept", value: "CodeableConcept", text: "Codeable Concept" }, { key: "expression", value: "Expression", text: "Expression" }];
            }
        }
        setValuePickListState(valuePickList);
        if (codeableConceptLevelValueSetDictionary[typeCode]) {
            setTypeSpecificCodeableConceptLevelValueSetState(codeableConceptLevelValueSetDictionary[typeCode]);
        }
    }), [characteristicState.code]);

    useEffect((() => {
        if (editMode) {
            let newCharacteristic = {};
            if (characteristicState.extension?.length > 0) {
                newCharacteristic.extension = characteristicState.extension;
            }
            if (characteristicState.description) {
                newCharacteristic.description = characteristicState.description;
            }
            if (characteristicState.code) {
                newCharacteristic.code = characteristicState.code;
            }
            if (characteristicState.method?.length > 0) {
                newCharacteristic.method = characteristicState.method;
            }
            if (characteristicState.determinedByExpression) {
                newCharacteristic.determinedByExpression = characteristicState.determinedByExpression;
            }
            if (characteristicState.determinedByReference) {
                newCharacteristic.determinedByReference = characteristicState.determinedByReference;
            }
            if (characteristicState.valueCodeableConcept) {
                newCharacteristic.valueCodeableConcept = characteristicState.valueCodeableConcept;
            }
            if (characteristicState.valueQuantity) {
                newCharacteristic.valueQuantity = characteristicState.valueQuantity;
            }
            if (characteristicState.valueRange) {
                newCharacteristic.valueRange = characteristicState.valueRange;
            }
            if (characteristicState.valueReference) {
                newCharacteristic.valueReference = characteristicState.valueReference;
            }
            if (characteristicState.valueExpression) {
                newCharacteristic.valueExpression = characteristicState.valueExpression;
            }
            if (characteristicState.valueUri) {
                newCharacteristic.valueUri = characteristicState.valueUri;
            }
            if (typeof characteristicState.valueBoolean === "boolean") {
                newCharacteristic.valueBoolean = characteristicState.valueBoolean;
            }
            if (characteristicState.offset) {
                newCharacteristic.offset = characteristicState.offset;
            }
            if (typeof characteristicState.exclude === "boolean") {
                newCharacteristic.exclude = characteristicState.exclude;
            } else {
                newCharacteristic.exclude = false;
            }
            if (characteristicState.instancesQuantity) {
                newCharacteristic.instancesQuantity = characteristicState.instancesQuantity;
            }
            if (characteristicState.instancesRange) {
                newCharacteristic.instancesRange = characteristicState.instancesRange;
            }
            if (characteristicState.durationDuration) {
                newCharacteristic.durationDuration = characteristicState.durationDuration;
            }
            if (characteristicState.durationRange) {
                newCharacteristic.durationRange = characteristicState.durationRange;
            }
            if (characteristicState.timing?.length > 0) {
                newCharacteristic.timing = characteristicState.timing;
            }
            if (characteristicState.period) {
                newCharacteristic.period = characteristicState.period;
            }
            if (newCharacteristic.description || newCharacteristic.code) {
                if (criteriaType.toLowerCase() === "inclusion") {
                    setGroupCharacteristicsState(prevState => {
                        let inclusionCriteria = [...prevState.groupInclusionCharacteristics];
                        inclusionCriteria[index] = newCharacteristic;
                        return { ...prevState, groupInclusionCharacteristics: inclusionCriteria };
                    })
                }
                if (criteriaType.toLowerCase() === "exclusion") {
                    setGroupCharacteristicsState(prevState => {
                        let exclusionCriteria = [...prevState.groupExclusionCharacteristics];
                        exclusionCriteria[index] = newCharacteristic;
                        return { ...prevState, groupExclusionCharacteristics: exclusionCriteria };
                    })
                }
            }
        }
    }), [characteristicState]);

    let rowStyle = {};
    if (characteristic.delete) {
        rowStyle["display"] = "none";
    }

    let hasCharacteristicValueDatatype = false;
    if (characteristicValueDatatypeState && characteristicValueDatatypeState !== "none") {
        hasCharacteristicValueDatatype = true;
    }

    return <>
        {editMode ?
            <>
                <Table.Row className='descriptionRowEdit' key={index + '-description'} style={rowStyle}>
                    <Table.Cell style={{ verticalAlign: "top" }} colSpan="4">
                        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description (text summary of characteristic)'
                            startingValue={characteristicState.description || null} setResourceState={setCharacteristicState}
                            startCollapsed />
                    </Table.Cell>
                </Table.Row>
                <Table.Row className='detailRow' key={index} style={rowStyle}>
                    <Table.Cell style={{ verticalAlign: "top", align: "center" }}>
                        <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
                            <DeleteCharacteristicRowButton criteriaType={criteriaType}
                                setGroupCharacteristicsState={setGroupCharacteristicsState}
                                index={index} characteristicString={characteristicCodeDisplay} />
                        </div>
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top" }}>
                        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Characteristic Type'
                            startingValue={characteristicState.code || null} setResourceState={setCharacteristicState}
                            codeableConceptLevelValueSet={codeableConceptLevelValueSet}
                            systemChoices={valueCodeableConceptSystemChoices} systemChoicesOpen={true}
                            startCollapsed inTableCell={true} />
                        <br />
                        {determinedByOpenState ?
                            <>
                                <DataEntry asArray={true} datatype='CodeableConcept' elementName='method' fieldLabel='Method'
                                    startingValue={characteristicState.method || []} setResourceState={setCharacteristicState}
                                    startCollapsed inTableCell={true} />
                                <br />
                                <DatatypeSelector elementXName='determinedBy[x]' allowedDatatypes={characteristicDotDeterminedByAllowedDatatypes}
                                    datatypeState={determinedByDatatypeState} setDatatypeState={setDeterminedByDatatypeState} />
                                {(determinedByDatatypeState === 'Reference') &&
                                    <DataEntry datatype='Reference' elementName='determinedByReference' fieldLabel='Determined By (as Reference)'
                                        referencedResourceTypes={characteristicDotDeterminedByReferenceResourceTypes} inTableCell={true}
                                        startingValue={characteristicState.determinedByReference || null} setResourceState={setCharacteristicState} />}
                                {(determinedByDatatypeState === 'Expression') &&
                                    <DataEntry datatype='Expression' elementName='determinedByExpression' fieldLabel='Determined By (as Expression)'
                                        startingValue={characteristicState.determinedByExpression || null} setResourceState={setCharacteristicState}
                                        inTableCell={true} />}
                            </>
                            :
                            <span className={"unselectable"} style={{ color: "#70BDEB", cursor: "pointer" }}
                                onClick={() => { setDeterminedByOpenState(true); }} ><i>Click to add DeterminedBy/Method data.</i></span>
                        }
                        <div style={{ margin: "8px" }}></div>
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top" }}>
                        {chooseValueDataTypeState && <>
                            <Dropdown
                                name="characteristicvaluetype"
                                placeholder="Characteristic Value Type"
                                closeOnChange selection selectOnBlur={false} autoComplete="off"
                                style={{ minWidth: "50%", width: "100%" }}
                                options={valuePickListState}
                                value={characteristicValueDatatypeState}
                                onChange={(e, data) => {
                                    if (characteristicState.valueCodeableConcept || characteristicState.valueExpression ||
                                        characteristicState.valueUri || characteristicState.valueReference ||
                                        characteristicState.valueRange || characteristicState.valueQuantity ||
                                        typeof characteristicState.valueBoolean === "boolean") {
                                        alert("You switched datatypes, so previously entered data was lost for this characteristic value.");
                                    }
                                    setCharacteristicValueDatatypeState(data.value);
                                }}
                            />&nbsp;&nbsp;
                            {hasCharacteristicValueDatatype ?
                                <span style={{ color: "#828282" }}><i>Change Value Datatype</i></span>
                                :
                                <span style={{ color: "#828282" }}><i>Select Value Datatype</i></span>
                            }
                            <div style={{ margin: "12px" }}></div>
                        </>}
                        {characteristicValueDatatypeState === "CodeableConcept" &&
                            <DataEntry datatype='CodeableConcept' elementName='valueCodeableConcept' fieldLabel='Characteristic Value'
                                startingValue={characteristicState.valueCodeableConcept || null} setResourceState={setCharacteristicState}
                                codeableConceptLevelValueSet={typeSpecificCodeableConceptLevelValueSetState}
                                systemChoices={systemChoicesState} setSystemChoices={setSystemChoicesState} systemChoicesOpen={true} inTableCell={true}
                                startCollapsed={!hasCharacteristicValueDatatype} />
                        }
                        {characteristicValueDatatypeState === "Reference" &&
                            <DataEntry datatype='Reference' elementName='valueReference' fieldLabel='Characteristic Value'
                                startingValue={characteristicState.valueReference || null} setResourceState={setCharacteristicState}
                                startingResourceType={eligibilityCriteriaEntryState ? "Group" : null}
                                fullResourceState={fullResourceState}
                                setSourceJsonState={setSourceJsonState}
                                globalContext={globalContext}
                                referencedResourceTypes={eligibilityCriteriaEntryState ? ["Group"] : null}
                                enableCreation={true} inTableCell={true} startCollapsed={!hasCharacteristicValueDatatype} />
                        }
                        {characteristicValueDatatypeState === "Quantity" &&
                            <DataEntry datatype='Quantity' elementName='valueQuantity' fieldLabel='Characteristic Value'
                                startingValue={characteristicState.valueQuantity || null} setResourceState={setCharacteristicState}
                                inTableCell={true} valueSet="UCUM" dataEntryStyle={ageEntryState ? "Age" : ""}
                                startCollapsed={!hasCharacteristicValueDatatype} />
                        }
                        {characteristicValueDatatypeState === "Range" &&
                            <DataEntry datatype='Range' elementName='valueRange' fieldLabel='Characteristic Value'
                                startingValue={characteristicState.valueRange || null} setResourceState={setCharacteristicState}
                                inTableCell={true} valueSet="UCUM" dataEntryStyle={ageEntryState ? "Age" : ""}
                                startCollapsed={!hasCharacteristicValueDatatype} />
                        }
                        {characteristicValueDatatypeState === "boolean" &&
                            <DataEntry datatype='boolean' elementName='valueBoolean' fieldLabel='Characteristic Value'
                                startingValue={characteristicState.valueBoolean ?? null} setResourceState={setCharacteristicState}
                                storeFalse={true} inTableCell={true} />
                        }
                        {characteristicValueDatatypeState === "Expression" &&
                            <DataEntry datatype='Expression' elementName='valueExpression' fieldLabel='Characteristic Value'
                                startingValue={characteristicState.valueExpression || null} setResourceState={setCharacteristicState}
                                inTableCell={true}
                                startCollapsed={!hasCharacteristicValueDatatype} />
                        }
                        {characteristicValueDatatypeState === "uri" &&
                            <DataEntry datatype='uri' elementName='valueUri' fieldLabel='Characteristic Value'
                                startingValue={characteristicState.valueUri || null} setResourceState={setCharacteristicState} inTableCell={true} />}
                        <div style={{ paddingTop: "8px" }} />
                        <DataEntry datatype='CodeableConcept' elementName='offset' fieldLabel='Offset'
                            startingValue={characteristicState.offset || null} setResourceState={setCharacteristicState}
                            valueSet={characteristicOffsetValueSet} inTableCell={false} startCollapsed />
                        <br />
                        {instancesOpenState ?
                            <>
                                <DatatypeSelector elementXName='instances[x]' allowedDatatypes={characteristicDotInstancesAllowedDatatypes}
                                    datatypeState={instancesDatatypeState} setDatatypeState={setInstancesDatatypeState} inTableCell={true} />
                                {(instancesDatatypeState === 'Quantity') &&
                                    <DataEntry datatype='Quantity' elementName='instancesQuantity' fieldLabel='Number of Times Criteria Met'
                                        startingValue={characteristicState.instancesQuantity || null} setResourceState={setCharacteristicState} inTableCell={true} />}
                                {(instancesDatatypeState === 'Range') &&
                                    <DataEntry datatype='Range' elementName='instancesRange' fieldLabel='Number of Times Criteria Met'
                                        startingValue={characteristicState.instancesRange || null} setResourceState={setCharacteristicState} inTableCell={true} />}
                            </>
                            :
                            <span className={"unselectable"} style={{ color: "#70BDEB", cursor: "pointer" }}
                                onClick={() => { setInstancesOpenState(true); }} ><i>Click to add number of instances.</i></span>
                        }
                        <br />
                        {durationOpenState ?
                            <>
                                <DatatypeSelector elementXName='duration[x]' allowedDatatypes={characteristicDotDurationAllowedDatatypes}
                                    datatypeState={durationDatatypeState} setDatatypeState={setDurationDatatypeState} inTableCell={true} />
                                {(durationDatatypeState === 'Duration') &&
                                    <DataEntry datatype='Duration' elementName='durationDuration' fieldLabel='Length of Time Meeting Criteria'
                                        startingValue={characteristicState.durationDuration || null} setResourceState={setCharacteristicState} inTableCell={true} />}
                                {(durationDatatypeState === 'Range') &&
                                    <DataEntry datatype='Range' elementName='durationRange' fieldLabel='Length of Time Meeting Criteria'
                                        dataEntryStyle="Duration"
                                        startingValue={characteristicState.durationRange || null} setResourceState={setCharacteristicState} inTableCell={true} />}
                            </>
                            :
                            <span className={"unselectable"} style={{ color: "#70BDEB", cursor: "pointer" }}
                                onClick={() => { setDurationOpenState(true); }} ><i>Click to add duration meeting criteria.</i></span>
                        }
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top" }}>
                        <div>
                            {relativeTimeModalState.modalOpen &&
                                <RelativeTimeModal startingValue={characteristicState.timing || null}
                                    setCharacteristicState={setCharacteristicState}
                                    relativeTimeModalState={relativeTimeModalState}
                                    setRelativeTimeModalState={setRelativeTimeModalState} />}
                            {characteristicState.timing?.length > 0 && <div>
                                <>{characteristicState.timing.map(timing => {
                                    return getStringFromFHIR.RelativeTime(timing)
                                }).join("; ")}</>
                                <br />
                            </div>}
                            <Button style={{ width: "120px" }} className="formButton"
                                content="Add/Edit Timing"
                                onClick={() => {
                                    setRelativeTimeModalState(prevState => { return { ...prevState, "modalOpen": true } });
                                }} />
                        </div>
                        <br />
                        <DataEntry datatype='Period' elementName='period' fieldLabel='Period' startCollapsed inTableCell={true}
                            startingValue={characteristicState.period || null} setResourceState={setCharacteristicState} />
                    </Table.Cell>
                </Table.Row>
            </>
            :
            <>
                <Table.Row className='detailRow' key={index} style={rowStyle}>
                    <Table.Cell style={{ verticalAlign: "top" }}>
                        {characteristicCodeDisplay}
                        {methodDisplay && <><br /><b>Method: </b>{methodDisplay}</>}
                        {characteristicDeterminedByDisplay && <><br /><b>Determined by: </b>{characteristicDeterminedByDisplay}</>}
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top" }}>
                        {characteristicState.valueCodeableConcept && <DisplayFromFHIR codeableConcept={characteristicState.valueCodeableConcept} />}
                        {characteristicState.valueQuantity && getStringFromFHIR.Quantity(characteristicState.valueQuantity)}
                        {characteristicState.valueRange && getStringFromFHIR.Range(characteristicState.valueRange)}
                        {typeof characteristicState.valueBoolean === "boolean" && getStringFromFHIR.boolean(characteristicState.valueBoolean)}
                        {characteristicState.valueReference && <DisplayFromFHIR reference={characteristicState.valueReference} />}
                        {characteristicState.valueExpression && <DisplayFromFHIR expression={characteristicState.valueExpression} />}
                        {characteristicState.valueUri && <DisplayFromFHIR uri={characteristicState.valueUri} />}
                        {characteristicState.offset && <>
                            <br /><b>Offset: </b><DisplayFromFHIR codeableConcept={characteristicState.offset} />
                        </>}
                        {characteristicState.instancesQuantity && <><br />
                            <b>Number of instances meeting criteria: </b>{getStringFromFHIR.Quantity(characteristicState.instancesQuantity)}</>}
                        {characteristicState.instancesRange && <><br />
                            <b>Number of instances meeting criteria: </b>{getStringFromFHIR.Range(characteristicState.instancesRange)}</>}
                        {characteristicState.durationDuration && <><br />
                            <b>Duration meeting criteria: </b>{getStringFromFHIR.Quantity(characteristicState.durationDuration)}</>}
                        {characteristicState.durationRange && <><br />
                            <b>Duration meeting criteria: </b>{getStringFromFHIR.Range(characteristicState.durationRange)}</>}
                    </Table.Cell>
                    <Table.Cell style={{ verticalAlign: "top" }}>
                        {characteristicState.timing?.length > 0 && characteristicState.timing.map(timing => {
                            return getStringFromFHIR.RelativeTime(timing)
                        }).join("; ")}
                        {(characteristicState.timing?.length > 0 && characteristicState.period) && <br />}
                        {characteristicState.period && <>{getStringFromFHIR.Period(characteristicState.period)}</>}
                    </Table.Cell>
                </Table.Row>
                <Table.Row className='descriptionRow' key={index + '-description'} style={rowStyle}>
                    <Table.Cell style={{ verticalAlign: "top" }} colSpan="3">
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Description: </b><DisplayFromFHIR markdown={characteristicState.description} /></>
                    </Table.Cell>
                </Table.Row>
            </>
        }
    </>
}

const EnhancedCharacteristicsTable = ({ criteriaType, editMode, smallCriteriaHeader, groupCriteriaCharacteristics,
    setGroupCharacteristicsState, fullResourceState, setSourceJsonState, globalContext }) => {

    let headerCellColor = "#F9FAFB";
    let h3Color = "#000000";
    if (criteriaType.toLowerCase() === "inclusion") {
        headerCellColor = "#EAFCEA";
        h3Color = "#006800";
    } else if (criteriaType.toLowerCase() === "exclusion") {
        headerCellColor = "#FCEAEA";
        h3Color = "#680000";
    }

    return <>
        {smallCriteriaHeader === false && <h3 id={criteriaType.toLowerCase() + "-criteria"}
            style={{ marginTop: "0px", color: h3Color }}>{criteriaType} Criteria</h3>}
        <div style={{ marginLeft: "24px" }}>
            {groupCriteriaCharacteristics?.length > 0 ? <>
                {smallCriteriaHeader && <b>{criteriaType} Criteria</b>}
                <Table className='enhancedCharacteristicsTable' style={{ margin: "0px" }} >
                    <Table.Header>
                        <Table.Row>
                            {editMode && <Table.HeaderCell>Delete</Table.HeaderCell>}
                            <Table.HeaderCell style={{ width: "30%", backgroundColor: headerCellColor }}>Type of Characteristic</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "50%", backgroundColor: headerCellColor }}>Characteristic Value</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "20%", backgroundColor: headerCellColor }}>Timing</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {groupCriteriaCharacteristics.map((characteristic, index) => {
                            return <EnhancedCharacteristicTableRow key={index} characteristic={characteristic} index={index}
                                setGroupCharacteristicsState={setGroupCharacteristicsState}
                                criteriaType={criteriaType} editMode={editMode}
                                fullResourceState={fullResourceState}
                                setSourceJsonState={setSourceJsonState}
                                globalContext={globalContext} />
                        })
                        }
                    </Table.Body>
                </Table>
            </>
                :
                <>No {criteriaType.toLowerCase()} criteria.</>}
            {smallCriteriaHeader === false &&
                <span>
                    {editMode && <>
                        <Button className="formButton" style={{ color: "#000000" }}
                            content={"+ Add " + criteriaType + " Criteria"}
                            onClick={() => {
                                if (criteriaType.toLowerCase() === "inclusion") {
                                    setGroupCharacteristicsState(prevState => {
                                        let inclusionCriteria = [...prevState.groupInclusionCharacteristics];
                                        inclusionCriteria.push({ "exclude": false });
                                        return { ...prevState, groupInclusionCharacteristics: inclusionCriteria };
                                    })
                                }
                                if (criteriaType.toLowerCase() === "exclusion") {
                                    setGroupCharacteristicsState(prevState => {
                                        let exclusionCriteria = [...prevState.groupExclusionCharacteristics];
                                        exclusionCriteria.push({ "exclude": true });
                                        return { ...prevState, groupExclusionCharacteristics: exclusionCriteria };
                                    })
                                }
                            }} />
                    </>}
                    <br />
                </span>
            }
        </div>
    </>
}

export default EnhancedCharacteristicsTable;