import React, { useState, useContext, useEffect } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Segment, Table } from 'semantic-ui-react';
import { retrieveUserFevirApiToken } from "./UserAccountFunctions";
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';
import './App.css';

const ResultsPage = ({ useTitle, setFormState, changeFormState, urls, titles, redirect, searchstring, resourcetype, searchparameters}) => {
  useTitle("Search Results Page");
  
  const [urlsState, setUrlsState] = useState(urls);
  const [titlesState, setTitlesState] = useState(urls);
  const globalContext = useContext(FevirContext);

  //const history = useHistory();

  useEffect(async () => {
    if (redirect) {
      const body = {
        'functionid': 'search',
        'searchstring': searchstring,
        'resourcetype': resourcetype,
        'searchparameters': searchparameters,
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 120000, body, true, false);
      if (response) {
        setUrlsState(response.urls);
        setTitlesState(response.titles);
        //history.push("/");
        //history.push(response.substring(17));
      }
    }
  }, []);

  let headerStyle = {backgroundColor: "#D1D7EF"};
  let cellStyle = {backgroundColor: "#ECF4FC"};
  //if (urls && urls?.length === titles?.length) { }
  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      {urlsState?.map((url, index) => {
        let title = "";
        if (titlesState && titlesState[index]) {
          title = titlesState[index];
        }
        return <div key={index}>{index+1}. <a href={"/resources" + url.substring(27)} target="_blank">{title}</a><br /></div>
      })}
     </Segment>
  </div>

};

export default ResultsPage;