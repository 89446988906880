import React from 'react';

//Display the functional equivalent of <a href='url'> 'display' </a>
const CreateHyperlink = ({ url, display, openInNewTab }) => {
  if (url?.trim()) {
    if (url.toLowerCase().indexOf("http:") === 0 || url.toLowerCase().indexOf("https:") === 0) {
      //For absolute path
      //Ex. https://pubmed.ncbi.nlm.nih.gov/34406727/
      //Ex. https://fevir.net/456
      //Ex. https://fevir.net/resources/ResearchStudy/456
      return <>
        {openInNewTab ?
          <a href={url} target="_blank" rel="noopener noreferrer">{display || url}</a>
          :
          <a href={url}>{display || url}</a>
        }
      </>
    } else {
      //For relative paths
      let urlCleaned = url.trim();
      if (urlCleaned.split("/").length === 2 && urlCleaned.charCodeAt(0) >= 65 && urlCleaned.charCodeAt(0) <= 90) {  //If it only has one slash and starts with a capital letter, then it's likely a relative path to a FHIR resource on the FEvIR platform
        //Ex: Citation/789 will become /resources/Citation/789
        url = "/resources/" + urlCleaned;
        return <>
          {openInNewTab ?
            <a href={url} target="_blank" rel="noopener noreferrer">{display || url}</a>
            :
            <a href={url}>{display || url}</a>
          }
        </>
      } else if (urlCleaned.toLowerCase().indexOf("/fli/") === 0) {
        return <>
          {openInNewTab ?
            <a href={urlCleaned} target="_blank" rel="noopener noreferrer">{display || "FLI: " + urlCleaned.replace("/FLI/", "").replace("/fli/", "")}</a>
            :
            <a href={urlCleaned}>{display || "FLI: " + urlCleaned.replace("/FLI/", "").replace("/fli/", "")}</a>
          }
        </>
      } else {
        //If it's a relative path outside of our system or it's just some string, then just display it without a hyperlink
        let returnDisplay = display || url || "";
        return <>{returnDisplay}</>
      }
    }
  } else {
    return <></>
  }
};

export default CreateHyperlink;