import { Table } from 'semantic-ui-react';

const CodeSystemTermProgressTable = ({ termProgress, tableHeaderStyle, tableCellStyle, totalText }) => {
  let sevcoSubTotalCount = {
    "Study Design Total": { "termCount": 0, "termApprovedCount": 0 },
    "Bias Total": { "termCount": 0, "termApprovedCount": 0 },
    "Statistic Total": { "termCount": 0, "termApprovedCount": 0 },
    "UNCATEGORIZED-FIX": { "termCount": 0, "termApprovedCount": 0, "terms": [] }
  };
  let sevcoSubTotalLookup = {
    "study design": "Study Design Total",
    "Study Design Process": "Study Design Total",
    "Study Design Feature": "Study Design Total",
    "Bias": "Bias Total",
    "Rating of Bias Risk": "Bias Total",
    "Rating of Factor Presence": "Bias Total",
    "Rating of Bias Direction": "Bias Total",
    "Rating of Potential Influence": "Bias Total",
    "Statistic": "Statistic Total",
    "Hypothesis test attribute": "Statistic Total",
    "Statistical Model": "Statistic Total",
    "Data Transformation": "Statistic Total",
    "Statistical Model Characteristic": "Statistic Total",
    "Statistical Model Component": "Statistic Total",
    "Statistical Distribution Parameter": "Statistic Total",
    "Probability Distribution Attribute": "Statistic Total"
  };

  return <>{termProgress?.subTerm &&
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={tableHeaderStyle}>Top Term</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}># of Terms</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}># of Approved</Table.HeaderCell>
          <Table.HeaderCell style={tableHeaderStyle}>Percentage</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.keys(termProgress.subTerm).map((topTerm, topTermIndex) => {
          let topTermInfo = termProgress.subTerm[topTerm] || {termCount: 0, termApprovedCount: 0, termApprovedRatio: 0};
          if (totalText === "SEVCO (total)") {
            if (topTermInfo?.termCount) {
              try {
                sevcoSubTotalCount[sevcoSubTotalLookup[topTermInfo.display]]["termCount"] += topTermInfo.termCount;
              } catch {
                //alert("Add " + topTermInfo.display + " to the sevcoSubTotalLookup dictionary");
                sevcoSubTotalCount["UNCATEGORIZED-FIX"]["termCount"] += topTermInfo.termCount;
                sevcoSubTotalCount["UNCATEGORIZED-FIX"]["terms"].push(topTermInfo.display);
                console.log(topTermInfo.display);
              }
            }
            if (topTermInfo?.termApprovedCount) {
              try {
                sevcoSubTotalCount[sevcoSubTotalLookup[topTermInfo.display]]["termApprovedCount"] += topTermInfo.termApprovedCount;
              } catch {
                sevcoSubTotalCount["UNCATEGORIZED-FIX"]["termApprovedCount"] += topTermInfo.termApprovedCount;
                if (!sevcoSubTotalCount["UNCATEGORIZED-FIX"]["terms"].includes(topTermInfo.display)) {
                  sevcoSubTotalCount["UNCATEGORIZED-FIX"]["terms"].push(topTermInfo.display);
                }
              }
            }
          }
          return <Table.Row key={topTermIndex}>
            <Table.Cell style={tableCellStyle}><a href={"/resources/CodeSystem/27270#" + topTermInfo.code} target="_blank" rel="noopener noreferrer">{topTermInfo.display}</a></Table.Cell>
            <Table.Cell>{topTermInfo?.termCount !== undefined && topTermInfo.termCount}</Table.Cell>
            <Table.Cell>{topTermInfo?.termApprovedCount !== undefined && topTermInfo.termApprovedCount}</Table.Cell>
            <Table.Cell>{topTermInfo?.termApprovedRatio !== undefined && parseFloat((topTermInfo.termApprovedRatio * 100).toFixed(2))}%</Table.Cell>
          </Table.Row>
        })
        }
        {totalText === "SEVCO (total)" &&
          <>
            <Table.Row>
              <Table.Cell style={tableCellStyle}><i>Study Design (subtotal)</i></Table.Cell>
              <Table.Cell><i>{sevcoSubTotalCount["Study Design Total"]["termCount"]}</i></Table.Cell>
              <Table.Cell><i>{sevcoSubTotalCount["Study Design Total"]["termApprovedCount"]}</i></Table.Cell>
              <Table.Cell><i>{parseFloat(((sevcoSubTotalCount["Study Design Total"]["termApprovedCount"] / sevcoSubTotalCount["Study Design Total"]["termCount"]) * 100).toFixed(2))}%</i></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={tableCellStyle}><i>Bias (subtotal)</i></Table.Cell>
              <Table.Cell><i>{sevcoSubTotalCount["Bias Total"]["termCount"]}</i></Table.Cell>
              <Table.Cell><i>{sevcoSubTotalCount["Bias Total"]["termApprovedCount"]}</i></Table.Cell>
              <Table.Cell><i>{parseFloat(((sevcoSubTotalCount["Bias Total"]["termApprovedCount"] / sevcoSubTotalCount["Bias Total"]["termCount"]) * 100).toFixed(2))}%</i></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={tableCellStyle}><i>Statistic (subtotal)</i></Table.Cell>
              <Table.Cell><i>{sevcoSubTotalCount["Statistic Total"]["termCount"]}</i></Table.Cell>
              <Table.Cell><i>{sevcoSubTotalCount["Statistic Total"]["termApprovedCount"]}</i></Table.Cell>
              <Table.Cell><i>{parseFloat(((sevcoSubTotalCount["Statistic Total"]["termApprovedCount"] / sevcoSubTotalCount["Statistic Total"]["termCount"]) * 100).toFixed(2))}%</i></Table.Cell>
            </Table.Row>
            {sevcoSubTotalCount["UNCATEGORIZED-FIX"]["termCount"] ?
                <Table.Row>
                  <Table.Cell style={tableCellStyle}><i>UNCATEGORIZED (FIX)</i></Table.Cell>
                  <Table.Cell><i>{sevcoSubTotalCount["UNCATEGORIZED-FIX"]["termCount"]}</i></Table.Cell>
                  <Table.Cell><i>{sevcoSubTotalCount["UNCATEGORIZED-FIX"]["termApprovedCount"]}</i></Table.Cell>
                  <Table.Cell>{sevcoSubTotalCount["UNCATEGORIZED-FIX"]["terms"].join(", ")}</Table.Cell>
                </Table.Row>
              :
                <></>
            }
          </>
        }
        <Table.Row>
          <Table.Cell style={tableCellStyle}><b>{totalText}</b></Table.Cell>
          <Table.Cell><b>{termProgress.termCount}</b></Table.Cell>
          <Table.Cell><b>{termProgress.termApprovedCount}</b></Table.Cell>
          <Table.Cell><b>{parseFloat((termProgress.termApprovedRatio * 100).toFixed(2))}%</b></Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>}
  </>
}

export default CodeSystemTermProgressTable;