import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const groupTextViewChangesToJson = (fhirJson, groupState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = groupState.meta;
    fhirJson.language = groupState.language;
    fhirJson.text = groupState.text;
    fhirJson.contained = groupState.contained || "DELETEME";
    fhirJson.extension = groupState.extension || "DELETEME";
    fhirJson.modifierExtension = groupState.modifierExtension || "DELETEME";
    fhirJson.url = groupState.url || "DELETEME";
    fhirJson.identifier = groupState.identifier || "DELETEME";
    fhirJson.version = groupState.version || "DELETEME";
    fhirJson.versionAlgorithmString = groupState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = groupState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = groupState.name || "DELETEME";
    fhirJson.title = groupState.title || "DELETEME";
    fhirJson.status = groupState.status || "DELETEME";
    fhirJson.experimental = groupState.experimental ?? "DELETEME";
    fhirJson.date = groupState.date || "DELETEME";
    fhirJson.publisher = groupState.publisher || "DELETEME";
    fhirJson.contact = groupState.contact || "DELETEME";
    fhirJson.description = groupState.description || "DELETEME";
    fhirJson.useContext = groupState.useContext || "DELETEME";
    fhirJson.purpose = groupState.purpose || "DELETEME";
    fhirJson.copyright = groupState.copyright || "DELETEME";
    fhirJson.copyrightLabel = groupState.copyrightLabel || "DELETEME";
    fhirJson.type = groupState.type || "DELETEME";
    fhirJson.membership = groupState.membership || "DELETEME";
    fhirJson.code = groupState.code || "DELETEME";
    fhirJson.quantity = groupState.quantity || "DELETEME";
    fhirJson.managingEntity = groupState.managingEntity || "DELETEME";
    fhirJson.combinationMethod = groupState.combinationMethod || "DELETEME";
    fhirJson.combinationThreshold = groupState.combinationThreshold || "DELETEME";
    fhirJson.characteristic = groupState.characteristic || "DELETEME";
    fhirJson.member = groupState.member || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (groupState) => {
    let contentArray = groupState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "Group";
    let classifiedFoi = groupState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = groupState.name || "[Untitled.]";
    if (classifedTitle === "[Untitled.]" && groupState.extension) {
        if (Array.isArray(groupState.extension) && groupState.extension.length > 0) {
            for (const extension of groupState.extension) {
                if (extension.url === "http://hl7.org/fhir/StructureDefinition/artifact-title" && extension.valueString) {
                    classifedTitle = extension.valueString;
                    break;
                }
            }
        }
    }
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateGroupJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let groupState = formInputsStateRef.current.groupState;

    let fhirJson = jsonErrorCheck2("Group", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
     if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = groupTextViewChangesToJson(fhirJson, groupState);
        fhirJson = jsonErrorCheck2("Group", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(groupState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateGroupJson };