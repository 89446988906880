import React, { useState, useEffect } from 'react';
import { getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector } from './DataEntryFormFunctions';

const planDefinitionDotAsNeededCodeableConceptSystemChoices = [{ 'uri': 'http://snomed.info/sct', 'display': 'SNOMED CT' }];
const planDefinitionDotSubjectReferenceResourceTypes = ['Group', 'MedicinalProductDefinition', 'SubstanceDefinition', 'AdministrableProductDefinition', 'ManufacturedItemDefinition', 'PackagedProductDefinition'];
const planDefinitionDotSubjectCodeableConceptValueSet = [
  { system: "http://hl7.org/fhir/fhir-types", code: "CareTeam", display: "CareTeam" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Device", display: "Device" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Group", display: "Group" },
  { system: "http://hl7.org/fhir/fhir-types", code: "HealthcareService", display: "HealthcareService" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Location", display: "Location" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Organization", display: "Organization" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Patient", display: "Patient" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Practitioner", display: "Practitioner" },
  { system: "http://hl7.org/fhir/fhir-types", code: "PractitionerRole", display: "PractitionerRole" },
  { system: "http://hl7.org/fhir/fhir-types", code: "RelatedPerson", display: "RelatedPerson" }
];
const planDefinitionDotSubjectAllowedDatatypes = ['CodeableConcept', 'Reference', 'canonical'];
const planDefinitionDotAsNeededAllowedDatatypes = ['boolean', 'CodeableConcept'];
const planDefinitionDotTypeValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "order-set", display: "Order Set" },
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "clinical-protocol", display: "Clinical Protocol" },
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "eca-rule", display: "ECA Rule" },
  { system: "http://terminology.hl7.org/CodeSystem/plan-definition-type", code: "workflow-definition", display: "Workflow Definition" }
];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.subtitle && typeof resource.type === "string") {
      innerDiv += "<p><b>Subtitle: </b>" + resource.subtitle + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.subjectCodeableConcept) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.CodeableConcept(resource.subjectCodeableConcept) + "</p><br/>";
    }
    if (resource.subjectReference) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.Reference(resource.subjectReference) + "</p><br/>";
    }
    if (resource.subjectCanonical) {
      innerDiv += "<p><b>Subject: </b>" + resource.subjectCanonical + "</p><br/>";
    }
    if (resource.action?.length > 0) {
      innerDiv += resource.action.map((item) => {
        let title = item.title || "";
        if (item.prefix) {
          title = item.prefix + " " + title;
        }
        let description = item.description || "";
        let textEquivalent = item.textEquivalent || "";
        let code = getStringFromFHIR.CodeableConcept(item.code) || "";
        let type = getStringFromFHIR.CodeableConcept(item.type) || "";
        let definition = item.definitionCanonical || item.definitionUri || "";
        let timing = getStringFromFHIR.Timing(item.timingTiming) ||
          getStringFromFHIR.Quantity(item.timingAge) ||
          getStringFromFHIR.Range(item.timingRange) ||
          getStringFromFHIR.Quantity(item.timingDuration) || "";
        return "<p><b>Action (" + title + "): </b>" + textEquivalent + " " + description + " " + code + " " + type + " " + definition + " " + timing + "</p><br/>"
      }).join("");
    }
    if (resource.asNeededCodeableConcept) {
      innerDiv += "<p><b>As needed for: </b>" + getStringFromFHIR.CodeableConcept(resource.asNeededCodeableConcept) + "</p><br/>";
    }
    if (resource.asNeededBoolean !== undefined) {
      innerDiv += "<p><b>As needed: </b>" + getStringFromFHIR.boolean(resource.asNeededBoolean) + "</p><br/>";
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const PlanDefinitionBuilder = ({ resourceState, setResourceState }) => {

  let startingSubjectDatatype = 'none';
  if (resourceState.subjectCodeableConcept) { startingSubjectDatatype = 'CodeableConcept'; }
  if (resourceState.subjectReference) { startingSubjectDatatype = 'Reference'; }
  if (resourceState.subjectCanonical) { startingSubjectDatatype = 'canonical'; }
  let startingAsNeededDatatype = 'none';
  if (resourceState.asNeededBoolean !== undefined) { startingAsNeededDatatype = 'boolean'; }
  if (resourceState.asNeededCodeableConcept) { startingAsNeededDatatype = 'CodeableConcept'; }
  const [subjectDatatypeState, setSubjectDatatypeState] = useState(startingSubjectDatatype);
  const [asNeededDatatypeState, setAsNeededDatatypeState] = useState(startingAsNeededDatatype);

  useEffect((() => {
    if (subjectDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null, 'subjectCanonical': null } })
    }
    if (subjectDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'subjectCodeableConcept': null, 'subjectCanonical': null } })
    }
    if (subjectDatatypeState === 'canonical') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null, 'subjectCodeableConcept': null } })
    }
  }), [subjectDatatypeState]);

  useEffect((() => {
    if (asNeededDatatypeState === 'boolean') {
      setResourceState(prevState => { return { ...prevState, 'asNeededCodeableConcept': null } })
    }
    if (asNeededDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'asNeededBoolean': null } })
    }
  }), [asNeededDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='subtitle' fieldLabel='Subtitle'
          startingValue={resourceState.subtitle} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='type'
          fieldLabel='Type' startingValue={resourceState.type || null}
          valueSet={planDefinitionDotTypeValueSet} startCollapsed
          setResourceState={setResourceState} />
      </div>
      <h3 id="goal">Goal</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='PlanDefinitionGoal' elementName='goal' startCollapsed
          fieldLabel='Goal' startingValue={resourceState.goal} setResourceState={setResourceState} />
      </div>
      <h3 id="actor">Actor</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='PlanDefinitionActor' elementName='actor' startCollapsed
          fieldLabel='Actor' startingValue={resourceState.actor} setResourceState={setResourceState} />
        <p><b>Subject:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='subject[x]' allowedDatatypes={planDefinitionDotSubjectAllowedDatatypes}
            datatypeState={subjectDatatypeState} setDatatypeState={setSubjectDatatypeState} />
          {(subjectDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='subjectCodeableConcept' startCollapsed
              fieldLabel='Subject (as CodeableConcept)' startingValue={resourceState.subjectCodeableConcept || null}
              valueSet={planDefinitionDotSubjectCodeableConceptValueSet} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='subjectReference' fieldLabel='Subject (as Reference)'
              referencedResourceTypes={planDefinitionDotSubjectReferenceResourceTypes} startCollapsed
              startingValue={resourceState.subjectReference || null} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'canonical') &&
            <DataEntry datatype='uri' elementName='subjectCanonical' fieldLabel='Subject (as Canonical URI)'
              startingValue={resourceState.subjectCanonical || null} setResourceState={setResourceState} />}
        </div>
      </div>
      <h3 id="action">Action</h3>
      <div style={{ marginLeft: "24px" }}>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry asArray={true} datatype='PlanDefinitionAction' elementName='action' startCollapsed
            fieldLabel='Action' startingValue={resourceState.action} setResourceState={setResourceState} />
          <p><b>As Needed:</b></p>
          <div style={{ marginLeft: "24px" }}>
            <DatatypeSelector elementXName='asNeeded[x]' allowedDatatypes={planDefinitionDotAsNeededAllowedDatatypes}
              datatypeState={asNeededDatatypeState} setDatatypeState={setAsNeededDatatypeState} />
            {(asNeededDatatypeState === 'boolean') &&
              <DataEntry datatype='boolean' elementName='asNeededBoolean' fieldLabel='As needed'
                startingValue={resourceState.asNeededBoolean ?? null} setResourceState={setResourceState}
                storeFalse={true} />}
            {(asNeededDatatypeState === 'CodeableConcept') &&
              <DataEntry datatype='CodeableConcept' elementName='asNeededCodeableConcept' startCollapsed
                fieldLabel='As needed (reason)' startingValue={resourceState.asNeededCodeableConcept || null}
                systemChoices={planDefinitionDotAsNeededCodeableConceptSystemChoices} systemChoicesOpen
                setResourceState={setResourceState} />}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default PlanDefinitionBuilder;
