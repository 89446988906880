import { Table } from 'semantic-ui-react';
import { getStringFromFHIR, DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { characteristicNavigationEntryDisplay, navigateToAnotherCharacteristic } from './NavigationCharacteristicSegment';
import { useContext } from 'react';
import FevirContext from './FevirContext';

const getCharacteristicByCombinationDisplay = (characteristic) => {
  let characteristicByCombincationDisplay = "";
  let combinationCode = "";
  let threshold = "";
  if (characteristic.definitionByCombination) {
    if (characteristic.definitionByCombination.code !== undefined) {
      combinationCode = characteristic.definitionByCombination.code;
    }
    if (characteristic.definitionByCombination.threshold) {
      threshold = characteristic.definitionByCombination.threshold;
    }
    if (combinationCode === "any-of") {
      characteristicByCombincationDisplay = "Any of: ";
    } else if (combinationCode === "all-of") {
      characteristicByCombincationDisplay = "All of: ";
    } else if (combinationCode === "at-least") {
      characteristicByCombincationDisplay = "At least " + threshold + " of: ";
    } else if (combinationCode === "at-most") {
      characteristicByCombincationDisplay = "At most " + threshold + " of: ";
    } else if (combinationCode === "net-effect") {
      characteristicByCombincationDisplay = "Net effect by combination of: ";
    } else if (combinationCode === "statistical") {
      characteristicByCombincationDisplay = "Statistical combination of: ";
    } else if (combinationCode === "dataset") {
      characteristicByCombincationDisplay = "Dataset includes: ";
    }
  }

  return characteristicByCombincationDisplay;
}

const getTypeAndValueDisplayValuesForTable = (characteristic) => {
  let criteriaTypeDisplay = "";
  let criteriaValue = "";
  let criteriaValueDisplay = "";
  if (characteristic.definitionByTypeAndValue) {
    if (characteristic.definitionByTypeAndValue.type !== undefined) {
      criteriaTypeDisplay = getStringFromFHIR.CodeableConcept(characteristic.definitionByTypeAndValue.type);
    }
    if (characteristic.definitionByTypeAndValue.valueCodeableConcept !== undefined) {
      criteriaValue = characteristic.definitionByTypeAndValue.valueCodeableConcept;
      criteriaValueDisplay = getStringFromFHIR.CodeableConcept(criteriaValue);
    } else if (characteristic.definitionByTypeAndValue.valueBoolean !== undefined) {
      criteriaValue = characteristic.definitionByTypeAndValue.valueBoolean;
      criteriaValueDisplay = criteriaValue.toString();
    } else if (characteristic.definitionByTypeAndValue.valueQuantity !== undefined) {
      criteriaValue = characteristic.definitionByTypeAndValue.valueQuantity;
      criteriaValueDisplay = getStringFromFHIR.Quantity(criteriaValue);
    } else if (characteristic.definitionByTypeAndValue.valueRange !== undefined) {
      criteriaValue = characteristic.definitionByTypeAndValue.valueRange;
      criteriaValueDisplay = getStringFromFHIR.Range(criteriaValue);
    } else if (characteristic.definitionByTypeAndValue.valueReference !== undefined) {
      criteriaValue = characteristic.definitionByTypeAndValue.valueReference;
      criteriaValueDisplay = getStringFromFHIR.Reference(criteriaValue);
    } else if (characteristic.definitionByTypeAndValue.valueId !== undefined) {
      criteriaValue = characteristic.definitionByTypeAndValue.valueId;
      criteriaValueDisplay = criteriaValue;
    }
  }
  return [criteriaTypeDisplay, criteriaValueDisplay];
}

const getCharacteristicByTypeAndValueDisplay = (characteristic) => {
  let characteristicTypeDisplay = "";
  let characteristicValue = "";
  let characteristicValueDisplay = "";
  let characteristicByTypeAndValueDisplay = "";
  if (characteristic.definitionByTypeAndValue) {
    if (characteristic.definitionByTypeAndValue.type !== undefined) {
      characteristicTypeDisplay = getStringFromFHIR.CodeableConcept(characteristic.definitionByTypeAndValue.type);
    }
    if (characteristic.definitionByTypeAndValue.valueCodeableConcept !== undefined) {
      characteristicValue = characteristic.definitionByTypeAndValue.valueCodeableConcept;
      characteristicValueDisplay = getStringFromFHIR.CodeableConcept(characteristicValue);
    } else if (characteristic.definitionByTypeAndValue.valueBoolean !== undefined) {
      characteristicValue = characteristic.definitionByTypeAndValue.valueBoolean;
      characteristicValueDisplay = characteristicValue.toString();
    } else if (characteristic.definitionByTypeAndValue.valueQuantity !== undefined) {
      characteristicValue = characteristic.definitionByTypeAndValue.valueQuantity;
      characteristicValueDisplay = getStringFromFHIR.Quantity(characteristicValue);
    } else if (characteristic.definitionByTypeAndValue.valueRange !== undefined) {
      characteristicValue = characteristic.definitionByTypeAndValue.valueRange;
      characteristicValueDisplay = getStringFromFHIR.Range(characteristicValue);
    } else if (characteristic.definitionByTypeAndValue.valueReference !== undefined) {
      characteristicValue = characteristic.definitionByTypeAndValue.valueReference;
      characteristicValueDisplay = getStringFromFHIR.Reference(characteristicValue);
    } else if (characteristic.definitionByTypeAndValue.valueId !== undefined) {
      characteristicValue = characteristic.definitionByTypeAndValue.valueId;
      characteristicValueDisplay = characteristicValue;
    }
  }
  if (characteristicTypeDisplay && characteristicValueDisplay) {
    characteristicByTypeAndValueDisplay = characteristicTypeDisplay + ": " + characteristicValueDisplay;
  } else if (characteristicTypeDisplay) {
    characteristicByTypeAndValueDisplay = characteristicTypeDisplay;
  } else if (characteristicValueDisplay) {
    characteristicByTypeAndValueDisplay = characteristicValueDisplay;
  }

  return characteristicByTypeAndValueDisplay;
}

const getCharacteristicDefinitionDisplay = (characteristic) => {
  let characteristicDefinitionDisplay = "";
  if (characteristic.definitionByTypeAndValue) {
    characteristicDefinitionDisplay = getCharacteristicByTypeAndValueDisplay(characteristic);
  } else if (characteristic.definitionByCombination) {
    characteristicDefinitionDisplay = getCharacteristicByCombinationDisplay(characteristic);
  } else if (characteristic.definitionReference !== undefined) {
    characteristicDefinitionDisplay = getStringFromFHIR.Reference(characteristic.definitionReference);
    if (characteristicDefinitionDisplay === undefined || characteristicDefinitionDisplay === "") {
      characteristicDefinitionDisplay = JSON.stringify(characteristic.definitionReference, null, 2);
    }
  } else if (characteristic.definitionCanonical !== undefined) {
    characteristicDefinitionDisplay = characteristic.definitionCanonical;
  } else if (characteristic.definitionCodeableConcept !== undefined) {
    characteristicDefinitionDisplay = getStringFromFHIR.CodeableConcept(characteristic.definitionCodeableConcept);
  } else if (characteristic.definitionExpression !== undefined) {
    characteristicDefinitionDisplay = characteristic.definitionExpression.description || characteristic.definitionExpression.expression || characteristic.definitionExpression.name || JSON.stringify(characteristic.definitionExpression, null, 2);
    if (characteristicDefinitionDisplay === undefined || characteristicDefinitionDisplay === "") {
      characteristicDefinitionDisplay = characteristic.definitionExpression;
    }
  } else if (characteristic.definitionId !== undefined) {
    characteristicDefinitionDisplay = characteristic.definitionId;
  }
  if (typeof characteristicDefinitionDisplay === "object") {
    characteristicDefinitionDisplay = JSON.stringify(characteristicDefinitionDisplay, null, 2);
  }
  return characteristicDefinitionDisplay;
}

const ChildCriteriaTable = ({ parentJson, resourceId, fhirEntryState, setFhirEntryState,
  formInputsStateRef, history, changeFhirEntryState, submitUpdatedFhirResource, changeFormState, addToast }) => {
  const globalContext = useContext(FevirContext);
  let header = "Child criteria:";
  let conceptPath = fhirEntryState.conceptPath;
  if (conceptPath === undefined || conceptPath.length === 0) {
    header = "Top-level criteria:"
  }
  let subCharacteristics;
  if (parentJson.characteristic?.length > 0) {
    subCharacteristics = parentJson.characteristic;
  } else if (parentJson.definitionByCombination?.characteristic?.length > 0) {
    subCharacteristics = parentJson.definitionByCombination.characteristic;
  }

  return <>
    {subCharacteristics &&
      <div>
        <b>{header}</b>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Definition</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Qualifiers</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {subCharacteristics.map((subConcept, subConceptIndex) => {
              let subConceptPath = [];
              let tempPathString = characteristicNavigationEntryDisplay(subConcept);
              let subConceptPathString = fhirEntryState.conceptPathString + ": " + tempPathString[1];
              if (conceptPath) {
                subConceptPath = [...conceptPath, tempPathString[1]];
              } else {
                subConceptPath = [tempPathString[1]];
              }
              let subConceptPathIndexes = [];
              if (fhirEntryState.conceptPathIndexes) {
                subConceptPathIndexes = [...fhirEntryState.conceptPathIndexes, subConceptIndex];
              } else {
                subConceptPathIndexes = [subConceptIndex];
              }
              return <Table.Row key={subConceptIndex}>
                <Table.Cell>
                  <a onClick={() => {
                    let tempPathString = characteristicNavigationEntryDisplay(subConcept);
                    navigateToAnotherCharacteristic("Characteristic", history, fhirEntryState, setFhirEntryState,
                      'EvidenceVariable', resourceId, parentJson, subConcept, tempPathString[1], subConceptPath,
                      subConceptPathIndexes, subConceptPathString, formInputsStateRef, changeFhirEntryState,
                      submitUpdatedFhirResource, globalContext, changeFormState, addToast);
                  }}>{characteristicNavigationEntryDisplay(subConcept)[1]}</a>
                  {subConcept.offset && <><br /><br /><b>Offset:</b> {getStringFromFHIR.CodeableConcept(subConcept.offset)}</>} {/*TO DO todo, June 23, 2022 remove this line, this is the OLD structure for offset, no longer needed*/}
                  {subConcept.definitionByTypeAndValue?.offset && <><br /><b>Offset:</b> <DisplayFromFHIR codeableConcept={subConcept.definitionByTypeAndValue.offset} /></>}
                </Table.Cell>
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {subConcept.description && <span>
                    <b>Description: </b><DisplayFromFHIR markdown={subConcept.description} />
                  </span>}
                  <br />
                  {Array.isArray(subConcept.note) && subConcept.note.length > 0 && <span><b>Notes: </b></span>}
                  {Array.isArray(subConcept.note) && subConcept.note.map((note, noteIndex) => {
                    if (typeof (note) === 'object') {
                      return <div key={noteIndex}><DisplayFromFHIR annotation={note} /><br /></div>
                    }
                    if (typeof (note) === 'string') {
                      return <div key={noteIndex}>{note}<br /></div>
                    }
                  })}
                </Table.Cell>
                <Table.Cell style={{ verticalAlign: "top" }}>
                  {subConcept.instancesQuantity && <>
                    <b>Number of instances:</b> {getStringFromFHIR.Quantity(subConcept.instancesQuantity)}<br />
                  </>}
                  {subConcept.instancesRange && <>
                    <b>Number of instances:</b> {getStringFromFHIR.Range(subConcept.instancesRange)}<br />
                  </>}
                  {subConcept.durationQuantity && <>
                    <b>Duration:</b> {getStringFromFHIR.Quantity(subConcept.durationQuantity)}<br />
                  </>}
                  {subConcept.durationRange && <>
                    <b>Duration:</b> {getStringFromFHIR.Range(subConcept.durationRange)}<br />
                  </>}
                  {Array.isArray(subConcept.definitionByTypeAndValue?.method) &&
                    <>
                      <b>Method:</b>
                      {subConcept.definitionByTypeAndValue.method.length === 1 ?
                        <DisplayFromFHIR codeableConcept={subConcept.definitionByTypeAndValue.method} />
                        :
                        subConcept.definitionByTypeAndValue.method.map((method) => {
                          return getStringFromFHIR.CodeableConcept(method)
                        }).join('; ')
                      }
                    </>}
                  {subConcept.definitionByTypeAndValue?.device &&
                    <><br /><br /><b>Device:</b> <DisplayFromFHIR reference={subConcept.definitionByTypeAndValue.device} /></>}
                  {subConcept.timeFromEvent?.length > 0 && <>
                    {subConcept.timeFromEvent.map((timeFromEvent, timeFromEventIndex) => {
                      return <div key={timeFromEventIndex}>
                        <b>Time from event:</b>
                        <br />
                        <div style={{ paddingLeft: "32px" }}>
                          {timeFromEvent.description && <><b>Description: </b> <DisplayFromFHIR markdown={timeFromEvent.description} /><br /></>}
                          {(timeFromEvent.note?.length === 1 && timeFromEvent.note[0].text) && <><b>Note: </b> {timeFromEvent.note[0].text}<br /></>}
                          {timeFromEvent.note?.length > 1 && <><b>Notes: </b> {timeFromEvent.note.map((note, noteIndex) => { return <span key={noteIndex}>{note.text}{noteIndex + 1 < timeFromEvent.note.length && <>; </>}</span> })}<br /></>}
                          {timeFromEvent.eventCodeableConcept && <><b>Event Definition: </b> <DisplayFromFHIR codeableConcept={timeFromEvent.eventCodeableConcept} /><br /></>}
                          {timeFromEvent.eventReference && <><b>Event Definition: </b> <DisplayFromFHIR reference={timeFromEvent.eventReference} /><br /></>}
                          {timeFromEvent.eventDateTime && <><b>Event Definition: </b> {getStringFromFHIR.dateTime(timeFromEvent.eventDateTime)}<br /></>}
                          {timeFromEvent.eventId && <><b>Event Definition: </b> {timeFromEvent.eventId}<br /></>}
                          {timeFromEvent.quantity && <><b>Timing: </b> {getStringFromFHIR.Quantity(timeFromEvent.quantity)}<br /></>}
                          {timeFromEvent.range && <><b>Timing: </b> {getStringFromFHIR.Range(timeFromEvent.range)}<br /></>}
                          <br /><br />
                        </div>
                      </div>
                    })}
                  </>}
                </Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
      </div>
    }
  </>
}

const EvidenceVariableCharacteristicDisplay = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState, 
  history, changeFhirEntryState, submitUpdatedFhirResource, changeFormState, addToast }) => {

  let characteristic = fhirEntryState.characteristicSelected;
  let fhirJson = characteristic;
  if (fhirJson?.note) {
    if (!Array.isArray(fhirJson.note)) {
      fhirJson.note = [fhirJson.note];
    }
    for (let i in fhirJson.note) {
      if (typeof (fhirJson.note[i]) === 'string') {
        fhirJson.note[i] = { "text": fhirJson.note[i] };
      }
    }
  }

  let conceptPath = fhirEntryState.conceptPath;

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          <h2 style={{ margin: "0px" }}>{fhirEntryState.conceptName}</h2>
          <br />
          <span style={{ whiteSpace: "pre-wrap" }}>
            <div style={{ marginTop: "12px" }}>
              <h3 id="description">Description</h3>
              <div style={{ marginLeft: "24px" }}>
                {fhirJson.description && <div><b>Description: </b><DisplayFromFHIR markdown={fhirJson.description} /><br /><br /></div>}
                {Array.isArray(fhirJson.note) && fhirJson.note.map((note, noteIndex) => {
                  return <div key={noteIndex}><div><b>Note: </b><DisplayFromFHIR annotation={note} /></div></div>
                })}
              </div>
              <h3 id="definition">Definition</h3>
              <div style={{ marginLeft: "24px" }}>
                {fhirJson.exclude ? <p><b>EXCLUDE</b> subjects matching this characteristic definition</p> :
                  <p>INCLUDE subjects matching this characteristic definition</p>}
                {fhirJson.definitionReference && <span><b>Defined at: </b><DisplayFromFHIR reference={fhirJson.definitionReference} /><br /></span>}
                {fhirJson.definitionCanonical && <span><b>Defined at: </b><DisplayFromFHIR uri={fhirJson.definitionCanonical} /><br /></span>}
                {fhirJson.definitionCodeableConcept && <span><b>Defined as: </b><DisplayFromFHIR codeableConcept={fhirJson.definitionCodeableConcept} /><br /></span>}
                {fhirJson.definitionId && <span><b>Defined at ID: </b>fhirJson.definitionId<br /></span>}
                {fhirJson.definitionByTypeAndValue && <div>
                  <div>
                    <b>Attribute: </b><DisplayFromFHIR codeableConcept={fhirJson.definitionByTypeAndValue.type} />
                    {Array.isArray(fhirJson.definitionByTypeAndValue.method) &&
                      fhirJson.definitionByTypeAndValue.method.map((method, methodIndex) => {
                        return <div style={{ marginLeft: "24px" }} key={methodIndex}>
                          <b>Determined by: </b><DisplayFromFHIR codeableConcept={method} />
                        </div>
                      })}
                    {fhirJson.definitionByTypeAndValue.device && <div style={{ marginLeft: "24px" }}>
                      <b>Determined with: </b><DisplayFromFHIR reference={fhirJson.definitionByTypeAndValue.device} />
                    </div>}
                    <br /><br />
                  </div>
                  <div>
                    <p><b>Matching Value of:</b></p>
                    <div style={{ marginLeft: "24px" }}>
                      {fhirJson.definitionByTypeAndValue.valueCodeableConcept && <DisplayFromFHIR codeableConcept={fhirJson.definitionByTypeAndValue.valueCodeableConcept} />}
                      {fhirJson.definitionByTypeAndValue.valueBoolean === true && <>true</>}
                      {fhirJson.definitionByTypeAndValue.valueBoolean === false && <>false</>}
                      {fhirJson.definitionByTypeAndValue.valueQuantity && getStringFromFHIR.Quantity(fhirJson.definitionByTypeAndValue.valueQuantity)}
                      {fhirJson.definitionByTypeAndValue.valueRange && getStringFromFHIR.Range(fhirJson.definitionByTypeAndValue.valueRange)}
                      {fhirJson.definitionByTypeAndValue.valueReference && <DisplayFromFHIR reference={fhirJson.definitionByTypeAndValue.valueReference} />}
                      {fhirJson.definitionByTypeAndValue.valueId && fhirJson.definitionByTypeAndValue.valueId}
                      {fhirJson.definitionByTypeAndValue.offset && <div style={{ marginLeft: "24px" }}>
                        <b>Offset: </b>
                        <DisplayFromFHIR codeableConcept={fhirJson.definitionByTypeAndValue.offset} />
                      </div>}
                    </div>
                  </div>
                </div>
                }
                {fhirJson.definitionByCombination && <div>
                  <p>
                    <b>Method of Combination: </b>
                    {fhirJson.definitionByCombination.code}
                    &nbsp;&nbsp;&nbsp;
                    {fhirJson.definitionByCombination.threshold && fhirJson.definitionByCombination.threshold}
                  </p>
                  {(fhirJson.definitionByCombination.characteristic &&
                    Array.isArray(fhirJson.definitionByCombination.characteristic) &&
                    fhirJson.definitionByCombination.characteristic.length > 0) &&
                    <ChildCriteriaTable parentJson={fhirJson}
                      resourceId={resourceId} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
                      formInputsStateRef={formInputsStateRef} history={history}
                      changeFhirEntryState={changeFhirEntryState} submitUpdatedFhirResource={submitUpdatedFhirResource}
                      changeFormState={changeFormState} addToast={addToast} />}
                </div>}
                     <br /><br />
                {fhirJson.instancesQuantity && <p>
                  <b>Number of instances matching characteristic definition: </b>
                  {getStringFromFHIR.Quantity(fhirJson.instancesQuantity)}
                </p>}
                {fhirJson.instancesRange && <p>
                  <b>Number of instances matching characteristic definition: </b>
                  {getStringFromFHIR.Range(fhirJson.instancesRange)}
                </p>}
                {fhirJson.durationQuantity && <p>
                  <b>Length of time meeting characteristic definition: </b>
                  {getStringFromFHIR.Quantity(fhirJson.durationQuantity)}
                </p>}
                {fhirJson.durationRange && <p>
                  <b>Length of time meeting characteristic definition: </b>
                  {getStringFromFHIR.Range(fhirJson.durationRange)}
                </p>}
                {(Array.isArray(fhirJson.timeFromEvent) && fhirJson.timeFromEvent.length > 0) &&
                  fhirJson.timeFromEvent.map((timing, timingIndex) => {
                    return <div key={timingIndex}>
                      <p><b>Timing from Event:</b></p>
                      <div style={{ marginLeft: "24px" }}>
                        {timing.description && <><b>Description: </b><DisplayFromFHIR markdown={timing.description} /><br /></>}
                        {timing.eventCodeableConcept && <><b>Event: </b><DisplayFromFHIR codeableConcept={timing.eventCodeableConcept} /><br /></>}
                        {timing.eventReference && <><b>Event: </b><DisplayFromFHIR reference={timing.eventReference} /><br /></>}
                        {timing.eventDateTime && <><b>Event: </b>{getStringFromFHIR.dateTime(timing.eventDateTime)}<br /></>}
                        {timing.eventId && <><b>Event ID: </b>timing.eventId<br /></>}
                        {timing.quantity && <><b>At: </b>{getStringFromFHIR.Quantity(timing.quantity)}<br /></>}
                        {timing.range && <><b>Within: </b>{getStringFromFHIR.Range(timing.range)}<br /></>}
                        {(Array.isArray(timing.note) && timing.note.length === 1) && <div><b>Note: </b><DisplayFromFHIR annotation={timing.note[0]} /></div>}
                        {(Array.isArray(timing.note) && timing.note.length > 1) && timing.note.map((note, noteIndex) => {
                          return <div key={noteIndex}><div><b>Note: </b><DisplayFromFHIR annotation={note} /></div></div>
                        })}
                      </div>
                    </div>
                  })}
                       {fhirJson.definitionExpression && <span><b>Defined as: </b><DisplayFromFHIR expression={fhirJson.definitionExpression} /><br /></span>}
              </div>
            </div>
            <br />
          </span>
          <h3 id="json-outline">JSON Outline</h3>
          <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
          <br /><br />
          <span><b>JSON string</b> <span className={"unselectable"} style={{ cursor: "pointer" }} onClick={() => { setFhirEntryState(prevState => { return { ...prevState, conceptJsonExpanded: !prevState.conceptJsonExpanded }; }) }}>{fhirEntryState.conceptJsonExpanded ? <>▼</> : <>►</>}</span></span>
          <br />
          {fhirEntryState.conceptJsonExpanded && <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>{JSON.stringify(characteristic, null, 2)}</div>}
        </>
        :
        <>
          <span>Please select a term</span>
          <br />
          <br />
          <ChildCriteriaTable parentJson={fhirEntryState.fhirEntryString && JSON.parse(fhirEntryState.fhirEntryString)}
            resourceId={resourceId} fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
            formInputsStateRef={formInputsStateRef} history={history} />
        </>
      }
    </div>
  </div>
}

export { EvidenceVariableCharacteristicDisplay, getCharacteristicDefinitionDisplay, ChildCriteriaTable, getTypeAndValueDisplayValuesForTable, getCharacteristicByCombinationDisplay };