import React, { useState, useEffect } from 'react';
import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import ManageInclusionExclusionEnhancedCharacteristicTables from './ManageInclusionExclusionEnhancedCharacteristicTables';

const groupDotTypeValues = ['person', 'animal', 'practitioner', 'device', 'careteam', 'healthcareservice', 'location', 'organization', 'relatedperson', 'specimen'];
const groupDotMembershipValues = ['conceptual', 'definitional', 'enumerated'];
const groupDotManagingEntityResourceTypes = ['Organization', 'RelatedPerson', 'Practitioner', 'PractitionerRole'];
const groupDotCombinationMethodValues = ['all-of', 'any-of', 'at-least', 'at-most', 'except-subset'];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.type && typeof resource.type === "string") {
      innerDiv += "<p><b>Type: </b>" + resource.type + "</p><br/>";
    }
    if (resource.code) {
      innerDiv += "<p><b>Kind: </b>" + getStringFromFHIR.CodeableConcept(resource.code) + "</p><br/>";
    }
    if (resource.membership) {
      innerDiv += "<p><b>Membership/Definitional: </b>" + resource.membership + "</p><br/>";
    }
    if (resource.quantity) {
      innerDiv += "<p><b>Quantity: </b>" + resource.quantity + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.managingEntity) {
      innerDiv += "<p><b>Managing Entity: </b>" + getStringFromFHIR.Reference(resource.managingEntity) + "</p><br/>";
    }
    let characteristicExpression;
    if (Array.isArray(resource.extension)) {
      for (let extension of resource.extension) {
        if (extension.url === "http://hl7.org/fhir/StructureDefinition/characteristicExpression") {
          characteristicExpression = extension.valueExpression;
        }
      }
    }
    if (characteristicExpression) {
      status = "extensions";
      if (characteristicExpression.name) {
        innerDiv += "<p><b>Defined by Expression Named: </b>" + characteristicExpression.name + "</p><br/>";
      }
      if (characteristicExpression.description) {
        innerDiv += "<p><b>Defined by Expression: </b>" + characteristicExpression.description + "</p><br/>";
      }
      if (characteristicExpression.language) {
        innerDiv += "<p><b>Defined by Expression in Language: </b>" + characteristicExpression.language + "</p><br/>";
      }
      if (characteristicExpression.expression) {
        innerDiv += "<p><b>Defined by Expression: </b>" + characteristicExpression.expression + "</p><br/>";
      }
      if (characteristicExpression.reference) {
        innerDiv += "<p><b>Defined by Expression at URL: </b>" + characteristicExpression.reference + "</p><br/>";
      }
    }
    if (resource.combinationMethod) {
      innerDiv += "<p><b>Combination Method: </b>" + resource.combinationMethod + "</p><br/>";
    }
    if (resource.combinationThreshold) {
      innerDiv += "<p><b>Number of characteristics: </b>" + resource.combinationThreshold + "</p><br/>";
    }
    if (resource.characteristic?.length > 0) {
      innerDiv += resource.characteristic.map((item) => {
        let description = item.description || "";
        let code = getStringFromFHIR.CodeableConcept(item.code) || "";
        let categoryValue = "";
        if (item.valueCodeableConcept) {
          categoryValue = getStringFromFHIR.CodeableConcept(item.valueCodeableConcept);
        } else if (item.valueQuantity) {
          categoryValue = getStringFromFHIR.Quantity(item.valueQuantity);
        } else if (item.valueRange) {
          categoryValue = getStringFromFHIR.Range(item.valueRange);
        } else if (item.valueReference) {
          categoryValue = getStringFromFHIR.Reference(item.valueReference);
        } else if (item.valueBoolean) {
          categoryValue = getStringFromFHIR.boolean(item.valueBoolean);
        } else if (item.valueUri) {
          categoryValue = item.valueUri;
        } else if (item.valueExpression) {
          categoryValue = "[Defined by Expression]";
        }
        if (description) {
          return "<p><b>Characteristic: </b>" + description + "; <b>Defined as: </b>" + code + " matching value of " + categoryValue + "</p><br/>"
        } else {
          return "<p><b>Characteristic: </b>" + code + " matching value of " + categoryValue + "</p><br/>"
        }
      }).join("");
    }
    if (resource.member?.length > 0) {
      innerDiv += resource.member.map((member) => {
        return "<p><b>Member: </b>" + getStringFromFHIR.Reference(member.entity) + "</p><br/>"
      }).join("");
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const GroupBuilder = ({ resourceState, setResourceState, globalContext, setSourceJsonState }) => {

  let fhirJson = resourceState.resourceJson;

  let starterCharacteristicExpression = null;
  if (Array.isArray(fhirJson.extension)) {
    for (let extension of fhirJson.extension) {
      if (extension.url === "http://hl7.org/fhir/StructureDefinition/characteristicExpression") {
        starterCharacteristicExpression = extension.valueExpression;
        break;
      }
    }
  }

  const [characteristicExpressionState, setCharacteristicExpressionState] = useState({ "valueExpression": starterCharacteristicExpression });

  useEffect((() => {
    if (characteristicExpressionState.valueExpression) {
      setResourceState(prevState => {
        let newExtension = prevState.extension;
        if (!newExtension) {
          newExtension = [];
        }
        let expressionExtensionFound = false;
        for (let extension of newExtension) {
          if (extension.url === "http://hl7.org/fhir/StructureDefinition/characteristicExpression") {
            expressionExtensionFound = true;
            extension.valueExpression = characteristicExpressionState.valueExpression;
          }
        }
        if (!expressionExtensionFound) {
          newExtension.push({
            "url": "http://hl7.org/fhir/StructureDefinition/characteristicExpression",
            "valueExpression": characteristicExpressionState.valueExpression
          })
        }
        return { ...prevState, "extension": newExtension };
      });
    }
  }), [characteristicExpressionState]);

  let noMemberText = "Members list not provided";
  if (fhirJson.member?.length > 0) {
    noMemberText = "";
  }

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <p>Select a Membership code for this Group. Choose 'enumerated' if you will report a quantity for the Group or if you will report or reference the Group members. Choose 'definitional' if you will report the characteristics defining membership in the Group and will select a Type code specifying the type of Group members in a FHIR context. Choose 'conceptual' if you are not reporting an enumerated or definitional Group, or if you are reporting a Group without a Type code.</p>
        <DataEntry datatype='code' elementName='membership' fieldLabel='Membership'
          allowedValues={groupDotMembershipValues}
          startingValue={resourceState.membership} setResourceState={setResourceState} />
        <DataEntry datatype='unsignedInt' elementName='quantity' fieldLabel='Quantity'
          startingValue={resourceState.quantity} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='type' fieldLabel='Type'
          allowedValues={groupDotTypeValues}
          startingValue={resourceState.type} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Kind'
          startCollapsed
          startingValue={resourceState.code} setResourceState={setResourceState} />
        <DataEntry datatype="Expression" elementName="valueExpression"
          fieldLabel="Characteristics Defined By Expression"
          startingValue={characteristicExpressionState.valueExpression}
          startCollapsed
          setResourceState={setCharacteristicExpressionState} />
        <DataEntry datatype='Reference' elementName='managingEntity' fieldLabel='Managing Entity'
          referencedResourceTypes={groupDotManagingEntityResourceTypes} startCollapsed
          startingValue={resourceState.managingEntity} setResourceState={setResourceState} />
        <h4>Narrative Summary</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
            generatedNarrative={generateNarrative(resourceState)} startCollapsed
            startingValue={resourceState.text} setResourceState={setResourceState} />
        </div>
      </div>
      <h3 id="characteristic-combination">Characteristic Combination</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='code' elementName='combinationMethod' fieldLabel='Combination Method'
          allowedValues={groupDotCombinationMethodValues}
          startingValue={resourceState.combinationMethod} setResourceState={setResourceState} />
        {(resourceState.combinationMethod === "at-least" || resourceState.combinationMethod === "at-most" ||
          resourceState.combinationThreshold) && <DataEntry datatype='positiveInt'
            elementName='combinationThreshold' fieldLabel='Number of characteristics'
            startingValue={resourceState.combinationThreshold} setResourceState={setResourceState} />}
      </div>
      <br />
      <ManageInclusionExclusionEnhancedCharacteristicTables editMode={true}
        setSourceJsonState={setSourceJsonState}
        globalContext={globalContext}
        fhirJson={fhirJson} setResourceState={setResourceState} />
      <h3 id="members">Members</h3>
      <div style={{ marginLeft: "24px" }}>
        {resourceState.membership === "enumerated" ?
          <>{noMemberText ?
            <p>{noMemberText}</p>
            :
            <>{fhirJson.member.map((member, memberIndex) => {
              if (member.entity === undefined) {
                return <p key={memberIndex}></p>
              }
              return <div key={memberIndex}>
                <DisplayFromFHIR reference={member.entity} />
              </div>
            })}</>
          }</>
          :
          <p>This is a descriptive (definitional) Group. Listing members does not apply.</p>
        }
      </div>
    </div>
  </div>
}

export default GroupBuilder;
