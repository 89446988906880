import React, { useState, useImperativeHandle } from 'react';
import { DataEntry, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const SoftwareScriptEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
/*
  const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental", "date", "publisher", "contact", "description", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "subtitle", "nameWithoutExtension", "extensionCode", "language", "format", "classification", "size",
    "location", "codeString", "containedScript", "dependsOn", "output", "compatibleWith", "licenseTerms", "licenseContact",
    "authorizationCriteria", "integrity", "expectedQuantity"]
*/
  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "SoftwareScript", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "subtitle": fhirJson.subtitle, "nameWithoutExtension": fhirJson.nameWithoutExtension, "extensionCode": fhirJson.extensionCode,
    "format": fhirJson.format, "classification": fhirJson.classification, "size": fhirJson.size,
    "location": fhirJson.location, "codeString": fhirJson.codeString, "containedScript": fhirJson.containedScript, "dependsOn": fhirJson.dependsOn,
    "output": fhirJson.output, "compatibleWith": fhirJson.compatibleWith, "licenseTerms": fhirJson.licenseTerms,
    "licenseContact": fhirJson.licenseContact, "authorizationCriteria": fhirJson.authorizationCriteria,
    "integrity": fhirJson.integrity, "expectedQuantity": fhirJson.expectedQuantity,
    "newClassifications": null
  });

  useImperativeHandle(formInputsStateRef, () => ({
    softwareScriptState: resourceState
  }), [resourceState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='subtitle' fieldLabel='Subtitle'
          startingValue={resourceState.subtitle} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='nameWithoutExtension' fieldLabel='Filename without extension'
          startingValue={resourceState.nameWithoutExtension} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='extensionCode' fieldLabel='Filename Extension'
          startingValue={resourceState.extensionCode} setResourceState={setResourceState} />
      </div>
      <h3 id="detail">Detail</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='language' fieldLabel='Language'
          startingValue={resourceState.language} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='format'
          fieldLabel='Format' startingValue={resourceState.format} setResourceState={setResourceState} />
        <DataEntry datatype='Quantity' elementName='size' fieldLabel='Size'
          startingValue={resourceState.size} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='string' elementName='location'
          fieldLabel='Location' startingValue={resourceState.location} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startingValue={resourceState.description} setResourceState={setResourceState} />
      </div>
      <h3 id="code-string">Code String</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='codeString' fieldLabel='Code String'
          startingValue={resourceState.codeString} setResourceState={setResourceState} />
      </div>
      <h3 id="files-documents">Files/Documents</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='RelatedArtifact' elementName='relatedArtifact'
          fieldLabel='Related Item' allowedTypeValues='FHIR' deletableArray={true}
          startingValue={resourceState.relatedArtifact} setResourceState={setResourceState} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit fhirJson={fhirJson} resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <br /><br />
    </div>
  </div>
}

export default SoftwareScriptEdit;
