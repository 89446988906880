import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Button, TextArea, Dimmer, Loader, Table } from 'semantic-ui-react';
import { TextField, RadioGroup, Radio, FormControlLabel, Checkbox } from '@mui/material';
import "firebase/compat/auth";
import { getResource } from './UserAccountFunctions';
import { getContainedResources } from './ResourceFunctions';
import YesNoSwitch from './YesNoSwitch';

const changeQuestionnaireUserAnswer = (setQuestionnaireItemsState, path, newValue, answerIndex, field) => {
  try {
    setQuestionnaireItemsState(prevState => {
      let currentPath = prevState[path[0]];
      for (let pathIndex = 1; pathIndex < path.length; pathIndex++) {
        currentPath = currentPath["subItems"][path[pathIndex]];
      }
      if (answerIndex === undefined) {
        currentPath["userAnswer"] = [{ [field]: newValue }];
      } else {
        while (currentPath["userAnswer"].length - 1 < answerIndex) {
          currentPath["userAnswer"].push({ [field]: '' });
        }
        currentPath["userAnswer"][answerIndex][field] = newValue;
      }
      return [...prevState];
    });
  } catch (e) { }
}

const SelectorView = ({ checkBoxes, checked, item, setQuestionnaireItemsState }) => {
  let selector;

  selector = <>◯</>;
  if (checkBoxes) {
    if (checked) {
      selector = <>☑</>;
    } else {
      selector = <>☐</>;
    }
  } else if (checked) {
    selector = <>⦿</>;
  }
  return <b>{selector}</b>;
}

const MultipleChoices = ({ item, itemIndex, dataEntryMode, userAnswer, setQuestionnaireItemsState }) => {
  let multipleChoicesDisplay;
  //let selectorDisplay = <SelectorView checkBoxes={item.repeats} checked={item.initialSelected} />;

  if (item.repeats) {
    if (dataEntryMode) {
      //TO DO CHECKBOXES
      multipleChoicesDisplay = <div>{item.answerChoices.map((itemAnswer, itemAnswerIndex) => {
        return <div key={itemAnswerIndex}>
          <Checkbox size="small" color="primary" style={{ padding: "0px" }}
            checked={(itemAnswer.userAnswer?.length > 0 && itemAnswer.userAnswer[0]["value"]) ? itemAnswer.userAnswer[0]["value"] : false}
            onChange={(e) => {console.log('change line 77');
              let path = item.path;
              setQuestionnaireItemsState(prevState => {
                let currentPath = prevState[path[0]];
                for (let pathIndex = 1; pathIndex < path.length; pathIndex++) {
                  currentPath = currentPath["subItems"][path[pathIndex]];
                }
                if (currentPath["answerChoices"][itemAnswerIndex]["userAnswer"] === undefined || currentPath["answerChoices"][itemAnswerIndex]["userAnswer"].length === 0) {
                  currentPath["answerChoices"][itemAnswerIndex]["userAnswer"] = [{ "value": false }];
                }
                currentPath["answerChoices"][itemAnswerIndex]["userAnswer"][0]["value"] = e.target.checked;
                return [...prevState];
              });
            }}
          /> {itemAnswer.display}</div>
      })}</div>;
    } else {
      multipleChoicesDisplay = <div>{item.answerChoices.map((itemAnswer, itemAnswerIndex) => {
        let subSelectorDisplay = <SelectorView checkBoxes={(item.isCheckboxes || item.repeats) ? true : false} checked={itemAnswer.initialSelected} />;
        return <div key={itemAnswerIndex}>{subSelectorDisplay} {itemAnswer.display}</div>
      })}</div>;
    }
  } else {
    if (dataEntryMode) {
      multipleChoicesDisplay = <RadioGroup row={false} aria-label={item.text} name={"item-" + itemIndex}
        value={item.userAnswer?.[0]?.value || ''}
        onChange={(e) => {console.log('change line 103'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}>
        {item.answerChoices.map((answer, answerIndex) => {
          return <div key={answerIndex}>
            <FormControlLabel
              name="radio-button-control" color="default"
              value={answer.code || answer.display}
              control={<Radio color="primary" style={{ padding: "4px 0px 2px 0px" }} />} inputprops={{ 'aria-label': answer.display }}
              label={<div style={{ textAlign: 'center' }}>&nbsp;{answer.display}</div>}
            /></div>
        })}
      </RadioGroup>
    } else {
      multipleChoicesDisplay = <div>{item.answerChoices.map((itemAnswer, itemAnswerIndex) => {
        let subSelectorDisplay = <SelectorView checkBoxes={(item.isCheckboxes || item.repeats) ? true : false} checked={itemAnswer.initialSelected} />;
        return <div key={itemAnswerIndex}>{subSelectorDisplay} {itemAnswer.display}</div>
      })}</div>;
    }
  }
  return <>{multipleChoicesDisplay && multipleChoicesDisplay}</>
}

const TypeBasedDataEntryItem = ({ item, itemIndex, dataEntryMode, userAnswer, setQuestionnaireItemsState }) => {
  let itemDisplay;

  if (item.type === "coding") {
    if (dataEntryMode) {
      itemDisplay = <div style={{ marginTop: "8px" }}>
        <TextField style={{ width: "260px" }} label="System" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["system"] || ''}
          onChange={(e) => {console.log('change line 131');
            changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "system");
          }}
        />
        &nbsp;
        <TextField style={{ width: "180px" }} label="Code" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["code"] || ''}
          onChange={(e) => {console.log('change line 137'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "code"); }}
        />
        &nbsp;
        <TextField style={{ width: "200px" }} label="Display" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["display"] || ''}
          onChange={(e) => {console.log('change line 141'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "display"); }}
        />
      </div>
    } else {
      itemDisplay = <div><div style={{ width: "260px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF", display: "inline-block" }}>&nbsp;System</div>&nbsp;<div style={{ width: "180px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF", display: "inline-block" }}>&nbsp;Code</div>&nbsp;<div style={{ width: "200px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF", display: "inline-block" }}>&nbsp;Display</div></div>;
    }
  } else if (item.type === "string") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "50%", marginTop: "8px" }} multiline className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        //type='tel' onKeyPress={(e) => { if (e.key === "-") { alert("Your answer has to be a posiitive integer."); } }} //e.key === "." ||
        onChange={(e) => {console.log('change line 151'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />;
    } else {
      itemDisplay = <div style={{ width: "500px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "text") {
    if (dataEntryMode) {
      itemDisplay = <TextArea style={{ width: "90%", marginTop: "8px" }} value={(userAnswer.length > 0 && userAnswer[0]["value"]) || ''}
        onChange={(e) => {console.log('change line 159'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />
    } else {
      itemDisplay = <div style={{ width: "600px", height: "48px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "date") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "160px", marginTop: "8px" }} label="Date" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />
    } else {
      itemDisplay = <div style={{ width: "160px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "time") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "160px", marginTop: "8px" }} label="Time" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />
    } else {
      itemDisplay = <div style={{ width: "160px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "dateTime") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "260px", marginTop: "8px" }} label="Date Time" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />
    } else {
      itemDisplay = <div style={{ width: "260px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "decimal") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "160px", marginTop: "8px" }} className="inputField" type="number" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />
    } else {
      itemDisplay = <div style={{ width: "160px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "integer") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "160px", marginTop: "8px" }} className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        //type='tel' onKeyPress={(e) => { if (e.key === "-") { alert("Your answer has to be a posiitive integer."); } }} //e.key === "." ||
        //numberInput = numberInput.replace("-", "");
        onChange={(e) => {
          //console.log(e.target.value);
          let numberInput = e.target.value;
          numberInput = numberInput.replace(/[^0-9\-]/g, '');
          changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, numberInput, 0, "value");
        }}
      />
    } else {
      itemDisplay = <div style={{ width: "160px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "quantity") {
    if (dataEntryMode) {
      itemDisplay = <div style={{ marginTop: "8px" }}>
        <TextField style={{ width: "120px" }} label="Comparator" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["comparator"] || ''}
          onChange={(e) => {
            let input = e.target.value;
            input = input.replace(" ", "").replace(".", "").replace(",", "").replace("-", "").replace("\n", "").replace("=<", "<=").replace("=>", ">=").toLowerCase();
            if (input === "<" || input === "<=" || input === ">=" || input === ">" || input === "=" || input === "ad" || input === "a" || input === "d" || input === "") {
              changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, input, 0, "comparator");
            }
          }}
        /> {/*REPLACE WITH DROPDOWN*/}
        &nbsp;
        <TextField style={{ width: "160px" }} label="Value" className="inputField" type="number" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
          onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
        />
        &nbsp;
        <TextField style={{ width: "120px" }} label="Units" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["unit"] || ''}
          onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "unit"); }}
        />
      </div>
    } else {
      itemDisplay = <div><div style={{ width: "160px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF", display: "inline-block" }}>&nbsp;Comparator</div>&nbsp;<div style={{ width: "160px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF", display: "inline-block" }}>&nbsp;Value</div>&nbsp;<div style={{ width: "160px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF", display: "inline-block" }}>&nbsp;Units</div></div>;
    }
  } else if (item.type === "url") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "400px", marginTop: "8px" }} label="URL" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />
    } else {
      itemDisplay = <div style={{ width: "400px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "reference") {
    if (dataEntryMode) {
      itemDisplay = <TextField style={{ width: "400px", marginTop: "8px" }} label="Reference" className="inputField" size="small" variant='outlined' value={item["userAnswer"][0]["value"] || ''}
        onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value, 0, "value"); }}
      />
    } else {
      itemDisplay = <div style={{ width: "400px", height: "24px", border: "1px solid #000000", backgroundColor: "#FFFFFF" }} />;
    }
  } else if (item.type === "boolean") {
    if (dataEntryMode) {
      /*
      //OLD WAY with Radio buttons
      itemDisplay = <RadioGroup row={false} aria-label={item.text} name={"item-"+itemIndex} 
        value={item["userAnswer"][0]}
        onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.value); }}>
        {item.answerChoices.map((answer, answerIndex) => {
          return <div key={answerIndex}>
            <FormControlLabel
                name="radio-button-control" color="default"
                value={answer.code || answer.display}
                control={<Radio color="primary" style={{padding: "4px 0px 2px 0px"}} />} inputprops={{ 'aria-label': answer.display }}
                label={<div style={{textAlign: 'center'}}>&nbsp;{answer.display}</div>}
            /></div>})}
      </RadioGroup>;*/
      if (item["userAnswer"].length === 0 || item["userAnswer"][0]["value"] === "") {
        itemDisplay = <div style={{ align: "center", width: "100%", padding: "8px" }}>
          <Button className="formButton negative" content="No" compact style={{ width: "48px", padding: "5px 0px 5px 0px", margin: "0px", display: "inline-block" }}
            onClick={() => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, "false", 0, "value"); }}
          />
          <div style={{ paddingLeft: "8px", display: "inline-block" }} />
          <Button className="formButton positive" content="Yes" compact style={{ width: "48px", padding: "5px 0px 5px 0px", margin: "0px", display: "inline-block" }}
            onClick={() => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, "true", 0, "value"); }}
          />
        </div>;
      } else {
        let trueOrFalse;
        if (item["userAnswer"][0]["value"] === true || item["userAnswer"][0]["value"] === "true") {
          trueOrFalse = "true";
        } else if (item["userAnswer"][0]["value"] === false || item["userAnswer"][0]["value"] === "false") {
          trueOrFalse = "false";
        }
        itemDisplay = <FormControlLabel className="yesNoSliderLabel" style={{ margin: "4px 0px 4px 0px" }}
          control={<YesNoSwitch className="yesNoSliderSwitch" value={trueOrFalse} checked={trueOrFalse === "true" ? true : false}
            onClick={(e) => {
              let newValue = "true";
              if (e.target.value === "true") {
                newValue = "false";
              }
              changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, newValue, 0, "value");
            }} />}
        />
      }
    } else {
      itemDisplay = <div><b>◯ </b> Yes<span style={{ paddingLeft: "24px" }} /><b>◯ </b> No<span style={{ paddingLeft: "24px" }} /></div>;
    }
  } else if (item.type === "attachment") {
    //So users can add their own file
    itemDisplay = <div>Questionnaire attachments are <b>not</b> currently supported.</div>
  } else {
    console.log("Unrecognized Questionnaire item type");
  }
  return <div style={{ display: "inline-block", width: "100%" }}>{itemDisplay && itemDisplay}</div>;
}

const QuestionnaireItem = ({ questionnaireItemsState, setQuestionnaireItemsState, item, itemIndex, dataEntryMode, depth }) => {

  let userAnswer = item["userAnswer"];
  if (userAnswer === undefined || userAnswer.length === 0) {
    userAnswer = [{ "value": '' }];
  }

  let multipleChoicesDisplay;
  let itemDisplay;
  let groupDisplay;
  let selectorDisplay;

  if (item.type === "display") {
    //itemDisplay = <div><br /></div>
  } else if (item.type === "group") {
    if (item.subItems?.length > 0) {
      if (item.isTable && item.isCheckboxes) {
        let tableHeader = [{ "display": "" }, ...item.tableHeaders];
        groupDisplay = <Table>
          <Table.Header>
            <Table.Row>
              {tableHeader.map((header, headerIndex) => { return <Table.HeaderCell key={headerIndex}>{header.display}</Table.HeaderCell> })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {item.subItems.map((subItem, subItemIndex) => {
              return <Table.Row key={subItemIndex}>
                {tableHeader.map((header, headerIndex) => {
                  if (headerIndex == 0) {
                    return <Table.Cell key={headerIndex}>{subItem.prefix && <>{subItem.prefix}. </>}{subItem.text}</Table.Cell>;
                  } else {
                    if (dataEntryMode) {
                      return <Table.Cell key={headerIndex} style={{ textAlign: "center" }}>
                        <Checkbox size="small" color="primary" style={{ padding: "0px" }}
                          checked={(subItem["userAnswer"] && subItem["userAnswer"].length > 0 && subItem["userAnswer"][headerIndex]) ? subItem["userAnswer"][headerIndex] : false}
                          onChange={(e) => {console.log('change line 342');
                            let path = subItem.path;
                            setQuestionnaireItemsState(prevState => {
                              let currentPath = prevState[path[0]];
                              console.log(currentPath)
                              for (let pathIndex = 1; pathIndex < path.length; pathIndex++) {
                                currentPath = currentPath["subItems"][path[pathIndex]];
                              }
                              if (currentPath["userAnswer"][headerIndex] === undefined || currentPath["userAnswer"][headerIndex].length === 0) {
                                currentPath["userAnswer"][headerIndex] = false;
                              }
                              currentPath["userAnswer"][headerIndex] = e.target.checked;
                              return [...prevState];
                            });
                          }}
                        />
                      </Table.Cell>;
                    } else {
                      return <Table.Cell key={headerIndex} style={{ textAlign: "center" }}><b>◯ </b></Table.Cell>;
                    }
                  }
                })}
              </Table.Row>
            })}
          </Table.Body>
        </Table>;
      } else if (item.isTable) {
        let tableHeader = [{ "display": "" }, ...item.tableHeaders];
        groupDisplay = <Table>
          <Table.Header>
            <Table.Row>
              {tableHeader.map((header, headerIndex) => { return <Table.HeaderCell key={headerIndex}>{header.display}</Table.HeaderCell> })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {item.subItems.map((subItem, subItemIndex) => {
              return <Table.Row key={subItemIndex}>
                {tableHeader.map((header, headerIndex) => {
                  if (headerIndex == 0) {
                    return <Table.Cell key={headerIndex}>{subItem.prefix && <>{subItem.prefix}. </>}{subItem.text}</Table.Cell>;
                  } else {
                    if (dataEntryMode) {
                      return <Table.Cell key={headerIndex} style={{ textAlign: "center" }}>
                        <FormControlLabel
                          name="radio-button-control" color="default"
                          value={header.code || header.text}
                          control={<Radio color="primary" style={{ padding: "4px 0px 2px 0px" }}
                            onChange={(e) => {console.log('change line 388'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, subItem.path, header.code, 0, "value"); }}
                            checked={(subItem?.userAnswer?.[0]?.value === header.code)}
                          />} inputprops={{ 'aria-label': subItem.text }}
                        />
                      </Table.Cell>;
                    } else {
                      return <Table.Cell key={headerIndex} style={{ textAlign: "center" }}><b>◯ </b></Table.Cell>;
                    }
                  }
                })}
              </Table.Row>
            })}
          </Table.Body>
        </Table>;
      } else if (item.isCheckboxes) {
        if (dataEntryMode) {
          groupDisplay = <>{item.subItems.map((subItem, subItemIndex) => {
            return <div key={subItemIndex}>
              <Checkbox size="small" color="primary" style={{ padding: "0px" }}
                checked={subItem?.userAnswer?.[0].value ? subItem.userAnswer[0].value : false}
                onChange={(e) => {console.log('change line 408'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, subItem.path, e.target.checked, 0, "value"); }}
              />
              {subItem.prefix && <>{subItem.prefix}. </>}{subItem.text}
            </div>
          })}
          </>
        } else {
          //{subItem.checked ? <>☑</> : <>☐</>}
          groupDisplay = <>{item.subItems.map((subItem, subItemIndex) => { return <div key={subItemIndex}><b>☐ </b>{subItem.prefix && <>{subItem.prefix}. </>}{subItem.text}</div> })}</>;
        }
      } else {
        groupDisplay = <><div style={{ paddingTop: "8px" }} />{item.subItems.map((subItem, subItemIndex) => {
          return <QuestionnaireItem key={subItemIndex} questionnaireItemsState={questionnaireItemsState} setQuestionnaireItemsState={setQuestionnaireItemsState} item={subItem} itemIndex={subItemIndex} dataEntryMode={dataEntryMode} depth={depth + 1} />;
        })}</>;
      }
    }
  } else {
    itemDisplay = <TypeBasedDataEntryItem item={item} itemIndex={itemIndex} dataEntryMode={dataEntryMode} userAnswer={userAnswer} setQuestionnaireItemsState={setQuestionnaireItemsState} />;
  }

  /*//We have this to support alternative Questionnaire resource structures with a grouping of booleans
  if (item.isCheckboxes) {
    selectorDisplay = <div style={{display: "inline-block"}}><b>☐ </b></div>;
  }*/

  if (item.answerChoices?.length > 0) {
    if (item.answerConstraint) {
      if (dataEntryMode) {
        if (item.isCheckboxes || item.repeats) {/* 
        //NOTE: This was blank and attempt to add this code on 2023-01-03 was not successful.
          selectorDisplay = <div style={{ display: "inline-block" }}>
            <Checkbox size="small" color="primary" style={{ padding: "0px" }}
              checked={(item["userAnswer"]?.length > 0 && item["userAnswer"][0]["value"]) ? item["userAnswer"][0]["value"] : false}
              onChange={(e) => { changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, e.target.checked, 0, "value"); }}
            />
          </div>
      */} else {
          selectorDisplay = <div style={{ display: "inline-block" }}>
            <FormControlLabel
              name="radio-button-control" color="default"
              value="_WRITE-IN_"
              control={<Radio color="primary" style={{ padding: "4px 0px 2px 0px" }}
                onChange={(e) => {console.log('change line 451'); changeQuestionnaireUserAnswer(setQuestionnaireItemsState, item.path, "_WRITE-IN_", 0, "value"); }}
                //checked={item.initialSelected} 
                checked={(item?.userAnswer?.[0]?.value === "_WRITE-IN_")}
              />} inputprops={{ 'aria-label': item.text }}
            />
          </div>;
        }
      } else {
        selectorDisplay = <SelectorView checkBoxes={(item.isCheckboxes || item.repeats) ? true : false} checked={item.initialSelected} dataEntryMode={dataEntryMode} item={item} setQuestionnaireItemsState={setQuestionnaireItemsState} />;
      }

      if (item.answerConstraint === "optionsOrString") {
        itemDisplay = <table className="noStyleTable" style={{ width: "100%" }}><tbody><tr>
          <td>{selectorDisplay}</td>
          <td style={{ width: "100%" }}><div style={{ width: "500px", height: "24px", marginTop: "6px", border: "1px solid #000000", backgroundColor: "#FFFFFF", display: "inline-block" }}>&nbsp;Other response (write in)</div></td>
        </tr></tbody></table>;
      } else if (item.answerConstraint === "optionsOrType") {
        itemDisplay = <table className="noStyleTable" style={{ width: "100%" }}><tbody><tr>
          <td>{selectorDisplay}</td>
          <td style={{ width: "100%" }}>{itemDisplay}</td>
        </tr></tbody></table>;
      } else if (item.answerConstraint === "optionsOnly") {
        itemDisplay = undefined;
      }
    }
    multipleChoicesDisplay = <MultipleChoices item={item} itemIndex={itemIndex} dataEntryMode={dataEntryMode} userAnswer={userAnswer} setQuestionnaireItemsState={setQuestionnaireItemsState} />;
    if (item.answerConstraint === undefined || item.answerConstraint === "optionsOnly") {
      itemDisplay = undefined;
    }
  }

  return <div style={{ marginBottom: "4px" }}>
    <div style={{ fontWeight: "bold" }}>{item.prefix && <>{item.prefix}. </>}{item.text && <>{item.text}</>}</div>

    <div style={{ marginLeft: "24px" }}>
      {groupDisplay && groupDisplay}
      {multipleChoicesDisplay && multipleChoicesDisplay}
      {itemDisplay && itemDisplay}
    </div>

    <br />
    {depth === 0 && <div style={{ marginBottom: "12px" }} />}
  </div>;
}

const QuestionnaireForm = ({ formInputsStateRef, fhirJson, fhirEntryState, userState, dataEntryMode, previousQuestionnaireResponseJson }) => {

  const [questionnaireLoadingState, setQuestionnaireLoadingState] = useState(true);
  const [questionnaireItemsState, setQuestionnaireItemsState] = useState([]);
  const [valueSetAnswersState, setValueSetAnswersState] = useState({ "boolean": { "true": true, "false": false } });

  useImperativeHandle(formInputsStateRef, () => ({
    questionnaireItemsState: questionnaireItemsState,
    valueSetAnswersState: valueSetAnswersState
  }), [questionnaireItemsState, valueSetAnswersState]);

  const retrieveAnswersFromValueSet = (valueSet, answers, valueSetUrl) => {
    let valueSetDictionary = {};
    if (valueSet.compose?.include?.length > 0 && valueSet.compose.include[0].concept) {
      for (let conceptIndex in valueSet.compose.include[0].concept) {
        let concept = valueSet.compose.include[0].concept[conceptIndex];
        let conceptEntry = {};
        conceptEntry["code"] = concept.code;
        if (concept.display) {
          conceptEntry["display"] = concept.display;
        } else {
          conceptEntry["display"] = concept.code;
        }
        valueSetDictionary[concept.code] = { system: valueSet.compose.include[0].system, version: valueSet.compose.include[0].version, code: concept.code, display: concept.display };
        if (concept.designation) {
          conceptEntry["designation"] = concept.designation;
        }
        answers.push(conceptEntry);
      }
    }
    setValueSetAnswersState(prevState => {
      return { ...prevState, [valueSetUrl]: valueSetDictionary };
    });
    return answers;
  }

  const recursiveItemProcess = async (items, path, itemProperties, valueSets, previousResponseItem, containedResources) => {
    let itemEntries = [];
    let answerIndexStart;
    let overallValueSetId;
    for (let itemIndex in items) {
      itemIndex = parseInt(itemIndex);
      let item = items[itemIndex];
      let subPreviousResponseItem;
      let answerChoices;
      let userAnswer = [{ "value": "" }];
      let values = [];
      let answerValueSetUrl;
      let answerValueSetId;
      let hasAnswerOptions;
      let answerOptions;
      let itemPath = [...path];
      itemPath.push(itemIndex);

      let repeats = false;
      if (item.repeats) {
        repeats = true;
        item.isCheckboxes = true; //added 2023-01-03
      }

      if (item.type === "boolean" || item.isCheckboxes) {
        //answerChoices = [{code: "true", display: "Yes"}, {code: "false", display: "No"}];
      } else {
        itemProperties.isCheckboxes = false;
      }

      if (previousResponseItem) {
        if (answerIndexStart === undefined) {
          if (item.linkId === previousResponseItem[0].linkId) { //item.text === previousResponseItem[0].text
            answerIndexStart = itemIndex;
          }
        }
        if (answerIndexStart !== undefined) {
          if (previousResponseItem.length + answerIndexStart > itemIndex) {
            if (item.linkId === previousResponseItem[itemIndex - answerIndexStart].linkId) { //item.text === previousResponseItem[itemIndex - answerIndexStart].text
              let userAnswerItemEntry = previousResponseItem[itemIndex - answerIndexStart];
              for (let answerIndex in userAnswerItemEntry.answer) {
                let userAnswerItem = JSON.parse(JSON.stringify(userAnswerItemEntry.answer[answerIndex]));
                let answerKeys = Object.keys(userAnswerItem);
                if (answerKeys.length > 0) {
                  let responseAnswer = userAnswerItem[answerKeys[0]];
                  if (responseAnswer !== undefined && responseAnswer !== null) {
                    if (answerKeys[0] === "valueQuantity") {
                      userAnswer[0] = { "comparator": "", "value": "", "unit": "" }
                      if (responseAnswer["comparator"]) {
                        userAnswer[0]["comparator"] = responseAnswer["comparator"];
                      }
                      if (responseAnswer["value"] !== undefined && responseAnswer["value"] !== null) {
                        userAnswer[0]["value"] = responseAnswer["value"].toString();
                      }
                      if (responseAnswer["unit"]) {
                        userAnswer[0]["unit"] = responseAnswer["unit"];
                      }
                    } else if (answerKeys[0] === "valueCoding") {
                      if (responseAnswer["code"] !== undefined) {
                        userAnswer[0]["value"] = responseAnswer["code"];
                      } else if (responseAnswer["display"] !== undefined) {
                        userAnswer[0]["value"] = responseAnswer["display"];
                      }
                    } else if (answerKeys[0] === "valueBoolean") {
                      if (typeof responseAnswer === "string") {
                        userAnswer[0]["value"] = true;
                      } else {
                        userAnswer[0]["value"] = responseAnswer;
                      }
                    } else {
                      userAnswer[0]["value"] = responseAnswer.toString();
                    }
                    values.push(userAnswer[0]["value"]);
                  }
                }
              }
              if (previousResponseItem[itemIndex - answerIndexStart].item?.length > 0) {
                subPreviousResponseItem = previousResponseItem[itemIndex - answerIndexStart].item;
              }
            }
          }
        }
      }
      
      if (item.answerValueSet) {
        answerValueSetUrl = item.answerValueSet;
        if (answerValueSetUrl) {
          if (answerValueSetUrl.includes("https://fevir.net/resources/ValueSet/") || answerValueSetUrl.includes("ValueSet/")) {
            answerValueSetId = answerValueSetUrl.replace("https://fevir.net/resources/ValueSet/", "").replace("ValueSet/", "");
          } else {
            answerValueSetId = answerValueSetUrl.replace("#", "");
          }
        }
        if (answerValueSetId === undefined) {
          itemProperties.isTable = false;
        } else if (answerValueSetUrl && answerValueSetUrl.includes("#")) {
            if (overallValueSetId !== undefined && answerValueSetId !== overallValueSetId) {
              itemProperties.isTable = false;
            } else {
              overallValueSetId = answerValueSetId;
            }
            if (valueSets[answerValueSetId] === undefined) {
              let itemValueSetResourceEntry = containedResources[answerValueSetId];
              if (itemValueSetResourceEntry) {
                let itemValueSet = containedResources[answerValueSetId]["json"]
                answerChoices = [];
                answerChoices = retrieveAnswersFromValueSet(itemValueSet, answerChoices, answerValueSetUrl);
                valueSets[answerValueSetId] = { answerChoices: answerChoices };
              }
            } else {
              answerChoices = JSON.parse(JSON.stringify(valueSets[answerValueSetId]["answerChoices"]));
            }
        } else {
          if (overallValueSetId !== undefined && answerValueSetId !== overallValueSetId) {
            itemProperties.isTable = false;
          } else {
            overallValueSetId = answerValueSetId;
          }
          if (valueSets[answerValueSetId] === undefined) {
            let itemValueSetResourceEntry = await getResource(answerValueSetId, "ValueSet", userState.idToken);
            let itemValueSetString = itemValueSetResourceEntry["fhirResource"] || "{}";
            let itemValueSet = JSON.parse(itemValueSetString);
            answerChoices = [];
            answerChoices = retrieveAnswersFromValueSet(itemValueSet, answerChoices, answerValueSetUrl);
            valueSets[answerValueSetId] = { answerChoices: answerChoices };
          } else {
            answerChoices = JSON.parse(JSON.stringify(valueSets[answerValueSetId]["answerChoices"]));
          }
        }
      } else if (item.answerOption?.length > 0) {
        hasAnswerOptions = true;
        answerChoices = [];
        //answerChoices = retrieveAnswersFromValueSet(itemValueSet, answerChoices, answerValueSetUrl);
        for (let answerOptionIndex in item.answerOption) {
          let answerOption = item.answerOption[answerOptionIndex];
          let conceptEntry = {};
          conceptEntry["code"] = answerOption.valueInteger || answerOption.valueDate || answerOption.valueTime || answerOption.valueString;
          conceptEntry["display"] = answerOption.valueInteger || answerOption.valueDate || answerOption.valueTime || answerOption.valueString;
          if (conceptEntry["code"] === undefined) {
            if (answerOption.valueCoding?.length > 0) {
              conceptEntry["code"] = answerOption.valueCoding[0]["code"];
              conceptEntry["display"] = answerOption.valueCoding[0]["display"];
              conceptEntry["system"] = answerOption.valueCoding[0]["system"];
              //TO DO
            } else if (answerOption.valueQuantity) {
              //TO DO
            } else if (answerOption.valueReference) {
              //TO DO
            }
          }
          //We have to do this for other data types
          conceptEntry["initialSelected"] = answerOption.initialSelected;
          if (conceptEntry["initialSelected"]) {
            if (previousResponseItem === undefined) {
              conceptEntry["userAnswer"] = [{ "value": true }];
              userAnswer[0]["value"] = conceptEntry["code"];
            }
          }
          if (values.includes(conceptEntry.code)) {
            conceptEntry["userAnswer"] = [{ "value": true }];
          }
          answerChoices.push(conceptEntry);
        }
      }

      if (!(item.type === "coding" && item.answerValueSet)) {
        itemProperties.isTable = false;
      }

      if (item.answerOption?.length > 0) {
        hasAnswerOptions = true;
        answerOptions = [];
        let oneChecked = false;
        for (let answerOptionIndex in item.answerOption) {
          let answerOption = item.answerOption[answerOptionIndex];
          let answerOptionEntry = { choice: undefined, checked: false };
          if (answerOption.initialSelected) {
            if (repeats || (oneChecked === false)) {    //On loading the form, allows multiple checkboxes to be checked by default if repeats is true, but if repeats is false only allows one option to be checked
              answerOptionEntry.checked = true;
              oneChecked = true;
            }
          }
          answerOptionEntry.choice = answerOption.valueInteger || answerOption.valueDate || answerOption.valueTime || answerOption.valueString || answerOption.valueCoding || answerOption.valueReference;
          answerOptions.push(answerOptionEntry);
        }
      }

      let itemEntry = {
        index: itemIndex, path: itemPath, linkId: item.linkId, prefix: item.prefix, text: item.text,
        repeats: repeats, hasAnswerOptions: hasAnswerOptions, answerOptions: answerOptions,
        type: item.type, isTable: false, isCheckboxes: false, answerConstraint: item.answerConstraint,
        tableHeaders: undefined, answerChoices: answerChoices, answerValueSetUrl: answerValueSetUrl,
        answerValueSetId: answerValueSetId, subItems: undefined, userAnswer: userAnswer
      };

      //userAnswer: userAnswer,
      //subAnswerValueSet: subAnswerValueSetUrl, subAnswerValueSetId: subAnswerValueSetId, tableHeaders: tableHeaders

      if (item.type === "group" && item.item) {
        let subItemProperties = { isTable: true, isCheckboxes: true, tableHeaders: undefined };

        itemEntry["subItems"] = await recursiveItemProcess(item.item, itemPath, subItemProperties, valueSets, subPreviousResponseItem, containedResources);

        itemEntry.isCheckboxes = subItemProperties.isCheckboxes;
        itemEntry.isTable = subItemProperties.isTable;
        if (itemEntry.isTable) {
          if (subItemProperties.tableHeaders.length > 0) {
            itemEntry.tableHeaders = subItemProperties.tableHeaders;
          } else {
            itemEntry.isTable = false;
          }
        }
        if (false) {
          //all subitems coding
          //all subitems have the same not undefined answerValueSetId;
        }
      }


      itemEntries.push(itemEntry);
    }
    if (overallValueSetId === undefined || itemEntries.length === 0) {
      itemProperties.isTable = false;
    } else if (itemProperties.isTable && itemEntries.length !== 0 && itemEntries[0].answerChoices) {
      itemProperties.tableHeaders = [...itemEntries[0].answerChoices]; //{"display": ""},
    }
    return itemEntries;
  }

  let containedResources = getContainedResources(fhirJson);
  
  useEffect(async () => {
    let itemEntries;
    if (fhirJson.item) {
      let items = fhirJson.item;
      let valueSets = {};
      let previousResponseItem;
      if (previousQuestionnaireResponseJson?.item?.length > 0) {
        previousResponseItem = previousQuestionnaireResponseJson.item;
      }
      itemEntries = await recursiveItemProcess(items, [], { isTable: false, isCheckboxes: false }, valueSets, previousResponseItem, containedResources);
      //console.log(itemEntries);
    }
    setQuestionnaireItemsState(itemEntries);
    try {
    } catch (e) { }
    setQuestionnaireLoadingState(false);
  }, []);

  return <div>
    {questionnaireLoadingState &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div style={{ paddingTop: "12px" }} />
    {questionnaireItemsState?.map((item, itemIndex) => {
      return <QuestionnaireItem key={itemIndex} questionnaireItemsState={questionnaireItemsState} setQuestionnaireItemsState={setQuestionnaireItemsState} item={item} itemIndex={itemIndex} dataEntryMode={dataEntryMode} depth={0} />
    })}
    <br /><br />
  </div>
}

export default QuestionnaireForm;