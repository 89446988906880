import { jsonErrorCheck2 } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';
import { deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';

const activityDefinitionTextViewChangesToJson = (fhirJson, activityDefinitionState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = activityDefinitionState.meta;
    fhirJson.language = activityDefinitionState.language;
    fhirJson.text = activityDefinitionState.text;
    fhirJson.contained = activityDefinitionState.contained || "DELETEME";
    fhirJson.extension = activityDefinitionState.extension || "DELETEME";
    fhirJson.modifierExtension = activityDefinitionState.modifierExtension || "DELETEME";
    fhirJson.url = activityDefinitionState.url || "DELETEME";
    fhirJson.identifier = activityDefinitionState.identifier || "DELETEME";
    fhirJson.version = activityDefinitionState.version || "DELETEME";
    fhirJson.versionAlgorithmString = activityDefinitionState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = activityDefinitionState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = activityDefinitionState.name || "DELETEME";
    fhirJson.title = activityDefinitionState.title || "DELETEME";
    fhirJson.status = activityDefinitionState.status || "unknown";
    fhirJson.experimental = activityDefinitionState.experimental || "DELETEME";
    fhirJson.date = activityDefinitionState.date || "DELETEME";
    fhirJson.publisher = activityDefinitionState.publisher || "DELETEME";
    fhirJson.contact = activityDefinitionState.contact || "DELETEME";
    fhirJson.description = activityDefinitionState.description || "DELETEME";
    fhirJson.useContext = activityDefinitionState.useContext || "DELETEME";
    fhirJson.jurisdiction = activityDefinitionState.jurisdiction || "DELETEME";
    fhirJson.purpose = activityDefinitionState.purpose || "DELETEME";
    fhirJson.usage = activityDefinitionState.usage || "DELETEME";
    fhirJson.copyright = activityDefinitionState.copyright || "DELETEME";
    fhirJson.copyrightLabel = activityDefinitionState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = activityDefinitionState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = activityDefinitionState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = activityDefinitionState.effectivePeriod || "DELETEME";
    fhirJson.topic = activityDefinitionState.topic || "DELETEME";
    fhirJson.author = activityDefinitionState.author || "DELETEME";
    fhirJson.editor = activityDefinitionState.editor || "DELETEME";
    fhirJson.reviewer = activityDefinitionState.reviewer || "DELETEME"
    fhirJson.endorser = activityDefinitionState.endorser || "DELETEME";
    fhirJson.relatedArtifact = activityDefinitionState.relatedArtifact || "DELETEME";
    fhirJson.subtitle = activityDefinitionState.subtitle || "DELETEME";
    fhirJson.subjectCodeableConcept = activityDefinitionState.subjectCodeableConcept || "DELETEME";
    fhirJson.subjectReference = activityDefinitionState.subjectReference || "DELETEME";
    fhirJson.subjectCanonical = activityDefinitionState.subjectCanonical || "DELETEME";
    fhirJson.library = activityDefinitionState.library || "DELETEME";
    fhirJson.kind = activityDefinitionState.kind || "DELETEME";
    fhirJson.profile = activityDefinitionState.profile || "DELETEME";
    fhirJson.code = activityDefinitionState.code || "DELETEME";
    fhirJson.intent = activityDefinitionState.intent || "DELETEME";
    fhirJson.priority = activityDefinitionState.priority || "DELETEME";
    fhirJson.doNotPerform = activityDefinitionState.doNotPerform || "DELETEME";
    fhirJson.timingTiming = activityDefinitionState.timingTiming || "DELETEME";
    fhirJson.timingAge = activityDefinitionState.timingAge || "DELETEME";
    fhirJson.timingRange = activityDefinitionState.timingRange || "DELETEME";
    fhirJson.timingDuration = activityDefinitionState.timingDuration || "DELETEME";
    fhirJson.asNeededBoolean = activityDefinitionState.asNeededBoolean ?? "DELETEME";
    fhirJson.asNeededCodeableConcept = activityDefinitionState.asNeededCodeableConcept || "DELETEME";
    fhirJson.location = activityDefinitionState.location || "DELETEME";
    fhirJson.participant = activityDefinitionState.participant || "DELETEME";
    fhirJson.productReference = activityDefinitionState.productReference || "DELETEME";
    fhirJson.productCodeableConcept = activityDefinitionState.productCodeableConcept || "DELETEME";
    fhirJson.quantity = activityDefinitionState.quantity || "DELETEME";
    fhirJson.dosage = activityDefinitionState.dosage || "DELETEME";
    fhirJson.bodySite = activityDefinitionState.bodySite || "DELETEME";
    fhirJson.specimenRequirement = activityDefinitionState.specimenRequirement || "DELETEME";
    fhirJson.observationRequirement = activityDefinitionState.observationRequirement || "DELETEME";
    fhirJson.observationResultRequirement = activityDefinitionState.observationResultRequirement || "DELETEME";
    fhirJson.transform = activityDefinitionState.transform || "DELETEME";
    fhirJson.dynamicValue = activityDefinitionState.dynamicValue || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (activityDefinitionState) => {
    let contentArray = activityDefinitionState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "ActivityDefinition";
    let classifiedFoi = activityDefinitionState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = activityDefinitionState.title || activityDefinitionState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateActivityDefinitionJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let activityDefinitionState = formInputsStateRef.current.activityDefinitionState;

    let fhirJson = jsonErrorCheck2("ActivityDefinition", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = activityDefinitionTextViewChangesToJson(fhirJson, activityDefinitionState);
        fhirJson = jsonErrorCheck2("ActivityDefinition", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(activityDefinitionState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateActivityDefinitionJson };