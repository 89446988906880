import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Modal, Button, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { FormControlLabel, Checkbox } from '@mui/material';
import './App.css';
import { Beforeunload } from 'react-beforeunload';
import { useToasts } from 'react-toast-notifications';
import { changeSimpleState, changeSimpleStateWithCheck } from './ResourceFunctions';

import EditUserProfileTextFields from './EditUserProfileTextFields';
import { getUserData, updateUserAccountWithUserProfile, retrieveUserFevirApiToken } from "./UserAccountFunctions";
import FevirContext from './FevirContext';

const AlertHistoryModal = ({ alertModalHistoryState, setAlertModalHistoryState }) => {
  const globalContext = useContext(FevirContext);

  const modalClose = () => {
    setAlertModalHistoryState(prevState => { return { ...prevState, "open": false }; });
  }

  useEffect(() => {
    window.addEventListener('keyup', (e) => { if (e.key === "Enter") { modalClose(); } }, false);
  }, []);

  let alertHistory = globalContext.alertModalState.alertHistory?.reverse();
  return (
    <Modal
      style={{ width: "60%", margin: "16px" }}
      dimmer={<Modal.Dimmer blurring style={{ backgroundColor: "#00000077" }} />}
      open={alertModalHistoryState.open}
      centered={false}
      content={<div style={{ whiteSpace: "pre-wrap" }}>
        <Button style={{ padding: "6px", position: "absolute", top: "6px", right: "6px" }} className="formButton negative" content="✖"
          onClick={modalClose} />
        <br />
        <div style={{ padding: "0px 16px 0px 16px" }}>
          {alertHistory?.map((entry, index) => {
            return <span key={index}>
              <span><b>{entry.header}</b> {entry.content}</span>
              <br />
            </span>
          })}
          <br />
        </div>
      </div>}
      closeOnEscape
      closeOnDimmerClick
      onClose={modalClose}
    />
  )
}

const EditUserProfilePage = ({ useTitle }) => {

  useTitle("FEvIR Edit User Profile");

  const globalContext = useContext(FevirContext);

  const startingUserProfileState = {
    loading: true,
    startLoad: true,
    name: '',
    notificationemail: '',
    additionalemail: '',
    shortbio: '',
    affiliations: [""],
    termsofuseacceptCheckbox: true,
    datapersonalagreementacceptCheckbox: true
  };

  const [userProfileState, setUserProfileState] = useState(startingUserProfileState);
  const [fevirApiState, setFevirApiState] = useState("");

  const [alertModalHistoryState, setAlertModalHistoryState] = useState({ open: false });

  const history = useHistory();

  const CancelToHomeButton = () => {
    const history = useHistory();
    return (
      // history.goBack();
      <Button className="formButton negative" content="Cancel" onClick={() => { changeSimpleState(setUserProfileState, true, "loading"); history.push(""); }} compact />
    );
  }

  const { addToast } = useToasts();

  useEffect(() => {
    setUserProfileState((prevState) => {
      let prevUserState = { ...globalContext.userState };
      return {
        ...prevState,
        ...prevUserState
      };
    });
  }, [globalContext.userState]);

  useEffect(async () => {
    changeSimpleStateWithCheck(userProfileState, setUserProfileState, true, "loading"); //Added back on January 11, 2022
    try {
      await getUserData(globalContext, 0, undefined);
    } catch (e) { }
    changeSimpleState(setUserProfileState, false, "loading");
  }, []);

  /*
  useEffect( async () => {
    changeSimpleState(setUserProfileState, true, "loading"); //Added back on January 11, 2022
  }, []);
  */

  return (
    <div>
      <AlertHistoryModal alertModalHistoryState={alertModalHistoryState} setAlertModalHistoryState={setAlertModalHistoryState} />
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        {
          userProfileState.loading
          &&
          <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        }
        {/*
        !userProfileState.loading &&
        <>
        */}
        {globalContext.userState.id && userProfileState.firebaseuid ?
          <>
            {/*<Beforeunload onBeforeunload={(event) => { event.preventDefault(); }} />*/}
            <EditUserProfileTextFields firebaseConfig={globalContext.firebaseConfig} userState={globalContext.userState} setUserState={globalContext.setUserState} userProfileState={userProfileState} setUserProfileState={setUserProfileState} />
            <br /><br />
            <FormControlLabel
              style={{ pointerEvents: "none" }}
              control={<Checkbox style={{ pointerEvents: "auto" }} name="termsofuseaccept" inputProps={{ 'aria-label': 'Agree to the Terms of Use' }} color="primary" checked={userProfileState.termsofuseacceptCheckbox}
                onChange={(e) => {
                  if (userProfileState.termsofuseacceptCheckbox) {
                    if (window.confirm("Do you want to be removed from the FEvIR Platform?")) {
                      changeSimpleState(setUserProfileState, false, "termsofuseacceptCheckbox");
                      globalContext.setUserState(prevState => { return { ...prevState, "userremoval": true }; });
                      history.push("/userremoval");
                    } else {
                      changeSimpleState(setUserProfileState, true, "termsofuseacceptCheckbox");
                    }
                  } else {
                    changeSimpleState(setUserProfileState, true, "termsofuseacceptCheckbox");
                  }
                }} />}
              label={<span>I agree to the <a href="/termsofuse" target="_blank" rel="noopener noreferrer" style={{ pointerEvents: "auto" }}>Acceptable Use Policy</a> (Uncheck to be removed from the FEvIR Platform.)</span>}
            />
            <br />
            <FormControlLabel
              style={{ pointerEvents: "none" }}
              control={<Checkbox style={{ pointerEvents: "auto" }} name="datapersonalagreementaccept" inputProps={{ 'aria-label': 'Agree to the Personal Data Agreement Terms' }} color="primary" checked={userProfileState.datapersonalagreementacceptCheckbox}
                onChange={(e) => {
                  if (userProfileState.datapersonalagreementacceptCheckbox) {
                    if (window.confirm("Do you want to be removed from the FEvIR Platform?")) {
                      changeSimpleState(setUserProfileState, false, "datapersonalagreementacceptCheckbox");
                      globalContext.setUserState(prevState => { return { ...prevState, "userremoval": true }; });
                      history.push("/userremoval");
                    } else {
                      changeSimpleState(setUserProfileState, true, "datapersonalagreementacceptCheckbox");
                    }
                  } else {
                    changeSimpleState(setUserProfileState, true, "datapersonalagreementacceptCheckbox");
                  }
                }} />}
              label={<span>I agree to the <a href="/personaldataagreement" target="_blank" rel="noopener noreferrer" style={{ pointerEvents: "auto" }}>Privacy Policy Agreement</a> (Uncheck to be removed from the FEvIR Platform.)</span>}
            />
            <br /><br />
            <Button className="formButton positive" content="Update Profile" compact onClick={() => updateUserAccountWithUserProfile(addToast, globalContext, userProfileState, setUserProfileState, history, "")} />
            <span style={{ marginLeft: "10px" }}></span>
          </>
          :
          <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
        }
        <CancelToHomeButton />
        <br /><br /><br /><br />
        {(globalContext.userState.id && userProfileState.firebaseuid) && <>
          <div style={{ fontSize: "16px" }}>FEvIR API Token {fevirApiState ? <span style={{ fontSize: "12px", marginLeft: "12px", padding: "4px 8px 4px 8px", border: "1px black solid" }}>{fevirApiState}</span> : <Button className="formButton" style={{ color: "#000000" }} content="Show My FEvIR API Token" compact onClick={() => { retrieveUserFevirApiToken(globalContext, setFevirApiState); }} />}</div>
          <br /><br />
        </>}
        {globalContext.alertModalState.alertHistory?.length > 0 && <Button className="formButton" style={{ color: "#000000" }} content="See current session alert history" compact onClick={() => { setAlertModalHistoryState(prevState => { return { ...prevState, "open": true }; }); }} />}
      </Segment>
    </div>
  );
};

export default EditUserProfilePage;