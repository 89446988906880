import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import FevirContext from './FevirContext';
import submitToFevirServer from "./SubmitToFevirServer";

const updateFavorites = async (addToast, globalContext, resourceId) => {
  let foi = parseInt(resourceId);
  let removeFromFavorites = globalContext.favoriteFOIsState.includes(foi);
  //This set State is to get instant webpage feedback of the star displaying while it submits to the server
  globalContext.setFavoriteFOIsState((prevState) => {
    if (removeFromFavorites) {
      prevState.splice(prevState.indexOf(foi), 1);    //removes foi from list
    } else {
      prevState.push(foi);        //otherwise add it
    }
    return [...prevState];
  });

  let body = {
    'functionid': 'updateuserfavorites',
    'foi': foi,
    'remove': removeFromFavorites
  };

  let response = await submitToFevirServer(globalContext, 5000, body, false, false);
  if (response?.success) {
    if (removeFromFavorites) {
      addToast('Removed from your favorites.', { appearance: 'success' });
    } else {
      addToast('Added to your favorites.', { appearance: 'success' });
    }
    if (response.favoritefois && globalContext.favoriteFOIsState.toString() !== response.favoritefois.toString()) {
      globalContext.setFavoriteFOIsState(response.favoritefois);
    }
  } else if (response?.errormessage) {
    addToast(response.errormessage, { appearance: 'error' });
  } else {
    addToast('Problem updating your favorites.', { appearance: 'error' });
  }
}

const FavoriteResourceStar = ({ resourceId, absolutePosition, topOffset, rightOffset, fontSize }) => {
  const globalContext = useContext(FevirContext);
  const { addToast } = useToasts();
  fontSize = fontSize || "30px";
  let style = { fontSize: fontSize, cursor: "pointer" };
  if (absolutePosition) {
    style.position = "absolute";
    style.zIndex = "1000";
    style.top = topOffset || "5px";
    style.right = rightOffset || "3px";
  }
  return <>{globalContext.userState.id &&
    <div style={style} onClick={() => { updateFavorites(addToast, globalContext, resourceId) }}>{resourceId && globalContext.favoriteFOIsState?.includes(parseInt(resourceId)) ? <span className="favoriteStarSelected">★</span> : <>✩</>}</div>
  }</>
}

const FavoriteResourcesSection = ({ }) => {
  const globalContext = useContext(FevirContext);
  const [loadedFavoritesState, setLoadedFavoritesState] = useState([]);

  useEffect(async () => {
    if (!globalContext.userState.loading) {
      let numberOfFavorites = globalContext.favoriteFOIsState.length;
      if (numberOfFavorites > 0 && Object.keys(globalContext.favoritedResourcesDictState).length != numberOfFavorites && loadedFavoritesState.toString() !== globalContext.favoriteFOIsState.toString()) {
        //LOADING
        setLoadedFavoritesState([...globalContext.favoriteFOIsState]);
        //DO SERVER CALL
        globalContext.setGlobalLoadingState(true);
        const body = {
          'functionid': "getResourcestitlesandtypes",
          'resourceids': globalContext.favoriteFOIsState
        };
        let response = await submitToFevirServer(globalContext, 20000, body, false, false);
        if (response.success) {
          globalContext.setFavoritedResourcesDictState(response.resources);
        }
        globalContext.setGlobalLoadingState(false);
      }
    }
  }, [globalContext.userState, globalContext.favoriteFOIsState]);

  //MAKE ALPHABETICAL ORDER?
  const FhirResourcesLinks = () => {
    return <div>{globalContext.favoriteFOIsState.map((foi, index) => {
      let relativeUrl = "/" + foi;
      let title = foi;
      let status;
      let resourceEntry = globalContext.favoritedResourcesDictState[foi];
      if (resourceEntry) {
        relativeUrl = resourceEntry.relativeUrl;
        title = resourceEntry.title;
        status = resourceEntry.status;
      }
      return <div key={index} style={{ display: "flex", paddingBottom: "4px" }}>
        <FavoriteResourceStar resourceId={foi} fontSize={"24px"} />
        &nbsp;
        <Link to={relativeUrl}>
          <span style={{ fontSize: "24px" }}>{title}</span>
        </Link><span>{status !== "active" && <>&nbsp;&nbsp;({status})</>}</span>
      </div>
    })}
    </div>
  };
  return <>
    {(globalContext.globalLoadingState || globalContext.userState.loading)
      &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    {globalContext.favoriteFOIsState.length === 0 ? <h2>You haven't favorited any resources.</h2> : <FhirResourcesLinks />}
  </>
}

const FavoriteResourcesPage = ({ useTitle }) => {
  useTitle("FEvIR Favorites");
  const globalContext = useContext(FevirContext);

  return (
    <div>
      <Segment className={`containerSegment remainderOfPageSegment`} raised>
        {(globalContext.globalLoadingState || globalContext.userState.loading)
          &&
          <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        }
        <>{(!globalContext.globalLoadingState && !globalContext.userState.loading && !globalContext.userState.id) ?
          <h1>Please login to view your favorites</h1>
          :
          <FavoriteResourcesSection />
        }</>
      </Segment>
    </div>
  );
};

export { FavoriteResourcesPage, FavoriteResourcesSection, FavoriteResourceStar };