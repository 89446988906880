import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const listTextViewChangesToJson = (fhirJson, listState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = listState.meta;
    fhirJson.language = listState.language;
    fhirJson.text = listState.text;
    fhirJson.contained = listState.contained || "DELETEME";
    fhirJson.extension = listState.extension || "DELETEME";
    fhirJson.modifierExtension = listState.modifierExtension || "DELETEME";
       fhirJson.identifier = listState.identifier || "DELETEME";
    fhirJson.status = listState.status || "current";
    fhirJson.mode = listState.mode || "snapshot";
    fhirJson.title = listState.title || "DELETEME";
    fhirJson.code = listState.code || "DELETEME";
    fhirJson.subject = listState.subject || "DELETEME";
    fhirJson.encounter = listState.encounter || "DELETEME";
    fhirJson.date = listState.date || "DELETEME";
    fhirJson.source = listState.source || "DELETEME";
    fhirJson.orderedBy = listState.orderedBy || "DELETEME";
    fhirJson.note = listState.note || "DELETEME";
    fhirJson.entry = listState.entry || "DELETEME";
    fhirJson.emptyReason = listState.emptyReason || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (listState) => {
    let contentArray = listState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "List";
    let classifiedFoi = listState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = listState.title || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateListJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let listState = formInputsStateRef.current.listState;

    let fhirJson = jsonErrorCheck2("List", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = listTextViewChangesToJson(fhirJson, listState);
        fhirJson = jsonErrorCheck2("List", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(listState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateListJson };