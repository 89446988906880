import React, { useState, useEffect, useImperativeHandle, useContext } from 'react';
import "firebase/compat/auth";
import FevirContext from './FevirContext';
import { Beforeunload } from 'react-beforeunload';
import { Button, Dimmer, Loader, Table, TextArea, Modal } from 'semantic-ui-react';
import { FormControlLabel, RadioGroup, Radio, TextField } from '@mui/material';
import { navigateToAnotherTerm } from './CodeSystemFunctions';
import { joinGroup, ReorganizeSectionsModal, votePermissionCheck } from './ResourceFunctions';
import { getCodeSystemConceptValuesFromFhirJson } from './CodeSystemEditFunctions';
import { submitCodeSystemComment } from './CodeSystemFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import CodeSystemTermDisplay from './CodeSystemTermDisplay';
import NavigationCodeSystemTermSegment from './NavigationCodeSystemTermSegment';
import NavigationRobatTermSegment from './NavigationRobatTermSegment';

const TermCommentButton = ({ firebase, history, resourceId, fhirEntryState, setFormState, commentEntryState,
  setCommentEntryState }) => {
  const globalContext = useContext(FevirContext);
  let disableButton = false;
  let conceptCode = fhirEntryState.conceptCode;
  if (conceptCode) {
    disableButton = false;
  } else {
    disableButton = true;
  }
  let loggedInContent;
  let actions;
  let expandModalButtonBackgroundColor = "";
  let modalBodyContentStyle = { paddingLeft: "20px" };
  if (commentEntryState.modalExpanded === false) {
    expandModalButtonBackgroundColor = "#DAA520";
    modalBodyContentStyle["display"] = "none";
  }
  if (globalContext.userState.id) {
    loggedInContent = <div style={modalBodyContentStyle}>
      {commentEntryState.commentSummary && <Beforeunload onBeforeunload={(event) => { event.preventDefault(); }} />}
      <br />
      <TextArea style={{ marginTop: "6px", width: "98%" }} rows={5} className="inputField" type='text'
        label={'Comment'} size="small" variant='outlined' value={commentEntryState.commentSummary}
        onChange={(e) => { setCommentEntryState(prevState => { return { ...prevState, "commentSummary": e.target.value } }); }} />
      <b style={{ margin: "0px", padding: "0px" }}></b>
      <p style={{ margin: "0px", padding: "0px", paddingLeft: "20px" }}></p>
      <br />
    </div>;
    actions = [
      {
        key: 'submit', content: 'Submit', positive: true, onClick: async () => {
          let resourceTitle = JSON.parse(fhirEntryState.fhirEntryString).title;
          let response = await submitCodeSystemComment(globalContext, "comment", firebase, resourceId, resourceTitle, fhirEntryState.conceptCode, fhirEntryState.conceptName, fhirEntryState.conceptDefinition, fhirEntryState.conceptSelected, commentEntryState.commentTitle, commentEntryState.commentSummary, commentEntryState.termVote, true, setCommentEntryState, null);
          if (response?.success) {
            setFormState(prevState => {
              return {
                ...prevState,
                hashLoaded: false
              };
            });
            history.push("/");
            history.push(`/resources/CodeSystem/${resourceId}#${conceptCode}`);
          }
        }
      },
      { key: 'done', content: 'Cancel', negative: true }
    ];
  } else {
    loggedInContent = <div style={{ paddingLeft: "20px" }}>
      <h1>Please close window and login</h1>
    </div>;
    actions = [
      { key: 'done', content: 'Cancel', negative: true }
    ];
  }
  if (commentEntryState.modalExpanded === false) {
    actions = undefined;
  }
  return (
    <Modal
      trigger={<Button className="formButton subCommunicateButton" content="Comment"
        style={{ margin: "0px", color: "#000000" }}
        disabled={disableButton || fhirEntryState.previousVersionLoaded}
        onClick={() => {
          if (globalContext.userState.id) {
            setCommentEntryState(prevState => {
              return {
                ...prevState,
                "modalExpanded": true,
                "commentTitle": `Comment on ${fhirEntryState.conceptName} by ${globalContext.userState.name}`,
                "commentSummary": ""
              }
            });
          } else { alert("Please login to use the comment function."); }
        }} />}
      header={<div style={{ padding: "8px" }}><div style={{ paddingLeft: "10px", borderBottom: "1px solid" }}>
        <h2 style={{ paddingBottom: "6px" }}>{commentEntryState.commentTitle}</h2>
        <Button style={{ position: "absolute", padding: "0px 8px 0px 8px", fontWeight: "bold", right: "3px", top: "3px", backgroundColor: expandModalButtonBackgroundColor }}
          className="formButton" content={commentEntryState.modalExpanded ? "▼" : "▶"}
          onClick={() => {
            setCommentEntryState(prevState => { return { ...prevState, "modalExpanded": !prevState.modalExpanded } });
          }} />
      </div></div>}
      centered={false}
      closeOnDimmerClick={false}
      content={loggedInContent}
      actions={actions}
    />
  )
}

const TermVoteButton = ({ firebase, history, resourceId, fhirEntryState, setFhirEntryState, setFormState,
  commentEntryState, setCommentEntryState }) => {
  const globalContext = useContext(FevirContext);
  let disableButton = false;
  let conceptCode = fhirEntryState.conceptCode;
  if (fhirEntryState.conceptOpenForVoting) {
    disableButton = false;
  } else {
    disableButton = true;
  }
  let loggedInContent;
  let actions;
  let expandModalButtonBackgroundColor = "";
  let modalBodyContentStyle = { paddingLeft: "20px" };
  if (commentEntryState.modalExpanded === false) {
    expandModalButtonBackgroundColor = "#DAA520";
    modalBodyContentStyle["display"] = "none";
  }
  if (globalContext.userState.id) {
    if (fhirEntryState.votePermission === true) {
      loggedInContent = <div>
        <div style={{ padding: "8px" }}>
          <div style={{ paddingLeft: "10px", borderBottom: "1px solid" }}>
            {(commentEntryState.termVote || commentEntryState.commentSummary) &&
              <Beforeunload onBeforeunload={(event) => { event.preventDefault(); }} />}
            <h2 style={{ paddingBottom: "6px" }}>
              <span style={{ fontWeight: "normal" }}>Voting on </span>{fhirEntryState.conceptName}<span style={{ fontWeight: "normal" }}> as {globalContext.userState.name}</span>
            </h2>
            <Button style={{ position: "absolute", padding: "0px 8px 0px 8px", fontWeight: "bold", right: "3px", top: "3px", backgroundColor: expandModalButtonBackgroundColor }}
              className="formButton" content={commentEntryState.modalExpanded ? "▼" : "▶"}
              onClick={() => { setCommentEntryState(prevState => { return { ...prevState, "modalExpanded": !prevState.modalExpanded } }); }} />
          </div>
        </div>
        <div style={modalBodyContentStyle}>
          <br />
          <fieldset style={{ width: "100%", display: "inline", border: "0px", verticalAlign: "bottom", padding: "0px" }}>
            <input type="radio" name={"voteYesOrNo"} value="true" checked={commentEntryState.termVote === "Yes"}
              onChange={(e) => {
                setCommentEntryState(prevState => { return { ...prevState, "termVote": "Yes" } });
              }} />
            <label style={{ fontSize: "16px" }}> Vote Yes on {fhirEntryState.conceptName} = {fhirEntryState.conceptDefinition}</label>
            <br /><br />
            <input type="radio" name={"voteYesOrNo"} value="true" checked={commentEntryState.termVote === "No"}
              onChange={(e) => {
                setCommentEntryState(prevState => { return { ...prevState, "termVote": "No" } });
              }} />
            <label style={{ fontSize: "16px" }}> Vote No on Term</label>
          </fieldset>
          <br /><br />
          <span style={{ margin: "0px", padding: "0px" }}>Comment (required if you vote No):</span>
          <p style={{ margin: "0px", padding: "0px", paddingLeft: "20px" }}>
            <TextArea style={{ marginTop: "6px", width: "98%" }}
              rows={5} className="inputField" type='text'
              label={'Comment'} size="small" variant='outlined'
              value={commentEntryState.commentSummary}
              onChange={(e) => { setCommentEntryState(prevState => { return { ...prevState, "commentSummary": e.target.value } }); }} />
          </p>
          <br />
        </div>
      </div>;
    } else if (fhirEntryState.votePermission === false) {
      loggedInContent = <div style={{ paddingLeft: "20px" }}>
        <br />
        <span><b>For voting privileges, please join the {"SEVCO Expert Working Group"}.</b></span>
        <br /><br />
        <span>Would you like to join the group in order to vote?</span>
        <br />
        <br />
      </div>;
    } else {
      loggedInContent = <div style={{ paddingLeft: "20px" }}>
        <br />
        <span>Loading...</span>
        <br />
        <br />
      </div>;
    }
    let disabledSubmit = false;
    if (commentEntryState.termVote === "" || (commentEntryState.termVote === "No" && commentEntryState.commentSummary === "")) {
      disabledSubmit = true;
    }
    if (fhirEntryState.votePermission === true) {
      actions = [
        {
          key: 'submit', content: 'Submit', positive: true, disabled: disabledSubmit, onClick: async () => {
            let resourceTitle = JSON.parse(fhirEntryState.fhirEntryString).title;
            let response = await submitCodeSystemComment(globalContext, "vote", firebase, resourceId, resourceTitle, fhirEntryState.conceptCode, fhirEntryState.conceptName, fhirEntryState.conceptDefinition, fhirEntryState.conceptSelected, commentEntryState.commentTitle, commentEntryState.commentSummary, commentEntryState.termVote, true, setCommentEntryState, null);
            if (response?.success) {
              setFormState(prevState => {
                return {
                  ...prevState,
                  hashLoaded: false
                };
              });
              history.push("/");
              history.push(`/resources/CodeSystem/${resourceId}#${conceptCode}`);
            }
          }
        },
        { key: 'done', content: 'Cancel', negative: true }
      ];
    } else {
      actions = [
        {
          key: 'join', content: 'Join Group', positive: true, onClick: async () => {
            let response = await joinGroup(resourceId, globalContext);
            if (response?.success) {
              setFormState(prevState => {
                return {
                  ...prevState,
                  hashLoaded: false
                };
              });
              history.push("/");
              history.push(`/resources/CodeSystem/${resourceId}#${conceptCode}`);
            }
          }
        },
        { key: 'done', content: 'Cancel', negative: true }
      ];
    }
  } else {
    loggedInContent = <div style={{ paddingLeft: "20px" }}>
      <h1>Please close window and login before you vote.</h1>
    </div>;
    actions = [
      { key: 'done', content: 'Cancel', negative: true }
    ];
  }
  if (commentEntryState.modalExpanded === false) {
    actions = undefined;
  }
  return (
    <Modal
      trigger={
        <Button className="formButton subCommunicateButton" content="Vote" style={{ margin: "0px", color: "#000000" }} disabled={disableButton || fhirEntryState.previousVersionLoaded}
          onClick={async () => {
            if (globalContext.userState.id) {
              if (fhirEntryState.votePermission === undefined) {
                let response = await votePermissionCheck(firebase, globalContext, resourceId, fhirEntryState, setFhirEntryState);
              }
              setCommentEntryState(prevState => { return { ...prevState, "modalExpanded": true, "commentTitle": `Voting on ${fhirEntryState.conceptName} as ${globalContext.userState.name}`, "commentSummary": "", "termVote": "" } });
            } else {
              alert("Please login to vote.");
            }
          }
          } />
      }
      centered={false}
      closeOnDimmerClick={false}
      content={loggedInContent}
      actions={actions}
    />
  )
}

const ChildConceptsTable = ({ resourceId, fhirEntryState, setFhirEntryState, history }) => {

  let header = "Child concepts:";
  let addButtonText = "+ Add Child Concept";
  let concept = fhirEntryState.conceptSelected;
  let conceptPath = fhirEntryState.conceptPath;
  let json;
  if (fhirEntryState.fhirEntryString) {
    json = JSON.parse(fhirEntryState.fhirEntryString);
  }

  if (conceptPath === undefined || conceptPath.length === 0) {
    concept = json;
    header = "Top-level concepts:"
    addButtonText = "+ Add Top-Level Concept";
  }
  return <>
    <b>{header}</b>
    {(concept?.concept?.length > 0 || fhirEntryState.newChildConcepts?.length > 0) &&
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Code</Table.HeaderCell>
              <Table.HeaderCell>Display</Table.HeaderCell>
              <Table.HeaderCell>Definition</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {concept?.concept?.map((subConcept, subConceptIndex) => {
              let subConceptPath = [];
              let subConceptPathString = fhirEntryState.conceptPathString + ": " + subConcept.display;
              if (conceptPath) {
                subConceptPath = [...conceptPath, subConcept.display];
              } else {
                subConceptPath = [subConcept.display];
              }
              let subConceptPathIndexes = [];
              if (fhirEntryState.conceptPathIndexes) {
                subConceptPathIndexes = [...fhirEntryState.conceptPathIndexes, subConceptIndex];
              } else {
                subConceptPathIndexes = [subConceptIndex];
              }
              return <Table.Row key={subConceptIndex}>
                <Table.Cell>{subConcept.code}</Table.Cell>
                <Table.Cell>
                  <a onClick={() => {
                    navigateToAnotherTerm("term", history, fhirEntryState, setFhirEntryState, 'CodeSystem', resourceId, json,
                      subConcept, subConcept.display, subConceptPath, subConceptPathIndexes, subConceptPathString);
                  }}>
                    {subConcept.display}
                  </a>
                </Table.Cell>
                <Table.Cell>{subConcept.definition}</Table.Cell>
              </Table.Row>
            })}
            {fhirEntryState.newChildConcepts?.map((newConcept, newConceptIndex) => {
              return <Table.Row key={newConceptIndex}>
                <Table.Cell>
                  <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Code'}
                    size="small" variant='outlined'
                    value={fhirEntryState.newChildConcepts[newConceptIndex]["code"] || ''}
                    onChange={(e) => {
                      setFhirEntryState(prevState => {
                        let newNewChildConcepts = prevState.newChildConcepts;
                        newNewChildConcepts[newConceptIndex]["code"] = e.target.value;
                        return { ...prevState, "newChildConcepts": newNewChildConcepts };
                      });
                    }} />
                </Table.Cell>
                <Table.Cell>
                  <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Display'}
                    size="small" variant='outlined' value={fhirEntryState.newChildConcepts[newConceptIndex]["display"] || ''}
                    onChange={(e) => {
                      setFhirEntryState(prevState => {
                        let newNewChildConcepts = prevState.newChildConcepts;
                        newNewChildConcepts[newConceptIndex]["display"] = e.target.value;
                        return { ...prevState, "newChildConcepts": newNewChildConcepts };
                      });
                    }} />
                </Table.Cell>
                <Table.Cell>
                  <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Definition'}
                    size="small" variant='outlined' value={fhirEntryState.newChildConcepts[newConceptIndex]["definition"] || ''}
                    onChange={(e) => {
                      setFhirEntryState(prevState => {
                        let newNewChildConcepts = prevState.newChildConcepts;
                        newNewChildConcepts[newConceptIndex]["definition"] = e.target.value;
                        return { ...prevState, "newChildConcepts": newNewChildConcepts };
                      });
                    }} />
                </Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
      </div>
    }
    <Button className="formButton" style={{ color: "#000000" }} content={addButtonText}
      onClick={() => {
        setFhirEntryState(prevState => {
          let newNewChildConcepts = prevState.newChildConcepts;
          newNewChildConcepts.push({ "code": "", "display": "", "definition": "" });
          return { ...prevState, "newChildConcepts": newNewChildConcepts };
        });
      }} />
  </>
}

//no longer used with creation of CodeSystemConceptDesignationEntry
const getCodeSystemDesignationJsonFormat = (alternativeTermsState) => {
  let newDesignation = alternativeTermsState.map(term => {
    return {
      "use": { system: "http://snomed.info/sct", code: "900000000000013009", display: "Synonym (core metadata concept)" },
      "value": term
    }
  });
  return newDesignation;
}

const getCodeSystemProperties = (codeSystemTermState) => {
  let startingConceptProperty = {};
  if (Array.isArray(codeSystemTermState.property) && codeSystemTermState.property.length > 0) {
    for (const property of codeSystemTermState.property) {
      startingConceptProperty[property.code] = {
        valueCode: property.valueCode || null,
        valueCoding: property.valueCoding || null,
        valueString: property.valueString || null,
        valueInteger: property.valueInteger || null,
        valueBoolean: property.valueBoolean ?? null,
        valueDateTime: property.valueDateTime || null,
        valueDecimal: property.valueDecimal || null
      }
    }
  }
  return startingConceptProperty;
}

//no longer used with creation of CodeSystemConceptDesignationEntry
const getCodeSystemAlternativeTerms = (designation) => {
  let startingAlternativeTerms = [];
  let alternativeTermsOptions = [];
  if (Array.isArray(designation) && designation.length > 0) {
    for (const designationEntry of designation) {
      if (designationEntry.use?.code === "900000000000013009" || designationEntry.use?.display === "Synonym (core metadata concept)" || designationEntry.use?.display === "Alternative term") {
        startingAlternativeTerms.push(designationEntry.value);
        alternativeTermsOptions.push({ key: designationEntry.value, text: designationEntry.value, value: designationEntry.value });
      }
    }
  }
  return [startingAlternativeTerms, alternativeTermsOptions]
}

const CodeSystemConceptDataEntry = ({ resourceId, fhirEntryState, setFhirEntryState, history,
  codeSystemTermState, setCodeSystemTermState, previousCode }) => {
  const fhirJson = JSON.parse(fhirEntryState.fhirEntryString);

  const [conceptPropertyState, setConceptPropertyState] = useState(getCodeSystemProperties(codeSystemTermState));
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (ready && previousCode === fhirEntryState.conceptCode) {
      let newConceptProperty = [];
      for (const code in conceptPropertyState) {
        let instance = { "code": code };
        if (conceptPropertyState[code].valueString) {
          instance.valueString = conceptPropertyState[code].valueString;
        } else if (conceptPropertyState[code].valueCode) {
          instance.valueCode = conceptPropertyState[code].valueCode;
        } else if (conceptPropertyState[code].valueCoding) {
          instance.valueCoding = conceptPropertyState[code].valueCoding;
        } else if (conceptPropertyState[code].valueInteger) {
          instance.valueInteger = conceptPropertyState[code].valueInteger;
        } else if (conceptPropertyState[code].valueDecimal) {
          instance.valueDecimal = conceptPropertyState[code].valueDecimal;
        } else if (conceptPropertyState[code].valueDateTime) {
          instance.valueDateTime = conceptPropertyState[code].valueDateTime;
        } else if (typeof conceptPropertyState[code].valueBoolean === "boolean") {
          instance.valueBoolean = conceptPropertyState[code].valueBoolean;
        } else {
          instance.valueString = "";
        }
        newConceptProperty.push(instance);
      }

      if (JSON.stringify(codeSystemTermState.property) !== JSON.stringify(newConceptProperty)) {
        setCodeSystemTermState(prevState => { return { ...prevState, "property": newConceptProperty } });
      }
    } else if (!ready) {
      setReady(true);
    }
  }, [conceptPropertyState]);

  useEffect(() => {
    if (ready && previousCode !== fhirEntryState.conceptCode) {
      setConceptPropertyState(getCodeSystemProperties(codeSystemTermState));
    }
  }, [codeSystemTermState]); //fhirEntryState.conceptCode

  return <>
    <DataEntry datatype='string' elementName='code' fieldLabel='Code'
      startingValue={codeSystemTermState.code} setResourceState={setCodeSystemTermState} />
    <DataEntry datatype='string' elementName='display' fieldLabel='Preferred term (Display)'
      startingValue={codeSystemTermState.display} setResourceState={setCodeSystemTermState} />
    <DataEntry datatype='string' elementName='definition' fieldLabel='Definition'
      startingValue={codeSystemTermState.definition} setResourceState={setCodeSystemTermState} />
    <DataEntry asArray={true} datatype='CodeSystemConceptDesignation'
      elementName='designation' fieldLabel='Alternative Term' deletable={true}
      startingValue={codeSystemTermState.designation} setResourceState={setCodeSystemTermState} />
    <br /><br />
    {fhirJson.property?.length > 0 &&
      <Table style={{ margin: "0px" }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "30%" }}>Type of Property</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "70%" }}>Property Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {fhirJson.property.map((systemProperty, systemPropertyIndex) => {
            return <Table.Row key={systemPropertyIndex}>
              <Table.Cell>{systemProperty.description}</Table.Cell>
              <Table.Cell>
                <TextField style={{ width: "100%" }} multiline className="inputField" type='text'
                  label={'Value'} size="small" variant='outlined'
                  value={conceptPropertyState[systemProperty.code]?.valueString || ""}
                  onChange={(e) => {
                    setConceptPropertyState(prevState => { return { ...prevState, [systemProperty.code]: { valueString: e.target.value } } })
                  }} />
              </Table.Cell>
            </Table.Row>
          })}
        </Table.Body>
      </Table>
    }
    <br />
    <ChildConceptsTable resourceId={resourceId} fhirEntryState={fhirEntryState}
      setFhirEntryState={setFhirEntryState} history={history} />
    <br /><br />
    <div style={{ width: "100%" }}>
      <Button style={{ color: "#000000", float: "right" }} className="formButton"
        content="Delete Concept"
        onClick={() => {
          if (window.confirm("Are you sure you want to permanately remove the term \"" + codeSystemTermState.display + "\" and all related concepts including child concepts (if any)?")) {
            setCodeSystemTermState(prevState => { return { ...prevState, "conceptDelete": true }; });
            //TODO - see if this line can be deleted
            setFhirEntryState(prevState => { return { ...prevState, "conceptDelete": true, termChanged: true }; });
          }
        }} />
    </div>
    <br /><br />
  </>
}

const CodeSystemTermEdit = ({ formInputsStateRef, fhirEntryState, resourceId, setFhirEntryState, history }) => {
  let startingResourceState;
  if (!startingResourceState && fhirEntryState?.fhirEntryString) {
    startingResourceState = { "concept": JSON.parse(fhirEntryState.fhirEntryString)["concept"] };
  }

  const [resourceState, setResourceState] = useState(startingResourceState);

  const [ready, setReady] = useState(false);
  const [previousCode, setPreviousCode] = useState(fhirEntryState.conceptCode);
  const [codeSystemTermState, setCodeSystemTermState] = useState(getCodeSystemConceptValuesFromFhirJson(fhirEntryState, { loaded: false, termChanged: false, editChanged: false }));
  const [reorganizeConceptsModalState, setReorganizeConceptsModalState] = useState({});

  useImperativeHandle(formInputsStateRef, () => ({
    codeSystemTermState,
  }), [codeSystemTermState]);

  useEffect(() => {
    if (!resourceState && fhirEntryState?.fhirEntryString) {
      setResourceState({ "concept": JSON.parse(fhirEntryState.fhirEntryString)["concept"] });
    }
  }, [fhirEntryState.fhirEntryString]);

  useEffect(() => {
    if (!ready) {
      setReady(true);
    } else if (resourceState.concept) {
      setFhirEntryState(prevState => { return { ...prevState, conceptsRearranged: resourceState.concept } });
    }
  }, [resourceState]);

  useEffect(() => {
    if (ready && !fhirEntryState.termChanged && previousCode === fhirEntryState.conceptCode) {
      setFhirEntryState(prevState => { return { ...prevState, "termChanged": true } });
      setPreviousCode(fhirEntryState.conceptCode);
    } else if (!ready) {
      setReady(true);
    } else if (previousCode !== fhirEntryState.conceptCode) {
      setPreviousCode(fhirEntryState.conceptCode);
    }
  }, [codeSystemTermState]);

  useEffect(() => {
    if (ready && previousCode !== fhirEntryState.conceptCode) {
      setCodeSystemTermState(getCodeSystemConceptValuesFromFhirJson(fhirEntryState, { loaded: false, termChanged: false, editChanged: false }));
    }
  }, [fhirEntryState.conceptCode])

  let conceptPath = fhirEntryState.conceptPath;

  return <div>
    <div style={{ marginTop: "12px" }}>
      {conceptPath?.length > 0 ?
        <>
          {fhirEntryState.conceptDelete ?
            <><h1 style={{ color: "#FF0000" }}>
              Concept "{fhirEntryState.conceptName}" deletion pending, please click "Update" to complete deletion.
            </h1></>
            :
            <>
              <h2 style={{ margin: "0px" }}>{fhirEntryState.conceptName}</h2>
              <CodeSystemConceptDataEntry fhirEntryState={fhirEntryState}
                resourceId={resourceId} setFhirEntryState={setFhirEntryState} history={history}
                codeSystemTermState={codeSystemTermState}
                setCodeSystemTermState={setCodeSystemTermState} previousCode={previousCode} />
              <br /><br />
            </>
          }
        </>
        :
        <>
          <span>Please select a term</span>
          <br />
          <br />
          <ChildConceptsTable resourceId={resourceId} fhirEntryState={fhirEntryState}
            setFhirEntryState={setFhirEntryState} history={history} />
          <br />
        </>
      }
    </div>
  </div>
}

const CodeSystemTermDetailTabRouting = ({ formInputsStateRef, resourceId, fhirEntryState, setFhirEntryState,
  history }) => {
  if (fhirEntryState.editMode) {
    return <CodeSystemTermEdit fhirEntryState={fhirEntryState} formInputsStateRef={formInputsStateRef}
      resourceId={resourceId} setFhirEntryState={setFhirEntryState} history={history}
    />
  } else {
    return <CodeSystemTermDisplay resourceId={resourceId}
      fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} history={history} />
  }
}

const updateCodeSystemTerm = async (fhirEntryState, submitUpdatedFhirResource,
  resourceType, resourceId, formInputsStateRef, setFhirEntryState, globalContext, history, changeFormState
) => {
  if (fhirEntryState.editMode) {
    await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState,
      setFhirEntryState, globalContext, history, changeFormState, false, undefined);
  }
}

const TermDetailLeftSideDisplay = ({ firebase, robatTermLoad, fhirEntryState, setFhirEntryState, formState,
  resourceId, resourceType, formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource, changeFormState,
  addToast, globalContext, history, setPageEditModeState, setFormState, changeResourceViewTab
}) => {

  const [commentEntryState, setCommentEntryState] = useState({
    "modalExpanded": true, "commentTitle": "", "commentSummary": "", "termVote": ""
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  return <div style={{ float: "left", minWidth: "500px", marginRight: "18px" }}><div style={{ position: "fixed", width: "500px" }}>
    {loadingSubmit &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>}
    <h3 style={{ fontSize: "1.1rem", marginTop: "0px", textAlign: "center" }}>
      {fhirEntryState.title ?
        <>{fhirEntryState.title}</>
        :
        <>{formState.tempResourceId === resourceId ?
          <>{formState.tempResourceTitle}</>
          :
          <><br /></>}</>}
    </h3>
    {(robatTermLoad || (resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource)) ?
      <NavigationRobatTermSegment resourceId={resourceId} fhirEntryState={fhirEntryState}
        setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef}
        changeFormState={changeFormState} changeFhirEntryState={changeFhirEntryState} addToast={addToast} />
      :
      <NavigationCodeSystemTermSegment resourceId={resourceId} fhirEntryState={fhirEntryState}
        setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} />
    }
    <div style={{ marginTop: "14px" }} />
    {resourceType === "CodeSystem" ?
      <div>
        <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
          <Button className="formButton communicateButton"
            style={{
              color: "#000000", width: "100%", paddingTop: "6px", paddingBottom: "6px", margin: "0px",
              marginRight: "0px"
            }}
            content="Communicate" disabled />
        </div>
        <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
          <Button className="formButton subCommunicateButton" style={{ margin: "0px", color: "#000000" }}
            content="Share" disabled />
          <TermCommentButton firebase={firebase} history={history} resourceId={resourceId}
            fhirEntryState={fhirEntryState} setFormState={setFormState} commentEntryState={commentEntryState}
            setCommentEntryState={setCommentEntryState} />
          <TermVoteButton firebase={firebase} history={history} resourceId={resourceId}
            fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState}
            setFormState={setFormState} commentEntryState={commentEntryState}
            setCommentEntryState={setCommentEntryState} />
          <Button className="formButton subCommunicateButton"
            style={{ margin: "0px", color: "#000000" }} content="Follow" disabled />
        </div>
        <div style={{ marginTop: "10px" }} />
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "100%", float: "left", textAlign: "left" }}>
            <Button className="formButton navigationButton"
              style={{ color: "#000000", width: "100%" }} content={"Create Coding JSON/XML"} disabled />
          </div>
        </div>
        <div style={{ marginTop: "10px" }} />
        <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
          <div style={{ width: "50%", float: "left", textAlign: "left" }}>
            <Button className="formButton navigationButton"
              style={{ color: "#000000", width: "97%", margin: "0px" }}
              content={fhirEntryState.editMode ? "Update" : "Edit CodeSystem Term"}
              onClick={fhirEntryState.editMode ? (async () => {
                setLoadingSubmit(true);
                await updateCodeSystemTerm(fhirEntryState, submitUpdatedFhirResource, resourceType, resourceId,
                  formInputsStateRef, setFhirEntryState, globalContext, history, changeFormState
                );
                setLoadingSubmit(false);
              }) : (() => {
                changeFhirEntryState(true, "editMode");
                setPageEditModeState(resourceId);
              })}
              disabled={(!fhirEntryState.previousVersionLoaded && globalContext.userState.name && fhirEntryState.readpermission && fhirEntryState.editpermission && resourceType !== "Recommendation") ? false : true} />
          </div>
          <div style={{ width: "50%", float: "right", textAlign: "right" }}>
            <Button className="formButton navigationButton"
              style={{ color: "#000000", width: "97%", margin: "0px" }}
              content={"Clone CodeSystem Term"} disabled />
          </div>
        </div>
      </div>
      :
      <>{(resourceType === "ArtifactAssessment" && fhirEntryState.riskOfBiasAssessmentToolResource) &&
        <>{fhirEntryState.editMode ?
          <>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ float: "left" }}>
                <RadioGroup row aria-label="status" name="status"
                  value={fhirEntryState.status}
                  onChange={(e) => { changeFhirEntryState(e.target.value, "status"); }} >
                  <FormControlLabel
                    value="active"
                    control={<Radio color="primary" />}
                    name="radio-button-control"
                    color="default"
                    inputprops={{ 'aria-label': 'Publish' }}
                    labelPlacement="top"
                    style={{ marginLeft: '0px' }}
                    label={<p style={{ textAlign: 'center' }}>Publish<br />(anyone can view)</p>}
                  />
                  <FormControlLabel
                    value="draft"
                    control={<Radio color="primary" />}
                    color="default"
                    name="radio-button-control"
                    inputprops={{ 'aria-label': 'Draft' }}
                    labelPlacement="top"
                    label={<p style={{ textAlign: 'center' }}>Draft<br />(only editors can view)</p>}
                  />
                </RadioGroup>
              </div>
              <div style={{ marginLeft: "28px" }} />
              <div style={{ float: "right" }}>
                <div style={{ marginTop: "20px" }} />
                <Button className="formButton positive" content="Update" compact
                  onClick={async () => {
                    globalContext.setGlobalLoadingState(true);
                    let updateResult = await submitUpdatedFhirResource(null, resourceType, resourceId, formInputsStateRef, fhirEntryState, setFhirEntryState, globalContext, history, changeFormState, true, addToast);
                    if (updateResult === false) {
                      alert("You made no changes to this term");
                    } else {
                      changeFhirEntryState(false, "editMode");
                      setPageEditModeState("");
                      changeResourceViewTab(0, "Text View", true);
                    }
                    globalContext.setGlobalLoadingState(false);
                  }}
                />
              </div>
            </div>
            <br />
            <b>(Auto-saves whenever you change terms or tabs)</b>
          </>
          :
          <></>
        }</>
      }</>
    }
  </div>
  </div>
}

export { CodeSystemTermDetailTabRouting, TermDetailLeftSideDisplay };