import { DisplayFromFHIR, getStringFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const BundleDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.identifier && <>
          {Array.isArray(fhirJson.identifier) ?
            <>{fhirJson.identifier?.map((identifier, identifierIndex) => {
              return <div key={identifierIndex}><div><b>Identifier: </b>{getStringFromFHIR.Identifier(identifier)}</div></div>
            })}</>
            :
            <p><b>Identifier: </b>{getStringFromFHIR.Identifier(fhirJson.identifier)}</p>}
        </>}
        {fhirJson.type && <p><b>Type: </b>{fhirJson.type}</p>}
        {fhirJson.timestamp && <p><b>Timestamp: </b>{fhirJson.timestamp}</p>}
        {!isNaN(fhirJson.total) && <p><b>Number of entries: </b>{fhirJson.total}</p>}
        {fhirJson.link?.map((link, linkIndex) => {
          return <div key={linkIndex}><div><b>{link.relation} Link: </b><DisplayFromFHIR uri={link.url} /></div></div>
        })}
      </div>
      <h3 id="bundle-entries">Bundle Entries</h3>
      <div style={{ marginLeft: "24px" }}>
        {Array.isArray(fhirJson.entry) && fhirJson.entry.map((entry, entryIndex) => {
          return <div key={entryIndex}>
            <p><b>Entry {entryIndex + 1}: </b></p>
            {entry && <div style={{ marginLeft: "24px" }}>
              {entry.fullUrl && <div><b>URL: </b><DisplayFromFHIR uri={entry.fullUrl} />
                <br /><br /></div>}
              {entry.resource && <div>
                {entry.resource.resourceType && <p><b>Resource Type: </b>{entry.resource.resourceType}</p>}
                {entry.resource.title && <p><b>Resource Title: </b>{entry.resource.title}</p>}
                {entry.resource.name && <p><b>Resource Name: </b>{entry.resource.name}</p>}
                {entry.resource.id && <p><b>Resource ID: </b>{entry.resource.id}</p>}
                {Array.isArray(entry.resource.identifier) &&
                  entry.resource.identifier.map((identifier, identifierIndex) => {
                    return <div key={identifierIndex}><div><b>Identifier: </b>{getStringFromFHIR.Identifier(identifier)}</div></div>
                  })}
              </div>}
            </div>}
          </div>
        })}
        {(!fhirJson.entry || fhirJson.entry.length === 0) &&
          <p>No entries.</p>}
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default BundleDisplay;