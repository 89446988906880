import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const EmptyBoxCharacter = () => {
    return <span className="box emptyBox">☐&nbsp;</span>;
}

const YesNoRadioCheckBoxSwitch = ({ thin, extrathin, medium, fontSize, togglable, boxes, showTrueFalse, alternativeValues, alternativeLabels, elementName, labelElementName, changeElementName, flatElementValue, higherParentElement, parentElement, startingValue, setter, setChangeAvailableToSaveState }) => {
    if (togglable === undefined) {
        togglable = true;
    }

    let Yes = "Yes";
    let No = "No";
    let yes = "yes";
    let no = "no";
    if (showTrueFalse) {
        Yes = "True";
        No = "False";
        yes = true;
        no = false;
        //This is untested, maybe it needs to be strings for the booleans instead?
    } else if (alternativeValues) {
        if (alternativeLabels) {
            Yes = alternativeLabels[0];
            No = alternativeLabels[1];
        } else {
            Yes = alternativeValues[0];
            No = alternativeValues[1];
        }
        yes = alternativeValues[0];
        no = alternativeValues[1];
        //This is untested
    }

    let classes = "yesNoRadioCheckBoxSwitch";
    if (thin) {
        classes = classes + " thin";
    } else if (extrathin) {
        classes = classes + " extrathin";
    } else if (medium) {
        classes = classes + " medium";
    }

    const handleClick = (value, label) => {
        if (setter && elementName) {
            let newValue = value;
            if (newValue === "true") {
                newValue = true;
            } else if (newValue === "false") {
                newValue = false;
            }
            if (parentElement) {
                if (higherParentElement) {
                    setter(prevState => {
                        if (togglable && prevState[higherParentElement] && prevState[higherParentElement][parentElement] && prevState[higherParentElement][parentElement][elementName] === value) {
                            newValue = "";
                        }
                        
                        let parentChanged;
                        if (prevState[higherParentElement][parentElement]) {
                            parentChanged = {
                                ...prevState[higherParentElement][parentElement],
                                [elementName]: newValue
                            }
                        } else {
                            parentChanged = {
                                [elementName]: newValue
                            }
                        }
                        let higherParentElementChanged;
                        if (prevState[higherParentElement]) {
                            higherParentElementChanged = {
                                ...prevState[higherParentElement],
                                [parentElement]: parentChanged
                            }
                        } else {
                            higherParentElementChanged = {
                                [parentElement]: parentChanged
                            }
                        }
                        if (labelElementName) {
                            higherParentElementChanged[parentElement][labelElementName] = label;
                        }
                        if (changeElementName) {
                            higherParentElementChanged[parentElement][changeElementName] = true;
                        }
                        return {
                            ...prevState,
                            [higherParentElement]: higherParentElementChanged
                        };
                    });
                } else {
                    setter(prevState => {
                        if (togglable && prevState[parentElement] && prevState[parentElement][elementName] === value) {
                            newValue = "";
                        }
                        let parentChanged = {
                            ...prevState[parentElement],
                            [elementName]: newValue
                        };
                        if (labelElementName) {
                            parentChanged[labelElementName] = label;
                        }
                        if (changeElementName) {
                            parentChanged[changeElementName] = true;
                        }
                        return {
                            ...prevState,
                            [parentElement]: parentChanged
                        };
                    });
                }
            } else {
                setter(prevState => {
                    if (togglable && prevState[elementName] === value) {
                        newValue = "";
                    }
                    if (flatElementValue) {
                        return {
                            ...prevState,
                            [elementName]: newValue
                        };
                    } else {
                        let stateChanged = {
                            ...prevState,
                            [elementName]: newValue
                        };
                        if (labelElementName) {
                            stateChanged[labelElementName] = label;
                        }
                        if (changeElementName) {
                            stateChanged[changeElementName] = true;
                        }
                        return {
                            ...prevState,
                            [elementName]: stateChanged
                        };
                    }
                });
            }
        } else {
            //
        }
        if (setChangeAvailableToSaveState) {
            setChangeAvailableToSaveState(true);
        }
    }

    let style = {};
    if (fontSize) {
        style.fontSize = fontSize
    }
    if (typeof startingValue === "boolean" || typeof no === "boolean") {
        if (typeof startingValue === "boolean") {
            startingValue = startingValue === true ? "true" : "false";
        }
        yes = "true";
        no = "false";
    }
    return <ToggleButtonGroup
        className={classes}
        exclusive
        value={startingValue || ""}
        aria-label={elementName}
        name={elementName}
    //onClick={(e) => { handleClick(e.target.value) }}
    >
        <ToggleButton className="yesButton" style={style} value={yes} aria-label={Yes} color="success" onClick={() => { handleClick(yes, Yes) }}>
            {boxes && <span>
                {startingValue === yes ? <span className="box">☑&nbsp;</span> : <EmptyBoxCharacter />}
            </span>}
            {Yes}
        </ToggleButton>
        <ToggleButton className="noButton" style={style} value={no} aria-label={No} color="error" onClick={() => { handleClick(no, No) }}>
            {boxes && <span>
                {startingValue === no ? <span className="box">☒&nbsp;</span> : <EmptyBoxCharacter />}
            </span>}
            {No}&nbsp;
        </ToggleButton>
    </ToggleButtonGroup>
}

export default YesNoRadioCheckBoxSwitch;