import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { TextField } from '@mui/material';
//import axios from 'axios';
//import server_config from './ServerConfiguration';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

//const go_server_address = server_config["go_server_address"];

const MedlineSearchImportPage = ({ useTitle }) => {

  useTitle("FEvIR MEDLINE-to-FEvIR Converter");

  const [medlineEntryState, setMedlineEntryState] = useState({
    "medlinequery": "", "projectfoi": "", "status": "active", "loadingSubmission": false
  });
  const globalContext = useContext(FevirContext);

  const changeMedlineEntryState = (newValue, field) => {
    setMedlineEntryState(prevState => {
      return {
        ...prevState,
        //medlineEntry: newValue,
        [field]: newValue
      };
    });
  };

  const history = useHistory();
/*
  const submitPMID = async (pmid, projectfoi, multiple, redirect) => {
    pmid = pmid ?? medlineEntryState.pmid;
    projectfoi = projectfoi ?? medlineEntryState.projectfoi;
    multiple = multiple ?? false;
    redirect = redirect ?? true;

    pmid = pmid.replace(" ", "");
    if (pmid.match(/[^$\d]/)) {
      alert("The PMID cannot contain any non-numerical characters: " + pmid);
    } else {
      pmid = parseInt(pmid).toString();

      const body = {
        'functionid': "submitpmid",
        'tool': "medlinetofhirconverter",
        'idToken': "",
        'pmid': pmid,
        'multiple': multiple,
        'projectfoi': projectfoi,
        'status': medlineEntryState.status
      };
      let response = await submitToFevirServer(globalContext, 8000, body, true, false);

      if (response?.success) {
        if (redirect) {
          history.push(`/resources/Citation/${response.formstateid}`);
        } else {
          return response;
        }
      } else if (response?.errormessage) {
        alert(response.errormessage);
        response.projecterror = true;
        return response;
      } else {
        globalContext.openAlert({ "header": "Error", "content": "We were unable to convert this citation: " + pmid + ". Perhaps this PMID entry doesn't have Medline XML. Maybe it's a Book entry, and we're only converting Journal Articles at this time." });
      }
    }
  };
*/
  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !globalContext.alertModalState.open) {
      submitMedlineQuery();
    }
  }

  const submitMedlineQuery = async () => {
    if (medlineEntryState.medlinequery) {
      changeMedlineEntryState(true, "loadingSubmission");

      const body = {
        'functionid': 'importmedlinesearchquery',
        'medlinequery': medlineEntryState.medlinequery,
        'projectfoi': medlineEntryState.projectfoi,
        'idToken': ''
      };

      let response = await submitToFevirServer(globalContext, 90000, body, true, false);

      changeMedlineEntryState(false, "loadingSubmission");
      if (response?.success) {
        if (response.noResults) {
          alert("No results found for this query.");
        } else {
          history.push(`/resources/Project/${response.projectfoi}`);
        }
      } else {
        alert("Something went wrong with the search query.");
      }

    } else {
      alert("Please enter a PMID Query.");
    }
  }
/*
  const sleep = (ms) => {
    return new Promise(r => setTimeout(r, ms));
  }
*/
  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Computable Publishing<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: MEDLINE Search-to-Import</h2>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <br /><br />
        {globalContext.userState.firebaseuid ?
          <>
            <TextField style={{ width: "480px" }} className="inputField" size="small" variant='outlined' placeholder='MEDLINE Search Query' //type='number'
              value={medlineEntryState.medlinequery} onKeyUp={handleKeyUp}
              onChange={(e) => { changeMedlineEntryState(e.target.value, "medlinequery"); }} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button className="formButton positive" content="Submit" compact onClick={submitMedlineQuery} />
            <br /><br />
            <TextField style={{ width: "220px" }} className="inputField" size="small" variant='outlined' placeholder='Project FOI (Optional)' //type='number'
              value={medlineEntryState.projectfoi} onKeyUp={handleKeyUp}
              onChange={(e) => { changeMedlineEntryState(e.target.value, "projectfoi"); }} />
            <br /><br />
            <img className={medlineEntryState.loadingSubmission ? 'visible' : 'invisible'} style={{ height: "22px" }} src="/spinner.gif"
              alt="Loading" />
            <br /><br /><br />
          </>
          :
          <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
        }
      </Segment>
    </div>
  );
};

export default MedlineSearchImportPage;