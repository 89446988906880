import React from 'react';
import { Link } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import './App.css';

const ToolsPage = ({ useTitle, firebaseConfig, userState, setUserState, setResourcesListState }) => {
  useTitle("FEvIR Platform Tools");

  //const history = useHistory();

  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <div className="box">
        <div className="row content">
          <table className={"HomePageNavigationTable"} style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>
                  <Link to="/content/myresources">
                    <Button className="formButton platformButton" style={{ color: "#000000", width: "100%" }} content="My FEvIR Resources" onClick={() => {
                      setResourcesListState((prevstate) => {
                        return {
                          ...prevstate,
                          filterByResourceType: [],
                          filterByProject: []
                        };
                      });
                    }}
                    />
                  </Link>
                </td>
                <td>View the resources that you made. Does not include resources initially created by other people.</td>
              </tr>
              <tr>
                <td>
                  <Link to="/content/resources">
                    <Button className="formButton platformButton" style={{ color: "#000000", width: "100%" }} content="View Resource List" onClick={() => {
                      setResourcesListState((prevstate) => {
                        return {
                          ...prevstate,
                          filterByResourceType: [],
                          filterByProject: []
                        };
                      });
                    }}
                    />
                  </Link>
                </td>
                <td>View the entire list of publicly available resources on the FEvIR Platform</td>
              </tr>
            </tbody>
          </table>
          <table className={"HomePageNavigationTable"} style={{ width: "100%" }}>
            <thead>
              <tr>
                <th colSpan={2}>Converter Tools
                </th></tr></thead>
            <tbody>
              <tr>
                <td>
                  <Link to="/medlineconvert">
                    <Button className="formButton converterButton" style={{ color: "#000000", width: "100%" }} content="MEDLINE Citation Converter" />
                  </Link>
                </td>
                <td>Enter a PMID to automatically create a HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Citation Resource <a href="https://fevir.net/resources/Project/29884">Release Notes</a></td>
              </tr>
              <tr>
                <td>
                  <Link to="/ctgovconvert">
                    <Button className="formButton converterButton" style={{ color: "#000000", width: "100%" }} content="ClinicalTrials.gov Converter" />
                  </Link>
                </td>
                <td>Enter an NCT Identifier to automatically create HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Resources <a href="https://fevir.net/resources/Project/29885">Release Notes</a></td>
              </tr>
              <tr>
                <td>
                  <Link to="/MAGIC">
                    <Button className="formButton converterButton" style={{ color: "#000000", width: "100%" }} content="MAGIC to FEvIR Converter" />
                  </Link>
                </td>
                <td>Select a MAGIC file (demo) to automatically create HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Resources <a href="https://fevir.net/resources/Project/106028">Release Notes</a></td>
              </tr>
              <tr>
                <td>
                  <Link to="/GRADEpro">
                    <Button className="formButton converterButton" style={{ color: "#000000", width: "100%" }} content="GRADEpro to FEvIR Converter" />
                  </Link>
                </td>
                <td>Select a GRADEpro record (by id) to automatically create HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Resources <a href="https://fevir.net/resources/Project/207970">Release Notes</a></td>
              </tr>
              <tr>
                <td>
                  <Link to="/ris">
                    <Button className="formButton converterButton" style={{ color: "#000000", width: "100%" }} content="RIS to FEvIR Converter" />
                  </Link>
                </td>
                <td>Submit a RIS file to automatically create a HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Citation Resource <a href="https://fevir.net/resources/Project/47783">Release Notes</a></td>
              </tr>
              <tr>
                <td>
                  <Link to="/srdr">
                    <Button className="formButton converterButton" style={{ color: "#000000", width: "100%" }} content="SRDR+ Project Importer" />
                  </Link>
                </td>
                <td>Retrieve all data from an SRDR+ project and create a Project on the FEvIR Platform associated with all its data in HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Resources <a href="https://fevir.net/resources/Project/112344">Release Notes</a></td>
              </tr>
            </tbody>
          </table>
          <table className={"HomePageNavigationTable"} style={{ width: "100%" }}>
            <thead>
              <tr>
                <th colSpan={2}>Specialized Tools
                </th></tr></thead>
            <tbody>
              <tr>
                <td>
                  <Link to="/myballot">
                    <Button className="formButton ballotButton" style={{ color: "#000000", width: "100%" }} content="My Ballot" />
                  </Link>
                </td>
                <td>Vote on any open-for-voting terms in developing Code Systems <a href="https://fevir.net/resources/Project/29890">Release Notes</a></td>
              </tr>
              <tr>
                <td>
                  <Link to="/recommendationtableviewer">
                    <Button className="formButton demoButton" style={{ color: "#000000", width: "100%" }} content="Recommendations Viewer (Demo)" />
                  </Link>
                </td>
                <td>View a selected set of recommendations in a table <a href="https://fevir.net/resources/Project/24612">Release Notes</a></td>
              </tr>
              <tr>
                <td>
                  <Link to="/knowledgeportaldemo">
                    <Button className="formButton demoButton" style={{ color: "#000000", width: "100%" }} content="Knowledge Portal (Demo)" />
                  </Link>
                </td>
                <td>View an organized list of resources related to a Project <a href="https://fevir.net/resources/Project/29888">Release Notes</a></td>
              </tr>
            </tbody>
          </table>
          <table className={"HomePageNavigationTable"} style={{ width: "100%" }}>
            <thead>
              <tr>
                <th colSpan={2}>Builder Tools
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>
                  The FEvIR Platform includes many Builder Tools to create FHIR Resources without requiring expertise in FHIR or JSON.
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/createrecommendation">
                    <Button className="formButton builderButtonEmphasis" style={{ color: "#000000", width: "100%" }} content="Create Recommendation" />
                  </Link>
                </td>
                <td>Add a title and click Submit to use the Computable Publishing<sup>&#174;</sup>: Recommendation Authoring Tool</td>
              </tr>
              <tr>
                <td>
                  <Link to="/createguideline">
                    <Button className="formButton builderButtonEmphasis"
                      style={{ color: "#000000", width: "100%" }} content="Create Guideline" />
                  </Link>
                </td>
                <td>Add a title and click Submit to use the Computable Publishing<sup>&#174;</sup>: Guideline Authoring Tool</td>
              </tr>
              <tr>
                <td>
                  <Link to="/create">
                    <Button className="formButton platformButton" style={{ color: "#000000", width: "100%" }} content="Create New Resource" />
                  </Link>
                </td>
                <td>Add a title and resource type, or add JSON, to add a Resource to the FEvIR Platform</td>
              </tr>
              <tr>
                <td>
                  <Link to="/createproject">
                    <Button className="formButton builderButton" style={{ color: "#000000", width: "100%" }} content="Project Builder" />
                  </Link>
                </td>
                <td>Create a Project Resource with an intuitive form (no manual JSON coding)</td>
              </tr>
              <tr>
                <td>
                  <Link to="/createcitation">
                    <Button className="formButton builderButton" style={{ color: "#000000", width: "100%" }} content="Citation Builder" />
                  </Link>
                </td>
                <td>Create a HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> Citation Resource with an intuitive form (no manual JSON coding)</td>
              </tr>
              <tr>
                <td>
                  <Link to="/createcodeableconcept">
                    <Button className="formButton builderButton" style={{ color: "#000000", width: "100%" }} content="CodeableConcept Builder" />
                  </Link>
                </td>
                <td>Create HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> JSON data element value for a CodeableConcept DataType</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row footer">
          <br />
          <span>The FEvIR Platform uses the HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> standard (<a href="http://build.fhir.org/" target="_blank" rel="noopener noreferrer">current build</a>). HL7<sup>&#174;</sup>, and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
        </div>

      </div>
    </Segment>
  </div>
};

export default ToolsPage;
