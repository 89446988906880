import { parseISO } from 'date-fns';

const getFhirDatatype = (elementName, elementValue) => {
    let datatype = "ERROR--Datatype not detected.";
    if (elementValue === undefined) {
        datatype = "ERROR--Element value is undefined.";
    } else if (elementValue === null) {
        datatype = "ERROR--Element value is null.";
    } else if (elementValue === "") {
        datatype = 'ERROR--Element value is "".';
    } else if (typeof elementValue === 'string') {
        if (parseISO(elementValue) != "Invalid Date") {
            datatype = "dateTime";
        } else {
            datatype = "string";
        }
    } else if (typeof elementValue === 'number') {
        datatype = "number";
    } else if (typeof elementValue === 'boolean') {
        datatype = "boolean";
    } else if (Array.isArray(elementValue)) {
        datatype = "ERROR--Element value is an Array not matched.";
        let workingDatatype = getFhirDatatype(elementName, elementValue[0]);
        datatype = "array-of-" + workingDatatype;
        if (elementValue.length > 1) {
            for (const entry of elementValue) {
                let nextDatatype = getFhirDatatype(elementName, entry);
                if (nextDatatype !== workingDatatype) {
                    datatype = "ERROR--Element value is an Array not matched.";
                    break;
                }
            }
        }
    } else if (typeof elementValue === 'object') {
        datatype = 'BackboneElement';
        let compatibleWith = {
            Address: true, Annotation: true, Attachment: true,
            CodeableConcept: true, CodeableReference: true, Coding: true, ContactDetail: true,
            ContactPoint: true, Dosage: true, Expression: true, ExtendedContactDetail: true,
            Extension: true, HumanName: true, Identifier: true, Meta: true, Narrative: true,
            Quantity: true, Range: true, Ratio: true, Reference: true, RelatedArtifact: true,
            Period: true, Timing: true, UsageContext: true
        }
        const elementKeys = Object.keys(elementValue);
        for (const key of elementKeys) {
            switch (key) {
                case 'additionalInstruction':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'address':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'asNeeded':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'asNeededFor':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'assigner':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'authorReference':
                    compatibleWith.Address = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'authorString':
                    compatibleWith.Address = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'citation':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'city':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'classifier':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'code':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    break
                case 'coding':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'comparator':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'concept':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'contentType':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'country':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'creation':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'data':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'denominator':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'description':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'display':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'district':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'div':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'document':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'doseAndRate':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'duration':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'end':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'event':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'expression':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'extension':
                    break;
                case 'family':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'frames':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'given':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'hash':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'height':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'high':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'identifier':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'label':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'language':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'lastUpdated':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'line':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'low':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'maxDosePerAdministration':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'maxDosePerLifetime':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'maxDosePerPeriod':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'method':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'name':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'numerator':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'organization':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'pages':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'patientInstruction':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'period':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.Extension = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'postalCode':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'prefix':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'profile':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'publicationDate':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'publicationStatus':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'purpose':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'rank':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'reference':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'repeat':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'resource':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    if (elementValue.resource.resourceType) {
                        compatibleWith.RelatedArtifact = false;
                    }
                    break
                case 'resourceReference':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'resourceType':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break;
                case 'route':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'security':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'sequence':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'site':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'size':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'source':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'start':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'state':
                    if (elementName.includes('tatus')) {
                        compatibleWith.Address = false;
                    }
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'status':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'suffix':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'system':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'tag':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'telecom':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'text':
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'time':
                    compatibleWith.Address = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'timing':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'title':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'type':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    if (typeof elementValue.type === 'object') {
                        compatibleWith.RelatedArtifact = false;
                    }
                    break
                case 'url':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'unit':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'use':
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'userSelected':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'value':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'version':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'versionId':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'width':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.Extension = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
                    break
                case 'valueCodeableConcept':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    break
                case 'valueQuantity':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    break
                case 'valueRange':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    break
                case 'valueReference':
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    break
                default:
                    compatibleWith.Address = false;
                    compatibleWith.Annotation = false;
                    compatibleWith.Attachment = false;
                    compatibleWith.CodeableConcept = false;
                    compatibleWith.CodeableReference = false;
                    compatibleWith.Coding = false;
                    compatibleWith.ContactDetail = false;
                    compatibleWith.ContactPoint = false;
                    compatibleWith.Dosage = false;
                    compatibleWith.Expression = false;
                    compatibleWith.ExtendedContactDetail = false;
                    if (key.indexOf('value') !== 0) {
                        compatibleWith.Extension = false;
                    }
                    compatibleWith.HumanName = false;
                    compatibleWith.Identifier = false;
                    compatibleWith.Meta = false;
                    compatibleWith.Narrative = false;
                    compatibleWith.Quantity = false;
                    compatibleWith.Range = false;
                    compatibleWith.Ratio = false;
                    compatibleWith.Reference = false;
                    compatibleWith.RelatedArtifact = false;
                    compatibleWith.Period = false;
                    compatibleWith.Timing = false;
                    compatibleWith.UsageContext = false;
            }
        }
        if (compatibleWith.Address) {
            if (!compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Address';
            }
            if (elementName.includes('ddress') && elementName !== 'addresses') {
                datatype = 'Address';
            }
        }
        if (compatibleWith.Annotation) {
            if (!compatibleWith.Address && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Annotation';
            }
            if (elementName === 'note') {
                datatype = 'Annotation';
            }
        }
        if (compatibleWith.Attachment) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Attachment';
            }
            if (elementName === 'document' || elementName === 'photo') {
                datatype = 'Attachment';
            }
            if (elementName.includes('ttachment')) {
                datatype = 'Attachment';
            }
        }
        if (compatibleWith.CodeableConcept) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'CodeableConcept';
            }
            if (elementName !== 'note' && elementName !== 'text' && elementName !== 'abstract') {
                datatype = 'CodeableConcept';
            }
            if (elementName.includes('odeableConcept')) {
                datatype = 'CodeableConcept';
            }
            if (elementName === 'language' || elementName === 'classifier') {
                datatype = 'CodeableConcept';
            }
        }
        if (compatibleWith.CodeableReference) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'CodeableReference';
            }
            if (typeof elementValue.reference === 'object') {
                datatype = 'CodeableReference';
            }
        }
        if (compatibleWith.Coding) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Coding';
            }
            if (elementName === 'coding' || elementName === 'versionAlgorithmCoding' ||
                elementName === 'use' || elementName === 'additionalUse' || elementName === 'valueCoding' ||
                elementName === 'operationalStatus') {
                datatype = 'Coding';
            }
        }
        if (compatibleWith.ContactDetail) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'ContactDetail';
            }
            if (elementName === 'contact' || elementName === 'author' || elementName === 'editor' ||
                elementName === 'reviewer' || elementName === 'endorser') {
                datatype = 'ContactDetail';
            }
        }
        if (compatibleWith.ContactPoint) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'ContactPoint';
            }
            if (elementName === 'telecom') {
                datatype = 'ContactPoint';
            }
        }
        if (compatibleWith.Dosage) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Dosage';
            }
        }
        if (compatibleWith.Expression) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Expression';
            }
            if (elementName.includes('xpression')) {
                datatype = 'Expression';
            }
        }
        if (compatibleWith.ExtendedContactDetail) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'ExtendedContactDetail';
            }
        }
        if (compatibleWith.Extension) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Extension';
            }
        }
        if (compatibleWith.HumanName) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'HumanName';
            }
            if (elementName === 'name') {
                datatype = 'HumanName';
            }
        }
        if (compatibleWith.Identifier) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Identifier';
            }
            if (elementName.includes('dentifier')) {
                datatype = 'Identifier';
            }
        }
        if (compatibleWith.Meta) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Meta';
            }
        }
        if (compatibleWith.Narrative) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Narrative';
            }
            if (elementName === 'text') {
                datatype = 'Narrative';
            }
        }
        if (compatibleWith.Quantity) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Quantity';
            }
            if (elementName.includes('uantity')) {
                datatype = 'Quantity';
            }
        }
        if (compatibleWith.Range) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Range';
            }
        }
        if (compatibleWith.Ratio) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Ratio';
            }
        }
        if (compatibleWith.Reference) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Reference';
            }
            if (elementName.includes('eference')) {
                datatype = 'Reference';
            }
            if (typeof elementValue.reference === 'string') {
                datatype = 'Reference';
            }
            if ((!elementValue.reference || typeof elementValue.reference === 'string') &&
                (elementName === 'baseCitation' || elementName === 'publisher' || elementName === 'encounter' ||
                    elementName === 'contributor' || elementName === 'affiliation' || elementName === 'author' ||
                    elementName === 'party' || elementName === 'entry' || elementName === 'subject' ||
                    elementName === 'actor' || elementName === 'assessment' || elementName === 'observed' ||
                    elementName === 'intended' || elementName === 'variableDefinition' || elementName === 'device' ||
                    elementName === 'managingEntity' || elementName === 'entity' || elementName === 'managingOrganization' ||
                    elementName === 'partOf' || elementName === 'issuer' || elementName === 'assigner' ||
                    elementName === 'protocol' || elementName === 'site' || elementName === 'eligibility' ||
                    elementName === 'actualGroup' || elementName === 'observedGroup' || elementName === 'intendedExposure' ||
                    elementName === 'result')) {
                datatype = 'Reference';
            }
        }
        if (compatibleWith.RelatedArtifact) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.Period &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'RelatedArtifact';
            }
            if (elementName === 'relatedArtifact' || elementName === 'relatesTo' || elementName === 'documentation') {
                datatype = 'RelatedArtifact';
            }
        }
        if (compatibleWith.Period) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact &&
                !compatibleWith.Timing && !compatibleWith.UsageContext) {
                datatype = 'Period';
            }
        }
        if (compatibleWith.Timing) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.UsageContext) {
                datatype = 'Timing';
            }
        }
        if (compatibleWith.UsageContext) {
            if (!compatibleWith.Address && !compatibleWith.Annotation && !compatibleWith.Attachment && !compatibleWith.CodeableConcept &&
                !compatibleWith.CodeableReference && !compatibleWith.Coding && !compatibleWith.ContactDetail &&
                !compatibleWith.ContactPoint && !compatibleWith.Dosage && !compatibleWith.Expression &&
                !compatibleWith.ExtendedContactDetail && !compatibleWith.Extension && !compatibleWith.HumanName &&
                !compatibleWith.Identifier && !compatibleWith.Meta && !compatibleWith.Narrative &&
                !compatibleWith.Quantity && !compatibleWith.Range && !compatibleWith.Ratio &&
                !compatibleWith.Reference && !compatibleWith.RelatedArtifact && !compatibleWith.Period &&
                !compatibleWith.Timing) {
                datatype = 'UsageContext';
            }
            if (elementName === 'useContext') {
                datatype = 'UsageContext';
            }
        }
    }
    return datatype;
}

export default getFhirDatatype;