import React, { useState, useEffect, useRef, useContext, memo } from 'react';
import { Button, Label, Icon, Segment, Table, TextArea, Dimmer, Loader, Tab, Modal } from 'semantic-ui-react';
import { TextField, RadioGroup, Radio, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import NavigationSegment from './NavigationSegment';
import scrollTo from './ScrollTo';
import { useHistory } from "react-router-dom";
import submitToFevirServer from './SubmitToFevirServer';
import { retrieveUserFevirApiToken } from "./UserAccountFunctions";
import FevirContext from './FevirContext';
import './App.css';

const createNewInvestigation = async (history, globalContext, setLoading, title, description, openToJoin) => {
  if (title) {
    setLoading(true);
    const body = {
      'functionid': 'createnewinvestigation',
      'title': title,
      'description': description,
      'opentojoin': openToJoin,
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    setLoading(false);
    if (response?.success && response.investid) {
      history.push(`/radar/${response.investid}`);
    } else {
      globalContext.openAlert({ "header": "Error", "content": "Not successful in creating a new investigation. Please contact FEvIR admins." });
    }
  } else {
    globalContext.openAlert({ "header": "", "content": "Please enter a title of the investigation." });
  }
}

const MeasuringTheRateCreationPage = ({ useTitle, resourceId }) => {
  useTitle("FEvIR RADAR Tool");

  const globalContext = useContext(FevirContext);

  const [loading, setLoading] = useState(false);
  const [investigationTitle, setInvestigationTitle] = useState("");
  const [investigationDescription, setInvestigationDescription] = useState("");
  const [openToJoin, setOpenToJoin] = useState(true);

  const history = useHistory();

  return <div>
    {(globalContext.userState?.loading || loading) &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }

    <Segment className={`containerSegment measuringTheRateSegment maxRemainderOfPageSegment`} raised>
      <h3>Create a new investigation</h3>
      <br />
      <TextField style={{ width: "70%" }} className="inputField" type='text' label={'Investigation Title'} size="small" variant='outlined' value={investigationTitle} onChange={(e) => { setInvestigationTitle(e.target.value); }} />
      <br />
      <br />
      <TextField style={{ width: "70%" }} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined' value={investigationDescription} onChange={(e) => { setInvestigationDescription(e.target.value); }} />
      <br />
      <FormGroup style={{width: "320px"}}>
        <FormControlLabel control={<Checkbox size="medium" checked={openToJoin} onChange={(e) => { setOpenToJoin(e.target.checked); }} />} label="Open for anyone to join the investigation?" />
      </FormGroup>
      <br />
      {(globalContext.userState?.loading || globalContext.userState?.name) ?  /* The loading userState check so that the button doesn't flash into existance after loading. */
        <Button color='green' /*  "color": "#000000", */
          style={{ width: "280px", float: "left" }}
          content="Start New Investigation"
          onClick={() => { createNewInvestigation(history, globalContext, setLoading, investigationTitle, investigationDescription, openToJoin); }}
        />
        :
        <>Please login to create a measuring the rate investigation.</>
      }

    </Segment>

  </div>
};

export default MeasuringTheRateCreationPage;