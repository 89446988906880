import CreateHyperlink from "./CreateHyperlink";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import RemarkMathPlugin from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Modal } from 'semantic-ui-react';
import getStringFromFHIR from "./getStringFromFHIR";

//to create a hyperlink to an absolute or relative URL in a Reference Datatype
const ResourceReferenceHyperlink = ({ reference }) => {
    if (reference) {
        if (typeof reference.reference === "string" && reference.reference.trim()) {
            return <CreateHyperlink url={reference.reference} display={getStringFromFHIR.Reference(reference)} openInNewTab={true} />;
        } else if (reference.identifier) {
            let fli;
            //check if FEvIR Linking Identifier is found
            if (reference.identifier.system?.toLowerCase() === "https://fevir.net/fli" || reference.identifier.type?.text === "FEvIR Linking Identifier") {
                fli = reference.identifier.value;
            }
            if (fli) {
                return <CreateHyperlink url={"/FLI/" + fli} display={getStringFromFHIR.Reference(reference)} openInNewTab={true} />;
                //return <a href={"/FLI/" + fli} target="_blank" rel="noopener noreferrer">{fli}</a>;
            }
        }
        return <>{getStringFromFHIR.Reference(reference)}</>;
    }
    return <></>;
}

const AbstractMarkdownDisplay = ({ abstractMarkdown }) => {
    let i = 0;
    while (i < abstractMarkdown.length) {
        if (abstractMarkdown.substring(i, i + 20) === `<AbstractText Label=`) {
            if (abstractMarkdown.charAt(i + 20) === `"`) {
                let stringToProcess = abstractMarkdown.substring(i + 21);
                let labelValue = "";
                if (i !== 0) {
                    labelValue = "\n\n";
                }
                labelValue += "**" + stringToProcess.split(`"`)[0] + ":** ";
                let endTagIndex = abstractMarkdown.indexOf(">", i) + 1;
                abstractMarkdown = abstractMarkdown.substring(0, endTagIndex) + labelValue + abstractMarkdown.substring(endTagIndex);
                i += labelValue.length + 20;
            }
        }
        i++;
    }
    return <MarkdownDisplay markdown={abstractMarkdown} />
}

const AnnotationDisplay = ({ annotation }) => {
    let noteDisplay = getStringFromFHIR.Annotation(annotation);
    return <MarkdownDisplay markdown={noteDisplay} />
}

const AttachmentDisplay = ({ attachment }) => {
    if (attachment) {
        if (Array.isArray(attachment) || typeof attachment != "object") {
            return <>[ERROR: Attachment data not interpretable.]</>;
        }
        let contentType = attachment.contentType || "";
        let language = attachment.language || "";
        let data = attachment.data || "";
        let url = attachment.url || "";
        let size = attachment.size || "";
        let hash = attachment.hash || "";
        let title = attachment.title || "";
        let creation = attachment.creation || "";
        let height = attachment.height || "";
        let width = attachment.width || "";
        let frames = attachment.frames || "";
        let duration = attachment.duration || "";
        let pages = attachment.pages || "";
        if (contentType === "application/pdf") {
            contentType = "PDF";
        }
        if (!title && contentType) {
            title = "Untitled " + contentType + " Attachment";
        }
        let divstyle = {};
        if (title) {
            divstyle = { "marginLeft": "24px" };
        }
        return <div>
            {title && <p><b>Title (of Attachment): </b> {title}</p>}
            <div style={divstyle}>
                {contentType && <p><b>Attachment Type: </b> {contentType}</p>}
                {language && <p><b>Language: </b> {language}</p>}
                {creation && <p><b>Date created: </b> {creation}</p>}
                {url && <p><b>URL: </b> <UriDisplay uri={url} /></p>}
                {(data && contentType === "PDF") && <p><b>View PDF: </b> <ViewPdfLauncher label={title} pdfData={data} /></p>}
                {(hash || (data && contentType !== "PDF")) && <p><b>Data available in base64Binary form.</b></p>}
                {size && <p><b>Attachement size: </b> {size} bytes</p>}
                {(height || width) && <p><b>Image size: </b> Height {height} pixels, Width {width} pixels</p>}
                {duration && <p><b>Duration: </b> {duration} seconds</p>}
                {frames && <p><b>Number of frames: </b> {frames}</p>}
                {pages && <p><b>Number of pages: </b> {pages}</p>}
            </div>
        </div>
    } else {
        return <></>
    }
}

const AttesterDisplay = ({ attester }) => {
    if (attester) {
        if (Array.isArray(attester) || typeof attester != "object") {
            return <>[ERROR: Attester data not interpretable.]</>;
        }
        return <div style={{ marginLeft: "24px" }}>
            {attester.mode && <span><b>Mode: </b><CodeableConceptDisplay codeableConcept={attester.mode} /></span>}
            {attester.time && <p><b>Date/Time: </b>{getStringFromFHIR.dateTime(attester.time)} </p>}
            {attester.party && <div><b>Party: </b><ReferenceDisplay reference={attester.party} /></div>}
        </div>
    } else {
        return <></>
    }
}

//Classification is not a FHIR Datatype. Classification schema is an object with:
//type 0..1 CodeableConcept
//classifier 0..* CodeableConcept
//(when used in Classification Profile of ArtifactAssessment.content -- author 0..1 Reference)
//(when used in Classification Profile of ArtifactAssessment.content -- freeToShare 0..1 boolean)
const ClassificationDisplay = ({ classification }) => {
    if (classification) { 
        if (Array.isArray(classification) || typeof classification != "object") {
            return <>[ERROR: Classification data not interpretable.]</>;
        }
        let classificationType = classification.type || "[Untyped]";
        let classificationClassifiers = "[No classifiers]";
        if (Array.isArray(classification.classifier) && classification.classifier.length) {
            classificationClassifiers = classification.classifier.join('; ');
        }
        let dateAsRating;
        if (Array.isArray(classification.extension) && classification.extension.length) {
            dateAsRating = classification.extension.map((extension) => {
                if ((extension.url === "http://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating" ||
                    extension.url === "https://hl7.org/fhir/uv/ebm/StructureDefinition/artifact-assessment-date-as-rating") &&
                    extension.valueDateTime) {
                    return getStringFromFHIR.dateTime(extension.valueDateTime);
                }
            }).join('; ');
        }
        let classificationAuthor = getStringFromFHIR.Reference(classification.author) || "";
        let classificationFreeToShare = getStringFromFHIR.boolean(classification.freeToShare) || null;
        return <div>
            <p><b>{classificationType} classifiers:</b></p>
            <div style={{ marginLeft: "24px" }}>
                {(!dateAsRating || classificationClassifiers !== "[No classifiers]") &&
                    <p>{classificationClassifiers}</p>}
                {dateAsRating && <p>{dateAsRating}</p>}
                {classificationAuthor && <p>Classified by: {classificationAuthor}</p>}
                {classificationFreeToShare === 'True' && <p>Free to share.</p>}
                {classificationFreeToShare === 'False' && <p>NOT free to share.</p>}
            </div>
        </div>
    } else {
        return <></>
    }
}

const CodeableConceptDisplay = ({ codeableConcept }) => {
    if (codeableConcept) {
        if (typeof codeableConcept === "string") {
            return <>{codeableConcept} [ERROR: CodeableConcept data not in expected CodeableConcept datatype.]</>
        }
        if (Array.isArray(codeableConcept) || typeof codeableConcept != "object") {
            return <>[ERROR: CodeableConcept data not interpretable.]</>;
        }
        if (!codeableConcept.text && !codeableConcept.coding) {
            return <>[ERROR: CodeableConcept data not interpretable.]</>;
        }
        try {
            return <>{codeableConcept.coding && codeableConcept.coding.length === 1 &&
                <CodingDisplay coding={codeableConcept.coding[0]} />}
                {codeableConcept.coding && codeableConcept.coding.length > 1 && <>
                    {codeableConcept.coding.map((coding, codingIndex) => {
                        return <span key={codingIndex}>
                            <CodingDisplay coding={coding} />
                            {codingIndex !== codeableConcept.coding.length - 1 && <>; </>}
                        </span>
                    })}
                </>}
                {(codeableConcept.text && codeableConcept.coding) &&
                    <>; {codeableConcept.text}</>}
                {(codeableConcept.text && !codeableConcept.coding) &&
                    <>{codeableConcept.text}</>}
            </>
        } catch {
            return <>[ERROR: CodeableConcept data not interpretable.]</>;
        }
    } else {
        return <></>
    }
}

const CodeableReferenceDisplay = ({ codeableReference }) => {
    if (codeableReference) {
        if (Array.isArray(codeableReference) || typeof codeableReference != "object") {
            return <>[ERROR: CodeableReference data not interpretable.]</>;
        }
        if (codeableReference.concept && !codeableReference.reference) {
            return <CodeableConceptDisplay codeableConcept={codeableReference.concept} />
        }
        if (!codeableReference.concept && codeableReference.reference) {
            return <ReferenceDisplay reference={codeableReference.reference} />
        }
        if (codeableReference.concept && codeableReference.reference) {
            return <><CodeableConceptDisplay codeableConcept={codeableReference.concept} />; <ReferenceDisplay reference={codeableReference.reference} /></>
        }
    } else {
        return <></>
    }
}

const CodingDisplay = ({ coding }) => {
    if (coding) {
        if (Array.isArray(coding) || typeof coding != "object") {
            return <>[ERROR: Coding data not interpretable.]</>;
        }
        let code = coding.code || "[Code missing.]";
        let system = coding.system || "[Code System missing.]";
        let systemDisplay;
        if (system === "http://snomed.info/sct") {
            systemDisplay = "SNOMED-CT";
        } else if (system === "http://loinc.org") {
            systemDisplay = "LOINC";
        } else if (system === "http://www.nlm.nih.gov/research/umls/rxnorm") {
            systemDisplay = "RxNorm";
        } else if (system === "http://unitsofmeasure.org") {
            systemDisplay = "UCUM";
        }
        if (coding.display && systemDisplay) {
            return <>{coding.display} (<CreateHyperlink url={system} display={systemDisplay || system} openInNewTab={true} /> code: {code})</>
        } else if (coding.display && !systemDisplay) {
            return <>{coding.display} (coded as: {code} from <CreateHyperlink url={system} display={systemDisplay || system} openInNewTab={true} />)</>
        } else if (systemDisplay) {
            return <><CreateHyperlink url={system} display={systemDisplay || system} openInNewTab={true} /> code: {code}</>
        } else {
            return <>code {code} from <CreateHyperlink url={system} display={systemDisplay || system} openInNewTab={true} /></>
        }
    } else {
        return <></>
    }
}

const ContactDetailDisplay = ({ contactDetail }) => {
    if (contactDetail) {
        if (Array.isArray(contactDetail) || typeof contactDetail != "object") {
            return <>[ERROR: ContactDetail data not interpretable.]</>;
        }
        let nameDisplay = contactDetail.name || "[No name provided.]";
        return <div>
            <p><b>Name: </b>{nameDisplay}</p>
            {Array.isArray(contactDetail.telecom) && contactDetail.telecom.map((telecom, telecomIndex) => {
                return <div key={telecomIndex}><p><b>Contact Method: </b></p><ContactPointDisplay contactPoint={telecom} /><br /></div>
            })}
            {(contactDetail.telecom && !Array.isArray(contactDetail.telecom)) && <>[ERROR: contactDetail.telecom is not in expected JSON form (should be an array).]</>}
        </div>
    } else {
        return <></>
    }
}

const ContactPointDisplay = ({ contactPoint }) => {
    if (contactPoint) {
        if (Array.isArray(contactPoint) || typeof contactPoint != "object") {
            return <>[ERROR: ContactPoint data not interpretable.]</>;
        }
        return <div style={{ marginLeft: "24px" }}>
            {contactPoint.system && <p><b>Contact Type: </b>{contactPoint.system} </p>}
            {contactPoint.value && <p><b>Detail: </b>{contactPoint.value} </p>}
            {contactPoint.use && <p><b>Use: </b>{contactPoint.use} </p>}
            {contactPoint.period && <p><b>Period: </b>{getStringFromFHIR.Period(contactPoint.period)} </p>}
        </div>
    } else {
        return <></>
    }
}

const DataRequirementDisplay = ({ dataRequirement }) => {
    if (dataRequirement) {
        if (Array.isArray(dataRequirement) || typeof dataRequirement != "object") {
            return <>[ERROR: ParameterDefinition data not interpretable.]</>;
        } else {
            return <div style={{ marginLeft: "24px", wordBreak: "break-all" }}>
                {dataRequirement.type && <><b>Type:</b> {dataRequirement.type}<br /></>}
                {dataRequirement.profile?.length > 0 &&
                    dataRequirement.profile.map((profile, profileIndex) => {
                        return <span key={profileIndex}>
                            <b>Profile:</b> <UriDisplay uri={profile} /><br />
                        </span>
                    })
                }
                {dataRequirement.subjectCodeableConcept &&
                    <span>
                        <b>Subject: </b><CodeableConceptDisplay codeableConcept={dataRequirement.subjectCodeableConcept} />
                    </span>
                }
                {dataRequirement.subjectReference &&
                    <span>
                        <b>Subject: </b><ReferenceDisplay reference={dataRequirement.subjectReference} />
                    </span>
                }
                {dataRequirement.mustSupport?.length > 0 &&
                    dataRequirement.mustSupport.map((must, mustIndex) => {
                        return <span key={mustIndex}><b>Must Support:</b> {must}<br /></span>
                    })
                }
                {dataRequirement.codeFilter?.length > 0 &&
                    dataRequirement.codeFilter.map((codeFilter, codeFilterIndex) => {
                        return <span key={codeFilterIndex}>
                            <b>Code Filter:</b>
                            <div style={{ marginLeft: "24px" }}>
                                {codeFilter.path && <><b>Path:</b> {codeFilter.path}<br /></>}
                                {codeFilter.searchParam && <><b>Search Parameter:</b> {codeFilter.searchParam}<br /></>}
                                {codeFilter.valueSet && <><b>Value Set:</b> <UriDisplay uri={codeFilter.valueSet} /><br /></>}
                                {codeFilter.code?.length > 0 &&
                                    codeFilter.code.map((code => { return <CodingDisplay coding={code} /> })).join('; ')
                                }
                            </div>
                        </span>
                    })
                }
                {dataRequirement.dateFilter?.length > 0 &&
                    dataRequirement.dateFilter.map((dateFilter, dateFilterIndex) => {
                        return <span key={dateFilterIndex}>
                            <b>Date Filter:</b>
                            <div style={{ marginLeft: "24px" }}>
                                {dateFilter.path && <><b>Path:</b> {dateFilter.path}<br /></>}
                                {dateFilter.searchParam && <><b>Search Parameter:</b> {dateFilter.searchParam}<br /></>}
                                {dateFilter.valueDateTime && <>
                                    <b>Value:</b> {getStringFromFHIR.dateTime(dateFilter.valueDateTime)}<br />
                                </>}
                                {dateFilter.valuePeriod && <>
                                    <b>Value:</b> {getStringFromFHIR.Period(dateFilter.valuePeriod)}<br />
                                </>}
                                {dateFilter.valueDuration && <>
                                    <b>Value:</b> {getStringFromFHIR.Quantity(dateFilter.valueDuration)}<br />
                                </>}
                            </div>
                        </span>
                    })
                }
                {dataRequirement.valueFilter?.length > 0 &&
                    dataRequirement.valueFilter.map((valueFilter, valueFilterIndex) => {
                        return <span key={valueFilterIndex}>
                            <b>Value Filter:</b>
                            <div style={{ marginLeft: "24px" }}>
                                {valueFilter.path && <><b>Path:</b> {valueFilter.path}<br /></>}
                                {valueFilter.searchParam && <><b>Search Parameter:</b> {valueFilter.searchParam}<br /></>}
                                {valueFilter.comparator && <><b>Comparator:</b> {valueFilter.comparator}<br /></>}
                                {valueFilter.valueDateTime && <>
                                    <b>Value:</b> {getStringFromFHIR.dateTime(valueFilter.valueDateTime)}<br />
                                </>}
                                {valueFilter.valuePeriod && <>
                                    <b>Value:</b> {getStringFromFHIR.Period(valueFilter.valuePeriod)}<br />
                                </>}
                                {valueFilter.valueDuration && <>
                                    <b>Value:</b> {getStringFromFHIR.Quantity(valueFilter.valueDuration)}<br />
                                </>}
                            </div>
                        </span>
                    })
                }

                {dataRequirement.limit && <><b>Limit:</b> {dataRequirement.limit}<br /></>}
                {dataRequirement.sort?.length > 0 &&
                    dataRequirement.sort.map((sort, sortIndex) => {
                        return <span key={sortIndex}><b>Sort by: </b> {sort.path}, {sort.direction}<br /></span>
                    })
                }
            </div >
        }
    } else {
        return <></>
    }
}

const ExpressionDisplay = ({ expression }) => {
    if (expression) {
        if (Array.isArray(expression) || typeof expression != "object") {
            return <>[ERROR: Expression data not interpretable.]</>;
        } else {
            let description = expression.description;
            let name = expression.name;
            let language = expression.language;
            let expressionString = expression.expression;
            let referenceUri = expression.reference;
            if (language === "text/javascript" && expressionString) {
                return <div style={{ marginLeft: "24px", wordBreak: "break-all" }}>
                    {(name && description) && <><b>{name}:</b> {description}<br /></>}
                    {(name && !description) && <><b>{name}</b>:<br /></>}
                    {(!name && description) && <>{description}<br /></>}
                    {referenceUri && <>Available at: {referenceUri}<br /></>}
                    <p><b>JavaScript:</b></p>
                    <div style={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }} >{expressionString}</div>
                </div>
            }
            return <div style={{ marginLeft: "24px", wordBreak: "break-all" }}>
                {(name && description) && <><b>{name}:</b> {description}<br /></>}
                {(name && !description) && <><b>{name}:</b><br /></>}
                {(!name && description) && <>{description}<br /></>}
                {(language && expressionString) && <><b>{language}</b>: {expressionString}</>}
                {(!language && expressionString) && expressionString}
                {(language && !expressionString) && <><b>Language</b>: {language}</>}
                {referenceUri && <><br />Available at: {referenceUri}</>}
            </div>
        }
    } else {
        return <></>
    }
}

const ExtensionDisplay = ({ extension }) => {
    if (extension) {
        if (Array.isArray(extension) || typeof extension != "object") {
            return <>[ERROR: Extension data not interpretable.]</>;
        } else {
            let innerExtension = extension.extension;
            let url = extension.url;
            let valueDatatype;
            for (const key in extension) {
                if (key.includes('value')) {
                    valueDatatype = key.replace('value', "");
                }
            }
            let value = extension["value" + valueDatatype];
            return <div style={{ marginLeft: "24px", wordBreak: "break-all" }}>
                {url && <><b>Extension URL: </b> <UriDisplay uri={url} /><br /></>}
                {value && <>
                    <b>Extension Value: </b>
                    {(valueDatatype === "Coding") && <CodingDisplay coding={value} />}
                    {(valueDatatype === "CodeableConcept") && <CodeableConceptDisplay codeableConcept={value} />}
                    {(valueDatatype === "Date") && <>{getStringFromFHIR.date(value)}</>}
                    {(valueDatatype === "DateTime") && <>{getStringFromFHIR.dateTime(value)}</>}
                    {(valueDatatype === "Identifier") && <>{getStringFromFHIR.Identifier(value)}</>}
                    {(valueDatatype === "Markdown") && <MarkdownDisplay markdown={value} />}
                    {(valueDatatype === "Reference") && <ReferenceDisplay reference={value} />}
                    {(valueDatatype === "String" || valueDatatype === "Code") && <>{value}</>}
                    {(valueDatatype === "Uri" || valueDatatype === "Canonical") && <UriDisplay uri={value} />}
                    <br />
                </>}
                {innerExtension?.length > 0 && <>
                    <p><b>Extensions:</b></p>
                    {innerExtension.map(innerExtension => { return <ExtensionDisplay extension={innerExtension} /> })}
                </>}
            </div>
        }
    } else {
        return <></>
    }
}

const MarkdownDisplay = ({ markdown }) => {
    if (markdown) {
        if (Array.isArray(markdown) || typeof markdown != "string") {
            return <>[ERROR: Markdown data not interpretable.]</>;
        }
        try {
            let markdownCorrected = markdown?.replaceAll("\n","<br/>");
            if (markdownCorrected.substring(0, 42) === "<div xmlns=\"http://www.w3.org/1999/xhtml\">" && markdownCorrected.substring(markdownCorrected.length - 6) === "</div>") {
                markdownCorrected = markdownCorrected.substring(42, markdownCorrected.length - 6);
            }
            return <span className={!markdown.includes("<br/>") ? "nolinebreak" : ""}><ReactMarkdown children={markdownCorrected} remarkPlugins={[remarkGfm, RemarkMathPlugin]} rehypePlugins={[rehypeRaw, rehypeKatex]} /></span>
        } catch (e) {
            return <>[ERROR: Problem with the markdown format. {e}] {markdown}</>;
        }
        
    } else {
        return <></>
    }
}

const ParameterDefinitionDisplay = ({ parameterDefinition }) => {
    if (parameterDefinition) {
        if (Array.isArray(parameterDefinition) || typeof parameterDefinition != "object") {
            return <>[ERROR: ParameterDefinition data not interpretable.]</>;
        } else {
            return <div style={{ marginLeft: "24px", wordBreak: "break-all" }}>
                {parameterDefinition.name && <><b>Name:</b> {parameterDefinition.name}<br /></>}
                {parameterDefinition.use && <><b>Use:</b> {parameterDefinition.use}<br /></>}
                {(!isNaN(parameterDefinition.min) || parameterDefinition.max) &&
                    <><b>Cardinality: </b>{parameterDefinition.min}-{parameterDefinition.max}<br /></>}
                {parameterDefinition.documentation && <><b>Description:</b> {parameterDefinition.documentation}<br /></>}
                {parameterDefinition.type && <><b>Type:</b> {parameterDefinition.type}<br /></>}
                {parameterDefinition.profile && <><b>Profile:</b> <DisplayFromFHIR uri={parameterDefinition.profile} /><br /></>}
            </div>
        }
    } else {
        return <></>
    }
}

//Rating is not a FHIR Datatype. Rating schema is an object with:
//type 0..1 CodeableConcept
//classifier 0..* CodeableConcept
//quantity 0..1 Quantity
//(when used in Rating Profile of ArtifactAssessment.content -- author 0..1 Reference)
//(when used in Rating Profile of ArtifactAssessment.content -- freeToShare 0..1 boolean)
const RatingDisplay = ({ rating }) => {
    if (rating) {
        if (Array.isArray(rating) || typeof rating != "object") {
            return <>[ERROR: Rating data not interpretable.]</>;
        }
        let ratingType = getStringFromFHIR.CodeableConcept(rating.type) || "[Untyped]";
        let ratingClassifiers = "";
        if (Array.isArray(rating.classifier) && rating.classifier.length > 0) {
            ratingClassifiers = rating.classifier.map((classifier) => { return getStringFromFHIR.CodeableConcept(classifier) }).join('; ');
        }
        let ratingQuantity = getStringFromFHIR.Quantity(rating.quantity) || "";
        let ratingAuthor = getStringFromFHIR.Reference(rating.author) || "";
        let ratingFreeToShare = getStringFromFHIR.boolean(rating.freeToShare) || null;
        return <div>
            <p><b>{ratingType} ratings:</b></p>
            <div style={{ marginLeft: "24px" }}>
                {ratingClassifiers && <p>{ratingClassifiers}</p>}
                {ratingQuantity && <p>{ratingQuantity}</p>}
                {(!ratingClassifiers && !ratingQuantity) && <p>[No ratings.]</p>}
                {ratingAuthor && <p>Rated by: {ratingAuthor}</p>}
                {ratingFreeToShare === 'True' && <p>Free to share.</p>}
                {ratingFreeToShare === 'False' && <p>NOT free to share.</p>}
            </div>
        </div>
    } else {
        return <></>
    }
}

const ReferenceDisplay = ({ reference }) => {
    if (reference) {
        if (Array.isArray(reference) || typeof reference != "object") {
            return <>[ERROR: Reference data not interpretable.]</>;
        } else {
            return <ResourceReferenceHyperlink reference={reference} />
        }
    } else {
        return <></>
    }
}

const RelatedArtifactDisplay = ({ relatedArtifact }) => {
    if (relatedArtifact) {
        let type = relatedArtifact.type || "";
        if (typeof type === 'object') {
            type = "[ERROR: RelatedArtifact.type is not in string form.]";
        }
        let classifierDisplay = "";
        if (relatedArtifact.classifier?.length > 0) {
            let classifierList = [];
            for (let classifierEntry in relatedArtifact.classifier) {
                let classifierString = getStringFromFHIR.CodeableConcept(relatedArtifact.classifier[classifierEntry]);
                if (classifierString.substring(0, 81) === "Quotation (coded as: quotation from http://hl7.org/fhir/provenance-entity-role); ") {
                    classifierString = "Quotation (coded as: quotation from http://hl7.org/fhir/provenance-entity-role); " + "\"" + classifierString.substring(81) + "\"";
                }
                classifierList.push(classifierString);
            }
            classifierDisplay = classifierList.join('; ') || "";
        }
        let label = relatedArtifact.label || "";
        let display = relatedArtifact.display || "";
        let citation = relatedArtifact.citation || "";
        let document = relatedArtifact.document || "";
        let canonical = relatedArtifact.resource || "";
        let resourceReference = "";
        if (relatedArtifact.resourceReference) {
            resourceReference = <ResourceReferenceHyperlink reference={relatedArtifact.resourceReference} />;
        }
        let publicationStatus = relatedArtifact.publicationStatus || "";
        let publicationDate = relatedArtifact.publicationDate || "";
        return <div style={{ marginLeft: "24px" }}>
            <p><b>Relationship Type: </b> {type}</p>
            {label && <p><b>Label: </b> {label}</p>}
            {display && <p><b>Description: </b> {display}</p>}
            {citation && <span><b>Citation: </b> <MarkdownDisplay markdown={citation} /></span>}
            {classifierDisplay && <p><b>Classifier(s): </b> {classifierDisplay}</p>}
            {canonical && <p><b>URL: </b> <CreateHyperlink url={canonical} display={canonical} openInNewTab={true} /></p>}
            {document && <AttachmentDisplay attachment={document} />}
            {resourceReference && <p><b>Resource Reference: </b> {resourceReference}</p>}
            {publicationStatus && <p><b>Publication Status: </b> {publicationStatus}</p>}
            {publicationDate && <p><b>Publication Date: </b> {publicationDate}</p>}
        </div>
    } else {
        return <></>
    }
}
//expected input of string datatype
//expected output if not string datatype is '[ERROR: Uri data not interpretable.]'
//expected output if not linkable is {uri}
//expected output if linkable url is <a href="{uri}">{uri}</a>
const UriDisplay = ({ uri }) => {
    if (uri?.trim()) {
        if (typeof uri != "string") {
            return <>[ERROR: Uri data not interpretable.]</>;
        } else {
            return <CreateHyperlink url={uri} openInNewTab={true} />;
        }
    } else {
        return <></>
    }
}

const UsageContextDisplay = ({ usageContext }) => {
    if (usageContext) {
        if (Array.isArray(usageContext) || typeof usageContext != "object") {
            return <>[ERROR: UsageContext data not interpretable.]</>;
        }
        let code = getStringFromFHIR.Coding(usageContext.code) || "[Usage Context Code Missing.]";
        if (usageContext.valueCodeableConcept) {
            return <>{code} matches {getStringFromFHIR.CodeableConcept(usageContext.valueCodeableConcept)}</>
        } else if (usageContext.valueQuantity) {
            return <>{code} matches {getStringFromFHIR.Quantity(usageContext.valueQuantity)}</>
        } else if (usageContext.valueRange) {
            return <>{code} matches {getStringFromFHIR.Range(usageContext.valueRange)}</>
        } else if (usageContext.valueReference) {
            return <>{code} matches <ReferenceDisplay reference={usageContext.valueReference} /></>
        } else {
            return <>{code} matches [Usage Context Value Missing.]</>
        }
    } else {
        return <></>
    }
}

const ViewPdfLauncher = ({ label, pdfData }) => {
    return (
        <Modal
            style={{ width: "75%", height: "75%" }}
            trigger={<span style={{ color: "#4183C4", cursor: "pointer" }}>{label}</span>}
            centered={false}
            dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
            closeOnDimmerClick={true}
            content={<embed src={`data:application/pdf;base64,${pdfData}`} type="application/pdf" width="100%" height="100%" />}
            actions={[{ key: 'done', content: 'Close', negative: true }]}
        />
    )
}

const XhtmlDisplay = ({ xhtml }) => {
    if (xhtml) {
        if (Array.isArray(xhtml) || typeof xhtml != "string") {
            return <>[ERROR: Xhtml data not interpretable.]</>;
        }
        try {
            return <span>
                <ReactMarkdown children={xhtml} rehypePlugins={[rehypeRaw]} /></span>
        } catch (e) {
            return <>[ERROR: Problem with the xhtml format. {e}] {xhtml}</>;
        }
        
    } else {
        return <></>
    }
}

const DisplayFromFHIR = ({ coding, codeableConcept, uri, relatedArtifact, reference, expression, extension,
    markdown, abstractMarkdown, annotation, attachment, contactPoint, contactDetail, usageContext,
    codeableReference, classification, rating, attester, parameterDefinition, dataRequirement, xhtml }) => {
    if (uri) {
        if (typeof(uri) === "string") {
            return <UriDisplay uri={uri} />
        } else {
            return <>This FHIR element is supposed to be a string instead of {typeof(uri)} {JSON.stringify(uri)}</>
        }
    } else if (relatedArtifact) {
        return <RelatedArtifactDisplay relatedArtifact={relatedArtifact} />
    } else if (reference) {
        return <ReferenceDisplay reference={reference} />
    } else if (markdown) {
        return <MarkdownDisplay markdown={markdown} />
    } else if (codeableConcept) {
        return <CodeableConceptDisplay codeableConcept={codeableConcept} />
    } else if (coding) {
        return <CodingDisplay coding={coding} />
    } else if (classification) {
        return <ClassificationDisplay classification={classification} />
    } else if (rating) {
        return <RatingDisplay rating={rating} />
    } else if (expression) {
        return <ExpressionDisplay expression={expression} />
    } else if (abstractMarkdown) {
        return <AbstractMarkdownDisplay abstractMarkdown={abstractMarkdown} />
    } else if (annotation) {
        return <AnnotationDisplay annotation={annotation} />
    } else if (attachment) {
        return <AttachmentDisplay attachment={attachment} />
    } else if (contactPoint) {
        return <ContactPointDisplay contactPoint={contactPoint} />
    } else if (contactDetail) {
        return <ContactDetailDisplay contactDetail={contactDetail} />
    } else if (usageContext) {
        return <UsageContextDisplay usageContext={usageContext} />
    } else if (codeableReference) {
        return <CodeableReferenceDisplay codeableReference={codeableReference} />
    } else if (attester) {
        return <AttesterDisplay attester={attester} />
    } else if (extension) {
        return <ExtensionDisplay extension={extension} />
    } else if (parameterDefinition) {
        return <ParameterDefinitionDisplay parameterDefinition={parameterDefinition} />
    } else if (dataRequirement) {
        return <DataRequirementDisplay dataRequirement={dataRequirement} />
    } else if (xhtml) {
        return <XhtmlDisplay xhtml={xhtml} />
    } else {
        return <></>
    }
}

export { getStringFromFHIR, DisplayFromFHIR };