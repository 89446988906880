import React from 'react';
import { getStringFromFHIR } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';
import { Table } from 'semantic-ui-react';
import { CertaintyRows } from './EvidenceFunctions';
import SEVCO from './SEVCO';

const evidenceDotSynthesisTypeValueSet = [
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "std-MA", display: "summary data meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "IPD-MA", display: "individual patient data meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "indirect-NMA", display: "indirect network meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "combined-NMA", display: "combined direct plus indirect network meta-analysis" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "range", display: "range of results" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "classification", display: "classifcation of results" },
  { system: "http://terminology.hl7.org/CodeSystem/synthesis-type", code: "NotApplicable", display: "not applicable" }
];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.assertion) {
      innerDiv += "<p><b>Assertion: </b>" + resource.assertion + "</p><br/>";
    }
    if (resource.note?.length > 0) {
      innerDiv += resource.note.map((note) => {
        return "<p><b>Note: </b>" + note.text + "</p><br/>"
      }).join("");
    }
    if (resource.variableDefinition?.length > 0) {
      innerDiv += resource.variableDefinition.map((varDef) => {
        let varRole;
        if (typeof (varDef.variableRole) === "string") {
          varRole = varDef.variableRole;
        } else if (varDef.variableRole.coding?.[0]?.code) {
          varRole = varDef.variableRole.coding[0].code;
        } else {
          varRole = getStringFromFHIR.CodeableConcept(varDef.variableRole);
        }
        let varDefDisplay = "<b>Variable in role of " + varRole + ": </b>";
        if (varDef.description) {
          varDefDisplay += varDef.description;
        } else if (varDef.observed?.display) {
          varDefDisplay += varDef.observed.display;
        } else if (varDef.intended?.display) {
          varDefDisplay += varDef.intended.display;
        } else {
          varDefDisplay += "noted";
        }
        return "<p>" + varDefDisplay + "</p><br/>"
      }).join("");
    }
    if (resource.synthesisType) {
      innerDiv += "<p><b>Synthesis Type: </b>" + getStringFromFHIR.CodeableConcept(resource.synthesisType) + "</p><br/>";
    }
    if (resource.studyDesign?.length > 0) {
      innerDiv += resource.studyDesign.map((item) => {
        return "<p><b>Study Design: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.statistic?.length > 0) {
      innerDiv += resource.statistic.map((item) => {
        if (item.description) {
          return "<p><b>Statistic: </b>" + item.description + "</p><br/>"
        } else if (item.statisticType && item.quantity) {
          return "<p><b>Statistic: </b>" + getStringFromFHIR.CodeableConcept(item.statisticType) + " " + getStringFromFHIR.Quantity(item.quantity) + "</p><br/>"
        } else {
          return "<p><b>Statistic: </b>unclear</p><br/>"
        }
      }).join("");
    }
    if (resource.certainty?.length > 0) {
      innerDiv += resource.certainty.map((item) => {
        let certaintyType = getStringFromFHIR.CodeableConcept(item.type) || "untyped";
        let certaintyRating = getStringFromFHIR.CodeableConcept(item.rating) || "unrated";
        return "<p><b>Certainty of type " + certaintyType + ": </b>" + certaintyRating + "</p><br/>"
      }).join("");
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const EvidenceBuilder = ({ resourceState, setResourceState, globalContext, setSourceJsonState }) => {

  let fhirJson = resourceState.resourceJson;
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='assertion' fieldLabel='Assertion'
          startCollapsed
          startingValue={resourceState.assertion} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
          fieldLabel='Note' startingValue={resourceState.note} setResourceState={setResourceState} />
        <h4>Narrative Summary</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
            generatedNarrative={generateNarrative(resourceState)} startCollapsed
            startingValue={resourceState.text} setResourceState={setResourceState} />
        </div>
      </div>
      <h3 id="variable-definitions">Variable Definitions</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='EvidenceVariableDefinition' elementName='variableDefinition'
          startCollapsed fullResourceState={resourceState} setSourceJsonState={setSourceJsonState}
          globalContext={globalContext}
          fieldLabel='Variable Definition' startingValue={resourceState.variableDefinition} setResourceState={setResourceState} />
      </div>
      <h3 id="study-design">Study Design</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='CodeableConcept' elementName='synthesisType' fieldLabel='Synthesis Type'
          startingValue={resourceState.synthesisType} startCollapsed
          valueSet={evidenceDotSynthesisTypeValueSet} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='studyDesign' fieldLabel='Study Design'
          startingValue={resourceState.studyDesign} startCollapsed
          valueSet={SEVCO.studyDesign} setResourceState={setResourceState} />
      </div>
      <h3 id="statistics">Statistics</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='EvidenceStatistic' elementName='statistic' startCollapsed
          fieldLabel='Statistic' startingValue={resourceState.statistic} setResourceState={setResourceState} />
      </div>
      <h3 id="certainty">Certainty</h3>
      <p>Note: Certainty elements are not editable from here yet.</p>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.certainty?.length > 0 &&
          <Table style={{ margin: "4px" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ padding: "6px" }}>Type</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px" }}>Rating</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>Description</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px", width: "25%" }}>Notes</Table.HeaderCell>
                <Table.HeaderCell style={{ padding: "6px" }}>Rater</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <CertaintyRows certaintyEntries={fhirJson.certainty} level={0} editMode={false} />
            </Table.Body>
          </Table>
        }
      </div>
    </div>
  </div>
}

export default EvidenceBuilder;
