import React, { useState, useEffect, useContext } from 'react';
import { Button, Segment, Dropdown } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import TextareaAutosize from 'react-textarea-autosize';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const loadCodeSystemsAndValueSets = async (globalContext, setCodeSystemOptionsState, setCodeSystemDictionaryState, setCodeSystemUrlDictionaryState) => {
  let newCodeSystemDictionary = {};
  let newCodeSystemUrlDictionary = {};

  let body = {
    'functionid': 'getcodesystemsandvaluesetslist',
    'idToken': ""
  };

  let response = await submitToFevirServer(globalContext, 10000, body, true, false);
  if (response.success && response.codesystemsandvaluesets) {
    for (let index in response.codesystemsandvaluesets) {
      let resourceObject = response.codesystemsandvaluesets[index];
      let key = Object.keys(resourceObject)[0];
      let resource = resourceObject[key];
      if (key !== undefined && key !== "") {
        if (newCodeSystemDictionary[key] === undefined) {
          newCodeSystemDictionary[key] = { status: resource.status, name: null, title: null, resourceTitle: key, url: resource.url, codeSystemOrValueSet: resource.codeSystemOrValueSet, identifierValue: resource.identifierValue, identifierSystem: resource.identifierSystem };
        }
        if (resource.url && newCodeSystemUrlDictionary[resource.url] === undefined) {
          newCodeSystemUrlDictionary[resource.url] = key;
        }
      }
    }
  }
  //let blankDictionaryEntry = {key: {text: null, value: null, name: null, title: null, url: null, codeSystemOrValueSet: null, identifierValue: null, identifierSystem: null}};
  //convertDictionaryToOptions(
  setCodeSystemDictionaryState(newCodeSystemDictionary);
  let options = convertDictionaryToOptions(newCodeSystemDictionary);
  options.sort((a, b) => {
    if (a.key > b.key) return 1;
    if (a.key < b.key) return -1;
  });
  setCodeSystemOptionsState(options);
  setCodeSystemUrlDictionaryState(newCodeSystemUrlDictionary);

}

const failedToLoadCodes = (setPickCodeDictionaryState, setPickCodeOptionsState) => {
  const failedToLoadCodes = { "No codes loaded...": { status: null, name: null, title: null, resourceTitle: null, url: null, codeSystemOrValueSet: null, identifierValue: null, identifierSystem: null } };
  const failedToLoadCodesOptions = convertDictionaryToOptions(failedToLoadCodes);
  setPickCodeDictionaryState(failedToLoadCodes);
  setPickCodeOptionsState(failedToLoadCodesOptions);
}


const importCodesToPickList = async (globalContext, setPickCodeOptionsState, setPickCodeDictionaryState, codeSystemEntry) => {
  let loadedCodes = false;
  if (codeSystemEntry.identifierSystem === "https://fevir.net") {
    if (codeSystemEntry.codeSystemOrValueSet === "CodeSystem") {
      const body = {
        'functionid': "codesystemtermstovaluesetconcept",
        'idToken': "",
        'resourceid': codeSystemEntry.identifierValue,
        'termcode': "",
        'recursive': true
      };

      let response = await submitToFevirServer(globalContext, 5000, body, true, false);

      if (response.success && response.valuesetincludeinstance?.concept) {
        let pickCodeDictionary = {};
        for (let index in response.valuesetincludeinstance.concept) {
          let term = response.valuesetincludeinstance.concept[index];
          if (term.code && pickCodeDictionary[term.code] === undefined) {
            pickCodeDictionary[term.code] = { 'display': term.display, 'codeSystemTitle': codeSystemEntry.resourceTitle, 'codeSystemUrl': codeSystemEntry.url };
          }
        }
        setPickCodeDictionaryState(pickCodeDictionary);
        let pickCodeOptions = convertDictionaryToOptions(pickCodeDictionary);
        /*
        options.sort((a, b) => {
          if (a.key > b.key) return 1;
          if (a.key < b.key) return -1;
        });
        */
        if (pickCodeOptions.length > 0) {
          setPickCodeOptionsState(pickCodeOptions);
          loadedCodes = true;
        }
      }

    } else if (codeSystemEntry.codeSystemOrValueSet === "ValueSet") {
      //TO DO
      const body = {
        'functionid': 'getfhirresource',
        'resourceid': codeSystemEntry.identifierValue,
        'resourcetype': "ValueSet",
        'idToken': ""
      };

      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      if (response.success && response.fhirjsonstring) {
        let json = JSON.parse(response.fhirjsonstring);
        if (json.compose) {
          let pickCodeDictionary = {};
          let codeSystemTitleDictionary = {};
          for (let includeIndex in json.compose.include) {
            let include = json.compose.include[includeIndex];
            for (let conceptIndex in include.concept) {
              let term = include.concept[conceptIndex];
              if (pickCodeDictionary[term.code] === undefined) {
                let codeSystemName = "";
                if (include.system) {
                  if (include.system.substring(0, 39) === "https://fevir.net/resources/CodeSystem/") {
                    let resourceId = include.system.substring(39);
                    if (resourceId) {
                      if (codeSystemTitleDictionary[resourceId]) {
                        codeSystemName = codeSystemTitleDictionary[resourceId];
                      } else {
                        //LOOK IT UP AND MAKE SURE NOT EMPTY
                        const codeSystemRequestBody = {
                          'functionid': 'getfhirresource',
                          'resourceid': resourceId,
                          'resourcetype': "CodeSystem",
                          'idToken': ""
                        };

                        let codeSystemResponse = await submitToFevirServer(globalContext, 5000, codeSystemRequestBody, true, false);
                        if (codeSystemResponse.success && codeSystemResponse.title && codeSystemResponse.resourcetype === "CodeSystem") {
                          codeSystemTitleDictionary[resourceId] = codeSystemResponse.title;
                        }
                      }
                    }
                  }
                }
                if (term.code && pickCodeDictionary[term.code] === undefined) {
                  pickCodeDictionary[term.code] = { 'display': term.display, 'codeSystemTitle': codeSystemName, 'codeSystemUrl': include.system };
                }
              } else {
                if (include.system !== pickCodeDictionary[term.code].codeSystemUrl) {
                  alert(`The code ${term.code} is used more than once in this value set. Only the first instance is included in this Choose Code picklist.`);
                }
              }
            }
          }
          setPickCodeDictionaryState(pickCodeDictionary);
          let pickCodeOptions = convertDictionaryToOptions(pickCodeDictionary);
          if (pickCodeOptions.length > 0) {
            setPickCodeOptionsState(pickCodeOptions);
            loadedCodes = true;
          }
        }
      }
    }

  }
  if (loadedCodes === false) {
    failedToLoadCodes(setPickCodeDictionaryState, setPickCodeOptionsState);
  }
}

const convertDictionaryToOptions = (dictionary) => {
  let currentDisplays = {};
  return Object.keys(dictionary).map((key, index) => {
    let display = key;
    if (dictionary[key].display) {
      display = dictionary[key].display;
    } else if (dictionary[key].text) {
      display = dictionary[key].text;
    }
    if (display && currentDisplays[display]) {
      if (dictionary[key].codeSystemTitle && dictionary[key].codeSystemTitle !== currentDisplays[display].codeSystemTitle) {
        display += " (" + dictionary[key].codeSystemTitle + ")";
      } else if (dictionary[key].codeSystemUrl && dictionary[key].codeSystemUrl !== currentDisplays[display].codeSystemUrl) {
        display += " (" + dictionary[key].codeSystemUrl + ")";
      }
    }
    currentDisplays[display] = { 'codeSystemTitle': dictionary[key].codeSystemTitle, 'codeSystemUrl': dictionary[key].codeSystemUrl };
    let typeObj = {
      key: key,
      text: display,
      value: key
    };
    return typeObj;
  });
}

const CreateCodeableConceptJsonPage = ({ useTitle }) => {
  const globalContext = useContext(FevirContext);
  useTitle("FEvIR CodeableConcept Element Builder");

  const [fhirEntryState, setFhirEntryState] = useState({ "inputChanged": true, "codings": [{ "systemName": "", "systemUri": "", "code": "", "display": "", "qualifiers": [], "preset": "" }], "text": "" });
  const [fhirOutputState, setFhirOutputState] = useState("");
  const [fhirOutputValidState, setFhirOutputValidState] = useState("#000000");
  const [presetDictionaryState, setPresetDictionaryState] = useState({});
  const [presetOptionsState, setPresetOptionsState] = useState([]);
  const startingPick = { "Loading...": { status: null, name: null, title: null, resourceTitle: null, url: null, codeSystemOrValueSet: null, identifierValue: null, identifierSystem: null } };
  const startingPickOptions = convertDictionaryToOptions(startingPick);
  const [codeSystemDictionaryState, setCodeSystemDictionaryState] = useState(startingPick);
  const [codeSystemOptionsState, setCodeSystemOptionsState] = useState(startingPickOptions);
  const [codeSystemUrlDictionaryState, setCodeSystemUrlDictionaryState] = useState({});
  const [pickCodeSystemState, setPickCodeSystemState] = useState("");
  const [pickCodeDictionaryState, setPickCodeDictionaryState] = useState(startingPick);
  const [pickCodeOptionsState, setPickCodeOptionsState] = useState(startingPickOptions);
  const [pickCodeState, setPickCodeState] = useState("");
  const [additionLabelState, setAdditionLabelState] = useState("");
  const [pickCodeSystemEntersPressed, setPickCodeSystemEntersPressed] = useState(0);
  const [loadedFromUrl, setLoadedFromUrl] = useState(false);

  const changeCoding = (newValue, codingIndex, field) => {
    setFhirEntryState(prevState => {
      let newCodings = prevState.codings;
      newCodings[codingIndex][field] = newValue;
      return {
        ...prevState,
        inputChanged: true,
        codings: newCodings
      };
    });
  };

  const changeCodingQualifierCoding = (newValue, codingIndex, qualifierIndex, qualifierCodingIndex, field) => {
    setFhirEntryState(prevState => {
      let newCodings = prevState.codings;
      newCodings[codingIndex]["qualifiers"][qualifierIndex]["codings"][qualifierCodingIndex][field] = newValue;
      return {
        ...prevState,
        inputChanged: true,
        codings: newCodings
      };
    });
  };

  const changeCodingQualifierText = (newValue, codingIndex, qualifierIndex) => {
    setFhirEntryState(prevState => {
      let newCodings = prevState.codings;
      newCodings[codingIndex]["qualifiers"][qualifierIndex]["text"] = newValue;
      return {
        ...prevState,
        inputChanged: true,
        codings: newCodings
      };
    });
  };

  const updateJsonOutput = () => {
    let outputJson = { "coding": [], "text": "" };

    let presetDictionary = {};

    for (let codingIndex in fhirEntryState.codings) {
      let coding = fhirEntryState.codings[codingIndex];
      let codingJson = { "extension": [], "system": coding.systemUri, "code": coding.code, "display": coding.display };
      let presetDisplay;
      if (coding.systemName) {
        codingJson.extension.push({ "url": "https://fevir.net/resources/StructureDefinition/18147", "valueString": coding.systemName });
        presetDisplay = coding.systemName;
      } else if (coding.systemUri) {
        presetDisplay = coding.systemUri;
      }
      if (presetDisplay && presetDictionary[presetDisplay] === undefined) {
        presetDictionary[presetDisplay] = { "systemname": coding.systemName, "systemuri": coding.systemUri };
      }
      for (let qualifierIndex in coding.qualifiers) {
        let qualifier = coding.qualifiers[qualifierIndex];
        let qualifierJson = { "url": "https://fevir.net/resources/StructureDefinition/21201", "valueCodeableConcept": { "coding": [], "text": qualifier.text } };

        for (let qualifierCodingIndex in qualifier.codings) {
          let qualifierCoding = qualifier.codings[qualifierCodingIndex];
          let qualifierCodingJson = {
            "extension": [],
            "system": qualifierCoding.systemUri,
            "code": qualifierCoding.code,
            "display": qualifierCoding.display
          };
          let presetDisplay;
          if (qualifierCoding.systemName) {
            qualifierCodingJson.extension.push({ "url": "https://fevir.net/resources/StructureDefinition/18147", "valueString": qualifierCoding.systemName });
            presetDisplay = qualifierCoding.systemName;
          } else {
            delete qualifierCodingJson.extension;
            if (qualifierCoding.systemUri) {
              presetDisplay = qualifierCoding.systemUri;
            }
          }
          qualifierJson.valueCodeableConcept.coding.push(qualifierCodingJson);
          if (presetDisplay && presetDictionary[presetDisplay] === undefined) {
            presetDictionary[presetDisplay] = { "systemname": qualifierCoding.systemName, "systemuri": qualifierCoding.systemUri };
          }
        }
        codingJson.extension.push(qualifierJson);

      }
      if (codingJson.extension.length === 0) {
        delete codingJson.extension;
      }
      outputJson.coding.push(codingJson);
    }

    const presetOptions = Object.keys(presetDictionary).map((presetKey) => {
      let preset = {
        key: presetKey,
        text: presetKey,
        value: presetKey,
        systemname: presetDictionary[presetKey].systemname || "",
        systemuri: presetDictionary[presetKey].systemuri || ""
      };
      return preset;
    });
    setPresetOptionsState(presetOptions);
    setPresetDictionaryState(presetDictionary);

    outputJson.text = fhirEntryState.text;

    setFhirOutputState(JSON.stringify(outputJson, null, 2));
  }

  const updateJsonInput = (outputJsonString) => {
    let outputJson;

    let presetDictionary = {};

    try {
      outputJson = JSON.parse(outputJsonString);
      setFhirOutputValidState("#000000");
    } catch (e) { setFhirOutputValidState("#FF0000"); return; }

    if (outputJson) {
      let newState = { inputChanged: false, "text": outputJson.text };
      newState.codings = [];
      for (let codingIndex in outputJson.coding) {
        let coding = outputJson.coding[codingIndex];
        let systemName = "";
        let qualifiers = [];

        let presetDisplay = "";

        for (let extensionIndex in coding.extension) {
          let extension = coding.extension[extensionIndex];
          if (extension.url === "https://fevir.net/resources/StructureDefinition/18147") {
            if (extension.valueString) {
              systemName = extension.valueString;
              presetDisplay = systemName;
            }
          }
        }

        if (systemName === "") {
          presetDisplay = coding.system;
        }
        if (presetDisplay && presetDictionary[presetDisplay] === undefined) {
          presetDictionary[presetDisplay] = { "systemname": systemName, "systemuri": coding.system };
        }

        for (let extensionIndex in coding.extension) {
          let extension = coding.extension[extensionIndex];
          if (extension.url === "https://fevir.net/resources/StructureDefinition/21201") {
            if (extension.valueCodeableConcept) {
              let qualifier = { "codings": [], "text": "" };
              for (let extensionCodingIndex in extension.valueCodeableConcept.coding) {
                let extensionCoding = extension.valueCodeableConcept.coding[extensionCodingIndex];
                let qualifierCoding = { "systemUri": extensionCoding.system, "code": extensionCoding.code, "display": extensionCoding.display, "presetSelected": "" };
                if (extensionCoding.extension?.length > 0) {
                  if (extensionCoding.extension[0].url === "https://fevir.net/resources/StructureDefinition/18147") {
                    qualifierCoding.systemName = extensionCoding.extension[0].valueString;
                  }
                }
                qualifier.codings.push(qualifierCoding);
                let presetDisplay = "";
                if (qualifierCoding.systemName) {
                  presetDisplay = qualifierCoding.systemName;
                } else if (extensionCoding.system) {
                  presetDisplay = extensionCoding.system;
                }
                if (presetDisplay && presetDictionary[presetDisplay] === undefined) {
                  presetDictionary[presetDisplay] = { "systemname": qualifierCoding.systemName, "systemuri": extensionCoding.system };
                }
              }
              if (extension.valueCodeableConcept.text) {
                qualifier.text = extension.valueCodeableConcept.text;
              }
              qualifiers.push(qualifier);
            }
          }
        }
        newState.codings.push({ "systemName": systemName, "systemUri": coding.system, "code": coding.code, "display": coding.display, "qualifiers": qualifiers, "presetSelected": "" });

      }
      const presetOptions = Object.keys(presetDictionary).map((presetKey) => {
        let preset = {
          key: presetKey,
          text: presetKey,
          value: presetKey,
          systemname: presetDictionary[presetKey].systemname || "",
          systemuri: presetDictionary[presetKey].systemuri || ""
        };
        return preset;
      });
      setPresetOptionsState(presetOptions);
      setPresetDictionaryState(presetDictionary);

      setFhirEntryState(newState);
    }
  }

  const addTermFromFevir = () => {
    setLoadedFromUrl(false);
    let codePicked = pickCodeState;
    if (pickCodeDictionaryState[codePicked]) {
      setFhirEntryState(prevState => {
        let newCodings = prevState.codings;
        let newCodingEntry = { "systemName": pickCodeDictionaryState[codePicked].codeSystemTitle, "systemUri": pickCodeDictionaryState[codePicked].codeSystemUrl, "code": codePicked, "display": pickCodeDictionaryState[codePicked].display, "qualifiers": [] };
        let entryAdded = false;
        if (newCodings.length > 0) {
          let lastEntry = newCodings[prevState.codings.length - 1];
          if (lastEntry.systemName === "" && lastEntry.systemUri === "" && lastEntry.code === "" && lastEntry.display === "" && (lastEntry.qualifiers === undefined || lastEntry.qualifiers.length === 0)) {
            newCodings[newCodings.length - 1] = newCodingEntry;
            entryAdded = true;
          }
        }
        if (entryAdded === false) {
          newCodings.push(newCodingEntry);
        }
        return { ...prevState, inputChanged: true, codings: newCodings };
      });
      setPickCodeState("");
      setPickCodeSystemEntersPressed(0);
    }
  }

  useEffect(async () => {
    if (fhirEntryState.inputChanged) {
      updateJsonOutput();
    }
  }, [fhirEntryState]);

  useEffect(() => {
    loadCodeSystemsAndValueSets(globalContext, setCodeSystemOptionsState, setCodeSystemDictionaryState, setCodeSystemUrlDictionaryState);
  }, [globalContext.userState]);

  let pickCodeButtonStyle = { padding: "8px", marginLeft: "12px", color: "#000000", border: '2px solid #DAA520 !important' };
  let pickCodeButtonDisabled = false;
  let pickCodeButtonClasses = "formButton";
  if (pickCodeState) {
    pickCodeButtonStyle.display = "";
    pickCodeButtonClasses = "formButton highlightedBorder";
    pickCodeButtonStyle["marginLeft"] = "11px";
  } else {
    pickCodeButtonDisabled = true;
  }

  let codeDropDownStyle = { marginLeft: "12px", width: "360px", border: "2px solid #00000030" };
  if (pickCodeSystemState) {
  } else {
    codeDropDownStyle.display = "none";
    pickCodeButtonStyle.display = "none";
  }

  if (pickCodeState) {
  } else {
    codeDropDownStyle.border = "2px solid #DAA520";
  }

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>FEvIR<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: CodeableConcept Element Builder</h2>
        {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
        <div style={{ marginTop: "16px" }} >
          <b>Add from Terminology on FEvIR: </b>
          <Dropdown
            name="pickcodesystem"
            placeholder="Choose CodeSystem"
            closeOnChange selection clearable search selectOnBlur={false} selectOnNavigation={false}
            style={{ width: "560px", border: "2px solid #00000030" }}
            options={codeSystemOptionsState}
            value={pickCodeSystemState}
            additionLabel={additionLabelState}
            allowAdditions={additionLabelState ? true : false}
            onSearchChange={(e, data) => {
              let searchQuery = data.searchQuery;
              let searchQueryLowerCase = searchQuery.toLowerCase()
              if (searchQueryLowerCase.substring(0, 4) === "http" && searchQueryLowerCase.includes('fevir.net')) {
                let poundSignIndex = searchQuery.lastIndexOf('#');
                if (searchQuery.includes('#') && poundSignIndex < searchQuery.length - 1) {
                  setAdditionLabelState("Load term from URL ");
                } else if (codeSystemUrlDictionaryState[searchQuery]) {
                  setAdditionLabelState(codeSystemUrlDictionaryState[searchQuery] + ": ");
                  setPickCodeState("");
                  setPickCodeSystemEntersPressed(0);
                  setLoadedFromUrl(false);
                } else {
                  setAdditionLabelState("");
                }
              } else {
                setAdditionLabelState("");
              }
            }}

            onAddItem={(e, data) => {
              let url = data.value;
              let poundSignIndex = url.lastIndexOf('#');
              let codeSystem;
              let codeSystemEntry;
              let codeEntry = "";
              setPickCodeSystemEntersPressed(0);
              if (poundSignIndex === -1) {
                if (codeSystemUrlDictionaryState[url]) {
                  codeEntry = "";
                  codeSystem = codeSystemUrlDictionaryState[url];
                  codeSystemEntry = codeSystemDictionaryState[codeSystem];
                }
              } else {
                let codeSystemUrl = url.substring(0, poundSignIndex);
                let code = url.substring(poundSignIndex + 1, url.length);
                if (codeSystemUrlDictionaryState[codeSystemUrl]) {
                  codeEntry = code;
                  codeSystem = codeSystemUrlDictionaryState[codeSystemUrl];
                  codeSystemEntry = codeSystemDictionaryState[codeSystem];
                  setLoadedFromUrl(true);
                }
              }
              if (codeSystem && codeSystemEntry) {
                setPickCodeSystemState(codeSystem);
                importCodesToPickList(globalContext, setPickCodeOptionsState, setPickCodeDictionaryState, codeSystemEntry);
                setPickCodeState(codeEntry);
              } else {
                setPickCodeSystemState("");
              }
            }}
            onChange={(e, data) => {
              if (!(codeSystemOptionsState.length === 1 && codeSystemOptionsState[0].key === "Loading...")) {

                let value = data.value;

                setPickCodeSystemState(value);
                setLoadedFromUrl(false);
                if (value) {
                  if (codeSystemDictionaryState[value]) {
                    let codeSystemEntry = codeSystemDictionaryState[value];
                    importCodesToPickList(globalContext, setPickCodeOptionsState, setPickCodeDictionaryState, codeSystemEntry);
                    setPickCodeState("");
                  }
                } else {
                  //Clears the code drop down
                  setPickCodeState("");
                  setPickCodeDictionaryState(startingPick);
                  setPickCodeOptionsState(startingPickOptions);
                  setLoadedFromUrl(false);
                }
              }
            }}

            onKeyUp={(e) => {
              if (e.key === "Enter") {
                if (loadedFromUrl) {
                  if (pickCodeSystemState && pickCodeState) {
                    if (pickCodeSystemEntersPressed == 0) {
                      setPickCodeSystemEntersPressed(1);
                    } else if (pickCodeDictionaryState[pickCodeState]) {
                      addTermFromFevir();
                    }
                  }
                }
              }
            }}
          />
          <Dropdown
            name="pickcode"
            placeholder="Choose Code"
            closeOnChange selection clearable search selectOnBlur={false} selectOnNavigation={false}
            style={codeDropDownStyle}
            options={pickCodeOptionsState}
            value={pickCodeDictionaryState[pickCodeState] ? pickCodeState : ''}
            onChange={(e, data) => {
              setLoadedFromUrl(false);
              if (!(pickCodeOptionsState.length === 1 && (pickCodeOptionsState[0].key === "Loading..." || pickCodeOptionsState[0].key === "No codes loaded..."))) {
                setPickCodeState(data.value);
              }
            }}
          />
          <Button className={pickCodeButtonClasses} style={pickCodeButtonStyle} content={"+ Add Term"} disabled={pickCodeButtonDisabled}
            onClick={addTermFromFevir}
          />
        </div>
        <h3 style={{ margin: "0px", marginTop: "10px" }}>Coding(s)</h3>
        {fhirEntryState.codings?.map((coding, codingIndex) => {
          return <div key={codingIndex} style={{ border: "2px solid #BBBBBB", borderTop: "1px solid #BBBBBB", borderBottom: "1px solid #BBBBBB", padding: "10px", backgroundColor: "#F0F0F0" }}>
            <b>#{codingIndex + 1}&nbsp;&nbsp;&nbsp;</b>
            <Dropdown
              name="codingpreset"
              placeholder="Preset"
              closeOnChange selection clearable selectOnBlur={false} selectOnNavigation={false} autoComplete="off"
              style={{ width: "15%" }}
              options={presetOptionsState}
              value={coding.presetSelected || ""}

              onChange={(e, data) => {
                if (data.value) {
                  let systemUri = presetDictionaryState[data.value].systemuri;
                  let systemName = presetDictionaryState[data.value].systemname;
                  changeCoding(data.value, codingIndex, "presetSelected");
                  changeCoding(systemUri, codingIndex, "systemUri");
                  changeCoding(systemName, codingIndex, "systemName");
                } else {
                  changeCoding("", codingIndex, "presetSelected");
                  changeCoding("", codingIndex, "systemUri");
                  changeCoding("", codingIndex, "systemName");
                }

              }}
            />
            <TextField style={{ width: "15%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Code System Name'} size="small" variant='outlined' value={coding.systemName || ''} onChange={(e) => { changeCoding(e.target.value, codingIndex, "systemName"); changeCoding("", codingIndex, "presetSelected"); }} />
            <TextField style={{ width: "20%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Code System URI'} size="small" variant='outlined' value={coding.systemUri || ''} onChange={(e) => { changeCoding(e.target.value, codingIndex, "systemUri"); changeCoding("", codingIndex, "presetSelected"); }} />
            <TextField style={{ width: "10%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Code'} size="small" variant='outlined' value={coding.code || ''} onChange={(e) => { changeCoding(e.target.value, codingIndex, "code"); }} />
            <TextField style={{ width: "16%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Display'} size="small" variant='outlined' value={coding.display || ''} onChange={(e) => { changeCoding(e.target.value, codingIndex, "display"); }} />
            <Button className="formButton" style={{ color: "#000000", marginLeft: "10px" }} content={"+ Add Qualifier"} onClick={() => { setFhirEntryState(prevState => { let newCodings = prevState.codings; newCodings[codingIndex]["qualifiers"].push({ "codings": [{ "systemName": "", "systemUri": "", "code": "", "display": "", "systemSelected": "" }], "text": "" }); return { ...prevState, inputChanged: true, codings: newCodings }; }); }} />
            {coding.qualifiers?.map((qualifier, qualifierIndex) => {
              return <div key={{ qualifierIndex }} style={{ marginLeft: "70px", paddingTop: "6px" }}><div style={{ border: "2px solid #BBBBBB", borderTop: "1px solid #BBBBBB", borderBottom: "1px solid #BBBBBB", padding: "10px", backgroundColor: "#E9E9E9" }}>
                <b>Qualifier {qualifierIndex + 1}</b>
                {qualifier.codings?.map((qualifierCoding, qualifierCodingIndex) => {
                  let divStyle = { border: "2px solid #809E80", borderTop: "1px solid #809E80", borderBottom: "1px solid #809E80", padding: "10px", backgroundColor: "#F7F7F7" };
                  if (qualifierCodingIndex == 0) {
                    divStyle["borderTop"] = "2px solid #809E80";
                  }
                  if (qualifierCodingIndex === qualifier.codings.length - 1) {
                    divStyle["borderBottom"] = "2px solid #809E80";
                  }
                  return <div key={qualifierCodingIndex} style={divStyle}>
                    <table className={"noStyleTable"} style={{ padding: "0px", margin: "0px", width: "100%" }}><tbody>
                      <tr>
                        <td style={{ width: "0px", whiteSpace: "nowrap" }}><span style={{ color: "#005400" }}><b>Qualifier {qualifierIndex + 1} Coding #{qualifierCodingIndex + 1}&nbsp;&nbsp;&nbsp;</b></span></td>
                        <td>
                          <Dropdown
                            name="qualifierpreset"
                            placeholder="Preset"
                            closeOnChange selection clearable selectOnBlur={false} 
                            selectOnNavigation={false} autoComplete="off"
                            style={{ width: "17%" }}
                            options={presetOptionsState}
                            value={qualifierCoding.presetSelected || ""}

                            onChange={(e, data) => {
                              if (data.value) {
                                let systemUri = presetDictionaryState[data.value].systemuri;
                                let systemName = presetDictionaryState[data.value].systemname;
                                changeCodingQualifierCoding(systemUri, codingIndex, qualifierIndex, qualifierCodingIndex, "systemUri");
                                changeCodingQualifierCoding(systemName, codingIndex, qualifierIndex, qualifierCodingIndex, "systemName");
                                changeCodingQualifierCoding(data.value, codingIndex, qualifierIndex, qualifierCodingIndex, "presetSelected");
                              } else {
                                changeCodingQualifierCoding("", codingIndex, qualifierIndex, qualifierCodingIndex, "systemUri");
                                changeCodingQualifierCoding("", codingIndex, qualifierIndex, qualifierCodingIndex, "systemName");
                                changeCodingQualifierCoding("", codingIndex, qualifierIndex, qualifierCodingIndex, "presetSelected");
                              }
                            }}
                          />
                          <TextField style={{ width: "17%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Code System Name'} size="small" variant='outlined' value={qualifierCoding.systemName || ''} onChange={(e) => { changeCodingQualifierCoding(e.target.value, codingIndex, qualifierIndex, qualifierCodingIndex, "systemName"); changeCodingQualifierCoding("", codingIndex, qualifierIndex, qualifierCodingIndex, "presetSelected"); }} />
                          <TextField style={{ width: "22%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Code System URI'} size="small" variant='outlined' value={qualifierCoding.systemUri || ''} onChange={(e) => { changeCodingQualifierCoding(e.target.value, codingIndex, qualifierIndex, qualifierCodingIndex, "systemUri"); changeCodingQualifierCoding("", codingIndex, qualifierIndex, qualifierCodingIndex, "presetSelected"); }} />
                          <TextField style={{ width: "10%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Code'} size="small" variant='outlined' value={qualifierCoding.code || ''} onChange={(e) => { changeCodingQualifierCoding(e.target.value, codingIndex, qualifierIndex, qualifierCodingIndex, "code"); }} />
                          <TextField style={{ width: "18%", marginLeft: "10px" }} multiline className="inputField" type='text' label={'Display'} size="small" variant='outlined' value={qualifierCoding.display || ''} onChange={(e) => { changeCodingQualifierCoding(e.target.value, codingIndex, qualifierIndex, qualifierCodingIndex, "display"); }} />
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
                })}
                <Button className="formButton" style={{ color: "#005400", marginTop: "16px" }} content={`+ Add Qualifier ${qualifierIndex + 1} Coding`} onClick={() => { setFhirEntryState(prevState => { let newCodings = prevState.codings; newCodings[codingIndex]["qualifiers"][qualifierIndex]["codings"].push({ "systemName": "", "systemUri": "", "code": "", "display": "", "presetSelected": "" }); return { ...prevState, inputChanged: true, codings: newCodings }; }); }} />
                <br /><br />
                <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={'Qualifier Text'} size="small" variant='outlined' value={qualifier.text || ''} onChange={(e) => { changeCodingQualifierText(e.target.value, codingIndex, qualifierIndex); }} />
              </div></div>
            })}
          </div>
        })}
        <div style={{ marginTop: "10px" }} />
        <Button className="formButton" style={{ color: "#000000" }} content={"+ Add Coding"} onClick={() => { setFhirEntryState(prevState => { let newCodings = prevState.codings; newCodings.push({ "systemName": "", "systemUri": "", "code": "", "display": "", "qualifiers": [] }); return { ...prevState, inputChanged: true, codings: newCodings }; }); }} />
        <div style={{ marginTop: "10px" }} />
        <h3>Text</h3>
        <TextField style={{ width: "100%" }} multiline className="inputField" type='text' label={''} size="small" variant='outlined' value={fhirEntryState.text || ''} onChange={(e) => { setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, text: e.target.value }; }); }} />
        <br /><br />
        <Button className="formButton positive" content="Copy JSON" compact onClick={() => { navigator.clipboard.writeText(fhirOutputState); }} />
        <br />
        <h3>JSON</h3>
        {/*<textarea style={{width: "98%", height: "800px"}} value={fhirOutputState} />*/}
        <TextareaAutosize style={{ width: "98%", color: fhirOutputValidState }} spellCheck="false" value={fhirOutputState} onChange={(e) => { setFhirOutputState(e.target.value); updateJsonInput(e.target.value); }} />
        {/*The div below is a good output, but can't easily detect changes to the content*/}
        {/*<div contentEditable="true" style={{width: "98%", whiteSpace: "pre-wrap", display: "inline-block", backgroundColor: "#FFFFFF", border: "solid 1px #000000", minHeight: "10px"}}>{fhirOutputState}</div>*/}
      </Segment>
    </div>
  );
};

export default CreateCodeableConceptJsonPage;