import React, { useContext, useEffect } from 'react';
import { emptyTextNoData, emptyReasonValueSet, emptyReasonCodeableConceptSet, sectionCodeIntroduction,
   emptyReasonNotStarted, sectionCodeDiscussion, sectionCodeMethods, sectionCodeReferences, 
   sectionCodeCompetingInterests, sectionCodeAcknowledgements, sectionCodeAppendices,
    compositionTypeCodeEvidenceReportPackage } from './CodeSystemLookup';
import { DataEntry } from './DataEntryFormFunctions';
import { DocumentSectionEntry } from './CompositionDocumentBundleFunctions';
import FevirContext from './FevirContext';

const EditIntroduction = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState
}) => {
  return <>
    <h3 id="introduction">Introduction</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        fieldLabel="Introduction" 
        sectionCode={"section[" + sectionIndex + "]:https://fevir.net/resources/CodeSystem/179423#introduction"}
        startingValue={resourceState.section[sectionIndex] || {
          "title": "Introduction",
          "code": sectionCodeIntroduction,
          "text": emptyTextNoData,
          "emptyReason": emptyReasonNotStarted
        }}
        editTitle={true} fixedCode={sectionCodeIntroduction} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditDiscussion = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="discussion">Discussion</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        startingValue={resourceState.section[sectionIndex] || {
          "title": "Discussion",
          "code": sectionCodeDiscussion,
          "text": emptyTextNoData,
          "emptyReason": emptyReasonNotStarted
        }}
        fieldLabel="Discussion"
        sectionCode={"section[" + sectionIndex + "]:https://fevir.net/resources/CodeSystem/179423#discussion"}
        editTitle={true} fixedCode={sectionCodeDiscussion} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditMethods = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="methods">Methods</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        startingValue={resourceState.section[sectionIndex] || {
          "title": "Methods",
          "code": sectionCodeMethods,
          "text": emptyTextNoData,
          "emptyReason": emptyReasonNotStarted
        }}
        fieldLabel="Methods"
        sectionCode={"section[" + sectionIndex + "]:https://fevir.net/resources/CodeSystem/179423#methods"}
        editTitle={true} fixedCode={sectionCodeMethods} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditReferences = ({ resourceState, setResourceState, sectionIndex, previousVersionLoaded,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  const globalContext = useContext(FevirContext);
  return <>
    <h3 id="references">References</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry createCitation={true} globalContext={globalContext}
        previousVersionLoaded={previousVersionLoaded} sectionArrayIndex={sectionIndex}
        fieldLabel="References"
        sectionCode={"section[" + sectionIndex + "]:https://fevir.net/resources/CodeSystem/179423#references"}
        startingValue={resourceState.section[sectionIndex] || {
          "title": "References",
          "code": sectionCodeReferences,
          "text": emptyTextNoData,
          "emptyReason": emptyReasonNotStarted
        }}
        editTitle={true} fixedCode={sectionCodeReferences} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true} entryReferencedResourceTypes={["Citation"]}
        entryStartingResourceType="Citation"
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState} resourceState={resourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
    <br />
  </>
};

const EditCompetingInterests = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="competing-interests">Competing Interests</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        startingValue={resourceState.section[sectionIndex] || {
          "title": "Competing Interests",
          "code":sectionCodeCompetingInterests,
          "text": emptyTextNoData,
          "emptyReason": emptyReasonNotStarted
        }}
        fieldLabel="Competing Interests"
        sectionCode={"section[" + sectionIndex + "]:https://fevir.net/resources/CodeSystem/179423#competing-interests"}
        editTitle={true} fixedCode={sectionCodeCompetingInterests} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditAcknowledgements = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="acknowledgements">Acknowledgements</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        fieldLabel="Acknowledgements"
        sectionCode={"section[" + sectionIndex + "]:https://fevir.net/resources/CodeSystem/179423#acknowledgements"}
        startingValue={resourceState.section[sectionIndex] || {
          "title": "Acknowledgements",
          "code": sectionCodeAcknowledgements,
          "text": emptyTextNoData,
          "emptyReason": emptyReasonNotStarted
        }}
        editTitle={true} fixedCode={sectionCodeAcknowledgements} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EditAppendices = ({ resourceState, setResourceState, sectionIndex,
  adaptationReportState, setAdaptationReportState, setChangeAvailableToSaveState }) => {
  return <>
    <h3 id="appendices">Appendices</h3>
    <div style={{ marginLeft: "24px" }}>
      <DocumentSectionEntry sectionArrayIndex={sectionIndex}
        fieldLabel="Appendices"
        sectionCode={"section[" + sectionIndex + "]:https://fevir.net/resources/CodeSystem/179423#appendices"}
        startingValue={resourceState.section[sectionIndex] || {
          "title": "Appendices",
          "code": sectionCodeAppendices,
          "text": emptyTextNoData,
          "emptyReason": emptyReasonNotStarted
        }}
        editTitle={true} fixedCode={sectionCodeAppendices} editTextDiv={true} editAuthor={true} editOrderedBy={true} editOrderedByStartCollapsed={true}
        entryDeletable={true} entryInstanceDeletable={true} entryEnableCreation={true}
        entryStartCollapsed={true} editEmptyReason={true} emptyReasonValueSet={emptyReasonValueSet}
        emptyReasonStartCollapsed={true}
        emptyReasonCodeableConceptLevelValueSet={emptyReasonCodeableConceptSet}
        editSection={true}
        editableSectionCodes={true} addSectionAllowed={true}
        setResourceState={setResourceState}
        compositionId={resourceState.id} compositionTitle={resourceState.title}
        adaptationReportState={adaptationReportState}
        setAdaptationReportState={setAdaptationReportState}
        setChangeAvailableToSaveState={setChangeAvailableToSaveState} />
    </div>
  </>
};

const EvidenceReportPackageAuthor = ({ resourceState, setResourceState, previousVersionLoaded }) => {

  let defaultType = compositionTypeCodeEvidenceReportPackage;

  useEffect(() => {
    if (resourceState) {
      setResourceState(prevState => {
        return {
          ...prevState,
          "type": prevState.type || defaultType
        };
      });
    }
  }, []);

  return <div style={{ marginTop: "12px" }}>
    <div>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note'
          startEmptyArrayClosed={true} deletableArray={true} startCollapsed={true}
          fieldLabel='Note' startingValue={resourceState.note} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type'
          startCollapsed
          startingValue={resourceState.type} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='category' fieldLabel='Category'
          startCollapsed
          startingValue={resourceState.category} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Reference' elementName='subject' fieldLabel='Subject'
          startCollapsed
          startingValue={resourceState.subject} setResourceState={setResourceState} />
        <DataEntry datatype='Reference' elementName='encounter' fieldLabel='Encounter'
          startCollapsed
          startingValue={resourceState.encounter} setResourceState={setResourceState} />
        <h4>Narrative Summary</h4>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
            startCollapsed
            startingValue={resourceState.text} setResourceState={setResourceState} />
        </div>
      </div>
      {resourceState.section?.length > 6 ? <>
        <EditIntroduction sectionIndex={0} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditDiscussion sectionIndex={1} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditMethods sectionIndex={2} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditReferences sectionIndex={3} previousVersionLoaded={previousVersionLoaded}
          resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditCompetingInterests sectionIndex={4} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditAcknowledgements sectionIndex={5} resourceState={resourceState} setResourceState={setResourceState} />
        <br />
        <EditAppendices sectionIndex={6} resourceState={resourceState} setResourceState={setResourceState} />
      </>
        :
        <>Section element is missing in the JSON, or missing 7 instances.</>
      }
    </div>
  </div>
};

const changeSectionDetailEditSettingsForEvidenceReportPackage = (sectionDetailEditSettings, section) => {

  if (section.code?.coding?.[0]?.system === "https://fevir.net/resources/CodeSystem/179423") {
    if (section.code.coding[0].code === "introduction") {
      sectionDetailEditSettings.editTextDiv = true;
      sectionDetailEditSettings.editTextStatus = false;
      sectionDetailEditSettings.editAuthor = true;
      sectionDetailEditSettings.editFocus = false;
      sectionDetailEditSettings.editOrderedBy = true;
      sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
      sectionDetailEditSettings.entryHeader = "Add Resource(s) to Introduction";
      sectionDetailEditSettings.entryDeletable = true;
      sectionDetailEditSettings.entryInstanceDeletable = true;
      sectionDetailEditSettings.entryEnableCreation = true;
      sectionDetailEditSettings.editEmptyReason = true;
      sectionDetailEditSettings.addSectionAllowed = true;
      sectionDetailEditSettings.editableSectionCodes = true;
    }
    if (section.code.coding[0].code === "discussion") {
      sectionDetailEditSettings.editTextDiv = true;
      sectionDetailEditSettings.editTextStatus = false;
      sectionDetailEditSettings.editAuthor = true;
      sectionDetailEditSettings.editFocus = false;
      sectionDetailEditSettings.editOrderedBy = true;
      sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
      sectionDetailEditSettings.entryHeader = "Add Resource(s) to Discussion";
      sectionDetailEditSettings.entryDeletable = true;
      sectionDetailEditSettings.entryInstanceDeletable = true;
      sectionDetailEditSettings.entryEnableCreation = true;
      sectionDetailEditSettings.editEmptyReason = true;
      sectionDetailEditSettings.addSectionAllowed = true;
      sectionDetailEditSettings.editableSectionCodes = true;
    }
    if (section.code.coding[0].code === "methods") {
      sectionDetailEditSettings.editTextDiv = true;
      sectionDetailEditSettings.editTextStatus = false;
      sectionDetailEditSettings.editAuthor = true;
      sectionDetailEditSettings.editFocus = false;
      sectionDetailEditSettings.editOrderedBy = true;
      sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
      sectionDetailEditSettings.entryHeader = "Add Resource(s) to Methods";
      sectionDetailEditSettings.entryDeletable = true;
      sectionDetailEditSettings.entryInstanceDeletable = true;
      sectionDetailEditSettings.entryEnableCreation = true;
      sectionDetailEditSettings.editEmptyReason = true;
      sectionDetailEditSettings.addSectionAllowed = true;
      sectionDetailEditSettings.editableSectionCodes = true;
    }
    if (section.code.coding[0].code === "references") {
      sectionDetailEditSettings.editTextDiv = true;
      sectionDetailEditSettings.editTextStatus = false;
      sectionDetailEditSettings.editAuthor = true;
      sectionDetailEditSettings.editFocus = false;
      sectionDetailEditSettings.editOrderedBy = true;
      sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
      sectionDetailEditSettings.entryHeader = "Add Citation Resource(s)";
      sectionDetailEditSettings.entryDeletable = true;
      sectionDetailEditSettings.entryInstanceDeletable = true;
      sectionDetailEditSettings.entryEnableCreation = true;
      sectionDetailEditSettings.entryReferencedResourceTypes = ["Citation"];
      sectionDetailEditSettings.entryStartingResourceType = "Citation";
      sectionDetailEditSettings.editEmptyReason = true;
      sectionDetailEditSettings.addSectionAllowed = true;
      sectionDetailEditSettings.editableSectionCodes = true;
      sectionDetailEditSettings.createCitation = true;
    }
    if (section.code.coding[0].code === "competing-interests") {
      sectionDetailEditSettings.editTextDiv = true;
      sectionDetailEditSettings.editTextStatus = false;
      sectionDetailEditSettings.editAuthor = true;
      sectionDetailEditSettings.editFocus = false;
      sectionDetailEditSettings.editOrderedBy = true;
      sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
      sectionDetailEditSettings.entryHeader = "Add Resource(s) to Competing Interests";
      sectionDetailEditSettings.entryDeletable = true;
      sectionDetailEditSettings.entryInstanceDeletable = true;
      sectionDetailEditSettings.entryEnableCreation = true;
      sectionDetailEditSettings.editEmptyReason = true;
      sectionDetailEditSettings.addSectionAllowed = true;
      sectionDetailEditSettings.editableSectionCodes = true;
    }
    if (section.code.coding[0].code === "acknowledgements") {
      sectionDetailEditSettings.editTextDiv = true;
      sectionDetailEditSettings.editTextStatus = false;
      sectionDetailEditSettings.editAuthor = true;
      sectionDetailEditSettings.editFocus = false;
      sectionDetailEditSettings.editOrderedBy = true;
      sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
      sectionDetailEditSettings.entryHeader = "Add Resource(s) to Acknowledgements";
      sectionDetailEditSettings.entryDeletable = true;
      sectionDetailEditSettings.entryInstanceDeletable = true;
      sectionDetailEditSettings.entryEnableCreation = true;
      sectionDetailEditSettings.editEmptyReason = true;
      sectionDetailEditSettings.addSectionAllowed = true;
      sectionDetailEditSettings.editableSectionCodes = true;
    }
    if (section.code.coding[0].code === "appendices") {
      sectionDetailEditSettings.editTextDiv = true;
      sectionDetailEditSettings.editTextStatus = false;
      sectionDetailEditSettings.editAuthor = true;
      sectionDetailEditSettings.editFocus = false;
      sectionDetailEditSettings.editOrderedBy = true;
      sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
      sectionDetailEditSettings.entryHeader = "Add Resource(s) to Appendix";
      sectionDetailEditSettings.entryDeletable = true;
      sectionDetailEditSettings.entryInstanceDeletable = true;
      sectionDetailEditSettings.entryEnableCreation = true;
      sectionDetailEditSettings.editEmptyReason = true;
      sectionDetailEditSettings.addSectionAllowed = true;
      sectionDetailEditSettings.editableSectionCodes = true;
    }
  }
  if (section.code?.text?.includes("references-section")) {
    sectionDetailEditSettings.editTextDiv = true;
    sectionDetailEditSettings.editTextStatus = false;
    sectionDetailEditSettings.editAuthor = true;
    sectionDetailEditSettings.editFocus = false;
    sectionDetailEditSettings.editOrderedBy = true;
    sectionDetailEditSettings.emptyTextDivValue = "[No data.]";
    sectionDetailEditSettings.entryHeader = "Add Citation Resource(s)";
    sectionDetailEditSettings.entryDeletable = true;
    sectionDetailEditSettings.entryInstanceDeletable = true;
    sectionDetailEditSettings.entryEnableCreation = true;
    sectionDetailEditSettings.entryReferencedResourceTypes = ["Citation"];
    sectionDetailEditSettings.entryStartingResourceType = "Citation";
    sectionDetailEditSettings.editEmptyReason = true;
    sectionDetailEditSettings.addSectionAllowed = true;
    sectionDetailEditSettings.editableSectionCodes = true;
    sectionDetailEditSettings.createCitation = true;
  }

  return sectionDetailEditSettings;
}

export {
  EvidenceReportPackageAuthor, EditIntroduction, EditDiscussion, EditMethods,
  EditReferences, EditCompetingInterests, EditAcknowledgements, EditAppendices, changeSectionDetailEditSettingsForEvidenceReportPackage
};