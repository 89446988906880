import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const compositionTextViewChangesToJson = (fhirJson, compositionState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = compositionState.meta;
    fhirJson.language = compositionState.language;
    fhirJson.text = compositionState.text;
    fhirJson.contained = compositionState.contained || "DELETEME";
    fhirJson.extension = compositionState.extension || "DELETEME";
    fhirJson.modifierExtension = compositionState.modifierExtension || "DELETEME";
    fhirJson.url = compositionState.url || "DELETEME";
    fhirJson.identifier = compositionState.identifier || "DELETEME";
    fhirJson.version = compositionState.version || "DELETEME";
    fhirJson.name = compositionState.name || "DELETEME";
    fhirJson.title = compositionState.title || "DELETEME";
    fhirJson.status = compositionState.status || "unknown";
    fhirJson.date = compositionState.date || "DELETEME";
    fhirJson.custodian = compositionState.custodian || {
        "reference": "Organization/118079",
        "type": "Organization",
        "display": "Computable Publishing LLC"
    };
    fhirJson.useContext = compositionState.useContext || "DELETEME";
    fhirJson.note = compositionState.note || "DELETEME";
    fhirJson.author = compositionState.author || "DELETEME";
    fhirJson.relatesTo = compositionState.relatesTo || "DELETEME";
    fhirJson.type = compositionState.type || "DELETEME";
    fhirJson.category = compositionState.category || "DELETEME";
    fhirJson.subject = compositionState.subject || "DELETEME";
    fhirJson.encounter = compositionState.encounter || "DELETEME";
    fhirJson.attester = compositionState.attester || "DELETEME";
    fhirJson.event = compositionState.event || "DELETEME";
    fhirJson.section = compositionState.section || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (compositionState) => {
    let contentArray = compositionState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "Composition";
    let classifiedFoi = compositionState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = compositionState.title || compositionState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta": {
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateCompositionJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let compositionState = formInputsStateRef.current.compositionState;

    let fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = compositionTextViewChangesToJson(fhirJson, compositionState);
        fhirJson = jsonErrorCheck2("Composition", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(compositionState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateCompositionJson };