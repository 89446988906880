import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import submitToFevirServer from './SubmitToFevirServer';
import FevirContext from './FevirContext';

const SRDRProjectImporterPage = ( { useTitle } ) => {

  useTitle("FEvIR®: SRDR+ Project Importer");

  const globalContext = useContext(FevirContext);

  const [srdrEntryState, setSrdrEntryState] = useState({"srdrId": "", "apiToken": null, "loadedApiToken": null, "loadingSubmission": false });

  const changeSrdrEntryState = (newValue, field) => {
    setSrdrEntryState(prevState  => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const importSrdrProject = async () => {
    let srdrId = srdrEntryState.srdrId;
    let apiToken = srdrEntryState.apiToken;
    let loadedApiToken = srdrEntryState.loadedApiToken;

    if (!srdrId) {
      alert("Please enter a SRDR+ project id.");
      return ;
    }
    if (!apiToken) {
      alert("Please enter a SRDR+ API Token.");
      return ;
    }
    changeSrdrEntryState(true, "loadingSubmission");

    try {
      srdrId = parseInt(srdrId).toString();
    } catch {
      return ;
    }

    const body = {
      'functionid': "srdrprojectimport",
      'srdrProjectId': srdrId,
      'srdrApiToken': apiToken,
      'loadedSrdrApiToken': loadedApiToken,
      'idToken': ""
    };
    let response = await submitToFevirServer(globalContext, 120000, body, true, false);
    if (response && response.success) {
      history.push(`/resources/Project/${response.projectFOI}`);
    } else {
      changeSrdrEntryState(false, "loadingSubmission");
      if (response && response.error) {
        alert(response.error);
      } else {
        alert("Error importing SRDR Project."); //PLACEHOLDER
      }
    }
    //REDIRECT to the PROJECT PAGE OR ERROR ALERT    

  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && !globalContext.alertModalState.open) {
      importSrdrProject();
    }
  }

  useEffect(async () => {
    //LOAD API TOKEN FROM DATABASE
    if (globalContext.userState.id && srdrEntryState.apiToken === null) {
      const body = {
        'functionid': "retrieveuserapitoken",
        "systemname": "SRDR+",
        'idToken': ""
      };
      let response = await submitToFevirServer(globalContext, 5000, body, true, false);
      let apiToken = "";
      if (response?.success) {
        apiToken = response.apitoken;
      }
      
      //If it can't load the API token or none is found then it will be an empty string and loadedApiToken is null
      setSrdrEntryState(prevState  => {
        return {
          ...prevState,
          apiToken: apiToken,
          loadedApiToken: apiToken || null
        };
      });
    }
  }, [globalContext.userState]);

  return (
    <div>
    {srdrEntryState.apiToken === null
      &&
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
    }
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
      <br /><br />
      {globalContext.userState.firebaseuid ?
        <>
          <TextField style={{width: "240px"}} className="inputField" type='number' size="small" variant='outlined' 
          label='SRDR+ Project ID' value={srdrEntryState.srdrId} onKeyUp={handleKeyUp} 
          onChange={(e) => { changeSrdrEntryState(e.target.value, "srdrId"); } } />
          <br/><br/>
          <TextField style={{width: "60%"}} className="inputField" 
          type={srdrEntryState.apiToken === srdrEntryState.loadedApiToken ? "password" : "text"}
          autoComplete="off"
          InputProps={{
              autoComplete: "off"
          }}
          /*inputMode="tel"*/
          size="small" variant='outlined' placeholder='SRDR+ API Token'
          value={srdrEntryState.apiToken}
          onChange={(e) => { changeSrdrEntryState(e.target.value, "apiToken"); } } />
          <br/><br/>
          <Button className="formButton positive" content="Import Project" compact 
          onClick={importSrdrProject} />
          <br/><br/>
          <img className={srdrEntryState.loadingSubmission ? 'visible' : 'invisible'} style={{height: "22px"}} 
          src="/spinner.gif" alt="Loading" />
          <br/>
          <div style={{"textAlign": "left", "width": "100%"}}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>All project information loaded as FHIR Resources will be publicly available.</span>
          </div>
        </>
          :
            <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
      </Segment>
    </div>
  );
};

  export default SRDRProjectImporterPage;