import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const planDefinitionTextViewChangesToJson = (fhirJson, planDefinitionState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = planDefinitionState.meta;
    fhirJson.language = planDefinitionState.language;
    fhirJson.text = planDefinitionState.text;
    fhirJson.contained = planDefinitionState.contained || "DELETEME";
    fhirJson.extension = planDefinitionState.extension || "DELETEME";
    fhirJson.modifierExtension = planDefinitionState.modifierExtension || "DELETEME";
    fhirJson.url = planDefinitionState.url || "DELETEME";
    fhirJson.identifier = planDefinitionState.identifier || "DELETEME";
    fhirJson.version = planDefinitionState.version || "DELETEME";
    fhirJson.versionAlgorithmString = planDefinitionState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = planDefinitionState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = planDefinitionState.name || "DELETEME";
    fhirJson.title = planDefinitionState.title || "DELETEME";
    fhirJson.subtitle = planDefinitionState.subtitle || "DELETEME";
    fhirJson.type = planDefinitionState.type || "DELETEME";
    fhirJson.status = planDefinitionState.status || "unknown";
    fhirJson.experimental = planDefinitionState.experimental || "DELETEME";
    if (planDefinitionState.experimental === false) {
        fhirJson.experimental = false;
    }
    fhirJson.date = planDefinitionState.date || "DELETEME";
    fhirJson.publisher = planDefinitionState.publisher || "DELETEME";
    fhirJson.contact = planDefinitionState.contact || "DELETEME";
    fhirJson.description = planDefinitionState.description || "DELETEME";
    fhirJson.useContext = planDefinitionState.useContext || "DELETEME";
    fhirJson.jurisdiction = planDefinitionState.jurisdiction || "DELETEME";
    fhirJson.purpose = planDefinitionState.purpose || "DELETEME";
    fhirJson.usage = planDefinitionState.usage || "DELETEME";
    fhirJson.copyright = planDefinitionState.copyright || "DELETEME";
    fhirJson.copyrightLabel = planDefinitionState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = planDefinitionState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = planDefinitionState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = planDefinitionState.effectivePeriod || "DELETEME";
    fhirJson.topic = planDefinitionState.topic || "DELETEME";
    fhirJson.author = planDefinitionState.author || "DELETEME";
    fhirJson.editor = planDefinitionState.editor || "DELETEME";
    fhirJson.reviewer = planDefinitionState.reviewer || "DELETEME"
    fhirJson.endorser = planDefinitionState.endorser || "DELETEME";
    fhirJson.relatedArtifact = planDefinitionState.relatedArtifact || "DELETEME";
    fhirJson.subjectCodeableConcept = planDefinitionState.subjectCodeableConcept || "DELETEME";
    fhirJson.subjectReference = planDefinitionState.subjectReference || "DELETEME";
    fhirJson.subjectCanonical = planDefinitionState.subjectCanonical || "DELETEME";
    fhirJson.library = planDefinitionState.library || "DELETEME";
    fhirJson.goal = planDefinitionState.goal || "DELETEME";
    fhirJson.actor = planDefinitionState.actor || "DELETEME";
    fhirJson.action = planDefinitionState.action || "DELETEME";
    fhirJson.asNeededBoolean = planDefinitionState.asNeededBoolean ?? "DELETEME";
    fhirJson.asNeededCodeableConcept = planDefinitionState.asNeededCodeableConcept || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (planDefinitionState) => {
    let contentArray = planDefinitionState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "PlanDefinition";
    let classifiedFoi = planDefinitionState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = planDefinitionState.title || planDefinitionState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdatePlanDefinitionJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let planDefinitionState = formInputsStateRef.current.planDefinitionState;

    let fhirJson = jsonErrorCheck2("PlanDefinition", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = planDefinitionTextViewChangesToJson(fhirJson, planDefinitionState);
        fhirJson = jsonErrorCheck2("PlanDefinition", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(planDefinitionState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdatePlanDefinitionJson };