import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, TextArea, Dimmer, Loader } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import GenericToggleButtons from './GenericToggleButtons';
import { submitBulkResources } from './ResourceFunctions';
import FevirContext from './FevirContext';

const JsonBulkEntryPage = ( { useTitle } ) => {
  useTitle("FEvIR Resource JSON Bulk Entry");
  const globalContext = useContext(FevirContext);

  const [fhirEntryState, setFhirEntryState] = useState({"fhirBulkEntry": "", "status": "active", "tool": "", "projectFoi": ""});

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState  => {
      return {
        ...prevState,
        //fhirBulkEntry: newValue,
        [field]: newValue
      };
    });
  };

  useEffect( async () => {  }, []);


  const history = useHistory();

  return (
    <div>
      {fhirEntryState.bulkSubmitting &&
        <Dimmer className={"loadingDimmer"} active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      }
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <h3 style={{textAlign: "center", color: "#AC3B61", marginTop: "2px", marginBottom: "0px"}}>Resource JSON Bulk Entry (Limit of 1,000 resources at a time)</h3>
      {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
      <br />
      {globalContext.userState.firebaseuid ?
        <>
          <TextArea placeholder='Paste your array of JSON resources here' style={{ minHeight: 300, width: "60%" }} 
          value={fhirEntryState.fhirBulkEntry} onChange={(e) => { changeFhirEntryState(e.target.value, "fhirBulkEntry"); } } />
          <br/><br/>
          <TextField style={{width: "300px"}} className="inputField" type='number' label={'Project FOI (optional)'} size="small" 
          variant='outlined' value={fhirEntryState.projectFoi} 
          onChange={(e) => { changeFhirEntryState(e.target.value, "projectFoi"); } } />
          <br /><br />
          {/*<TextField style={{width: "500px"}} multiline className="inputField" type='text' label={'Tool (optional)'} size="small" variant='outlined' value={fhirEntryState.tool} onChange={(e) => { changeFhirEntryState(e.target.value, "tool"); } } />
          <br /><br />*/}
          {/* Move this into a module because it's used for both JsonEntryPage and ResourceDisplayPage */}
          <GenericToggleButtons thin={true} boxes={true} togglable={false}
              values={["active", "draft"]} labels={["Publish (anyone can view)", "Draft (only editors can view)"]} fieldLabel={"status"} elementName={"status"}
              startingValue={fhirEntryState.status} setter={setFhirEntryState}
          />
          <br />
          <br />
          <Button className="formButton positive" content="Submit" compact
            onClick={async () => {
              changeFhirEntryState(true, "bulkSubmitting");
              let fois = await submitBulkResources(globalContext, history, fhirEntryState, false);
              changeFhirEntryState(false, "bulkSubmitting");
            } }
            />
        </>
          :
            <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
      </Segment>
    </div>
  );
};

  export default JsonBulkEntryPage;