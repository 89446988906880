import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, Dimmer, Loader, Input } from 'semantic-ui-react';
import { TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { MAGICfile1, MAGICfile2 } from './MAGICfiles';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const MAGICtoFEvIRConverterPage = ({ useTitle }) => {

    useTitle("FEvIR MAGIC-to-FEvIR Converter");

    const [fhirEntryState, setFhirEntryState] = useState({ "magicIdInput": "", "magic": "", "magicFileUploaded": "" });
    const [submittingResources, setSubmittingResources] = useState(false);

    const history = useHistory();
    const globalContext = useContext(FevirContext);

    const magicSubmitResourcesToProject = async (history) => {

        let magicIdInput = fhirEntryState.magicIdInput.trim();
        
        let MAGICfile;
        let magicId;
        if (fhirEntryState.magic === "fileone") {
            MAGICfile = MAGICfile1;
        } else if (fhirEntryState.magic === "filetwo") {
            MAGICfile = MAGICfile2;
        } else if (fhirEntryState.magicFileUploaded) {
            MAGICfile = fhirEntryState.magicFileUploaded;
        } else if (magicIdInput) {
        } else {
            //alert("Please select a MAGIC file to process.");
            alert("Please input a MAGIC Id.");
            return;
        }
        if (!magicIdInput && MAGICfile?.shortCode && MAGICfile.sections?.[0].sectionId) {
            magicId = MAGICfile.shortCode + "-" + MAGICfile.sections[0].sectionId;
        }
        setSubmittingResources(true);
        const body = {
            'functionid': "submitconvertmagictofevir",
            'idToken': "",
            //'description': description,
            //'status': status,
            'magicfile': MAGICfile,
            'magicId': magicIdInput || magicId
        };
        let response = await submitToFevirServer(globalContext, 120000, body, true, false);
        if (response?.success && response.conversionReportFoiCreated) {
            history.push(`/resources/Composition/${response.conversionReportFoiCreated}`);
            setSubmittingResources(false);
            return { 'success': true };
        } else {
            setSubmittingResources(false);
            alert("Unsuccessful. Perhaps not a MAGIC JSON file.");
            return;
        }
    };

    let fileDictionary = {"fileone": "SGLT2 inhibitors example", "filetwo": "GLP-1 receptor agonists example"}

    return (
        <div>
            {submittingResources &&
                <Dimmer className={"loadingDimmer"} active inverted>
                    <Loader inverted>Submitting</Loader>
                </Dimmer>
            }
            <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
                <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Computable Publishing<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: MAGIC-to-FEvIR Converter</h2>
                <br />
                {globalContext.userState.firebaseuid ?
                    <>
                        <br/>
                        <TextField style={{width: "480px"}} className="inputField" type='text' label={'MAGIC Id'} size="small" variant='outlined' value={fhirEntryState.magicIdInput} onChange={(e) => { setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, magicIdInput: e.target.value, magic: "", magicFileUploaded: "" }; }); }} />
                        <br/>
                        <br/>
{/**
 * The file-upload model was not working, discovered on 8/23/2024, so is commented out pending determination of user need before further investigation.

                        <ToggleButtonGroup
                            className="genericToggleButtonGroup medium"
                            exclusive
                            value={fhirEntryState.magic || ""}
                            aria-label="magic"
                            name="magic"
                            onClick={(e) => {
                                let value = e.target.value;
                                if (fhirEntryState.magic === value) {
                                    value = "";
                                }
                                setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, magic: value, magicFileUploaded: "", magicIdInput: "" };
                            }); }}
                            >
                            {["fileone", "filetwo"].map((file, fileIndex) => {
                                let label = fileDictionary[file];
                                return <ToggleButton key={fileIndex} className="genericToggleButton" color="primary"
                                aria-label={label} value={file}
                                >{label}</ToggleButton>
                            })}
                        </ToggleButtonGroup>
                        <br/>
                        <br/>
                        <b>Or upload your own MAGIC file </b>
                        <Input id="inputMagicFile"
                            type="file"
                            onChange={(e, data) => {
                                if (e.target.files.length > 0) {
                                    let file = e.target.files[0];
                                    try {
                                        let reader = new FileReader();
                                        reader.readAsText(file);

                                        reader.onload = function () {
                                            if (reader.result.length < 70000000) {
                                                try {
                                                    let magicJson = JSON.parse(reader.result);
                                                    setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, magic: "", magicFileUploaded: magicJson, magicIdInput: "" }; });
                                                } catch (e) {
                                                    setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, magic: "", magicFileUploaded: "", magicIdInput: "" }; });
                                                    alert("Not a valid JSON file.");                                                    
                                                }
                                            } else {
                                                alert("We only support attaching files that are 50 MB or less. If your file is, then please email support@computablepublishing.com");
                                            }
                                        };
                                        reader.onerror = function (error) {
                                            console.log('Error, ', error);
                                        };
                                    } catch (e) {
                                        alert("File could not be processed.");
                                    }
                                }
                            }}
                        />
                        <br /><br />
 */}

                        <Button className="formButton positive" content="Convert MAGIC to FEvIR" compact onClick={() => { magicSubmitResourcesToProject(history); }} />
                        <br />
                    </>
                    :
                    <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
                }
            </Segment>
        </div>
    );
};

export default MAGICtoFEvIRConverterPage;