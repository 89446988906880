import { jsonErrorCheck2 } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const characteristicTextViewChangesToJson = (fhirJson, characteristicResourceState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.resourceType = "Characteristic";
    fhirJson.contained = characteristicResourceState.contained || "DELETEME";
    fhirJson.extension = characteristicResourceState.extension || "DELETEME";
    fhirJson.modifierExtension = characteristicResourceState.modifierExtension || "DELETEME";
    fhirJson.url = characteristicResourceState.url || "DELETEME";
    fhirJson.language = characteristicResourceState.language || "DELETEME";
    fhirJson.identifier = characteristicResourceState.identifier || "DELETEME";
    fhirJson.version = characteristicResourceState.version || "DELETEME";
    fhirJson.versionAlgorithmString = characteristicResourceState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = characteristicResourceState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = characteristicResourceState.name || "DELETEME";
    fhirJson.title = characteristicResourceState.title || "DELETEME";
    fhirJson.status = characteristicResourceState.status || "unknown";
    fhirJson.experimental = characteristicResourceState.experimental || "DELETEME";
    fhirJson.date = characteristicResourceState.date || "DELETEME";
    fhirJson.publisher = characteristicResourceState.publisher || "DELETEME";
    fhirJson.contact = characteristicResourceState.contact || "DELETEME";
    fhirJson.description = characteristicResourceState.description || "DELETEME";
    fhirJson.note = characteristicResourceState.note || "DELETEME";
    fhirJson.useContext = characteristicResourceState.useContext || "DELETEME";
    fhirJson.jurisdiction = characteristicResourceState.jurisdiction || "DELETEME";
    fhirJson.purpose = characteristicResourceState.purpose || "DELETEME";
    fhirJson.usage = characteristicResourceState.usage || "DELETEME";
    fhirJson.copyright = characteristicResourceState.copyright || "DELETEME";
    fhirJson.copyrightLabel = characteristicResourceState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = characteristicResourceState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = characteristicResourceState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = characteristicResourceState.effectivePeriod || "DELETEME";
    fhirJson.topic = characteristicResourceState.topic || "DELETEME";
    fhirJson.author = characteristicResourceState.author || "DELETEME";
    fhirJson.editor = characteristicResourceState.editor || "DELETEME";
    fhirJson.reviewer = characteristicResourceState.reviewer || "DELETEME"
    fhirJson.endorser = characteristicResourceState.endorser || "DELETEME";
    fhirJson.relatedArtifact = characteristicResourceState.relatedArtifact || "DELETEME";
    fhirJson.exclude = characteristicResourceState.exclude || "DELETEME";
    fhirJson.definitionReference = characteristicResourceState.definitionReference || "DELETEME";
    fhirJson.definitionUri = characteristicResourceState.definitionUri || "DELETEME";
    fhirJson.definitionCodeableConcept = characteristicResourceState.definitionCodeableConcept || "DELETEME";
    fhirJson.definitionByTypeAndValue = characteristicResourceState.definitionByTypeAndValue || "DELETEME";
    fhirJson.definitionByCombination = characteristicResourceState.definitionByCombination || "DELETEME";
    fhirJson.instancesQuantity = characteristicResourceState.instancesQuantity || "DELETEME";
    fhirJson.instancesRange = characteristicResourceState.instancesRange || "DELETEME";
    fhirJson.durationQuantity = characteristicResourceState.durationQuantity || "DELETEME";
    fhirJson.durationRange = characteristicResourceState.durationRange || "DELETEME";
    fhirJson.timeFromEvent = characteristicResourceState.timeFromEvent || "DELETEME";
    fhirJson.expression = characteristicResourceState.expression || "DELETEME";
    fhirJson.executableExpression = characteristicResourceState.executableExpression || "DELETEME";
    for (const key in fhirJson) {
        if (fhirJson[key] === "DELETEME" || (typeof fhirJson[key] === "object" && Object.keys(fhirJson[key]).length === 0) || fhirJson[key] === "" || fhirJson[key] === undefined || fhirJson[key] === null) {
            delete fhirJson[key];
        }
    }
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (characteristicResourceState) => {
    let contentArray = characteristicResourceState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "Characteristic";
    let classifiedFoi = characteristicResourceState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = characteristicResourceState.title || characteristicResourceState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateCharacteristicResourceJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let characteristicResourceState = formInputsStateRef.current.characteristicResourceState;
    let fhirJson = jsonErrorCheck2("Characteristic", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    if (fhirEntryState.activeIndex === 0) {
        fhirJson = characteristicTextViewChangesToJson(fhirJson, characteristicResourceState);
        fhirJson = jsonErrorCheck2("Characteristic", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(characteristicResourceState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateCharacteristicResourceJson };