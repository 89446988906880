import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import scrollTo from './ScrollTo';
import { generateCitation } from './DataEntryFormFunctions';

const getAdaptationComponentValuesFromFhirJson = (fhirEntryState, adaptationDetailState) => {
  //For the Adaptation Builder/Viewer in the Adaptation Detail tab
  //modified to support the Comparison Builder/Viewer in the Comparison Detail tab
  //Gets the values from the FHIR JSON for a single component and returns the values to be used for the adaptationDetailState for display/editing in the Adaptation Detail tab. It doesn't set the state in this function.

  let update = false; //Maybe make a better variable name

  let componentJson = fhirEntryState.characteristicSelected || adaptationDetailState.content;
  let adaptationDetailValues = adaptationDetailState || { "loaded": false };
  if (adaptationDetailState.editChanged) {
    componentJson = adaptationDetailState.content;
    update = true;
  }

  if (adaptationDetailValues.loaded === false || adaptationDetailValues.loaded === undefined) {
    update = true;
  }

  let aDpath = "";
  let aDcomponentType = "";
  let aDdatatype = "";
  let aDdatatypeClassifier = [];
  let aDoriginalSummary = "";
  let aDoriginalJSON = "";
  let comparisonOriginalSummary = "";
  let comparisonOriginalJSON = "";
  let comparisonDatatype = "";
  let comparisonDatatypeClassifier = [];
  let aDrationaleSummary = "";
  let aDchangeSuggestedClassifier = "";
  let aDchangeSuggestedClassifierClassifier = [];
  let comparisonRatingSummary = "";
  let comparisonRatingClassifier = "";
  let comparisonRatingClassifierClassifier = [];
  let aDrelatedArtifact = [];
  let aDsuggestedChangeSummary = "";
  let aDchangeAcceptedClassifier = "";
  let aDchangeAcceptedClassifierClassifier = [];
  let aDcontainedElementSummary = "";
  let aDcontainedExpanded = [];
  let aDfreeToShare = "";
  let subComponents;

  if (JSON.stringify(adaptationDetailState.pathIndexes) !== JSON.stringify(fhirEntryState.conceptPathIndexes)) {
    update = true;
    if (componentJson) {
      if (componentJson.path) {
        aDpath = componentJson.path;
      }
      if (componentJson.type) {
        aDcomponentType = componentJson.type.coding[0].code;
      }
      if (componentJson.classifier?.length > 0) {
        if (componentJson.classifier[0]?.coding?.length > 0) {
          aDdatatype = componentJson.classifier[0].coding[0].code;
        }
        aDdatatypeClassifier = componentJson.classifier;
      }
      if (componentJson.freeToShare === true) {
        aDfreeToShare = true;
      }
      if (componentJson.freeToShare === false) {
        aDfreeToShare = false;
      }
      if (componentJson.component) {
        for (const component of componentJson.component) {
          if (component.type.coding[0].code === "OriginalResourceContent") {
            if (component.summary) {
              aDoriginalSummary = component.summary;
            }
            if (component.component && component.component[0].type.coding[0].code === "OriginalResourceContentJSON") {
              aDoriginalJSON = component.component[0].summary;
              if (component.component && component.component.length > 1 &&
                component.component[1].type.coding[0].code === "ComparedResourceContent") {
                if (component.component[1].classifier?.length > 0) {
                  if (component.component[1].classifier[0]?.coding?.length > 0) {
                    comparisonDatatype = component.component[1].classifier[0].coding[0].code;
                  }
                  comparisonDatatypeClassifier = component.component[1].classifier;
                }
                if (component.component[1].summary) {
                  comparisonOriginalSummary = component.component[1].summary;
                }
                if (component.component[1].component && component.component[1].component[0] &&
                  component.component[1].component[0].type.coding[0].code === "OriginalResourceContentJSON") {
                  comparisonOriginalJSON = component.component[1].component[0].summary;
                }
              }
            } else if (component.component && component.component.length === 1 &&
              component.component[0].type.coding[0].code === "ComparedResourceContent") {
              if (component.component[0].classifier?.length > 0) {
                if (component.component[0].classifier[0]?.coding?.length > 0) {
                  comparisonDatatype = component.component[0].classifier[0].coding[0].code;
                }
                comparisonDatatypeClassifier = component.component[0].classifier;
              }
              if (component.component[0].summary) {
                comparisonOriginalSummary = component.component[0].summary;
              }
              if (component.component[0].component && component.component[0].component[0] &&
                component.component[0].component[0].type.coding[0].code === "OriginalResourceContentJSON") {
                comparisonOriginalJSON = component.component[0].component[0].summary;
              }
            }
          }
          if (component.type.coding[0].code === "RationaleForChange") {
            if (component.summary) {
              aDrationaleSummary = component.summary;
            }
            if (component.classifier) {
              try {
                aDchangeSuggestedClassifier = component.classifier[0].coding[0].code;
                aDchangeSuggestedClassifierClassifier = component.classifier;
              } catch { }
            }
            if (component.relatedArtifact) {
              aDrelatedArtifact = component.relatedArtifact;
            }
          }
          if (component.type.coding[0].code === "ComparisonOfResources") {
            if (component.summary) {
              comparisonRatingSummary = component.summary;
            }
            if (component.classifier) {
              try {
                comparisonRatingClassifier = component.classifier[0].coding[0].code;
                comparisonRatingClassifierClassifier = component.classifier;
              } catch { }
            }
            if (component.component) {
              if (component.component[0].type.coding[0].code === "RationaleForChange") {
                if (component.component[0].summary) {
                  aDrationaleSummary = component.component[0].summary;
                }
                if (component.component[0].classifier) {
                  try {
                    aDchangeSuggestedClassifier = component.component[0].classifier[0].coding[0].code;
                    aDchangeSuggestedClassifierClassifier = component.component[0].classifier;
                  } catch { }
                }
                if (component.component[0].relatedArtifact) {
                  aDrelatedArtifact = component.component[0].relatedArtifact;
                }
              }
            }
          }
          if (component.type.coding[0].code === "SuggestedChange") {
            if (component.summary) {
              aDsuggestedChangeSummary = component.summary;
            }
            if (component.classifier) {
              try {
                aDchangeAcceptedClassifier = component.classifier[0].coding[0].code;
                aDchangeAcceptedClassifierClassifier = component.classifier;
              } catch { }
            }
          }
          if (component.type.coding[0].code === "ContainedElementAdaptation") {
            if (component.summary) {
              aDcontainedElementSummary = component.summary;
            }
            if (component.component) {
              aDcontainedExpanded = component.component;
            }
          }
        }
      }
    }
  }
  if (componentJson === undefined && fhirEntryState.fhirEntryString) {
    let json = JSON.parse(fhirEntryState.fhirEntryString);
    if (json.component?.length > 0) {
      subComponents = json.component;
    }
    componentJson = fhirEntryState.fhirEntryString;
  }

  if (update) {
    adaptationDetailValues = {
      loaded: true,
      aDpath: aDpath,
      aDcomponentType: aDcomponentType,
      aDdatatype: aDdatatype,
      aDdatatypeClassifier: aDdatatypeClassifier,
      aDoriginalSummary: aDoriginalSummary,
      aDoriginalJSON: aDoriginalJSON,
      aDrationaleSummary: aDrationaleSummary,
      aDchangeSuggestedClassifier: aDchangeSuggestedClassifier,
      aDchangeSuggestedClassifierClassifier: aDchangeSuggestedClassifierClassifier,
      aDrelatedArtifact: aDrelatedArtifact,
      aDsuggestedChangeSummary: aDsuggestedChangeSummary,
      aDchangeAcceptedClassifier: aDchangeAcceptedClassifier,
      aDchangeAcceptedClassifierClassifier: aDchangeAcceptedClassifierClassifier,
      aDcontainedElementSummary: aDcontainedElementSummary,
      aDcontainedExpanded: aDcontainedExpanded,
      aDfreeToShare: aDfreeToShare,
      comparisonDatatype: comparisonDatatype,
      comparisonDatatypeClassifier: comparisonDatatypeClassifier,
      comparisonOriginalSummary: comparisonOriginalSummary,
      comparisonOriginalJSON: comparisonOriginalJSON,
      comparisonRatingSummary: comparisonRatingSummary,
      comparisonRatingClassifier: comparisonRatingClassifier,
      comparisonRatingClassifierClassifier: comparisonRatingClassifierClassifier,
      subComponents: subComponents,
      pathIndexes: fhirEntryState.conceptPathIndexes,
      termChanged: false,
      content: componentJson,
      editChanged: false
    };
  }
  return adaptationDetailValues;
}

const navigateToAnotherAdaptationItem = async (history, fhirEntryState, setFhirEntryState, resourceType, resourceId, json,
  characteristic, display, path, pathIndexes, pathString, formInputsStateRef, changeFhirEntryState, submitUpdatedFhirResource,
  globalContext, changeFormState, addToast) => {

  let priorAdaptationDetailState;
  if (formInputsStateRef?.current) {
    priorAdaptationDetailState = formInputsStateRef.current.adaptationDetailState;
  }

  let navigateAway = true;
  let submitResult;
  let submittingToServer = false;

  if (fhirEntryState.conceptDelete) {
    alert("Please click the 'Update' button before navigating away. Or refresh browser to discard changes.")
  } else {
    if (fhirEntryState.editMode && (fhirEntryState.adaptationUnitChanged || priorAdaptationDetailState?.editChanged)) {
      if (window.confirm("Do you want to save your change to this item to the server? If not, please refresh this page to discard changes.")) {
        submitResult = await submitUpdatedFhirResource(null, "ArtifactAssessment", resourceId, formInputsStateRef, fhirEntryState,
          setFhirEntryState, globalContext, history, changeFormState, false, addToast);
        if (submitResult) {
          submittingToServer = true;
        }
      } else {
        navigateAway = false;
      }

    }

    if (navigateAway) {
      let characteristicCode = "";
      let characteristicSummary = "";
      let scrollToId = "";
      if (characteristic) {
        if (characteristic.path) {
          scrollToId = "characteristicdetail-navigation-code-" + characteristic.path[0];
        }
        if (characteristic.summary) {
          characteristicSummary = characteristic.summary;
        }
      }

      if (pathIndexes?.length > 0) {
        history.push(`/resources/${resourceType}/${resourceId}#${pathIndexes.toString()}`);
        if (submittingToServer) {
          //changeFormState(false, "hashLoaded");
        }
      } else {
        //characteristic = json;
        history.push(`/resources/${resourceType}/${resourceId}`);
        //history.push(`/resources/${resourceType}/${resourceId}#TOP`);
      }

      let classifierCodes = [];
      let notes = [];

      setFhirEntryState(prevState => {
        return {
          ...prevState, submittingToServer: submittingToServer,
          conceptName: display, conceptPath: path, conceptPathIndexes: pathIndexes, conceptPathString: pathString,
          characteristicSelected: characteristic, conceptSummary: characteristicSummary,
          characteristicCode: characteristicCode, classifierCodes: classifierCodes, notes: notes,
          termChanged: false, newChildConcepts: []
        };
      });

      scrollTo("navigationcharacteristicDetailSegment", scrollToId, true);
      scrollTo("resourceTextViewSegment", "", true);
    }
  }
}

const adaptationTextViewChangesToJson = (fhirJson, adaptationState) => {
  if (fhirJson === undefined) {
    return;
  }
  fhirJson.resourceType = "ArtifactAssessment";
  fhirJson.meta = adaptationState.meta;
  fhirJson.language = adaptationState.language;
  fhirJson.text = adaptationState.text;
  fhirJson.contained = adaptationState.contained || "DELETEME";
  fhirJson.extension = adaptationState.extension || "DELETEME";
  fhirJson.modifierExtension = adaptationState.modifierExtension || "DELETEME";
  fhirJson.language = adaptationState.language || "DELETEME";
  fhirJson.identifier = adaptationState.identifier || "DELETEME";
  fhirJson.title = adaptationState.title || "DELETEME";
  fhirJson.date = adaptationState.date || "DELETEME";
  fhirJson.copyright = adaptationState.copyright || "DELETEME";
  fhirJson.approvalDate = adaptationState.approvalDate || "DELETEME";
  fhirJson.lastReviewDate = adaptationState.lastReviewDate || "DELETEME";
  fhirJson.artifactReference = adaptationState.artifactReference || "DELETEME";
  fhirJson.artifactCanonical = adaptationState.artifactCanonical || "DELETEME";
  fhirJson.artifactUri = adaptationState.artifactUri || "DELETEME";
  fhirJson.content = adaptationState.content || "DELETEME"
  fhirJson.workflowStatus = adaptationState.workflowStatus || "DELETEME"
  fhirJson.disposition = adaptationState.disposition || "DELETEME"

  deleteEmptyElementsInObjectRecursive(fhirJson);
  let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
  fhirJson = fhirJsonWithCiteAs;
  return fhirJson;
}

const adaptationDetailViewChangesToJson = (adaptationDetailState) => {
  let aDpath = adaptationDetailState.aDpath || "";
  let aDcomponentType = adaptationDetailState.aDcomponentType || "";
  let aDdatatype = adaptationDetailState.aDdatatype || "";
  let aDdatatypeClassifier = adaptationDetailState.aDdatatypeClassifier || [];
  let aDoriginalSummary = adaptationDetailState.aDoriginalSummary || "";
  let aDoriginalJSON = adaptationDetailState.aDoriginalJSON || "";
  let aDrationaleSummary = adaptationDetailState.aDrationaleSummary || "";
  let aDchangeSuggestedClassifier = adaptationDetailState.aDchangeSuggestedClassifier || "";
  let aDchangeSuggestedClassifierClassifier = adaptationDetailState.aDchangeSuggestedClassifierClassifier || [];
  let aDrelatedArtifact = adaptationDetailState.aDrelatedArtifact || [];
  let aDsuggestedChangeSummary = adaptationDetailState.aDsuggestedChangeSummary || "";
  let aDchangeAcceptedClassifier = adaptationDetailState.aDchangeAcceptedClassifier || "";
  let aDchangeAcceptedClassifierClassifier = adaptationDetailState.aDchangeAcceptedClassifierClassifier || [];
  let aDcontainedElementSummary = adaptationDetailState.aDcontainedElementSummary || "";
  let aDcontainedExpanded = adaptationDetailState.aDcontainedExpanded || [];
  let aDfreeToShare = adaptationDetailState.aDfreeToShare || "";
  let comparisonDatatype = adaptationDetailState.comparisonDatatype || "";
  let comparisonDatatypeClassifier = adaptationDetailState.comparisonDatatypeClassifier || [];
  let comparisonOriginalSummary = adaptationDetailState.comparisonOriginalSummary || "";
  let comparisonOriginalJSON = adaptationDetailState.comparisonOriginalJSON || "";
  let comparisonRatingSummary = adaptationDetailState.comparisonRatingSummary || "";
  let comparisonRatingClassifier = adaptationDetailState.comparisonRatingClassifier || "";
  let comparisonRatingClassifierClassifier = adaptationDetailState.comparisonRatingClassifierClassifier || [];

  let aDtype;
  switch (aDcomponentType) {
    case 'PrimitiveElement':
      aDtype = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "PrimitiveElement",
            "display": "Primitive Element"
          }
        ]
      };
      break
    case 'CodingElement':
      aDtype = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "CodingElement",
            "display": "Coding Element"
          }
        ]
      };
      break
    case 'ArrayElement':
      aDtype = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "ArrayElement",
            "display": "Array Element"
          }
        ]
      };
      break
    case 'ObjectElement':
      aDtype = {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "ObjectElement",
            "display": "Object Element"
          }
        ]
      };
      break
    default:
      alert('unrecognized aDcomponentType of ' + aDcomponentType);
      aDtype = { "text": aDcomponentType };
  }

  let detailUnitJson = {
    "type": aDtype,
    "classifier": aDdatatypeClassifier,
    "path": aDpath
  };
  if (typeof aDfreeToShare === "boolean") {
    detailUnitJson['freeToShare'] = aDfreeToShare;
  }
  let comparedResourceContentComponent = "";
  if (comparisonOriginalJSON) {
    comparedResourceContentComponent = {
      "summary": comparisonOriginalSummary,
      "type": {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "ComparedResourceContent",
            "display": "Compared Resource Content"
          }
        ]
      },
      "classifier": comparisonDatatypeClassifier,
      "component": [
        {
          "summary": comparisonOriginalJSON,
          "type": {
            "coding": [
              {
                "system": "https://fevir.net/resources/CodeSystem/112108",
                "code": "OriginalResourceContentJSON",
                "display": "Original Resource Content JSON"
              }
            ]
          }
        }
      ]
    }
  } else if (comparisonOriginalSummary) {
    comparedResourceContentComponent = {
      "summary": comparisonOriginalSummary,
      "type": {
        "coding": [
          {
            "system": "https://fevir.net/resources/CodeSystem/112108",
            "code": "ComparedResourceContent",
            "display": "Compared Resource Content"
          }
        ]
      },
      "classifier": comparisonDatatypeClassifier
    }
  }
  if (comparedResourceContentComponent) {
    if (aDoriginalJSON) {
      detailUnitJson['component'] = [{
        "summary": aDoriginalSummary,
        "type": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "OriginalResourceContent",
              "display": "Original Resource Content"
            }
          ]
        },
        "component": [
          {
            "summary": aDoriginalJSON,
            "type": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }
              ]
            }
          },
          comparedResourceContentComponent
        ]
      }]
    } else {
      detailUnitJson['component'] = [{
        "summary": aDoriginalSummary,
        "type": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "OriginalResourceContent",
              "display": "Original Resource Content"
            }
          ]
        },
        "component": [comparedResourceContentComponent]
      }]
    }
  } else {
    if (aDoriginalJSON) {
      detailUnitJson['component'] = [{
        "summary": aDoriginalSummary,
        "type": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "OriginalResourceContent",
              "display": "Original Resource Content"
            }
          ]
        },
        "component": [
          {
            "summary": aDoriginalJSON,
            "type": {
              "coding": [
                {
                  "system": "https://fevir.net/resources/CodeSystem/112108",
                  "code": "OriginalResourceContentJSON",
                  "display": "Original Resource Content JSON"
                }
              ]
            }
          }
        ]
      }]
    } else {
      detailUnitJson['component'] = [{
        "summary": aDoriginalSummary,
        "type": {
          "coding": [
            {
              "system": "https://fevir.net/resources/CodeSystem/112108",
              "code": "OriginalResourceContent",
              "display": "Original Resource Content"
            }
          ]
        }
      }]
    }
  }

  let rationaleForChangeJson = {};
  if (aDrationaleSummary) {
    rationaleForChangeJson['summary'] = aDrationaleSummary;
  }
  rationaleForChangeJson['type'] = {
    "coding": [
      {
        "system": "https://fevir.net/resources/CodeSystem/112108",
        "code": "RationaleForChange",
        "display": "Rationale for Change to Resource Content"
      }
    ]
  }
  if (aDchangeSuggestedClassifierClassifier) {
    rationaleForChangeJson['classifier'] = aDchangeSuggestedClassifierClassifier;
  }
  if (aDrelatedArtifact) {
    rationaleForChangeJson['relatedArtifact'] = aDrelatedArtifact;
  }
  if (comparedResourceContentComponent) {
    let comparisonOfResourcesJson = {};
    if (comparisonRatingSummary) {
      comparisonOfResourcesJson['summary'] = comparisonRatingSummary;
    }
    comparisonOfResourcesJson['type'] = {
      "coding": [
        {
          "system": "https://fevir.net/resources/CodeSystem/112108",
          "code": "ComparisonOfResources",
          "display": "Comparison of Resources Content"
        }
      ]
    };
    if (comparisonRatingClassifierClassifier) {
      comparisonOfResourcesJson['classifier'] = comparisonRatingClassifierClassifier;
    }
    if (rationaleForChangeJson) {
      comparisonOfResourcesJson["component"] = [rationaleForChangeJson]
    }
    detailUnitJson['component'].push(comparisonOfResourcesJson);
  } else {
    detailUnitJson['component'].push(rationaleForChangeJson);
  }
  let suggestedChangeJson = {};
  if (aDsuggestedChangeSummary) {
    suggestedChangeJson['summary'] = aDsuggestedChangeSummary;
  }
  suggestedChangeJson['type'] = {
    "coding": [
      {
        "system": "https://fevir.net/resources/CodeSystem/112108",
        "code": "SuggestedChange",
        "display": "Suggested Change to Resource Content"
      }
    ]
  };
  if (aDchangeAcceptedClassifierClassifier) {
    suggestedChangeJson['classifier'] = aDchangeAcceptedClassifierClassifier;
  }
  detailUnitJson['component'].push(suggestedChangeJson);
  let containedElementExists = false;
  let containedElementJson = {};
  if (aDcontainedElementSummary) {
    containedElementJson['summary'] = aDcontainedElementSummary;
    containedElementExists = true;
  };
  containedElementJson['type'] = {
    "coding": [
      {
        "system": "https://fevir.net/resources/CodeSystem/112108",
        "code": "ContainedElementAdaptation",
        "display": "Contained Element Adaptation"
      }
    ]
  };
  containedElementJson['path'] = aDpath;
  if (aDcontainedExpanded) {
    containedElementJson['component'] = aDcontainedExpanded;
    containedElementExists = true;
  }
  if (containedElementExists) {
    detailUnitJson['component'].push(containedElementJson);
  }

  return detailUnitJson;
}

const mapToContentComponentIndex = (arrayNumberString, component, contentComponentIndex) => {
  let middleArrayNumber = 0;
  for (const componentItem of component) {
    if (componentItem.path && componentItem.type.coding[0].code === 'ContainedElementAdaptation') {
      if (componentItem.component) {
        let innerArrayNumber = 0;
        for (const detailItem of componentItem.component) {
          if (detailItem.path) {
            let detailItemPathKey = detailItem.path.toString();
            if (!(detailItemPathKey in contentComponentIndex)) {
              contentComponentIndex[detailItemPathKey] = arrayNumberString + '.' + middleArrayNumber.toString() + '.' + innerArrayNumber.toString();
            }
            if (detailItem.component) {
              mapToContentComponentIndex(arrayNumberString + '.' + middleArrayNumber.toString() + '.' + innerArrayNumber.toString(), detailItem.component, contentComponentIndex);
            }
          }
          innerArrayNumber += 1;
        }
      }
    }
    middleArrayNumber += 1;
  }
}

const createContentComponentIndex = (content) => {
  let contentComponentIndex = {};
  let arrayNumber = 0;
  for (const contentItem of content) {
    if (contentItem.path) {
      let contentItemPathKey = contentItem.path.toString();
      if (!(contentItemPathKey in contentComponentIndex)) {
        contentComponentIndex[contentItemPathKey] = arrayNumber.toString();
      }
      if (contentItem.component) {
        mapToContentComponentIndex(arrayNumber.toString(), contentItem.component, contentComponentIndex);
      }
    }
    arrayNumber += 1;
  }
  return contentComponentIndex
}

const createNewClassificationJson = (adaptationState) => {
  let contentArray = adaptationState.newClassifications;
  if (!contentArray) {
    return "";
  }
  let classifiedResourceType = "ArtifactAssessment";
  let classifiedFoi = adaptationState.id;
  let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
  let classifedTitle = adaptationState.title || "[Untitled.]";
  let classificationJson = {
    "resourceType": "ArtifactAssessment",
    "artifactReference": {
      "reference": classifiedReference,
      "type": classifiedResourceType,
      "display": classifedTitle
    },
    "content": contentArray,
    "meta": {
      "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
    }
  };
  return classificationJson;
}

const builderUpdateAdaptationDetailJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let adaptationDetailState = formInputsStateRef.current.adaptationDetailState;
  let newDetailJson = adaptationDetailViewChangesToJson(adaptationDetailState);

  let fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }
  //function to create index of content.component entries by path
  let contentComponentIndex = createContentComponentIndex(fhirJson.content);
  //function to identify the content.component entry matching newDetailJson
  let newDetailPath = contentComponentIndex[newDetailJson.path];
  let newDetailPathSplit = newDetailPath.split('.');
  switch (newDetailPathSplit.length) {
    case 1:
      fhirJson.content[parseInt(newDetailPath)] = newDetailJson;
      break;
    case 3:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])] = newDetailJson;
      break;
    case 5:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])].component[parseInt(newDetailPathSplit[3])]
        .component[parseInt(newDetailPathSplit[4])] = newDetailJson;
      break;
    case 7:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])].component[parseInt(newDetailPathSplit[3])]
        .component[parseInt(newDetailPathSplit[4])].component[parseInt(newDetailPathSplit[5])]
        .component[parseInt(newDetailPathSplit[6])] = newDetailJson;
      break;
    case 9:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])].component[parseInt(newDetailPathSplit[3])]
        .component[parseInt(newDetailPathSplit[4])].component[parseInt(newDetailPathSplit[5])]
        .component[parseInt(newDetailPathSplit[6])].component[parseInt(newDetailPathSplit[7])]
        .component[parseInt(newDetailPathSplit[8])] = newDetailJson;
      break;
    case 11:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])].component[parseInt(newDetailPathSplit[3])]
        .component[parseInt(newDetailPathSplit[4])].component[parseInt(newDetailPathSplit[5])]
        .component[parseInt(newDetailPathSplit[6])].component[parseInt(newDetailPathSplit[7])]
        .component[parseInt(newDetailPathSplit[8])].component[parseInt(newDetailPathSplit[9])]
        .component[parseInt(newDetailPathSplit[10])] = newDetailJson;
      break;
    case 13:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])].component[parseInt(newDetailPathSplit[3])]
        .component[parseInt(newDetailPathSplit[4])].component[parseInt(newDetailPathSplit[5])]
        .component[parseInt(newDetailPathSplit[6])].component[parseInt(newDetailPathSplit[7])]
        .component[parseInt(newDetailPathSplit[8])].component[parseInt(newDetailPathSplit[9])]
        .component[parseInt(newDetailPathSplit[10])].component[parseInt(newDetailPathSplit[11])]
        .component[parseInt(newDetailPathSplit[12])] = newDetailJson;
      break;
    case 15:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])].component[parseInt(newDetailPathSplit[3])]
        .component[parseInt(newDetailPathSplit[4])].component[parseInt(newDetailPathSplit[5])]
        .component[parseInt(newDetailPathSplit[6])].component[parseInt(newDetailPathSplit[7])]
        .component[parseInt(newDetailPathSplit[8])].component[parseInt(newDetailPathSplit[9])]
        .component[parseInt(newDetailPathSplit[10])].component[parseInt(newDetailPathSplit[11])]
        .component[parseInt(newDetailPathSplit[12])].component[parseInt(newDetailPathSplit[13])]
        .component[parseInt(newDetailPathSplit[14])] = newDetailJson;
      break;
    case 17:
      fhirJson.content[parseInt(newDetailPathSplit[0])].component[parseInt(newDetailPathSplit[1])]
        .component[parseInt(newDetailPathSplit[2])].component[parseInt(newDetailPathSplit[3])]
        .component[parseInt(newDetailPathSplit[4])].component[parseInt(newDetailPathSplit[5])]
        .component[parseInt(newDetailPathSplit[6])].component[parseInt(newDetailPathSplit[7])]
        .component[parseInt(newDetailPathSplit[8])].component[parseInt(newDetailPathSplit[9])]
        .component[parseInt(newDetailPathSplit[10])].component[parseInt(newDetailPathSplit[11])]
        .component[parseInt(newDetailPathSplit[12])].component[parseInt(newDetailPathSplit[13])]
        .component[parseInt(newDetailPathSplit[14])].component[parseInt(newDetailPathSplit[15])]
        .component[parseInt(newDetailPathSplit[16])] = newDetailJson;
      break;
    default:
      alert('in too deep')
  }

  if (fhirEntryState.activeIndex === 0) {
    fhirJson = adaptationTextViewChangesToJson(fhirJson, adaptationDetailState);
    fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  return fhirJson;
}

const builderUpdateAdaptationContentJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let adaptationContentState = formInputsStateRef.current.adaptationContentState;
  let newContentJson = adaptationDetailViewChangesToJson(adaptationContentState);

  let fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }

  if (adaptationContentState) {
    fhirJson.content = JSON.parse(JSON.stringify(newContentJson));
    fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  return fhirJson;
}

const builderUpdateAdaptationJson = (resourceId, fhirEntryState, formInputsStateRef) => {
  let adaptationState = formInputsStateRef.current.adaptationState || formInputsStateRef.current.comparisonState;
  let fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
  if (fhirJson === undefined || fhirJson === false) {
    return false;
  }
  if (fhirEntryState.activeIndex === 0) {
    fhirJson = adaptationTextViewChangesToJson(fhirJson, adaptationState);
    fhirJson = jsonErrorCheck2("ArtifactAssessment", resourceId, fhirEntryState.startingVersionId, fhirJson);
    if (fhirJson === undefined || fhirJson === false) {
      return false;
    }
  }
  let newClassificationJson = createNewClassificationJson(adaptationState);

  return [fhirJson, newClassificationJson];
}

const createAdaptedArrayComponentJson = (parentPath, adaptationComponentJson) => {
  let adaptedProductArrayJson = [];
  if (!Array.isArray(adaptationComponentJson)) {
    alert('adaptationComponentJson is not an Array');
    return
  }
  if (adaptationComponentJson.length === 0) {
    alert('adaptationComponentJson has no content');
    return
  }
  for (const componentJson of adaptationComponentJson) {
    let componentType;
    let componentClassifier;
    let componentPath;
    let componentValue = {};
    try {
      componentType = componentJson.type.coding[0].code;
      try {
        componentClassifier = componentJson.classifier[0].coding[0].code;
      } catch { }
      componentPath = componentJson.path[0];
      try {
        componentValue = componentJson.component[0].component[0].summary;
      } catch {
        componentValue = componentJson.component[0].summary;
      }
    } catch { };
    if (componentType === 'PrimitiveElement') {
      if (componentClassifier === 'string-datatype') {
        try {
          if (componentJson.component[2].classifier?.length > 0 && componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                adaptedProductArrayJson.push(componentJson.component[0].component[0].summary);
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary !== "DELETEME") {
              adaptedProductArrayJson.push(componentJson.component[2].summary);
            }
          } else {
            adaptedProductArrayJson.push(componentValue);
          }
        } catch {
          adaptedProductArrayJson.push(componentValue);
        }
      }
      if (componentClassifier === 'number-datatype') {
        adaptedProductArrayJson.push(parseFloat(componentValue));
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                try {
                  adaptedProductArrayJson.push(parseFloat(componentJson.component[0].component[0].summary));
                } catch {
                  adaptedProductArrayJson.push(componentJson.component[0].component[0].summary);
                }
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary !== "DELETEME") {
              adaptedProductArrayJson.push(parseFloat(componentJson.component[2].summary));
            }
          } else {
            adaptedProductArrayJson.push(parseFloat(componentValue));
          }
        } catch {
          adaptedProductArrayJson.push(parseFloat(componentValue));
        }
      }
      if (componentClassifier === 'boolean-datatype') {
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                if (componentJson.component[0].component[0].summary === 'true') {
                  adaptedProductArrayJson.push(true);
                } else if (componentJson.component[0].component[0].summary === 'false') {
                  adaptedProductArrayJson.push(false);
                } else {
                  adaptedProductArrayJson.push(componentJson.component[0].component[0].summary);
                }
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary !== "DELETEME") {
              if (componentJson.component[2].summary === 'true') {
                adaptedProductArrayJson.push(true);
              }
              if (componentJson.component[2].summary === 'false') {
                adaptedProductArrayJson.push(false);
              }
            }
          } else {
            if (componentValue === 'true') {
              adaptedProductArrayJson.push(true);
            }
            if (componentValue === 'false') {
              adaptedProductArrayJson.push(false);
            }
          }
        } catch {
          if (componentValue === 'true') {
            adaptedProductArrayJson.push(true);
          }
          if (componentValue === 'false') {
            adaptedProductArrayJson.push(false);
          }
        }
      }
    }
    if (componentType === 'CodingElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 &&
          componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              let itemToProcess = componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary;
              if (typeof (itemToProcess) === 'object') {
                adaptedProductArrayJson.push(JSON.parse(JSON.stringify(itemToProcess)));
              } else if (typeof (itemToProcess) === 'string') {
                adaptedProductArrayJson.push(JSON.parse(itemToProcess));
              }
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary !== "DELETEME") {
            adaptedProductArrayJson.push(JSON.parse(JSON.stringify(componentJson.component[2].summary)));
          }
        } else {
          adaptedProductArrayJson.push(JSON.parse(componentValue));
        }
      } catch {
        adaptedProductArrayJson.push(JSON.parse(componentValue));
      }
    }
    if (componentType === 'ArrayElement') {
      alert('Array containing Array not allowed');
      return
    }
    if (componentType === 'ObjectElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 &&
          componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              let itemToProcess = componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary;
              if (typeof (itemToProcess) === 'object') {
                adaptedProductArrayJson.push(JSON.parse(JSON.stringify(itemToProcess)));
              } else if (typeof (itemToProcess) === 'string') {
                adaptedProductArrayJson.push(JSON.parse(itemToProcess));
              }
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary !== "DELETEME") {
            if (typeof (componentJson.component[2].summary) === 'object') {
              adaptedProductArrayJson.push(componentJson.component[2].summary);
            } else if (typeof (componentJson.component[2].summary) === 'string') {
              adaptedProductArrayJson.push(JSON.parse(componentJson.component[2].summary));
            }
          }
        } else {
          try {
            if (componentJson.component[3].component?.length > 0) {
              adaptedProductArrayJson.push(createAdaptedObjectComponentJson(componentPath, componentJson.component[3].component));
            } else {
              adaptedProductArrayJson.push(JSON.parse(componentValue));
            }
          } catch {
            adaptedProductArrayJson.push(JSON.parse(componentValue));
          }
        }
      } catch {
        adaptedProductArrayJson.push(JSON.parse(componentValue));
      }
    }
  }
  return adaptedProductArrayJson;
}

const createAdaptedObjectComponentJson = (parentPath, adaptationComponentJson) => {
  let adaptedProductObjectJson = {};
  if (!Array.isArray(adaptationComponentJson)) {
    alert('adaptationComponentJson is not an Array:' + JSON.stringify(adaptationComponentJson));
    return
  }
  if (adaptationComponentJson.length === 0) {
    alert('adaptationComponentJson has no content');
    return
  }
  for (const componentJson of adaptationComponentJson) {
    let componentType;
    let componentClassifier;
    let componentPath;
    let componentValue = {};
    let fullPath;
    try {
      componentType = componentJson.type.coding[0].code;
      try {
        componentClassifier = componentJson.classifier[0].coding[0].code;
      } catch { }
      fullPath = componentJson.path[0];
      componentPath = fullPath.replace(parentPath, '').replace('.', '');
      if (componentPath.split(']').length > 1) {
        if (componentPath.split(']').length === 2) {
          componentPath = componentPath.split('1');
        } else {
          alert('element path contains too many [n] values');
        }
      }
      try {
        componentValue = componentJson.component[0].component[0].summary;
      } catch {
        componentValue = componentJson.component[0].summary;
      }
    } catch { };
    if (componentType === 'PrimitiveElement') {
      if (componentClassifier === 'string-datatype') {
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                adaptedProductObjectJson[componentPath] = componentJson.component[0].component[0].summary;
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary === "DELETEME") {
              delete adaptedProductObjectJson[componentPath];
            } else {
              adaptedProductObjectJson[componentPath] = componentJson.component[2].summary;
            }
          } else {
            adaptedProductObjectJson[componentPath] = componentValue;
          }
        } catch {
          adaptedProductObjectJson[componentPath] = componentValue;
        }
      }
      if (componentClassifier === 'number-datatype') {
        adaptedProductObjectJson[componentPath] = parseFloat(componentValue);
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                try {
                  adaptedProductObjectJson[componentPath] = parseFloat(componentJson.component[0].component[0].summary);
                } catch {
                  adaptedProductObjectJson[componentPath] = componentJson.component[0].component[0].summary;
                }
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary === "DELETEME") {
              delete adaptedProductObjectJson[componentPath];
            } else {
              adaptedProductObjectJson[componentPath] = parseFloat(componentJson.component[2].summary);
            }
          } else {
            adaptedProductObjectJson[componentPath] = parseFloat(componentValue);
          }
        } catch {
          adaptedProductObjectJson[componentPath] = parseFloat(componentValue);
        }
      }
      if (componentClassifier === 'boolean-datatype') {
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                if (componentJson.component[0].component[0].summary === 'true') {
                  adaptedProductObjectJson[componentPath] = true;
                } else if (componentJson.component[0].component[0].summary === 'false') {
                  adaptedProductObjectJson[componentPath] = false;
                } else {
                  adaptedProductObjectJson[componentPath] = componentJson.component[0].component[0].summary;
                }
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary === "DELETEME") {
              delete adaptedProductObjectJson[componentPath];
            } else {
              if (componentJson.component[2].summary === 'true') {
                adaptedProductObjectJson[componentPath] = true;
              }
              if (componentJson.component[2].summary === 'false') {
                adaptedProductObjectJson[componentPath] = false;
              }
            }
          } else {
            if (componentValue === 'true') {
              adaptedProductObjectJson[componentPath] = true;
            }
            if (componentValue === 'false') {
              adaptedProductObjectJson[componentPath] = false;
            }
          }
        } catch {
          if (componentValue === 'true') {
            adaptedProductObjectJson[componentPath] = true;
          }
          if (componentValue === 'false') {
            adaptedProductObjectJson[componentPath] = false;
          }
        }
      }
    }
    if (componentType === 'CodingElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 &&
          componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              let itemToProcess = componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary;
              if (typeof (itemToProcess) === 'object') {
                adaptedProductObjectJson[componentPath] = JSON.parse(JSON.stringify(itemToProcess));
              } else if (typeof (itemToProcess) === 'string') {
                adaptedProductObjectJson[componentPath] = JSON.parse(itemToProcess);
              }
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary === "DELETEME") {
            delete adaptedProductObjectJson[componentPath];
          } else {
            adaptedProductObjectJson[componentPath] = JSON.parse(JSON.stringify(componentJson.component[2].summary));
          }
        } else {
          adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
        }
      } catch {
        adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
      }
    }
    if (componentType === 'ArrayElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 &&
          componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              let itemToProcess = componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary;
              if (typeof (itemToProcess) === 'object') {
                adaptedProductObjectJson[componentPath] = JSON.parse(JSON.stringify(itemToProcess));
              } else if (typeof (itemToProcess) === 'string') {
                adaptedProductObjectJson[componentPath] = JSON.parse(itemToProcess);
              }
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary === "DELETEME") {
            delete adaptedProductObjectJson[componentPath];
          } else {
            if (typeof (componentJson.component[2].summary) === 'object') {
              adaptedProductObjectJson[componentPath] = componentJson.component[2].summary;
            } else if (typeof (componentJson.component[2].summary) === 'string') {
              adaptedProductObjectJson[componentPath] = JSON.parse(componentJson.component[2].summary);
            }
          }
        } else {
          try {
            if (componentJson.component[3].component?.length > 0) {
              adaptedProductObjectJson[componentPath] = createAdaptedArrayComponentJson(fullPath, componentJson.component[3].component);
            } else {
              adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
            }
          } catch {
            adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
          }
        }
      } catch {
        adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
      }
    }
    if (componentType === 'ObjectElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 &&
          componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              let itemToProcess = componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary;
              if (typeof (itemToProcess) === 'object') {
                adaptedProductObjectJson[componentPath] = itemToProcess;
              } else if (typeof (itemToProcess) === 'string') {
                adaptedProductObjectJson[componentPath] = JSON.parse(itemToProcess);
              }
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary === "DELETEME") {
            delete adaptedProductObjectJson[componentPath];
          } else {
            if (typeof (componentJson.component[2].summary) === 'object') {
              adaptedProductObjectJson[componentPath] = componentJson.component[2].summary;
            } else if (typeof (componentJson.component[2].summary) === 'string') {
              adaptedProductObjectJson[componentPath] = JSON.parse(componentJson.component[2].summary);
            }
          }
        } else {
          try {
            if (componentJson.component[3].component?.length > 0) {
              adaptedProductObjectJson[componentPath] = createAdaptedObjectComponentJson(fullPath, componentJson.component[3].component);
            } else {
              adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
            }
          } catch {
            adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
          }
        }
      } catch {
        adaptedProductObjectJson[componentPath] = JSON.parse(componentValue);
      }
    }
  }
  return adaptedProductObjectJson;
}

const createAdaptedProductResource = (adaptationResourceJson) => {
  let adaptedProductResourceJson = {};
  if (!adaptationResourceJson.content?.length > 0) {
    alert('No content in Adaptation Resource');
    return
  };
  for (const componentJson of adaptationResourceJson.content) {
    let componentType;
    let componentClassifier;
    let componentPath;
    let componentValue = {};
    try {
      componentType = componentJson.type.coding[0].code;
      try {
        componentClassifier = componentJson.classifier[0].coding[0].code;
      } catch { }
      componentPath = componentJson.path[0];
      try {
        componentValue = componentJson.component[0].component[0].summary;
      } catch {
        componentValue = componentJson.component[0].summary;
      }
    } catch { };
    if (componentType === 'PrimitiveElement') {
      if (componentClassifier === 'string-datatype') {
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                adaptedProductResourceJson[componentPath] = componentJson.component[0].component[0].summary;
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary === "DELETEME") {
              delete adaptedProductResourceJson[componentPath];
            } else {
              adaptedProductResourceJson[componentPath] = componentJson.component[2].summary;
            }
          } else {
            adaptedProductResourceJson[componentPath] = componentValue;
          }
        } catch {
          adaptedProductResourceJson[componentPath] = componentValue;
        }
      }
      if (componentClassifier === 'number-datatype') {
        adaptedProductResourceJson[componentPath] = parseFloat(componentValue);
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                adaptedProductResourceJson[componentPath] = parseFloat(componentJson.component[0].component[0].summary);
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary === "DELETEME") {
              delete adaptedProductResourceJson[componentPath];
            } else {
              adaptedProductResourceJson[componentPath] = parseFloat(componentJson.component[2].summary);
            }
          } else {
            adaptedProductResourceJson[componentPath] = parseFloat(componentValue);
          }
        } catch {
          adaptedProductResourceJson[componentPath] = parseFloat(componentValue);
        }
      }
      if (componentClassifier === 'boolean-datatype') {
        try {
          if (componentJson.component[2].classifier?.length > 0 &&
            componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
            if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
              componentJson.component[1].component &&
              componentJson.component[1].component[0].classifier &&
              componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
              if (componentJson.component[0].component[0].summary) {
                if (componentJson.component[0].component[0].summary === 'true') {
                  adaptedProductResourceJson[componentPath] = true;
                }
                if (componentJson.component[0].component[0].summary === 'false') {
                  adaptedProductResourceJson[componentPath] = false;
                }
              } else {
                alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
              }
            } else if (componentJson.component[2].summary === "DELETEME") {
              delete adaptedProductResourceJson[componentPath];
            } else {
              if (componentJson.component[2].summary === 'true') {
                adaptedProductResourceJson[componentPath] = true;
              }
              if (componentJson.component[2].summary === 'false') {
                adaptedProductResourceJson[componentPath] = false;
              }
            }
          } else {
            if (componentValue === 'true') {
              adaptedProductResourceJson[componentPath] = true;
            }
            if (componentValue === 'false') {
              adaptedProductResourceJson[componentPath] = false;
            }
          }
        } catch {
          if (componentValue === 'true') {
            adaptedProductResourceJson[componentPath] = true;
          }
          if (componentValue === 'false') {
            adaptedProductResourceJson[componentPath] = false;
          }
        }
      }
    }
    if (componentType === 'CodingElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 &&
          componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              adaptedProductResourceJson[componentPath] = JSON.parse(JSON.stringify(componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary));
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary === "DELETEME") {
            delete adaptedProductResourceJson[componentPath];
          } else {
            adaptedProductResourceJson[componentPath] = JSON.parse(JSON.stringify(componentJson.component[2].summary));
          }
        } else {
          adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
        }
      } catch {
        adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
      }
    }
    if (componentType === 'ArrayElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 && componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              let itemToProcess = componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary;
              if (typeof (itemToProcess) === 'object') {
                adaptedProductResourceJson[componentPath] = JSON.parse(JSON.stringify(itemToProcess));
              } else if (typeof (itemToProcess) === 'string') {
                adaptedProductResourceJson[componentPath] = JSON.parse(itemToProcess);
              }
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary === "DELETEME") {
            delete adaptedProductResourceJson[componentPath];
          } else {
            if (typeof (componentJson.component[2].summary) === 'object') {
              adaptedProductResourceJson[componentPath] = componentJson.component[2].summary;
            } else if (typeof (componentJson.component[2].summary) === 'string') {
              adaptedProductResourceJson[componentPath] = JSON.parse(componentJson.component[2].summary);
            }
          }
        } else {
          try {
            if (componentJson.component[3].component?.length > 0) {
              adaptedProductResourceJson[componentPath] = createAdaptedArrayComponentJson(componentPath, componentJson.component[3].component);
            } else {
              adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
            }
          } catch {
            adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
          }
        }
      } catch {
        adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
      }
    }
    if (componentType === 'ObjectElement') {
      try {
        if (componentJson.component[2].classifier?.length > 0 && componentJson.component[2].classifier[0].coding[0].code === 'accepted-change') {
          if (componentJson.component[1].type.coding[0].code === 'ComparisonOfResources' &&
            componentJson.component[1].component &&
            componentJson.component[1].component[0].classifier &&
            componentJson.component[1].component[0].classifier[0].coding[0].code === 'suggested-replacement') {
            if (componentJson.component[0].component[1].summary ||
              componentJson.component[0].component[1].component[0].summary) {
              adaptedProductResourceJson[componentPath] = JSON.parse(JSON.stringify(componentJson.component[0].component[1].summary ||
                componentJson.component[0].component[1].component[0].summary));
            } else {
              alert(componentPath + ' attempted to replace from Compared Resource Content but no data found.')
            }
          } else if (componentJson.component[2].summary === "DELETEME") {
            delete adaptedProductResourceJson[componentPath];
          } else {
            if (typeof (componentJson.component[2].summary) === 'object') {
              adaptedProductResourceJson[componentPath] = componentJson.component[2].summary;
            } else if (typeof (componentJson.component[2].summary) === 'string') {
              adaptedProductResourceJson[componentPath] = JSON.parse(componentJson.component[2].summary);
            }
          }
        } else {
          try {
            if (componentJson.component[3].component?.length > 0) {
              adaptedProductResourceJson[componentPath] = createAdaptedObjectComponentJson(componentPath, componentJson.component[3].component);
            } else {
              adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
            }
          } catch {
            adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
          }
        }
      } catch {
        adaptedProductResourceJson[componentPath] = JSON.parse(componentValue);
      }
    }
  }
  return adaptedProductResourceJson;
}

export { builderUpdateAdaptationJson, builderUpdateAdaptationContentJson, builderUpdateAdaptationDetailJson, getAdaptationComponentValuesFromFhirJson, navigateToAnotherAdaptationItem, createAdaptedProductResource };