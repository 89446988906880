const UCUM = {
  common: [
    { system: "http://unitsofmeasure.org", code: "%", display: "%" },
    { system: "http://unitsofmeasure.org", code: "d", display: "days" },
    { system: "http://unitsofmeasure.org", code: "g", display: "gram (g)" },
    { system: "http://unitsofmeasure.org", code: "g/kg", display: "gram per kilogram (g/kg)" },
    { system: "http://unitsofmeasure.org", code: "g/L", display: "gram per liter (g/L)" },
    { system: "http://unitsofmeasure.org", code: "g/mL", display: "gram per milliliter (g/mL)" },
    { system: "http://unitsofmeasure.org", code: "h", display: "hours" },
    { system: "http://unitsofmeasure.org", code: "kg", display: "kilogram (kg)" },
    { system: "http://unitsofmeasure.org", code: "kg/m2", display: "kilogram per square meter (kg/m2)" },
    { system: "http://unitsofmeasure.org", code: "km", display: "kilometers (km)" },
    { system: "http://unitsofmeasure.org", code: "m", display: "meters (m)" },
    { system: "http://unitsofmeasure.org", code: "um", display: "micrometers (um)" },
    { system: "http://unitsofmeasure.org", code: "mg", display: "milligram (mg)" },
    { system: "http://unitsofmeasure.org", code: "mg/kg", display: "milligram per kilogram (mg/kg)" },
    { system: "http://unitsofmeasure.org", code: "mg/dL", display: "milligram per deciliter (mg/dL)" },
    { system: "http://unitsofmeasure.org", code: "mg/mL", display: "milligram per milliliter (mg/mL)" },
    { system: "http://unitsofmeasure.org", code: "mm", display: "millimeters (mm)" },
    { system: "http://unitsofmeasure.org", code: "mm[Hg]", display: "millimeters of mercury (mm Hg)" },
    { system: "http://unitsofmeasure.org", code: "ms", display: "milliseconds (ms)" },
    { system: "http://unitsofmeasure.org", code: "min", display: "minutes" },
    { system: "http://unitsofmeasure.org", code: "mo", display: "months" },
    { system: "http://unitsofmeasure.org", code: "nm", display: "nanometers (nm)" },
    { system: "http://unitsofmeasure.org", code: "s", display: "seconds" },
    { system: "http://unitsofmeasure.org", code: "wk", display: "weeks" },
    { system: "http://unitsofmeasure.org", code: "a", display: "years" }
  ],
  age: [
    { system: "http://unitsofmeasure.org", code: "a", display: "years" },
    { system: "http://unitsofmeasure.org", code: "mo", display: "months" },
    { system: "http://unitsofmeasure.org", code: "wk", display: "weeks" },
    { system: "http://unitsofmeasure.org", code: "d", display: "days" },
    { system: "http://unitsofmeasure.org", code: "h", display: "hours" },
    { system: "http://unitsofmeasure.org", code: "min", display: "minutes" }
  ],
  duration: [
    { system: "http://unitsofmeasure.org", code: "a", display: "years" },
    { system: "http://unitsofmeasure.org", code: "mo", display: "months" },
    { system: "http://unitsofmeasure.org", code: "wk", display: "weeks" },
    { system: "http://unitsofmeasure.org", code: "d", display: "days" },
    { system: "http://unitsofmeasure.org", code: "h", display: "hours" },
    { system: "http://unitsofmeasure.org", code: "min", display: "minutes" },
    { system: "http://unitsofmeasure.org", code: "s", display: "seconds" },
    { system: "http://unitsofmeasure.org", code: "ms", display: "milliseconds" }
  ]
}

export default UCUM;