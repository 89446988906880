import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, Dimmer, Loader, Input } from 'semantic-ui-react';
import { TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
//import { MAGICfile1, MAGICfile2 } from './MAGICfiles';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const GradeProConverterPage = ({ useTitle }) => {

    useTitle("FEvIR GRADEpro Converter");

    const [fhirEntryState, setFhirEntryState] = useState({ "title": "", "description": "This project was created by the Computable Publishing®: GRADEpro-to-FEvIR Converter.", "status": "active", "gradepro": "", "gradeproFileUploaded": "", "gradeProId": "" });
    const [submittingResources, setSubmittingResources] = useState(false);

    const history = useHistory();
    const globalContext = useContext(FevirContext);

    const submitGradeProId = async (gradeProId, redirect) => {
        
        if (gradeProId) {
            globalContext.setGlobalLoadingState(true);
            try {
                if (redirect === undefined) {
                  redirect = true;
                }
                const body = {
                    'functionid': 'convertgradepro',
                    'gradeProId': gradeProId,
                    'addToDatabase': true
                };
                let response = await submitToFevirServer(globalContext, 60000, body, true, false);
                if (response?.success) {
                    if (redirect) {
                        if (response.jobAlreadyRunning && response.message) {
                          globalContext.openAlert({"header": "Job Currently In Process", "content": response.message});
                        } else if (response.numberOfResourcesCreated) {
                          if (response.largeCollection) {
                            globalContext.openAlert({"header": "Processing", "content": "The GRADEpro record was converted to " + response.numberOfResourcesCreated + " FHIR Resources. Because the number of Resources created is so large, it may take a few minutes before the referenced Resources are loaded to the FEvIR Platform."});
                          } else {
                            globalContext.openAlert({"header": "Complete", "content": "The GRADEpro record was converted to " + response.numberOfResourcesCreated + " FHIR Resources."});
                          }
                        } else if (response.message) {
                          globalContext.openAlert({"header": "", "content": response.message});
                        }
                        if (response.compositionfoi) {
                          history.push(`/resources/Composition/${response.compositionfoi}`);
                        }
                      }


                } else if (response?.errormessage) {
                    globalContext.openAlert({"header": "Error", "content": response.errormessage});
                  } else {
                    globalContext.openAlert({"header": "Error", "content": "We were unable to convert this citation. Perhaps this GRADEpro ID: " + gradeProId + " doesn't exist or there are issues in its structure."});
                  }

            } catch (e) {  }
            globalContext.setGlobalLoadingState(false);
        } else {
            globalContext.openAlert({"header": "Error", "content": "Please enter a GRADEpro ID."});
        }
    }

    return (
        <div>
            {submittingResources &&
                <Dimmer className={"loadingDimmer"} active inverted>
                    <Loader inverted>Submitting</Loader>
                </Dimmer>
            }
            <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
                <h2 style={{ textAlign: "center", color: "#AC3B61" }}>Computable Publishing<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: GRADEpro-to-FEvIR Converter</h2>
                <br />
                {globalContext.userState.firebaseuid ?
                    <>
                    <TextField style={{ width: "560px" }} className="inputField" type='text' label={'GRADEpro ID'} size="small" variant='outlined' value={fhirEntryState.gradeProId || ''} onChange={(e) => { setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, gradeProId: e.target.value }; }); }} />
                    <br/>
                    <br/>
                    <Button className="formButton positive" content="Convert GRADEpro to FEvIR" compact onClick={() => {
                        submitGradeProId(fhirEntryState.gradeProId, true);
                    }} />
                    {/*
                        <TextField style={{ width: "40%" }} className="inputField" type='text' label={'Name of Project'} size="small" variant='outlined' value={fhirEntryState.projectName || ''} onChange={(e) => { setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, projectName: e.target.value, title: e.target.value }; }); }} />
                        <br/>
                        <br/>
                        <ToggleButtonGroup
                            className="genericToggleButtonGroup medium"
                            exclusive
                            value={fhirEntryState.gradepro || ""}
                            aria-label="grade pro"
                            name="GRADEpro"
                            onClick={(e) => {
                                let value = e.target.value;
                                if (fhirEntryState.gradepro === value) {
                                    value = "";
                                }
                                setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, gradepro: value, gradeproFileUploaded: "" };
                            }); }}
                            >
                        </ToggleButtonGroup>
                        <br/>
                        <br/>
                        <b>Or upload your own GRADEpro file </b>
                        <Input id="inputGradeProFile"
                            type="file"
                            onChange={(e, data) => {
                                if (e.target.files.length > 0) {
                                    let file = e.target.files[0];
                                    try {
                                        let reader = new FileReader();
                                        reader.readAsText(file);

                                        reader.onload = function () {
                                            if (reader.result.length < 70000000) {
                                                try {
                                                    let gradeproJson = JSON.parse(reader.result);
                                                    setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, gradepro: "", gradeproFileUploaded: gradeproJson }; });
                                                } catch (e) {
                                                    setFhirEntryState(prevState => { return { ...prevState, inputChanged: true, gradepro: "", gradeproFileUploaded: "" }; });
                                                    alert("Not a valid JSON file.");                                                    
                                                }
                                            } else {
                                                alert("We only support attaching files that are 50 MB or less. If your file is, then please email support@computablepublishing.com");
                                            }
                                        };
                                        reader.onerror = function (error) {
                                            console.log('Error, ', error);
                                        };
                                    } catch (e) {
                                        alert("File could not be processed.");
                                    }
                                }
                            }}
                        />
                        <br /><br />
                        <Button className="formButton positive" content="Convert GRADEpro to FEvIR" compact onClick={() => { }} />
                        <br />*/}
                    </>
                    :
                    <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
                }
            </Segment>
        </div>
    );
};

export default GradeProConverterPage;