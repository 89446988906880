import React, { useState, useEffect, useRef, useContext, memo } from 'react';
import { useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import DateFnsUtils from '@date-io/date-fns';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { Button, Label, Icon, Segment, Table, TextArea, Dimmer, Loader, Tab, Modal, Pagination, Dropdown, DropdownMenu, DropdownItem, DropdownDivider } from 'semantic-ui-react';
import { TextField, RadioGroup, Radio, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import './App.css';
import NavigationSegment from './NavigationSegment';
import scrollTo from './ScrollTo';
import YesNoRadioCheckBoxSwitch from './YesNoRadioCheckBoxSwitch';
import DropDownWithAdditions from './DropDownWithAdditions';
import addOption from './AddOptionFunction';
import submitToFevirServer from './SubmitToFevirServer';
import { retrieveUserFevirApiToken } from "./UserAccountFunctions";
import FevirContext from './FevirContext';
import { submitBulkResources } from './ResourceFunctions';
import { RIStoFHIRConversionForm, convertRIStoFHIR } from './RIStoFHIRConversionForm';
import { signInFirebase, signOutFirebase, cpLogin, continuedSessionLogin, getResource } from "./UserAccountFunctions";

const rowsPerPage = 50;   //For pagination. Other numbers to consider. 25, 40, 100

const RADARAddOption = (setter, indexFoi, foi, key, newValue) => {
  setter(prevState => {
    if (foi) {
      if (!prevState[indexFoi]) {
        prevState[indexFoi] = { [foi]: { [key]: [] } };
      } else if (!prevState[indexFoi][foi]) {
        prevState[indexFoi][foi] = { [key]: [] };
      } else if (!prevState[indexFoi][foi][key]) {
        prevState[indexFoi][foi][key] = [];
      } else {
        for (let optionIndex in prevState[indexFoi][foi][key]) {
          if (prevState[indexFoi][foi][key][optionIndex].value === newValue) {
            return prevState;
          }
        }
      }
      return {
        ...prevState,
        [indexFoi]: {
          ...prevState[indexFoi],
          [foi]: {
            ...prevState[indexFoi][foi],
            [key]: [...prevState[indexFoi][foi][key], newValue]
          }
        }
      }
    } else {
      if (!prevState[indexFoi]) {
        prevState[indexFoi] = { [key]: [] };
      } else if (!prevState[indexFoi][key]) {
        prevState[indexFoi][key] = [];
      } else {
        for (let optionIndex in prevState[indexFoi][key]) {
          if (prevState[indexFoi][key][optionIndex].value === newValue) {
            return prevState;
          }
        }
      }
      return {
        ...prevState,
        [indexFoi]: {
          ...prevState[indexFoi],
          [key]: [...prevState[indexFoi][key], newValue]
        }
      }
    }
  });
};

const RADARDropDownWithAdditions = ({ indexFoi, foi, currentValue, mismatch, options, optionsSetter, stateSetter, elementKeyEntry, width, disabled }) => {
  if (width === undefined) {
    width = "100%";
  }

  return <Dropdown className="radarThinDropdown" placeholder={<>{(mismatch && !currentValue) ? <span style={{ color: "#770000" }}><b><i>Mismatch</i></b></span> : ""}</>} closeOnChange search selection selectOnBlur={false} allowAdditions={optionsSetter ? true : false} clearable
    style={{ width: width, color: mismatch ? "#000000" : "#007700" }}
    disabled={disabled}
    options={options}
    value={currentValue}
    onAddItem={(e, data) => { RADARAddOption(optionsSetter, indexFoi, foi, elementKeyEntry, data.value); }}
    onChange={(e, data) => {
      stateSetter(prevState => {
        if (foi) {
          let newIndexEntry = prevState[indexFoi]?.[foi] || {};
          newIndexEntry = {
            ...newIndexEntry,
            [elementKeyEntry]: data.value
          };
          return {
            ...prevState,
            changesMade: true,
            [indexFoi]: {
              ...prevState[indexFoi],
              [foi]: newIndexEntry
            }
          }
        } else {
          let newIndexEntry = prevState[indexFoi] || {};
          newIndexEntry = {
            ...newIndexEntry,
            [elementKeyEntry]: data.value
          };
          return {
            ...prevState,
            changesMade: true,
            [indexFoi]: newIndexEntry
          }
        }
      });
    }}
  />
};

//This can be moved to another file to be used in other components outside of the RADAR Tool
const PaginationBar = ({ activePage, setActivePage, totalItems }) => {
  let numberOfPages = Math.ceil(totalItems / rowsPerPage);
  let endOfItemCount = ((activePage) * rowsPerPage);
  endOfItemCount = endOfItemCount < totalItems ? endOfItemCount : totalItems;
  return <div style={{ textAlign: "right", position: "sticky" }}>{(numberOfPages > 1) ? <>
    <i>Showing {((activePage - 1) * rowsPerPage) + 1}-{endOfItemCount} {totalItems && <>of {totalItems}</>}</i>&nbsp;&nbsp;&nbsp;
    <Pagination className="radarPagination" size='mini'
      activePage={activePage} totalPages={numberOfPages} boundaryRange={5} siblingRange={2}
      firstItem={null} lastItem={null} onPageChange={(e, data) => setActivePage(data.activePage)}
    />
  </> :
    <></>
  }</div>;
}

const ArticleLinks = ({ pmidQueryURL, ctgovURL, repoSearches, title }) => {
  return <div style={{ position: "absolute", right: "2px", top: "3px" }}>
    {repoSearches?.map((record, index) => {
      if (record.urlSearch && (record.name || record.symbol)) {
        return <div key={index} style={{ display: "inline-block", marginLeft: "3px", backgroundColor: "#705107", padding: "2px", borderRadius: "5px", fontSize: "12px" }} title={record.name || ""}><a href={record.urlSearch} target="_blank" style={{ color: "#FFFFFF" }}>{record.symbol || record.name}</a></div>
      } else {
        return <span key={index} />
      }
    })}
    {ctgovURL && <div style={{ display: "inline-block", marginLeft: "3px", backgroundColor: "#538cc6", padding: "2px", borderRadius: "5px", fontSize: "12px" }} title="ClinicalTrials.gov NCT ID"><a href={ctgovURL} target="_blank" style={{ color: "#FFFFFF" }}>CTgov</a></div>}
    {pmidQueryURL && <div style={{ display: "inline-block", marginLeft: "3px", backgroundColor: "#326699", padding: "2px", borderRadius: "5px", fontSize: "12px" }} title="PubMed search"><a href={pmidQueryURL} target="_blank" style={{ color: "#FFFFFF" }}>PubMed</a></div>}
  </div>
}

const handleKeyDown = (event, saveFunction, saveEnable) => {
  if ((event.ctrlKey || event.metaKey) && event.key === "s") {    //CTRL + S
    event.preventDefault();
    if (saveEnable && saveFunction) {
      saveFunction();
    }
  }
}

const convertYesNoStringToBoolean = (yesNoString) => {
  let yesNoBoolean = null;
  if (yesNoString === "yes") {
    yesNoBoolean = true;
  } else if (yesNoString === "no") {
    yesNoBoolean = false;
  }
  return yesNoBoolean;
}

const convertBooleanToYesNoString = (yesNoBoolean) => {
  let yesNoString = "";
  if (yesNoBoolean !== undefined && yesNoBoolean !== null) {
    if (yesNoBoolean === true) {
      yesNoString = "yes";
    } else if (yesNoBoolean === false) {
      yesNoString = "no";
    }
  }
  return yesNoString;
}

const capitalizeYesNo = (yesorno) => {
  if (yesorno == "yes") {
    return "Yes";
  } else if (yesorno == "no") {
    return "No";
  } else {
    return yesorno;
  }
}

const capitalizeYesNoAndCheckNotBlank = (yesorno) => {
  if (yesorno == "(blank)") {
    return ""
  } else if (yesorno == "yes") {
    return "Yes";
  } else if (yesorno == "no") {
    return "No";
  } else {
    return yesorno;
  }
}

const editInvestigation = async (addToast, globalContext, setModalToClose, setRisModalState, setLoading, setReloadState, setEditMode, investigationId, title, description, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, citingFois) => {
  if (title) {
    setLoading(true);
    let uniqueFois;
    if (indexFois) {
      uniqueFois = [...new Set(indexFois)]; //Just in case a duplicate FOI got in there.
    }
    const body = {
      'functionid': 'editinvestigation',
      'investid': investigationId,
      'title': title,
      'description': description,
      'opentojoin': openToJoin,
      'makeresultspublic': makeResultsPublic,
      'sufficientnumberofratings': sufficientNumberOfRatings,
      'indexfois': uniqueFois,
      'citingfois': citingFois,
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    setLoading(false);
    if (response?.success) {
      setReloadState(prevState => !prevState); //This value doesn't matter it just needs to be changed to trigger the useEffect
      addToast('Investigation changes saved.', { appearance: 'success' });
      setEditMode(false);
    } else {
      globalContext.openAlert({ "header": "Error", "content": response.errormessage || "Not successful in updating the investigation. Please contact FEvIR admins." });
    }
  } else {
    globalContext.openAlert({ "header": "", "content": "Please enter a title of the investigation." });
  }
  if (setModalToClose) {
    setModalToClose(prevState => { return { ...prevState, modalOpen: false }; });
  }
  if (setRisModalState) {
    setRisModalState({ modalOpen: false });
  }
}

const submitUpdatesToRatings = async (globalContext, addToast, investigationId, indexFoi, citationRatings, toolState, setToolState, setReloadState, setLoading, setChangesMade, setEditMode, setModalState) => {

  let changed = false;
  let changedFoiEntries = undefined;
  let changedIndexFoiEntries = undefined;
  if (indexFoi) {
    //Meaning it's for Citation Ratings because it provided an indexFoi
    changedFoiEntries = {};
    let citationEntry = JSON.parse(JSON.stringify(citationRatings[indexFoi]));
    let loadedCitationForOneIndexRatings = JSON.parse(JSON.stringify(toolState["loadedCitationRatings"][indexFoi]));
    for (let foi in citationEntry) {
      //Checking to see if there is any changes that have been made to the rating by comparing it to what it was when the page was loaded.
      let changedCitationRating = citationEntry[foi];
      delete changedCitationRating["itemChanged"];
      if (loadedCitationForOneIndexRatings === undefined || loadedCitationForOneIndexRatings[foi] === undefined || JSON.stringify(loadedCitationForOneIndexRatings[foi]) !== JSON.stringify(changedCitationRating)) {
        changedFoiEntries[foi] = changedCitationRating;
        loadedCitationForOneIndexRatings[foi] = changedCitationRating;
        changed = true;
      }
    }
    if (setToolState && changed) {
      setToolState(prevState => {
        return {
          ...prevState,
          "loadedCitationRatings": {
            ...prevState["loadedCitationRatings"],
            [indexFoi]: loadedCitationForOneIndexRatings
          }
        };
      });
    }
  } else { //INDEX RATINGS
    //Meaning it's for Index Citation Ratings
    changedIndexFoiEntries = {};
    let loadedIndexCitationRatings = JSON.parse(JSON.stringify(toolState["loadedIndexCitationRatings"]));
    for (let foi in citationRatings) {
      let changedCitationRating = JSON.parse(JSON.stringify(citationRatings[foi]));
      delete changedCitationRating["itemChanged"];
      if (loadedIndexCitationRatings[foi] === undefined || JSON.stringify(loadedIndexCitationRatings[foi]) !== JSON.stringify(changedCitationRating)) {
        changedIndexFoiEntries[foi] = changedCitationRating;
        loadedIndexCitationRatings[foi] = changedCitationRating;
        changed = true;
      }
    }
    if (setToolState && changed) {
      setToolState(prevState => {
        return {
          ...prevState,
          "loadedIndexCitationRatings": loadedIndexCitationRatings
        };
      });
    }
  }

  if (changed) {
    setLoading(true);
    const body = {
      'functionid': 'editcitationratings',
      'investid': investigationId,
      'indexfoi': indexFoi || undefined,
      'citingfoientries': changedFoiEntries || undefined,
      'indexfoientries': changedIndexFoiEntries || undefined,
      'idToken': ''
    };

    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (setReloadState) {
      setReloadState(prevState => !prevState);
    }
    if (response?.success) {
      if (response.numbersaved === 0) {
        addToast(`No changes have been made.`, { appearance: 'success' });
      } else if (response.numbersaved === 1) {
        addToast(`${response.numbersaved} rating has been saved.`, { appearance: 'success' });
      } else {
        addToast(`${response.numbersaved} ratings have been saved.`, { appearance: 'success' });
      }
      if (setEditMode) {
        setEditMode(false);
      }
      setChangesMade(false);
    } else {
      globalContext.openAlert({ "header": "Error", "content": response.errormessage || "Not successful in saving the changes to the ratings. Please contact FEvIR admins." });
    }
    if (!setReloadState) {
      setLoading(false);
    }
  } else {
    globalContext.openAlert({ "header": "No changes have been made.", "content": "" });
    setChangesMade(false);
  }
  //setChangesMade(false);
  if (setModalState) {
    setModalState({ modalOpen: false });
  }
}

const joinInvestigation = async (investigationId, globalContext, setReloadState, setJoinedGroup, joinAdmin, joinAdjudicator, adminInviteCode, adjudicatorInviteCode, inviteCode) => {
  const body = {
    'functionid': 'joininvestigation',
    'investid': investigationId,
    'joinadmin': joinAdmin,
    'joinadjudicator': joinAdjudicator,
    'admininvitecode': adminInviteCode,
    'adjudicatorinvitecode': adjudicatorInviteCode,
    'invitecode': inviteCode,
    'idToken': ''
  };
  let response;
  try {
    response = await submitToFevirServer(globalContext, 100000, body, true, false);
  } catch (e) { }
  if (response?.success) {
    if (joinAdmin) {
      globalContext.openAlert({ "header": "", "content": `You are now an administrator to ${response.title}!` });
      return true;
    } else if (joinAdjudicator) {
      globalContext.openAlert({ "header": "", "content": `You are now an adjudicator to ${response.title}!` });
      return true;
    } else {
      let emailMessage = "We don't have a notification email on file for you. Please visit your user profile by clicking your name in the upper right corner and add one. Otherwise, you won't receive a notification when investigation updates are sent.";
      if (globalContext.userState.notificationemail) {
        emailMessage = `Your email on record is: ${globalContext.userState.notificationemail} which we will use to send you notices when investigation updates are sent. If you want to change your email address used for notification, please edit your user profile by clicking your name in the upper right corner.`;
      }
      if (setJoinedGroup) {
        setJoinedGroup(true);
      }
      setReloadState(prevState => !prevState);
      globalContext.openAlert({ "header": "", "content": `Welcome to ${response.title}! ${emailMessage}` });
      return true;
    }
  } else {
    if (response?.error) {
      globalContext.openAlert({ "header": "Error", "content": response.error });
    } else {
      let content = joinAdmin ? "Joining as an administrator was unsuccessful." : joinAdjudicator ? "Joining as an adjudicator was unsuccessful." : "Joining the group was unsuccessful.";
      globalContext.openAlert({ "header": "Error", "content": content });
    }
    return false;
  }
}

const changeInviteCode = async (globalContext, investigationId, disable, invitetype, setAdminInviteCode, setAdjudicatorInviteCode, setInvestigatorInviteCode) => {
  const body = {
    'functionid': 'changeinvestigationinvitecode',
    'investid': investigationId,
    'disableinvite': disable,
    'changeinvitetype': invitetype,
    'idToken': ''
  };
  let response = await submitToFevirServer(globalContext, 60000, body, true, false);
  if (response?.success) {
    if (response.admininvitecode !== undefined && (invitetype === 'admininvite' || invitetype === 'both' || invitetype === 'allthree')) {
      setAdminInviteCode(response.admininvitecode);
    }
    if (response.invitecode !== undefined && (invitetype === 'investigatorinvite' || invitetype === 'both' || invitetype === 'allthree')) {
      setInvestigatorInviteCode(response.invitecode);
    }
    if (response.adjudicatorinvitecode !== undefined && (invitetype === 'adjudicatorinvite' || invitetype === 'allthree')) {
      setAdjudicatorInviteCode(response.adjudicatorinvitecode);
    }
  }
}

const submitPubmedEntry = async (globalContext, pmid) => {
  pmid = pmid.replace('\t', '');
  if (pmid.match(/[^$\d\s,]/)) {
    const body = {
      'functionid': 'importmedlinesearchquery',
      'medlinequery': pmid,
      'dontmakeproject': true,
      'idToken': ''
    };

    let response = await submitToFevirServer(globalContext, 120000, body, true, false);

    if (response?.success) {
      if (response.noResults) {
        globalContext.openAlert({ "header": "", "content": "No results found for this query." });
      } else {
        return response.allSuccessfulFois;
      }
    } else {
      globalContext.openAlert({ "header": "Error", "content": "Something went wrong with the search query." });
    }
  } else if (pmid.includes(",")) {
    //await submitPMIDList();
    let pmidlist = pmid.replace(" ", "").split(",");
    const body = {
      'functionid': "submitpmid",
      'tool': "medlinetofhirconverter",
      'idToken': "",
      'pmidlist': pmidlist,
      'multiple': true,
      'status': "active"
    };
    let response = await submitToFevirServer(globalContext, 120000, body, true, false);
    if (response?.success) {
      return response.allSuccessfulFois;
    } else {
      globalContext.openAlert({ "header": "Error", "content": "There was a problem." });
    }
  } else {
    pmid = pmid.replaceAll(" ", "");
    if (pmid.match(/[^$\d]/)) {
      globalContext.openAlert({ "header": "", "content": "The PMID cannot contain any non-numerical characters: " + pmid });
    } else {
      pmid = parseInt(pmid).toString();
      const body = {
        'functionid': "submitpmid",
        'tool': "medlinetofhirconverter",
        'idToken': "",
        'pmid': pmid || undefined,
        'multiple': false,
        'status': "active"
      };
      let response = await submitToFevirServer(globalContext, 120000, body, true, false);
      if (response?.success) {
        return [response.formstateid];
      } else if (response?.errormessage) {
        globalContext.openAlert({ "header": "Error", "content": response.errormessage });
        return false;
      } else if (pmid) {
        globalContext.openAlert({ "header": "Error", "content": "We were unable to convert this citation: " + pmid + ". Perhaps this PMID entry doesn't have Medline XML. Maybe it's a Book entry, and we're only converting Journal Articles at this time." });
        return false;
      } else {
        globalContext.openAlert({ "header": "Error", "content": "We were unable to convert this query." });
        return false;
      }
    }
  }
  return false;
}

const getArticleLabel = (foi, foiTitles, foiAuthors, foiYears) => {
  let articleLabel;
  if (foiAuthors && foiAuthors[foi]) {
    articleLabel = foiAuthors[foi] + " " + (foiYears[foi] || "");
  } else if (foiTitles && foiTitles[foi]) {
    articleLabel = foiTitles[foi].substring(0, 20);
  } else if (foiYears && foiYears[foi]) {
    articleLabel = "--No Title--" + " " + foiYears[foi];
  } else {
    articleLabel = "--No Title--";
  }
  return articleLabel;
}

const getProgressInfo = (foiCitings, foiRatings, typeIndex) => {
  //typeIndex is a boolean to tell this function if it's for an index rating button
  let buttonColor = "blue";
  let labelColor = "blue";
  let noCitations = false;
  let percentProgressText = "0%";
  let numberStartedRatings = 0;
  let numberCompletedRatings = 0;

  if (foiRatings) {
    //numberStartedRatings = foiRatings.length;
    for (let citingFoi in foiRatings) {
      let rating = foiRatings[citingFoi];
      if (rating) {
        if (rating["completed"]) {
          numberStartedRatings += 1;
          numberCompletedRatings += 1;
        } else if (rating["started"]) {
          numberStartedRatings += 1;
        }
      }
    }
  }

  if (!foiCitings || foiCitings.length === 0) {
    noCitations = true;
    labelColor = "red";
    percentProgressText = "No Citations Yet";
  } else if (numberStartedRatings === 0) {
    labelColor = "red";
    percentProgressText = "0%";
    //percentProgressText = "Not Yet Started";
  } else if (numberCompletedRatings >= foiCitings?.length) {
    buttonColor = "teal";
    labelColor = "green";
    percentProgressText = "100%";
    //percentProgressText = "Completed";
  } else if (numberStartedRatings && numberCompletedRatings) { // > 0
    labelColor = "blue";
    percentProgressText = Math.round(100 * (numberCompletedRatings / foiCitings.length)) + "%";
    //percentProgressText = "In Progress";
  }
  //So you'll get 0 of X in blue text instead of red if only one rating was started and not finished
  return { noCitations: noCitations, buttonColor: buttonColor, labelColor: labelColor, percentProgressText: percentProgressText, numberStartedRatings: numberStartedRatings, numberCompletedRatings: numberCompletedRatings };
}

const indexRatingFieldChange = (setChangesMade, setIndexCitationRatings, indexFoi, elementName, value) => {
  setIndexCitationRatings(prevState => {
    return {
      ...prevState,
      [indexFoi]: {
        ...prevState[indexFoi],
        [elementName]: value,
        "itemChanged": true
      }
    };
  });
  setChangesMade(true);
}

const ratingFieldChange = (setChangesMade, setCitationRatings, indexFoi, foi, elementName, value) => {
  setCitationRatings(prevState => {
    return {
      ...prevState,
      [indexFoi]: {
        ...prevState[indexFoi],
        [foi]: {
          ...prevState[indexFoi][foi],
          [elementName]: value,
          "itemChanged": true
        }
      }
    };
  });
  setChangesMade(true);
}

const getTitlesForFoiList = async (globalContext, indexFois, newFoiList) => {
  let onlyNewFois = [];
  for (let newFoi of newFoiList) {
    newFoi = parseInt(newFoi);
    if (!indexFois.includes(newFoi)) {
      onlyNewFois.push(newFoi);
    }
  }
  if (onlyNewFois.length === 0) {
    //alert("No new Citations added.");
    return;
  } else {
    //Confirm that it's a real Citation and that the user has permission to view it
    const body = {
      'functionid': 'getresourcetitle',
      'resourceids': onlyNewFois,
      'resourcetype': "Citation",
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success) {
      return response.titlesbyfoi;
    } else {
      globalContext.openAlert({ "header": "Error", "content": "Something went wrong. Contact the FEvIR site admin." });
    }
  }
  return false;
}

const NavigationLink = ({ id, text }) => {
  return <span><a onClick={() => scrollTo("measuringTheRateSegment", id, true)} >{text}</a></span>
}

const EvaluateCitationsButton = ({ history, investigationId, foi, indexFois, citingFois, citationRatings, foiTitles, foiAuthors, foiYears, setRateCitingArticlesModalState, loading }) => {
  const [titleTruncated, setTitleTruncated] = useState("");
  const [articleLabel, setArticleLabel] = useState("");

  const [noCitationsState, setNoCitationsState] = useState(true);
  const [buttonColorState, setButtonColorState] = useState("blue");
  const [labelColorState, setLabelColorState] = useState("red");
  const [percentProgressTextState, setPercentProgressTextState] = useState("0%");
  const [numberStartedRatingsState, setNumberStartedRatingsState] = useState(0);
  const [numberCompletedRatingsState, setNumberCompletedRatingsState] = useState(0);

  useEffect(async () => {
    if ((foiTitles && foiTitles[foi])) {
      let title = foiTitles[foi];
      if (title.length > 100) {
        title = title.substring(0, 100) + "...";
      }
      setTitleTruncated(title);
    }
    setArticleLabel(getArticleLabel(foi, foiTitles, foiAuthors, foiYears));
  }, [foiTitles, foiAuthors, foiYears]);

  useEffect(async () => {
    if (!loading || citingFois[foi]?.length > 0 || (citationRatings[foi] && Object.keys(citationRatings[foi]).length > 0)) {
      let { noCitations, buttonColor, labelColor, percentProgressText, numberStartedRatings, numberCompletedRatings } = getProgressInfo(citingFois[foi], citationRatings[foi], null);
      setNoCitationsState(noCitations);
      setButtonColorState(buttonColor);
      setLabelColorState(labelColor);
      setPercentProgressTextState(percentProgressText);
      setNumberStartedRatingsState(numberStartedRatings);
      setNumberCompletedRatingsState(numberCompletedRatings);
    }
  }, [citingFois, citationRatings]);

  return <Button compact as='div' labelPosition='right'
    style={{ float: "left" }}
    disabled={noCitationsState}
    onClick={() => {
      history.replace("/radar/" + investigationId + "#ratecitations" + foi);
      setRateCitingArticlesModalState(prevState => { return { ...prevState, modalOpen: true, indexFoi: foi } });
    }}>
    <Button compact icon labelPosition='left' animated='fade' color={buttonColorState} style={{ float: "left" }}>
      <Icon name='edit outline' />
      <Button.Content visible={titleTruncated !== ""} style={{ fontSize: "20px" }}>Evaluate Citations of {articleLabel}</Button.Content>
      {titleTruncated && <Button.Content className="hiddenButtonMultiRowText" hidden>{titleTruncated}</Button.Content>}
    </Button>
    <Label as='a' basic
      color={labelColorState}
      pointing='left'>
      {noCitationsState
        ?
        "No Citations Yet"
        :
        (numberCompletedRatingsState + " of " + citingFois[foi]?.length + `: ${percentProgressTextState}`)
      }
    </Label>
  </Button>
}

const YesNoCell = ({ citationRatings, setCitationRatings, setChangesMade, indexFoi, foi, elementName }) => {
  let inputValue = "";
  if (citationRatings[indexFoi] && citationRatings[indexFoi][foi] && citationRatings[indexFoi][foi][elementName]) {
    inputValue = citationRatings[indexFoi][foi][elementName];
  }

  return <Table.Cell style={{ textAlign: "center" }}>
    <YesNoRadioCheckBoxSwitch elementName={elementName} changeElementName={"itemChanged"} thin={true} togglable={true} boxes={true} higherParentElement={indexFoi} parentElement={foi} startingValue={inputValue} setter={setCitationRatings} setChangeAvailableToSaveState={setChangesMade} />
  </Table.Cell>
}

const SaveRatingsButton = ({ text, titleText, leftRightPadding, globalContext, addToast, investigationId, indexFoi, citationRatings, toolState, setToolState, setChangesMade, setReloadState, setLoading, setEditMode, setModalState }) => {
  return <Button className="formButton" color='green' style={{ padding: "6px", paddingLeft: leftRightPadding, paddingRight: leftRightPadding }}
    content={text} title={titleText}
    onClick={() => {
      submitUpdatesToRatings(globalContext, addToast, investigationId, indexFoi, citationRatings, toolState, setToolState, setReloadState, setLoading, setChangesMade, setEditMode, setModalState);
    }}
  />
}

const SaveRatingsButtons = ({ globalContext, addToast, investigationId, indexFoi, citationRatings, toolState, setToolState, setChangesMade, setReloadState, setLoading, setEditMode, setModalState }) => {
  return <>
    {/*<>
      <SaveRatingsButton text="✔" titleText="Save and Close" leftRightPadding="6px" globalContext={globalContext} addToast={addToast} investigationId={investigationId} indexFoi={indexFoi} citationRatings={citationRatings} toolState={toolState} setToolState={null} setChangesMade={setChangesMade} setReloadState={setReloadState} setLoading={setLoading} setEditMode={setEditMode} setModalState={setModalState} />
      <span style={{ marginLeft: "8px" }} />
    </>*/}
    <SaveRatingsButton text="Save" titleText="Save and Continue" leftRightPadding="60px" globalContext={globalContext} addToast={addToast} investigationId={investigationId} indexFoi={indexFoi} citationRatings={citationRatings} toolState={toolState} setToolState={setToolState} setChangesMade={setChangesMade} setReloadState={setReloadState} setLoading={setLoading} setEditMode={null} setModalState={null} />
  </>
}

const RisImportModal = ({ loading, setLoading, adminState, indexArticlesModalState, setIndexArticlesModalState, risModalState, setRisModalState, toolState, addToast, globalContext, reloadState, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, setIndexFois, citingFois }) => {
  const [fhirEntryState, setFhirEntryState] = useState({ "inputChanged": true, "codings": [{ "systemName": "", "systemUri": "", "code": "", "display": "", "qualifiers": [], "preset": "" }], text: "", risFile: "" });
  const [risMappingState, setRisMappingState] = useState([]);
  //const [fhirOutputState, setFhirOutputState] = useState("");

  const modalSaveAndClose = async () => {
    setLoading(true);
    let jsonArray = await convertRIStoFHIR(globalContext, fhirEntryState, risMappingState, null);
    let fois = await submitBulkResources(globalContext, null, { tool: "ristofevir", status: "active", 'fhirBulkEntry': jsonArray }, true);
    setLoading(false);
    if (fois) {
      setRisModalState({ modalOpen: false, newFois: fois });
    }
  }

  const modalActualClose = () => {
    setRisModalState({ modalOpen: false });
  }

  const modalClose = (dont) => {    //dont meaning, don't ask and don't close
    if (fhirEntryState.text || fhirEntryState.risFile) {
      if (!dont) {
        globalContext.openConfirmation({ "header": "You started the RIS conversion process.", "content": "Do you wish to exit out without adding the citations?", "runFunction": modalActualClose, "buttonTextOkay": "Yes", "buttonTextCancel": "No" });
      }
    } else {
      modalActualClose();
    }
  }

  let modalContent = <div id="indexArticlesModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#CAE4FC" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      <Button className="formButton negative"
        style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
        onClick={() => { modalClose(); }}
      />
      {risMappingState.length > 0 && <Button className="formButton" color='green' style={{ padding: "6px", paddingLeft: "20px", paddingRight: "20px", position: "absolute", left: "14px", zIndex: "10" }} content="Add RIS Citations"
        disabled={loading}
        onClick={async () => { modalSaveAndClose(); }}
      />}
    </div>
    <h2 style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
      Import Citations via RIS
    </h2>
    <br /><br /><br />
    <RIStoFHIRConversionForm fhirEntryState={fhirEntryState} setFhirEntryState={setFhirEntryState} risMappingState={risMappingState} setRisMappingState={setRisMappingState} />
  </div>;
  return <div onKeyDown={event => handleKeyDown(event, modalSaveAndClose, risMappingState.length > 0)}>
    <Modal
      className="radarModal"
      tabIndex="0"
      style={{ width: "87%", height: "90%", padding: "0px", margin: "0px", marginTop: "20px" }}
      dimmer={<Modal.Dimmer className="radarModal" tabIndex="0" blurring style={{ backgroundColor: "#5D4210A7" }} />}
      open={risModalState?.modalOpen}
      centered={false}
      content={modalContent}
      closeOnEscape
      closeOnDimmerClick
      onClose={() => modalClose(true)}
    />
  </div>
}

const IndexArticlesModal = ({ loading, setLoading, adminState, indexArticlesModalState, setIndexArticlesModalState, risModalState, setRisModalState, toolState, foiTitles, foiPmidQueries, foiCTgovURLs, foiAuthors, foiYears, addToast, globalContext, reloadState, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, setIndexFois, citingFois }) => {
  const [changesMade, setChangesMade] = useState(false);
  const [includingNewFoiTitles, setIncludingNewFoiTitles] = useState(foiTitles || {});
  const [latestFois, setLatestFois] = useState([]);

  useEffect(async () => {
    if (risModalState.newFois && risModalState.newFois.length > 0) {
      addIndexArticle(risModalState.newFois);
    }
  }, [risModalState.newFois]);

  useEffect(async () => {
    if (indexArticlesModalState.modalOpen && !loading) {
      if (adminState === false) {
        setIndexArticlesModalState({ modalOpen: false });
      }
    }
  }, [indexArticlesModalState.modalOpen, loading]);

  const addIndexArticle = async (newFois) => {
    setLoading(true);
    let newInvestigationFoi = indexArticlesModalState.newInvestigationFoi;
    let newInvestigationFoiList = null;
    let newInvestigationPmid = indexArticlesModalState.newInvestigationPmid;
    if (newInvestigationPmid) {
      newInvestigationFoi = await submitPubmedEntry(globalContext, newInvestigationPmid);
      if (newInvestigationFoi?.length === 1) {
        newInvestigationFoi = newInvestigationFoi[0];
      } else if (newInvestigationFoi?.length > 1) {
        newInvestigationFoiList = newInvestigationFoi;
      }
    } else {
      newInvestigationFoiList = newFois;
    }
    let newFoisList = [];
    if ((newInvestigationFoiList && newInvestigationFoiList.length > 0) || newInvestigationFoi) {
      let newEntries = {};
      if (newInvestigationFoiList && newInvestigationFoiList.length > 0) {
        newEntries = await getTitlesForFoiList(globalContext, indexFois, newInvestigationFoiList) || {};
      } else {
        let newFoi = parseInt(newInvestigationFoi.toString().replace(/\D/g, ''));
        if (!indexFois.includes(newFoi)) {
          //Confirm that it's a real Citation and that the user has permission to view it
          const body = {
            'functionid': 'getresourcetitle',
            'resourceid': newFoi,
            'resourcetype': "Citation",
            'idToken': ''
          };
          let response = await submitToFevirServer(globalContext, 60000, body, true, false);
          if (response?.success) {
            newEntries = { [newFoi]: response.title };
          } else {
            globalContext.openAlert({ "header": "Error", "content": "Can't verify that FOI. Maybe it's not a Citation resource or you don't have permission." });
            setLoading(false);
            return;
          }
        } else {
          globalContext.openAlert({ "header": "Error", "content": "Can't add, that FOI already exists in the list." });
          setLoading(false);
          return;
        }
      }
      if (newEntries && Object.keys(newEntries).length > 0) {
        newFoisList = Object.keys(newEntries).map(Number);
        setChangesMade(true);
        setIndexFois(prevState => { let newState = prevState.concat(newFoisList); return [...new Set(newState)]; });
        setIndexArticlesModalState(prevState => {
          let newState = { ...prevState, newInvestigationFoi: "", newInvestigationPmid: "" };
          for (let newFoi in newEntries) {
            newState[parseInt(newFoi)] = true;
          }
          return newState;
        });
        setIncludingNewFoiTitles(prevState => { return { ...prevState, ...newEntries }; });
      }
    }
    setLatestFois(newFoisList);
    setLoading(false);
  }

  const modalSaveAndClose = () => {
    let newIndexFois = [];
    let newCitingFois = { ...citingFois };
    for (let foi of indexFois) {
      if (indexArticlesModalState[foi] !== false) {   //if it's true or undefined then we are keeping it
        newIndexFois.push(foi);
      } else {
        delete newCitingFois[foi];    //So that if the index article is added back it won't have all the previously loaded articles along with it. THIS LINE CAN BE COMMENTED OUT IF YOU WANT THE PREVIOUSLY LOADED ARTICLES TO COME BACK TOO.
      }
    }
    editInvestigation(addToast, globalContext, setIndexArticlesModalState, setRisModalState, setLoading, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, newIndexFois, newCitingFois);
    setLatestFois([]);
    setChangesMade(false);
  }

  const modalSaveAndCloseAsk = () => {
    if (indexArticlesModalState.newInvestigationFoi) {
      globalContext.openAlert({ "header": "", "content": "Please either clear all fields or click the \"Add\" button." });
    } else {
      modalSaveAndClose();
    }
  }

  const modalActualClose = () => {
    setChangesMade(false);
    setIndexFois(toolState.loadedIndexFois);
    setIndexArticlesModalState({ modalOpen: false });
    setRisModalState({ modalOpen: false }); //Clears the new FOIs loaded from the modal too
    setLatestFois([]);
    setLoading(false);
    //setIndexArticlesModalState(prevState => { return { ...prevState, modalOpen: false }; }); 
  }

  const modalClose = (dont) => {
    if (changesMade || risModalState.newFois?.length > 0) {
      if (!dont) {
        globalContext.openConfirmation({ "header": "You have unsaved changes", "content": "", "saveFunction": modalSaveAndClose, "runFunction": modalActualClose, "buttonColorSave": "green", "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextSave": "Save Changes", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Keep Working" });
      }
    } else {
      modalActualClose();
    }
  }

  let modalContent = <div id="indexArticlesModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#FCE4CA" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      {changesMade &&
        <div style={{ position: "absolute", left: "14px", zIndex: "10" }}>
          <Button className="formButton" color='green' style={{ padding: "6px", paddingLeft: "60px", paddingRight: "60px" }} content="Save"
            onClick={modalSaveAndCloseAsk}
          />
        </div>
      }
      <Button className="formButton negative"
        style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
        onClick={() => { modalClose(); }}
      />
      <h2 style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        {indexFois.length ? "Change" : "Load"} Index Articles for this Investigation
      </h2>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div>
      <div>
        <TextField style={{ width: "160px" }}
          className="inputField" type='number' size="small" variant='outlined'
          label={'Add a citation by FOI'}
          value={indexArticlesModalState.newInvestigationFoi || ""}
          onChange={(e) => { setIndexArticlesModalState(prevState => { return { ...prevState, newInvestigationFoi: e.target.value, newInvestigationPmid: "" } }); }}
          onKeyUp={(e) => { if (e.key === "Enter") { addIndexArticle(); } }}
        />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "370px" }}
          className="inputField" type='text' size="small" variant='outlined'
          label={'Add a citation by PMID, PMIDs List, or Search Query'}
          value={indexArticlesModalState.newInvestigationPmid || ""}
          onChange={(e) => { setIndexArticlesModalState(prevState => { return { ...prevState, newInvestigationFoi: "", newInvestigationPmid: e.target.value } }); }}
          onKeyUp={(e) => { if (e.key === "Enter") { addIndexArticle(); } }}
        />
        &nbsp;&nbsp;&nbsp;
        <Button /*  "color": "#000000", */
          className="formButton"
          style={{ width: "120px", marginTop: "3px !important", color: "#000000" }}
          content="+ Add"
          disabled={(!indexArticlesModalState.newInvestigationFoi && !indexArticlesModalState.newInvestigationPmid)}
          onClick={() => { addIndexArticle(); }}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button /*  "color": "#000000", */
          className="formButton"
          style={{ width: "120px", marginTop: "3px !important", color: "#000000" }}
          content="Add via RIS"
          onClick={() => { setRisModalState({ modalOpen: true }) }}
          disabled={(indexArticlesModalState.newInvestigationFoi || indexArticlesModalState.newInvestigationPmid) ? true : false}
        />
      </div>
      <br />
      {indexFois.map((foi, index) => {
        let checked = indexArticlesModalState[foi];
        if (checked === undefined) {
          checked = true;
        }
        let entryStyle = { pointerEvents: "none" };
        if (latestFois.includes(foi)) {
          entryStyle["backgroundColor"] = "#FFFACD";
        }
        return <span key={index}>
          <FormGroup style={entryStyle}>
            <FormControlLabel control={<Checkbox size="medium" checked={checked} style={{ pointerEvents: "auto" }}
              onChange={(e) => { setChangesMade(true); setIndexArticlesModalState(prevState => { return { ...prevState, [foi]: e.target.checked } }); }}
            />} label={<a href={"/resources/Citation/" + foi} target="_blank" style={{ pointerEvents: "auto" }}>FOI:{foi} - {foiAuthors[foi] || ""} {foiYears[foi] || ""} - {includingNewFoiTitles[foi]}</a>} />
          </FormGroup>
        </span>
      })}
    </div>
  </div>;
  return <div onKeyDown={event => handleKeyDown(event, modalSaveAndClose, changesMade)}>
    <Modal
      className="radarModal"
      tabIndex="0"
      style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer className="radarModal" tabIndex="0" style={{ backgroundColor: "#00000077" }} />}
      open={indexArticlesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      closeOnEscape
      closeOnDimmerClick
      onClose={() => modalClose(true)}
    />
  </div>
}

const CitingArticlesModal = ({ loading, setLoading, adminState, citingArticlesModalState, setCitingArticlesModalState, risModalState, setRisModalState, toolState, foiTitles, foiPmidQueries, foiCTgovURLs, foiAuthors, foiYears, addToast, globalContext, reloadState, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, setIndexFois, citingFois, setCitingFois }) => {
  const [changesMade, setChangesMade] = useState(false);
  const [includingNewFoiTitles, setIncludingNewFoiTitles] = useState(foiTitles || {});
  const [latestFois, setLatestFois] = useState([]);

  let indexFoi = citingArticlesModalState.indexFoi;

  const addCitingArticle = async (newFois) => {
    setLoading(true);
    let newCitingArticleFoi = citingArticlesModalState.newCitingArticleFoi;
    let newCitingArticleFoiList = null;
    let newCitingArticlePmid = citingArticlesModalState.newCitingArticlePmid;
    let indexFoiCitingFoisList = citingFois[indexFoi];
    if (indexFoiCitingFoisList === undefined) {
      indexFoiCitingFoisList = [];
    }
    if (newCitingArticlePmid) {
      newCitingArticleFoi = await submitPubmedEntry(globalContext, newCitingArticlePmid);
      if (newCitingArticleFoi?.length === 1) {
        newCitingArticleFoi = newCitingArticleFoi[0];
      } else if (newCitingArticleFoi?.length > 1) {
        newCitingArticleFoiList = newCitingArticleFoi;
      }
    } else {
      newCitingArticleFoiList = newFois;
    }
    let newFoisList = [];
    if (newCitingArticleFoiList?.length > 0 || newCitingArticleFoi) {
      let newEntries = {};
      if (newCitingArticleFoiList?.length > 0) {
        newEntries = await getTitlesForFoiList(globalContext, indexFoiCitingFoisList, newCitingArticleFoiList) || {};
      } else {
        let newFoi = parseInt(newCitingArticleFoi.toString().replace(/\D/g, ''));
        if (newFoi == indexFoi) {
          globalContext.openAlert({ "header": "Error", "content": "You can't add a Citation as citing itself." });
          setLoading(false);
          return;
        } else {
          if (!indexFoiCitingFoisList.includes(newFoi)) {
            //Confirm that it's a real Citation and that the user has permission to view it
            const body = {
              'functionid': 'getresourcetitle',
              'resourceid': newFoi,
              'resourcetype': "Citation",
              'idToken': ''
            };
            let response = await submitToFevirServer(globalContext, 60000, body, true, false);
            if (response?.success) {
              newEntries = { [newFoi]: response.title };
            } else {
              globalContext.openAlert({ "header": "Error", "content": "Can't verify that FOI. Maybe it's not a Citation resource or you don't have permission." });
              setLoading(false);
              return;
            }
          } else {
            globalContext.openAlert({ "header": "Error", "content": "Can't add, that FOI already exists in the list." });
            setLoading(false);
            return;
          }
        }
      }
      if (newEntries && Object.keys(newEntries)?.length > 0) {
        newFoisList = Object.keys(newEntries).map(Number);
        setChangesMade(true);
        //setIndexFois(prevState => { return [...prevState, newFoi] });
        setCitingArticlesModalState(prevState => {
          let newState = { ...prevState, newCitingArticleFoi: "", newCitingArticlePmid: "" };
          for (let newFoi in newEntries) {
            newState[newFoi] = true;
          }
          return newState;
        });
        setIncludingNewFoiTitles(prevState => { return { ...prevState, ...newEntries }; });
        setCitingFois(prevState => { return { ...prevState, [indexFoi]: indexFoiCitingFoisList.concat(newFoisList) }; });
      }
    }
    setLatestFois(newFoisList);
    setLoading(false);
  }

  useEffect(async () => {
    if (risModalState.newFois && risModalState.newFois?.length > 0) {
      addCitingArticle(risModalState.newFois);
    }
  }, [risModalState.newFois]);

  useEffect(async () => {
    if (citingArticlesModalState.modalOpen && !loading) {
      if (adminState === false || (indexFois && !indexFois.includes(parseInt(indexFoi)))) { //Checks to be sure that Index FOI exists in the indexFois
        setCitingArticlesModalState({ modalOpen: false });
      } else {
        let foisToGetTitles = [];
        if (citingFois && citingFois[indexFoi]) {
          for (let foi of citingFois[indexFoi]) {
            if (includingNewFoiTitles[foi] === undefined) {
              foisToGetTitles.push(foi);
            }
          }
        }

        if (foisToGetTitles?.length > 0) {
          setLoading(true);
          const body = {
            'functionid': 'getresourcetitle',
            'resourceids': foisToGetTitles,
            'resourcetype': "Citation",
            'idToken': ''
          };
          let response = await submitToFevirServer(globalContext, 60000, body, true, false);
          if (response?.success) {
            setIncludingNewFoiTitles(response.titlesbyfoi)
          }
          setLoading(false);
        }
      }
    }
  }, [citingArticlesModalState.modalOpen, loading]);

  const modalSaveAndClose = () => {
    let newCitingFoisList = [];
    if (citingFois[indexFoi]) {
      for (let foi of citingFois[indexFoi]) {
        if (citingArticlesModalState[foi] !== false) {   //if it's true or undefined then we are keeping it
          newCitingFoisList.push(foi);
        }
      }
      newCitingFoisList = [...new Set(newCitingFoisList)]; //Remove duplicate FOIs
    }
    let newCitingFois = { ...citingFois, [indexFoi]: newCitingFoisList };
    editInvestigation(addToast, globalContext, setCitingArticlesModalState, setRisModalState, setLoading, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, newCitingFois);
    setLatestFois([]);
    setChangesMade(false);
  }

  const modalSaveAndCloseAsk = () => {
    if (citingArticlesModalState.newCitingArticleFoi) {
      globalContext.openAlert({ "header": "", "content": "Please either clear all fields or click the \"Add\" button." });
    } else {
      modalSaveAndClose();
    }
  }

  const modalActualClose = () => {
    setChangesMade(false);
    setCitingFois(toolState.loadedCitingFois);
    setCitingArticlesModalState({ modalOpen: false });
    setRisModalState({ modalOpen: false }); //Clears the new FOIs loaded from the modal too
    setLatestFois([]);
    setLoading(false);
    //setCitingArticlesModalState(prevState => { return { ...prevState, modalOpen: false }; }); 
  }

  const modalClose = (dont) => {
    if (changesMade || risModalState.newFois?.length > 0) {
      if (!dont) {
        globalContext.openConfirmation({ "header": "You have unsaved changes", "content": "", "saveFunction": modalSaveAndClose, "runFunction": modalActualClose, "buttonColorSave": "green", "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextSave": "Save Changes", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Keep Working" });
      }
    } else {
      modalActualClose();
    }
  }

  let modalContent = <div id="citingArticlesModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#FADEEE" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      {changesMade &&
        <div style={{ position: "absolute", left: "14px", zIndex: "10" }}>
          <Button className="formButton" color='green' style={{ padding: "6px", paddingLeft: "60px", paddingRight: "60px" }} content="Save"
            onClick={modalSaveAndCloseAsk}
          />
        </div>
      }
      <Button className="formButton negative"
        style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
        onClick={() => { modalClose(); }}
      />
      <div style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        <h2 style={{ marginBottom: "0px !mportant" }}>{citingFois[indexFoi]?.length ? "Change" : "Load"} Articles Citing This Index Article</h2>
        <h3 className="noTopBottomMargins" style={{ fontWeight: "bold" }}><a style={{ fontWeight: "normal" }} href={"/resources/Citation/" + indexFoi} target="_blank">{indexFoi}</a> <i>{foiAuthors[indexFoi]} {foiYears[indexFoi]}</i>: {foiTitles[indexFoi]?.substring(0, 120)}</h3>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <div>
      <div>
        <TextField style={{ width: "160px" }}
          className="inputField" type='number' size="small" variant='outlined'
          label={'Add a citation by FOI'}
          value={citingArticlesModalState.newCitingArticleFoi || ""}
          onChange={(e) => { setCitingArticlesModalState(prevState => { return { ...prevState, newCitingArticleFoi: e.target.value, newCitingArticlePmid: "" } }); }}
          onKeyUp={(e) => { if (e.key === "Enter") { addCitingArticle(); } }}
        />
        &nbsp;&nbsp;&nbsp;
        <TextField style={{ width: "370px" }}
          className="inputField" type='text' size="small" variant='outlined'
          label={'Add a citation by PMID, PMIDs List, or Search Query'}
          value={citingArticlesModalState.newCitingArticlePmid || ""}
          onChange={(e) => { setCitingArticlesModalState(prevState => { return { ...prevState, newCitingArticleFoi: "", newCitingArticlePmid: e.target.value } }); }}
          onKeyUp={(e) => { if (e.key === "Enter") { addCitingArticle(); } }}
        />
        &nbsp;&nbsp;&nbsp;
        <Button /*  "color": "#000000", */
          className="formButton"
          style={{ width: "120px", marginTop: "3px !important", color: "#000000" }}
          content="+ Add"
          disabled={(!citingArticlesModalState.newCitingArticleFoi && !citingArticlesModalState.newCitingArticlePmid)}
          onClick={() => { addCitingArticle(); }}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button /*  "color": "#000000", */
          className="formButton"
          style={{ width: "120px", marginTop: "3px !important", color: "#000000" }}
          content="Add via RIS"
          disabled={(citingArticlesModalState.newCitingArticleFoi || citingArticlesModalState.newCitingArticlePmid) ? true : false}
          onClick={() => { setRisModalState({ modalOpen: true }); }}
        />
      </div>
      <br />
      {citingFois[indexFoi]?.map((foi, index) => {
        let checked = citingArticlesModalState[foi];
        if (checked === undefined) {
          checked = true;
        }
        let entryStyle = { pointerEvents: "none" };
        if (latestFois.includes(foi)) {
          entryStyle["backgroundColor"] = "#FFFACD";
        }
        return <span key={index}>
          <FormGroup style={entryStyle}>
            <FormControlLabel control={<Checkbox size="medium" checked={checked} style={{ pointerEvents: "auto" }}
              onChange={(e) => { setChangesMade(true); setCitingArticlesModalState(prevState => { return { ...prevState, [foi]: e.target.checked } }); }}
            />} label={<a href={"/resources/Citation/" + foi} target="_blank" style={{ pointerEvents: "auto" }}>FOI:{foi} - {foiAuthors[foi] || ""} {foiYears[foi] || ""} - {includingNewFoiTitles[foi]}</a>} />
          </FormGroup>
        </span>
      })}
    </div>
  </div>;
  return <div onKeyDown={event => handleKeyDown(event, modalSaveAndClose, changesMade)}>
    <Modal
      className="radarModal"
      tabIndex="0"
      style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer className="radarModal" tabIndex="0" style={{ backgroundColor: "#00000077" }} />}
      open={citingArticlesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      closeOnEscape
      closeOnDimmerClick
      onClose={() => modalClose(true)}
    />
  </div>
}

const RatersFilterDropdownMenu = ({ open, setOpen, headerLabel, sufficientNumberOfRatings, raterFilterSelection, setRaterFilterSelection, raterFilterSearch, setRaterFilterSearch }) => {
  //const [open, setOpen] = useState(false);

  const toggleFilterSelection = (filterType) => {
    if (raterFilterSelection !== filterType) { setRaterFilterSelection(filterType); } else { setRaterFilterSelection("showall"); }
    setOpen(false);
  }

  useEffect(() => {
    if (raterFilterSearch === "") {
      setRaterFilterSelection("showall");
    } else {
      setRaterFilterSelection("showsearch");
    }
  }, [raterFilterSearch]);

  return <>
    <div style={{ padding: "8px" }} onClick={() => { setOpen(prevState => !prevState); }}>
      <span style={{ whiteSpace: "pre-wrap" }}>{headerLabel}</span>
      <span style={{ position: "absolute", fontSize: "10px", right: "0px", bottom: "6px" }}>▼</span>
      <Dropdown icon={<></>} style={{ padding: "0px" }}
        open={open}>
        <DropdownMenu style={{ left: "-28px", marginTop: "15px", zIndex: "100" }} open={open}>
          {(raterFilterSearch != null) && <><DropdownItem>
            <Checkbox onClick={() => { toggleFilterSelection("showsearch"); }}
              className="checkboxLessPadding" size="medium" checked={raterFilterSelection === "showsearch"}
              style={{ pointerEvents: "auto" }} disabled={!raterFilterSearch} />
            <TextField style={{ width: "396px", marginTop: "0px", marginLeft: "1px" }}
              className="inputField textInputSmallerFont textSmaller" type='text' label="Search: Title, Author, Year" size="small" variant='outlined'
              value={raterFilterSearch}
              onKeyDown={(e) => { if (e.code === 'Space') { e.stopPropagation(); } else if (e.code === "Enter" || e.code === "NumpadEnter") { setOpen(false); } }} /* Because Semantic-UI Dropdown Menus don't allow space input, so I go around it. The Enter key closes the menu so it acts like a submit. */
              onChange={(e) => { setRaterFilterSearch(e.target.value); }}
            />
          </DropdownItem>
            <DropdownDivider className="noMargins" /></>}
          <DropdownItem onClick={() => { toggleFilterSelection("shownotstarted"); }}><Checkbox className="checkboxLessPadding" size="medium" checked={raterFilterSelection === "shownotstarted"} style={{ pointerEvents: "auto" }} /> Show Your Not Started Ratings</DropdownItem>
          <DropdownItem onClick={() => { toggleFilterSelection("showstarted"); }}><Checkbox className="checkboxLessPadding" size="medium" checked={raterFilterSelection === "showstarted"} style={{ pointerEvents: "auto" }} /> Show Your Started but Incomplete Ratings (save first)</DropdownItem>
          <DropdownItem onClick={() => { toggleFilterSelection("showcompleted"); }}><Checkbox className="checkboxLessPadding" size="medium" checked={raterFilterSelection === "showcompleted"} style={{ pointerEvents: "auto" }} /> Show Your Completed Ratings (save first)</DropdownItem>
          {sufficientNumberOfRatings && <DropdownItem onClick={() => { toggleFilterSelection("showinsufficient"); }}><Checkbox className="checkboxLessPadding" size="medium" checked={raterFilterSelection === "showinsufficient"} style={{ pointerEvents: "auto" }} /> Show Articles with Less Than {sufficientNumberOfRatings} Ratings</DropdownItem>}
          {sufficientNumberOfRatings && <DropdownItem onClick={() => { toggleFilterSelection("showinsufficientnotcompleted"); }}><Checkbox className="checkboxLessPadding" size="medium" checked={raterFilterSelection === "showinsufficientnotcompleted"} style={{ pointerEvents: "auto" }} /> Show Articles with Less Than {sufficientNumberOfRatings} Ratings And You Didn't Complete</DropdownItem>}
          <DropdownDivider className="noMargins" />
          <DropdownItem onClick={() => { if (raterFilterSelection !== "showall") { setRaterFilterSelection("showall"); setOpen(false); } }}><Checkbox className="checkboxLessPadding" size="medium" checked={raterFilterSelection === "showall"} style={{ pointerEvents: "auto" }} /> Show All</DropdownItem>
          {/*<DropdownItem text='Open...' description='ctrl + o' />*/}
          {/*<DropdownItem text='Save as...' description='ctrl + s' />*/}
        </DropdownMenu>
      </Dropdown>
    </div>
    {open && <div style={{ position: "fixed", cursor: "default", top: "0", left: "0", width: "100%", height: "100%", zIndex: "-1" }} title="" onClick={() => { setOpen(false); }} />} {/* To close out of the dropdown menu if you click outside of it, also doesn't allow interaction with the table buttons, for better or for worse. */}
  </>
}

const RatersColumnHeaderCell = ({ open, setOpen, headerLabel, titleText, width, fontSize, verticalAlign, sufficientNumberOfRatings, raterFilterSelection, setRaterFilterSelection, raterFilterSearch, setRaterFilterSearch, disabled }) => {

  return <Table.HeaderCell style={{ verticalAlign: verticalAlign, width: width, fontSize: fontSize, padding: "0px", "cursor": disabled ? "" : "pointer" }} title={titleText} >
    {disabled ?
      <span style={{ whiteSpace: "pre-wrap" }}>{headerLabel}</span>
      :
      <RatersFilterDropdownMenu {...{ open, setOpen, headerLabel, sufficientNumberOfRatings, raterFilterSelection, setRaterFilterSelection, raterFilterSearch, setRaterFilterSearch }} />
    }
    {/* Above is how to pass multiple props without naming the key and value twice*/}
  </Table.HeaderCell>
}

const paginateFois = (ratingsCount, sufficientNumberOfRatings, raterFilterSelection, fois, activePage, ratingDictionary, foiAuthors = undefined, foiYears = undefined, foiTitles = undefined, raterFilterSearch = undefined, indexFoi = undefined) => {
  let visibleFois = fois;
  let paginatedFois = [];
  if (sufficientNumberOfRatings && (raterFilterSelection === "showinsufficient" || raterFilterSelection === "showinsufficientnotcompleted")) {
    let filteredFois = [];
    let countDict;
    if (indexFoi) {
      countDict = ratingsCount["citationratings"][indexFoi] || {};
    } else {
      countDict = ratingsCount["indexratings"] || {};
    }
    for (let foi of fois) {
      let numberOfRaters = countDict[foi] || 0;
      if (numberOfRaters < sufficientNumberOfRatings) {
        if (raterFilterSelection === "showinsufficientnotcompleted") {
          if (!(ratingDictionary[foi]?.["completed"])) {
            filteredFois.push(foi);
          }
        } else {
          filteredFois.push(foi);
        }
      }
    }
    visibleFois = filteredFois;
  } else if (raterFilterSelection === "shownotstarted") {
    let filteredFois = [];
    for (let foi of fois) {
      if (!(ratingDictionary[foi]?.["started"])) {
        filteredFois.push(foi);
      }
    }
    visibleFois = filteredFois;
  } else if (raterFilterSelection === "showstarted") {
    let filteredFois = [];
    for (let foi of fois) {
      if (ratingDictionary[foi] && (ratingDictionary[foi]["started"] && !ratingDictionary[foi]["completed"])) {
        filteredFois.push(foi);
      }
    }
    visibleFois = filteredFois;
  } else if (raterFilterSelection === "showcompleted") {
    let filteredFois = [];
    for (let foi of fois) {
      if (ratingDictionary[foi]?.["completed"]) {
        filteredFois.push(foi);
      }
    }
    visibleFois = filteredFois;
  } else if (raterFilterSelection === "showsearch" && raterFilterSearch) {
    //raterFilterSearch
    let filteredFois = [];
    for (let foi of fois) {
      let title = ((foiAuthors[foi] || "") + " - " + (foiYears[foi] || "") + " - " + (foiTitles[foi])).toLowerCase();
      let search = raterFilterSearch.replaceAll("\"", "").toLowerCase();  //Doesn't do exact quote matching
      if (title.includes(search)) {
        filteredFois.push(foi);
      } else if (search.includes(" ")) {
        //It will do a more thorough search by checking to see if each individual word shows up in the string. Order of words don't matter.
        let searchTerms = search.split(" ");
        let count = 0;
        for (let term of searchTerms) {
          if (term) {
            if (title.includes(term)) {
              count++;
            }
          } else {
            count++;
          }
        }
        if (count === searchTerms.length) {
          filteredFois.push(foi);
        }
      }
    }
    visibleFois = filteredFois;
  }
  if (visibleFois) {
    paginatedFois = visibleFois.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage);
  }
  return [visibleFois, paginatedFois];
}

const agreementCheck = (agreementValues) => {
  let text = "";
  let options = agreementValues;
  let backgroundColor = "#E4E4E4";
  let inAgreement;
  if (agreementValues?.length === 1) {
    text = agreementValues?.[0];
    backgroundColor = "#81F57D";
    inAgreement = true;
  } else {
    text = "MISMATCH";
    backgroundColor = "#F57D7F";
    inAgreement = false;
  }
  let inAgreementWholeCell = inAgreement;
  return [text, options, backgroundColor, inAgreement, inAgreementWholeCell];
}

const processAgreementInfoQuestion = (question, rating, agreementInfo, ratingQuestion = null, allowBlanks = null, blankValue = "(blank)") => {
  ratingQuestion = ratingQuestion || question;
  let answer = rating[ratingQuestion]?.trim();
  if (allowBlanks && !answer) {
    answer = blankValue;
  }
  if (answer && !agreementInfo[question].includes(answer)) {
    agreementInfo[question].push(answer);
  }
}

const processIndexAgreementInfo = (rating, agreementInfo) => {
  processAgreementInfoQuestion("questionarticlefirstpublished", rating, agreementInfo);
  agreementInfo["questionarticlefirstpublishedInfo"] = agreementCheck(agreementInfo["questionarticlefirstpublished"]);

  processAgreementInfoQuestion("questionresultsinpreprint", rating, agreementInfo);

  processAgreementInfoQuestion("questionstudyinregistry", rating, agreementInfo);
  let questionresultsinpreprintTemp = agreementCheck(agreementInfo["questionresultsinpreprint"]);
  let questionresultsinpreprintValue = questionresultsinpreprintTemp[0];
  agreementInfo["questionresultsinpreprintInfo"] = questionresultsinpreprintTemp;
  agreementInfo["questionresultsinpreprintInfo"][0] = "Results in preprint: " + capitalizeYesNo(questionresultsinpreprintValue);
  if (questionresultsinpreprintValue !== "no") {
    processAgreementInfoQuestion("questionpreprinturl", rating, agreementInfo);
    processAgreementInfoQuestion("questionpreprintdate", rating, agreementInfo);
    let questionpreprinturlTemp = agreementCheck(agreementInfo["questionpreprinturl"]);
    agreementInfo["questionpreprinturlInfo"] = questionpreprinturlTemp;
    agreementInfo["questionpreprinturlInfo"][0] = questionpreprinturlTemp[0]; //I can have a label for it
    let questionpreprintdateTemp = agreementCheck(agreementInfo["questionpreprintdate"]);
    agreementInfo["questionpreprintdateInfo"] = questionpreprintdateTemp;
    agreementInfo["questionpreprintdateInfo"][0] = questionpreprintdateTemp[0]; //I can have a label for it
    if (questionresultsinpreprintValue === "MISMATCH") {
      agreementInfo["questionresultsinpreprintInfo"][0] = "MISMATCH";
      agreementInfo["questionpreprinturlInfo"][0] = "MISMATCH";
      agreementInfo["questionpreprintdateInfo"][0] = "MISMATCH";
      agreementInfo["questionresultsinpreprintInfo"][4] = false;
      agreementInfo["questionpreprinturlInfo"][4] = false;
      agreementInfo["questionpreprintdateInfo"][4] = false;
    }
    agreementInfo["questionresultsinpreprintInfo"][0] += "\n" + agreementInfo["questionpreprinturlInfo"][0];
    agreementInfo["questionresultsinpreprintInfo"][0] += "\n" + agreementInfo["questionpreprintdateInfo"][0];
    agreementInfo["questionresultsinpreprintInfo"][4] = agreementInfo["questionresultsinpreprintInfo"][4] && agreementInfo["questionpreprinturlInfo"][4] && agreementInfo["questionpreprintdateInfo"][4];
    agreementInfo["questionresultsinpreprintInfo"][2] = agreementInfo["questionresultsinpreprintInfo"][4] ? agreementInfo["questionresultsinpreprintInfo"][2] : "#F57D7F";
  }
  //processAgreementInfoQuestion("questionstudyrecordurl", rating, agreementInfo);
  //processAgreementInfoQuestion("questionstudyinregistry", rating, agreementInfo);
  //processAgreementInfoQuestion("questionresultsinregistrydate", rating, agreementInfo);
  let questionstudyinregistryTemp = agreementCheck(agreementInfo["questionstudyinregistry"]);
  let questionstudyinregistryValue = questionstudyinregistryTemp[0];
  agreementInfo["questionstudyinregistryInfo"] = questionstudyinregistryTemp;
  agreementInfo["questionstudyinregistryInfo"][0] = "Study in registry: " + capitalizeYesNo(questionstudyinregistryValue);
  if (questionstudyinregistryValue !== "no") {    //As in yes, or mismatch
    processAgreementInfoQuestion("questionstudyrecordurl", rating, agreementInfo);
    processAgreementInfoQuestion("questionresultsinregistry", rating, agreementInfo);
    let questionstudyrecordurlTemp = agreementCheck(agreementInfo["questionstudyrecordurl"]);
    agreementInfo["questionstudyrecordurlInfo"] = questionstudyrecordurlTemp;
    agreementInfo["questionstudyrecordurlInfo"][0] = questionstudyrecordurlTemp[0];  //I can have a label for it
    let questionresultsinregistryTemp = agreementCheck(agreementInfo["questionresultsinregistry"]);
    let questionresultsinregistryValue = questionresultsinregistryTemp[0];
    if (questionstudyinregistryValue === "MISMATCH") {
      agreementInfo["questionstudyrecordurlInfo"][0] = "MISMATCH";
      agreementInfo["questionstudyrecordurlInfo"][4] = false;
      agreementInfo["questionstudyinregistryInfo"][4] = false;
      questionresultsinregistryValue = "MISMATCH";
    }
    agreementInfo["questionstudyinregistryInfo"][0] += "\n" + agreementInfo["questionstudyrecordurlInfo"][0];
    agreementInfo["questionresultsinregistryInfo"] = questionresultsinregistryTemp;
    agreementInfo["questionresultsinregistryInfo"][0] = "Results in registry: " + capitalizeYesNo(questionresultsinregistryValue);
    agreementInfo["questionstudyinregistryInfo"][0] += "\n" + agreementInfo["questionresultsinregistryInfo"][0];
    agreementInfo["questionstudyinregistryInfo"][4] = agreementInfo["questionstudyinregistryInfo"][4] && agreementInfo["questionstudyrecordurlInfo"][4] && agreementInfo["questionresultsinregistryInfo"][4];
    if (questionresultsinregistryValue !== "no") {
      processAgreementInfoQuestion("questionresultsinregistrydate", rating, agreementInfo);
      let questionresultsinregistrydateTemp = agreementCheck(agreementInfo["questionresultsinregistrydate"]);
      agreementInfo["questionresultsinregistrydateInfo"] = questionresultsinregistrydateTemp;
      agreementInfo["questionresultsinregistrydateInfo"][0] = questionresultsinregistrydateTemp[0]; //I can have a label for it
      if (questionresultsinregistryValue === "MISMATCH") {
        agreementInfo["questionresultsinregistrydateInfo"][0] = "MISMATCH";
        agreementInfo["questionresultsinregistrydateInfo"][4] = false;
        agreementInfo["questionstudyinregistryInfo"][4] = false;
      }
      agreementInfo["questionstudyinregistryInfo"][0] += "\n" + agreementInfo["questionresultsinregistrydateInfo"][0];
      agreementInfo["questionstudyinregistryInfo"][4] = agreementInfo["questionstudyinregistryInfo"][4] && agreementInfo["questionresultsinregistrydateInfo"][4];
      agreementInfo["questionstudyinregistryInfo"][2] = agreementInfo["questionstudyinregistryInfo"][4] ? agreementInfo["questionstudyinregistryInfo"][2] : "#F57D7F";
    }
  }

  if (agreementInfo["questionarticlefirstpublishedInfo"][4] && agreementInfo["questionresultsinpreprintInfo"][4] && agreementInfo["questionstudyinregistryInfo"][4]) {
    agreementInfo["ratingsInCompleteAgreement"] = true;
  } else {
    agreementInfo["ratingsInCompleteAgreement"] = false;
  }
}

const processCitingAgreementInfo = (rating, agreementInfo) => {
  processAgreementInfoQuestion("questionsystematicallyderived", rating, agreementInfo, "systematically-derived", true);
  agreementInfo["questionsystematicallyderivedInfo"] = agreementCheck(agreementInfo["questionsystematicallyderived"]);
  processAgreementInfoQuestion("questionintendedtoguide", rating, agreementInfo, "intended-to-guide", true);
  agreementInfo["questionintendedtoguideInfo"] = agreementCheck(agreementInfo["questionintendedtoguide"]);
  processAgreementInfoQuestion("questionincorporatedintoresults", rating, agreementInfo, "incorporated-into-results", true);
  agreementInfo["questionincorporatedintoresultsInfo"] = agreementCheck(agreementInfo["questionincorporatedintoresults"]);

  if (rating["systematically-derived"] === "yes" && rating["intended-to-guide"] === "yes" && rating["incorporated-into-results"] === "yes") {
    processAgreementInfoQuestion("questiondatefirstpublished", rating, agreementInfo, "questiondatefirstpublished", true, "No Date");
    agreementInfo["questiondatefirstpublishedInfo"] = agreementCheck(agreementInfo["questiondatefirstpublished"]);
  } else if (!agreementInfo["questiondatefirstpublishedInfo"]) {
    agreementInfo["questiondatefirstpublishedInfo"] = [agreementInfo["questiondatefirstpublished"]?.[0], agreementInfo["questiondatefirstpublished"], "#81F57D", true, true];
  }

  if (agreementInfo["questiondatefirstpublishedInfo"][4] && agreementInfo["questionsystematicallyderivedInfo"][4] && agreementInfo["questionintendedtoguideInfo"][4] && agreementInfo["questionincorporatedintoresultsInfo"][4]) {
    agreementInfo["ratingsInCompleteAgreement"] = true;
  } else {
    agreementInfo["ratingsInCompleteAgreement"] = false;
  }
}

const addNoDateToOptions = (options, optionsSetter, indexFoi, key, adjudicatedValue = null) => {
  if (!options.includes("No Date") || (adjudicatedValue && !options.includes(adjudicatedValue))) {
    optionsSetter(prevState => {
      if (prevState[indexFoi][key].includes("No Date") && !(adjudicatedValue && !prevState[indexFoi][key].includes(adjudicatedValue))) {
        return prevState;
      } else {
        let newEntry = {
          ...prevState[indexFoi]
        };
        if (adjudicatedValue && !prevState[indexFoi][key].includes(adjudicatedValue)) {
          newEntry[key] = [...prevState[indexFoi][key], adjudicatedValue, "No Date"];
        } else {
          newEntry[key] = [...prevState[indexFoi][key], "No Date"];
        }
        return {
          ...prevState,
          [indexFoi]: newEntry
        }
      }
    });
  }
}

const submitUpdatesToAdjudicatedIndexRatings = async (globalContext, addToast, investigationId, toolState, setToolState, setReloadState, setLoading, adjudicatedState, setAdjudicatedState, setEditMode, setAdjudicateIndexArticlesModalState) => {

  let changed = false;
  let changedIndexFoiEntries = {};

  let loadedAdjudicatedIndexRatings = JSON.parse(JSON.stringify(toolState["loadedAdjudicatedIndexRatings"]));
  for (let foi in adjudicatedState) {
    if (!isNaN(foi)) {
      let changedAdjudicatedRating = JSON.parse(JSON.stringify(adjudicatedState[foi]));
      if (loadedAdjudicatedIndexRatings[foi] === undefined || JSON.stringify(loadedAdjudicatedIndexRatings[foi]) !== JSON.stringify(changedAdjudicatedRating)) {
        changedIndexFoiEntries[foi] = changedAdjudicatedRating;
        loadedAdjudicatedIndexRatings[foi] = changedAdjudicatedRating;
        changed = true;
      }
      /*
      if (loadedAdjudicatedIndexRatings[foi] === undefined) {
        changedIndexFoiEntries[foi] = changedAdjudicatedRating;
        loadedAdjudicatedIndexRatings[foi] = changedAdjudicatedRating;
        changed = true;
      } else {
        delete loadedAdjudicatedIndexRatings[foi]["lastmodified"];
        delete changedAdjudicatedRating["lastmodified"];
        delete loadedAdjudicatedIndexRatings[foi]["useridlastusermodified"];
        delete changedAdjudicatedRating["useridlastusermodified"];
        delete loadedAdjudicatedIndexRatings[foi]["raterids"];
        delete changedAdjudicatedRating["raterids"];
        delete loadedAdjudicatedIndexRatings[foi]["adjudicatorids"];
        delete changedAdjudicatedRating["adjudicatorids"];
        if (JSON.stringify(loadedAdjudicatedIndexRatings[foi]) !== JSON.stringify(changedAdjudicatedRating)) {
          //Same as above in the first if statement
          changedIndexFoiEntries[foi] = changedAdjudicatedRating;
          loadedAdjudicatedIndexRatings[foi] = changedAdjudicatedRating;
          changed = true;
        }
      }*/
    }
  }

  if (changed) {
    setLoading(true);
    if (setToolState) {
      setToolState(prevState => {
        return {
          ...prevState,
          "loadedAdjudicatedIndexRatings": loadedAdjudicatedIndexRatings || prevState["loadedAdjudicatedIndexRatings"]
        };
      });
    }
    const body = {
      'functionid': 'editadjudicatedindexratings',
      'investid': investigationId,
      'indexfoientries': changedIndexFoiEntries || undefined,
      'idToken': ''
    };

    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (setReloadState) {
      setReloadState(prevState => !prevState);
    }
    if (response?.success) {
      if (response.numbersaved === 0) {
        addToast(`No changes have been made.`, { appearance: 'success' });
      } else if (response.numbersaved === 1) {
        addToast(`${response.numbersaved} adjudicated rating has been saved.`, { appearance: 'success' });
      } else {
        addToast(`${response.numbersaved} adjudicated ratings have been saved.`, { appearance: 'success' });
      }
      if (setEditMode) {
        setEditMode(false);
      }
      setAdjudicatedState(prevState => { return { ...prevState, changesMade: false } });
      if (!setReloadState) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      globalContext.openAlert({ "header": "Error", "content": response.errormessage || "Not successful in saving the changes to the adjudicated ratings. Please contact FEvIR admins." });
    }
  } else {
  }
  if (setAdjudicateIndexArticlesModalState) {
    setAdjudicateIndexArticlesModalState({ modalOpen: false });
  }

}

const submitUpdatesToAdjudicatedCitingArticlesRatings = async (globalContext, addToast, investigationId, indexFoi, toolState, setToolState, setReloadState, setLoading, adjudicatedState, setAdjudicatedState, setEditMode, setAdjudicateCitingArticlesModalState) => {

  let changed = false;
  let changedCitationEntries = {};

  let loadedAdjudicatedCitationRatings = JSON.parse(JSON.stringify(toolState["loadedAdjudicatedCitationRatings"]));
  for (let foi in adjudicatedState[indexFoi]) {
    if (!isNaN(foi)) {
      let changedAdjudicatedRating = JSON.parse(JSON.stringify(adjudicatedState[indexFoi][foi]));
      if (!loadedAdjudicatedCitationRatings[indexFoi]?.[foi] || JSON.stringify(loadedAdjudicatedCitationRatings[indexFoi][foi]) !== JSON.stringify(changedAdjudicatedRating)) {
        console.log(loadedAdjudicatedCitationRatings[indexFoi]?.[foi]);
        console.log(foi);
        changedCitationEntries[foi] = changedAdjudicatedRating;
        if (!loadedAdjudicatedCitationRatings[indexFoi]) {
          loadedAdjudicatedCitationRatings[indexFoi] = {};
        }
        loadedAdjudicatedCitationRatings[indexFoi][foi] = changedAdjudicatedRating;
        changed = true;
      }
    }
  }

  if (changed) {
    setLoading(true);
    if (setToolState) {
      setToolState(prevState => {
        return {
          ...prevState,
          "loadedAdjudicatedCitationRatings": loadedAdjudicatedCitationRatings || prevState["loadedAdjudicatedCitationRatings"]
        };
      });
    }
    const body = {
      'functionid': 'editadjudicatedcitationratings',
      'investid': investigationId,
      'indexfoi': indexFoi,
      'citingfoientries': changedCitationEntries || undefined,
      'idToken': ''
    };

    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (setReloadState) {
      setReloadState(prevState => !prevState);
    }
    if (response?.success) {
      if (response.numbersaved === 0) {
        addToast(`No changes have been made.`, { appearance: 'success' });
      } else if (response.numbersaved === 1) {
        addToast(`${response.numbersaved} adjudicated rating has been saved.`, { appearance: 'success' });
      } else {
        addToast(`${response.numbersaved} adjudicated ratings have been saved.`, { appearance: 'success' });
      }
      if (setEditMode) {
        setEditMode(false);
      }
      setAdjudicatedState(prevState => { return { ...prevState, changesMade: false } });
      if (!setReloadState) {
        setLoading(false);
      }
    } else {
      setLoading(false);
      globalContext.openAlert({ "header": "Error", "content": response.errormessage || "Not successful in saving the changes to the adjudicated ratings. Please contact FEvIR admins." });
    }
  } else {
  }
  if (setAdjudicateCitingArticlesModalState) {
    setAdjudicateCitingArticlesModalState({ modalOpen: false });
  }
}

const StudyTitleLinkCell = ({ cellStyle, foi, author, year, title, pmidQueryURL, ctgovURL, repoSearches }) => {
  //{cellStyle, indexFoi}
  //cellStyle={cellStyle} indexFoi={indexFoi}
  return <Table.Cell style={cellStyle}>
    <a href={"/resources/Citation/" + foi} target="_blank">{author || ""} {year || ""} - {title?.substring(0, 300)}</a>
    <ArticleLinks pmidQueryURL={pmidQueryURL} ctgovURL={ctgovURL} repoSearches={repoSearches} title={title} />
  </Table.Cell>
}

const AdjudicateIndexArticlesModal = ({ loading, setLoading, investigatorState, adjudicateIndexArticlesModalState, setAdjudicateIndexArticlesModalState, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, toolState, setToolState, allUsersIndexCitationRatings, foiTitles, foiPmidQueries, foiCTgovURLs, foiRepoSearches, foiAuthors, foiYears, addToast, globalContext, reloadState, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, setIndexFois, citingFois, indexCitationRatings, setIndexCitationRatings, setDidNamesLoad, ratingsCount }) => {
  const [raterFilterSelection, setRaterFilterSelection] = useState("showall");
  const [activePage, setActivePage] = useState(1);

  //TODO TODO I should also make agreementInfo a state and have it only process on load?
  const [agreementInfoState, setAgreementInfoState] = useState();
  const [adjudicatedState, setAdjudicatedState] = useState({});
  const changesMade = adjudicatedState.changesMade;

  let [visibleFois, paginatedFois] = paginateFois(ratingsCount, sufficientNumberOfRatings, raterFilterSelection, indexFois, activePage, allUsersIndexCitationRatings, undefined, undefined);
  //let visibleFois = indexFois;

  const modalSave = () => {
  }

  const modalSaveAndClose = () => {
  }

  const modalActualClose = () => {
    //setChangesMade(false);
    //setRaterFilterSelection("showall");
    //setIndexCitationRatings(toolState["loadedIndexCitationRatings"]);
    setAdjudicateIndexArticlesModalState({ modalOpen: false });
    setLoading(false);
  }

  const modalClose = (dont) => {
    if (changesMade) {
      if (!dont) {
        globalContext.openConfirmation({ "header": "You have unsaved changes", "content": "", "saveFunction": modalSaveAndClose, "runFunction": modalActualClose, "buttonColorSave": "green", "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextSave": "Save Changes", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Keep Working" });
      }
    } else {
      modalActualClose();
    }
  }

  const loadAdjudicationData = async () => {
    /*
    const body = {
      'functionid': 'getadjudicatedindexratings',
      'investid': investigationId,
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success) {
      setToolState(prevState => { return { ...prevState, loadedAdjudicatedIndexRatings: response.adjudicatedIndexRatings } });
      setAdjudicatedState(response.adjudicatedIndexRatings);
    }
    */
    setAdjudicatedState(toolState.loadedAdjudicatedIndexRatings || {});
  }

  useEffect(async () => {
    //setLoading(true);
    await getInvestigatorNames(globalContext, investigationId, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, setDidNamesLoad, false);
    await loadAdjudicationData();
    //setLoading(false);
  }, [globalContext.userState, toolState.loadedAdjudicatedIndexRatings]);

  useEffect(async () => {
    if (Object.keys(investigatorNames).length) {
      let agreementInfoAll = {};
      for (let indexFoi of visibleFois) {
        let agreementInfo = { questionarticlefirstpublished: [], questionarticlefirstpublishedInfo: [], questionstudyinregistry: [], questionstudyinregistryInfo: [], questionresultsinpreprint: [], questionresultsinpreprintInfo: [], questionpreprinturl: [], questionpreprinturlInfo: [], questionpreprintdate: [], questionpreprintdateInfo: [], questionresultsinregistry: [], questionstudyrecordurl: [], questionstudyrecordurlInfo: [], questionresultsinregistrydate: [], questionresultsinregistrydateInfo: [] };
        for (let investigatorId of Object.keys(investigatorNames)) {
          if (allUsersIndexCitationRatings[investigatorId]) {
            let rating = allUsersIndexCitationRatings[investigatorId][indexFoi];
            if (rating?.completed) {
              processIndexAgreementInfo(rating, agreementInfo);
            }
          }
        }
        agreementInfoAll[indexFoi] = agreementInfo;
      }
      setAgreementInfoState(agreementInfoAll);
    }
  }, [investigatorNames]);

  const TableRowSet = ({ indexFoi, adjudicatedState, setAdjudicatedState, agreementInfoState, setAgreementInfoState, investigatorNames, allUsersIndexCitationRatings, collapsedDefault }) => {
    const [collapsed, setCollapsed] = useState(collapsedDefault);
    let notesWidth = "100%";
    let preprintCellStyle = { verticalAlign: "top" };
    let registryCellStyle = { verticalAlign: "top" };
    let cellStyle = { fontSize: "16px", position: "relative" };

    let numberOfRaters = ratingsCount["indexratings"][indexFoi] || 0;
    let ratersWord = ratingsCount["indexratings"][indexFoi] === 1 ? "Rater" : "Raters";

    //TODO TO DO, replace the empty tag with something else include the key={index}  ?

    //agreementInfo.questionarticlefirstpublished

    const CompositeRow = ({ indexFoi, adjudicatedState, setAdjudicatedState, agreementInfoState, setAgreementInfoState, collapsedDefault }) => {

      const CollapseRowsSymbol = ({ }) => {
        return <span style={{ position: "absolute", top: "2px", left: "2px", zIndex: "10", cursor: "pointer" }} onClick={() => { setCollapsed(prevState => !prevState) }}>{collapsed ? "⮞" : "⮟"}{/* ⮝ */}{/* ⮞ */}{/* ⮟ */}</span>
      }

      let backgroundColor = "#E4E4E4";
      if (numberOfRaters === 0 || !agreementInfoState || !agreementInfoState[indexFoi]) {
        return <Table.Row style={{ backgroundColor: backgroundColor }}>
          <Table.Cell style={{ "textAlign": "center", fontSize: "18px" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>{numberOfRaters}</Table.Cell>
          <StudyTitleLinkCell cellStyle={cellStyle} foi={indexFoi} author={foiAuthors[indexFoi]} year={foiYears[indexFoi]} title={foiTitles[indexFoi]} pmidQueryURL={foiPmidQueries[indexFoi]} repoSearches={foiRepoSearches[indexFoi]} ctgovURL={foiCTgovURLs[indexFoi]} />
          <Table.Cell style={cellStyle}><b>Composite</b></Table.Cell>
          <Table.Cell colSpan="4"><b>Not Started</b></Table.Cell>
        </Table.Row>;

      } else if (numberOfRaters < sufficientNumberOfRatings) {
        return <Table.Row style={{ backgroundColor: backgroundColor }}>
          <Table.Cell style={{ position: "relative", "textAlign": "center", fontSize: "18px" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>
            <CollapseRowsSymbol />
            {numberOfRaters}
          </Table.Cell>
          <StudyTitleLinkCell cellStyle={cellStyle} foi={indexFoi} author={foiAuthors[indexFoi]} year={foiYears[indexFoi]} title={foiTitles[indexFoi]} pmidQueryURL={foiPmidQueries[indexFoi]} repoSearches={foiRepoSearches[indexFoi]} ctgovURL={foiCTgovURLs[indexFoi]} />
          <Table.Cell style={cellStyle}><b>Composite</b></Table.Cell>
          <Table.Cell colSpan="4"><b>Not Enough Ratings for this Article</b></Table.Cell>
        </Table.Row>;
      } else {
        let [questionarticlefirstpublishedText, questionarticlefirstpublishedOptionsTemp, questionarticlefirstpublishedColor, questionarticlefirstpublishedInAgreement, questionarticlefirstpublishedInAgreementWholeCell] = agreementInfoState[indexFoi]?.["questionarticlefirstpublishedInfo"];
        let [questionstudyinregistryText, questionstudyinregistryOptionsTemp, questionstudyinregistryColor, questionstudyinregistryInAgreement, questionstudyinregistryInAgreementWholeCell] = agreementInfoState[indexFoi]?.["questionstudyinregistryInfo"];
        let [questionresultsinpreprintText, questionresultsinpreprintOptionsTemp, questionresultsinpreprintColor, questionresultsinpreprintInAgreement, questionresultsinpreprintInAgreementWholeCell] = agreementInfoState[indexFoi]?.["questionresultsinpreprintInfo"];

        let questionpreprinturlInAgreement = agreementInfoState[indexFoi]?.["questionpreprinturlInfo"]?.[3];
        let questionpreprintdateInAgreement = agreementInfoState[indexFoi]?.["questionpreprintdateInfo"]?.[3];

        let questionresultsinregistryInAgreement = agreementInfoState[indexFoi]?.["questionresultsinregistryInfo"]?.[3];
        let questionstudyrecordurlInAgreement = agreementInfoState[indexFoi]?.["questionstudyrecordurlInfo"]?.[3];
        let questionresultsinregistrydateInAgreement = agreementInfoState[indexFoi]?.["questionresultsinregistrydateInfo"]?.[3];

        let yesNoOptions = [{ "text": "Yes", "value": "yes", "key": "yes" }, { "text": "No", "value": "no", "key": "no" }];
        let questionpreprinturlOptions = agreementInfoState[indexFoi]["questionpreprinturl"].map(value => { return { "text": value, "value": value, "key": value } });
        let questionstudyrecordurlOptions = agreementInfoState[indexFoi]["questionstudyrecordurl"].map(value => { return { "text": value, "value": value, "key": value } });
        let questionarticlefirstpublishedOptions = agreementInfoState[indexFoi]["questionarticlefirstpublished"].map(value => { return { "text": value, "value": value, "key": value } });
        addNoDateToOptions(questionarticlefirstpublishedOptions, setAgreementInfoState, indexFoi, "questionarticlefirstpublished", adjudicatedState[indexFoi]?.["questionarticlefirstpublished"]);
        let questionpreprintdateOptions = agreementInfoState[indexFoi]["questionpreprintdate"].map(value => { return { "text": value, "value": value, "key": value } });
        addNoDateToOptions(questionpreprintdateOptions, setAgreementInfoState, indexFoi, "questionpreprintdate", adjudicatedState[indexFoi]?.["questionpreprintdate"]);
        let questionresultsinregistrydateOptions = agreementInfoState[indexFoi]["questionresultsinregistrydate"].map(value => { return { "text": value, "value": value, "key": value } });
        addNoDateToOptions(questionresultsinregistrydateOptions, setAgreementInfoState, indexFoi, "questionresultsinregistrydate", adjudicatedState[indexFoi]?.["questionresultsinregistrydate"]);

        let adjudicatedquestionarticlefirstpublished = adjudicatedState[indexFoi]?.["questionarticlefirstpublished"];
        let adjudicatedquestionresultsinpreprint = adjudicatedState[indexFoi]?.["questionresultsinpreprint"];
        let adjudicatedquestionpreprinturl = adjudicatedState[indexFoi]?.["questionpreprinturl"];
        let adjudicatedquestionpreprintdate = adjudicatedState[indexFoi]?.["questionpreprintdate"];
        let adjudicatedquestionstudyinregistry = adjudicatedState[indexFoi]?.["questionstudyinregistry"];
        let adjudicatedquestionresultsinregistry = adjudicatedState[indexFoi]?.["questionresultsinregistry"];
        let adjudicatedquestionstudyrecordurl = adjudicatedState[indexFoi]?.["questionstudyrecordurl"];
        let adjudicatedquestionresultsinregistrydate = adjudicatedState[indexFoi]?.["questionresultsinregistrydate"];

        let adjudicatedresultsinpreprintWholeCell = ((questionresultsinpreprintInAgreement || adjudicatedquestionresultsinpreprint) && (questionpreprinturlInAgreement || adjudicatedquestionpreprinturl) && (((questionresultsinpreprintInAgreement && agreementInfoState[indexFoi]?.["questionresultsinpreprint"]?.[0] === "no") || adjudicatedquestionresultsinpreprint === "no") || ((questionpreprinturlInAgreement || adjudicatedquestionpreprinturl) && (questionpreprintdateInAgreement || adjudicatedquestionpreprintdate))));
        let adjudicatedstudyinregistryWholeCell = ((questionstudyinregistryInAgreement || adjudicatedquestionstudyinregistry) && (questionstudyrecordurlInAgreement || adjudicatedquestionstudyrecordurl) && (((questionstudyinregistryInAgreement && agreementInfoState[indexFoi]?.["questionstudyinregistry"]?.[0] === "no") || adjudicatedquestionstudyinregistry === "no") || ((questionstudyrecordurlInAgreement || adjudicatedquestionstudyrecordurl) && ((questionresultsinregistryInAgreement || adjudicatedquestionresultsinregistry) && (((questionresultsinregistryInAgreement && agreementInfoState[indexFoi]?.["questionresultsinregistry"]?.[0] === "no") || adjudicatedquestionresultsinregistry === "no") || questionresultsinregistrydateInAgreement || adjudicatedquestionresultsinregistrydate)))));

        let wholeRowCompleted = (questionarticlefirstpublishedInAgreement || adjudicatedquestionarticlefirstpublished) && (questionresultsinpreprintInAgreementWholeCell || adjudicatedresultsinpreprintWholeCell) && (questionstudyinregistryInAgreementWholeCell || adjudicatedstudyinregistryWholeCell);

        if (agreementInfoState?.[indexFoi]?.ratingsInCompleteAgreement || wholeRowCompleted) {
          backgroundColor = "#81F57D";  //Changes background color of the row to green if there's complete agreement
        }

        return <Table.Row className="adjudicationRow" style={{ backgroundColor: backgroundColor }}>
          <Table.Cell style={{ position: "relative", "textAlign": "center", fontSize: "18px" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>
            <CollapseRowsSymbol />
            {numberOfRaters}
          </Table.Cell>
          <StudyTitleLinkCell cellStyle={cellStyle} foi={indexFoi} author={foiAuthors[indexFoi]} year={foiYears[indexFoi]} title={foiTitles[indexFoi]} pmidQueryURL={foiPmidQueries[indexFoi]} ctgovURL={foiCTgovURLs[indexFoi]} repoSearches={foiRepoSearches[indexFoi]} />
          <Table.Cell style={cellStyle}><b>Composite</b></Table.Cell>
          <Table.Cell style={{ whiteSpace: "pre-wrap", verticalAlign: "top", fontSize: "16px", backgroundColor: adjudicatedquestionarticlefirstpublished ? "#CFFCCF" : questionarticlefirstpublishedColor }}>
            {questionarticlefirstpublishedInAgreement ?
              <i>{questionarticlefirstpublishedText}</i>
              :
              <>
                <span style={{ position: "relative", top: "-8px", left: "-8px" }}><i>{adjudicatedquestionarticlefirstpublished ? "ADJUDICATED" : "MISMATCH"}</i></span>
                <br />
                <RADARDropDownWithAdditions indexFoi={indexFoi} currentValue={adjudicatedquestionarticlefirstpublished || ""} mismatch={!questionarticlefirstpublishedInAgreement} options={questionarticlefirstpublishedOptions} optionsSetter={setAgreementInfoState} stateSetter={setAdjudicatedState} elementKeyEntry={"questionarticlefirstpublished"} />
              </>
            }
          </Table.Cell>
          <Table.Cell style={{ whiteSpace: "pre-wrap", verticalAlign: "top", fontSize: "16px", backgroundColor: adjudicatedresultsinpreprintWholeCell ? "#CFFCCF" : questionresultsinpreprintColor }}>
            {/*questionresultsinpreprintInAgreement*/}
            {questionresultsinpreprintInAgreementWholeCell ?
              <i>{questionresultsinpreprintText}</i>
              :
              <>
                <span style={{ position: "relative", verticalAlign: "top", top: "-8px", left: "-8px" }}><i>{adjudicatedresultsinpreprintWholeCell ? "ADJUDICATED" : "MISMATCH"}</i></span>
                <br />
                {(questionresultsinpreprintInAgreement && agreementInfoState[indexFoi]?.["questionresultsinpreprint"]?.[0]) ? <><i>Results in preprint: {capitalizeYesNo(agreementInfoState[indexFoi]?.["questionresultsinpreprint"]?.[0])}</i></> : <>Results in preprint: <RADARDropDownWithAdditions width="120px" indexFoi={indexFoi} currentValue={adjudicatedquestionresultsinpreprint || (questionresultsinpreprintInAgreement ? agreementInfoState[indexFoi]?.["questionresultsinpreprint"]?.[0] : "") || ""} mismatch={!questionresultsinpreprintInAgreement} options={yesNoOptions} stateSetter={setAdjudicatedState} elementKeyEntry={"questionresultsinpreprint"} /></>}
                {((agreementInfoState[indexFoi]?.["questionresultsinpreprint"]?.[0] === "yes" && !adjudicatedquestionresultsinpreprint && questionresultsinpreprintInAgreement) || adjudicatedquestionresultsinpreprint === "yes") && <>
                  <br />
                  {(questionpreprinturlInAgreement && agreementInfoState[indexFoi]?.["questionpreprinturl"]?.[0]) ? <><i>Preprint URL: {agreementInfoState[indexFoi]?.["questionpreprinturl"]?.[0]}</i></> : <>Preprint URL: <RADARDropDownWithAdditions width="165px" indexFoi={indexFoi} currentValue={adjudicatedquestionpreprinturl || (questionpreprinturlInAgreement ? agreementInfoState[indexFoi]?.["questionpreprinturl"]?.[0] : "") || ""} mismatch={!questionpreprinturlInAgreement} options={questionpreprinturlOptions} optionsSetter={setAgreementInfoState} stateSetter={setAdjudicatedState} elementKeyEntry={"questionpreprinturl"} /></>}
                  <br />
                  {(questionpreprintdateInAgreement && agreementInfoState[indexFoi]?.["questionpreprintdate"]?.[0]) ? <><i>Preprint Date: {agreementInfoState[indexFoi]?.["questionpreprintdate"]?.[0]}</i></> : <>Preprint Date: <RADARDropDownWithAdditions width="143px" indexFoi={indexFoi} currentValue={adjudicatedquestionpreprintdate || (questionpreprintdateInAgreement ? agreementInfoState[indexFoi]?.["questionpreprintdate"]?.[0] : "") || ""} mismatch={!questionpreprintdateInAgreement} options={questionpreprintdateOptions} optionsSetter={setAgreementInfoState} stateSetter={setAdjudicatedState} elementKeyEntry={"questionpreprintdate"} /></>}
                </>}
              </>
            }
          </Table.Cell>
          <Table.Cell style={{ whiteSpace: "pre-wrap", verticalAlign: "top", fontSize: "16px", backgroundColor: adjudicatedstudyinregistryWholeCell ? "#CFFCCF" : questionstudyinregistryColor }}>
            {questionstudyinregistryInAgreementWholeCell ?
              <i>{questionstudyinregistryText}</i>
              :
              <>
                <span style={{ position: "relative", verticalAlign: "top", top: "-8px", left: "-8px" }}><i>{adjudicatedstudyinregistryWholeCell ? "ADJUDICATED" : "MISMATCH"}</i></span>
                <br />
                {(questionstudyinregistryInAgreement && agreementInfoState[indexFoi]?.["questionstudyinregistry"]?.[0]) ? <><i>Study in registry: {capitalizeYesNo(agreementInfoState[indexFoi]?.["questionstudyinregistry"]?.[0])}</i></> : <>Study in registry: <RADARDropDownWithAdditions width="120px" indexFoi={indexFoi} currentValue={adjudicatedquestionstudyinregistry || (questionstudyinregistryInAgreement ? agreementInfoState[indexFoi]?.["questionstudyinregistry"]?.[0] : "") || ""} mismatch={!questionstudyinregistryInAgreement} options={yesNoOptions} stateSetter={setAdjudicatedState} elementKeyEntry={"questionstudyinregistry"} /></>}
                {((agreementInfoState[indexFoi]?.["questionstudyinregistry"]?.[0] === "yes" && !adjudicatedquestionstudyinregistry) || adjudicatedquestionstudyinregistry === "yes") && <>
                  <br />
                  {(questionstudyrecordurlInAgreement && agreementInfoState[indexFoi]?.["questionstudyrecordurl"]?.[0]) ? <><i>Record URL: {agreementInfoState[indexFoi]?.["questionstudyrecordurl"]?.[0]}</i></> : <>Record URL: <RADARDropDownWithAdditions width="165px" indexFoi={indexFoi} currentValue={adjudicatedquestionstudyrecordurl || (questionstudyrecordurlInAgreement ? agreementInfoState[indexFoi]?.["questionstudyrecordurl"]?.[0] : "") || ""} mismatch={!questionstudyrecordurlInAgreement} options={questionstudyrecordurlOptions} optionsSetter={setAgreementInfoState} stateSetter={setAdjudicatedState} elementKeyEntry={"questionstudyrecordurl"} /></>}
                  <br />
                  {(questionresultsinregistryInAgreement && agreementInfoState[indexFoi]?.["questionresultsinregistry"]?.[0]) ? <><i>Results in registry: {capitalizeYesNo(agreementInfoState[indexFoi]?.["questionresultsinregistry"]?.[0])}</i></> : <>Results in registry: <RADARDropDownWithAdditions width="120px" indexFoi={indexFoi} currentValue={adjudicatedquestionresultsinregistry || (questionresultsinregistryInAgreement ? agreementInfoState[indexFoi]?.["questionresultsinregistry"]?.[0] : "") || ""} mismatch={!questionresultsinregistryInAgreement} options={yesNoOptions} stateSetter={setAdjudicatedState} elementKeyEntry={"questionresultsinregistry"} /></>}
                  <br />
                  {((agreementInfoState[indexFoi]?.["questionresultsinregistry"]?.[0] === "yes" && !adjudicatedquestionresultsinregistry && questionresultsinregistryInAgreement) || adjudicatedquestionresultsinregistry === "yes") && <>
                    {(questionresultsinregistrydateInAgreement && agreementInfoState[indexFoi]?.["questionresultsinregistrydate"]?.[0]) ? <><i>Results posted: {agreementInfoState[indexFoi]?.["questionresultsinregistrydate"]?.[0]}</i></> : <>Results posted: <RADARDropDownWithAdditions width="143px" indexFoi={indexFoi} currentValue={adjudicatedquestionresultsinregistrydate || (questionresultsinregistrydateInAgreement ? agreementInfoState[indexFoi]?.["questionresultsinregistrydate"]?.[0] : "") || ""} mismatch={!questionresultsinregistrydateInAgreement} options={questionresultsinregistrydateOptions} optionsSetter={setAgreementInfoState} stateSetter={setAdjudicatedState} elementKeyEntry={"questionresultsinregistrydate"} /></>}
                  </>}
                </>}
              </>
            }
          </Table.Cell>
          <Table.Cell style={{ textAlign: "left", verticalAlign: "top" }}></Table.Cell>
        </Table.Row>
      }
    };

    const UserRatingRows = () => {
      return <>{Object.keys(investigatorNames).map((investigatorId, investigatorIndex) => {
        let userRatingRow = <Table.Row key={investigatorIndex}></Table.Row>;
        let rating = allUsersIndexCitationRatings[investigatorId]?.[indexFoi];
        if (rating?.completed) {
          if (!collapsed) {
            userRatingRow = <Table.Row className="userRatingRow" key={investigatorIndex}>
              <Table.Cell></Table.Cell>
              <StudyTitleLinkCell cellStyle={cellStyle} foi={indexFoi} author={foiAuthors[indexFoi]} year={foiYears[indexFoi]} title={foiTitles[indexFoi]} pmidQueryURL={foiPmidQueries[indexFoi]} ctgovURL={foiCTgovURLs[indexFoi]} repoSearches={foiRepoSearches[indexFoi]} />
              <Table.Cell style={cellStyle}>{investigatorNames[investigatorId]}</Table.Cell>
              <Table.Cell style={cellStyle}>{rating.questionarticlefirstpublished}</Table.Cell>
              <Table.Cell style={preprintCellStyle}>
                <span style={{ color: "#AAAAAA" }}>Results in preprint:</span> {capitalizeYesNo(rating.questionresultsinpreprint)}
                {rating.questionresultsinpreprint === "yes" && <>
                  <div>{rating.questionpreprinturl}</div>
                  <div>{rating.questionpreprintdate}</div>
                </>}
              </Table.Cell>
              <Table.Cell style={registryCellStyle}>
                <div>{rating.questionstudyinregistry && <>
                  <span style={{ color: "#AAAAAA" }}>Study in registry:</span> {capitalizeYesNo(rating.questionstudyinregistry)}
                  {rating.questionstudyinregistry === "yes" && <>
                    <div>{rating.questionstudyrecordurl}</div>
                    <div>{rating.questionresultsinregistry && <><span style={{ color: "#AAAAAA" }}>Results in registry:</span> {capitalizeYesNo(rating.questionresultsinregistry)}
                      {rating.questionresultsinregistry === "yes" && <div>{rating.questionresultsinregistrydate}</div>}
                    </>}</div>
                  </>}
                </>}</div>
              </Table.Cell>
              <Table.Cell style={{ textAlign: "left" }}>{rating.notes}</Table.Cell>
            </Table.Row>
          }
        }

        return userRatingRow;
      })}</>
    }

    return <>
      <CompositeRow indexFoi={indexFoi} adjudicatedState={adjudicatedState} setAdjudicatedState={setAdjudicatedState} agreementInfoState={agreementInfoState} setAgreementInfoState={setAgreementInfoState} collapsedDefault={false} />
      <UserRatingRows />
    </>
  }

  let modalContent = <div id="adjudicateArticlesModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#FCBEB6" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      {changesMade &&
        <div style={{ position: "absolute", left: "14px", zIndex: "10" }}>
          <Button className="formButton" color='green' style={{ padding: "6px", paddingLeft: "60px", paddingRight: "60px" }}
            //content="Save" title="Save and Continue"
            content="Save and Close" title="Save and Close"
            onClick={() => {
              submitUpdatesToAdjudicatedIndexRatings(globalContext, addToast, investigationId, toolState, null, setReloadState, setLoading, adjudicatedState, setAdjudicatedState, setEditMode, setAdjudicateIndexArticlesModalState);
            }}
          />
        </div>}
      <div style={{ float: "right" }}>
        <span style={{ fontSize: "18px", paddingRight: "36px" }}><b><span style={{ color: "#555555" }}>{visibleFois?.length !== indexFois?.length && <>Filtered {visibleFois?.length} out of </>} {indexFois?.length}</span> Items Included</b></span>
        <Button className="formButton negative"
          style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
          onClick={() => { modalClose(); }}
        />
      </div>
      <h2 style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        Adjudicate Index Article Ratings
      </h2>
    </div>
    <br />
    <br />
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
    <Table className='viewmyworktable' style={{ marginTop: "6px", marginBottom: "0px" }}>
      <Table.Header className="unselectable">
        <Table.Row>
          <RatersColumnHeaderCell open={null} setOpen={null} headerLabel={"ℜ"} titleText={"Number of Raters Rated This Article"} verticalAlign={"middle"} width={"38px"} fontSize={"26px"} sufficientNumberOfRatings={sufficientNumberOfRatings} raterFilterSelection={raterFilterSelection} setRaterFilterSelection={setRaterFilterSelection} raterFilterSearch={null} setRaterFilterSearch={null} disabled={true} />
          <Table.HeaderCell style={{ verticalAlign: "bottom" }}><span style={{ whiteSpace: "pre-wrap" }}>Original Study Publication</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom" }}><span style={{ whiteSpace: "pre-wrap" }}>Rater</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "155px" }} ><span style={{ whiteSpace: "pre-wrap" }}>Date Article FIRST Published</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "290px" }} ><span style={{ whiteSpace: "pre-wrap" }}>Date Results in Preprint</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "290px" }} ><span style={{ whiteSpace: "pre-wrap" }}>Registry Listing and Date Results Posted in Registry</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "90px" }} >Notes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {paginatedFois.map((indexFoi, index) => {
          return <TableRowSet indexFoi={indexFoi} adjudicatedState={adjudicatedState} setAdjudicatedState={setAdjudicatedState} agreementInfoState={agreementInfoState} setAgreementInfoState={setAgreementInfoState} investigatorNames={investigatorNames} allUsersIndexCitationRatings={allUsersIndexCitationRatings} />;
        })}
      </Table.Body>
    </Table>
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
  </div>;

  return <div onKeyDown={event => handleKeyDown(event, modalSave, changesMade)}>
    <Modal
      className="radarModal"
      tabIndex="0"
      style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer className="radarModal" tabIndex="0" style={{ backgroundColor: "#00000077" }} />}
      mountNode={document.getElementById('App')} // modal mounted on #App
      open={adjudicateIndexArticlesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      closeOnEscape
      closeOnDimmerClick
      onClose={() => modalClose(true)}
    />
  </div>
}

const AdjudicateCitingArticlesModal = ({ loading, setLoading, investigatorState, adjudicateCitingArticlesModalState, setAdjudicateCitingArticlesModalState, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, toolState, setToolState, allUsersCitationRatings, foiTitles, foiPmidQueries, foiCTgovURLs, foiRepoSearches, foiAuthors, foiYears, addToast, globalContext, reloadState, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, citingFois, indexCitationRatings, setIndexCitationRatings, setDidNamesLoad, ratingsCount }) => {
  const [raterFilterSelection, setRaterFilterSelection] = useState("showall");
  const [activePage, setActivePage] = useState(1);

  //TODO TODO I should also make agreementInfo a state and have it only process on load?
  const [agreementInfoState, setAgreementInfoState] = useState();
  const [adjudicatedState, setAdjudicatedState] = useState({});
  const changesMade = adjudicatedState.changesMade;
  let indexFoi = adjudicateCitingArticlesModalState.indexFoi;

  const [visibleFois, paginatedFois] = paginateFois(ratingsCount, sufficientNumberOfRatings, raterFilterSelection, citingFois[indexFoi], activePage, allUsersCitationRatings[indexFoi], foiAuthors, foiYears, foiTitles, undefined, indexFoi);
  //let visibleFois = citingFois[indexFoi];

  const modalSave = () => {
  }

  const modalSaveAndClose = () => {
  }

  const modalActualClose = () => {
    //setChangesMade(false);
    //setRaterFilterSelection("showall");
    //setIndexCitationRatings(toolState["loadedIndexCitationRatings"]);
    setAdjudicateCitingArticlesModalState({ modalOpen: false });
    setLoading(false);
  }

  const modalClose = (dont) => {
    if (changesMade) {
      if (!dont) {
        globalContext.openConfirmation({ "header": "You have unsaved changes", "content": "", "saveFunction": modalSaveAndClose, "runFunction": modalActualClose, "buttonColorSave": "green", "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextSave": "Save Changes", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Keep Working" });
      }
    } else {
      modalActualClose();
    }
  }

  const loadAdjudicationData = async () => {
    /*
    const body = {
      'functionid': 'getadjudicatedcitationratings',
      'investid': investigationId,
      'idToken': ''
    };
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success) {
      setToolState(prevState => { return { ...prevState, loadedAdjudicatedCitationRatings: response.adjudicatedCitationRatings } });
      setAdjudicatedState(response.adjudicatedCitationRatings);
    }*/
    setAdjudicatedState(toolState.loadedAdjudicatedCitationRatings || {});
  }

  useEffect(async () => {
    //setLoading(true);
    await getInvestigatorNames(globalContext, investigationId, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, setDidNamesLoad, false);
    await loadAdjudicationData();
    //setLoading(false);
  }, [globalContext.userState, toolState.adjudicatedCitationRatings]);

  useEffect(async () => {
    if (Object.keys(investigatorNames).length) {
      let agreementInfoAll = { [indexFoi]: {} };
      for (let foi of visibleFois) {
        let agreementInfo = { "questiondatefirstpublished": [], "questionsystematicallyderived": [], "questionintendedtoguide": [], "questionincorporatedintoresults": [] };
        for (let investigatorId of Object.keys(investigatorNames)) {
          if (allUsersCitationRatings[investigatorId]?.[indexFoi]) {
            let rating = allUsersCitationRatings[investigatorId][indexFoi][foi];
            if (rating?.completed) {
              processCitingAgreementInfo(rating, agreementInfo);
            }
          }
        }
        agreementInfoAll[indexFoi][foi] = agreementInfo;
      }
      setAgreementInfoState(agreementInfoAll);
    }
  }, [investigatorNames]);

  const TableRowSet = ({ indexFoi, foi, adjudicatedState, setAdjudicatedState, agreementInfoState, setAgreementInfoState, investigatorNames, allUsersCitationRatings, collapsedDefault }) => {
    const [collapsed, setCollapsed] = useState(collapsedDefault);
    let notesWidth = "100%";
    let preprintCellStyle = { verticalAlign: "top" };
    let registryCellStyle = { verticalAlign: "top" };
    let cellStyle = { fontSize: "16px", position: "relative" };

    let numberOfRaters = ratingsCount["citationratings"][indexFoi]?.[foi] || 0;

    let ratersWord = ratingsCount["citationratings"][indexFoi]?.[foi] === 1 ? "Rater" : "Raters";

    //TODO TO DO, replace the empty tag with something else include the key={index}  ?

    //agreementInfo.questionarticlefirstpublished

    const CompositeRow = ({ indexFoi, foi, adjudicatedState, setAdjudicatedState, agreementInfoState, setAgreementInfoState, collapsedDefault }) => {

      const YesNoCompositeCell = ({ adjudicatedValue, question, questionInAgreement, questionText, questionColor, options, openUpOtherYesNo }) => {
        return <Table.Cell style={{ whiteSpace: "pre-wrap", verticalAlign: "top", fontSize: "16px", backgroundColor: adjudicatedValue ? "#CFFCCF" : (openUpOtherYesNo ? "#F57D7F" : questionColor) }}>
          {questionInAgreement && !openUpOtherYesNo && !adjudicatedValue ?
            <i>{capitalizeYesNoAndCheckNotBlank(questionText)}</i>
            :
            <>
              <span style={{ position: "relative", verticalAlign: "top", top: "-8px", left: "-8px" }}><i>{adjudicatedValue ? "ADJUDICATED" : "MISMATCH"}</i></span>
              <br />
              <RADARDropDownWithAdditions width="110px" indexFoi={indexFoi} foi={foi} currentValue={adjudicatedValue || ""} mismatch={true} options={options} stateSetter={setAdjudicatedState} elementKeyEntry={question} />
            </>
          }
        </Table.Cell>
      }

      const CollapseRowsSymbol = ({ }) => {
        return <span style={{ position: "absolute", top: "2px", left: "2px", zIndex: "10", cursor: "pointer" }} onClick={() => { setCollapsed(prevState => !prevState) }}>{collapsed ? "⮞" : "⮟"}{/* ⮝ */}{/* ⮞ */}{/* ⮟ */}</span>
      }

      let backgroundColor = "#E4E4E4";
      if (numberOfRaters === 0 || !agreementInfoState || !agreementInfoState[indexFoi]) {
        return <Table.Row style={{ backgroundColor: backgroundColor }}>
          <Table.Cell style={{ "textAlign": "center", fontSize: "18px" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>{numberOfRaters}</Table.Cell>
          <StudyTitleLinkCell cellStyle={cellStyle} foi={foi} author={foiAuthors[foi]} year={foiYears[foi]} title={foiTitles[foi]} pmidQueryURL={foiPmidQueries[foi]} ctgovURL={foiCTgovURLs[foi]} repoSearches={foiRepoSearches[foi]} />
          <Table.Cell style={cellStyle}><b>Composite</b></Table.Cell>
          <Table.Cell colSpan="5"><b>Not Started</b></Table.Cell>
        </Table.Row>;

      } else if (numberOfRaters < sufficientNumberOfRatings) {
        return <Table.Row style={{ backgroundColor: backgroundColor }}>
          <Table.Cell style={{ position: "relative", "textAlign": "center", fontSize: "18px" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>
            <CollapseRowsSymbol />
            {numberOfRaters}
          </Table.Cell>
          <StudyTitleLinkCell cellStyle={cellStyle} foi={foi} author={foiAuthors[foi]} year={foiYears[foi]} title={foiTitles[foi]} pmidQueryURL={foiPmidQueries[foi]} ctgovURL={foiCTgovURLs[foi]} repoSearches={foiRepoSearches[foi]} />
          <Table.Cell style={cellStyle}><b>Composite</b></Table.Cell>
          <Table.Cell colSpan="5"><b>Not Enough Ratings for this Article</b></Table.Cell>
        </Table.Row>;
      } else {
        let [questiondatefirstpublishedText, questiondatefirstpublishedOptionsTemp, questiondatefirstpublishedColor, questiondatefirstpublishedInAgreement, questiondatefirstpublishedInAgreementWholeCell] = agreementInfoState[indexFoi]?.[foi]?.["questiondatefirstpublishedInfo"];
        let [questionsystematicallyderivedText, questionsystematicallyderivedOptionsTemp, questionsystematicallyderivedColor, questionsystematicallyderivedInAgreement, questionsystematicallyderivedInAgreementWholeCell] = agreementInfoState[indexFoi]?.[foi]?.["questionsystematicallyderivedInfo"];
        let [questionintendedtoguideText, questionintendedtoguideOptionsTemp, questionintendedtoguideColor, questionintendedtoguideInAgreement, questionintendedtoguideInAgreementWholeCell] = agreementInfoState[indexFoi]?.[foi]?.["questionintendedtoguideInfo"];
        let [questionincorporatedintoresultsText, questionincorporatedintoresultsOptionsTemp, questionincorporatedintoresultsColor, questionincorporatedintoresultsInAgreement, questionincorporatedintoresultsInAgreementWholeCell] = agreementInfoState[indexFoi]?.[foi]?.["questionincorporatedintoresultsInfo"];
        let agreementOnDateFirstPublished = questiondatefirstpublishedInAgreement && questionsystematicallyderivedInAgreement && questionintendedtoguideInAgreement && questionincorporatedintoresultsInAgreement;

        let questiondatefirstpublishedOptions = agreementInfoState[indexFoi][foi]["questiondatefirstpublished"].map(value => { return { "text": value, "value": value, "key": value } });
        if (!questiondatefirstpublishedOptions.includes("No Date") || (adjudicatedState[indexFoi]?.[foi]?.["questiondatefirstpublished"] && !questiondatefirstpublishedOptions.includes(questiondatefirstpublishedText))) {
          setAgreementInfoState(prevState => {
            if (prevState[indexFoi][foi]["questiondatefirstpublished"].includes("No Date") && !(adjudicatedState[indexFoi]?.[foi]?.["questiondatefirstpublished"] && !prevState[indexFoi][foi]["questiondatefirstpublished"].includes(adjudicatedState[indexFoi]?.[foi]?.["questiondatefirstpublished"]))) {
              return prevState;
            } else {
              let newEntry = {
                ...prevState[indexFoi][foi]
              };
              if (adjudicatedState[indexFoi]?.[foi]?.["questiondatefirstpublished"] && !prevState[indexFoi][foi]["questiondatefirstpublished"].includes(adjudicatedState[indexFoi]?.[foi]?.["questiondatefirstpublished"])) {
                newEntry["questiondatefirstpublished"] = [...prevState[indexFoi][foi]["questiondatefirstpublished"], adjudicatedState[indexFoi]?.[foi]?.["questiondatefirstpublished"], "No Date"];
              } else {
                newEntry["questiondatefirstpublished"] = [...prevState[indexFoi][foi]["questiondatefirstpublished"], "No Date"];
              }
              return {
                ...prevState,
                [indexFoi]: {
                  ...prevState[indexFoi],
                  [foi]: newEntry
                }
              }
            }
          });
        }

        let yesNoOptions = [{ "text": "Yes", "value": "yes", "key": "yes" }, { "text": "No", "value": "no", "key": "no" }];

        let adjudicatedquestiondatefirstpublished = adjudicatedState[indexFoi]?.[foi]?.["questiondatefirstpublished"];
        let adjudicatedquestionsystematicallyderived = adjudicatedState[indexFoi]?.[foi]?.["questionsystematicallyderived"];
        let adjudicatedquestionintendedtoguide = adjudicatedState[indexFoi]?.[foi]?.["questionintendedtoguide"];
        let adjudicatedquestionincorporatedintoresults = adjudicatedState[indexFoi]?.[foi]?.["questionincorporatedintoresults"];

        //let wholeRowCompleted = (questionarticlefirstpublishedInAgreement || adjudicatedquestionarticlefirstpublished) && (questionresultsinpreprintInAgreementWholeCell || adjudicatedresultsinpreprintWholeCell) && (questionstudyinregistryInAgreementWholeCell || adjudicatedstudyinregistryWholeCell);
        let threeYesAnswers = (questionsystematicallyderivedText === "yes" || adjudicatedquestionsystematicallyderived === "yes") && (questionintendedtoguideText === "yes" || adjudicatedquestionintendedtoguide === "yes") && (questionincorporatedintoresultsText === "yes" || adjudicatedquestionincorporatedintoresults === "yes");
        let atLeastOneNoAnswerAdjudicated = adjudicatedquestionsystematicallyderived === "no" || adjudicatedquestionintendedtoguide === "no" || adjudicatedquestionincorporatedintoresults === "no";
        let atLeastOneNoAnswerUserComposite = questionsystematicallyderivedText === "no" || questionintendedtoguideText === "no" || questionincorporatedintoresultsText === "no";
        let atLeastOneNoAnswer = atLeastOneNoAnswerAdjudicated || atLeastOneNoAnswerUserComposite;
        let agreementCompleted = (!threeYesAnswers || agreementOnDateFirstPublished || adjudicatedquestiondatefirstpublished) && (questionsystematicallyderivedInAgreement || adjudicatedquestionsystematicallyderived) && (questionintendedtoguideInAgreement || adjudicatedquestionintendedtoguide) && (questionincorporatedintoresultsInAgreement || adjudicatedquestionincorporatedintoresults);
        let wholeRowCompleted = agreementCompleted && (atLeastOneNoAnswer || ((questionsystematicallyderivedText !== "(blank)" || adjudicatedquestionsystematicallyderived) && (questionintendedtoguideText !== "(blank)" || adjudicatedquestionintendedtoguide) && (questionincorporatedintoresultsText !== "(blank)" || adjudicatedquestionincorporatedintoresults)));
        let openUpOtherYesNo = agreementCompleted && !wholeRowCompleted;

        if (agreementInfoState?.[indexFoi]?.ratingsInCompleteAgreement || wholeRowCompleted) {
          backgroundColor = "#81F57D";  //Changes background color of the row to green if there's complete agreement
        }

        return <Table.Row className="adjudicationRow" style={{ backgroundColor: backgroundColor }}>
          <Table.Cell style={{ position: "relative", "textAlign": "center", fontSize: "18px" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>
            <CollapseRowsSymbol />
            {numberOfRaters}
          </Table.Cell>
          <StudyTitleLinkCell cellStyle={cellStyle} foi={foi} author={foiAuthors[foi]} year={foiYears[foi]} title={foiTitles[foi]} pmidQueryURL={foiPmidQueries[foi]} ctgovURL={foiCTgovURLs[foi]} repoSearches={foiRepoSearches[foi]} />
          <Table.Cell style={cellStyle}><b>Composite</b></Table.Cell>
          <YesNoCompositeCell adjudicatedValue={adjudicatedquestionsystematicallyderived} question={"questionsystematicallyderived"} questionInAgreement={questionsystematicallyderivedInAgreement} questionText={questionsystematicallyderivedText} questionColor={questionsystematicallyderivedColor} options={yesNoOptions} openUpOtherYesNo={openUpOtherYesNo} />
          <YesNoCompositeCell adjudicatedValue={adjudicatedquestionintendedtoguide} question={"questionintendedtoguide"} questionInAgreement={questionintendedtoguideInAgreement} questionText={questionintendedtoguideText} questionColor={questionintendedtoguideColor} options={yesNoOptions} openUpOtherYesNo={openUpOtherYesNo} />
          <YesNoCompositeCell adjudicatedValue={adjudicatedquestionincorporatedintoresults} question={"questionincorporatedintoresults"} questionInAgreement={questionincorporatedintoresultsInAgreement} questionText={questionincorporatedintoresultsText} questionColor={questionincorporatedintoresultsColor} options={yesNoOptions} openUpOtherYesNo={openUpOtherYesNo} />
          <Table.Cell style={{ whiteSpace: "pre-wrap", verticalAlign: "top", fontSize: "16px", backgroundColor: threeYesAnswers ? (agreementOnDateFirstPublished ? "#81F57D" : (adjudicatedquestiondatefirstpublished ? "#CFFCCF" : "#F57D7F")) : (atLeastOneNoAnswerUserComposite ? "#81F57D" : (atLeastOneNoAnswerAdjudicated ? "#CFFCCF" : backgroundColor)) }}>
            {threeYesAnswers
              && <>
                {agreementOnDateFirstPublished ?
                  <i>{questiondatefirstpublishedText}</i>
                  :
                  <>
                    <span style={{ position: "relative", top: "-8px", left: "-8px" }}><i>{adjudicatedquestiondatefirstpublished ? "ADJUDICATED" : "MISMATCH"}</i></span>
                    <br />
                    <RADARDropDownWithAdditions indexFoi={indexFoi} foi={foi} currentValue={adjudicatedquestiondatefirstpublished || ""} mismatch={!agreementOnDateFirstPublished} options={questiondatefirstpublishedOptions} optionsSetter={setAgreementInfoState} stateSetter={setAdjudicatedState} elementKeyEntry={"questiondatefirstpublished"} />
                  </>
                }
              </>
            }
          </Table.Cell>
          <Table.Cell style={{ textAlign: "left", verticalAlign: "top" }}></Table.Cell>
        </Table.Row>
      }
    };

    const UserRatingRows = () => {
      return <>{Object.keys(investigatorNames).map((investigatorId, investigatorIndex) => {
        let userRatingRow = <Table.Row key={investigatorIndex}></Table.Row>;
        let rating = allUsersCitationRatings[investigatorId]?.[indexFoi]?.[foi];
        if (rating?.completed) {
          if (!collapsed) {
            userRatingRow = <Table.Row className="userRatingRow" key={investigatorIndex}>
              <Table.Cell></Table.Cell>
              <StudyTitleLinkCell cellStyle={cellStyle} foi={foi} author={foiAuthors[foi]} year={foiYears[foi]} title={foiTitles[foi]} pmidQueryURL={foiPmidQueries[foi]} ctgovURL={foiCTgovURLs[foi]} repoSearches={foiRepoSearches[foi]} />
              <Table.Cell style={cellStyle}>{investigatorNames[investigatorId]}</Table.Cell>
              <Table.Cell style={cellStyle}>{capitalizeYesNo(rating["systematically-derived"])}</Table.Cell>
              <Table.Cell style={cellStyle}>{capitalizeYesNo(rating["intended-to-guide"])}</Table.Cell>
              <Table.Cell style={cellStyle}>{capitalizeYesNo(rating["incorporated-into-results"])}</Table.Cell>
              <Table.Cell style={cellStyle}>{(rating["systematically-derived"] === "yes" && rating["intended-to-guide"] === "yes" && rating["incorporated-into-results"] === "yes") && rating["questiondatefirstpublished"]}</Table.Cell>
              <Table.Cell style={{ textAlign: "left" }}>{rating.notes}</Table.Cell>
            </Table.Row>
          }
        }

        return userRatingRow;
      })}</>
    }

    return <>
      <CompositeRow indexFoi={indexFoi} foi={foi} adjudicatedState={adjudicatedState} setAdjudicatedState={setAdjudicatedState} agreementInfoState={agreementInfoState} setAgreementInfoState={setAgreementInfoState} collapsedDefault={false} />
      <UserRatingRows />
    </>
  }

  let modalContent = <div id="adjudicateArticlesModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#FCBEB6" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      {changesMade &&
        <div style={{ position: "absolute", left: "14px", zIndex: "10" }}>
          <Button className="formButton" color='green' style={{ padding: "6px", paddingLeft: "60px", paddingRight: "60px" }}
            content="Save and Close" title="Save and Close"
            onClick={() => {
              submitUpdatesToAdjudicatedCitingArticlesRatings(globalContext, addToast, investigationId, indexFoi, toolState, null, setReloadState, setLoading, adjudicatedState, setAdjudicatedState, setEditMode, setAdjudicateCitingArticlesModalState);
            }}
          />
        </div>}
      <div style={{ float: "right" }}>
        <span style={{ fontSize: "18px", paddingRight: "36px" }}><b><span style={{ color: "#555555" }}>{visibleFois?.length !== citingFois[indexFoi]?.length && <>Filtered {visibleFois?.length} out of </>} {citingFois[indexFoi]?.length}</span> Items Included</b></span>
        <Button className="formButton negative"
          style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
          onClick={() => { modalClose(); }}
        />
      </div>
      <div style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        <h2 style={{ marginBottom: "0px !mportant" }}>
          Adjudicate Citing Articles Ratings
        </h2>
        <h3 className="noTopBottomMargins" style={{ fontWeight: "bold" }}><a style={{ fontWeight: "normal" }} href={"/resources/Citation/" + indexFoi} target="_blank">{indexFoi}</a> <i>{foiAuthors[indexFoi]} {foiYears[indexFoi]}</i>: {foiTitles[indexFoi]?.substring(0, 120)}</h3>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
    <Table className='viewmyworktable' style={{ marginTop: "6px", marginBottom: "0px" }}>
      <Table.Header className="unselectable">
        <Table.Row>
          <RatersColumnHeaderCell open={null} setOpen={null} headerLabel={"ℜ"} titleText={"Number of Raters Rated This Article"} verticalAlign={"middle"} width={"38px"} fontSize={"26px"} sufficientNumberOfRatings={sufficientNumberOfRatings} raterFilterSelection={raterFilterSelection} setRaterFilterSelection={setRaterFilterSelection} raterFilterSearch={null} setRaterFilterSearch={null} disabled={true} />
          <Table.HeaderCell style={{ verticalAlign: "bottom" }}><span style={{ whiteSpace: "pre-wrap" }}>Citing Publication</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom" }}><span style={{ whiteSpace: "pre-wrap" }}>Rater</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "130px" }}><span style={{ whiteSpace: "pre-wrap" }}>Systematically derived?</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "130px" }}><span style={{ whiteSpace: "pre-wrap" }}>Intended to guide clinical practice?</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "130px" }}><span style={{ whiteSpace: "pre-wrap" }}>Incorporated into results?</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "160px" }}><span style={{ whiteSpace: "pre-wrap" }}>Date FIRST Published</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "87px" }}><span style={{ whiteSpace: "pre-wrap" }}>Notes</span></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {paginatedFois.map((foi, index) => {
          return <TableRowSet indexFoi={indexFoi} foi={foi} adjudicatedState={adjudicatedState} setAdjudicatedState={setAdjudicatedState} agreementInfoState={agreementInfoState} setAgreementInfoState={setAgreementInfoState} investigatorNames={investigatorNames} allUsersCitationRatings={allUsersCitationRatings} />;
        })}
      </Table.Body>
    </Table>
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
  </div>;

  return <div onKeyDown={event => handleKeyDown(event, modalSave, changesMade)}>
    <Modal
      className="radarModal"
      tabIndex="0"
      style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer className="radarModal" tabIndex="0" style={{ backgroundColor: "#00000077" }} />}
      mountNode={document.getElementById('App')} // modal mounted on #App
      open={adjudicateCitingArticlesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      closeOnEscape
      closeOnDimmerClick
      onClose={() => modalClose(true)}
    />
  </div>
}

const RateIndexArticlesModal = ({ loading, setLoading, investigatorState, rateIndexArticlesModalState, setRateIndexArticlesModalState, toolState, setToolState, foiTitles, foiPmidQueries, foiCTgovURLs, foiRepoSearches, foiAuthors, foiYears, addToast, globalContext, reloadState, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, setIndexFois, citingFois, indexCitationRatings, setIndexCitationRatings, ratingsCount }) => {
  const [openRatersFilterDropdown, setOpenRatersFilterDropdown] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [raterFilterSelection, setRaterFilterSelection] = useState("showall");
  const [raterFilterSearch, setRaterFilterSearch] = useState("");

  const [activePage, setActivePage] = useState(1);

  let [visibleFois, paginatedFois] = paginateFois(ratingsCount, sufficientNumberOfRatings, raterFilterSelection, indexFois, activePage, indexCitationRatings, foiAuthors, foiYears, foiTitles, raterFilterSearch, undefined);
  /*
  useEffect(async () => {
    if (rateIndexArticlesModalState.modalOpen && !loading) {
      if (investigatorState === false) {
        setRateIndexArticlesModalState({ modalOpen: false });
      }
    }
  }, [rateIndexArticlesModalState.modalOpen, loading]);
  */

  const modalSave = () => {
    submitUpdatesToRatings(globalContext, addToast, investigationId, null, indexCitationRatings, toolState, setToolState, setReloadState, setLoading, setChangesMade, null, null);
  }

  const modalSaveAndClose = () => {
    submitUpdatesToRatings(globalContext, addToast, investigationId, null, indexCitationRatings, toolState, setToolState, setReloadState, setLoading, setChangesMade, setEditMode, setRateIndexArticlesModalState);
  }

  const modalActualClose = () => {
    //setChangesMade(false);
    //setRaterFilterSelection("showall");
    setIndexCitationRatings(toolState["loadedIndexCitationRatings"]);
    setRateIndexArticlesModalState({ modalOpen: false });
    setLoading(false);
  }

  const modalClose = (dont) => {
    if (changesMade) {
      if (!dont) {
        globalContext.openConfirmation({ "header": "You have unsaved changes", "content": "", "saveFunction": modalSaveAndClose, "runFunction": modalActualClose, "buttonColorSave": "green", "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextSave": "Save Changes", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Keep Working" });
      }
    } else {
      modalActualClose();
    }
  }

  let modalContent = <div id="indexArticlesModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#FADEE6" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      {changesMade &&
        <div style={{ position: "absolute", left: "14px", zIndex: "10" }}>
          <SaveRatingsButtons globalContext={globalContext} addToast={addToast} investigationId={investigationId} indexFoi={null} citationRatings={indexCitationRatings} toolState={toolState} setToolState={setToolState} setChangesMade={setChangesMade} setReloadState={setReloadState} setLoading={setLoading} setEditMode={setEditMode} setModalState={setRateIndexArticlesModalState} />
        </div>}
      <div style={{ float: "right" }}>
        <span style={{ fontSize: "18px", paddingRight: "36px" }}><b><span style={{ color: "#555555" }}>{visibleFois?.length !== indexFois?.length && <>Filtered {visibleFois?.length} out of </>} {indexFois?.length}</span> Items Included</b></span>
        <Button className="formButton negative"
          style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
          onClick={() => { modalClose(); }}
        />
      </div>
      <h2 style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        Rate Index Articles
      </h2>
    </div>
    <br />
    <br />
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
    <Table className='viewmyworktable' style={{ marginTop: "6px", marginBottom: "0px" }}>
      <Table.Header className="unselectable">
        <Table.Row>
          <RatersColumnHeaderCell open={openRatersFilterDropdown} setOpen={setOpenRatersFilterDropdown} headerLabel={"ℜ"} titleText={"Number of Raters Rated This Article"} verticalAlign={"middle"} width={"38px"} fontSize={"26px"} sufficientNumberOfRatings={sufficientNumberOfRatings} raterFilterSelection={raterFilterSelection} setRaterFilterSelection={setRaterFilterSelection} raterFilterSearch={raterFilterSearch} setRaterFilterSearch={setRaterFilterSearch} />
          <Table.HeaderCell style={{ verticalAlign: "bottom", cursor: "pointer" }} onClick={() => { setOpenRatersFilterDropdown(prevState => !prevState); }}>
            <span style={{ whiteSpace: "pre-wrap" }}>Original Study Publication (click to view in new tab)</span>
            <span style={{ position: "absolute", fontSize: "10px", paddingLeft: "4px", bottom: "6px" }}>▼</span>
          </Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "175px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Date Article FIRST Published</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "306px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Date Results in Preprint<br />(if noted in the article)</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "362px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Registry Listing and Date Results Posted in Registry</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "87px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}>Notes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {paginatedFois.map((indexFoi, index) => {

          let questionarticlefirstpublished = "";
          let questionpreprintdate = "";
          let questionresultsinregistrydate = "";
          let questionresultsinpreprint = "";
          let questionstudyinregistry = "";
          let questionresultsinregistry = "";
          let questionpreprinturl = "";
          let questionstudyrecordurl = "";
          let notes = "";
          let notesWidth = "100%";
          let preprintCellStyle = { verticalAlign: "top" };
          let registryCellStyle = { verticalAlign: "top" };
          let cellStyle = { fontSize: "16px", position: "relative" };
          let entry = indexCitationRatings[indexFoi];
          if (entry) {
            questionarticlefirstpublished = entry["questionarticlefirstpublished"] || "";
            questionpreprintdate = entry["questionpreprintdate"] || "";
            questionresultsinregistrydate = entry["questionresultsinregistrydate"] || "";
            questionresultsinpreprint = entry["questionresultsinpreprint"] || "";
            questionstudyinregistry = entry["questionstudyinregistry"] || "";
            questionresultsinregistry = entry["questionresultsinregistry"] || "";
            if (questionresultsinpreprint === "yes") {
              preprintCellStyle["paddingBottom"] = "0px";
            }
            if (questionstudyinregistry === "yes" && questionresultsinregistry === "yes") {
              registryCellStyle["paddingBottom"] = "0px";
            }
            questionpreprinturl = entry["questionpreprinturl"] || "";
            questionstudyrecordurl = entry["questionstudyrecordurl"] || "";
            notes = entry["notes"] || "";
            if (notes) {
              notesWidth = "160px";
            }

            if (entry?.questionarticlefirstpublished?.trim() && (entry?.questionresultsinpreprint === "no" || (entry?.questionresultsinpreprint === "yes" && entry?.questionpreprinturl?.trim() && entry?.questionpreprintdate?.trim())) && (entry?.questionstudyinregistry === "no" || (entry?.questionstudyinregistry === "yes" && entry?.questionstudyrecordurl?.trim() && (entry?.questionresultsinregistry === "no" || (entry?.questionresultsinregistry === "yes" && entry?.questionresultsinregistrydate?.trim()))))) {
              //The row is completed (other than optional notes)
              cellStyle["backgroundColor"] = "rgba(46, 225, 50, 0.26)";
            }

          }
          let numberOfRaters = ratingsCount["indexratings"][indexFoi] || 0;
          let ratersWord = ratingsCount["indexratings"][indexFoi] === 1 ? "Rater" : "Raters";
          return <Table.Row key={index}>
            <Table.Cell style={{ "textAlign": "center", fontSize: "18px", cursor: "default" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>{numberOfRaters}</Table.Cell>
            <Table.Cell style={cellStyle}>
              <a href={"/resources/Citation/" + indexFoi} target="_blank">{foiAuthors[indexFoi] || ""} {foiYears[indexFoi] || ""} - {foiTitles[indexFoi]}</a>
              <ArticleLinks pmidQueryURL={foiPmidQueries[indexFoi]} ctgovURL={foiCTgovURLs[indexFoi]} repoSearches={foiRepoSearches[indexFoi]} title={foiTitles[indexFoi]} />
            </Table.Cell>
            <Table.Cell style={{ "textAlign": "center", "paddingTop": "8px", "paddingBottom": "0px", "paddingRight": "0px", "paddingLeft": "2px" }}>
              <div style={{ paddingLeft: "6px", paddingRight: "6px" }}>
                <DateEntry elementName='questionarticlefirstpublished'
                  indexFoi={indexFoi}
                  setter={setIndexCitationRatings}
                  fieldLabel={'YYYY-MM-DD'}
                  startingValue={questionarticlefirstpublished}
                  setChangesMade={setChangesMade} width={"116px"} />
              </div>
            </Table.Cell>
            <Table.Cell style={preprintCellStyle}>
              <YesNoRadioCheckBoxSwitch elementName={"questionresultsinpreprint"} labelElementName={"Results in preprint? Yes or No?"} extrathin={true} fontSize={"14px"} togglable={true} boxes={true} alternativeValues={["yes", "no"]} alternativeLabels={["Results in preprint", "No preprint"]} parentElement={indexFoi} startingValue={questionresultsinpreprint} setter={setIndexCitationRatings} setChangeAvailableToSaveState={setChangesMade} />
              {questionresultsinpreprint === "yes" && <div>
                <div style={{ margin: "8px" }} />
                <TextField style={{ width: "100%", marginTop: "0px", maxWidth: "328px" }}
                  className="inputField textInputSmallerFont" type='text' label="URL for preprint" size="small" variant='outlined'
                  value={questionpreprinturl}
                  onChange={(e) => { indexRatingFieldChange(setChangesMade, setIndexCitationRatings, indexFoi, "questionpreprinturl", e.target.value); }}
                />
                <div style={{ margin: "8px" }} />
                <DateEntry elementName='questionpreprintdate'
                  indexFoi={indexFoi}
                  setter={setIndexCitationRatings}
                  fieldLabel={'YYYY-MM-DD Preprint'}
                  startingValue={questionpreprintdate}
                  setChangesMade={setChangesMade} width={"232px"} /> {/* "175px" */}
              </div>}
            </Table.Cell>
            <Table.Cell style={registryCellStyle}>
              <YesNoRadioCheckBoxSwitch elementName={"questionstudyinregistry"} labelElementName={"Study in Registry? Yes or No?"} extrathin={true} fontSize={"14px"} togglable={true} boxes={true} alternativeValues={["yes", "no"]} alternativeLabels={["Study in registry", "No registration"]} parentElement={indexFoi} startingValue={questionstudyinregistry} setter={setIndexCitationRatings} setChangeAvailableToSaveState={setChangesMade} />
              {questionstudyinregistry === "yes" && <div>
                <div style={{ margin: "8px" }} />
                <TextField style={{ width: "100%", marginTop: "0px", maxWidth: "338px" }}
                  className="inputField textInputSmallerFont" type='text' label="ID or URL for Study Record" size="small" variant='outlined'
                  value={questionstudyrecordurl}
                  onChange={async (e) => {
                    let possibleNctId = e.target.value.replaceAll(/\s/g, '').replace('\t', '').replace('\n', '').toUpperCase();
                    if (possibleNctId.substring(0, 3) === "NCT" && possibleNctId.length === 11) {
                      //setLoading(true);
                      try {
                        await axios({
                          method: 'GET',
                          url: `https://clinicaltrials.gov/api/v2/studies/${possibleNctId}?format=json`,
                          timeout: 6000,
                        }).then((response) => {
                          let resultsInRegistryFromCtGov = "";
                          let resultsFirstPostDateFromCtGov = "";
                          if (response?.data?.hasResults) {
                            //select the radio button! which is changing the state of that radio
                            resultsInRegistryFromCtGov = "yes";
                            if (response.data.protocolSection?.statusModule?.resultsFirstPostDateStruct?.date) {
                              resultsFirstPostDateFromCtGov = response.data.protocolSection.statusModule.resultsFirstPostDateStruct.date;
                            }
                          } else {
                            //select the radio button! which is changing the state of that radio
                            resultsInRegistryFromCtGov = "no";
                          }
                          setIndexCitationRatings(prevState => {
                            return {
                              ...prevState,
                              [indexFoi]: {
                                ...prevState[indexFoi],
                                "questionstudyrecordurl": possibleNctId,
                                "questionresultsinregistry": resultsInRegistryFromCtGov,
                                "questionresultsinregistrydate": resultsFirstPostDateFromCtGov,
                                "itemChanged": true
                              }
                            };
                          });
                        });
                      } catch (e) { console.log(e); }
                      //setLoading(false);
                    }
                    indexRatingFieldChange(setChangesMade, setIndexCitationRatings, indexFoi, "questionstudyrecordurl", e.target.value);
                  }}
                />
                <div style={{ margin: "8px" }} />
                <YesNoRadioCheckBoxSwitch elementName={"questionresultsinregistry"} labelElementName={"Results in registry? Yes or No?"} extrathin={true} fontSize={"14px"} togglable={true} boxes={true} alternativeValues={["yes", "no"]} alternativeLabels={["Results in registry", "No results in registry"]} parentElement={indexFoi} startingValue={questionresultsinregistry} setter={setIndexCitationRatings} setChangeAvailableToSaveState={setChangesMade} />
                {questionresultsinregistry === "yes" && <div>
                  <div style={{ margin: "8px" }} />
                  <DateEntry elementName='questionresultsinregistrydate'
                    indexFoi={indexFoi}
                    setter={setIndexCitationRatings}
                    fieldLabel={'YYYY-MM-DD Results Posted'}
                    startingValue={questionresultsinregistrydate}
                    setChangesMade={setChangesMade} width={"294px"} /> {/* "215px" */}
                </div>
                }
              </div>}
            </Table.Cell>
            <Table.Cell style={{ textAlign: "left" }}>
              <TextField style={{ width: notesWidth, marginTop: "0px" }}
                className="inputField textInputSmallerFont" type='text' label="Notes" size="small" variant='outlined'
                value={notes}
                onChange={(e) => { indexRatingFieldChange(setChangesMade, setIndexCitationRatings, indexFoi, "notes", e.target.value); }}
              />
            </Table.Cell>
          </Table.Row>
        })}
      </Table.Body>
    </Table>
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
  </div>;
  return <div onKeyDown={event => handleKeyDown(event, modalSave, changesMade)}>
    <Modal
      className="radarModal"
      tabIndex="0"
      style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer className="radarModal" tabIndex="0" style={{ backgroundColor: "#00000077" }} />}
      mountNode={document.getElementById('App')} // modal mounted on #App
      open={rateIndexArticlesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      closeOnEscape
      closeOnDimmerClick
      onClose={() => modalClose(true)}
    />
  </div>
}

const RateCitingArticlesModal = ({ loading, setLoading, investigatorState, rateCitingArticlesModalState, setRateCitingArticlesModalState, toolState, setToolState, foiTitles, foiPmidQueries, foiCTgovURLs, foiRepoSearches, foiAuthors, foiYears, addToast, globalContext, reloadState, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, indexFois, setIndexFois, citingFois, citationRatings, setCitationRatings, ratingsCount }) => {
  const [openRatersFilterDropdown, setOpenRatersFilterDropdown] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [raterFilterSelection, setRaterFilterSelection] = useState("showall");
  const [raterFilterSearch, setRaterFilterSearch] = useState("");
  const [activePage, setActivePage] = useState(1);

  let indexFoi = rateCitingArticlesModalState.indexFoi;

  let [visibleFois, paginatedFois] = paginateFois(ratingsCount, sufficientNumberOfRatings, raterFilterSelection, citingFois[indexFoi], activePage, citationRatings[indexFoi], foiAuthors, foiYears, foiTitles, raterFilterSearch, indexFoi);

  useEffect(async () => {
    if (rateCitingArticlesModalState.modalOpen && !loading) {
      if (investigatorState === false || (indexFois && !indexFois.includes(parseInt(indexFoi))) || !citingFois[indexFoi] || citingFois[indexFoi].length === 0) { //Checks to be sure that Index FOI exists in the indexFois
        setRateCitingArticlesModalState({ modalOpen: false });
      }
    }
  }, [rateCitingArticlesModalState.modalOpen, loading]);

  const modalSave = () => {
    submitUpdatesToRatings(globalContext, addToast, investigationId, indexFoi, citationRatings, toolState, setToolState, setReloadState, setLoading, setChangesMade, null, null);
  }

  const modalSaveAndClose = () => {
    submitUpdatesToRatings(globalContext, addToast, investigationId, indexFoi, citationRatings, toolState, setToolState, setReloadState, setLoading, setChangesMade, setEditMode, setRateCitingArticlesModalState);
  }

  const modalActualClose = () => {
    //setChangesMade(false);
    setCitationRatings(toolState["loadedCitationRatings"]);
    setRateCitingArticlesModalState({ modalOpen: false });
    setLoading(false);
  }

  const modalClose = (dont) => {
    if (changesMade) {
      if (!dont) {
        globalContext.openConfirmation({ "header": "You have unsaved changes", "content": "", "saveFunction": modalSaveAndClose, "runFunction": modalActualClose, "buttonColorSave": "green", "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextSave": "Save Changes", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Keep Working" });
      }
    } else {
      modalActualClose();
    }
  }

  const modalContent = <div id="indexArticlesModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#D2E4FA" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      {changesMade &&
        <div style={{ position: "absolute", left: "14px", zIndex: "10" }}>
          <SaveRatingsButtons globalContext={globalContext} addToast={addToast} investigationId={investigationId} indexFoi={indexFoi} citationRatings={citationRatings} toolState={toolState} setToolState={setToolState} setReloadState={setReloadState} setLoading={setLoading} setChangesMade={setChangesMade} setEditMode={setEditMode} setModalState={setRateCitingArticlesModalState} />
        </div>}
      <div style={{ float: "right" }}>
        <span style={{ fontSize: "18px", paddingRight: "36px" }}><b><span style={{ color: "#555555" }}>{visibleFois?.length !== citingFois[indexFoi]?.length && <>Filtered {visibleFois?.length} out of </>} {citingFois[indexFoi]?.length}</span> Items Included</b></span>
        <Button className="formButton negative"
          style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖" title="Close"
          onClick={() => { modalClose(); }}
        />
      </div>

      <div style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        <h2 style={{ marginBottom: "0px !mportant" }}>
          Rate Citing Articles
        </h2>
        <h3 className="noTopBottomMargins" style={{ fontWeight: "bold" }}><a style={{ fontWeight: "normal" }} href={"/resources/Citation/" + indexFoi} target="_blank">{indexFoi}</a> <i>{foiAuthors[indexFoi]} {foiYears[indexFoi]}</i>: {foiTitles[indexFoi]?.substring(0, 120)}</h3>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
    {/*<br />*/} {/* If you want a second row */}
    <Table className='viewmyworktable'>
      <Table.Header className="unselectable">
        <Table.Row>
          <RatersColumnHeaderCell open={openRatersFilterDropdown} setOpen={setOpenRatersFilterDropdown} headerLabel={"ℜ"} titleText={"Number of Raters Rated This Article"} verticalAlign={"middle"} width={"38px"} fontSize={"26px"} sufficientNumberOfRatings={sufficientNumberOfRatings} raterFilterSelection={raterFilterSelection} setRaterFilterSelection={setRaterFilterSelection} raterFilterSearch={raterFilterSearch} setRaterFilterSearch={setRaterFilterSearch} />
          <Table.HeaderCell style={{ verticalAlign: "bottom", cursor: "pointer" }} onClick={() => { setOpenRatersFilterDropdown(prevState => !prevState); }}>
            <span style={{ whiteSpace: "pre-wrap" }}>Citing Publication (click to view in new tab)</span>
            <span style={{ position: "absolute", fontSize: "10px", paddingLeft: "4px", bottom: "6px" }}>▼</span>
          </Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "150px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Systematically derived?</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "150px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Intended to guide clinical practice?</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "150px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Incorporated into results?</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "176px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Date FIRST Published</span></Table.HeaderCell>
          <Table.HeaderCell style={{ verticalAlign: "bottom", width: "87px" }} onClick={() => { setOpenRatersFilterDropdown(false); }}><span style={{ whiteSpace: "pre-wrap" }}>Notes</span></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {paginatedFois.map((foi, index) => {
          let systematicallyDerivedValue = "";
          let intendedToGuideValue = "";
          if (citationRatings[indexFoi] && citationRatings[indexFoi][foi]) {
            systematicallyDerivedValue = citationRatings[indexFoi][foi]["systematically-derived"] || "";
            intendedToGuideValue = citationRatings[indexFoi][foi]["intended-to-guide"] || "";
          }

          let cellStyle = { fontSize: "16px", position: "relative" };
          let rowAllYes = false;
          let rowHasNo = false;
          if (citationRatings[indexFoi][foi]) {
            if (citationRatings[indexFoi][foi]["systematically-derived"] === "yes" && citationRatings[indexFoi][foi]["intended-to-guide"] === "yes" && citationRatings[indexFoi][foi]["incorporated-into-results"] === "yes") {
              rowAllYes = true;
              if (citationRatings[indexFoi][foi]["questiondatefirstpublished"]) {
                //The row is completed (other than optional notes)
                cellStyle["backgroundColor"] = "rgba(46, 225, 50, 0.26)";
              }
            } else if (citationRatings[indexFoi][foi]["systematically-derived"] === "no" || citationRatings[indexFoi][foi]["intended-to-guide"] === "no" || citationRatings[indexFoi][foi]["incorporated-into-results"] === "no") {
              rowHasNo = true;
              cellStyle["backgroundColor"] = "rgba(245, 56, 70, 0.26)";
            } else {

            }
          }

          let questiondatefirstpublished = "";
          let notes = "";
          let notesWidth = "100%";
          if (citationRatings[indexFoi][foi]) {
            questiondatefirstpublished = citationRatings[indexFoi][foi]["questiondatefirstpublished"] || "";
            notes = citationRatings[indexFoi][foi]["notes"] || "";
            if (notes) {
              notesWidth = "160px";
            }
          }
          let numberOfRaters = ratingsCount["citationratings"][indexFoi]?.[foi] || 0;
          let ratersWord = ratingsCount["citationratings"][indexFoi]?.[foi] === 1 ? "Rater" : "Raters";
          return <Table.Row key={index}>
            <Table.Cell style={{ "textAlign": "center", fontSize: "18px", cursor: "default" }} title={numberOfRaters + " " + ratersWord + " Rated This Article"}>{numberOfRaters}</Table.Cell>
            <Table.Cell style={cellStyle}>
              <a href={"/resources/Citation/" + foi} target="_blank">{foiAuthors[foi] || ""} {foiYears[foi] || ""} - {foiTitles[foi]}</a>
              <ArticleLinks pmidQueryURL={foiPmidQueries[foi]} ctgovURL={foiCTgovURLs[foi]} repoSearches={foiRepoSearches[foi]} title={foiTitles[foi]} />
            </Table.Cell>
            <YesNoCell citationRatings={citationRatings} setCitationRatings={setCitationRatings} setChangesMade={setChangesMade} indexFoi={indexFoi} foi={foi} elementName={"systematically-derived"} />
            <YesNoCell citationRatings={citationRatings} setCitationRatings={setCitationRatings} setChangesMade={setChangesMade} indexFoi={indexFoi} foi={foi} elementName={"intended-to-guide"} />
            <YesNoCell citationRatings={citationRatings} setCitationRatings={setCitationRatings} setChangesMade={setChangesMade} indexFoi={indexFoi} foi={foi} elementName={"incorporated-into-results"} />
            <Table.Cell style={{ "textAlign": "left", "paddingTop": "8px", "paddingBottom": "0px", "paddingRight": "0px" }}>
              {rowAllYes &&
                <DateEntry elementName='questiondatefirstpublished'
                  indexFoi={indexFoi}
                  foi={foi}
                  setter={setCitationRatings}
                  fieldLabel={'YYYY-MM-DD'}
                  startingValue={questiondatefirstpublished}
                  setChangesMade={setChangesMade} width={"116px"} />
              }
            </Table.Cell>
            <Table.Cell style={{ textAlign: "left" }}>
              <TextField style={{ width: notesWidth, marginTop: "0px" }}
                className="inputField textInputSmallerFont" type='text' label="Notes" size="small" variant='outlined'
                value={notes}
                onChange={(e) => { ratingFieldChange(setChangesMade, setCitationRatings, indexFoi, foi, "notes", e.target.value); }}
              />
            </Table.Cell>
          </Table.Row>
        })}
      </Table.Body>
    </Table>
    <PaginationBar activePage={activePage} setActivePage={setActivePage} totalItems={visibleFois.length} />
  </div>;
  return <div onKeyDown={event => handleKeyDown(event, modalSave, changesMade)}>
    <Modal
      className="radarModal"
      tabIndex="0"
      style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
      dimmer={<Modal.Dimmer className="radarModal" tabIndex="0" style={{ backgroundColor: "#00000077" }} />}
      open={rateCitingArticlesModalState?.modalOpen}
      centered={false}
      content={modalContent}
      closeOnEscape
      closeOnDimmerClick
      onClose={() => modalClose(true)}
    />
  </div>
}

const InvitedModal = ({ loading, setLoading, invitedModalState, setInvitedModalState, toolState, investigationId, openToJoin, makeResultsPublic, sufficientNumberOfRatings, adminState, setAdminState, adjudicatorState, setAdjudicatorState, investigatorState, setInvestigatorState, globalContext, setReloadState }) => {

  const modalClose = () => {
    setInvitedModalState({ modalOpen: false });
    setLoading(false);
  }

  let modalContent = <div id="invitedModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#D2FAE4" }}>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <div>
      <div style={{ float: "right" }}>
        <Button className="formButton negative"
          style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖"
          onClick={() => { modalClose(); }}
        />
      </div>
      <div style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        <h2 style={{ marginBottom: "0px !mportant" }}>
          {invitedModalState.invalidInviteCode ? <span style={{ color: "#EE4444" }}>INVALID INVITE CODE</span> : "You have been invited to this investigation"}
        </h2>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    {globalContext.userState.id ?
      <>
        {!invitedModalState.invalidInviteCode &&
          <div><h3 className="noTopBottomMargins" style={{ fontWeight: "bold" }}>
            {(investigatorState && adminState && adjudicatorState) ?
              "You are already an administrator, an adjudicator, and an investigator."
              : <>{adminState ?
                <>You are an administrator and adjudicator for the {toolState.loadedTitle} investigation.<br />Do you want to join as an investigator as well?</>
                :
                <>{invitedModalState.adminInviteCode ?
                  `You have been invited to be an administrator for the ${toolState.loadedTitle} investigation. Do you accept?`
                  :
                  <>{(adjudicatorState && !investigatorState) ?
                    <>You are an adjudicator for the {toolState.loadedTitle} investigation.<br />Do you want to join as an investigator as well?</>
                    :
                    <>{!adjudicatorState && invitedModalState.adjudicatorInviteCode ?
                      `You have been invited to be an adjudicator for the ${toolState.loadedTitle} investigation. Do you accept?`
                      :
                      (adjudicatorState && invitedModalState.adjudicatorInviteCode && !investigatorState) ?
                        <>You are already an adjudicator for the {toolState.loadedTitle} investigation.<br />Do you want to join as an investigator as well?</>
                        :
                        investigatorState ?
                          adjudicatorState ? "You are already an adjudicator and an investigator." : "You are already an investigator."
                          :
                          <>{invitedModalState.inviteCode && `You have been invited to be an investigator for the ${toolState.loadedTitle} investigation. Do you accept?`}</>
                    }</>
                  }</>
                }</>}</>}
            <br />
            <br />
            {(
              (!investigatorState && (invitedModalState.inviteCode || invitedModalState.adjudicatorInviteCode || invitedModalState.adminInviteCode))
              ||
              (!adjudicatorState && invitedModalState.adjudicatorInviteCode) || (!adminState && invitedModalState.adminInviteCode)
            ) && <div>
                <Button className="formButton" color='green' style={{ width: "120px", padding: "8px", zIndex: "10" }} content="Yes"
                  disabled={loading}
                  onClick={async () => {
                    //acceptInvintation();
                    let joinAdmin = (!adminState && invitedModalState.adminInviteCode);
                    let joinAdjudicator = (!adjudicatorState && invitedModalState.adjudicatorInviteCode);
                    setLoading(true);
                    let joined = await joinInvestigation(investigationId, globalContext, setReloadState, null, joinAdmin, joinAdjudicator, invitedModalState.adminInviteCode, invitedModalState.adjudicatorInviteCode, invitedModalState.inviteCode);
                    setLoading(false);
                    if (joined) {
                      setAdminState(prevState => prevState || joinAdmin);
                      setAdjudicatorState(prevState => prevState || joinAdjudicator);
                      if (investigatorState || (!joinAdmin && !joinAdjudicator)) {
                        setInvitedModalState({ modalOpen: false });
                        setReloadState(prevState => !prevState);
                      }
                    }
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button className="formButton" color='red' style={{ width: "120px", padding: "8px", zIndex: "10" }} content="No"
                  disabled={loading}
                  onClick={() => {
                    setInvitedModalState({ modalOpen: false });
                    setLoading(false);
                    setReloadState(prevState => !prevState);
                  }}
                />
              </div>}
          </h3></div>
        }
      </>
      :
      <>Please login. <Button className="formButton logOutLogInButton" style={{ color: "#000000" }} content="Sign In / Create Account" compact onClick={() => { signInFirebase(globalContext); }} /></>
    }
  </div>;


  return <Modal
    style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
    dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
    open={invitedModalState?.modalOpen}
    centered={false}
    content={modalContent}
    closeOnEscape
    closeOnDimmerClick
    onClose={() => modalClose()}
  />
}


const getAndSetInvestigatorNames = async (globalContext, investigationId, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames) => {
  const body = {
    'functionid': 'getinvestigationusers',
    'investid': investigationId,
    'idToken': ''
  };
  let response = await submitToFevirServer(globalContext, 60000, body, true, false);
  if (response?.success) {
    setAdminNames(response.adminnames);
    setAdjudicatorNames(response.adjudicatornames);
    setInvestigatorNames(response.investigatornames);
  }
}
const getInvestigatorNames = async (globalContext, investigationId, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, setDidNamesLoad, forceLoading) => {
  if (!globalContext.userState.loading && (forceLoading || Object.keys(investigatorNames).length === 0)) {
    setDidNamesLoad(prevState => {
      if (prevState === false && globalContext.userState.id && Object.keys(investigatorNames).length === 0 && Object.keys(adminNames).length === 0) {      //prevState is investigatorNames
        getAndSetInvestigatorNames(globalContext, investigationId, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames);
        return true;
      }
      return prevState;
    });

  }
}

const ManageUsersModal = ({ loading, setLoading, manageUsersModalState, setManageUsersModalState, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, toolState, investigationId, openToJoin, makeResultsPublic, sufficientNumberOfRatings, adminState, globalContext, reloadState, setReloadState, adminUsersList, adjudicatorsList, investigatorsList, ownerId, adminInviteCode, setAdminInviteCode, adjudicatorInviteCode, setAdjudicatorInviteCode, investigatorInviteCode, setInvestigatorInviteCode, setDidNamesLoad, addToast }) => {

  const [uncheckedInvestigators, setUncheckedInvestigators] = useState([]);
  const [uncheckedAdjudicators, setUncheckedAdjudicators] = useState([]);
  const [uncheckedAdmin, setUncheckedAdmin] = useState([]);

  useEffect(async () => {
    setLoading(true);
    await getInvestigatorNames(globalContext, investigationId, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, setDidNamesLoad, false);
    setLoading(false);
  }, [globalContext.userState]);

  const modalClose = () => {
    setManageUsersModalState({ modalOpen: false });
    setUncheckedInvestigators([]);
    setUncheckedAdjudicators([]);
    setUncheckedAdmin([]);
    setLoading(false);
  }

  const removeUsers = async (investigatorOrAdmin = "") => {
    setLoading(true);
    const body = {
      'functionid': 'removeinvestigationusers',
      'investid': investigationId,
      'idToken': ''
    };
    if (investigatorOrAdmin === "investigator") {
      body.removeinvestigators = uncheckedInvestigators;
    } else if (investigatorOrAdmin === "admin") {
      body.removeadministrators = uncheckedAdmin;
    } else if (investigatorOrAdmin === "adjudicator") {
      body.removeadjudicators = uncheckedAdjudicators;
    } else {
      body.removeinvestigators = uncheckedInvestigators;
      body.removeadministrators = uncheckedAdmin;
      body.removeadjudicators = uncheckedAdjudicators;
    }
    let response = await submitToFevirServer(globalContext, 60000, body, true, false);
    if (response?.success) {
      setReloadState(prevState => !prevState);
      addToast('Investigation users updated.', { appearance: 'success' });
      if (investigatorOrAdmin === "investigator") {
        setUncheckedInvestigators([]);
      } else if (investigatorOrAdmin === "admin") {
        setUncheckedAdmin([]);
      } else if (investigatorOrAdmin === "adjudicator") {
        setUncheckedAdjudicators([]);
      } else {
        setUncheckedInvestigators([]);
        setUncheckedAdjudicators([]);
        setUncheckedAdmin([]);
      }
      await getInvestigatorNames(globalContext, investigationId, adminNames, setAdminNames, investigatorNames, setInvestigatorNames, adjudicatorNames, setAdjudicatorNames, setDidNamesLoad, true);
    } else {
      globalContext.openAlert({ "header": "Error", "content": "Problem updating the Investigation users. Please contact FEvIR administrators for help." });
    }
    setLoading(false);
  }

  let modalContent = <div id="manageUsersModalContent" style={{ paddingTop: "6px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "40px", width: "100%", height: "100%", overflow: "auto", backgroundColor: "#E4FAD2" }}>
    <div>
      <div style={{ float: "right" }}>
        <Button className="formButton negative"
          style={{ padding: "6px", position: "absolute", right: "14px", zIndex: "10" }} content="✖"
          onClick={() => {
            let content = ""
            if (uncheckedInvestigators.length > 0 && uncheckedAdmin.length > 0) {
              let header = "You removed users without clicking the buttons to update";
              globalContext.openConfirmation({ "header": header, "content": content, "runFunction": modalClose, "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Go Back" });
            } else if (uncheckedInvestigators.length > 0) {
              let header = "You removed investigators without clicking the button to update";
              if (uncheckedInvestigators.length === 1) {
                header = "You removed an investigator without clicking the button to update";
              }
              globalContext.openConfirmation({ "header": header, "content": content, "runFunction": modalClose, "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Go Back" });
            } else if (uncheckedAdmin.length > 0) {
              let header = "You removed admin users without clicking the button to update";
              if (uncheckedAdmin.length === 1) {
                header = "You removed an admin user without clicking the button to update";
              }
              globalContext.openConfirmation({ "header": header, "content": content, "runFunction": modalClose, "buttonColorOkay": "grey", "buttonColorCancel": "grey", "buttonTextOkay": "Discard Changes", "buttonTextCancel": "Go Back" });
            } else {
              modalClose();
            }
          }}
        />
      </div>
      <div style={{ textAlign: "center", position: "absolute", width: "100%", marginTop: "0px", paddingLeft: "12px", right: "12px" }}>
        <h2 style={{ marginBottom: "0px !mportant" }}>Manage the users of this investigation</h2>
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    {loading ?
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      :
      <div>
        {investigatorInviteCode && <><b>Investigator Invite Link:</b> https://fevir.net/radar/{investigationId}#invite={investigatorInviteCode}</>}
        <br />
        {investigatorInviteCode ? <Button className="formButton"
          style={{ color: "#000000", background: "#EAEFF1 !important", width: "230px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
          onClick={async () => {
            changeInviteCode(globalContext, investigationId, true, "investigatorinvite", null, null, setInvestigatorInviteCode);
          }}>
          Disable Investigator Invite
        </Button>
          :
          <Button className="formButton"
            style={{ color: "#000000", background: "#EAEFF1 !important", width: "230px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
            onClick={async () => {
              changeInviteCode(globalContext, investigationId, false, "investigatorinvite", null, null, setInvestigatorInviteCode);
            }}>
            Re-enable Investigator Invite
          </Button>
        }
        &nbsp;&nbsp;&nbsp;
        {investigatorInviteCode && <Button className="formButton"
          style={{ color: "#000000", background: "#EAEFF1 !important", width: "250px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
          onClick={async () => {
            changeInviteCode(globalContext, investigationId, false, "investigatorinvite", null, null, setInvestigatorInviteCode);
          }}>
          Change Investigator Invite Code
        </Button>
        }
        <br /><br />
        <div><span style={{ fontWeight: "bold", fontSize: "22px" }}>The Investigators</span> {uncheckedInvestigators.length > 0 && <Button className="formButton" color='green' style={{ padding: "0px 6px 0px 6px" }} content={"Remove Unchecked User" + (uncheckedInvestigators.length === 1 ? "" : "s")}
          onClick={async () => { removeUsers("investigator"); }} />}</div>
        <br />
        <div style={{ paddingLeft: "24px" }}>
          {investigatorsList.map((investigatorId, investigatorIndex) => {
            let checked = (!uncheckedInvestigators.includes(investigatorId));
            return <div key={investigatorIndex}>
              <Checkbox size="medium" checked={checked} style={{ padding: "0px 0px 4px 0px" }}
                onChange={(e) => {
                  setUncheckedInvestigators(prevState => {
                    if (checked) {
                      return [...prevState, investigatorId];
                    } else {
                      let uncheckedList = [];
                      for (let userId of prevState) {
                        if (userId !== investigatorId) {
                          uncheckedList.push(userId);
                        }
                      }
                      return uncheckedList;
                    }
                  })
                }} /> <span style={{ marginTop: "2px" }}>{investigatorNames[investigatorId]}</span>
              <br />
            </div>
          })}
        </div>
        <br />
        <br />
        <hr />
        <br />
        <br />
        {adjudicatorInviteCode && <><b>Adjudicator Invite Link:</b> https://fevir.net/radar/{investigationId}#adjudicatorinvite={adjudicatorInviteCode}</>}
        <br />
        {adjudicatorInviteCode ? <Button className="formButton"
          style={{ color: "#000000", background: "#EAEFF1 !important", width: "230px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
          onClick={async () => {
            changeInviteCode(globalContext, investigationId, true, "adjudicatorinvite", null, setAdjudicatorInviteCode, null);
          }}>
          Disable Adjudicator Invite
        </Button>
          :
          <Button className="formButton"
            style={{ color: "#000000", background: "#EAEFF1 !important", width: "230px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
            onClick={async () => {
              changeInviteCode(globalContext, investigationId, false, "adjudicatorinvite", null, setAdjudicatorInviteCode, null);
            }}>
            Re-enable Adjudicator Invite
          </Button>
        }
        &nbsp;&nbsp;&nbsp;
        {adjudicatorInviteCode && <Button className="formButton"
          style={{ color: "#000000", background: "#EAEFF1 !important", width: "250px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
          onClick={async () => {
            changeInviteCode(globalContext, investigationId, false, "adjudicatorinvite", null, setAdjudicatorInviteCode, null);
          }}>
          Change Adjudicator Invite Code
        </Button>
        }
        <br /><br />
        <div><span style={{ fontWeight: "bold", fontSize: "22px" }}>The Adjudicators</span> {uncheckedAdjudicators.length > 0 && <Button className="formButton" color='green' style={{ padding: "0px 6px 0px 6px" }} content={"Remove Unchecked User" + (uncheckedAdjudicators.length === 1 ? "" : "s")}
          onClick={async () => { removeUsers("adjudicator"); }} />}</div>
        {/*The entry with the ownerId is disabled*/}
        <br />
        <div style={{ paddingLeft: "24px" }}>
          {adjudicatorsList.map((adjudicatorId, adjudicatorIndex) => {
            let checked = (!uncheckedAdjudicators.includes(adjudicatorId));
            return <div key={adjudicatorIndex}>{adjudicatorId == ownerId ?
              <b>Owner: </b>
              :
              <>{adminUsersList.includes(adjudicatorId) ? <b>Admin: </b> :
                <Checkbox size="medium" checked={checked} style={{ padding: "0px 0px 4px 0px" }}
                  onChange={(e) => {
                    setUncheckedAdjudicators(prevState => {
                      if (checked) {
                        return [...prevState, adjudicatorId];
                      } else {
                        let uncheckedList = [];
                        for (let userId of prevState) {
                          if (userId !== adjudicatorId) {
                            uncheckedList.push(userId);
                          }
                        }
                        return uncheckedList;
                      }
                    });
                  }} />}</>} {adjudicatorNames[adjudicatorId]}<br /></div>
          })}
        </div>
        <br />
        <br />
        <hr />
        <br />
        <br />
        {adminInviteCode && <><b>Administrator Invite Link:</b> https://fevir.net/radar/{investigationId}#admininvite={adminInviteCode}</>}
        <br />
        {adminInviteCode ? <Button className="formButton"
          style={{ color: "#000000", background: "#EAEFF1 !important", width: "200px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
          onClick={async () => {
            changeInviteCode(globalContext, investigationId, true, "admininvite", setAdminInviteCode, null, null);
          }}>
          Disable Admin Invite
        </Button>
          :
          <Button className="formButton"
            style={{ color: "#000000", background: "#EAEFF1 !important", width: "200px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
            onClick={async () => {
              changeInviteCode(globalContext, investigationId, false, "admininvite", setAdminInviteCode, null, null);
            }}>
            Re-enable Admin Invite
          </Button>
        }
        &nbsp;&nbsp;&nbsp;
        {adminInviteCode && <Button className="formButton"
          style={{ color: "#000000", background: "#EAEFF1 !important", width: "220px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
          onClick={async () => {
            changeInviteCode(globalContext, investigationId, false, "admininvite", setAdminInviteCode, null, null);
          }}>
          Change Admin Invite Code
        </Button>
        }
        <br /><br />
        <div><span style={{ fontWeight: "bold", fontSize: "22px" }}>The Administrators</span> {uncheckedAdmin.length > 0 && <Button className="formButton" color='green' style={{ padding: "0px 6px 0px 6px" }} content={"Remove Unchecked User" + (uncheckedAdmin.length === 1 ? "" : "s")}
          onClick={async () => { removeUsers("admin"); }} />}</div>
        {/*The entry with the ownerId is disabled*/}
        <br />
        <div style={{ paddingLeft: "24px" }}>
          {adminUsersList.map((adminId, adminIndex) => {
            let checked = (!uncheckedAdmin.includes(adminId));
            return <div key={adminIndex}>{adminId == ownerId ? <b>Owner: </b> : <Checkbox size="medium" checked={checked} style={{ padding: "0px 0px 4px 0px" }}
              onChange={(e) => {
                setUncheckedAdmin(prevState => {
                  if (checked) {
                    return [...prevState, adminId];
                  } else {
                    let uncheckedList = [];
                    for (let userId of prevState) {
                      if (userId !== adminId) {
                        uncheckedList.push(userId);
                      }
                    }
                    return uncheckedList;
                  }
                });
              }} />} {adminNames[adminId]}<br /></div>
          })}
        </div>
        <br />
        <br />
      </div>
    }
  </div>;

  return <Modal
    style={{ width: "90%", height: "95%", padding: "0px", margin: "0px" }}
    dimmer={<Modal.Dimmer style={{ backgroundColor: "#00000077" }} />}
    open={manageUsersModalState?.modalOpen}
    centered={false}
    content={modalContent}
    closeOnEscape
    closeOnDimmerClick
    onClose={() => { if (uncheckedInvestigators.length === 0 && uncheckedAdmin.length === 0) { modalClose(); } }}
  />
}

const DateEntry = memo(({ elementName, fieldLabel, indexFoi, foi, startingValue, setter, setChangesMade, width }) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const CalendarModal = () => {
    return <div className="calendarModal"><MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        format="yyyy-MM-dd"
        emptyLabel="yyyy-MM-dd"
        value={startingValue ? parseISO(startingValue, "yyyy-MM-dd") : null}
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        onChange={(e) => {
          if (foi) {
            ratingFieldChange(setChangesMade, setter, indexFoi, foi, elementName, format(e, "yyyy-MM-dd"));
          } else {
            indexRatingFieldChange(setChangesMade, setter, indexFoi, elementName, format(e, "yyyy-MM-dd"));
          }
        }}
      />
    </MuiPickersUtilsProvider></div>
  }
  return <div>
    <TextField style={{ width: "100%", maxWidth: width ? width : "150px" }}
      className="inputField" type='text' label={fieldLabel} size="small" variant='outlined'
      value={startingValue || ""}
      onChange={(e) => {
        if (foi) {
          ratingFieldChange(setChangesMade, setter, indexFoi, foi, elementName, e.target.value);
        } else {
          indexRatingFieldChange(setChangesMade, setter, indexFoi, elementName, e.target.value);
        }
      }} />
    <Button style={{ marginTop: "2px", marginLeft: "6px", fontSize: "28px", padding: "0px", backgroundColor: "white" }} className="calendarButton"
      content="📅" onClick={() => { setCalendarOpen(true) }} />
    <CalendarModal />
  </div>
});

const MeasuringTheRatePage = ({ useTitle, investigationId, modalLoad }) => {
  useTitle("FEvIR RADAR Tool");

  const history = useHistory();

  const globalContext = useContext(FevirContext);

  const [investigationLoaded, setInvestigationLoaded] = useState(false);

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [reloadState, setReloadState] = useState(false); //
  const [editMode, setEditMode] = useState(false);
  const [investigationTitle, setInvestigationTitle] = useState("");
  const [investigationDescription, setInvestigationDescription] = useState("");
  const [ownerId, setOwnerId] = useState(null);
  const [adminState, setAdminState] = useState(null);
  const [adjudicatorState, setAdjudicatorState] = useState(null);
  const [investigatorState, setInvestigatorState] = useState(null);
  const [openToJoin, setOpenToJoin] = useState();
  const [makeResultsPublic, setMakeResultsPublic] = useState();
  const [sufficientNumberOfRatings, setSufficientNumberOfRatings] = useState();
  const [ratingsCount, setRatingsCount] = useState({ "indexratings": {}, "citationratings": {} });
  //const [usersWhoContributedToRatings, setUsersWhoContributedToRatings] = useState({ "indexratings": {}, "citationratings": {} });
  //const [userRatingProgress, setUserRatingProgress] = useState({ "indexratings": {}, "citationratings": {} });
  const [adminInviteCode, setAdminInviteCode] = useState();
  const [adjudicatorInviteCode, setAdjudicatorInviteCode] = useState();
  const [investigatorInviteCode, setInvestigatorInviteCode] = useState();
  const [investigatorsList, setInvestigatorsList] = useState();
  const [adjudicatorsList, setAdjudicatorsList] = useState();
  const [adminUsersList, setAdminUsersList] = useState();
  const [adminNames, setAdminNames] = useState({});
  const [investigatorNames, setInvestigatorNames] = useState({});
  const [adjudicatorNames, setAdjudicatorNames] = useState({});
  const [didNamesLoad, setDidNamesLoad] = useState(false);
  const [joinedGroup, setJoinedGroup] = useState(false);

  const [indexFois, setIndexFois] = useState([]);
  const [citingFois, setCitingFois] = useState({});
  const [indexCitationRatings, setIndexCitationRatings] = useState({});
  const [allUsersIndexCitationRatings, setAllUsersIndexCitationRatings] = useState({});
  const [citationRatings, setCitationRatings] = useState({});
  const [allUsersCitationRatings, setAllUsersCitationRatings] = useState({});

  const [toolState, setToolState] = useState({ loadedTitle: "", loadedDescription: "", loadedOpenToJoin: null, loadedMakeResultsPublic: null, loadedSufficientNumberOfRatings: null });

  const [foiTitles, setFoiTitles] = useState({});
  const [foiCTgovURLs, setFoiCTgovURLs] = useState({});
  const [foiPmidQueries, setFoiPmidQueries] = useState({});
  const [foiRepoSearches, setFoiRepoSearches] = useState({});
  const [foiAuthors, setFoiAuthors] = useState({});
  const [foiYears, setFoiYears] = useState({});

  const [indexArticlesModalState, setIndexArticlesModalState] = useState({ modalOpen: false, newInvestigationFoi: "", newInvestigationPmid: "" });
  const [citingArticlesModalState, setCitingArticlesModalState] = useState({ modalOpen: false, newCitingArticleFoi: "", newCitingArticlePmid: "" });
  const [rateIndexArticlesModalState, setRateIndexArticlesModalState] = useState({ modalOpen: false });
  const [rateCitingArticlesModalState, setRateCitingArticlesModalState] = useState({ modalOpen: false });
  const [adjudicateIndexArticlesModalState, setAdjudicateIndexArticlesModalState] = useState({ modalOpen: false });
  const [adjudicateCitingArticlesModalState, setAdjudicateCitingArticlesModalState] = useState({ modalOpen: false });
  const [risModalState, setRisModalState] = useState({ modalOpen: false });

  const [invitedModalState, setInvitedModalState] = useState({ modalOpen: false });
  const [manageUsersModalState, setManageUsersModalState] = useState({ modalOpen: false });

  const [indexNoCitations, setIndexNoCitations] = useState(true);
  const [indexButtonColor, setIndexButtonColor] = useState("blue");
  const [indexLabelColor, setIndexLabelColor] = useState("red");
  const [indexPercentProgressText, setIndexPercentProgressText] = useState("0%");
  const [indexNumberStartedRatings, setIndexNumberStartedRatings] = useState(0);
  const [indexNumberCompletedRatings, setIndexNumberCompletedRatings] = useState(0);

  useEffect(async () => {
    if (investigationId) {
      if (globalContext.userState?.loading === false) {
        setLoading(true);
        try {
          const body = {
            'functionid': 'loadinvestigation',
            'investid': investigationId,
            'idToken': ''
          };
          if (modalLoad.substring(0, 12) === "admininvite=") {
            let adminInviteCode = modalLoad.substring(12);
            if (adminInviteCode) {
              body.admininvitecode = adminInviteCode;
            }
          } else if (modalLoad.substring(0, 7) === "invite=") {
            let inviteCode = modalLoad.substring(7);
            if (inviteCode) {
              body.invitecode = inviteCode;
            }
          } else if (modalLoad.substring(0, 18) === "adjudicatorinvite=") {
            let adjudicatorInviteCode = modalLoad.substring(18);
            if (adjudicatorInviteCode) {
              body.adjudicatorinvitecode = adjudicatorInviteCode;
            }
          }
          let response = await submitToFevirServer(globalContext, 100000, body, true, false);
          if (response?.success) {
            if (!response.youCanJoin) {
              setJoinedGroup(true);
            }
            setInvestigationTitle(response.title);
            setInvestigationDescription(response.description);
            setIndexFois(response.indexfois);
            setCitingFois(response.citingfois || {});
            setIndexCitationRatings(response.indexcitationratings || {});
            setAllUsersIndexCitationRatings(response.allusersindexcitationratings || {});
            setCitationRatings(response.citationratings || {});
            setAllUsersCitationRatings(response.alluserscitationratings || {});
            let loadedIndexFois = response.indexfois;
            let loadedCitingFois = response.citingfois;
            let loadedIndexCitationRatings = response.indexcitationratings;
            //let loadedAllUsersIndexCitationRatings = response.allusersindexcitationratings;
            let loadedCitationRatings = response.citationratings;
            let loadedAdjudicatedIndexRatings = response.adjudicatedIndexRatings || {};
            let loadedAdjudicatedCitationRatings = response.adjudicatedCitationRatings || {};
            //let loadedAllUsersCitationRatings = response.alluserscitationratings;

            if (loadedIndexFois) {
              try { loadedIndexFois = JSON.parse(JSON.stringify(loadedIndexFois)); } catch (e) { }
            }
            if (loadedCitingFois) {
              try { loadedCitingFois = JSON.parse(JSON.stringify(loadedCitingFois)); } catch (e) { }
            }
            if (loadedCitationRatings) {
              try { loadedCitationRatings = JSON.parse(JSON.stringify(loadedCitationRatings)); } catch (e) { }
            }
            if (loadedIndexCitationRatings) {
              try { loadedIndexCitationRatings = JSON.parse(JSON.stringify(loadedIndexCitationRatings)); } catch (e) { }
            }
            if (loadedAdjudicatedIndexRatings) {
              try { loadedAdjudicatedIndexRatings = JSON.parse(JSON.stringify(loadedAdjudicatedIndexRatings)); } catch (e) { }
            }
            if (loadedAdjudicatedCitationRatings) {
              try { loadedAdjudicatedCitationRatings = JSON.parse(JSON.stringify(loadedAdjudicatedCitationRatings)); } catch (e) { }
            }

            setToolState(prevState => { return { ...prevState, loadedTitle: response.title, loadedDescription: response.description, loadedOpenToJoin: response.opentojoin, loadedMakeResultsPublic: response.makeresultspublic, loadedSufficientNumberOfRatings: response.sufficientnumberofratings, loadedIndexFois: loadedIndexFois, loadedCitingFois: loadedCitingFois, loadedCitationRatings: loadedCitationRatings, loadedIndexCitationRatings: loadedIndexCitationRatings, loadedAdjudicatedIndexRatings: loadedAdjudicatedIndexRatings, loadedAdjudicatedCitationRatings: loadedAdjudicatedCitationRatings } });
            setFoiTitles(response.titlesbyfoi || {});
            setFoiCTgovURLs(response.ctgovurlsbyfoi || {});
            setFoiPmidQueries(response.pmidqueriesbyfoi || {});
            setFoiRepoSearches(response.reposearchesbyfoi || {});
            setFoiAuthors(response.authorsbyfoi || {});
            setFoiYears(response.yearsbyfoi || {});

            let { noCitations, buttonColor, labelColor, percentProgressText, numberStartedRatings, numberCompletedRatings } = getProgressInfo(response.indexfois, response.indexcitationratings, true);
            setIndexNoCitations(noCitations);
            setIndexButtonColor(buttonColor);
            setIndexLabelColor(labelColor);
            setIndexPercentProgressText(percentProgressText);
            setIndexNumberStartedRatings(numberStartedRatings);
            setIndexNumberCompletedRatings(numberCompletedRatings);
            /*
            if (response.investigator) {
              //let foisToGetTitles = [];
              //for (let foi of response.indexfois) { if (foiTitles[foi] === undefined) { foisToGetTitles.push(foi); } }        
              if (response?.indexfois?.length > 0) {
                const body = {
                  'functionid': 'getresourcetitle',
                  'resourceids': response.indexfois,
                  'resourcetype': "Citation",
                  'idToken': ''
                };
                let response = await submitToFevirServer(globalContext, 60000, body, true, false);
                if (response?.success) {
                  setFoiTitles(response.titlesbyfoi);
                }
              }
            }
            */
            setMakeResultsPublic(response.makeresultspublic);
            setOpenToJoin(response.opentojoin);
            setSufficientNumberOfRatings(response.sufficientnumberofratings);
            setRatingsCount(response.ratingscount);
            if (response.investigator) {
              setInvestigatorState(true);
            } else {
              setInvestigatorState(false);
            }
            if (response.adjudicator) {
              setAdjudicatorState(true);
              setInvestigatorsList(response.investigators);
              setAdjudicatorsList(response.adjudicators);
            } else {
              setAdjudicatorState(false);
            }
            if (response.adminuser) {
              setAdminState(true);
              setAdminInviteCode(response.admininvitecode);
              setAdjudicatorInviteCode(response.adjudicatorinvitecode);
              setInvestigatorInviteCode(response.invitecode);
              //setUserRatingProgress(response.userratingprogress);
              setOwnerId(response.ownerid);
              setAdminUsersList(response.adminusers);
              setAdjudicatorsList(response.adjudicators);
              setInvestigatorsList(response.investigators);
            } else {
              setAdminState(false);
            }
            if (!investigationLoaded) {   //Only opens the modals on the initial loading of the page
              openModals(response.adminuser, response.adjudicator, response.investigator, response.invalidInviteCode);
            }
            setInvestigationLoaded(true);
          } else {
            setErrorLoading(true);
          }
        } catch (e) { }
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [globalContext.userState, reloadState]);

  useEffect(async () => {
    if (investigationLoaded && !invitedModalState.modalOpen && !manageUsersModalState.modalOpen && !indexArticlesModalState.modalOpen && !citingArticlesModalState.modalOpen && !rateIndexArticlesModalState.modalOpen && !rateCitingArticlesModalState.modalOpen && !adjudicateIndexArticlesModalState.modalOpen && !adjudicateCitingArticlesModalState.modalOpen) {
      history.replace("/radar/" + investigationId);
    }
  }, [manageUsersModalState.modalOpen, invitedModalState.modalOpen, indexArticlesModalState.modalOpen, citingArticlesModalState.modalOpen, rateIndexArticlesModalState.modalOpen, rateCitingArticlesModalState.modalOpen, adjudicateIndexArticlesModalState.modalOpen, adjudicateCitingArticlesModalState.modalOpen]);

  const openModals = (admin, adjudicator, investigator, invalidInviteCode) => {
    if (modalLoad) {
      if (modalLoad === "rateindex") {
        if (investigator) {
          setRateIndexArticlesModalState(prevState => { return { ...prevState, modalOpen: true } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad === "changeindex") {
        if (admin) {
          setIndexArticlesModalState(prevState => { return { ...prevState, modalOpen: true } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad === "adjudicatecompositeindexratings") {
        if (admin || adjudicator) {
          setAdjudicateIndexArticlesModalState(prevState => { return { ...prevState, modalOpen: true } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad.substring(0, 32) === "adjudicatecompositecitingratings") {
        let foi = modalLoad.substring(32);
        if (admin || adjudicator) {
          setAdjudicateCitingArticlesModalState(prevState => { return { ...prevState, modalOpen: true, indexFoi: foi } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad.substring(0, 15) === "changecitations") {
        let foi = modalLoad.substring(15);
        if (admin && foi) {
          setCitingArticlesModalState(prevState => { return { ...prevState, modalOpen: true, indexFoi: foi } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad.substring(0, 13) === "ratecitations") {
        let foi = modalLoad.substring(13);
        if (investigator && foi) {
          setRateCitingArticlesModalState(prevState => { return { ...prevState, modalOpen: true, indexFoi: foi } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad.substring(0, 12) === "admininvite=") {
        let adminInviteCode = modalLoad.substring(12);
        if (adminInviteCode) {
          setInvitedModalState(prevState => { return { ...prevState, modalOpen: true, adminInviteCode: adminInviteCode, invalidInviteCode: invalidInviteCode, adminInviteCodeMatches: (adminInviteCode && !invalidInviteCode) ? true : undefined } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad.substring(0, 18) === "adjudicatorinvite=") {
        let adjudicatorInviteCode = modalLoad.substring(18);
        if (adjudicatorInviteCode) {
          setInvitedModalState(prevState => { return { ...prevState, modalOpen: true, adjudicatorInviteCode: adjudicatorInviteCode, invalidInviteCode: invalidInviteCode, adjudicatorInviteCodeMatches: (adjudicatorInviteCode && !invalidInviteCode) ? true : undefined } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad.substring(0, 7) === "invite=") {
        let inviteCode = modalLoad.substring(7);
        if (inviteCode) {
          setInvitedModalState(prevState => { return { ...prevState, modalOpen: true, inviteCode: inviteCode, invalidInviteCode: invalidInviteCode, adminInviteCodeMatches: (inviteCode && !invalidInviteCode) ? true : undefined } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      } else if (modalLoad.substring(0, 11) === "manageusers") {
        if (admin) {
          setManageUsersModalState(prevState => { return { ...prevState, modalOpen: true } });
        } else {
          history.replace("/radar/" + investigationId);
        }
      }
    }
  }

  const { addToast } = useToasts();

  let headerStyle = { backgroundColor: "#D1D7EF" };
  let cellStyle = { backgroundColor: "#ECF4FC" };

  let elements = ["TEST1", "TEST2"];

  return <div>
    {loading &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    {errorLoading && <><br /><h1 style={{ color: "red" }}>ERROR LOADING INVESTIGATION, not found</h1></>}
    {(invitedModalState.modalOpen && (invitedModalState.adminInviteCode || invitedModalState.adjudicatorInviteCode || invitedModalState.inviteCode)) && <InvitedModal loading={loading} setLoading={setLoading} invitedModalState={invitedModalState} setInvitedModalState={setInvitedModalState} toolState={toolState} investigationId={investigationId} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} adminState={adminState} setAdminState={setAdminState} adjudicatorState={adjudicatorState} setAdjudicatorState={setAdjudicatorState} investigatorState={investigatorState} setInvestigatorState={setInvestigatorState} globalContext={globalContext} setReloadState={setReloadState} />}
    {(adminState && manageUsersModalState.modalOpen) && <ManageUsersModal loading={loading} setLoading={setLoading} manageUsersModalState={manageUsersModalState} setManageUsersModalState={setManageUsersModalState} adminNames={adminNames} setAdminNames={setAdminNames} investigatorNames={investigatorNames} setInvestigatorNames={setInvestigatorNames} adjudicatorNames={adjudicatorNames} setAdjudicatorNames={setAdjudicatorNames} toolState={toolState} investigationId={investigationId} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} adminState={adminState} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} adminUsersList={adminUsersList} adjudicatorsList={adjudicatorsList} investigatorsList={investigatorsList} ownerId={ownerId} adminInviteCode={adminInviteCode} setAdminInviteCode={setAdminInviteCode} adjudicatorInviteCode={adjudicatorInviteCode} setAdjudicatorInviteCode={setAdjudicatorInviteCode} investigatorInviteCode={investigatorInviteCode} setInvestigatorInviteCode={setInvestigatorInviteCode} addToast={addToast} setDidNamesLoad={setDidNamesLoad} />}
    {(adminState && risModalState.modalOpen) && <RisImportModal loading={loading} setLoading={setLoading} adminState={adminState} indexArticlesModalState={indexArticlesModalState} setIndexArticlesModalState={setIndexArticlesModalState} risModalState={risModalState} setRisModalState={setRisModalState} toolState={toolState} addToast={addToast} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} setEditMode={setEditMode} investigationId={investigationId} investigationTitle={investigationTitle} investigationDescription={investigationDescription} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} indexFois={indexFois} setIndexFois={setIndexFois} citingFois={citingFois} />}
    {(adminState && indexArticlesModalState.modalOpen) && <IndexArticlesModal loading={loading} setLoading={setLoading} adminState={adminState} indexArticlesModalState={indexArticlesModalState} setIndexArticlesModalState={setIndexArticlesModalState} risModalState={risModalState} setRisModalState={setRisModalState} toolState={toolState} foiTitles={foiTitles} foiPmidQueries={foiPmidQueries} foiCTgovURLs={foiCTgovURLs} foiAuthors={foiAuthors} foiYears={foiYears} addToast={addToast} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} setEditMode={setEditMode} investigationId={investigationId} investigationTitle={investigationTitle} investigationDescription={investigationDescription} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} indexFois={indexFois} setIndexFois={setIndexFois} citingFois={citingFois} />}
    {(adminState && citingArticlesModalState.modalOpen) && <CitingArticlesModal loading={loading} setLoading={setLoading} adminState={adminState} citingArticlesModalState={citingArticlesModalState} setCitingArticlesModalState={setCitingArticlesModalState} risModalState={risModalState} setRisModalState={setRisModalState} toolState={toolState} foiTitles={foiTitles} foiPmidQueries={foiPmidQueries} foiCTgovURLs={foiCTgovURLs} foiAuthors={foiAuthors} foiYears={foiYears} addToast={addToast} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} setEditMode={setEditMode} investigationId={investigationId} investigationTitle={investigationTitle} investigationDescription={investigationDescription} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} indexFois={indexFois} setIndexFois={setIndexFois} citingFois={citingFois} setCitingFois={setCitingFois} />}
    {((adminState || adjudicatorState) && adjudicateIndexArticlesModalState.modalOpen) && <AdjudicateIndexArticlesModal loading={loading} setLoading={setLoading} investigatorState={investigatorState} adjudicateIndexArticlesModalState={adjudicateIndexArticlesModalState} setAdjudicateIndexArticlesModalState={setAdjudicateIndexArticlesModalState} adminNames={adminNames} setAdminNames={setAdminNames} investigatorNames={investigatorNames} setInvestigatorNames={setInvestigatorNames} adjudicatorNames={adjudicatorNames} setAdjudicatorNames={setAdjudicatorNames} toolState={toolState} setToolState={setToolState} allUsersIndexCitationRatings={allUsersIndexCitationRatings} foiTitles={foiTitles} foiPmidQueries={foiPmidQueries} foiRepoSearches={foiRepoSearches} foiCTgovURLs={foiCTgovURLs} foiAuthors={foiAuthors} foiYears={foiYears} addToast={addToast} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} setEditMode={setEditMode} investigationId={investigationId} investigationTitle={investigationTitle} investigationDescription={investigationDescription} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} indexFois={indexFois} setIndexFois={setIndexFois} citingFois={citingFois} indexCitationRatings={indexCitationRatings} setIndexCitationRatings={setIndexCitationRatings} ratingsCount={ratingsCount} setDidNamesLoad={setDidNamesLoad} />}
    {((adminState || adjudicatorState) && adjudicateCitingArticlesModalState.modalOpen) && <AdjudicateCitingArticlesModal loading={loading} setLoading={setLoading} investigatorState={investigatorState} adjudicateCitingArticlesModalState={adjudicateCitingArticlesModalState} setAdjudicateCitingArticlesModalState={setAdjudicateCitingArticlesModalState} adminNames={adminNames} setAdminNames={setAdminNames} investigatorNames={investigatorNames} setInvestigatorNames={setInvestigatorNames} adjudicatorNames={adjudicatorNames} setAdjudicatorNames={setAdjudicatorNames} toolState={toolState} setToolState={setToolState} allUsersCitationRatings={allUsersCitationRatings} foiTitles={foiTitles} foiPmidQueries={foiPmidQueries} foiRepoSearches={foiRepoSearches} foiCTgovURLs={foiCTgovURLs} foiAuthors={foiAuthors} foiYears={foiYears} addToast={addToast} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} setEditMode={setEditMode} investigationId={investigationId} investigationTitle={investigationTitle} investigationDescription={investigationDescription} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} citingFois={citingFois} indexCitationRatings={indexCitationRatings} setIndexCitationRatings={setIndexCitationRatings} ratingsCount={ratingsCount} setDidNamesLoad={setDidNamesLoad} />}
    {(investigatorState && rateIndexArticlesModalState.modalOpen) && <RateIndexArticlesModal loading={loading} setLoading={setLoading} investigatorState={investigatorState} rateIndexArticlesModalState={rateIndexArticlesModalState} setRateIndexArticlesModalState={setRateIndexArticlesModalState} toolState={toolState} setToolState={setToolState} foiTitles={foiTitles} foiPmidQueries={foiPmidQueries} foiCTgovURLs={foiCTgovURLs} foiRepoSearches={foiRepoSearches} foiAuthors={foiAuthors} foiYears={foiYears} addToast={addToast} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} setEditMode={setEditMode} investigationId={investigationId} investigationTitle={investigationTitle} investigationDescription={investigationDescription} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} indexFois={indexFois} setIndexFois={setIndexFois} citingFois={citingFois} indexCitationRatings={indexCitationRatings} setIndexCitationRatings={setIndexCitationRatings} ratingsCount={ratingsCount} />}
    {(investigatorState && rateCitingArticlesModalState.modalOpen) && <RateCitingArticlesModal loading={loading} setLoading={setLoading} investigatorState={investigatorState} rateCitingArticlesModalState={rateCitingArticlesModalState} setRateCitingArticlesModalState={setRateCitingArticlesModalState} toolState={toolState} setToolState={setToolState} foiTitles={foiTitles} foiPmidQueries={foiPmidQueries} foiCTgovURLs={foiCTgovURLs} foiRepoSearches={foiRepoSearches} foiAuthors={foiAuthors} foiYears={foiYears} addToast={addToast} globalContext={globalContext} reloadState={reloadState} setReloadState={setReloadState} setEditMode={setEditMode} investigationId={investigationId} investigationTitle={investigationTitle} investigationDescription={investigationDescription} openToJoin={openToJoin} makeResultsPublic={makeResultsPublic} sufficientNumberOfRatings={sufficientNumberOfRatings} indexFois={indexFois} setIndexFois={setIndexFois} citingFois={citingFois} citationRatings={citationRatings} setCitationRatings={setCitationRatings} ratingsCount={ratingsCount} />}
    {!errorLoading && <div style={{ display: "flex", width: "100%" }}>
      <div style={{ float: "left", minWidth: "182px", marginRight: "16px" }}>
        <div style={{ position: "fixed", width: "182px" }}>
          <Segment className={`containerSegment measuringTheRateNavigationSegment`} style={{ paddingTop: "2px", paddingBottom: "6px" }} raised>
            <div className={`navigationLinksSection unselectable`}>
              <NavigationLink id={"investigation-title"} text={"Introduction"} /><br /><div style={{ height: "4px" }} />
              <NavigationLink id={"actions"} text={"Actions"} /><br /><div style={{ height: "4px" }} />
              {/*<><NavigationLink id={"links"} text={"Links"} /><br /><div style={{ height: "4px" }} /></>*/}
              {adminState && <><NavigationLink id={"change-articles"} text={(indexFois.length ? "Change" : "Load") + " Articles"} /><br /><div style={{ height: "4px" }} /></>}
              {/*<NavigationLink id={"composite-ratings"} text="Composites" /><br /><div style={{ height: "4px" }} />*/}
              <NavigationLink id={"results"} text="Results" /><br /><div style={{ height: "4px" }} />
              <NavigationLink id={"instructions"} text={"Instructions"} /><br /><div style={{ height: "4px" }} />
              <NavigationLink id={"how-to-cite"} text={"How to Cite"} />
            </div>
          </Segment>
          {adminState &&
            <div style={{ width: "100%", textAlign: "center", display: "flex" }}>
              {editMode ?
                <Button className="formButton"
                  style={{ color: "#000000", background: "#EAEFF1 !important", width: "100%", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
                  disabled={(loading || (investigationTitle === toolState.loadedTitle && investigationDescription === toolState.loadedDescription && openToJoin === toolState.loadedOpenToJoin && makeResultsPublic === toolState.loadedMakeResultsPublic && sufficientNumberOfRatings === toolState.loadedSufficientNumberOfRatings))} //The button is disabled if none of the fields are changed, so it won't allow unnecessary saves
                  onClick={() => { editInvestigation(addToast, globalContext, undefined, undefined, setLoading, setReloadState, setEditMode, investigationId, investigationTitle, investigationDescription, openToJoin, makeResultsPublic, sufficientNumberOfRatings, undefined, undefined); }}
                >Save Changes</Button>
                :
                <Button className="formButton"
                  style={{ color: "#000000", background: "#EAEFF1 !important", width: "100%", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
                  onClick={() => { setEditMode(true); }}>
                  <Icon name='edit outline' /> Edit Details
                </Button>
              }
            </div>
          }
        </div>
      </div>
      <div style={{ float: "right", width: "100%" }}>
        <Segment id="measuringTheRateSegment" name="measuringTheRateSegment" className={`containerSegment maxRemainderOfPageSegment measuringTheRateSegment`} raised>
          <h3 id="investigation-title">Investigation Title</h3>
          <div style={{ marginLeft: "24px" }}>
            {editMode ? <TextField style={{ width: "70%", marginTop: "6px" }} className="inputField" type='text' label={'Investigation Title'} size="small" variant='outlined' value={investigationTitle} onChange={(e) => { setInvestigationTitle(e.target.value); }} /> : investigationTitle}
          </div>
          {investigationDescription && <>
            <h3 id="investigation-description" style={{ marginTop: "12px" }}>Investigation Description</h3>
            <div style={{ marginLeft: "24px" }}>
              {editMode ? <TextField style={{ width: "70%", marginTop: "6px" }} multiline className="inputField" type='text' label={'Description'} size="small" variant='outlined' value={investigationDescription} onChange={(e) => { setInvestigationDescription(e.target.value); }} /> : investigationDescription}
            </div>
          </>}
          <br />
          {(!editMode && (adminState || adjudicatorState || investigatorState)) && <>
            {openToJoin && <div><b>Open For Anyone To Join</b><br /></div>}
            {makeResultsPublic && <div><b>Investigation Results Are Open to the Public</b><br /></div>}
            <div><b>The number of raters needed per article: </b> {(sufficientNumberOfRatings || sufficientNumberOfRatings == 0) ? sufficientNumberOfRatings : "Not set"} <span style={{ fontSize: "18px", fontWeight: "bold" }}>ℜ</span><br /></div>
            <br />
          </>}
          {adminState && <div>
            {editMode &&
              <>
                <FormGroup>
                  <FormControlLabel control={<Checkbox size="medium" checked={openToJoin} onChange={(e) => { setOpenToJoin(e.target.checked); }} />} label="Open for anyone to join the investigation?" />
                  <FormControlLabel control={<Checkbox size="medium" checked={makeResultsPublic} onChange={(e) => { setMakeResultsPublic(e.target.checked); }} />} label="Make the results of this investigation viewable to the public?" />
                </FormGroup>
                <TextField style={{ width: "260px" }}
                  className="inputField" type='number' size="small" variant='outlined'
                  label={'Number of Raters Needed per Article'}
                  value={sufficientNumberOfRatings}
                  onChange={(e) => { setSufficientNumberOfRatings(e.target.value) }}
                />
                <br /><br />
              </>
            }
            <Button className="formButton"
              style={{ color: "#000000", background: "#EAEFF1 !important", width: "170px", margin: "0px", paddingTop: "10px", paddingBottom: "10px" }}
              onClick={() => { history.replace("/radar/" + investigationId + "#manageusers"); setManageUsersModalState(prevState => { return { ...prevState, modalOpen: true } }); }}>
              <span><b>Investigators:</b> {investigatorsList?.length}<br /></span>
              <span><b>Adjudicators:</b> {adjudicatorsList?.length}<br /></span>
              <span><b>Admin Users:</b> {adminUsersList?.length}</span>
            </Button>
            <br />
          </div>}
          <br />
          <span id="actions" />
          <table className={"ProjectActionsTable"}>
            <tbody>
              {!joinedGroup &&
                <tr>
                  <td>
                    <Button color='green' /*  "color": "#000000", */
                      style={{ width: "160px", float: "left" }}
                      content="Join Group"
                      onClick={() => {
                        if (globalContext.userState.id) {
                          joinInvestigation(investigationId, globalContext, setReloadState, setJoinedGroup, false, false, invitedModalState.adminInviteCode, invitedModalState.adjudicatorInviteCode, invitedModalState.inviteCode);
                        } else {
                          globalContext.openAlert({ "header": "", "content": "Please login to join group." });
                        }
                      }} />
                  </td>
                  <td>
                    {globalContext.userState.id ?
                      <>You will need to join the group to enter data for this investigation.</>
                      :
                      <>Please login to join group.</>
                    }
                  </td>
                </tr>}
            </tbody>
          </table>
          <table className={"ProjectActionsTable"}>
            <tbody>
              {investigatorState && <tr>
                <td>
                  <Button compact as='div' labelPosition='right'
                    style={{ float: "left" }}
                    disabled={indexNoCitations}
                    onClick={() => {
                      history.replace("/radar/" + investigationId + "#rateindex");
                      setRateIndexArticlesModalState(prevState => { return { ...prevState, modalOpen: true } });
                    }}>
                    {/* Make this button change color like the Evaluate Citations Buttons? */}
                    <Button compact icon labelPosition='left' color='google plus' style={{ textAlign: "left", float: "left" }}>
                      <Icon name='edit outline' />
                      <Button.Content style={{ fontSize: "20px" }}>Rate Index Articles</Button.Content>
                    </Button>
                    <Label as='a' basic color={indexLabelColor} pointing='left'>
                      {indexNoCitations ? "No Citations Yet" : `${indexNumberCompletedRatings} of ${indexFois?.length || "0"}: ${indexPercentProgressText}`}
                    </Label>
                  </Button>
                </td>
                <td>{/*Placeholder*/}</td>
              </tr>}
            </tbody>
          </table>
          <table className={"ProjectActionsTable"} style={{ width: "100%" }}>
            <tbody>
              {investigatorState && indexFois?.map((foi, index) => {
                return <tr key={index}>
                  <td>
                    <EvaluateCitationsButton history={history} investigationId={investigationId} foi={foi} indexFois={indexFois} citingFois={citingFois} citationRatings={citationRatings} foiTitles={foiTitles} foiAuthors={foiAuthors} foiYears={foiYears} setRateCitingArticlesModalState={setRateCitingArticlesModalState} loading={loading} />
                  </td>
                  {/*<td>Placeholder</td>*/}
                </tr>
              })}
            </tbody>
          </table>
          {adminState && <>
            <h3 id="change-articles" style={{ paddingBottom: "8px" }}>{indexFois.length ? "Change" : "Load"} Articles</h3>
            <div style={{ marginLeft: "36px" }}>
              <table className={"ProjectActionsTable"}>
                <tbody>
                  <tr>
                    <td>
                      <Button compact as='div' labelPosition='right'
                        style={{ float: "left" }}
                        onClick={() => {
                          history.replace("/radar/" + investigationId + "#changeindex");
                          setIndexArticlesModalState(prevState => { return { ...prevState, modalOpen: true } });
                        }}>
                        <Button compact icon labelPosition='left' color='orange' style={{ textAlign: "left", float: "left" }}>
                          <Icon name={indexFois.length ? 'edit outline' : 'upload'} />
                          <span className={"actionButtonLargerFont"}>{indexFois.length ? "Change" : "Load"} Index Articles</span>
                        </Button>
                        <Label as='a' basic color={indexFois.length ? "blue" : "red"} pointing='left'>
                          {indexFois?.length || "0"} Articles
                        </Label>
                      </Button>
                    </td>
                    <td>{/*Placeholder*/}</td>
                  </tr>
                </tbody>
              </table>
              <table className={"ProjectActionsTable"} style={{ width: "100%" }}>
                <tbody>
                  {indexFois?.map((foi, index) => {
                    let titleTruncated = "";
                    if (foiTitles && foiTitles[foi]) {
                      titleTruncated = foiTitles[foi];
                      if (titleTruncated.length > 100) {
                        titleTruncated = titleTruncated.substring(0, 100) + "...";
                      }
                    }
                    return <tr key={index}>
                      <td>
                        <Button compact as='div' labelPosition='right'
                          style={{ float: "left" }}
                          onClick={() => {
                            history.replace("/radar/" + investigationId + "#changecitations" + foi);
                            setCitingArticlesModalState(prevState => { return { ...prevState, modalOpen: true, indexFoi: foi } });
                          }}>
                          <Button compact icon labelPosition='left' color='pink' style={{ textAlign: "left", float: "left" }}>
                            <Icon name={citingFois[foi]?.length ? 'edit outline' : 'upload'} />
                            <span className={"actionButtonLargerFont"}>
                              {citingFois[foi]?.length ? "Change" : "Load"} Articles Citing {getArticleLabel(foi, foiTitles, foiAuthors, foiYears)}
                            </span>
                          </Button>
                          <Label as='a' basic color={citingFois[foi]?.length ? "blue" : "red"} pointing='left'>
                            {citingFois[foi]?.length || "0"} Citations
                          </Label>
                        </Button>
                      </td>
                      {/*<td>Placeholder</td>*/}
                    </tr>
                  })}
                </tbody>
              </table>
            </div>
          </>}
          {/*<h3 id="composite-ratings" style={{ paddingBottom: "8px" }}>Composites</h3>*/}
          <h3 id="results" style={{ paddingBottom: "8px" }}>Results</h3>
          <div style={{ marginLeft: "36px" }}>
            {(!makeResultsPublic && !joinedGroup) && <>The results are not viewable to the public.</>}
            {(adminState || adjudicatorState) && <>
              {(indexFois?.length) ?
                <><table className={"ProjectActionsTable"} style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <Button compact as='div' labelPosition='right'
                          style={{ float: "left" }}
                          onClick={() => {
                            history.replace("/radar/" + investigationId + "#adjudicatecompositeindexratings");
                            setAdjudicateIndexArticlesModalState(prevState => { return { ...prevState, modalOpen: true } });
                          }}>
                          <Button compact icon labelPosition='left' color='red' style={{ textAlign: "left", float: "left" }}>
                            <Icon name={indexFois.length ? 'edit outline' : 'upload'} />
                            <span className={"actionButtonLargerFont"}>Adjudicate Composite Index Ratings</span>
                          </Button>
                          <Label as='a' basic color="blue" pointing='left'>
                            {indexFois.length} Articles
                          </Label>
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <table className={"ProjectActionsTable"} style={{ width: "100%" }}>
                    <tbody>
                      {indexFois?.map((foi, index) => {
                        let titleTruncated = "";
                        if ((foiTitles && foiTitles[foi])) {
                          let title = foiTitles[foi];
                          if (title.length > 100) {
                            title = title.substring(0, 100) + "...";
                          }
                          titleTruncated = title;
                        }
                        return <tr key={index}>
                          <td>
                            <Button compact as='div' labelPosition='right'
                              style={{ float: "left" }}
                              disabled={!citingFois[foi]}
                              onClick={() => {
                                history.replace("/radar/" + investigationId + "#adjudicatecompositecitingratings" + foi);
                                setAdjudicateCitingArticlesModalState(prevState => { return { ...prevState, modalOpen: true, indexFoi: foi } });
                              }}>
                              <Button compact icon labelPosition='left' animated='fade' color='red' style={{ float: "left" }}>
                                <Icon name='edit outline' />
                                <Button.Content visible={titleTruncated !== ""} style={{ fontSize: "20px" }}>Adjudicate Composite Citing Article Ratings of {getArticleLabel(foi, foiTitles, foiAuthors, foiYears)}</Button.Content>
                                {titleTruncated && <Button.Content className="hiddenButtonMultiRowText" hidden>{titleTruncated}</Button.Content>}
                              </Button>
                              <Label as='a' basic color="blue" pointing='left'>
                                {citingFois[foi]?.length} Articles
                              </Label>
                            </Button>
                            {/*<EvaluateCitationsButton history={history} investigationId={investigationId} foi={foi} indexFois={indexFois} citingFois={citingFois} citationRatings={citationRatings} foiTitles={foiTitles} foiAuthors={foiAuthors} foiYears={foiYears} setRateCitingArticlesModalState={setRateCitingArticlesModalState} loading={loading} />*/}
                          </td>
                          {/*<td>Placeholder</td>*/}
                        </tr>
                      })}
                    </tbody>
                  </table></>
                : <>No articles added yet.</>
              }
            </>}
            {/* The Results Buttons */}
            {/* Have this check on the server side too */}
            {(makeResultsPublic || (adminState || adjudicatorState || investigatorState)) && <></>}
          </div>
          <br /><br />
          <h3 id="instructions">Instructions</h3>
          <div style={{ marginLeft: "36px" }}>
            <span>Rate Index Article: to rate index articles, click on rate index articles</span>
            <br /><br />
            <span>List of articles is on the left. Select an article to rate. When you click it, the Citation will open in a new tab. Use the information in the Citation to retrieve the article and click on the link in the <b>Citation Summary</b> or go down to the URLs. Either way you will go to the internet and see the citation and you can retrieve the full text.</span>
            <br /><br />
            <span><u>Then read to answer the three questions:</u></span>
            <p style={{ paddingLeft: "24px" }}>1. Date Article FIRST Published</p>
            <p style={{ paddingLeft: "48px" }}>Check the article for the date the article was first published (using the earlier date if online release and issue date differ). Enter this date (in <i>YYYY-MM-DD</i> format) under 'Date Article FIRST Published' and then '<span style={{ color: "#1B581B", fontWeight: "bold" }}>Save</span>'. The PubMed Citation on MEDLINE may state "Epub ahead of print" or the full-text article may state "early online" or "published online"</p>
            <p style={{ paddingLeft: "24px" }}>2. Date Results in Preprint (if noted in the article)</p>
            <p style={{ paddingLeft: "48px", }}>Check the article for any mention of prior results availability in preprints. Check <i>Results in preprint</i> or <i>No preprint</i>. (2a) If <i>Results in preprint</i> is checked, then enter the URL for the Preprint. (2b) If Results in preprint is checked, then enter the Date Results in Preprint in <i>YYYY-MM-DD</i> format. (2c) Click '<span style={{ color: "#1B581B", fontWeight: "bold" }}>Save</span>'</p>
            <p style={{ paddingLeft: "24px" }}>3. Registry Listing and Date Results Posted in Registry</p>
            <p style={{ paddingLeft: "48px" }}>Check the article for any mention of trial registration, <i>e.g. ClinicalTrials.gov registration</i>. Check <i>Study in registry</i> or <i>No registration</i>. (The ClinicalTrials.gov registration may be listed as a Related identifier in the FEvIR Citation record, but only if catalogued in the MEDLINE record. If absent, there may be registration details in the cited article so check the full-text to be sure.)</p>
            <p style={{ paddingLeft: "48px" }}>If <i>Study in registry</i> is checked, then enter the URL, identifier, or registry name in the text box, and then check <i>Results in registry</i> or <i>No results in registry</i>.</p>
            <p style={{ paddingLeft: "48px" }}>If <i>Results in registry</i> is checked then enter the Date Results First Posted in Trial Registry in <i>YYYY-MM-DD</i> format. Click '<span style={{ color: "#1B581B", fontWeight: "bold" }}>Save</span>'</p>
            <p style={{ paddingLeft: "24px" }}>4. Enter any notes (comments, not structured data for the research) [<i>Optional</i>]</p>
          </div>
          <br />
          <div style={{ marginLeft: "36px" }}>
            <span>Evaluate Citing Articles: to evaluate citing articles, click on '<b>Evaluate Citations of <i><u>...</u></i></b>'</span>
            <br /><br />
            <span>List of articles is on the left. Select an article to evaluate. When you click it, the Citation will open in a new tab. Use the information in the Citation to retrieve the article and click on the link in the <b>Citation Summary</b> or go down to the URLs. Either way you will go to the internet and see the citation and you can retrieve the full text.</span>
            <br /><br />
            <span><u>Then read to answer the three screening questions:</u></span>
            <p style={{ paddingLeft: "24px" }}>1. Systematically derived?</p>
            <p style={{ paddingLeft: "48px" }}>"<i>Systematically derived</i>" means that, for the methods used to select evidence for inclusion, there is a specification of search sources and inclusion criteria.</p>
            <p style={{ paddingLeft: "24px" }}>2. Intended to guide clinical practice?</p>
            <p style={{ paddingLeft: "48px", }}>"<i>Intended to guide clinical practice</i>" means the report is created with a primary intention of directly informing or guiding clinical practice (or directly informing decision makers who are developing clinical practice guidance)</p>
            <p style={{ paddingLeft: "24px" }}>3. Incorporated into results?</p>
            <p style={{ paddingLeft: "48px" }}>"<i>Incorporated into results</i>" means that the clinical trial results are incorporated into the Results of the report and not just the Introduction and Discussion (if the report has structured sections labeled Introduction, Methods, Results, and Discussion; if not, the clinical trial results are incorporated into the justification for the guidance). If the answer to the three questions above is yes, then enter the date the citing article was published</p>
            <p style={{ paddingLeft: "24px" }}>4. Enter date</p>
            <p style={{ paddingLeft: "48px" }}>Check the article for the date the article was first published (using the earlier date if online release and issue date differ). Enter this date (in <i>YYYY-MM-DD</i> format) under 'Date Article FIRST Published' and then '<span style={{ color: "#1B581B", fontWeight: "bold" }}>Save</span>'. The PubMed Citation on MEDLINE may state "Epub ahead of print" or the full-text article may state "early online" or "published online"</p>
            <p style={{ paddingLeft: "24px" }}>5. Enter notes [<i>optional</i>]</p>
          </div>
          <h3 id="how-to-cite">How To Cite</h3>
          <div style={{ marginLeft: "24px" }}>

          </div>
          <p style={{ fontSize: "20px" }}>
          </p>
        </Segment>
      </div>
    </div>}
  </div>
};

export default MeasuringTheRatePage;