import React from 'react';
import { Table } from 'semantic-ui-react';
import "firebase/compat/auth";
import { DisplayFromFHIR, getStringFromFHIR } from './ResourceFunctions';
import { getTypeAndValueDisplayValuesForTable, getCharacteristicByCombinationDisplay, getCharacteristicDefinitionDisplay } from './EvidenceVariableCharacteristicDisplay';

const EvidenceVariableCharacteristicTable = ({ criterias, criteriaType, matchReport }) => {

  let headerCellColor = "#F9FAFB";
  if (criteriaType) {
    if (criteriaType.toLowerCase() === "inclusion") {
      headerCellColor = "#EAFCEA";
    } else if (criteriaType.toLowerCase() === "exclusion") {
      headerCellColor = "#FCEAEA";
    }
  }

  return <Table>
    <Table.Header>
      <Table.Row>
        {matchReport && <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Match Result</Table.HeaderCell>}
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Definition</Table.HeaderCell>
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Description</Table.HeaderCell>
        <Table.HeaderCell style={{ backgroundColor: headerCellColor }}>Qualifiers (Timing)</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {criterias.map((criteria, criteriaIndex) => {
        let backgroundColor = "#FFFFFF";
        let fullRowPadding = '';
        let otherRowPadding = '';
        if (criteria.level !== 0) {
          fullRowPadding = `${60 * criteria.level}px`;
          otherRowPadding = `${(60 * criteria.level) - 30}px`;
          if (criteria.level === 1) {
            backgroundColor = "#EBEBEB";
          } else if (criteria.level === 2) {
            backgroundColor = "#D0D0D0";
          } else if (criteria.level === 3) {
            backgroundColor = "#CCCCCC";
          } else if (criteria.level >= 4) {
            backgroundColor = "#B1B1B1";
          }
        }
        return <Table.Row key={criteriaIndex} style={{ backgroundColor: backgroundColor }}>
          {criteria.fullRow ?
            <>
              {matchReport && <Table.Cell style={{ verticalAlign: "top", textAlign: "center" }}>{criteria.matchResult && criteria.matchResult}</Table.Cell>}
              <Table.Cell style={{ verticalAlign: "top", paddingLeft: fullRowPadding }}>
                {criteria.criteriaTypeDisplay ?
                  <b>{criteria.criteriaTypeDisplay}{criteria.criteriaValueDisplay !== undefined && <>: </>}</b>
                  :
                  <>{criteria.criteriaDefinitionByCombinationDisplay ?
                    <b>{criteria.criteriaDefinitionByCombinationDisplay}</b>
                    :
                    <>{criteria.criteriaDefinition && <b>Definition: </b>}</>
                  }</>
                }
                {criteria.criteriaValueDisplay !== undefined ?
                  criteria.criteriaValueDisplay
                  :
                  <>{criteria.criteriaDefinitionByCombinationDisplay ?
                    <></>
                    :
                    <>{criteria.criteriaDefinition}</>
                  }</>
                }
                {criteria.offset && <><br /><b>Offset:</b> {criteria.offset}</>}
                {criteria.typeMethodDisplay?.length > 0 && <><br /><b>Method:</b> {criteria.typeMethodDisplay.join("; ")}</>}
                {criteria.device && <><br /><br /><b>Device:</b> {JSON.stringify(criteria.device, null, 2)}</>}
              </Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {criteria.description !== undefined && <span><b>Description: </b><DisplayFromFHIR markdown={criteria.description} /></span>}
                <br />
                {criteria.notes.length > 0 && <span><b>Notes: </b></span>}
                {criteria.notes.map((note, noteIndex) => {
                  return <div key={noteIndex}>{note}<br /></div>
                })}</Table.Cell>
              <Table.Cell style={{ verticalAlign: "top" }}>
                {criteria.instancesQuantity && <><b>Number of instances:</b> {getStringFromFHIR.Quantity(criteria.instancesQuantity)}<br /></>}
                {criteria.instancesRange && <><b>Number of instances:</b> {getStringFromFHIR.Range(criteria.instancesRange)}<br /></>}
                {/*criteria.method && <><b>Method:</b> {criteria.method}</>*/}
                {Array.isArray(criteria.timeFromEvent) && criteria.timeFromEvent.map((timeFromEvent, timeFromEventIndex) => {
                  return <div key={timeFromEventIndex}>
                    <b>Time from event:</b>
                    <br />
                    <div style={{ paddingLeft: "32px" }}>
                      {timeFromEvent.description && <><b>Description: </b> <DisplayFromFHIR markdown={timeFromEvent.description} /><br /></>}
                      {(timeFromEvent.note?.length === 1 && timeFromEvent.note[0].text) && <><b>Note: </b> {timeFromEvent.note[0].text}<br /></>}
                      {timeFromEvent.note?.length > 1 && <><b>Notes: </b> {timeFromEvent.note.map((note, noteIndex) => { return <span key={noteIndex}>{note.text}{noteIndex + 1 < timeFromEvent.note.length && <>; </>}</span> })}<br /></>}
                      {timeFromEvent.eventCodeableConcept && <><b>Event Definition: </b> <DisplayFromFHIR codeableConcept={timeFromEvent.eventCodeableConcept} /><br /></>}
                      {timeFromEvent.eventReference && <><b>Event Definition: </b> {getStringFromFHIR.Reference(timeFromEvent.eventReference)}<br /></>}
                      {timeFromEvent.eventDateTime && <><b>Event Definition: </b> {timeFromEvent.eventDateTime}<br /></>}
                      {timeFromEvent.eventId && <><b>Event Definition: </b> {timeFromEvent.eventId}<br /></>}
                      {timeFromEvent.quantity && <><b>Timing: </b> {getStringFromFHIR.Quantity(timeFromEvent.quantity)}<br /></>}
                      {timeFromEvent.range && <><b>Timing: </b> {getStringFromFHIR.Range(timeFromEvent.range)}<br /></>}
                      <br /><br />
                    </div>
                  </div>
                })}
                {criteria.groupMeasure !== undefined && <><br /><br /><b>Group measure:</b> {criteria.groupMeasure}</>}
              </Table.Cell>
            </>
            :
            <Table.Cell style={{ verticalAlign: "top", paddingLeft: otherRowPadding }} colSpan={matchReport ? "4" : "3"}><b>{criteria.text}</b></Table.Cell>
          }
        </Table.Row>
      })}
    </Table.Body>
  </Table>
}

const getCriteriaData = (characteristic, level) => {
  let criteria = {};
  let criteriaDefinition = getCharacteristicDefinitionDisplay(characteristic);
  let criteriaTypeDisplay;
  let criteriaValueDisplay;
  if (characteristic.definitionByTypeAndValue) {
    [criteriaTypeDisplay, criteriaValueDisplay] = getTypeAndValueDisplayValuesForTable(characteristic);
  }
  let criteriaDefinitionByCombinationDisplay;
  if (characteristic.definitionByCombination) {
    criteriaDefinitionByCombinationDisplay = getCharacteristicByCombinationDisplay(characteristic);
  }
  criteria.characteristic = characteristic;
  criteria.criteriaTypeDisplay = criteriaTypeDisplay;
  criteria.criteriaValueDisplay = criteriaValueDisplay;
  criteria.criteriaDefinition = criteriaDefinition;
  criteria.criteriaDefinitionByCombinationDisplay = criteriaDefinitionByCombinationDisplay;
  criteria.description = characteristic.description;
  criteria.instancesQuantity = characteristic.instancesQuantity;
  criteria.instancesRange = characteristic.instancesRange;
  criteria.method = getStringFromFHIR.CodeableConcept(characteristic.method); /* July 19, 2022, delete this, this is the old structure */
  criteria.typeMethodDisplay = [];
  criteria.device = "";
  criteria.offset = "";
  if (characteristic.definitionByTypeAndValue) {
    criteria.offset = getStringFromFHIR.CodeableConcept(characteristic.definitionByTypeAndValue.offset);
    criteria.device = characteristic.definitionByTypeAndValue.device;
    for (let methodIndex in characteristic.definitionByTypeAndValue.method) {
      let method = characteristic.definitionByTypeAndValue.method[methodIndex];
      criteria.typeMethodDisplay.push(getStringFromFHIR.CodeableConcept(method));
    }
  } else if (characteristic.offset) { //TO DO todo, June 23, 2022 remove this else if and the contents inside, this is the OLD structure, no longer needed
    criteria.offset = getStringFromFHIR.CodeableConcept(characteristic.offset); //REMOVE
  }
  criteria.timeFromEvent = characteristic.timeFromEvent;
  if (criteria.timeFromEvent === undefined && characteristic.timeFromStart) {
    if (Array.isArray(characteristic.timeFromStart)) {
      criteria.timeFromEvent = characteristic.timeFromStart;
    } else {
      criteria.timeFromEvent = [characteristic.timeFromStart];
    }
  }
  criteria.groupMeasure = characteristic.groupMeasure; /* July 19, 2022, DELETE this is an old structure */
  criteria.notes = [];
  for (let noteIndex in characteristic.note) {
    if (characteristic.note[noteIndex].text) {
      criteria.notes.push(characteristic.note[noteIndex].text);
    }
  }
  criteria.level = level;
  criteria.fullRow = true;

  for (let extensionIndex in characteristic.extension) {
    let extension = characteristic.extension[extensionIndex];
    if (extension.valueCoding) {
      if (extension.valueCoding.system === "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992") {
        if (extension.valueCoding.code === "result_characteristic_match") {
          criteria.matchResult = "✅ Match";
        } else if (extension.valueCoding.code === "result_characteristic_no_match") {
          criteria.matchResult = "❌ No match";
        } else if (extension.valueCoding.display) {
          criteria.matchResult = extension.valueCoding.display;
        }
      }
    }
  }


  return criteria;
}

const subCharacteristicRecursion = (criteriaList, characteristic, level) => {
  if (characteristic.definitionByCombination?.characteristic?.length > 0) {
    let includingSubCharacteristicsPresent = false;
    let excludingSubCharacteristicsPresent = false;
    let includingSubCriteria = [];
    let excludingSubCriteria = [];
    for (let subCharacteristicIndex in characteristic.definitionByCombination.characteristic) {
      let subCharacteristic = characteristic.definitionByCombination.characteristic[subCharacteristicIndex];
      let subCriteria = getCriteriaData(subCharacteristic, level);
      if (subCharacteristic.exclude) {
        excludingSubCharacteristicsPresent = true;
        excludingSubCriteria.push(subCriteria);
        excludingSubCriteria = subCharacteristicRecursion(excludingSubCriteria, subCharacteristic, level + 1);
      } else {
        includingSubCharacteristicsPresent = true;
        includingSubCriteria.push(subCriteria);
        includingSubCriteria = subCharacteristicRecursion(includingSubCriteria, subCharacteristic, level + 1);
      }
    }
    if (includingSubCharacteristicsPresent) {
      criteriaList.push({ fullRow: false, level: level, text: "Including these characteristics:" });
      criteriaList = criteriaList.concat(includingSubCriteria);
    }
    if (excludingSubCharacteristicsPresent) {
      criteriaList.push({ fullRow: false, level: level, text: "Excluding these characteristics:" });
      criteriaList = criteriaList.concat(excludingSubCriteria);
    }
  }
  return criteriaList;
}

const EvidenceVariableCriteriaDisplay = ({ fhirJson }) => {
  if (!fhirJson || fhirJson.resourceType !== "EvidenceVariable") {
    return <></>
  }
  let inclusionCriteria = [];
  let exclusionCriteria = [];
  for (let characteristicIndex in fhirJson.characteristic) {
    let characteristic = fhirJson.characteristic[characteristicIndex];
    let criteria = getCriteriaData(characteristic, 0);
    let exclude = characteristic.exclude;
    let level = 1;
    if (exclude) {
      exclusionCriteria.push(criteria);
      exclusionCriteria = subCharacteristicRecursion(exclusionCriteria, characteristic, level);
    } else {
      inclusionCriteria.push(criteria);
      inclusionCriteria = subCharacteristicRecursion(inclusionCriteria, characteristic, level);
    }
  }
  let matchReport = false;
  let matchResult;
  for (let useContextIndex in fhirJson.useContext) {
    let useContext = fhirJson.useContext[useContextIndex];
    if (useContext.valueCodeableConcept?.text === "match report") {
      matchReport = true;
    }
  }
  for (let extensionIndex in fhirJson.extension) {
    let extension = fhirJson.extension[extensionIndex];
    if (extension.valueCoding) {
      if (extension.valueCoding.system === "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992") {
        if (extension.valueCoding.code === "result_eligibility_criteria_match") {
          matchResult = "✅ Match";
        } else if (extension.valueCoding.code === "result_eligibility_criteria_no_match") {
          matchResult = "❌ No match";
        } else if (extension.valueCoding.display) {
          matchResult = extension.valueCoding.display;
        }
      }
    }
  }
  if (matchResult) {
    matchReport = true;
  }

  return <>
    <div style={{ marginLeft: "24px" }}>
      <div style={{ paddingTop: "12px" }} id="inclusion-criteria" />
      <b>Inclusion Criteria</b>
      <div style={{ marginLeft: "24px" }}>
        {inclusionCriteria.length > 0 ?
          <EvidenceVariableCharacteristicTable criterias={inclusionCriteria} criteriaType={"Inclusion"} matchReport={matchReport} />
          :
          <div style={{ marginLeft: "8px" }}>None</div>
        }
      </div>
      <div style={{ paddingTop: "12px" }} id="exclusion-criteria" />
      <b>Exclusion Criteria</b>

      <div style={{ marginLeft: "24px" }}>
        {exclusionCriteria.length > 0 ?
          <EvidenceVariableCharacteristicTable criterias={exclusionCriteria} criteriaType={"Exclusion"} matchReport={matchReport} />
          :
          <div style={{ marginLeft: "8px" }}>None</div>
        }
      </div>
    </div>
  </>
}

export default EvidenceVariableCriteriaDisplay;