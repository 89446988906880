import React from 'react';
import {
  Dropdown,
} from 'semantic-ui-react';
import "firebase/compat/auth";
import './App.css';
import { recursiveChange } from './ResourceFunctions';
import addOption from './AddOptionFunction';

const DropDownWithAdditions = ( { currentValue, labelText, options, optionsLookup, addOptionCustom, setter, stateSetter, changeElementState, index, elementKeyEntry, elementKeySubEntry, parentElement, parentElementIndex, indexPath, elementKeySubSubEntry, subIndex, width, multiple, disabled } ) => {
  let addOptionDecided = addOptionCustom || addOption;
  if (width === undefined) {
    width = "100%";
  }
  if (multiple && currentValue === undefined) {
    currentValue = [];
  }
  
  return <Dropdown placeholder={labelText} closeOnChange search selection selectOnBlur={false} multiple={multiple} allowAdditions clearable
      style={{width: width}}
      disabled={disabled}
      options={options}
      value={currentValue}
      onAddItem={ (e, data) => { if (optionsLookup === undefined || optionsLookup[data.value] === undefined) { addOptionDecided(setter, data.value); } }}
      onChange={(e, data) => {
        if (changeElementState) {
          changeElementState(stateSetter, data.value, parentElement, parentElementIndex, elementKeyEntry, index, elementKeySubEntry, undefined, undefined);
        } else {
          //indexPath for recursive elements in the state
          if (indexPath) {
            stateSetter(prevState  => {
              let prevElement = prevState;
              if (parentElement) {
                prevElement = prevState[parentElement];
              }
              prevElement = recursiveChange(prevElement, elementKeyEntry, elementKeySubEntry, indexPath, data.value, elementKeySubSubEntry, subIndex);
              return { ...prevState, [parentElement]: prevElement };
            } );
          } else {
            if (parentElement) {
              stateSetter(prevState  => { let newArray = prevState[parentElementIndex][elementKeyEntry]; newArray[index][elementKeySubEntry] = data.value; return { ...prevState, [parentElementIndex]: { ...prevState[parentElementIndex], [elementKeyEntry]: newArray } } } );
            } else if (index !== undefined) {
              stateSetter(prevState  => {
                if (Array.isArray(prevState)) {
                  let newState = prevState;
                  newState[index] = { ...prevState[index], [elementKeySubEntry]: data.value }
                  return [ ...newState ];
                } else {
                  return { ...prevState, [index]: { ...prevState[index], [elementKeySubEntry]: data.value } };
                }
              } );
            } else if (elementKeyEntry && elementKeySubEntry) {
              stateSetter(prevState  => {return { ...prevState, [elementKeyEntry]: { ...prevState[elementKeyEntry], [elementKeySubEntry]:  data.value } } } );
            } else if (elementKeyEntry) {
              stateSetter(prevState  => { return { ...prevState, [elementKeyEntry]: data.value } } );
            } else {
              stateSetter(prevState  => { return { ...prevState, [elementKeySubEntry]: data.value } } );
            }
          }        
        }
      }}
    />
};

export default DropDownWithAdditions;