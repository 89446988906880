import React from 'react';
import { emptyReasonValueSet, emptyReasonCodeableConceptSet } from './CodeSystemLookup';
import { getStringFromFHIR } from './ResourceFunctions';
import { DataEntry } from './DataEntryFormFunctions';

const orderedByValueSet = [
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "user", "display": "Sorted by User" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "system", "display": "Sorted by System" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "event-date", "display": "Sorted by Event Date" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "entry-date", "display": "Sorted by Item Date" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "priority", "display": "Sorted by Priority" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "alphabetic", "display": "Sorted Alphabetically" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "category", "display": "Sorted by Category" },
  { "system": "http://terminology.hl7.org/CodeSystem/list-order", "code": "patient", "display": "Sorted by Patient" }
];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.status) {
      innerDiv += "<p><b>Status: </b>" + resource.status + "</p><br/>";
    }
    if (resource.mode) {
      innerDiv += "<p><b>Mode: </b>" + resource.mode + "</p><br/>";
    }
    if (resource.code) {
      innerDiv += "<p><b>Purpose: </b>" + getStringFromFHIR.CodeableConcept(resource.code) + "</p><br/>";
    }
    if (resource.subject?.length > 0) {
      innerDiv += resource.subject.map((subject) => {
        return "<p><b>Subject: </b>" + getStringFromFHIR.Reference(subject) + "</p><br/>"
      }).join("");
    }
    if (resource.encounter) {
      innerDiv += "<p><b>Encounter: </b>" + getStringFromFHIR.Reference(resource.encounter) + "</p><br/>";
    }
    if (resource.date) {
      innerDiv += "<p><b>Date: </b>" + getStringFromFHIR.dateTime(resource.date) + "</p><br/>";
    }
    if (resource.source) {
      innerDiv += "<p><b>Source (Author): </b>" + getStringFromFHIR.Reference(resource.source) + "</p><br/>";
    }
    if (resource.orderedBy) {
      innerDiv += "<p><b>Ordered by: </b>" + getStringFromFHIR.CodeableConcept(resource.orderedBy) + "</p><br/>";
    }
    if (resource.note?.length > 0) {
      innerDiv += resource.note.map((note) => {
        return "<p><b>Note: </b>" + note.text + "</p><br/>"
      }).join("");
    }
    if (resource.entry?.length > 0) {
      innerDiv += resource.entry.map((item) => {
        return "<p><b>Entry: </b>" + getStringFromFHIR.Reference(item.item) + "</p><br/>"
      }).join("");
    }
    if (resource.emptyReason) {
      innerDiv += "<p><b>Why Empty: </b>" + getStringFromFHIR.CodeableConcept(resource.emptyReason) + "</p><br/>";
    }
  } catch { 
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const ListBuilder = ({ resourceState, setResourceState }) => {

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
      <h3 id="description">Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='status' fieldLabel='Status'
          allowedValues={['current', 'retired', 'entered-in-error']}
          startingValue={resourceState.status} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='mode' fieldLabel='Mode'
          allowedValues={['working', 'snapshot', 'changes']}
          startingValue={resourceState.mode} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='code' fieldLabel='Purpose'
          startingValue={resourceState.code} startCollapsed
          setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Reference' elementName='subject' fieldLabel='Subject'
          enableCreation={true} startCollapsed
          startingValue={resourceState.subject} setResourceState={setResourceState} />
        <DataEntry datatype='Reference' elementName='encounter' fieldLabel='Encounter'
          enableCreation={true} referencedResourceTypes={['Encounter']} startCollapsed
          startingValue={resourceState.encounter} setResourceState={setResourceState} />
        <DataEntry datatype='dateTime' elementName='date' fieldLabel='Date'
          startingValue={resourceState.date} setResourceState={setResourceState} />
        <DataEntry datatype='Reference' elementName='source' fieldLabel='Source (Author)'
          enableCreation={true} startCollapsed
          referencedResourceTypes={['Practitioner', 'PractitionerRole', 'Patient', 'Device', 'Organization', 'RelatedPerson', 'CareTeam']}
          startingValue={resourceState.source} setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='orderedBy'
          fieldLabel='Ordered by' startingValue={resourceState.orderedBy || null}
          valueSet={orderedByValueSet} startCollapsed
          setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
          startingValue={resourceState.note || null} startCollapsed
          setResourceState={setResourceState} />
      </div>
      <h3 id="list-entries">List Entries</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='ListEntry' elementName='entry' startCollapsed
          fieldLabel='Entry' startingValue={resourceState.entry} setResourceState={setResourceState} />
        {(!resourceState.entry || resourceState.entry.length === 0) && <>
          <br />
          <p><b>Explain why this List is empty</b></p>
          <DataEntry datatype='CodeableConcept' elementName='emptyReason' fieldLabel='Empty Reason'
            startingValue={resourceState.emptyReason} valueSet={emptyReasonValueSet} startCollapsed
            setResourceState={setResourceState} />
        </>}
      </div>
    </div>
  </div>
}

export default ListBuilder;
