import submitToFevirServer from "./SubmitToFevirServer";

const getResourcesList = async (globalContext, resourcesListState, setResourcesListState, loadMyResources) => {
    if (!loadMyResources || !globalContext.userState.loading) {       //Before I had userState.loading !== undefined && in the conditional, but adding it will break it for unlogged in users
        if (resourcesListState.loadMyResources === undefined || resourcesListState.loadMyResources !== loadMyResources) {
            setResourcesListState((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                    loadMyResources: loadMyResources
                };
            });
            let resourceListByType;
            let resourceTypesList;
            if (loadMyResources) {
                let body = {
                    'functionid': 'getresourcelistbytype',
                    'loadmyresources': loadMyResources, //not functional until accounted for
                    'idToken': ''
                };
                let response = await submitToFevirServer(globalContext, 30000, body, true, false);
                
                if (response?.success) {
                    resourceListByType = response.resourceListByType;
                    resourceTypesList = Object.keys(resourceListByType)
                    resourceTypesList.sort();
                    setResourcesListState((prevState) => {
                        return {
                            ...prevState,
                            resourceDictionary: resourceListByType || null,
                            allResourceTypes: resourceTypesList, //the distinction between allResourceTypes and visibleResourceTypes is lost in this rewrite, will need to determine what is desired if needed to add back
                            visibleResourceTypes: resourceTypesList,
                            //resourcesTypeOptions: resourceTypeOptions,
                            loading: false,
                            displayLinks: true
                        };
                    });
                }
            } else {
                let body = {
                    'functionid': 'getresourcetypeslist',      //getresourcelistbytype
                    'idToken': ''
                };
                let response = await submitToFevirServer(globalContext, 30000, body, true, false);
                if (response?.success) {
                    setResourcesListState((prevState) => {
                        return {
                            ...prevState,
                            //resourceDictionary: {},
                            allResourceTypes: response.resourcetypes, //the distinction between allResourceTypes and visibleResourceTypes is lost in this rewrite, will need to determine what is desired if needed to add back
                            visibleResourceTypes: response.resourcetypes,
                            //resourcesTypeOptions: resourceTypeOptions,
                            loading: false,
                            displayLinks: true
                        };
                    });
                }
            }
        }
    } else {
        setResourcesListState((prevState) => {
            return {
                ...prevState,
                loading: true,
                displayLinks: true
            };
        });
    }
}

export default getResourcesList;