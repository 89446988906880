import { jsonErrorCheck2 } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const softwareScriptTextViewChangesToJson = (fhirJson, softwareScriptState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.contained = softwareScriptState.contained || "DELETEME";
    fhirJson.extension = softwareScriptState.extension || "DELETEME";
    fhirJson.modifierExtension = softwareScriptState.modifierExtension || "DELETEME";
    fhirJson.url = softwareScriptState.url || "DELETEME";
    fhirJson.language = softwareScriptState.language || "DELETEME";
    fhirJson.identifier = softwareScriptState.identifier || "DELETEME";
    fhirJson.version = softwareScriptState.version || "DELETEME";
    fhirJson.versionAlgorithmString = softwareScriptState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = softwareScriptState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = softwareScriptState.name || "DELETEME";
    fhirJson.title = softwareScriptState.title || "DELETEME";
    fhirJson.status = softwareScriptState.status || "unknown";
    fhirJson.experimental = softwareScriptState.experimental || "DELETEME";
    fhirJson.date = softwareScriptState.date || "DELETEME";
    fhirJson.publisher = softwareScriptState.publisher || "DELETEME";
    fhirJson.contact = softwareScriptState.contact || "DELETEME";
    fhirJson.description = softwareScriptState.description || "DELETEME";
    fhirJson.useContext = softwareScriptState.useContext || "DELETEME";
    fhirJson.jurisdiction = softwareScriptState.jurisdiction || "DELETEME";
    fhirJson.purpose = softwareScriptState.purpose || "DELETEME";
    fhirJson.usage = softwareScriptState.usage || "DELETEME";
    fhirJson.copyright = softwareScriptState.copyright || "DELETEME";
    fhirJson.copyrightLabel = softwareScriptState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = softwareScriptState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = softwareScriptState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = softwareScriptState.effectivePeriod || "DELETEME";
    fhirJson.topic = softwareScriptState.topic || "DELETEME";
    fhirJson.author = softwareScriptState.author || "DELETEME";
    fhirJson.editor = softwareScriptState.editor || "DELETEME";
    fhirJson.reviewer = softwareScriptState.reviewer || "DELETEME"
    fhirJson.endorser = softwareScriptState.endorser || "DELETEME";
    fhirJson.relatedArtifact = softwareScriptState.relatedArtifact || "DELETEME";
    fhirJson.subtitle = softwareScriptState.subtitle || "DELETEME";
    fhirJson.nameWithoutExtension = softwareScriptState.nameWithoutExtension || "DELETEME";
    fhirJson.extensionCode = softwareScriptState.extensionCode || "DELETEME";
    fhirJson.format = softwareScriptState.format || "DELETEME";
    fhirJson.classification = softwareScriptState.classification || "DELETEME";
    fhirJson.size = softwareScriptState.size || "DELETEME";
    fhirJson.location = softwareScriptState.location || "DELETEME";
    fhirJson.codeString = softwareScriptState.codeString || "DELETEME";
    fhirJson.containedScript = softwareScriptState.containedScript || "DELETEME";
    fhirJson.dependsOn = softwareScriptState.dependsOn || "DELETEME";
    fhirJson.output = softwareScriptState.output || "DELETEME";
    fhirJson.compatibleWith = softwareScriptState.compatibleWith || "DELETEME";
    fhirJson.licenseTerms = softwareScriptState.licenseTerms || "DELETEME";
    fhirJson.licenseContact = softwareScriptState.licenseContact || "DELETEME";
    fhirJson.authorizationCriteria = softwareScriptState.authorizationCriteria || "DELETEME";
    fhirJson.integrity = softwareScriptState.integrity || "DELETEME";
    fhirJson.expectedQuantity = softwareScriptState.expectedQuantity || "DELETEME";
    for (const key in fhirJson) {
        if (fhirJson[key] === "DELETEME" || (typeof fhirJson[key] === "object" && Object.keys(fhirJson[key]).length === 0) || fhirJson[key] === "" || fhirJson[key] === undefined || fhirJson[key] === null) {
            delete fhirJson[key];
        }
    }
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (softwareScriptState) => {
    let contentArray = softwareScriptState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "SoftwareScript";
    let classifiedFoi = softwareScriptState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = softwareScriptState.title || softwareScriptState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateSoftwareScriptJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let softwareScriptState = formInputsStateRef.current.softwareScriptState;
    let fhirJson = jsonErrorCheck2("SoftwareScript", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }
    if (fhirEntryState.activeIndex === 0) {
        fhirJson = softwareScriptTextViewChangesToJson(fhirJson, softwareScriptState);
        fhirJson = jsonErrorCheck2("SoftwareScript", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(softwareScriptState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateSoftwareScriptJson };