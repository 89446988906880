import EligibilityCriteriaApplication from './EligibilityCriteriaMatchingApplication20220916';
import submitToFevirServer from './SubmitToFevirServer';

const checkEligibilityMatch = async (patientDataBundle, eligibiltyCriteriaEV, globalContext) => {
    //REPLACE THIS WITH THE WORKING FUNCTION
    let reportArray = [];
    for (const index in eligibiltyCriteriaEV) {
        //Add function to check match of patientDataBundle and ec, replace match result of 'Unchecked'

        let ec = eligibiltyCriteriaEV[index];
        const body = {
            'functionid': 'getfhirresource',
            'resourceid': ec.id,
            'resourcetype': "EvidenceVariable",
            'idToken': ''
        };
        let response = await submitToFevirServer(globalContext, 10000, body, true, false);
        if (response?.success && response.fhirjsonstring) {
            const ecJSON = JSON.parse(response.fhirjsonstring);
            const matchResultReport = EligibilityCriteriaApplication(ecJSON, patientDataBundle);
            if (matchResultReport.useContext === undefined) {
                matchResultReport.useContext = [];
            }
            matchResultReport.useContext.push({
                "code": {
                    "system": "https://terminology.hl7.org/3.1.0/CodeSystem-usage-context-type.html",
                    "code": "program",
                    "display": "Program"
                }, "valueCodeableConcept": {
                    "text": "match report"
                }
            });
            let matchResultReportDisplay;

            //This can be turned into a function so it can be used in the other two places without repeated code
            for (let extensionIndex in matchResultReport.extension) {
                let extension = matchResultReport.extension[extensionIndex];
                if (extension.valueCoding) {
                    if (extension.valueCoding.system === "http://www.eunochains.com/fhir/CodeSystem/714a86033e892a0c5406cfb5fd2d265fd708b200d22845cbf54844f9ee3ae992") {
                        if (extension.valueCoding.code === "result_eligibility_criteria_match") {
                            matchResultReportDisplay = "✅ Match";
                        } else if (extension.valueCoding.code === "result_eligibility_criteria_no_match") {
                            matchResultReportDisplay = "❌ No match";
                        } else if (extension.valueCoding.display) {
                            matchResultReportDisplay = extension.valueCoding.display;
                        }
                    }
                }
            }

            reportArray.push({ "success": true, "title": ecJSON.title, "matchReportDisplay": matchResultReportDisplay, "matchResultReport": matchResultReport, "description": "Result for " + ecJSON.title + " ==> " + matchResultReportDisplay });
        } else {
            alert("The attempt to get FHIR Resource " + ec.id + "failed.");
            reportArray.push({ "success": false, "id": ec.id, "description": "The attempt to get FHIR Resource " + ec.id + "failed." });
        }

    }

    return reportArray;
}




// Entry Point Process.Main(ec, data);
//-----------------------------------------------------------
//***CPcomemnt: deleted the rest which was test data */

export default checkEligibilityMatch;