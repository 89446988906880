import { jsonErrorCheck } from './ResourceFunctions';

const schemaElementTextViewChangesToJson = (fhirJson, schemaElementState) => {
    if (fhirJson === undefined) {
      return ;
    }

    fhirJson.title = schemaElementState.title || '';
    fhirJson.name = schemaElementState.name || '';
    fhirJson.schema = schemaElementState.schema || '';
    fhirJson.elementName = schemaElementState.elementName || '';
    fhirJson.minimumCardinality = schemaElementState.minimumCardinality || '';
    fhirJson.maximumCardinality = schemaElementState.maximumCardinality || '';
    fhirJson.description = schemaElementState.description || '';
    fhirJson.schemaVersion = schemaElementState.schemaVersion || '';
    fhirJson.path = schemaElementState.path || '';
    fhirJson.elementPosition = schemaElementState.elementPosition || '';
    if (schemaElementState.datatype.length > 0) {
        fhirJson.datatype = schemaElementState.datatype;
    } else {
        delete fhirJson.datatype;
    }
    let jsonDatatypeCodeableConcepts = [];
    for (let datatypeCodeableConceptIndex in schemaElementState.datatypeCodeableConcept) {
        let datatypeCodeableConcept = schemaElementState.datatypeCodeableConcept[datatypeCodeableConceptIndex];
        try {
            if (datatypeCodeableConcept) {
                jsonDatatypeCodeableConcepts.push(JSON.parse(datatypeCodeableConcept));
            } else {
                //jsonDatatypes.push({});
            }
        } catch (e) {
            alert("Problem with datatypeCodeableConcept JSON: " + (parseInt(datatypeCodeableConceptIndex)+1).toString() + " of " + schemaElementState.datatypeCodeableConcept.length.toString())
            return false;
        }
    }
    fhirJson.datatypeCodeableConcept = jsonDatatypeCodeableConcepts;
    if (fhirJson.datatypeCodeableConcept.length === 0) {
        delete fhirJson.datatypeCodeableConcept;
    }
    let jsonAllowedValues = [];
    for (let allowedValueIndex in schemaElementState.allowedValue) {
        let allowedValue = schemaElementState.allowedValue[allowedValueIndex];
        try {
            if (allowedValue) {
                jsonAllowedValues.push(JSON.parse(allowedValue));
            } else {
                //jsonAllowedValues.push({});                
            }
        } catch (e) {
            alert("Problem with AllowedValue JSON: " + (parseInt(allowedValueIndex)+1).toString() + " of " + schemaElementState.allowedValue.length.toString())
            return false;
        }
    }
    fhirJson.allowedValue = jsonAllowedValues;

    fhirJson.contains = schemaElementState.contains;
    fhirJson.rule = schemaElementState.rule;

    for (let mappingIndex in schemaElementState.mapping) {
        if (fhirJson.map === undefined) {
            fhirJson.map = [];
        }
        if (mappingIndex >= fhirJson.map.length) {
            fhirJson.map.push({});
        }
        let mapping = schemaElementState.mapping[mappingIndex];

        if (mapping.mappingFhirBuildStatus === "Not Relevant") {
            fhirJson.map[mappingIndex].laterFhirBuildStatus = "";
        } else {
            fhirJson.map[mappingIndex].laterFhirBuildStatus = mapping.mappingFhirBuildStatus;
        }
        if (fhirJson.map[mappingIndex].schemaElement === undefined) {
            fhirJson.map[mappingIndex].schemaElement = {};
        }
        fhirJson.map[mappingIndex].schemaElement.reference = "";
        if (mapping.mappingSchemaElementFOI) {
            fhirJson.map[mappingIndex].schemaElement.reference = "SchemaElement/"+mapping.mappingSchemaElementFOI.replace("SchemaElement/", "")
        }
        fhirJson.map[mappingIndex].schemaElement.type = "SchemaElement";
        fhirJson.map[mappingIndex].schemaElement.display = mapping.mappingSchemaElementDisplay;
        fhirJson.map[mappingIndex].schema = mapping.mappingSchema;
        fhirJson.map[mappingIndex].schemaVersion = mapping.mappingSchemaVersion;
        fhirJson.map[mappingIndex].schemaPath = mapping.mappingSchemaPath;
        fhirJson.map[mappingIndex].elementName = mapping.mappingElementName;
        fhirJson.map[mappingIndex].rule = mapping.mappingRule;
        if (mapping.inverse) {
            fhirJson.map[mappingIndex].inverse = true;
        } else {
            delete fhirJson.map[mappingIndex].inverse;
        }
    }

    return fhirJson;
}

const builderUpdateSchemaElementJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let schemaElementState = formInputsStateRef.current.schemaElementState;

    let fhirJson = jsonErrorCheck("SchemaElement", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString, fhirEntryState.fhirJson, "");
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex == 0) {
        fhirJson = schemaElementTextViewChangesToJson(fhirJson, schemaElementState);
    }
    return fhirJson;
}

export { builderUpdateSchemaElementJson };