import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import getResourcesList from './GetResourcesList';
import { FavoriteResourcesSection } from './FavoriteResourcesPage';
import FevirContext from './FevirContext';
import submitToFevirServer from "./SubmitToFevirServer";

const ContentResourcesPage = ({ useTitle, loadMyResources, signInStatusState, resourcesListState, setResourcesListState }) => {
  let pageTitle = "FEvIR Resources";
  if (loadMyResources) {
    pageTitle = "FEvIR Resources You Made";
  }
  useTitle(pageTitle);

  const [favoriteResourcesExpandedState, setFavoriteResourcesExpandedState] = useState(false);

  useEffect(async () => {
    let loadMyResourcesLoggedIn = false;
    if (loadMyResources && !globalContext.userState.loading && globalContext.userState.id) {
      loadMyResourcesLoggedIn = true;
    }
    if (loadMyResources !== true || (loadMyResources && loadMyResourcesLoggedIn)) {
      await getResourcesList(globalContext, resourcesListState, setResourcesListState, loadMyResources);
    } else if (!globalContext.userState.loading) {
      setResourcesListState((prevState) => {
        return {
          ...prevState,
          loading: false,
          displayLinks: loadMyResourcesLoggedIn
        };
      });
    }
  }, [signInStatusState]);

  const globalContext = useContext(FevirContext);
  const FhirResourcesLinks = () => {

    let resourcesLinks = [];
    for (let resourceType of resourcesListState.visibleResourceTypes) {
      //let resourceList = resourcesListState.resourceDictionary[resourceType];
      let resourceList;
      if (loadMyResources) {
        resourceList = resourcesListState.resourceDictionary[resourceType];
      } else {
        resourceList = resourcesListState[resourceType + "---resources"];
      }
      resourcesLinks.push(<>
        <div className="resourceListHeader" onClick={async () => {
          let expanded = resourcesListState[resourceType + "expanded"];
          if (expanded === undefined) {
            expanded = false;
          }
          if (expanded) {
            expanded = false;
          } else {
            expanded = true;
          }
          setResourcesListState((prevstate) => {
            return {
              ...prevstate,
              [resourceType + "expanded"]: expanded
            };
          });
          let resourcesForType;
          if (!loadMyResources && resourcesListState[resourceType + "---resources"] === undefined) {
            let body = {
              'functionid': 'getresourcelistbyspecifiedtype',
              'resourcetype': resourceType,
              'idToken': ''
            };
            let response = await submitToFevirServer(globalContext, 30000, body, true, false);

            if (response?.success) {
              resourcesForType = response.resourcelistbyspecifiedtype;
            }

            setResourcesListState((prevstate) => {
              return {
                ...prevstate,
                [resourceType + "expanded"]: expanded,    //Line not needed, but here just in case
                [resourceType + "---resources"]: resourcesForType || prevstate[resourceType + "---resources"] || undefined,
              };
            });
          }
        }
        }>

          <div style={{ paddingTop: "20px" }} className="resourceListHeaderText">
            <div>
              <span>{resourceType + " Resources"} <b style={{ color: "#0000D0" }}>{(!resourcesListState[resourceType + "expanded"]) ? "▶" : "▼"}</b></span>
            </div>
          </div>
          {(!resourcesListState[resourceType + "expanded"]) &&
            <div style={{ paddingTop: "0px", paddingBottom: "0px" }} className="resourceListHeaderExpand">
              {/*<p style={{color: "blue"}}><b>E X P A N D</b></p>*/}
            </div>
          }
        </div>

        {(resourcesListState[resourceType + "expanded"] && resourcesListState.projectResourcesList.length === 1 && resourcesListState.projectResourcesList[0] === "") &&
          <>{resourceList ?
            <>{resourceList?.map((resource, resourceIndex) => {
              return <div style={{ paddingTop: "0px", paddingBottom: "4px" }} key={resourceIndex}>
                <Link to={`/resources/${resource.resourcetype}/${resource.id}`}>
                  <span style={{ fontSize: "24px" }}>{resource.title}</span>
                </Link><span>{resource.status === "draft" && <>&nbsp;&nbsp;(draft)</>}</span>
                <br />
              </div>
            })}</>
            :
            <>
              <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /><span style={{ fontSize: "larger" }}> Loading resources...</span></>
              {/*
                <Dimmer className={"loadingDimmer"} active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              */}
            </>
          }</>
        }
      </>);
    }

    return <div>
      {globalContext.favoriteFOIsState.length > 0 &&
        <div>
          <div className="resourceListHeader" onClick={() => { setFavoriteResourcesExpandedState((prevState) => { return !prevState }); }}>
            <div style={{ paddingTop: "20px" }} className="resourceListHeaderText">
              <div>
                <span><span style={{ color: "#C4941C" }}>All My Favorite Resources</span> <b style={{ color: "#0000D0" }}>{(!favoriteResourcesExpandedState) ? "▶" : "▼"}</b></span>
              </div>
            </div>
            {(!favoriteResourcesExpandedState) &&
              <div style={{ paddingTop: "0px", paddingBottom: "0px" }} className="resourceListHeaderExpand">
                {/*<p style={{color: "blue"}}><b>E X P A N D</b></p>*/}
              </div>
            }
          </div>
          {favoriteResourcesExpandedState && <FavoriteResourcesSection />}
        </div>
      }
      {resourcesLinks?.map((resourceLink, index) => { return <div key={index}>{resourceLink}</div> })}
    </div>
  };

  return (
    <div>
      <Segment className={`containerSegment remainderOfPageSegment`} raised>
        {(globalContext.userState.loading || resourcesListState.loading)
          &&
          <Dimmer className={"loadingDimmer"} active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        }
        {(!globalContext.userState.loading && loadMyResources && resourcesListState.loading === false && resourcesListState.displayLinks !== false && resourcesListState.visibleResourceTypes.length === 0)
          ?
          <h1>You haven't created any resources yet.</h1>
          :
          <>{(!globalContext.userState.loading && loadMyResources && !globalContext.userState.id) ?
            <h1>Please login to view your resources</h1>
            :
            <>{resourcesListState.visibleResourceTypes.length !== 0 && <FhirResourcesLinks />}</>
          }</>
        }
      </Segment>
    </div>
  );
};

export default ContentResourcesPage;