import React, { useState, useEffect, useImperativeHandle } from 'react';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { SimpleResourceFieldViewer } from './ResourceFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';

const characteristicDefinitionAllowedDatatypes = ['Reference', 'uri', 'CodeableConcept', 'ByTypeAndValue', 'ByCombination'];
const characteristicInstancesAllowedDatatypes = ['Quantity', 'Range'];
const characteristicDurationAllowedDatatypes = ['Quantity', 'Range'];

const CharacteristicResourceEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson, classificationsArrayState, classificationsLoadedState }) => {
/*
  const resourceElementNames = ["id", "meta", "implicitRules", "language", "text", "contained", "extension", "modifierExtension",
    "url", "identifier", "version", "versionAlgorithmString", "versionAlgorithmCoding", "name", "title", "status", "experimental",
    "date", "publisher", "contact", "description", "note", "useContext", "jurisdiction", "purpose", "usage", "copyright", "copyrightLabel",
    "approvalDate", "lastReviewDate", "effectivePeriod", "topic", "author", "editor", "reviewer", "endorser", "relatedArtifact",
    "exclude", "definitionReference", "definitionUri", "definitionCodeableConcept", "definitionByTypeAndValue",
    "definitionByCombination", "instancesQuantity", "instancesRange", "durationQuantity", "durationRange",
    "timeFromEvent", "executableExpression"]
*/

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "Characteristic", "id": fhirJson.id, "meta": fhirJson.meta,
    "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "versionAlgorithmString": fhirJson.versionAlgorithmString, "versionAlgorithmCoding": fhirJson.versionAlgorithmCoding,
    "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact,
    "description": fhirJson.description, "note": fhirJson.note, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction,
    "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "exclude": fhirJson.exclude, "definitionReference": fhirJson.definitionReference, "definitionUri": fhirJson.definitionUri,
    "definitionCodeableConcept": fhirJson.definitionCodeableConcept, "definitionByTypeAndValue": fhirJson.definitionByTypeAndValue,
    "definitionByCombination": fhirJson.definitionByCombination, "instancesQuantity": fhirJson.instancesQuantity,
    "instancesRange": fhirJson.instancesRange, "durationQuantity": fhirJson.durationQuantity, "durationRange": fhirJson.durationRange,
    "timeFromEvent": fhirJson.timeFromEvent, "executableExpression": fhirJson.executableExpression, "newClassifications": null
  });

  let startingCharacteristicDefinitionDatatype = 'none';
  if (fhirJson.definitionCodeableConcept) { startingCharacteristicDefinitionDatatype = 'CodeableConcept'; }
  if (fhirJson.definitionReference) { startingCharacteristicDefinitionDatatype = 'Reference'; }
  if (fhirJson.definitionUri || fhirJson.definitionCanonical) { startingCharacteristicDefinitionDatatype = 'uri'; }
  if (fhirJson.definitionByTypeAndValue) {
    startingCharacteristicDefinitionDatatype = 'ByTypeAndValue';
  }
  if (fhirJson.definitionByCombination) { startingCharacteristicDefinitionDatatype = 'ByCombination'; }
  let startingCharacteristicInstancesDatatype = 'none';
  if (fhirJson.instancesQuantity) { startingCharacteristicInstancesDatatype = 'Quantity'; }
  if (fhirJson.instancesRange) { startingCharacteristicInstancesDatatype = 'Range'; }
  let startingCharacteristicDurationDatatype = 'none';
  if (fhirJson.durationQuantity) { startingCharacteristicDurationDatatype = 'Quantity'; }
  if (fhirJson.durationRange) { startingCharacteristicDurationDatatype = 'Range'; }

  const [characteristicDefinitionDatatypeState, setCharacteristicDefinitionDatatypeState] = useState(startingCharacteristicDefinitionDatatype);
  const [characteristicInstancesDatatypeState, setCharacteristicInstancesDatatypeState] = useState(startingCharacteristicInstancesDatatype);
  const [characteristicDurationDatatypeState, setCharacteristicDurationDatatypeState] = useState(startingCharacteristicDurationDatatype);

  useImperativeHandle(formInputsStateRef, () => ({
    characteristicResourceState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (characteristicDefinitionDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'definitionReference': null, 'definitionUri': null, 'definitionByTypeAndValue': null, 'definitionByCombination': null } })
    }
    if (characteristicDefinitionDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'definitionCodeableConcept': null, 'definitionUri': null, 'definitionByTypeAndValue': null, 'definitionByCombination': null } })
    }
    if (characteristicDefinitionDatatypeState === 'uri') {
      setResourceState(prevState => { return { ...prevState, 'definitionReference': null, 'definitionCodeableConcept': null, 'definitionByTypeAndValue': null, 'definitionByCombination': null } })
    }
    if (characteristicDefinitionDatatypeState === 'ByTypeAndValue') {
      setResourceState(prevState => { return { ...prevState, 'definitionReference': null, 'definitionCodeableConcept': null, 'definitionUri': null, 'definitionByCombination': null } })
    }
    if (characteristicDefinitionDatatypeState === 'ByCombination') {
      setResourceState(prevState => { return { ...prevState, 'definitionReference': null, 'definitionCodeableConcept': null, 'definitionUri': null, 'definitionByTypeAndValue': null } })
    }
  }), [characteristicDefinitionDatatypeState]);

  useEffect((() => {
    if (characteristicInstancesDatatypeState === 'Quantity') {
      setResourceState(prevState => { return { ...prevState, 'instancesRange': null } })
    }
    if (characteristicInstancesDatatypeState === 'Range') {
      setResourceState(prevState => { return { ...prevState, 'instancesQuantity': null } })
    }
  }), [characteristicInstancesDatatypeState]);

  useEffect((() => {
    if (characteristicDurationDatatypeState === 'Quantity') {
      setResourceState(prevState => { return { ...prevState, 'durationRange': null } })
    }
    if (characteristicDurationDatatypeState === 'Range') {
      setResourceState(prevState => { return { ...prevState, 'durationQuantity': null } })
    }
  }), [characteristicDurationDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="description">Description</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name (for machine use)'
          startingValue={resourceState.name} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startingValue={resourceState.description} setResourceState={setResourceState} />
        <DataEntry asArray={true} datatype='Annotation' elementName='note' fieldLabel='Note'
          startEmptyArrayClosed={true} deletableArray={true}
          startingValue={resourceState.note} setResourceState={setResourceState} />
      </div>
      <h3 id="definition">Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='boolean' elementName='exclude' fieldLabel='Exclusion?'
          startingValue={resourceState.exclude} setResourceState={setResourceState} />
        <p><b>Definition Detail:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='definition[x]' allowedDatatypes={characteristicDefinitionAllowedDatatypes}
            datatypeState={characteristicDefinitionDatatypeState} setDatatypeState={setCharacteristicDefinitionDatatypeState} />
          {(characteristicDefinitionDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='definitionReference'
              fieldLabel='Define by Reference' startingValue={resourceState.definitionReference || null}
              enableCreation={true} setResourceState={setResourceState} />}
          {(characteristicDefinitionDatatypeState === 'uri') &&
            <DataEntry datatype='uri' elementName='definitionUri'
              fieldLabel='Define by URL' startingValue={resourceState.definitionUri || null}
              setResourceState={setResourceState} />}
          {(characteristicDefinitionDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='definitionCodeableConcept'
              fieldLabel='Define by Codeable Concept' startingValue={resourceState.definitionCodeableConcept || null}
              setResourceState={setResourceState} />}
          {(characteristicDefinitionDatatypeState === 'ByTypeAndValue') &&
            <DataEntry datatype='CharacteristicDefinitionByTypeAndValue' elementName='definitionByTypeAndValue'
              fieldLabel='Define by Type and Value' startingValue={resourceState.definitionByTypeAndValue || null}
              setResourceState={setResourceState} />
          }
          {(characteristicDefinitionDatatypeState === 'ByCombination') &&
            <DataEntry datatype='CharacteristicDefinitionByCombination' elementName='definitionByCombination'
              fieldLabel='Define by Combination' startingValue={resourceState.definitionByCombination || null}
              setResourceState={setResourceState} />
          }
          <br /><br />
          <p><b>Definition Modifiers:</b></p>
          <div style={{ marginLeft: "24px" }}>
            <p><b>Number of instances meeting characteristic definition:</b></p>
            <div style={{ marginLeft: "24px" }}>
              <DatatypeSelector elementXName='instances[x]' allowedDatatypes={characteristicInstancesAllowedDatatypes}
                datatypeState={characteristicInstancesDatatypeState} setDatatypeState={setCharacteristicInstancesDatatypeState} />
              {(characteristicInstancesDatatypeState === 'Quantity') &&
                <DataEntry datatype='Quantity' elementName='instancesQuantity'
                  fieldLabel='Number of instances matching characteristic definition' startingValue={resourceState.instancesQuantity || null}
                  setResourceState={setResourceState} />
              }
              {(characteristicInstancesDatatypeState === 'Range') &&
                <DataEntry datatype='Range' elementName='instancesRange'
                  fieldLabel='Number of instances matching characteristic definition' startingValue={resourceState.instancesRange || null}
                  setResourceState={setResourceState} />
              }
            </div>
            <br /><br />
            <p><b>Length of time meeting characteristic definition:</b></p>
            <div style={{ marginLeft: "24px" }}>
              <DatatypeSelector elementXName='duration[x]' allowedDatatypes={characteristicDurationAllowedDatatypes}
                datatypeState={characteristicDurationDatatypeState} setDatatypeState={setCharacteristicDurationDatatypeState} />
              {(characteristicDurationDatatypeState === 'Quantity') &&
                <DataEntry datatype='Quantity' elementName='durationQuantity'
                  fieldLabel='Length of time meeting characteristic definition' startingValue={resourceState.durationQuantity || null}
                  setResourceState={setResourceState} />
              }
              {(characteristicDurationDatatypeState === 'Range') &&
                <DataEntry datatype='Range' elementName='durationRange'
                  fieldLabel='Length of time meeting characteristic definition'
                  startingValue={resourceState.durationRange || null}
                  setResourceState={setResourceState} />
              }
            </div>
            <br /><br />
            <DataEntry asArray={true} datatype='CharacteristicTimeFromEvent' elementName='timeFromEvent'
              fieldLabel='Timing from Event'
              startEmptyArrayClosed={true} deletableArray={true}
              startingValue={resourceState.timeFromEvent} setResourceState={setResourceState} />
          </div>
        </div>
      </div>
      <h3 id="expression">Expression</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry asArray={true} datatype='CharacteristicExecutableExpression' elementName='executableExpression' fieldLabel='Expression'
          startEmptyArrayClosed={true} deletableArray={true}
          startingValue={resourceState.executableExpression} setResourceState={setResourceState} />
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
         <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
                <DisplayClassifiers classificationsArray={classificationsArrayState} />
                :
                <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
            }
        </div>}   
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default CharacteristicResourceEdit;
