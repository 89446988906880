import React, { useState, useEffect } from 'react';
import { getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector } from './DataEntryFormFunctions';

const measureDotBasisValues = ['Observation', 'Condition', 'Medication'];
const measureDotScoringSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/measure-scoring', 'display': 'HL7 Measure Scoring CodeSystem' }];
const measureDotScoringUnitSystemChoices = [{ 'uri': 'http://unitsofmeasure.org	', 'display': 'UCUM' }];
const measureDotCompositeScoringSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/composite-measure-scoring', 'display': 'HL7 Composite Measure Scoring CodeSystem' }];
const measureDotTypeSystemChoices = [{ 'uri': 'http://terminology.hl7.org/CodeSystem/measure-type', 'display': 'HL7 Measure Type CodeSystem' }];
const measureDotSubjectAllowedDatatypes = ['CodeableConcept', 'Reference'];
const measureDotSubjectReferenceResourceTypes = ['Group'];
const measureDotSubjectCodeableConceptValueSet = [
  { system: "http://hl7.org/fhir/fhir-types", code: "CareTeam", display: "CareTeam" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Device", display: "Device" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Group", display: "Group" },
  { system: "http://hl7.org/fhir/fhir-types", code: "HealthcareService", display: "HealthcareService" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Location", display: "Location" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Organization", display: "Organization" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Patient", display: "Patient" },
  { system: "http://hl7.org/fhir/fhir-types", code: "Practitioner", display: "Practitioner" },
  { system: "http://hl7.org/fhir/fhir-types", code: "PractitionerRole", display: "PractitionerRole" },
  { system: "http://hl7.org/fhir/fhir-types", code: "RelatedPerson", display: "RelatedPerson" }
];

const generateNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    if (resource.subtitle && typeof resource.type === "string") {
      innerDiv += "<p><b>Subtitle: </b>" + resource.subtitle + "</p><br/>";
    }
    if (resource.name) {
      innerDiv += "<p><b>Name: </b>" + resource.name + "</p><br/>";
    }
    if (resource.description) {
      innerDiv += "<p><b>Description: </b>" + resource.description + "</p><br/>";
    }
    if (resource.subjectCodeableConcept) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.CodeableConcept(resource.subjectCodeableConcept) + "</p><br/>";
    }
    if (resource.subjectReference) {
      innerDiv += "<p><b>Subject: </b>" + getStringFromFHIR.Reference(resource.subjectReference) + "</p><br/>";
    }
    if (resource.basis) {
      innerDiv += "<p><b>Basis: </b>" + resource.basis + "</p><br/>";
    }
    if (resource.library?.length > 0) {
      innerDiv += resource.library.map((item) => {
        return "<p><b>Library: </b>" + item + "</p><br/>"
      }).join("");
    }
    if (resource.disclaimer) {
      innerDiv += "<p><b>Disclaimer: </b>" + resource.disclaimer + "</p><br/>";
    }
    if (resource.scoring) {
      innerDiv += "<p><b>Scoring: </b>" + getStringFromFHIR.CodeableConcept(resource.scoring) + "</p><br/>";
    }
    if (resource.scoringUnit) {
      innerDiv += "<p><b>Scoring Unit: </b>" + getStringFromFHIR.CodeableConcept(resource.scoringUnit) + "</p><br/>";
    }
    if (resource.compositeScoring) {
      innerDiv += "<p><b>Composite Scoring: </b>" + getStringFromFHIR.CodeableConcept(resource.compositeScoring) + "</p><br/>";
    }
    if (resource.type?.length > 0) {
      innerDiv += resource.type.map((item) => {
        return "<p><b>Measure Type: </b>" + getStringFromFHIR.CodeableConcept(item) + "</p><br/>"
      }).join("");
    }
    if (resource.riskAdjustment) {
      innerDiv += "<p><b>Risk Adjustment: </b>" + resource.riskAdjustment + "</p><br/>";
    }
    if (resource.rateAggregation) {
      innerDiv += "<p><b>Rate Aggregation: </b>" + resource.rateAggregation + "</p><br/>";
    }
    if (resource.rationale) {
      innerDiv += "<p><b>Rationale: </b>" + resource.rationale + "</p><br/>";
    }
    if (resource.clinicalRecommendationStatement) {
      innerDiv += "<p><b>Clinical Recommendation Statement: </b>" + resource.clinicalRecommendationStatement + "</p><br/>";
    }
    if (resource.improvementNotation) {
      innerDiv += "<p><b>Improvement Notation: </b>" + getStringFromFHIR.CodeableConcept(resource.improvementNotation) + "</p><br/>";
    }
    if (resource.guidance) {
      innerDiv += "<p><b>Guidance: </b>" + resource.guidance + "</p><br/>";
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const MeasureBuilder = ({ resourceState, setResourceState }) => {

  let startingSubjectDatatype = 'none';
  if (resourceState.subjectCodeableConcept) { startingSubjectDatatype = 'CodeableConcept'; }
  if (resourceState.subjectReference) { startingSubjectDatatype = 'Reference'; }

  const [subjectDatatypeState, setSubjectDatatypeState] = useState(startingSubjectDatatype);

  useEffect((() => {
    if (subjectDatatypeState === 'CodeableConcept') {
      setResourceState(prevState => { return { ...prevState, 'subjectReference': null } })
    }
    if (subjectDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'subjectCodeableConcept': null } })
    }
  }), [subjectDatatypeState]);

  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="summary">Summary</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateNarrative(resourceState)} startCollapsed
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
      <h3 id="title">Title</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='string' elementName='title' fieldLabel='Title'
          startingValue={resourceState.title} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='subtitle' fieldLabel='Subtitle'
          startingValue={resourceState.subtitle} setResourceState={setResourceState} />
        <DataEntry datatype='string' elementName='name' fieldLabel='Name'
          startingValue={resourceState.name} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
          startCollapsed
          startingValue={resourceState.description} setResourceState={setResourceState} />
      </div>
      <h3 id="measure-definition">Measure Definition</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Subject:</b></p>
        <div style={{ marginLeft: "24px" }}>
          <DatatypeSelector elementXName='subject[x]' allowedDatatypes={measureDotSubjectAllowedDatatypes}
            datatypeState={subjectDatatypeState} setDatatypeState={setSubjectDatatypeState} />
          {(subjectDatatypeState === 'CodeableConcept') &&
            <DataEntry datatype='CodeableConcept' elementName='subjectCodeableConcept'
              fieldLabel='Subject (as CodeableConcept)' startCollapsed
              startingValue={resourceState.subjectCodeableConcept || null}
              valueSet={measureDotSubjectCodeableConceptValueSet} setResourceState={setResourceState} />}
          {(subjectDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='subjectReference' fieldLabel='Subject (as Reference)'
              referencedResourceTypes={measureDotSubjectReferenceResourceTypes} startCollapsed
              startingValue={resourceState.subjectReference || null} setResourceState={setResourceState} />}
        </div>
        <DataEntry asArray={true} datatype='uri' elementName='library'
          fieldLabel='Library (canonical URL)' startingValue={resourceState.library} setResourceState={setResourceState} />
        <DataEntry datatype='code' elementName='basis' fieldLabel='Basis' startingValue={resourceState.basis} setResourceState={setResourceState}
          allowedValues={measureDotBasisValues}
          dataEntryStyle='dropdown' />
      </div>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='disclaimer' fieldLabel='Disclaimer'
          startingValue={resourceState.disclaimer} startCollapsed
          setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='scoring' fieldLabel='Scoring'
          startingValue={resourceState.scoring} startCollapsed
          setResourceState={setResourceState} systemChoices={measureDotScoringSystemChoices} systemChoicesOpen={true} />
        <DataEntry datatype='CodeableConcept' elementName='scoringUnit' fieldLabel='Scoring Unit'
          startingValue={resourceState.scoringUnit} startCollapsed
          setResourceState={setResourceState} systemChoices={measureDotScoringUnitSystemChoices} systemChoicesOpen={true} />
        <DataEntry datatype='CodeableConcept' elementName='compositeScoring' fieldLabel='Composite Scoring'
          startingValue={resourceState.compositeScoring} startCollapsed
          setResourceState={setResourceState} systemChoices={measureDotCompositeScoringSystemChoices} systemChoicesOpen={true} />
        <DataEntry asArray={true} datatype='CodeableConcept' elementName='type' fieldLabel='Measure Type'
          startingValue={resourceState.type} systemChoices={measureDotTypeSystemChoices}
          systemChoicesOpen={true} setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='riskAdjustment' fieldLabel='Risk Adjustment'
          startingValue={resourceState.riskAdjustment} startCollapsed
          setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='rateAggregation' fieldLabel='Rate Aggregation'
          startingValue={resourceState.rateAggregation} startCollapsed
          setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='rationale' fieldLabel='Rationale'
          startingValue={resourceState.rationale} startCollapsed
          setResourceState={setResourceState} />
        <DataEntry datatype='markdown' elementName='clinicalRecommendationStatement'
          fieldLabel='Clinical Recommendation Statement' startCollapsed
          startingValue={resourceState.clinicalRecommendationStatement}
          setResourceState={setResourceState} />
        <DataEntry datatype='CodeableConcept' elementName='improvementNotation'
          fieldLabel='Improvement Notation' startCollapsed
          startingValue={resourceState.improvementNotation}
          setResourceState={setResourceState} systemChoicesOpen={true} />
        <p>Placeholder - data entry for term BackboneElement</p>
        <DataEntry datatype='markdown' elementName='guidance' fieldLabel='Guidance'
          startingValue={resourceState.guidance} startCollapsed
          setResourceState={setResourceState} />
        <p>Placeholder - data entry for group BackboneElement</p>
        <p>Placeholder - data entry for supplementalData BackboneElement</p>
      </div>
    </div>
  </div>
}

export default MeasureBuilder;
