import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Segment } from 'semantic-ui-react';

import scrollTo from './ScrollTo';
import { navigateToAnotherRobatTerm } from './CodeSystemFunctions';
import FevirContext from './FevirContext';

const bulletPoints = ['◉', '⦾', '■', '○', '♦', '◘', '•'];

let separatorCharacter = ": ";

const NavigationRobatTermSegment = ({ resourceId, fhirEntryState, setFhirEntryState, formInputsStateRef, changeFormState, changeFhirEntryState, addToast }) => {
    const globalContext = useContext(FevirContext);
    const generatePathsLookup = (parentJson, parentPathString, dictionary) => {
        if (parentJson) {
            let separator = "";
            if (parentPathString) {
                separator = separatorCharacter;
            }
            let concepts;
            if (parentJson.content) {
                concepts = parentJson.content;
            } else if (parentJson.component) {
                concepts = parentJson.component;
            } else if (parentJson.concept) {
                concepts = parentJson.concept;
            }
            for (let conceptIndex in concepts) {
                let concept = concepts[conceptIndex];
                if (concept.type && concept.type.text !== "Profile") {
                    let code;
                    let display;
                    if (concept.type) {
                        for (let codingIndex in concept.type.coding) {
                            let coding = concept.type.coding[codingIndex];
                            if (coding.code && coding.display) {
                                code = coding.code;
                                concept.code = code;
                                display = coding.display;
                                concept.display = display;
                                break;
                            }
                        }
                    }
                    if (display) {
                        let pathString = parentPathString + separator + display;
                        if (concept.component?.length > 0) {
                            dictionary[pathString] = { expanded: true };
                            generatePathsLookup(concept, pathString, dictionary);
                        }
                    }
                }
            }
        }
        return dictionary
    }

    const [robatJsonState, setRobatJsonState] = useState({});

    const [navigationCollapsedState, setNavigationCollapsedState] = useState(generatePathsLookup({}, "", {}));

    useEffect(async () => {
        let json;
        if (fhirEntryState.fhirEntryString) {
            json = JSON.parse(fhirEntryState.fhirEntryString);
            if (JSON.stringify(robatJsonState) !== JSON.stringify(json)) {
                setRobatJsonState(json);
                setNavigationCollapsedState(generatePathsLookup(json, "", {}));
                let scrollToId = "termdetail-navigation-code-" + fhirEntryState.conceptCode;
                if (fhirEntryState.conceptPathIndexes?.length === 1 && parseInt(fhirEntryState.conceptPathIndexes[0]) === 0) {
                    scrollToId = ""; //So it doesn't scroll for the top-most element
                }
                scrollTo("navigationTermDetailSegment", scrollToId, true);
                if (fhirEntryState.initialScrolled !== true) {
                    setFhirEntryState(prevState => { return { ...prevState, initialScrolled: true } });
                }
            }
        }
    }, [fhirEntryState]);

    const history = useHistory();

    const NavigationConcepts = ({ parentJson, level, parentPathString, parentPath, parentPathIndexes, setFhirEntryState, formInputsStateRef, changeFhirEntryState }) => {
        let style = { paddingLeft: (level * 10).toString() + "px" };
        let separator = "";
        if (parentPathString) {
            separator = separatorCharacter;
        }
        let concepts;
        if (parentJson) {
            if (parentJson.content) {
                concepts = parentJson.content;
            } else if (parentJson.component) {
                concepts = parentJson.component;
            } else if (parentJson.concept) {
                concepts = parentJson.concept;
            }
        }
        return <>
            {Array.isArray(concepts) && concepts.map((concept, conceptIndex) => {
                let display = concept.display;
                if (display) {
                    let pathString = parentPathString + separator + display;
                    let path = [...parentPath, display];
                    let pathIndexes = [...parentPathIndexes, conceptIndex];

                    let noData = true;
                    //let riskOfBiasRatingDisplay;
                    let ratingDisplay;
                    for (let classifierIndex in concept.classifier) {
                        let classifier = concept.classifier[classifierIndex];
                        for (let codingIndex in classifier.coding) {
                            let classifierCoding = classifier.coding[codingIndex];
                            let classifierCode = classifierCoding.code;
                            if (["SEVCO:00186", "SEVCO:00187", "SEVCO:00188", "SEVCO:00189", "SEVCO:00190", "SEVCO:00191", "SEVCO:00192"].includes(classifierCode)) {
                                noData = false;
                                if (classifierCode === "SEVCO:00186") {
                                    ratingDisplay = "Low";
                                } else if (classifierCode === "SEVCO:00187") {
                                    ratingDisplay = "Moderate";
                                } else if (classifierCode === "SEVCO:00188") {
                                    ratingDisplay = "High";
                                } else if (classifierCode === "SEVCO:00189") {
                                    ratingDisplay = "Serious";
                                } else if (classifierCode === "SEVCO:00190") {
                                    ratingDisplay = "Critical";
                                } else if (classifierCode === "SEVCO:00191") {
                                    ratingDisplay = "Some";
                                } else if (classifierCode === "SEVCO:00192") {
                                    ratingDisplay = "Unclear";
                                }
                                ratingDisplay += " risk";
                                break;
                            } else if (["SEVCO:00194", "SEVCO:00195", "SEVCO:00196", "SEVCO:00197", "SEVCO:00199", "SEVCO:00198"].includes(classifierCode)) {
                                noData = false;
                                if (classifierCode === "SEVCO:00194") {
                                    ratingDisplay = "Present";
                                } else if (classifierCode === "SEVCO:00195") {
                                    ratingDisplay = "Likely present";
                                } else if (classifierCode === "SEVCO:00196") {
                                    ratingDisplay = "Likely absent";
                                } else if (classifierCode === "SEVCO:00197") {
                                    ratingDisplay = "Absent";
                                } else if (classifierCode === "SEVCO:00199") {
                                    ratingDisplay = "Unclear";
                                } else if (classifierCode === "SEVCO:00198") {
                                    ratingDisplay = "No information";
                                }
                            } else if (["SEVCO:00201", "SEVCO:00202", "SEVCO:00203", "SEVCO:00204", "SEVCO:00205"].includes(classifierCode)) {
                                noData = false;
                                if (classifierCode === "SEVCO:00201") {
                                    ratingDisplay = "Favor experimental";
                                } else if (classifierCode === "SEVCO:00202") {
                                    ratingDisplay = "Favor comparator";
                                } else if (classifierCode === "SEVCO:00203") {
                                    ratingDisplay = "Towards null";
                                } else if (classifierCode === "SEVCO:00204") {
                                    ratingDisplay = "Away from null";
                                } else if (classifierCode === "SEVCO:00205") {
                                    ratingDisplay = "Unpredictable";
                                }
                            } else if (["SEVCO:00207", "SEVCO:00208", "SEVCO:00209", "SEVCO:00210"].includes(classifierCode)) {
                                noData = false;
                            }
                        }
                    }
                    if (concept.summary) {
                        noData = false;
                    }
                    if (noData) {
                        for (let subComponentIndex in concept.component) {
                            let subComponent = concept.component[subComponentIndex];
                            if (!subComponent.type) {
                                if (subComponent.summary) {
                                    noData = false;
                                }
                            }
                        }
                    }

                    let linkStyle = {};
                    let linkOnClick;
                    if (fhirEntryState.conceptCode === concept.code) { // || (fhirEntryState.termChanged && fhirEntryState.conceptName === concept.display)
                        linkStyle = { cursor: "default", color: "#000000", fontWeight: "bold" };
                    } else {
                        linkOnClick = () => {
                            navigateToAnotherRobatTerm("Term", history, fhirEntryState, setFhirEntryState, "ArtifactAssessment", resourceId, robatJsonState, concept, display, path, pathIndexes, pathString, globalContext, changeFormState, formInputsStateRef, changeFhirEntryState, addToast);
                        }
                    }
                    return <span key={conceptIndex} style={style} id={"termdetail-navigation-code-" + concept.code}>
                        {bulletPoints[level % bulletPoints.length]}
                        &nbsp;<a style={linkStyle} onClick={linkOnClick}>{display}</a>
                        {ratingDisplay ?
                            <span style={{ fontSize: "11px" }}> ({ratingDisplay})</span>
                            :
                            <span style={{ fontSize: "11px" }}>
                                <i> {noData ? <></> : <></>}</i>
                            </span>
                        }
                        {(navigationCollapsedState[pathString] && navigationCollapsedState[pathString]["expanded"] !== undefined) &&
                            <span className={"unselectable"} style={{ cursor: "pointer" }} onClick={() => { setNavigationCollapsedState(prevState => { let prevObj = prevState[pathString]; prevObj["expanded"] = !prevObj["expanded"]; return { ...prevState, [pathString]: prevObj }; }) }}>&nbsp;{navigationCollapsedState[pathString]["expanded"] ? <>▼</> : <>►</>}</span>
                        }
                        <br />
                        {(navigationCollapsedState[pathString] && navigationCollapsedState[pathString]["expanded"] !== false) &&
                            <NavigationConcepts parentJson={concept} level={level + 1} parentPathString={pathString} setFhirEntryState={setFhirEntryState} parentPath={path} parentPathIndexes={pathIndexes} formInputsStateRef={formInputsStateRef} changeFhirEntryState={changeFhirEntryState} />
                        }
                    </span>
                } else {
                    return <span key={conceptIndex}></span>
                }
            })}
        </>
    }


    return <Segment id={`navigationTermDetailSegment`} className={`containerSegment maxNavigationRobatSegment`} style={{ marginTop: "8px", padding: "2px 4px 6px 4px" }} raised>
        <h2 className={"unselectable"} style={{ fontSize: "22px", marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>Type of Bias</h2>
        <div className={`navigationRobatTermDetailSection`} style={{ paddingTop: "4px" }}>
            <NavigationConcepts parentJson={robatJsonState} level={0} parentPathString={""} parentPath={[]} parentPathIndexes={[]} setFhirEntryState={setFhirEntryState} formInputsStateRef={formInputsStateRef} changeFhirEntryState={changeFhirEntryState} />
        </div>
    </Segment>
}
export default NavigationRobatTermSegment;