import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment } from 'semantic-ui-react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { exileUser } from "./UserAccountFunctions";
import FevirContext from './FevirContext';

const UserRemovalPage = ({ useTitle, setSignInStatusState }) => {
  const globalContext = useContext(FevirContext);
  useTitle("FEvIR Remove User");

  const [removalTermsState, setRemovalTermsState] = useState({ radiovalue: "" });

  const editRemovalTermsState = (newValue, field) => {
    setRemovalTermsState(prevState => {
      return {
        ...prevState,
        //agreement: newValue,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const CancelToHomeButton = () => {
    const history = useHistory();
    return (
      // history.goBack();
      <Button className="formButton negative" content="Cancel" onClick={() => { history.push("/editprofile"); }} compact />
    );
  }

  return (
    <div>
      <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
        <h2 style={{ textAlign: "center", color: "#AC3B61" }}>User Removal</h2>
        {/* <Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } /> */}
        <br /><br />
        <p style={{ maxWidth: "800px" }}><b>You have not been removed yet.</b> You can be removed temporarily such that your data will be stored securely and not available for any use, but if you return to the FEvIR Platform you can continue to grow your same profile. Or you can be removed completely and permanently and there will be no way to re-use any of your data.</p>
        <RadioGroup aria-label="remove yourself from fevir" name="removeyourselffromfevir" value={removalTermsState.radiovalue} onChange={(e) => { editRemovalTermsState(e.target.value, "radiovalue"); }}>
          <FormControlLabel value="temporary" control={<Radio color="primary" />} label={<>I would like to be removed <b>Temporarily</b> from FEvIR</>} />
          <FormControlLabel value="permanent" control={<Radio color="primary" />} label={<>I would like to be removed <b>Permanently</b> from FEvIR</>} />
        </RadioGroup>
        <br /><br />
        <Button className="formButton positive" content="Remove myself from FEvIR" compact onClick={() => { exileUser(globalContext, true, removalTermsState.radiovalue === "permanent"); history.push(""); }} disabled={removalTermsState.radiovalue === ""} />
        <span style={{ marginLeft: "10px" }}></span>
        <CancelToHomeButton />
      </Segment>
    </div>
  );
};

export default UserRemovalPage;