import React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import "firebase/compat/auth";
import './App.css';

const PersonalDataAgreementPage = ( { useTitle } ) => {

  useTitle("FEvIR Personal Data Agreement");
  
  return (
    <div>
    <Segment className={`containerSegment remainderOfPageSegment`} raised>
      <h2 style={{textAlign: "center", color: "#AC3B61"}}>Computable Publishing ONLINE PRIVACY POLICY AGREEMENT</h2>
      <Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { window.close(); } } />
      <br/>
      <p>Computable Publishing values its users' privacy. This Privacy Policy ("Policy") will help you understand how Computable Publishing LLC (“Computable Publishing”) collects and uses personal information from those who visit or make use of our Platform(s)/Website(s)/Digital Properties including but not limited to our online product(s) and service(s), and what Computable Publishing will and will not do with the information Computable Publishing collects. Our Policy has been designed and created to ensure those affiliated with Computable Publishing of our commitment and realization of our obligation not only to meet, but to exceed, most existing privacy standards.</p>
      <p>Computable Publishing reserves the right to make changes to this Policy at any given time. If you want to make sure that you are up to date with the latest changes, Computable Publishing advises you to frequently visit this page. If at any point in time Computable Publishing decides to make use of any personally identifiable information on file, in a manner materially different from that which was stated when this information was initially collected, the user or users shall be promptly notified by email or other available digital means. Users at that time shall have the option as to whether to permit the use of their information in this separate manner.</p>
      <p>This Policy applies to Computable Publishing, and it governs any and all data collection and usage by Computable Publishing and any of its digital properties for example, the FEvIR.net family of products and websites. Through the use of any of these digital properties, you are therefore consenting to the data collection procedures expressed in this Policy.</p>
      <p>Please note that this Policy does not govern the collection and use of information by companies that Computable Publishing does not control, nor by individuals not employed or managed by us. If you visit a website that Computable Publishing mentions or links to, be sure to review its privacy policy before providing the site with information. It is highly recommended and suggested that you review the privacy policies and statements of any website you choose to use or frequent to better understand the way in which websites garner, make use of and share the information collected.</p>
      <p>Specifically, this Policy will inform you of the following:
        <ol>
          <li>What personally identifiable information is collected from you through Computable Publishing’s Platform(s), Website(s), and any other Digital Service(s);</li>
          <li>Why Computable Publishing collects personally identifiable information and the legal basis for such collection,</li>
          <li>How Computable Publishing uses the collected information and with whom it may be shared,</li>
          <li>What choices are available to you regarding the use of your data; and</li>
          <li>The security procedures in place to protect the misuse of your information.</li>
        </ol>
      </p>
      <h4>Information Computable Publishing Collects</h4>
      <p>It is always up to you whether to disclose personally identifiable information to us, although if you elect not to do so, Computable Publishing reserves the right not to register you as a user or provide you with any its products or services.</p>
      <p>In addition, Computable Publishing may have the occasion to collect non-personal anonymous demographic information, such as age, declared gender, occupation and race as well as the type of browser you are using, IP address, or type of operating system, which will assist us in providing and maintaining superior quality service.</p>
      <h4>Why Computable Publishing Collects Information and For How Long</h4>
      <p>Computable Publishing is collecting your data for several reasons:
        <ul>
          <li>To better understand your needs and provide you with the services you have requested,</li>
          <li>To fulfill our legitimate interest in improving our platform services and products;</li>
          <li>To send you communications containing information that may be of interest to you when Computable Publishing has your consent to do so,</li>
          <li>To contact you to fill out surveys or participate in other types of research, when Computable Publishing has your consent to do so;</li>
          <li>To tune Computable Publishing products, services including but not limited to website(s) and/or other digital properties according to your online behavior and personal preferences.</li>
        </ul>
      </p>
      <p>The data Computable Publishing collects from you will be stored for no longer than necessary, for the purposes of measuring scientific impact. The length of time Computable Publishing retains said information will be determined based upon the following criteria: the length of time your personal information remains relevant; the length of time it is reasonable to keep records to demonstrate that Computable Publishing has fulfilled our duties and obligations; any limitation periods within which claims might be made; any retention periods prescribed by law or recommended by regulators, professional bodies or associations; the type of contract/agreement Computable Publishing has with you, the existence of your consent, and our legitimate interest in keeping such information as stated in this Policy.</p>
      <h4>Use of Information Collected</h4>
      <p>Computable Publishing may collect and may make use of personal information to assist in the operation of any of our Platform(s)/Website(s) and to ensure delivery of the services you need and request. At times, Computable Publishing may find it necessary to use personally identifiable information as a means to keep you informed of other possible Platforms, Products and/or Services that may be available to you from Computable Publishing or its Partners. Computable Publishing may also be in contact with you with regards to completing surveys and/or research questionnaires related to your opinion of current or potential future services that may be offered.</p>
      <h4>Disclosure of Information</h4>
      <p>Computable Publishing may not use or disclose the information provided by you except under the following circumstances:
        <ul>
        <li>as necessary to provide services or products you have ordered,</li>
        <li>in other ways described in this Policy or to which you have otherwise consented,</li>
        <li>in the aggregate with other information in such a way so that your identity cannot reasonably be determined,</li>
        <li>as required by law, or in response to a subpoena or search warrant,</li>
        <li>to outside auditors who have agreed to keep the information confidential,</li>
        <li>as necessary to enforce the Terms of Service,</li>
        <li>as necessary to maintain, safeguard and preserve all the rights and property of Computable Publishing,</li>
      </ul>
      </p>
      <h4>Non-Marketing Purposes</h4>
      <p>Computable Publishing greatly respects your privacy. Computable Publishing does maintain and reserve the right to contact you if needed for non-marketing purposes (such as bug alerts, security breaches, account issues, and/or changes in Computable Publishing products and services). In certain circumstances, Computable Publishing may use our website or other digital properties, media including social media, or other public means to post a notice.</p>
      <h4>Children under the age of 13</h4>
      <p>Computable Publishing's website is not directed to, and does not knowingly collect personal identifiable information from, children under the age of thirteen (13). If it is determined that such information has been inadvertently collected on anyone under the age of thirteen (13), Computable Publishing shall immediately take the necessary steps to ensure that such information is deleted from our system's database, or in the alternative, that verifiable parental consent is obtained for the use and storage of such information. Anyone under the age of thirteen (13) must seek and obtain parent or guardian permission to use this website.</p>
      <h4>Links to Other Websites/Digital Properties</h4>
      <p>Our website may contain links and/or references to affiliate and other Websites/Digital Properties/Services. Computable Publishing does not claim nor accept responsibility for any privacy policies, practices and/or procedures of other such Websites/Digital Properties/services. Therefore, Computable Publishing encourage all users and visitors to be aware when they leave our Websites/Digital Properties/Service and to read the privacy statements of every Website/Digital Property/Service that collects personally identifiable information. This Privacy Policy Agreement applies only and solely to the information collected by our Website(s)/Digital Properties/Services.</p>
      <h4>Notice to European Union Users</h4>
      <p>Computable Publishing's operations are located primarily in the United States. If you provide information to us, the information will be transferred out of the European Union (EU) and sent to the United States. (The adequacy decision on the EU-US Privacy became operational on August 1, 2016. This framework protects the fundamental rights of anyone in the EU whose personal data is transferred to the United States for commercial purposes. It allows the free transfer of data to companies that are certified in the US under the Privacy Shield.) By providing personal information to us, you are consenting to its storage and use as described in this Policy.</p>
      <h4>Security</h4>
      <p>Computable Publishing takes precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline. Wherever Computable Publishing collects sensitive information (e.g. Personally Identifiable Information), that information is encrypted and transmitted to Computable Publishing in a secure way. You can verify this by looking for a lock icon in the address bar and looking for "https" at the beginning of the address of the webpage.</p>
      <p>While Computable Publishing uses encryption to protect sensitive information transmitted online, Computable Publishing also protects your information offline. Only employees who need the information to perform a specific job (for example, customer service) are granted access to personally identifiable information. The computers and servers in which Computable Publishing store personally identifiable information are kept in a secure cloud environment. This is all done to prevent any loss, misuse, unauthorized access, disclosure or modification of the user's personal information under our control.</p>
      <h4>Acceptance of Terms</h4>
      <p>By using this or any Computable Publishing Website/Digital Property/Service, you are hereby accepting the terms and conditions stipulated within the Privacy Policy Agreement. If you are not in agreement with our terms and conditions, then you should refrain from further use of our sites. In addition, your continued use of our website following the posting of any updates or changes to our terms and conditions shall mean that you agree and acceptance of such changes.</p>
      <h4>How to Contact Us</h4>
      <p>If you have any questions or concerns regarding the Privacy Policy Agreement related to our website, please feel free to contact us at the following email or mailing address. 
      <p style={{paddingLeft: "20px"}}>
        <b>Email:</b> <a href="mailto:support@computablepublishing.com">support@computablepublishing.com</a>
        <br/><b>Physical Mailing Address:</b>
          <p style={{paddingLeft: "20px"}}>
          Computable Publishing LLC
          <br/>41 Labor In Vain Road
          <br/>Ipswich
          <br/>Massachusetts, 01938
          <br/>USA
        </p>
        </p>
      </p>
      
      <h5>Last modified: March 31, 2021</h5>
      </Segment>
    </div>
  );
};

  export default PersonalDataAgreementPage;