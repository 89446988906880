import EnhancedCharacteristicsTable from './EnhancedCharacteristicsTable';
import { useContext, useEffect, useState } from "react";
import submitToFevirServer from './SubmitToFevirServer';
import FevirContext from './FevirContext';

const InclusionExclusionEnhancedCharacteristicTables = ({ editMode, smallCriteriaHeader, groupInclusionCharacteristics, groupExclusionCharacteristics, setGroupCharacteristicsState }) => {
    return <>
        <EnhancedCharacteristicsTable criteriaType={"Inclusion"} editMode={editMode} smallCriteriaHeader={smallCriteriaHeader} groupCriteriaCharacteristics={groupInclusionCharacteristics} setGroupCharacteristicsState={setGroupCharacteristicsState} />
        <br />
        <EnhancedCharacteristicsTable criteriaType={"Exclusion"} editMode={editMode} smallCriteriaHeader={smallCriteriaHeader} groupCriteriaCharacteristics={groupExclusionCharacteristics} setGroupCharacteristicsState={setGroupCharacteristicsState} />
    </>
}

const DisplayGroupCharacteristics = ({ reference }) => {
    const globalContext = useContext(FevirContext);
    const [loadedState, setLoadedState] = useState({"success": null});
    useEffect((async () => {
        let foi = reference.split("/")[1];
        if (!isNaN(foi)) {
            setLoadedState({"success": false});
        }
        const body = {
            'functionid': 'getfhirresource',
            'resourceid': foi,
            'resourcetype': "Group",
            'idToken': ''
        };
        let response = await submitToFevirServer(globalContext, 10000, body, true, false);
        if (response?.success && response.fhirjsonstring) {
            const fhirJson = JSON.parse(response.fhirjsonstring);
            let groupInclusionCharacteristics = [];
            let groupExclusionCharacteristics = [];
            if (fhirJson.characteristic?.length > 0) {
                for (const characteristic of fhirJson.characteristic) {
                    if (characteristic?.description || characteristic?.code) {
                        if (characteristic.exclude) {
                            groupExclusionCharacteristics.push(characteristic);
                        } else {
                            groupInclusionCharacteristics.push(characteristic);
                        }
                    }
                }
            }
            setLoadedState({"success": true, 
            "groupInclusionCharacteristics": groupInclusionCharacteristics,
            "groupExclusionCharacteristics": groupExclusionCharacteristics,
            "combinationMethod": fhirJson.combinationMethod,
            "combinationThreshold": fhirJson.combinationThreshold
        });
        } else {
            setLoadedState({"success": false});
        }
    }), []);
    if (loadedState.success === false) {
        return <><p>The referenced Resource ({reference}) does not resolve to a FEvIR Resource.</p></>
    }
    if (loadedState.success === true) {
        return <>
            {loadedState.combinationMethod && <>
                <h4>Characteristic Combination</h4>
                <div style={{ marginLeft: "24px" }}>
                    <span><b>Combination Method: </b>{loadedState.combinationMethod}</span>
                    {loadedState.combinationThreshold && 
                    <span>&nbsp;&nbsp;&nbsp;<b>Number of characteristics: </b>{loadedState.combinationThreshold}</span>}
                </div>
            </>}
            <InclusionExclusionEnhancedCharacteristicTables editMode={false}
                smallCriteriaHeader={false} groupInclusionCharacteristics={loadedState.groupInclusionCharacteristics}
                groupExclusionCharacteristics={loadedState.groupExclusionCharacteristics} />
        </>
    } else {
        return <><p>Waiting for the referenced Resource ({reference})  to load.</p></>
    }
}

export default DisplayGroupCharacteristics;