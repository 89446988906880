import React, { useState, useEffect, useImperativeHandle, memo } from 'react';
import { DisplayFromFHIR, SimpleResourceFieldViewer, getStringFromFHIR } from './ResourceFunctions';
import { DataEntry, DatatypeSelector, MetadataPatternEdit, DisplayHowToCite } from './DataEntryFormFunctions';
import { DisplayClassifiers } from './MetadataPatternDisplay';
import { sectionCodeCompetingInterests, sectionCodeConsiderations, sectionCodeEvidence, sectionCodeJudgments, sectionCodeNetEffect, sectionCodeRecommendationSpecification } from './CodeSystemLookup';

const handleChange = (name, value, setResourceState) => {
  if (name.at(-1) === "]") {
    let nameSplit = name.split("[");
    setResourceState(prevState => {
      let newValue = prevState[nameSplit[0]].map((entry, entryIndex) => {
        if (entryIndex === parseInt(nameSplit[1])) {
          return value;
        } else {
          return entry;
        }
      })
      return { ...prevState, [nameSplit[0]]: newValue }
    });
  } else {
    setResourceState(prevState => { return { ...prevState, [name]: value } })
  }
}

const ExpandToAddOrEdit = ({ startingValue, setStartCollapsedState }) => {
  return <span className={"unselectable"} style={{ cursor: "pointer" }}
    onClick={() => { setStartCollapsedState(false) }}>
    {startingValue ? <>✎ Edit</> : <>➕ Add</>}
  </span>
}

const generateRecommendationJustificationNarrative = (resource) => {
  let status = "generated";
  let innerDiv = "";
  try {
    if (resource.title) {
      innerDiv += "<p><b>Title: </b>" + resource.title + "</p><br/>";
    }
    innerDiv += "<p><b>Justification for Recommendation: </b>";
    if (resource.artifactReference) {
      innerDiv += getStringFromFHIR.Reference(resource.artifactReference) + "</p><br/>";
    } else if (resource.artifactCanonical) {
      innerDiv += resource.artifactCanonical + "</p><br/>";
    } else if (resource.artifactUri) {
      innerDiv += resource.artifactUri + "</p><br/>";
    } else {
      innerDiv += "[Reference to recommendation missing.]</p><br/>";
    }
    if (resource.content) {
      innerDiv += "<table><tr><th>Concept</th><th>Summary</th><th>Rating</th></tr>";
      for (const content of resource.content) {
        let type = getStringFromFHIR.CodeableConcept(content.type);
        let summary = content.summary;
        let rating = content.classifier?.map(classifier => getStringFromFHIR.CodeableConcept(classifier)).join("; ");
        if (summary || rating) {
          innerDiv += "<tr><td>" + type + "</td><td>" + summary + "</td><td>" + rating + "</td></tr>";
        }
        if (content.component) {
          for (const component of content.component) {
            let componenttype = getStringFromFHIR.CodeableConcept(component.type);
            let componentsummary = component.summary;
            let componentrating = component.classifier?.map(classifier => getStringFromFHIR.CodeableConcept(classifier)).join("; ");
            if (componentsummary || componentrating) {
              innerDiv += "<tr><td>" + componenttype + "</td><td>" + componentsummary + "</td><td>" + componentrating + "</td></tr>";
            }
          }
        }
      }
      innerDiv += "</table><br/>";
    }
    if (resource.certainty?.length > 0) { //this will not apply and this code can be removed
      innerDiv += resource.certainty.map((item) => {
        let certaintyType = getStringFromFHIR.CodeableConcept(item.type) || "untyped";
        let certaintyRating = getStringFromFHIR.CodeableConcept(item.rating) || "unrated";
        return "<p><b>Certainty of type " + certaintyType + ": </b>" + certaintyRating + "</p><br/>"
      }).join("");
    }
  } catch {
    innerDiv = "[Unable to generate Narrative Summary.]"
  }
  return { "status": status, "div": '<div xmlns=\"http://www.w3.org/1999/xhtml\">' + innerDiv + "</div>" };
}

const recommendationJustificationComponentDotAuthorResourceTypes = ['Patient', 'Practitioner', 'PractitionerRole', 'Organization', 'Device'];
const RecommendationJustificationComponentEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, valueSet, dataEntryStyle, debug, typeValueSet, typeSpecificValueSets,
  typeSpecificInterface, startCollapsed }) => {
  let startingRecommendationJustificationComponent = {
    "summary": "", "type": "",
    "classifier": [], "quantity": "", "author": [], "path": [], "relatedArtifact": [], "freeToShare": true,
    "component": []
  };
  let startingTypeSpecificValueSet;
  let startingTypeSpecificInterface = typeSpecificInterface;

  if (!startingValue) {
    startingRecommendationJustificationComponent = "";
  } else {
    if (startingValue.extension) { startingRecommendationJustificationComponent.extension = startingValue.extension; }
    if (startingValue.summary) { startingRecommendationJustificationComponent.summary = startingValue.summary; }
    if (startingValue.type) {
      startingRecommendationJustificationComponent.type = startingValue.type;
      if (typeSpecificValueSets) {
        try {
          let typeKey = startingValue.type.coding[0].code;
          startingTypeSpecificValueSet = typeSpecificValueSets[typeKey]
        } catch { }
      }
      if (!typeSpecificInterface) {
        try {
          startingTypeSpecificInterface = startingValue.type.coding[0].code;
        } catch { }
      }
    }
    if (startingValue.classifier) { startingRecommendationJustificationComponent.classifier = startingValue.classifier; }
    if (startingValue.quantity) { startingRecommendationJustificationComponent.quantity = startingValue.quantity; }
    if (startingValue.author) { startingRecommendationJustificationComponent.author = startingValue.author; }
    if (startingValue.path) { startingRecommendationJustificationComponent.path = startingValue.path; }
    if (startingValue.relatedArtifact) { startingRecommendationJustificationComponent.relatedArtifact = startingValue.relatedArtifact; }
    if (typeof startingValue.freeToShare === "boolean") { startingRecommendationJustificationComponent.freeToShare = startingValue.freeToShare; }
    if (startingValue.component) { startingRecommendationJustificationComponent.component = startingValue.component; }
  }
  const [recJustComponentState, setRecJustComponentState] = useState(JSON.parse(JSON.stringify(startingRecommendationJustificationComponent || {})));
  const [typeSpecificValueSetState, setTypeSpecificValueSetState] = useState(startingTypeSpecificValueSet);
  const [typeSpecificInterfaceState, setTypeSpecificInterfaceState] = useState(startingTypeSpecificInterface);
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (Object.keys(recJustComponentState).length) {
      let newRating = {};
      if (recJustComponentState.extension) { newRating.extension = recJustComponentState.extension; }
      if (recJustComponentState.summary) { newRating.summary = recJustComponentState.summary; }
      if (recJustComponentState.type && Object.keys(recJustComponentState.type).length) {
        newRating.type = recJustComponentState.type;
        if (typeSpecificValueSets) {
          try {
            let typeKey = recJustComponentState.type.coding[0].code;
            setTypeSpecificValueSetState(typeSpecificValueSets[typeKey]);
          } catch { }
        }
        if (!typeSpecificInterface) {
          try {
            setTypeSpecificInterfaceState(recJustComponentState.type.coding[0].code);
          } catch { }
        }
      }
      if (Array.isArray(recJustComponentState.classifier) && recJustComponentState.classifier.length) { 
        newRating.classifier = recJustComponentState.classifier; 
      }
      if (recJustComponentState.quantity && Object.keys(recJustComponentState.quantity).length) { 
        newRating.quantity = recJustComponentState.quantity; 
      }
      if (recJustComponentState.author) {
        if (Array.isArray(recJustComponentState.author) && recJustComponentState.author.length) { 
          newRating.author = recJustComponentState.author; 
        } else if (Object.keys(recJustComponentState.author).length) { 
            newRating.author = [recJustComponentState.author]; 
          }
      }
      if (Array.isArray(recJustComponentState.path) && recJustComponentState.path.length) { newRating.path = recJustComponentState.path; }
      if (Array.isArray(recJustComponentState.relatedArtifact) && recJustComponentState.relatedArtifact.length) { newRating.relatedArtifact = recJustComponentState.relatedArtifact; }
      if (typeof recJustComponentState.freeToShare === "boolean") { newRating.freeToShare = recJustComponentState.freeToShare; }
      if (Array.isArray(recJustComponentState.component) && recJustComponentState.component.length) { newRating.component = recJustComponentState.component; }
      if (Object.keys(newRating).length === 0) {
        newRating = null;
      }
      handleChange(elementName, newRating, setResourceState);
    }
  }), [recJustComponentState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR rating={startingRecommendationJustificationComponent} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    if (typeSpecificInterfaceState === 'cost') {
      return <>
        <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='markdown' elementName='summary' fieldLabel='Summary'
            startingValue={recJustComponentState.summary || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} debug={debug} />
          <br />
          <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type of Rating'
            startingValue={recJustComponentState.type || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} valueSet={typeValueSet} debug={debug} />
          <br />
          <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Cost'
            startingValue={recJustComponentState.quantity || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} debug={debug} />
          <br /><br />
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel='Rater' startCollapsed={true}
            startingValue={recJustComponentState.author || null} setResourceState={setRecJustComponentState}
            referencedResourceTypes={recommendationJustificationComponentDotAuthorResourceTypes} />
          <DataEntry datatype='boolean' elementName="freeToShare" fieldLabel='Free to share?'
            startingValue={recJustComponentState.freeToShare ?? true} setResourceState={setRecJustComponentState} />
        </div>
      </>
    } else {
      return <>
        <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
        <div style={{ marginLeft: "24px" }}>
          <DataEntry datatype='markdown' elementName='summary' fieldLabel='Summary'
            startingValue={recJustComponentState.summary || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} debug={debug} />
          <br />
          <DataEntry datatype='CodeableConcept' elementName='type' fieldLabel='Type of Rating'
            startingValue={recJustComponentState.type || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} valueSet={typeValueSet} debug={debug} />
          <br />
          <DataEntry asArray={true} datatype="CodeableConcept" elementName='classifier' fieldLabel='Classifier Value'
            startingValue={recJustComponentState.classifier || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} valueSet={typeSpecificValueSetState || valueSet} debug={debug} />
          <br />
          <DataEntry datatype='Quantity' elementName='quantity' fieldLabel='Quantity'
            startingValue={recJustComponentState.quantity || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} debug={debug} startCollapsed={true} />
          <br />
          <DataEntry asArray={true} datatype="RelatedArtifact" elementName='relatedArtifact' fieldLabel='Related Item'
            startingValue={recJustComponentState.relatedArtifact || null} setResourceState={setRecJustComponentState}
            allowedTypeValues="FHIR" doNotShowFieldLabel={true} debug={debug} startCollapsed={true}
            startEmptyArrayClosed={true} />
          <br />
          <br />
          <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel='Rater' startCollapsed={true}
            startingValue={recJustComponentState.author || null} setResourceState={setRecJustComponentState}
            referencedResourceTypes={recommendationJustificationComponentDotAuthorResourceTypes} />
          <DataEntry datatype='boolean' elementName="freeToShare" fieldLabel='Free to share?'
            startingValue={recJustComponentState.freeToShare ?? true} setResourceState={setRecJustComponentState} />
          <br />
          <br />
          {/*------Add Component button was leading to non-ending recursive rendering--------
          <DataEntry asArray={true} datatype="RecommendationJustificationComponent" elementName='component'
            fieldLabel='Component'
            typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets}
            startingValue={recJustComponentState.component || null} setResourceState={setRecJustComponentState}
            dataEntryStyle={dataEntryStyle} debug={debug} />
          <br />
    */}
        </div>
      </>
    }
  }
})

const RecommendationJustificationContentEntry = memo(({ elementName, fieldLabel, startingValue,
  setResourceState, dataEntryStyle, debug, startCollapsed }) => {
  let typeValueSet = [
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "strength-of-recommendation", display: "Strength of Recommendation" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "summary-of-findings", display: "Summary of Findings" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "competing-interests", display: "Competing Interests" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "problem-importance", display: "Problem Importance" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "desirable-effects", display: "Desirable Effects" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "undesirable-effects", display: "Undesirable Effects" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "preferences", display: "Preferences" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "net-effect", display: "Net Effect" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "costs", display: "Costs" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "cost-effectiveness", display: "Cost-effectiveness" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "equity", display: "Equity" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "acceptability", display: "Acceptability" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "feasibility", display: "Feasibility" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "justification", display: "Justification" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "direction-of-recommendation", display: "Direction of Recommendation" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "population", display: "Population" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "action", display: "Action" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "opposite-action", display: "Opposite Action" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "subgroup-considerations", display: "Subgroup Considerations" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "implementation-considerations", display: "Implementation Considerations" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "monitoring-considerations", display: "Monitoring Considerations" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "research-considerations", display: "Research Considerations" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "rating-system", display: "Rating System" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "ratings", display: "Ratings" },
    { system: "https://fevir.net/resources/CodeSystem/179423", code: "discussion", display: "Discussion" }
  ];

  let typeSpecificValueSets = {
    "strength-of-recommendation": [ //Strength of Recommendation
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2001", display: "Strong expectation" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2002", display: "Weak expectation" }
    ],
    "summary-of-findings": [ //was Certainty of Evidence
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" }
    ],
    "competing-interests": [ //Competing Interests
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2007", display: "Yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2008", display: "Probably yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2009", display: "Probably no" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2010", display: "No" }
    ],
    "problem-importance": [ //Problem Importance
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2007", display: "Yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2008", display: "Probably yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2009", display: "Probably no" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2010", display: "No" }
    ],
    "desirable-effects": [ //Desirable Effects
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2013", display: "Large size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2014", display: "Moderate size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2015", display: "Small size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2016", display: "Trivial size" }
    ],
    "undesirable-effects": [ //Undesirable Effects
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2013", display: "Large size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2014", display: "Moderate size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2015", display: "Small size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2016", display: "Trivial size" }
    ],
    "preferences": [ //was Values/Preferences
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2017", display: "Important uncertainty or variability" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2018", display: "Possibly important uncertainty or variability" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2019", display: "Possibly no important uncertainty or variability" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2020", display: "No important uncertainty or variability" }
    ],
    "net-effect": [ //Net Effect
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2021", display: "Favors intervention" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2022", display: "Possibly favors intervention" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2023", display: "Does not favor intervention or comparison" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2024", display: "Possibly favors comparison" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2025", display: "Favors comparison" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2013", display: "Large size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2014", display: "Moderate size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2015", display: "Small size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2016", display: "Trivial size" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2011", display: "Varies" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2012", display: "Unknown" }
    ],
    "costs": [ //Resources/Costs
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2026", display: "Large costs" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2027", display: "Moderate costs" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2028", display: "Negligible costs or savings" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2029", display: "Moderate savings" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2030", display: "Large savings" }
    ],
    "cost-effectiveness": [ //Cost-effectiveness
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2026", display: "Large costs" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2027", display: "Moderate costs" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2028", display: "Negligible costs or savings" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2029", display: "Moderate savings" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2030", display: "Large savings" }
    ],
    "equity": [ //Equity
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2007", display: "Yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2008", display: "Probably yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2009", display: "Probably no" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2010", display: "No" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" }
    ],
    "acceptability": [ //Acceptability
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2007", display: "Yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2008", display: "Probably yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2009", display: "Probably no" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2010", display: "No" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" }
    ],
    "feasibility": [ //Feasibility
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2007", display: "Yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2008", display: "Probably yes" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2009", display: "Probably no" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2010", display: "No" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2003", display: "High certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2004", display: "Moderate certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2005", display: "Low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006", display: "Very low certainty" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2006.0", display: "No evidence" }
    ],
    "direction-of-recommendation": [ //Direction of Recommendation
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2021", display: "Favors intervention" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2023", display: "Does not favor intervention or comparison" },
      { system: "https://fevir.net/resources/CodeSystem/27834", code: "RJCS-2025", display: "Favors comparison" }
    ]
  };
  let contentType = sectionCodeConsiderations;
  if (dataEntryStyle === "Recommendation Specification") {
    contentType = sectionCodeRecommendationSpecification;
    typeValueSet = [
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "strength-of-recommendation", display: "Strength of Recommendation" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "direction-of-recommendation", display: "Direction of Recommendation" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "rating-system", display: "Rating System" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "ratings", display: "Ratings" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "discussion", display: "Discussion" }
    ];
  } else if (dataEntryStyle === "Evidence") {
    contentType = sectionCodeEvidence;
    typeValueSet = [
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "summary-of-findings", display: "Summary of Findings" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "desirable-effects", display: "Desirable Effects" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "undesirable-effects", display: "Undesirable Effects" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "discussion", display: "Discussion" }
    ];
  } else if (dataEntryStyle === "Net Effect") {
    contentType = sectionCodeNetEffect;
    typeValueSet = [
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "preferences", display: "Preferences" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "discussion", display: "Discussion" }
    ];
  } else if (dataEntryStyle === "Judgments") {
    contentType = sectionCodeJudgments;
    typeValueSet = [
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "problem-importance", display: "Problem Importance" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "costs", display: "Costs" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "cost-effectiveness", display: "Cost-effectiveness" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "equity", display: "Equity" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "acceptability", display: "Acceptability" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "feasibility", display: "Feasibility" },
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "justification", display: "Justification" }
    ];
  } else if (dataEntryStyle === "Competing Interests") {
    contentType = sectionCodeCompetingInterests;
    typeValueSet = [
      { system: "https://fevir.net/resources/CodeSystem/179423", code: "competing-interests", display: "Competing Interests" },
    ]
  }
  let startingRecommendationJustificationContent = {
    "summary": "", "type": contentType,
    "classifier": [], "quantity": "", "author": [], "path": [], "relatedArtifact": [], "freeToShare": true,
    "component": []
  };

  if (!startingValue) {
    startingRecommendationJustificationContent = "";
  } else {
    if (startingValue.extension) { startingRecommendationJustificationContent.extension = startingValue.extension; }
    if (startingValue.summary) { startingRecommendationJustificationContent.summary = startingValue.summary; }
    if (startingValue.type) { startingRecommendationJustificationContent.type = startingValue.type; }
    if (startingValue.classifier) { startingRecommendationJustificationContent.classifier = startingValue.classifier; }
    if (startingValue.quantity) { startingRecommendationJustificationContent.quantity = startingValue.quantity; }
    if (startingValue.author) { startingRecommendationJustificationContent.author = startingValue.author; }
    if (startingValue.path) { startingRecommendationJustificationContent.path = startingValue.path; }
    if (startingValue.relatedArtifact) { startingRecommendationJustificationContent.relatedArtifact = startingValue.relatedArtifact; }
    if (typeof startingValue.freeToShare === "boolean") { startingRecommendationJustificationContent.freeToShare = startingValue.freeToShare; }
    if (startingValue.component) { startingRecommendationJustificationContent.component = startingValue.component; }
  }
  const [recommendationJustContentState, setRecommendationJustContentState] = useState(JSON.parse(JSON.stringify(startingRecommendationJustificationContent || {})));
  const [startCollapsedState, setStartCollapsedState] = useState(startCollapsed);

  useEffect((() => {
    if (recommendationJustContentState.type) {
      if (Object.keys(recommendationJustContentState).length > 1) {
        let newRating = {};
        if (recommendationJustContentState.extension) { newRating.extension = recommendationJustContentState.extension; }
        if (recommendationJustContentState.summary) { newRating.summary = recommendationJustContentState.summary; }
        if (recommendationJustContentState.type && Object.keys(recommendationJustContentState.type).length) {
          newRating.type = recommendationJustContentState.type;
        }
        if (Array.isArray(recommendationJustContentState.classifier) && recommendationJustContentState.classifier.length) { newRating.classifier = recommendationJustContentState.classifier; }
        if (recommendationJustContentState.quantity && Object.keys(recommendationJustContentState.quantity).length) { newRating.quantity = recommendationJustContentState.quantity; }
        if (recommendationJustContentState.author) {
          if (Array.isArray(recommendationJustContentState.author) && recommendationJustContentState.author.length) {
            newRating.author = recommendationJustContentState.author; 
          } else if (Object.keys(recommendationJustContentState.author).length) { 
            newRating.author = [recommendationJustContentState.author]; 
          }
        }
        if (Array.isArray(recommendationJustContentState.path) && recommendationJustContentState.path.length) { newRating.path = recommendationJustContentState.path; }
        if (Array.isArray(recommendationJustContentState.relatedArtifact) && recommendationJustContentState.relatedArtifact.length) { newRating.relatedArtifact = recommendationJustContentState.relatedArtifact; }
        if (typeof recommendationJustContentState.freeToShare === "boolean") { newRating.freeToShare = recommendationJustContentState.freeToShare; }
        if (Array.isArray(recommendationJustContentState.component) && recommendationJustContentState.component.length) { newRating.component = recommendationJustContentState.component; }
        if (Object.keys(newRating).length < 2) {
          newRating = null;
        }
        handleChange(elementName, newRating, setResourceState);
      }
    }
  }), [recommendationJustContentState]);

  if (startCollapsedState) {
    return <>
      <div>
        <b>{fieldLabel}: </b>
        {startingValue && <DisplayFromFHIR rating={startingRecommendationJustificationContent} />}
        &nbsp;&nbsp;
        <ExpandToAddOrEdit startingValue={startingValue} setStartCollapsedState={setStartCollapsedState} />
      </div>
    </>
  } else {
    return <>
      <p style={{ marginBottom: "0px" }}><b>{fieldLabel}: </b></p>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='markdown' elementName='summary' fieldLabel='Summary'
          startingValue={recommendationJustContentState.summary || null} setResourceState={setRecommendationJustContentState}
          dataEntryStyle={dataEntryStyle} debug={debug} />
        <br />
        <DataEntry asArray={true} datatype="RelatedArtifact" elementName='relatedArtifact' fieldLabel='Related Item'
          startingValue={recommendationJustContentState.relatedArtifact || null} setResourceState={setRecommendationJustContentState}
          allowedTypeValues="FHIR" doNotShowFieldLabel={true}
          debug={debug} startCollapsed={true} startEmptyArrayClosed={true} />
        <br />
        <br />
        <DataEntry asArray={true} datatype='Reference' elementName='author' fieldLabel='Rater' startCollapsed={true}
          startingValue={recommendationJustContentState.author || null} setResourceState={setRecommendationJustContentState}
          referencedResourceTypes={recommendationJustificationComponentDotAuthorResourceTypes} />
        <DataEntry datatype='boolean' elementName="freeToShare" fieldLabel='Free to share?'
          startingValue={recommendationJustContentState.freeToShare ?? true} setResourceState={setRecommendationJustContentState} />
        <br />
        <br />
        <DataEntry asArray={true} datatype="RecommendationJustificationComponent" elementName='component'
          fieldLabel={dataEntryStyle + " Component"}
          typeValueSet={typeValueSet} typeSpecificValueSets={typeSpecificValueSets}
          startingValue={recommendationJustContentState.component || null} setResourceState={setRecommendationJustContentState}
          dataEntryStyle={dataEntryStyle} debug={debug} />
        <br />
      </div>
    </>
  }
})

const RecommendationJustificationEdit = ({ fhirJson, formInputsStateRef, citationSummary, citationJson,
  classificationsArrayState, classificationsLoadedState }) => {

  let startingArtifactReferenceDatatype = 'none';
  if (fhirJson.artifactReference) { startingArtifactReferenceDatatype = 'Reference'; }
  if (fhirJson.artifactCanonical) { startingArtifactReferenceDatatype = 'canonical'; }
  if (fhirJson.artifactUri) { startingArtifactReferenceDatatype = 'uri'; }

  if (!fhirJson.content || !Array.isArray(fhirJson.content) || fhirJson.content.length === 0) {
    fhirJson.content = [
      {
        "type": sectionCodeRecommendationSpecification
      }
    ]
  }
  if (fhirJson.content.length === 1) {
    fhirJson.content.push({
      "type": sectionCodeEvidence
    })
  }
  if (fhirJson.content.length === 2) {
    fhirJson.content.push({
      "type": sectionCodeNetEffect
    })
  }
  if (fhirJson.content.length === 3) {
    fhirJson.content.push({      
      "type": sectionCodeJudgments
    })
  }
  if (fhirJson.content.length === 4) {
    fhirJson.content.push({
      "type": sectionCodeCompetingInterests
    })
  }

  const [resourceState, setResourceState] = useState({
    "resourceJson": fhirJson, "resourceType": "ArtifactAssessment", "id": fhirJson.id, "meta": fhirJson.meta, "implicitRules": fhirJson.implicitRules, "language": fhirJson.language, "text": fhirJson.text, "contained": fhirJson.contained, "extension": fhirJson.extension, "modifierExtension": fhirJson.modifierExtension,
    "url": fhirJson.url, "identifier": fhirJson.identifier, "version": fhirJson.version, "name": fhirJson.name, "title": fhirJson.title, "status": fhirJson.status, "experimental": fhirJson.experimental, "date": fhirJson.date, "publisher": fhirJson.publisher, "contact": fhirJson.contact, "description": fhirJson.description, "useContext": fhirJson.useContext, "jurisdiction": fhirJson.jurisdiction, "purpose": fhirJson.purpose, "usage": fhirJson.usage, "copyright": fhirJson.copyright, "copyrightLabel": fhirJson.copyrightLabel,
    "approvalDate": fhirJson.approvalDate, "lastReviewDate": fhirJson.lastReviewDate, "effectivePeriod": fhirJson.effectivePeriod, "topic": fhirJson.topic, "author": fhirJson.author, "editor": fhirJson.editor, "reviewer": fhirJson.reviewer, "endorser": fhirJson.endorser, "relatedArtifact": fhirJson.relatedArtifact,
    "artifactReference": fhirJson.artifactReference, "artifactCanonical": fhirJson.artifactCanonical, "artifactUri": fhirJson.artifactUri,
    "content": fhirJson.content, "workflowStatus": fhirJson.workflowStatus, "disposition": fhirJson.disposition,
    "citeAsReference": fhirJson.citeAsReference, "citeAsMarkdown": fhirJson.citeAsMarkdown
  });
  const [artifactReferenceEditState, setArtifactReferenceEditState] = useState(false);
  const [artifactReferenceDatatypeState, setArtifactReferenceDatatypeState] = useState(startingArtifactReferenceDatatype);

  useImperativeHandle(formInputsStateRef, () => ({
    recommendationJustificationState: resourceState
  }), [resourceState]);

  useEffect((() => {
    if (artifactReferenceDatatypeState === 'uri') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'Reference') {
      setResourceState(prevState => { return { ...prevState, 'artifactUri': null, 'artifactCanonical': null } })
    }
    if (artifactReferenceDatatypeState === 'canonical') {
      setResourceState(prevState => { return { ...prevState, 'artifactReference': null, 'artifactUri': null } })
    }
  }), [artifactReferenceDatatypeState]);

  return <div style={{ marginTop: "12px" }}>
    <h3 id="summary">Summary</h3>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='title' fieldLabel='Title'
        startingValue={resourceState.title} setResourceState={setResourceState} />
      <h4>Narrative Summary</h4>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype='Narrative' elementName='text' fieldLabel='Narrative Summary'
          generatedNarrative={generateRecommendationJustificationNarrative(resourceState)}
          startingValue={resourceState.text} setResourceState={setResourceState} />
      </div>
    </div>
    <h3 id="recommendation">Recommendation</h3>
    <div>
      <div><b>The Recommendation Being Explained: </b>
        <DisplayFromFHIR reference={resourceState.artifactReference} />
        <DisplayFromFHIR uri={resourceState.artifactUri} />
        <span className={"unselectable"} style={{ marginLeft: "24px", cursor: "pointer" }} onClick={() => { setArtifactReferenceEditState(!artifactReferenceEditState) }}>
          <i>Change the Recommendation Resource Being Explained</i>&nbsp;&nbsp;
          {artifactReferenceEditState ? <>▼</> : <>►</>}
        </span>
      </div>
      {artifactReferenceEditState &&
        <div style={{ whiteSpace: "pre-wrap", paddingLeft: "24px" }}>
          <DatatypeSelector elementXName='artifact[x]' allowedDatatypes={['Reference', 'canonical', 'uri']}
            datatypeState={artifactReferenceDatatypeState} setDatatypeState={setArtifactReferenceDatatypeState} />
          {(artifactReferenceDatatypeState === 'uri') &&
            <DataEntry datatype='uri' elementName='artifactUri'
              fieldLabel='The Resource Being Rated (by URL)' startingValue={resourceState.artifactUri || null}
              setResourceState={setResourceState} />}
          {(artifactReferenceDatatypeState === 'Reference') &&
            <DataEntry datatype='Reference' elementName='artifactReference' fieldLabel='The Resource Being Rated'
              startingValue={resourceState.artifactReference || null} setResourceState={setResourceState} />}
          {(artifactReferenceDatatypeState === 'canonical') &&
            <DataEntry datatype='uri' elementName='artifactCanonical' fieldLabel='The Resource Being Rated (by Canonical URI)'
              startingValue={resourceState.artifactCanonical || null} setResourceState={setResourceState} />}
          <br />
        </div>
      }
      <h3>Recommendation Specification</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype="RecommendationJustificationContent" elementName='content[0]' fieldLabel='Recommendation Specification'
          startingValue={resourceState.content[0] || null} setResourceState={setResourceState}
          dataEntryStyle='Recommendation Specification' />
        <br />
        <br />
      </div>
      <br />
    </div>
    <div>
      <h3 id="evidence">Evidence</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype="RecommendationJustificationContent" elementName='content[1]' fieldLabel='Evidence'
          startingValue={resourceState.content[1] || null} setResourceState={setResourceState}
          dataEntryStyle='Evidence' />
      </div>
      <br />
      <h3 id="net-effect">Net Effect</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype="RecommendationJustificationContent" elementName='content[2]' fieldLabel='Net Effect'
          startingValue={resourceState.content[2] || null} setResourceState={setResourceState}
          dataEntryStyle='Net Effect' />
      </div>
      <br />
      <h3 id="judgments">Judgments</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype="RecommendationJustificationContent" elementName='content[3]' fieldLabel='Judgments'
          startingValue={resourceState.content[3] || null} setResourceState={setResourceState}
          dataEntryStyle='Judgments' />
      </div>
      <br />
      <h3 id="competing-interests">Competing Interests</h3>
      <div style={{ marginLeft: "24px" }}>
        <DataEntry datatype="RecommendationJustificationContent" elementName='content[4]' fieldLabel='Competing Interests'
          startingValue={resourceState.content[4] || null} setResourceState={setResourceState}
          dataEntryStyle='Competing Interests' />
      </div>
      <br />
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        <DisplayHowToCite citationSummary={citationSummary}
          citationJson={citationJson} />
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternEdit resourceState={resourceState} setResourceState={setResourceState} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      <div style={{ marginLeft: "24px" }}>
        <div>
          <p>Add Classifiers:</p>
          <DataEntry asArray={true} datatype='Classification' elementName='newClassifications'
            fieldLabel='Classification' startingValue={resourceState.newClassifications} setResourceState={setResourceState} />
        </div>
        {(classificationsArrayState) && <div>
          <p>Existing Classifiers:</p>
          {classificationsLoadedState ?
            <DisplayClassifiers classificationsArray={classificationsArrayState} />
            :
            <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
          }
        </div>}
      </div>
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
};

export {
  RecommendationJustificationComponentEntry, RecommendationJustificationContentEntry,
  RecommendationJustificationEdit, generateRecommendationJustificationNarrative
};