import React, { useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import FevirContext from './FevirContext';
import ActiveHEvKAProjects from './ActiveHEvKAProjects';
import submitToFevirServer from './SubmitToFevirServer';

const AllProjectsList = ({ useTitle, signInStatusState, resourcesListState, setResourcesListState }) => {
  const globalContext = useContext(FevirContext);
  //const [loadMyProjectsState, setLoadMyProjectsState] = useState(false);

  //useTitle(loadMyProjectsState ? "My FEvIR Projects" : "FEvIR Platform Projects");
  useTitle("FEvIR Platform Projects");

  useEffect(async () => {
    if (!globalContext.userState.loading) {
      await getProjectResourcesList(setResourcesListState);
    }
  }, [signInStatusState]);

  const getProjectResourcesList = async (setResourcesListState) => {
    if (!globalContext.userState.loading) {       //Before I had userState.loading !== undefined && in the conditional, but adding it will break it for unlogged in users
      if (true
        //resourcesListState.loadMyResources === undefined || resourcesListState.loadMyResources !== loadMyResources
      ) {
        setResourcesListState((prevState) => {
          return {
            ...prevState,
            loading: true,
            //loadMyResources: loadMyResources
          };
        });
        //let idToken = userState.idToken;
        const body = {
          'functionid': 'getfevirprojectresourceslist',
          //'loadmyresources': loadMyResources,
          'idToken': ''
        };
        let response = await submitToFevirServer(globalContext, 15000, body, true, false);
        if (response?.success) {
          //Sorts by title (in alphabetical order), then by id
          response.resourceslist.sort((a, b) => {
            const atitle = a.title.trim().toLowerCase();
            const btitle = b.title.trim().toLowerCase();
            if (atitle > btitle) return 1;
            if (atitle < btitle) return -1;
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
          });

          setResourcesListState((prevState) => {
            return {
              ...prevState,
              resourcesList: response.resourceslist,
              loading: false
            };
          });
        }
      }
    } else {
      setResourcesListState((prevState) => {
        return {
          ...prevState,
          loading: true
        };
      });
    }
  }

  const FhirResourcesLinks = () => {
    let resourcesLinks = [];
    let resourceList = resourcesListState.resourcesList;
    resourcesLinks.push(<>
      {resourceList.map((resource, resourceIndex) => {
        return <div style={{ paddingTop: "0px", paddingBottom: "4px" }} key={resourceIndex}>
          <Link to={`/resources/${resource.resourcetype}/${resource.id}`}>
            <span style={{ fontSize: "24px" }}>{resource.title}</span>
          </Link>
          <span>{resource.status === "draft" && <>&nbsp;&nbsp;(draft)</>}</span>
          <br />
        </div>
      })}
    </>);

    return <div>
      {resourcesLinks.map((resourceLink, index) => { return <div key={index}>{resourceLink}</div> })}
    </div>
  };

  return <div>
    {(globalContext.userState.loading || resourcesListState.loading) &&
      <Dimmer className={"loadingDimmer"} active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    }
    <h2>All Projects</h2>
    {/*loadMyProjectsState ?
      <span>
        <h2>My Projects <span style={{ paddingLeft: "32px", fontSize: "14px", cursor: "pointer", color: "blue" }}
          onClick={() => { setLoadMyProjectsState(false); }}>change to All Projects</span></h2>
      </span>
      :
      <span>
        <h2>All Projects <span style={{ paddingLeft: "32px", fontSize: "14px", cursor: "pointer", color: "blue" }}
          onClick={() => { setLoadMyProjectsState(true); }}>change to My Projects</span></h2>
      </span>
  */}
    <br />
    <FhirResourcesLinks />
    {/*(!userState.loading && loadMyProjectsState && resourcesListState.loading === false)
      ?
      <h1>You haven't created any projects yet.</h1>
      :
      <>{(!userState.loading && loadMyProjectsState && !userState.id)
        ?
        <h1>Please login to view your resources</h1>
        :
        <>{resourcesListState.resourcesList?.length !== 0 && <FhirResourcesLinks />}</>}</>
      */}
  </div>
};

const ProjectsPage = ({ useTitle, signInStatusState, resourcesListState, setResourcesListState }) => {

  return <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <div>
        <h2>Join the <Link to="/resources/Project/29272">Health Evidence Knowledge Accelerator (HEvKA)</Link></h2>
        <h3>Learn about <Link to="/resources/Project/29394">Fast Evidence Interoperability Resources (FEvIR) Platform</Link></h3>
        <br />
      </div>
      <div>
        <div className="row content">
          <ActiveHEvKAProjects />
        </div>
      </div>
      <br />
      <div>
        <AllProjectsList useTitle={useTitle} signInStatusState={signInStatusState}
          resourcesListState={resourcesListState} setResourcesListState={setResourcesListState} />
      </div>
      <div className="row footer">
        <br />
        <span>The FEvIR Platform uses the HL7<sup>&#174;</sup> FHIR<sup>&#174;</sup> standard (<a href="http://build.fhir.org/" target="_blank" rel="noopener noreferrer">current build</a>). HL7<sup>&#174;</sup>, and FHIR<sup>&#174;</sup> are the registered trademarks of Health Level Seven International and their use of these trademarks does not constitute an endorsement by HL7.</span>
      </div>
    </Segment>
  </div>
};

export default ProjectsPage;
