import { DisplayFromFHIR, SimpleResourceFieldViewer } from './ResourceFunctions';
import { MetadataPatternDisplay, DisplayClassifiers } from './MetadataPatternDisplay';

const ClassificationDisplay = ({ fhirJson, classificationsArrayState, classificationsLoadedState }) => {
  let howToCite;
  if (fhirJson.citeAsMarkdown) {
    howToCite = fhirJson.citeAsMarkdown;
  }
  return <div>
    <div style={{ marginTop: "12px" }}>
      <h3 id="classified-item">Classified Item</h3>
      <div style={{ marginLeft: "24px" }}>
        {fhirJson.artifactReference && <DisplayFromFHIR reference={fhirJson.artifactReference} />}
        {fhirJson.artifactCanonical && <DisplayFromFHIR uri={fhirJson.artifactCanonical} />}
        {fhirJson.artifactUri && <DisplayFromFHIR uri={fhirJson.artifactUri} />}
      </div>
      <h3 id="classifications">Classifications</h3>
      <div>
        {(Array.isArray(fhirJson.content) && fhirJson.content.length > 0) &&
          fhirJson.content.map((content, contentIndex) => {
            let contentCopy = JSON.parse(JSON.stringify(content));
            contentCopy.type = content.type.coding?.[0]?.display || content.type.text || content.type.coding?.[0]?.code || "[Untyped]";
            contentCopy.classifier = content.classifier.map(classifier => classifier.coding?.[0]?.display || classifier.text || classifier.coding?.[0]?.code || "[Unclassified]");
            return <div key={contentIndex}>
              <p><b>Classification {contentIndex + 1}: </b></p>
              <div style={{ marginLeft: "24px" }}>
              <DisplayFromFHIR classification={contentCopy} />
              </div>
              </div>
          })}
      </div>
      <h3 id="classification-status">Classification Status</h3>
      <div style={{ marginLeft: "24px" }}>
        <p><b>Workflow Status: </b>{fhirJson.workflowStatus}</p>
        <p><b>Disposition: </b>{fhirJson.disposition}</p>
      </div>
      <h3 id="how-to-cite">How to Cite</h3>
      <div style={{ marginLeft: "24px" }}>
        {(howToCite) ?
          <div>
            <p><b>Citation Summary:</b></p>
            <DisplayFromFHIR markdown={howToCite} />
          </div>
          :
          <p>Can be added upon editing.</p>
        }
      </div>
      <h3 id="metadata">Metadata</h3>
      <div style={{ marginLeft: "24px" }}>
        <MetadataPatternDisplay fhirJson={fhirJson} />
      </div>
      <h3 id="classifiers">Classifiers</h3>
      {classificationsLoadedState ?
        <DisplayClassifiers classificationsArray={classificationsArrayState} />
        :
        <><img style={{ height: "22px" }} src="/spinner.gif" alt="Loading" /> Classifiers being loaded...</>
      }
      <h3 id="json-outline">JSON Outline</h3>
      <SimpleResourceFieldViewer resource={fhirJson} parentElement={""} />
      <br /><br />
    </div>
  </div>
}

export default ClassificationDisplay;