const addOption = (setter, newText, newValue) => {
    if (newValue === undefined || newValue === "") {
      newValue = newText;
    }
    setter(prevState  => {
      let newOptions;
      let addNewOption = true;
      for (let optionIndex in prevState.options) {
        if (prevState.options[optionIndex].value ===  newValue) {
          addNewOption = false;
          break;
        }
      }
      if (addNewOption) {
        newOptions = [{ text: newText, value: newValue, key: newValue }, ...prevState.options];
      } else {
        newOptions = prevState.options;
      }
      return {
        ...prevState,
        "options": newOptions,
      }
    });
};

export default addOption;