import React, { useState, useEffect, memo } from 'react';
import { DataEntry, DatatypeSelector } from './DataEntryFormFunctions';
import { DisplayFromFHIR } from './ResourceFunctions';

const handleChange = (name, value, setResourceState) => {
  if (name.at(-1) === "]") {
    let nameSplit = name.split("[");
    setResourceState(prevState => {
      let newValue = prevState[nameSplit[0]].map((entry, entryIndex) => {
        if (entryIndex === parseInt(nameSplit[1])) {
          return value;
        } else {
          return entry;
        }
      })
      return { ...prevState, [nameSplit[0]]: newValue }
    });
  } else {
    setResourceState(prevState => { return { ...prevState, [name]: value } })
  }
}

let projectActionCodeInstructions = {
  "addcitation": "Button label will be **Add Citation** and descriptive text will be **Create a citation record and associate with this project.** There are no parameters to set.",
  "addresearchsubjectandconsent": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.

  Set parameters to include "Group-FOI" and "ResearchStudy-FOI" and "document" as strings`,
  "addupdatereferencelist": "Button label will be **Add/Update Reference List** and descriptive text will be **Create or update a Reference List based on the Citation Resources associated with this project.",
  "changebaselinerates": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "targetResourceType" to an array of the FHIR Resource Types to be selected as targets for work, specifically EvidenceVariable. 

  Set parameter of "columnHeaders" to an array of the desired column headers for the display of ChangeBaselineRates (e.g. Outcome, Research Estimate, Self-Reported Risk (as %)). 
  
  Set parameter of "columnValues" to an array of the code for the desired table cell values for the display of ChangeBaselineRates. Supported codes include "resource.title", "research-estimate" and "self-reported" 

  Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "checkeligibilitycriteriamatch": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display.`,
  "checkeligibilitycriteriamatch2": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display.`,
  "createevidencereportsubject": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display.`,
  "generateevidencereport": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display.`,
  "generateneteffectreport": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display.`,
  "generatesummaryoffindings": "Button label will be **Generate Summary of Findings** and descriptive text will be **Create a Summary of Findings derived from the OutcomeDefinition, ComparativeEvidence, ComparatorOnlyEvidence, and InterventionOnlyEvidence Resources associated with the project.** There are no parameters to set.",
  "gotourl": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "url" to the desired URL value. Set parameter of "buttonDisplay" to "noButton" to avoid button display (and the link will replace the current tab instead of opening in a new tab).`,
  "joinprojectgroup": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "joinvotingpermissiongroup": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "resourceId" to the FOI of the CodeSystem Resource. Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "launchquestionnaire": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "ResearchStudy-FOI" to the FOI of the ResearchStudy Resource. Set parameter of "Questionnaire-FOI" to the FOI of the Questionnaire Resource. Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "launchquestionnaire2": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "ResearchStudy-FOI" to the FOI of the ResearchStudy Resource. Set parameter of "Questionnaire-FOI" to the FOI of the Questionnaire Resource. Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "provideinput": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "inputForm" to a string value with the desired type ("textBox" or "radioButton" or "checkboxList"). The input form will display after the description on the right side.
  
  Set parameter of "variableName" to a string value with the desired variable name (e.g. "patientDataBundle", "eligibityCriteraEvidenceVariable"). 

  Set parameter of "validationCheck" to an array value with the desired validation checks (e.g. "isJSON", "isBundle", "contains1Patient", "isEvidenceVariable", "containsCharacteristic").
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "raterelativeimportance": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "targetResourceType" to an array of the FHIR Resource Types to be selected as targets for work, specifically EvidenceVariable. 

  Set parameter of "columnHeaders" to an array of the desired column headers for the display of RateRelativeImportance (e.g. Outcome, Desirability, Relative Importance (as %)). 
  
  Set parameter of "columnValues" to an array of the code for the desired table cell values for the display of RateRelativeImportance. Supported codes include "resource.title", "desirability" and "relative-importance" 

  Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "selectresources": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "inputForm" to a string value with the desired type ("textBox" or "radioButton" or "checkboxList"). The input form will display after the description on the right side.
  
  Set parameter of "variableName" to a string value with the desired variable name (e.g. "patientDataBundle", "eligibityCriteraEvidenceVariable"). 

  Set parameter of "validationCheck" to an array value with the desired validation checks (e.g. "isJSON", "isBundle", "contains1Patient", "isEvidenceVariable", "containsCharacteristic").
  
  Set parameter of "buttonDisplay" to "noButton" to avoid button display.`,
  "toggleenablerobat": "Button label will be **Enable/Disallow RoBAT** and descriptive text will be **Allow/Disallow project viewers to use the Risk of Bias Assessment Tool (RoBAT) for items in the Reference List.** There are no parameters to set.",
  "vieweligibilitycriteria": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.

  Set parameters to include "FOI" as a string to include the FOI for the Resource containing the eligibility criteria.`,
  "viewmyworkone": `Add text to *Label* field above to set the display inside the **Button** and add text to *Description* above to set the descriptive summary to the **right side** of the Button.
  
  Set parameter of "targetResourceType" to an array of the FHIR Resource Types to be selected as targets for work. 

  Set parameter of "columnHeaders" to an array of the desired column headers for the display of ViewMyWork.
  
  Set parameter of "columnValues" to an array of the code for the desired table cell values for the display of ViewMyWork. Supported codes include "resource.title", "date-of-first-publication-of-article", "date-of-first-preprint-of-article", "date-of-first-registry-posting-of-results", "notes" and "date-of-first-publication-of-results" 

  Set parameter of "buttonDisplay" to "noButton" to avoid button display (and lose the functional action).`,
  "viewmyworktwo": `PLACEHOLDER`
};

let projectActionCodeOptions = Object.keys(projectActionCodeInstructions);

const ProjectActionEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingAction = { description: "", label: "", function: "", parameter: [] };
  if (!startingValue) {
    startingAction = {};
  } else {
    if (startingValue.extension) { startingAction.extension = startingValue.extension; }
    if (startingValue.description) { startingAction.description = startingValue.description; }
    if (startingValue.label) { startingAction.label = startingValue.label; }
    if (startingValue.function) { startingAction.function = startingValue.function; }
    if (startingValue.parameter) { startingAction.parameter = startingValue.parameter; }
  }

  const [actionState, setActionState] = useState(JSON.parse(JSON.stringify(startingAction || {})));

  useEffect((() => {
    if (Object.keys(actionState).length) {
      let newAction = {};
      if (actionState.extension) { newAction.extension = actionState.extension; }
      if (actionState.description) { newAction.description = actionState.description; }
      if (actionState.label) { newAction.label = actionState.label; }
      if (actionState.function) { newAction.function = actionState.function; }
      if (Array.isArray(actionState.parameter) && actionState.parameter.length > 0) {
        newAction.parameter = actionState.parameter;
      }
      if (Object.keys(newAction).length === 0) {
        newAction = null;
      }
      handleChange(elementName, newAction, setResourceState);
    }
  }), [actionState]);

  return <>
    <p style={{marginBottom: "0px"}}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='markdown' elementName='description' fieldLabel='Description'
        startingValue={actionState.description} setResourceState={setActionState} />
      <DataEntry datatype='string' elementName='label' fieldLabel='Label'
        startingValue={actionState.label} setResourceState={setActionState} />
      <DataEntry datatype='code' elementName='function' fieldLabel='Function Code'
        allowedValues={projectActionCodeOptions} dataEntryStyle='dropdown'
        startingValue={actionState.function} setResourceState={setActionState} />
      {actionState.function && <>
        <FunctionSpecificProjectActionParameterEntry functionid={actionState.function}
          elementName='parameter' fieldLabel='Parameter(s)'
          startingValue={actionState.parameter} setResourceState={setActionState} />
      </>}
    </div>
  </>
});

const FunctionSpecificProjectActionParameterEntry = memo(({ functionid, elementName, fieldLabel, startingValue,
  setResourceState }) => {
  switch (functionid) {
    case "addcitation":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
      </div>;
    case "addresearchsubjectandconsent":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "addupdatereferencelist":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
      </div>;
    case "changebaselinerates":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "checkeligibilitycriteriamatch":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "checkeligibilitycriteriamatch2":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "createevidencereportsubject":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "generateevidencereport":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "generateneteffectreport":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "generatesummaryoffindings":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
      </div>;
    case "gotourl":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "joinprojectgroup":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "joinvotingpermissiongroup":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "launchquestionnaire":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "launchquestionnaire2":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "provideinput":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "raterelativeimportance":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "selectresources":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "toggleenablerobat":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
      </div>;
    case "vieweligibilitycriteria":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "viewmywork":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "viewmyworkone":
      return <div>
        <p><b>Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    case "viewmyworktwo":
      return <div>
        <p><b>PLACEHOLDER Project Action Code Instructions: </b></p>
        <div style={{ border: "1px solid", width: "90%", padding: "1px 5px" }}>
          <DisplayFromFHIR markdown={projectActionCodeInstructions[functionid]} />
        </div>
        <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
          fieldLabel='Parameter(s)'
          startingValue={startingValue} setResourceState={setResourceState} />
      </div>;
    default:
      return <DataEntry asArray={true} datatype='ProjectActionParameter' elementName='parameter'
        fieldLabel='Parameter(s)'
        startingValue={startingValue} setResourceState={setResourceState} />
  }
});

const ProjectActionParameterDotValueAllowedDatatypes = ['String', 'Array'];
const ProjectActionParameterEntry = memo(({ elementName, fieldLabel, startingValue, setResourceState }) => {
  let startingParameter = {
    element: "", valueString: "", valueArray: ""
  }
  let startingParameterDotValueDatatype = 'none';
  if (!startingValue) {
    startingValue = "";
  } else {
    if (startingValue.extension) { startingParameter.extension = startingValue.extension; }
    if (startingValue.element) { startingParameter.element = startingValue.element; }
    if (startingValue.valueString) {
      startingParameter.valueString = startingValue.valueString;
      startingParameterDotValueDatatype = "String";
    }
    if (startingValue.valueArray) {
      startingParameter.valueArray = startingValue.valueArray;
      startingParameterDotValueDatatype = "Array";
    }
  }

  const [actionParameterState, setActionParameterState] = useState(JSON.parse(JSON.stringify(startingParameter || {})));

  const [parameterDotValueDatatypeState, setParameterDotValueDatatypeState] = useState(startingParameterDotValueDatatype);

  useEffect((() => {
    if (parameterDotValueDatatypeState === 'String') {
      setActionParameterState(prevState => { return { ...prevState, 'valueArray': null } })
    }
    if (parameterDotValueDatatypeState === 'Array') {
      setActionParameterState(prevState => { return { ...prevState, 'valueString': null } })
    }
  }), [parameterDotValueDatatypeState]);

  useEffect((() => {
    if (Object.keys(actionParameterState).length > 0) {
      let newParameter = {};
      if (actionParameterState.extension) { newParameter.extension = actionParameterState.extension; }
      if (actionParameterState.element) { newParameter.element = actionParameterState.element; }
      if (parameterDotValueDatatypeState === 'String' && actionParameterState.valueString) {
        newParameter.valueString = actionParameterState.valueString;
      }
      if (parameterDotValueDatatypeState === 'Array' && actionParameterState.valueArray) {
        newParameter.valueArray = actionParameterState.valueArray;
      }
      if (Object.keys(newParameter).length === 0) {
        newParameter = null;
      }
      handleChange(elementName, newParameter, setResourceState);
    }
  }), [actionParameterState]);

  return <>
    <p style={{marginBottom: "0px"}}><b>{fieldLabel}: </b></p>
    <div style={{ marginLeft: "24px" }}>
      <DataEntry datatype='string' elementName='element' fieldLabel='Parameter Name'
        startingValue={actionParameterState.element} setResourceState={setActionParameterState} />
      <DatatypeSelector elementXName='value[x]' allowedDatatypes={ProjectActionParameterDotValueAllowedDatatypes}
        datatypeState={parameterDotValueDatatypeState} setDatatypeState={setParameterDotValueDatatypeState} />
      {parameterDotValueDatatypeState === 'String' &&
        <DataEntry datatype='string' elementName='valueString' fieldLabel='Parameter Value'
          startingValue={actionParameterState.valueString}
          setResourceState={setActionParameterState} />}
      {parameterDotValueDatatypeState === 'Array' &&
        <DataEntry asArray={true} datatype='string' elementName='valueArray' fieldLabel='Parameter Value (as array)'
          startingValue={actionParameterState.valueArray}
          setResourceState={setActionParameterState} />}
    </div>
  </>
})

export { ProjectActionEntry, ProjectActionParameterEntry };