import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Segment, TextArea } from 'semantic-ui-react';
import { TextField } from '@mui/material';
import GenericToggleButtons from './GenericToggleButtons';
import FevirContext from './FevirContext';
import submitToFevirServer from './SubmitToFevirServer';

const CreateNewProjectPage = ( { useTitle } ) => {
  useTitle("FEvIR Project Builder");
  const globalContext = useContext(FevirContext);

  const [fhirEntryState, setFhirEntryState] = useState({"title": "", "description": "", "status": "active" });

  const changeFhirEntryState = (newValue, field) => {
    setFhirEntryState(prevState  => {
      return {
        ...prevState,
        [field]: newValue
      };
    });
  };

  const history = useHistory();

  const submitResource = async () => {

    if (fhirEntryState.title.trim() === "") {
      alert("title field is missing");
      return ;
    }

    if (fhirEntryState.description.trim() === "") {
      alert("description field is missing");
      return ;
    }

    let fhirEntry = {
      "resourceType": "Project",
      "title": fhirEntryState.title,
      "description": fhirEntryState.description,
      "action": [
        {
          "description": "Create a citation record and associate with this project.",
          "label": "Add Citation",
          "function": "addcitation"
        }
      ]
    };

    let fhirEntryString = JSON.stringify(fhirEntry, null, 2);

    const body = {
      'functionid': "submitfhirresource",
      'idToken': "",
      'fhirEntry': fhirEntryString,
      'title': fhirEntryState.title,
      'status': fhirEntryState.status,
    };

    let response = await submitToFevirServer(globalContext, 5000, body, true, false);
    
    if (response.success) {
      history.push(`/resources/${response.resourcetype}/${response.formstateid}/edit`);
    }

  };

  return (
    <div>
    <Segment className={`containerSegment maxRemainderOfPageSegment`} raised>
      <h2 style={{textAlign: "center", color: "#AC3B61"}}>FEvIR<sup style={{verticalAlign: "super", fontSize: "x-small"}}>®</sup>: Project Builder</h2>
      {/*<Button style={{position: "absolute", right: "3px", top: "3px"}} className="formButton negative" content="✖" onClick={() => { history.push("/"); } } />*/}
      <br /><br />
      {globalContext.userState.firebaseuid ?
        <>
          <TextField style={{width: "760px" }} multiline className="inputField" type='text' label={'Project Title'} size="small" variant='outlined' value={fhirEntryState.title} onChange={(e) => { changeFhirEntryState(e.target.value, "title"); } }/>
          <br /><br />
          <b>Description: </b><br/>
          <TextArea style={{width: "760px" }} rows={5} type='text' label={'Description'} size="small" variant='outlined' value={fhirEntryState.description} onChange={(e) => { changeFhirEntryState(e.target.value, "description"); } }/>
          <br/><br/>
          {/* Move this into a module because it's used for both JsonEntryPage and ResourceDisplayPage */}
          <GenericToggleButtons thin={true} boxes={true} togglable={false}
              values={["active", "draft"]} labels={["Publish (anyone can view)", "Draft (only editors can view)"]} fieldLabel={"status"} elementName={"status"}
              startingValue={fhirEntryState.status} setter={setFhirEntryState}
          />
          <br />
          <br />
          <Button className="formButton positive" content="Submit" compact onClick={submitResource} />
        </>
          :
            <p>Please login. (If you do not have an account click Sign In and log in with your Google Account.)</p>
      }
      </Segment>
    </div>
  );
};

  export default CreateNewProjectPage;