
import CharacteristicsTable from './CharacteristicsTable';

const InclusionExclusionCharacteristicTables = ({editMode, smallCriteriaHeader, groupInclusionCharacteristics, groupExclusionCharacteristics, stateSetter, changeState, groupCharacteristicKindOptions, setGroupCharacteristicKindOptions}) => {
    return <>
        <CharacteristicsTable criteriaType={"Inclusion"} editMode={editMode} smallCriteriaHeader={smallCriteriaHeader} groupCriteriaCharacteristics={groupInclusionCharacteristics} stateSetter={stateSetter} changeState={changeState} groupCharacteristicKindOptions={groupCharacteristicKindOptions} setGroupCharacteristicKindOptions={setGroupCharacteristicKindOptions} />
        <br/>
        <CharacteristicsTable criteriaType={"Exclusion"} editMode={editMode} smallCriteriaHeader={smallCriteriaHeader} groupCriteriaCharacteristics={groupExclusionCharacteristics} stateSetter={stateSetter} changeState={changeState} groupCharacteristicKindOptions={groupCharacteristicKindOptions} setGroupCharacteristicKindOptions={setGroupCharacteristicKindOptions} />
    </>
}

export default InclusionExclusionCharacteristicTables;