import { jsonErrorCheck2, deleteEmptyElementsInObjectRecursive } from './ResourceFunctions';
import { generateCitation } from './DataEntryFormFunctions';

const measureTextViewChangesToJson = (fhirJson, measureState) => {
    if (fhirJson === undefined) {
        return;
    }
    fhirJson.meta = measureState.meta;
    fhirJson.language = measureState.language;
    fhirJson.text = measureState.text;
    fhirJson.contained = measureState.contained || "DELETEME";
    fhirJson.extension = measureState.extension || "DELETEME";
    fhirJson.modifierExtension = measureState.modifierExtension || "DELETEME";
    fhirJson.url = measureState.url || "DELETEME";
    fhirJson.identifier = measureState.identifier || "DELETEME";
    fhirJson.version = measureState.version || "DELETEME";
    fhirJson.versionAlgorithmString = measureState.versionAlgorithmString || "DELETEME";
    fhirJson.versionAlgorithmCoding = measureState.versionAlgorithmCoding || "DELETEME";
    fhirJson.name = measureState.name || "DELETEME";
    fhirJson.title = measureState.title || "DELETEME";
    fhirJson.subtitle = measureState.subtitle || "DELETEME";
    fhirJson.status = measureState.status || "unknown";
    fhirJson.experimental = measureState.experimental ?? "DELETEME";
    fhirJson.subjectCodeableConcept = measureState.subjectCodeableConcept || "DELETEME";
    fhirJson.subjectReference = measureState.subjectReference || "DELETEME";
    fhirJson.basis = measureState.basis || "DELETEME";
    fhirJson.date = measureState.date || "DELETEME";
    fhirJson.publisher = measureState.publisher || "DELETEME";
    fhirJson.contact = measureState.contact || "DELETEME";
    fhirJson.description = measureState.description || "DELETEME";
    fhirJson.useContext = measureState.useContext || "DELETEME";
    fhirJson.jurisdiction = measureState.jurisdiction || "DELETEME";
    fhirJson.purpose = measureState.purpose || "DELETEME";
    fhirJson.usage = measureState.usage || "DELETEME";
    fhirJson.copyright = measureState.copyright || "DELETEME";
    fhirJson.copyrightLabel = measureState.copyrightLabel || "DELETEME";
    fhirJson.approvalDate = measureState.approvalDate || "DELETEME";
    fhirJson.lastReviewDate = measureState.lastReviewDate || "DELETEME";
    fhirJson.effectivePeriod = measureState.effectivePeriod || "DELETEME";
    fhirJson.topic = measureState.topic || "DELETEME";
    fhirJson.author = measureState.author || "DELETEME";
    fhirJson.editor = measureState.editor || "DELETEME";
    fhirJson.reviewer = measureState.reviewer || "DELETEME";
    fhirJson.endorser = measureState.endorser || "DELETEME";
    fhirJson.relatedArtifact = measureState.relatedArtifact || "DELETEME";
    fhirJson.library = measureState.library || "DELETEME";
    fhirJson.disclaimer = measureState.disclaimer || "DELETEME";
    fhirJson.scoring = measureState.scoring || "DELETEME";
    fhirJson.scoringUnit = measureState.scoringUnit || "DELETEME";
    fhirJson.compositeScoring = measureState.compositeScoring || "DELETEME";
    fhirJson.type = measureState.type || "DELETEME";
    fhirJson.riskAdjustment = measureState.riskAdjustment || "DELETEME";
    fhirJson.rateAggregation = measureState.rateAggregation || "DELETEME";
    fhirJson.rationale = measureState.rationale || "DELETEME";
    fhirJson.clinicalRecommendationStatement = measureState.clinicalRecommendationStatement || "DELETEME";
    fhirJson.improvementNotation = measureState.improvementNotation || "DELETEME";
    fhirJson.term = measureState.term || "DELETEME";
    fhirJson.guidance = measureState.guidance || "DELETEME";
    fhirJson.group = measureState.group || "DELETEME";
    fhirJson.supplementalData = measureState.supplementalData || "DELETEME";

    deleteEmptyElementsInObjectRecursive(fhirJson);
    let [citationSummary, citationJson, fhirJsonWithCiteAs] = generateCitation(fhirJson, fhirJson.id);
    fhirJson = fhirJsonWithCiteAs;
    return fhirJson;
}

const createNewClassificationJson = (measureState) => {
    let contentArray = measureState.newClassifications;
    if (!contentArray) {
        return "";
    }
    let classifiedResourceType = "Measure";
    let classifiedFoi = measureState.id;
    let classifiedReference = classifiedResourceType + "/" + classifiedFoi;
    let classifedTitle = measureState.title || measureState.name || "[Untitled.]";
    let classificationJson = {
        "resourceType": "ArtifactAssessment",
        "artifactReference": {
            "reference": classifiedReference,
            "type": classifiedResourceType,
            "display": classifedTitle
        },
        "content": contentArray,
        "meta":{
            "profile": ["http://hl7.org/fhir/uv/ebm/StructureDefinition/classification"]
        }
    };
    return classificationJson;
}

const builderUpdateMeasureJson = (resourceId, fhirEntryState, formInputsStateRef) => {
    let measureState = formInputsStateRef.current.measureState;

    let fhirJson = jsonErrorCheck2("Measure", resourceId, fhirEntryState.startingVersionId, fhirEntryState.fhirEntryString);
    if (fhirJson === undefined || fhirJson === false) {
        return false;
    }

    if (fhirEntryState.activeIndex === 0) {
        fhirJson = measureTextViewChangesToJson(fhirJson, measureState);
        fhirJson = jsonErrorCheck2("Measure", resourceId, fhirEntryState.startingVersionId, fhirJson);
        if (fhirJson === undefined || fhirJson === false) {
            return false;
        }
    }
    let newClassificationJson = createNewClassificationJson(measureState);

    return [fhirJson, newClassificationJson];
}

export { builderUpdateMeasureJson };